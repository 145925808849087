import React, { Component, Fragment } from "react";
import { Input } from "@patient-access/ui-kit";

import {
  Overlay,
  Button,
  Form,
  FormItem,
  InputError,
} from "@patient-access/ui-kit";

import type { CONFIRMATION_TYPES } from "constants/BranchesConstants";
import locale from "service/locale";

type Props = {
  handleDoNotConfirm: () => any,
  handleConfirm: (password: string) => any,
  type: CONFIRMATION_TYPES,
  isConfirmationError: boolean,
  errorMessage: string,
  isLoading: boolean,
  entityName: string,
};

type State = {
  phraseText: string,
  isConfirmationError: boolean,
  isValidationError: boolean,
};

class ConfirmByPasswordOverlay extends Component<Props, State> {
  state = {
    phraseText: "",
    isConfirmationError: this.props.isConfirmationError,
    isValidationError: false,
  };

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({ isConfirmationError: nextProps.isConfirmationError });
  };

  handleSendConfirmationRequest = (e: any) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    const { phraseText } = this.state;
    const { handleConfirm, entityName } = this.props;
    if (phraseText === entityName) {
      handleConfirm(phraseText);
    } else {
      this.setState({
        isValidationError: true,
      });
    }
  };

  handlePasswordChange = (e: any) => {
    this.setState({
      phraseText: e.target.value,
      isValidationError: false,
    });
  };

  render() {
    const {
      handleDoNotConfirm,
      type,
      errorMessage,
      isLoading,
      entityName,
    } = this.props;
    const { isConfirmationError, isValidationError } = this.state;

    const renderErrorMessage = () => {
      return (
        <Fragment>
          {(isConfirmationError || isValidationError) && (
            <InputError
              message={
                isConfirmationError
                  ? errorMessage
                  : isValidationError
                    ? `${
                        locale.Modals.confirmByPasswordOverlay.validationError
                      } ${type}.`
                    : null
              }
            />
          )}
        </Fragment>
      );
    };

    const renderDeleteButton = () => {
      return (
        <Button
          buttonType="secondary"
          messageKey="stay-btn"
          defaultMessage={`${
            locale.Modals.confirmByPasswordOverlay.header
          } ${type}`}
          data-id="stay-btn"
          className="patient-care-btn-in-group"
          isLoading={isLoading}
          isDisabled={isConfirmationError || isValidationError}
          type="submit"
        />
      );
    };

    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="confirm-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{`${locale.Modals.confirmByPasswordOverlay.header} ${type}`}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{`${
            locale.Modals.confirmByPasswordOverlay.content[0]
          } `}
          <b>{`${entityName}`}</b>
          {`${locale.Modals.confirmByPasswordOverlay.content[1]} ${type}
          ${locale.Modals.confirmByPasswordOverlay.content[2]}`}.</h4>
        </div>
        <Form
          noValidate
          className="patient-care-overlay-form"
          onSubmit={this.handleSendConfirmationRequest}
        >
          <FormItem
            type="item"
            error={isConfirmationError || isValidationError ? "error" : null}
          >
            <Input
              type="text"
              id="some-id"
              name="some-name"
              placeholder= {`${locale.Modals.confirmByPasswordOverlay.placeholder}${type}${locale.Modals.confirmByPasswordOverlay.placeholder2}`}
              onChange={this.handlePasswordChange}
            />
            {renderErrorMessage()}
          </FormItem>
          <FormItem type="buttons">
            <div className="overlay-confirmation-buttons">
              <Button
                buttonType="blueline"
                messageKey="discard-btn"
                defaultMessage={
                  locale.Modals.confirmByPasswordOverlay.buttonDoNotRemove
                }
                onClick={handleDoNotConfirm}
                data-id="discard-btn"
                className="patient-care-btn-in-group"
                isLoading={isLoading}
                type="button"
              />
              {renderDeleteButton()}
            </div>
          </FormItem>
        </Form>
      </Overlay>
    );
  }
}

export default ConfirmByPasswordOverlay;
