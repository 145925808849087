import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  Search,
  LinkIcon,
  IconChevronLeft
} from "@patient-access/ui-kit";
import {checkSearchDOB} from 'helpers/checkValues';
import {formatSearchDOB} from 'helpers/formatData';
import { closeSearch } from "actions/panel";
import { searchAppointments, setSearchEvents, setSearchResults } from "actions/appointments";
import type { PanelActions } from "types/panel";

import locale from "service/locale";

type Props = {
  closeSearch: () => PanelActions,
  searchAppointments: (searchText: string) => any,
  setSearchEvents: (events: any[]) => any,
  setSearchResults: (results: any[]) => any,
  isActiveSearchResults: boolean
};

type State = {
  searchText: string
};

const mapStateToProps = state => ({
  isActiveSearchResults: state.panel.isActiveSearchResults
});

const mapDispatchToProps = (dispatch: (action: any) => Function): Object => ({
  searchAppointments: searchText => dispatch(searchAppointments(searchText)),
  setSearchEvents: (events) => dispatch(setSearchEvents(events)),
  setSearchResults: (results) => dispatch(setSearchResults(results)),
  closeSearch: () => dispatch(closeSearch())
});

class SearchForm extends Component<Props, State> {
  state = {
    searchText: ""
  };

  handleSearchAppointments = (e?: Event) => {
    e && e.preventDefault();
    const { searchAppointments } = this.props;
    let { searchText } = this.state;
    if(checkSearchDOB(searchText)){
      searchText = formatSearchDOB(searchText);
    }
    searchAppointments(searchText);
  };

  handleChangeSearch = ({ target: { value } }) => {
    this.setState({ searchText: value });
  };

  handleCloseSearch = () => {
    const { closeSearch, setSearchEvents, setSearchResults } = this.props;
    this.setState({ searchText: "" }, () => {
      closeSearch();
      setSearchEvents([]);
      setSearchResults([]);
    })
  };

  render() {
    const { searchText } = this.state;
    const { isActiveSearchResults } = this.props;

    return (
      <div className={`patient-care-calendar-search ${isActiveSearchResults ? "active-results" : ""}`}>
        {isActiveSearchResults ? (
          <LinkIcon
            to="#delete"
            size="medium"
            icon={<IconChevronLeft outline />}
            accessibilitySpan="kk"
            onClick={this.handleCloseSearch}
          />
        ) : null}
        <Form noValidate>
          <FormItem type="item">
            <Search
              type="icon"
              id="patient-care-search-form"
              name="search-appointments"
              searchMessage={locale.Share.searchFormPlaceholder}
              placeholder={locale.Share.searchFormPlaceholder}
              autoComplete="off"
              searchHandler={this.handleSearchAppointments}
              onChange={this.handleChangeSearch}
              value={searchText}
            />
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchForm);
