import React, { Component } from "react";
import { Button, FormItem } from "@patient-access/ui-kit";

type Props = {
  handleDiscardChanges: () => any,
  handleCloseModal: () => any,
  proceedFunction: () => any,
  isDisabled?: boolean,
  isDiscard: boolean,
  cancelText: string,
  proceedText: string,
  isLoading: boolean,
  customClassName: string
};

class ProceedButtons extends Component<Props> {
  render () {
    const {
      handleDiscardChanges,
      isDiscard,
      handleCloseModal,
      isDisabled,
      cancelText,
      proceedText,
      proceedFunction,
      customClassName,
      isLoading,
    } = this.props;

    return (
      <div className={`patient-care-modal-footer ${customClassName}`}>
        <FormItem type="buttons">
          <div className="patient-care-row-align-right">
            <div className="patient-care-buttons-group">
              <Button
                buttonType="blueline"
                messageKey="cancel-btn"
                defaultMessage={cancelText}
                onClick={isDiscard ? handleDiscardChanges : handleCloseModal}
                isLoading={isLoading}
                data-id="cancel-btn"
                className="patient-care-btn-in-group"
              />
              <Button
                buttonType="secondary"
                messageKey="create-btn"
                defaultMessage={proceedText}
                isDisabled={isDisabled || false}
                onClick={proceedFunction}
                isLoading={isLoading}
                data-id="create-btn"
                className="patient-care-btn-in-group"
                tabIndex={(isDisabled || false) ? -1 : 0}
              />
            </div>
          </div>
        </FormItem>
      </div>
    );
    }
}

export default ProceedButtons;
