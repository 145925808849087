import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link, Redirect } from 'react-router-dom';
import { Icon, IconChevronLeft } from '@patient-access/ui-kit';
import type { Action } from 'types/actions';
import type { Organisation, OrganisationDetails } from 'types/organisation';
import { getOrganisations } from 'actions/organisations';
import { getOrganisationDetails } from 'actions/organisationDetails';
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';
import * as RolesConstants from 'constants/RolesConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import locale from 'service/locale';
import { ai } from 'service/telemetry';

type Props = {
  organisationsList: Organisation[],
  organisationId: string,
  profile: any,
  getOrganisations: () => Action,
  currentRole: Object,
  getOrganisationDetails: (organisationId: string) => Action,
  organisationDetails: OrganisationDetails,
};

type State = {
  organisationDetails: Object | string;
};

const mapStateToProps = (state) => ({
  organisationsList: state.organisations.organisationsList,
  currentRole: state.roles.profileCurrentRole,
  organisationDetails: state.organisationDetails,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getOrganisations: () => dispatch(getOrganisations()),
  getOrganisationDetails: (organisationId) => dispatch(getOrganisationDetails(organisationId)),
});

class OrganisationSidebar extends Component<Props, State> {
  state = {
    organisationDetails: this.props.organisationDetails ? this.props.organisationDetails : "Loading..."
  }

  componentDidMount = () => {
    const { organisationDetails, getOrganisations, getOrganisationDetails, organisationId } = this.props;
    getOrganisations();
    !organisationDetails && getOrganisationDetails(organisationId);
    if (organisationDetails.confirmationStatus === "unchanged") {
      getOrganisationDetails(organisationId);
    }
  }

  componentWillReceiveProps = (nextProps: Props) => {
    nextProps.organisationDetails && this.setState({
      organisationDetails: nextProps.organisationDetails
    })
  }

  handleSmartPharmacyRegisterTelemetry = () => {
    const { organisationId, profile, organisationDetails } = this.props;
    ai.appInsights.trackEvent({
      name: 'PAProSmartPharmacyRegisterMenuItem',
    }, {
      aiUserId: profile.id,
      aiUserEmail: profile.email,
      aiUserName: profile.displayName,
      aiOrganisationId: organisationId,
      aiOrganisationName: organisationDetails.name,
      aiOrganisationPostCode: organisationDetails.companyAddress.postcode,
    });
  }

  render() {
    const { organisationDetails } = this.state;
    const { currentRole, organisationId } = this.props;

    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrganizationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    const isSmartPharmacy = organisationDetails && organisationDetails.supportedFeatures && organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy') && organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy').isEnabled;

    const canSeePayout = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role) && (organisationDetails && organisationDetails.organisationType !== ORGANISATION_TYPE.AFFILIATE);
    const canSeeCalendars = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role) && (organisationDetails && organisationDetails.organisationType !== ORGANISATION_TYPE.AFFILIATE);
    const canSeeOrgSettings = currentRole.role === RolesConstants.ADMIN || (currentRole.role === RolesConstants.ORGANIZATION_ADMIN && organisationDetails && organisationDetails.organisationType === ORGANISATION_TYPE.AFFILIATE);
    const canSeeEnabledServices = currentRole.role === RolesConstants.ADMIN;
    const canSeeAvailabilityRules = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role) && (organisationDetails && organisationDetails.organisationType !== ORGANISATION_TYPE.AFFILIATE) && (organisationDetails && organisationDetails.organisationType !== ORGANISATION_TYPE.INTEGRATE);
    const canSeeSmartPharmacy = currentRole.role === RolesConstants.ADMIN;
    const canSeeSmartPharmacyRegister = isOrganizationAdmin && !isSmartPharmacy;

    const renderSmartPharmacyRegister = () => {
      if (canSeeSmartPharmacyRegister) {
        return (
          <ul className="patient-care-sidebar-register">
            <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SMART_PHARMACY_REGISTER}`} onClick={this.handleSmartPharmacyRegisterTelemetry}><em>{locale.Sidebars.smartPharmacyRegisterBefore}</em> <span>{locale.Sidebars.smartPharmacyRegister}</span></NavLink></li>
          </ul>
        );
      }
      return null;
    }

    if (organisationDetails) {
      return (
        <aside className="patient-care-sidebar-left">
          <div className="patient-care-sidebar-block">
            <div className="patient-care-breadcrumbs">
              {isSuperAdmin ? <Link to={RoutesConstants.ADMIN_ORGANISATIONS} className="patient-care-breadcrumbs-icon"><Icon type="inline" size="smaller" icon={<IconChevronLeft outline={true} />} /></Link> : null}
              {isSuperAdmin ? organisationDetails.name || "Loading..." : "Organisation panel"}
            </div>
          </div>
          <div className="patient-care-sidebar-block">
            <ul className="patient-care-unstyled-list">
              <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`} activeClassName="active">{locale.Sidebars.branches}</NavLink></li>
              <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.USERS}`} activeClassName="active">{locale.Sidebars.users}</NavLink></li>
              {canSeeCalendars ? <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.CALENDARS}`} activeClassName="active">{locale.Sidebars.calendars}</NavLink></li> : null}
              <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICES}`} activeClassName="active">{locale.Sidebars.services}</NavLink></li>
              {canSeeAvailabilityRules ? <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.AVAILABILITY_RULES}`} activeClassName="active">{locale.Sidebars.availabilityRules}</NavLink></li> : null}
              {canSeePayout ? <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.PAYOUT}`} activeClassName="active">{locale.Sidebars.payoutDetails}</NavLink></li> : null}
              {canSeeSmartPharmacy ? <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SMART_PHARMACY}`} activeClassName="active">{locale.Sidebars.smartPharmacy}</NavLink></li> : null}
              <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.DETAILS}`} activeClassName="active">{locale.Sidebars.organisationDetails}</NavLink></li>
              {canSeeOrgSettings ? <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SETTINGS}`} activeClassName="active">{locale.Sidebars.organisationSettings}</NavLink></li> : null}
              {canSeeEnabledServices ? <li><NavLink className="patient-care-sidebar-list-item" to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.ENABLED_SERVICES}`} activeClassName="active">{locale.Sidebars.enabledServices}</NavLink></li> : null}
            </ul>
            {renderSmartPharmacyRegister()}
          </div>
        </aside>
      );
    };
    return <Redirect to={RoutesConstants.ADMIN_ORGANISATIONS} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSidebar);
