export const SET_BOOKING_CALENDAR = "SET_BOOKING_CALENDAR";
export const SET_BOOKING_SERVICE = "SET_BOOKING_SERVICE";
export const SET_BOOKING_SLOTS = "SET_BOOKING_SLOTS";
export const SET_BOOKING_TIME = "SET_BOOKING_TIME";
export const SET_SLOT_CALENDARS_LIST = "SET_SLOT_CALENDARS_LIST";
export const BOOK_APPOINTMENT_PENDING = "BOOK_APPOINTMENT_PENDING";
export const BOOK_APPOINTMENT_PENDING_STOP = "BOOK_APPOINTMENT_PENDING_STOP";
export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS";
export const BOOK_APPOINTMENT_ERROR = "BOOK_APPOINTMENT_ERROR";
export const HOLD_APPOINTMENT_ERROR = "HOLD_APPOINTMENT_ERROR";
export const GET_SLOTS_PENDING = "GET_SLOTS_PENDING";
export const GET_SLOTS_SUCCESS = "GET_SLOTS_SUCCESS";
export const GET_SLOTS_ERROR = "GET_SLOTS_ERROR";
export const CREATE_INTERNAL_EVENT_PENDING = "CREATE_INTERNAL_EVENT_PENDING";
export const CREATE_INTERNAL_EVENT_ERROR = "CREATE_INTERNAL_EVENT_ERROR";
export const CREATE_INTERNAL_EVENT_SUCCESS = "CREATE_INTERNAL_EVENT_SUCCESS";
export const BOOK_BULK_APPOINTMENT_SUCCESS = "BOOK_BULK_APPOINTMENT_SUCCESS";
export const BOOK_BULK_APPOINTMENT_ERROR = "BOOK_BULK_APPOINTMENT_ERROR";


export const AFTERNOON_HOUR = "12";
