import values from "lodash.values";
import locale from "service/locale";

const checkFirstNameRegexp = /^[A-Z\s-'`]{2,50}$/i;
const checkLastNameRegexp = /^[A-Z\s-'`]{2,100}$/i;
const checkSearchDOBRegexp = /^([0-2][0-9]|(3)[0-1])\/(((0)[0-9])|((1)[0-2]))\/(19|20)\d\d$/i;
const checkDOBRegexp = /^(19|20)\d\d-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/;
// eslint-disable-next-line
export const checkEmailRegexp = /^([^<>()\[\]\\.,;:\s@"](\.[^<>()\[\]\\.,;:\s@"]+)*){1,64}@(([a-zA-Z\-0-9]{2,184}\.)+[a-zA-Z]{2,64})$/i;
const checkPhoneRegexp = /^([0-9]{10,})$/i;
// eslint-disable-next-line
const checkPasswordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%£*\-?@_|^~:.])(?=.{12,})/;
const checkPostcodeRegexp = /^[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]? ?[0-9][a-zA-Z]{2}$/;
const checkServicePriceRegexp = /^\d*\.?\d{0,2}$/;
const checkTimeFormatRegexp = /^(([0-1][0-9])|([2][0-4])):[0-5][05]$/;
const checkDescriptionRegexp = /^[-\w`~!@#$%^&*()+={}|[\]\\:"";'?,./""''–—£\s\n\r ]*$/;
const checkUrlRegexp =/^http(s)?:\/\/(www\.)?[a-zA-Z0-9]+([-_.]?[a-zA-Z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const checkFirstName = (firstName: string): boolean => {
  return firstName ? checkFirstNameRegexp.test(firstName) : false;
};

export const checkDescription = (description: string): boolean => {
  return description ? checkDescriptionRegexp.test(description) : false;
};

export const validateDescription = (text: string): string => {
  let arr = [];
  const  lines = text.split('\n');
  const pattern = checkDescriptionRegexp;
  if (lines.filter( line => !pattern.test(line)).length > 0 ) {
    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i);
      if (arr.indexOf(char) === -1) {
        arr.push(' \'' + char + '\''); //collect all unique characters in text
      }
    }
  }
  arr = arr.filter( el => !pattern.test(el));
  return arr;
};

export const checkLastName = (lastName: string): boolean => {
  return lastName ? checkLastNameRegexp.test(lastName) : false;
};

export const checkDOB = (DOB: string): boolean => {
  return DOB ? checkDOBRegexp.test(DOB) : false;
};

export const checkSearchDOB = (DOB: string): boolean => {
  return DOB ? checkSearchDOBRegexp.test(DOB) : false;
};

export const checkEmail = (email: string): boolean => {
  return email ? checkEmailRegexp.test(email) : false;
};

export const checkPhoneNumber = (phoneNumber: string): boolean => {
  return phoneNumber ? checkPhoneRegexp.test(phoneNumber) : false;
};

export const checkPassword = (password: string): boolean => {
  return password ? checkPasswordRegexp.test(password) : true;
};

export const checkPostcode = (postcode: string): boolean => {
  return postcode ? checkPostcodeRegexp.test(postcode) : false;
};

export const checkServicePrice = (servicePrice: string): boolean => {
  return (servicePrice || "").length ? checkServicePriceRegexp.test(servicePrice) : false;
};

export const checkTimeFormat = (time: string): boolean => {
  return time ? checkTimeFormatRegexp.test(time) : false;
};

export const checkPrice = (price, nhsCovered): boolean => {
  return (!nhsCovered && price !== 0 && (!price || price[price.length - 1] === "."));
};


export const checkPastDate = (inputDate: string): boolean => {
  if (inputDate !=="") {
    let dateToCompare = new Date(inputDate);
    let today = new Date();
    return (today > dateToCompare) ? true: false;
  }
};

export const checkOpenHours = (form: any, calendarDetails: any): Object => {
  const { availablePeriods } = calendarDetails;
  const formData = JSON.parse(JSON.stringify(form));
  formData.edited && delete formData.edited;
  formData.name && delete formData.name;
  formData.startDate && delete formData.startDate;
  formData.endDate && delete formData.endDate;
  formData.duration_type && delete formData.duration_type;
  formData.blockedPeriods && delete formData.blockedPeriods;
  const days = values(formData);
  const keys = Object.keys(formData);
  const hasValues = days.some(
    (day, index) => {
      const editedDay = locale.AddNewCalendar.daysOfWeek.find(day => day.value.name === keys[index]);
      const availablePeriod = availablePeriods.find(period => period.dayOfWeek === (editedDay || {}).dayOfWeek);
      const status = day.status || availablePeriod;
      const start = day.start || (availablePeriod && availablePeriod.periods[0].start.slice(0,-3));
      const end = day.end || (availablePeriod && availablePeriod.periods[0].end.slice(0,-3));
      return status ? start && end : true;
    }
  );
  const isNotMultipleByFive = days.some(
    (day, index) => {
      const editedDay = locale.AddNewCalendar.daysOfWeek.find(day => day.value.name === keys[index]);
      const availablePeriod = availablePeriods.find(period => period.dayOfWeek === (editedDay || {}).dayOfWeek);
      const start = day.start || (availablePeriod && availablePeriod.periods[0].start.slice(0,-3));
      const end = day.end || (availablePeriod && availablePeriod.periods[0].end.slice(0,-3));
      return (!day.status || day.status === locale.AddNewCalendar.dayAvailableOptions[0].value) &&
        ((start && !checkTimeFormat(start)) || (end && !checkTimeFormat(end)));
    }
  );
  const isEqualTime = days.some(
    (day, index) => {
      const editedDay = locale.AddNewCalendar.daysOfWeek.find(day => day.value.name === keys[index]);
      const availablePeriod = availablePeriods.find(period => period.dayOfWeek === (editedDay || {}).dayOfWeek);
      const start = day.start || (availablePeriod && availablePeriod.periods[0].start.slice(0,-3));
      const end = day.end || (availablePeriod && availablePeriod.periods[0].end.slice(0,-3));
      return (!day.status || day.status === locale.AddNewCalendar.dayAvailableOptions[0].value) &&
        start && checkTimeFormat(start) &&
        end && checkTimeFormat(end) &&
        start === end &&
        start !== "";
    }
  );
  const isStartTimeLessThanEndTime = days.some(
    (day, index) => {
      const editedDay = locale.AddNewCalendar.daysOfWeek.find(day => day.value.name === keys[index]);
      const availablePeriod = availablePeriods.find(period => period.dayOfWeek === (editedDay || {}).dayOfWeek);
      const start = day.start || (availablePeriod && availablePeriod.periods[0].start.slice(0,-3));
      const end = day.end || (availablePeriod && availablePeriod.periods[0].end.slice(0,-3));
      return (!day.status || day.status === locale.AddNewCalendar.dayAvailableOptions[0].value) &&
        checkTimeFormat(start || "") &&
        checkTimeFormat(end || "") &&
        start.length === 5 &&
        end.length === 5 &&
        start > end;
    }
  );
  return { isNotMultipleByFive, isEqualTime, isStartTimeLessThanEndTime, hasValues };
};

export const passwordContainsEmail = (email: string, password: string): boolean => {
  return password.toLowerCase().indexOf(email.toLowerCase().split("@")[0]) >= 0;
};

export const checkURL = (url: string): boolean => {
  return url ? checkUrlRegexp.test(url) : false;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}

export const isEmptyArray = (arr) => {
  return arr.length === 0;
}
