import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import IdleTimerContainer from './IdleTimer/IdleTimer';

import Header from "./Header/Header";
import Calendar from "components/Pages/Calendar/Calendar";
import Reports from "components/Pages/Reports/Reports";
import Admin from "components/Pages/Admin/Admin";
import Agenda from "components/Agenda/Agenda";
import TaskManager from "components/TaskManager/TaskManager";

import { requestUserData } from "actions/profile";
import { setScreenWidth } from "actions/panel";
import { closeCalendarDropdown } from "actions/calendarDropdown";
import { closeProfileDropdown } from 'actions/panel';
import type { Action } from "types/actions";

import * as RoutesConstants from 'constants/RoutesConstants';
import * as RolesConstants from 'constants/RolesConstants';

import { isAuthorized, isAzureA2bAuthencationEnabled } from 'components/Pages/Login/AAD/aadAuthHelper';

import 'react-datepicker/dist/react-datepicker.css';
import "./styles.scss";
import "./styles-overridden.scss";
import "./patient-care-datepicker.scss";
import "./aside-visible.scss";

type Props = {
  isAuth: boolean,
  currentRole: any,
  isTaskManagerVisible: boolean,
  isAgendaVisible: boolean,
  requestUserData: () => Action,
  hasUnhandledError: boolean,
  setScreenWidth: (isLowResolution: boolean) => Action,
  isLowResolution: boolean,
  isCalendarLinkOpened: boolean,
  closeCalendarDropdown: () => Action,
  isProfileDropdownOpen: boolean,
  closeProfileDropdown: () => Action,
}

type State = {
  isCurrentSession: string | typeof undefined | null,
  isUncaughtFrontEndError: boolean,
}

const mapStateToProps = (state) => ({
  isAuth: state.login.isAuth,
  currentRole: state.roles.profileCurrentRole,
  hasUnhandledError: state.panel.hasUnhandledError,
  isLowResolution: state.panel.isLowResolution,
  isCalendarLinkOpened: state.calendarDropdown.isOpened,
  isProfileDropdownOpen: state.panel.isProfileDropdownOpen,
  isTaskManagerVisible: state.taskManager.isVisible,
  isAgendaVisible: state.panel.isActiveAgenda,
});

const mapDispatchToProps = (dispatch) => ({
  requestUserData: () => dispatch(requestUserData()),
  setScreenWidth: (isLowResolution: boolean) => dispatch(setScreenWidth(isLowResolution)),
  closeCalendarDropdown: () => dispatch(closeCalendarDropdown()),
  closeProfileDropdown: () => dispatch(closeProfileDropdown()),
});

class App extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      isCurrentSession: this.getCurrentSession(),
      isUncaughtFrontEndError: false,
    };
    this.mainContainer = React.createRef();
  }

  getCurrentSession = () => {
    if(isAzureA2bAuthencationEnabled()) {
      return isAuthorized();
    }
    return sessionStorage.getItem("token");
  }

  componentDidMount = () => {
    const { isAuth, requestUserData } = this.props;
    const { isCurrentSession } = this.state;

    window.addEventListener("resize", this.setScreenWidth);
    localStorage.setItem('isActiveAgenda', JSON.stringify(false));
    if (!isAuth && isCurrentSession) {
      requestUserData();
    }

    document.body.style.overflowY = "hidden";
  };

  componentWillReceiveProps = (nextProps: Props) => {
    this.mainContainer.current && this.mainContainer.current.removeEventListener("click", this.checkDropdown);
    this.mainContainer.current && this.mainContainer.current.addEventListener("click", this.checkDropdown);
    if (!nextProps.isAuth) {
      const { requestUserData } = this.props;
      const isCurrentSession = this.getCurrentSession();
      this.setState({ isCurrentSession }, () => { isCurrentSession && requestUserData() });
    }
  };

  componentDidCatch = () => {
    this.setState({ isUncaughtFrontEndError: true });
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.setScreenWidth);
    document.body.style.overflowY = "auto";
  };

  setScreenWidth = () => {
    const { setScreenWidth, isLowResolution } = this.props;
    setScreenWidth(isLowResolution);
  };

  checkDropdown = () => {
    const { isCalendarLinkOpened, closeCalendarDropdown, isProfileDropdownOpen, closeProfileDropdown } = this.props;
    isCalendarLinkOpened && closeCalendarDropdown();
    isProfileDropdownOpen && closeProfileDropdown();
  };

  renderApp() {
    const { isTaskManagerVisible, isAgendaVisible, currentRole } = this.props;

    const isAdmin = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role);

    return (
      <Fragment>
        <IdleTimerContainer />
        <div className={`patient-care-wrapper${(isTaskManagerVisible || isAgendaVisible) ? ' aside-visible' : ''}`}>
          <Header />
          <main className="patient-care-main" ref={this.mainContainer}>
            <Switch>
              <Route path={RoutesConstants.ADMIN} component={Admin} />
              <Route path={RoutesConstants.REPORTS} component={Reports} />
              <Route exact path={RoutesConstants.HOME} component={Calendar} />
              <Route exact path={RoutesConstants.CALENDAR} component={() => (<Redirect to={RoutesConstants.HOME} />)} />
            </Switch>
          </main>
          <Agenda />
          {isAdmin && <TaskManager />}
        </div>
      </Fragment>
    );
  }

  render() {
    const { isAuth, hasUnhandledError, location } = this.props;
    const { isCurrentSession, isUncaughtFrontEndError } = this.state;

    const refferer = () => {
      if (!location || location.pathname === RoutesConstants.HOME || location.pathname === RoutesConstants.LOGIN) return '';
      return `?referrer=${location.pathname}`;
    }

    if (isUncaughtFrontEndError) {
      //TODO: make component for showing FE error message instead of blank screen
      return (
        <div>
          <h1>Unexpected error happened</h1>
          <p>Please, reload page</p>
        </div>
      )
    }

    if ((!isAuth && !isCurrentSession) || hasUnhandledError) {
      return <Redirect to={`${RoutesConstants.LOGIN}${refferer()}`} />
    }

    if (isAuth) {
      return (
        <div className="patient-care-app">
          {this.renderApp()}
        </div>
      );
    }

    return (
      <div className="patient-care-app">
        <div>Loading...</div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
