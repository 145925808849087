import React, { Component } from 'react';
import './styles/reports-legend.scss';

type Props = {
  children: any,
};

class ReportsLegend extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="reports-legend">
        {children}
      </div>
    );
  }
};

export default ReportsLegend;
