import React, { Component } from 'react';
import './styles/task-manager-content-scroll.scss';

type State = {
  isScrollTop: boolean,
};

type Props = {
  children: any,
};

class TaskManagerContentScroll extends Component<Props, State> {
  state = {
    isScrollTop: true,
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    const wrapperContent = document.querySelector('.task-manager-content');
    this.setState({
      isScrollTop: wrapperContent.scrollTop === 0,
    });
  }

  render() {
    const { isScrollTop } = this.state;
    const { children } = this.props;

    return (
      <div className={`task-manager-content ${isScrollTop ? 'task-manager-content-top' : 'task-manager-content-scroll'}`} onScroll={this.scrollHandler} id="task-manager-content-scroll">
        {children}
      </div>
    );
  }
};

export default TaskManagerContentScroll;
