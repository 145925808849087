import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { Form, IconCog, IconPerson, IconPhone, IconVideo, IconHome, IconCheckbox, LogoNHS } from '@patient-access/ui-kit';
import { IconSort } from 'svg';
import { formatAsCurrency } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

export const columnsAffiliates = [
  {
    className: 'cell-main',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.name,
    accessor: 'name',
    Cell: ({ cell: { value } }) => <span>{value}</span>
  },
  {
    className: 'cell-default',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.showPrice,
    accessor: 'showPrice',
    Cell: ({ cell: { value } }) => <i className="group">{value ? <IconCheckbox outline={false} /> : <IconCheckbox outline={true} />}</i>,
    sortType: 'basic'
  },
  {
    className: 'cell-default',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.price,
    accessor: 'price',
    Cell: ({ cell: { value } }) => <span>{value ? <Fragment>{locale.BranchServicesRedesign.table.servicesSummary.labels.price}{formatAsCurrency(value)}</Fragment> : <Fragment>{locale.BranchServicesRedesign.table.servicesSummary.labels.priceNull}</Fragment>}</span>
  },
  {
    className: 'cell-default',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.status,
    accessor: 'paused',
    Cell: ({ cell: { value } }) => <span>{value ? locale.BranchServicesRedesign.table.servicesSummary.labels.statusPaused : locale.BranchServicesRedesign.table.servicesSummary.labels.statusActive}</span>,
    sortType: 'basic'
  },
  {
    className: 'cell-default',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.duration,
    accessor: 'duration',
    Cell: ({ cell: { value } }) => <span>{value} {locale.BranchServicesRedesign.table.servicesSummary.labels.duration}</span>
  },
  {
    className: 'cell-default',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.modes,
    accessor: 'modes',
    Cell: ({ cell: { value } }) =>
      <i className="group">
        {value.sort().map(item => (
          <Fragment key={item}>
            {item === 0 ? <span title={locale.BranchServicesRedesign.table.servicesSummary.labels.modesF2F}><IconPerson outline={false} /></span> : null}
            {item === 1 ? <span title={locale.BranchServicesRedesign.table.servicesSummary.labels.modesVideo}><IconVideo outline={false} /></span> : null}
            {item === 2 ? <span title={locale.BranchServicesRedesign.table.servicesSummary.labels.modesPhone}><IconPhone outline={false} /></span> : null}
            {item === 5 ? <span title={locale.BranchServicesRedesign.table.servicesSummary.labels.modesHomeDelivery}><IconHome outline={false} /></span> : null}
          </Fragment>
        ))}
      </i>,
    sortType: 'basic',
  },
  {
    className: 'cell-default',
    Header: locale.BranchServicesRedesign.table.servicesSummary.headers.bookingUrl,
    accessor: 'bookingUrl',
    Cell: ({ cell: { value } }) => (<span>
      {value !== '' && value !== null ? value : locale.OrganisationServices.table.servicesSummary.labels.bookingUrlEmpy}
    </span>)
  }
];

export function TableServicesSummaryAffiliates({ columns, data, props }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: 'name', desc: false }],
      },
    },
    useSortBy,
  );
  const [ isMenuOn, setMenuOn ] = useState(false);

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div className="react-table-settings-background" onClick={() => { setMenuOn(!isMenuOn); }} />
          <div className="react-table-settings-menu">
            <h2>{locale.BranchServicesRedesign.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map(column => {
                if (column.id === 'name') return null;
                return (
                  <div className="input-checkbox input-checkbox-small" key={column.id}>
                    <label htmlFor={column.id}>
                      <input id={column.id} name={column.id} type="checkbox" {...column.getToggleHiddenProps()} />
                      <span>{column.Header}</span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  }

  const renderCell = (row, cell) => {
    if (cell.column.id === 'name' && !props.isBranchMember) {
      return (
        <Link to="#service-details" className={row.original.nhsCovered ? 'nhs-service' : null } title={cell.value} onClick={(e) => handleSelectedRow(e, row.original)}>
          {cell.render('Cell')}
          {row.original.nhsCovered ? <i><LogoNHS /></i> : null}
        </Link>
      );
    }
    return <Fragment>{cell.render('Cell')}</Fragment>;
  }

  const handleSelectedRow = (e, row) => {
    e.preventDefault();
    props.setUserData({
      serviceId: row.id,
      serviceName: row.name,
      serviceAvailableModes: row.allowedModes,
      serviceIsNhsCovered: row.nhsCovered,
      serviceModes: row.modes,
      serviceIsActive: !row.paused,
      servicePrice: row.price,
      serviceIsVatIncluded: row.vatIncluded,
      serviceDuration: row.duration,
      serviceCutOff: row.lastMinute,
      serviceIsShowPrice: row.showPrice,
      serviceBookingUrl: row.bookingUrl
    });
    props.setUI({ isSidebarEditVisible: true });
  }

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div className="react-table-settings-button" onClick={() => { setMenuOn(!isMenuOn); }}>
          <i><IconCog outline={false} /></i>
        </div>
      </div>
      <table className="react-table branch-services-summary spacing-medium" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="sticky grey">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())} title={locale.BranchServicesRedesign.table.sortByLabel(column.Header)}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr className={row.original.paused ? 'highlighted' : ''} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {renderCell(row, cell)}
                      </td>
                    );
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
