import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Modal extends Component<{}, {}> {
  render() {
    return ReactDOM.createPortal(
      <React.Fragment>
        {this.props.children}
      </React.Fragment>,
      document.getElementById("modal")
    )
  }
}