import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAppointmentTime } from "helpers/formatData";
import { getCalendarColor } from "helpers/common";
import type { Appointment } from 'types/appointments';
import { getAppointmentDetails } from 'actions/appointmentDetails';
import type { AppointmentDetailsAction } from "types/appointmentDetails";

import locale from 'service/locale';

type Props = {
  appointments: Appointment[];
  getAppointmentDetails: (appointmentId: string, type: string) => AppointmentDetailsAction,
  calendarsList: any[]
};

const mapStateToProps = state => ({
  calendarsList: state.branchDetails.calendarsList
});

const mapDispatchToProps = (dispatch: Function) => ({
  getAppointmentDetails: (appointmentId, type) => dispatch(getAppointmentDetails(appointmentId, type)),
});

class SearchItem extends Component<Props> {

  openSearchDetails = (appointment: Appointment) => {
    const { getAppointmentDetails } = this.props;
    getAppointmentDetails(appointment.appointmentId, "search");
  };

  render() {
    const { appointments, calendarsList } = this.props;
    return (
      <ul className="patient-care-unstyled-list">
        {appointments.map(appointment => {
          const { endTime, startTime, service, calendar, patient, appointmentId, status } = appointment;
          const appointmentCalendar = calendarsList.find(cal => cal.id === calendar.id);
          return (
            <li
              key={appointmentId}
              className={
                `patient-care-search-item-holder
                    ${status === locale.Appointment.status.cancelled.status ? "cancelled" : ""}`
              }
              onClick={() => this.openSearchDetails(appointment)}
            >
              <div className="patient-care-avatar-holder">
                <i className={
                  `ui-kit-avatar ui-kit-avatar-small ui-kit-avatar-${appointmentCalendar
                    ? getCalendarColor(appointmentCalendar.order)
                    : 1}`
                }>
                  <span>{calendar.name ? calendar.name[0] : "Z"}</span>
                </i>
              </div>
              <div className="patient-care-info-search">
                    <span className="patient-care-time">
                      {getAppointmentTime(startTime, endTime)}
                    </span>
                <span className="patient-care-service">
                      {
                        status === locale.Appointment.status.cancelled.status
                          ? (locale.Appointment.status.cancelled.label + ": ")
                          : ""
                      }
                  {service.name}
                    </span>
                <span className="patient-care-person">{patient.fullName}</span>
              </div>
            </li>
          );
        })}
      </ul>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItem);
