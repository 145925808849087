import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconSupport extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z M11,16 L13,16 L13,18 L11,18 L11,16 Z M12.61,6.04 C10.55,5.74 8.73,7.01 8.18,8.83 C8,9.41 8.44,10 9.05,10 L9.25,10 C9.66,10 9.99,9.71 10.13,9.33 C10.45,8.44 11.4,7.83 12.43,8.05 C13.38,8.25 14.08,9.18 14,10.15 C13.9,11.49 12.38,11.78 11.55,13.03 C11.55,13.04 11.54,13.04 11.54,13.05 C11.53,13.07 11.52,13.08 11.51,13.1 C11.42,13.25 11.33,13.42 11.26,13.6 C11.25,13.63 11.23,13.65 11.22,13.68 C11.21,13.7 11.21,13.72 11.2,13.75 C11.08,14.09 11,14.5 11,15 L13,15 C13,14.58 13.11,14.23 13.28,13.93 C13.3,13.9 13.31,13.87 13.33,13.84 C13.41,13.7 13.51,13.57 13.61,13.45 C13.62,13.44 13.63,13.42 13.64,13.41 C13.74,13.29 13.85,13.18 13.97,13.07 C14.93,12.16 16.23,11.42 15.96,9.51 C15.72,7.77 14.35,6.3 12.61,6.04 L12.61,6.04 Z" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-support ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconSupport;
