import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconStar } from '@patient-access/ui-kit';
import './styles.scss';

class SectionBookingsPharmacy extends Component {
  render() {
    return (
      <div className="section-block section-bookings">
        <div className="wrapper section-bookings-wrapper">
          <div className="section-bookings-blob" style={{ top: '-130px', right: '-500px' }}>
            <img src="/assets/images/blobs/blob-four.svg" alt="" width="1240" />
          </div>
          <div className="section-bookings-header">
            <h2><FormattedMessage id="landing.feature.pharmacy.bookings.header" /></h2>
          </div>
          <div className="section-bookings-content">
            <div className="section-bookings-lside">
              <ul className="section-block-list">
                <li>
                  <h3><FormattedMessage id="landing.feature.pharmacy.bookings.feature_one.header" /></h3>
                  <p><FormattedMessage id="landing.feature.pharmacy.bookings.feature_one.copy" /></p>
                  <span className="line" />
                </li>
                <li>
                  <h3><FormattedMessage id="landing.feature.pharmacy.bookings.feature_two.header" /></h3>
                  <p><FormattedMessage id="landing.feature.pharmacy.bookings.feature_two.copy" /></p>
                  <span className="line" />
                </li>
                <li>
                  <h3><FormattedMessage id="landing.feature.pharmacy.bookings.feature_three.header" /></h3>
                  <p><FormattedMessage id="landing.feature.pharmacy.bookings.feature_three.copy" /></p>
                </li>
              </ul>
            </div>
            <div className="section-bookings-rside">
              <div className="section-bookings-image">
                <img src="/assets/images/landing/section-bookings-pharmacy.png" alt="" />
              </div>
              <div className="section-bookings-testimonials">
                <h3><FormattedMessage id="landing.feature.pharmacy.bookings.testimonial_copy" /></h3>
                <i><IconStar outline={false} /><IconStar outline={false} /><IconStar outline={false} /><IconStar outline={false} /><IconStar outline={false} /></i>
                <p><FormattedMessage id="landing.feature.pharmacy.bookings.testimonial_no" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionBookingsPharmacy;
