import React, { Component } from "react";
import { Form } from "@patient-access/ui-kit";
import moment from 'moment';

import type { OpenHours } from "types/calendars";
import * as CalendarsConstants from 'constants/CalendarsConstants';
import locale from 'service/locale';

type Props = {
  day: OpenHours,
};

export default class DaySettings extends Component<Props> {
  render() {
    const { day } = this.props;

    return (
      <div className="patient-care-block-row">
        <div className="patient-care-day-col">
          <span className="patient-care-day">{locale.AvailabilitySettings.daysOfWeek[day.day]}</span>
          <span className="patient-care-filled-select">
            { day.timesSessions && day.timesSessions.length ? locale.AvailabilitySettings.open : locale.AvailabilitySettings.closed }
          </span>
        </div>
        {
          day.timesSessions && day.timesSessions.length ? (
            <div className="patient-care-time-col">
              {
                day.timesSessions.map((timeSession, index) => {
                  const { from, to } = timeSession;
                  return (
                    <Form noValidate className="patient-care-time-inputs" key={index}>
                      <span className="patient-care-filled-input">
                        {
                          (from && from.length)
                            ? moment(from, CalendarsConstants.SERVER_TIME_FORMAT).format(CalendarsConstants.CLIENT_TIME_FORMAT)
                            : CalendarsConstants.EMPTY_VALUE
                        }
                      </span>
                            <span className="patient-care-filled-input">
                        {
                          (to && to.length)
                            ? moment(to, CalendarsConstants.SERVER_TIME_FORMAT).format(CalendarsConstants.CLIENT_TIME_FORMAT)
                            : CalendarsConstants.EMPTY_VALUE
                        }
                      </span>
                    </Form>
                  )
                })
              }
            </div>
          ) : null
        }
      </div>
    );
  }
}
