import * as BranchesConstants from "constants/BranchesConstants";
import type {
  Action
} from "types/actions";
import type {
  Branch
} from "types/organisation";

type BranchesState = {
  branchesList: Branch[],
  branchesListAsync: Branch[],
  pages: number,
  pageNumber: number,
  searchValue: string,
  createdBranch: {
    isRedirect: boolean,
    newOrganisationId: string,
    newBranchId: string,
  },
  onlineBranch: {
    isOverlayVisible: boolean,
    isCreateBranchLater: boolean,
  },
  isBranchesPending: boolean,
  isBranchesPPLPending: boolean,
  isBranchDeletionPending: boolean,
  pendingAppointmentsCount: number,
  isDisplayPendingAppointmentList: boolean,
  disableAlertBranchId: string,
  disableAlertPage: string,
  disabledCalendarAlertBranches: string[],
  disabledReportAlertBranches: string[]
};

const initialState: BranchesState = {
  branchesList: [],
  branchesListAsync:[],
  pages: 0,
  pageNumber: 0,
  searchValue: "",
  createdBranch: {
    isRedirect: false,
    newOrganisationId: "",
    newBranchId: "",
  },
  onlineBranch: {
    isOverlayVisible: false,
    isCreateBranchLater: false,
  },
  isBranchesPending: false,
  isBranchesPPLPending: false,
  isBranchDeletionPending: false,
  pendingAppointmentsCount: 0,
  isDisplayPendingAppointmentList: false,
  disableAlertBranchId: "",
  disableAlertPage: "",
  disabledCalendarAlertBranches: [],
  disabledReportAlertBranches: []
};

export default (
  localState: BranchesState = initialState,
  action: Action
): BranchesState => {
  switch (action.type) {
    case BranchesConstants.SET_BRANCHES_LIST:
      const {
        branchesList, searchValue
      } = action.payload;
      const {
        items, pages, pageNumber
      } = branchesList;
      return {
        ...localState,
        branchesList: items,
          pages: pages === 0 ? localState.pages : pages,
          pageNumber,
          searchValue,
          isBranchesPending: false,
      };
    case BranchesConstants.SET_BRANCHES_LIST_ASYNC:
      const {
        branchesListAsync, searchValueAsync
      } = action.payload;
      return {
        ...localState,
        branchesListAsync: branchesListAsync.items,
          searchValueAsync,
      };
    case BranchesConstants.SET_BRANCHES_PPL_LIST:
      const {
        PPLBranchesList, pplSearchValue
      } = action.payload;
      return {
        ...localState,
        PPLBranchesList: PPLBranchesList,
          pplSearchValue,
          isBranchesPPLPending: false,
      };
    case BranchesConstants.REDIRECT_TO_BRANCH_SETTINGS:
      return {
        ...localState,
        createdBranch: {
          isRedirect: action.payload.isRedirect,
          newOrganisationId: action.payload.newOrganisationId,
          newBranchId: action.payload.newBranchId,
        }
      };
    case BranchesConstants.REMOVE_REDIRECT:
      return {
        ...localState,
        createdBranch: {
          isRedirect: false,
          newOrganisationId: "",
          newBranchId: "",
        }
      };
    case BranchesConstants.SET_BRANCHES_PENDING:
      return {
        ...localState,
        isBranchesPending: true,
      };
    case BranchesConstants.SET_BRANCHESPPL_PENDING:
      return {
        ...localState,
        isBranchesPPLPending: true,
      };
    case BranchesConstants.DELETE_BRANCH_PENDING:
      return {
        ...localState,
        isBranchDeletionPending: true,
      };
    case BranchesConstants.DELETE_BRANCH_SUCCESS:
      return {
        ...localState,
        isBranchDeletionPending: false,
      };
    case BranchesConstants.DELETE_BRANCH_ERROR:
      return {
        ...localState,
        isBranchDeletionPending: false,
      };
    case BranchesConstants.SET_ONLINE_BRANCH_OVERLAY_VISIBILITY:
      return {
        ...localState,
        onlineBranch: {
          ...localState.onlineBranch,
          isOverlayVisible: action.payload
        }
      };
    case BranchesConstants.SET_ONLINE_BRANCH_OVERLAY_CREATE_LATER:
      return {
        ...localState,
        onlineBranch: {
          ...localState.onlineBranch,
          isCreateBranchLater: action.payload
        }
      };
     case BranchesConstants.SET_PENDING_APPOINTMENTS_COUNT:
      return {
        ...localState,
        pendingAppointmentsCount: action.payload
      };
      case BranchesConstants.SET_DISPLAY_PENDING_ALERT:
        const disabledCalendarAlertBranches = localState.disabledCalendarAlertBranches;
        const disabledReportAlertBranches = localState.disabledReportAlertBranches;
        if(action.payload.disableAlertPage === BranchesConstants.PENDING_ALERT_PAGE.CALENDAR){
          disabledCalendarAlertBranches.push(action.payload.disableAlertBranchId);
        }
        else if (action.payload.disableAlertPage === BranchesConstants.PENDING_ALERT_PAGE.REPORT){
          disabledReportAlertBranches.push(action.payload.disableAlertBranchId);
        }
        return {
          ...localState,
          disabledCalendarAlertBranches: disabledCalendarAlertBranches,
          disabledReportAlertBranches:  disabledReportAlertBranches
        };      
      case BranchesConstants.SET_DISPLAY_PENDING_APPOINTMENT_LIST:
        return {
          ...localState,
          isDisplayPendingAppointmentList: action.payload
        };
    default:
      return localState;
  }
};
