import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IconCog } from 'svg';
import './styles/button-settings.scss';

type Props = {
  onClick: any,
};

class ButtonSettings extends Component<Props> {
  render() {
    const { onClick } = this.props;
    return (
      <Link className="button-settings" to="#settings" onClick={onClick}>
        <IconCog outline={false} />
      </Link>
    );
  }
};

export default ButtonSettings;
