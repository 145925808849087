import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { filterSearchResultsByStatus } from 'actions/appointments';
import locale from 'service/locale';

type Props = {
  filterSearchResultsByStatus: (value: number, status: number, outcomeStatus: number) => Function,
  isActiveAppointmentSlots: boolean
};

const mapStateToProps = state => ({
  isActiveSearchResults: state.panel.isActiveSearchResults
});

const mapDispatchToProps = (dispatch: any): Object => ({
  filterSearchResultsByStatus: (value, status, outcomeStatus) =>
    dispatch(filterSearchResultsByStatus(value, status, outcomeStatus))
});

class SearchFilter extends Component<Props> {

  handleFilterByStatus = (selectedFilterOption: any) => {
    const { filterSearchResultsByStatus } = this.props;
    const { value, status, outcomeStatus } = selectedFilterOption;
    filterSearchResultsByStatus(value, status, outcomeStatus);
  };

  render() {
    const { isActiveSearchResults } = this.props;

    return (
      <div className={`patient-care-search-filter ${isActiveSearchResults ? "active-results": ""}`}>
        <Select
          onChange={this.handleFilterByStatus}
          options={locale.Search.searchFilterOptions}
          defaultValue={locale.Search.searchFilterOptions[0]}
          className="patient-care-select"
          classNamePrefix="patient-care"
          isSearchable={false}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
