import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FeatureSidebar } from 'components/Share/feature';
import { setUserStep, setUI, resetUserData } from 'actions/branchServices';
import StepOne from './StepOne/StepOne';
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';

type State = {
  isOverlayVisible: boolean,
};

type Props = {
  organisationId: string,
  branchId: string,
  userStep: any,
  userData: any,
  servicesSummary: any,
  isSidebarEditVisible: boolean,
  setUserStep: (value: any) => any,
  setUI: (value: any) => any,
  resetUserData: () => any,
  isAffiliate: boolean,
};

const mapStateToProps = (state) => ({
  userStep: state.branchServices.user.step,
  userData: state.branchServices.user.data,
  servicesSummary: state.branchServices.servicesSummary.data,
  isSidebarEditVisible: state.branchServices.ui.isSidebarEditVisible,
  isAffiliate: state.organisationDetails.organisationType === OrganisationsConstants.ORGANISATION_TYPE.AFFILIATE,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserData: () => dispatch(resetUserData()),
});

class SidebarEditService extends Component<Props, State> {
  state = {
    isOverlayVisible: false,
  };

  render() {
    const { organisationId, branchId, userStep, userData, servicesSummary, isSidebarEditVisible, setUserStep, setUI, resetUserData, isAffiliate } = this.props;
    const { isOverlayVisible } = this.state;
    const serviceId =  userData.serviceId;

    const handleStay = () => {
      this.setState({ isOverlayVisible: false });
    }

    const handleDiscard = () => {
      this.setState({ isOverlayVisible: false }, () => {
        setUI({ isSidebarEditVisible: false });
        setUserStep(1);
        resetUserData();
      });
    }

    const handleClose = () => {
      if (validateModifiedDetails()) {
        this.setState({ isOverlayVisible: true });
      } else {
        resetUserData();
        setUI({ isSidebarEditVisible: false });
      }
    }

    // Function is used to validate if discard overlay is required
    const validateModifiedDetails = () => {
      const serviceData = servicesSummary.find(service => service.id.includes(serviceId));

      if (isServiceStatusModified(serviceData) || isPriceModified(serviceData)
        || isDurationModified(serviceData) || isCutOffModified(serviceData) || isModesModified(serviceData) || (isAffiliate && isBookingUrlModified(serviceData))) {
        return true;
      } return false;
    }

    const isServiceStatusModified = (serviceData) => {
      if (serviceData.paused === userData.serviceIsActive) {
        return true;
      } return false;
    }

    const isPriceModified = (serviceData) => {
      if (serviceData.price !== userData.servicePrice || serviceData.vatIncluded !== userData.serviceIsVatIncluded) {
        return true;
      } return false;
    }

    const isDurationModified = (serviceData) => {
      if (serviceData.duration !== userData.serviceDuration) {
        return true;
      } return false;
    }

    const isCutOffModified = (serviceData) => {
      if (serviceData.lastMinute !== userData.serviceCutOff) {
        return true;
      } return false;
    }

    const isModesModified = (serviceData) => {
      const selectedModes = serviceData.modes.every(mode => userData.serviceModes.includes(mode));
      if (serviceData.modes.length !== userData.serviceModes.length || !selectedModes) {
        return true;
      } return false;
    }

    const isBookingUrlModified = (serviceData)=>{ 
      if(serviceData.bookingUrl !== userData.serviceBookingUrl){
        return true;
      }return false;
    }

    const renderContent = () => {
      if (serviceId !== "") {
        switch (userStep) {
          case 1:
            return <StepOne organisationId={organisationId} branchId={branchId} serviceId={serviceId} handleClose={handleClose} />;
          default:
            return null;
        }
      } else {
        return null;
      }
    }

    const renderOverlay = () => {
      if (isOverlayVisible) {
        return <DiscardChangesOverlay handleDiscardChanges={handleDiscard} handleStay={handleStay} />;
      }
      return null;
    }

    if (isSidebarEditVisible) {
      return (
        <Fragment>
          <FeatureSidebar isOpen={true} doClose={handleClose}>
            {renderContent()}
          </FeatureSidebar>
          {renderOverlay()}
        </Fragment>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarEditService);
