import React from "react";
import { Button } from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleDiscardChanges: () => any,
  proceedFunction: () => any,
  isEditMode: boolean,
  isLoading: boolean,
};

const FloatingFooterButtons = ({ handleDiscardChanges, isEditMode, proceedFunction, isLoading }: Props) => {
  return (
    <div className="patient-care-buttons-fixed-bottom">
      <div className="patient-care-container-sm">
        <div className="patient-care-row-align-right">
          <Button
            buttonType="blueline"
            messageKey="discard-btn"
            defaultMessage={locale.Appointment.buttons.discardChanges}
            onClick={handleDiscardChanges}
            isLoading={isLoading}
            data-id="discard-btn"
            className="patient-care-btn-in-group"
          />
          <Button
            buttonType="secondary"
            messageKey="save-changes-btn"
            defaultMessage={locale.Appointment.buttons.saveChanges}
            isLoading={isLoading}
            onClick={proceedFunction}
            isDisabled={isEditMode}
            data-id="save-changes-btn"
            className="patient-care-btn-in-group"
            tabIndex={isEditMode ? -1 : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default FloatingFooterButtons;
