import React, { Component } from 'react';
import { FormItem, InputError, Radio } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import locale from 'service/locale';

type Props = {
  userData: any,
  isError: boolean,
  setUserData: any,
  handleValidateStatus: any,
};

class SectionStatus extends Component<Props> {
  render() {
    const { userData, isError, setUserData, handleValidateStatus } = this.props;

    const handleStatus = (value: any) => {
      setUserData({ serviceIsActive: value });
      handleValidateStatus(true);
    }

    return (
      <SidebarBodySection>
        <SidebarBodyHeader>
          <h3>{locale.BranchServicesRedesign.section.serviceStatus.header}</h3>
          <p>{locale.BranchServicesRedesign.section.serviceStatus.description}</p>
        </SidebarBodyHeader>
        <SidebarBodyContent>
          <FormItem type="item" error={isError ? 'error' : null}>
            <div className="row row-list">
              <Radio
                id="serviceStatus_paused"
                name="serviceStatus"
                label={locale.BranchServicesRedesign.section.serviceStatus.labelPaused}
                onChange={() => { handleStatus(false); }}
                value="serviceStatus_paused"
                checked={userData.serviceIsActive === false}
              />
              <Radio
                id="serviceStatus_active"
                name="serviceStatus"
                label={locale.BranchServicesRedesign.section.serviceStatus.labelActive}
                onChange={() => { handleStatus(true); }}
                value="serviceStatus_active"
                checked={userData.serviceIsActive === true}
              />
            </div>
            {isError ? <InputError message={locale.BranchServicesRedesign.validationError.paused} /> : null}
          </FormItem>
        </SidebarBodyContent>
      </SidebarBodySection>
    );
  }
}

export default SectionStatus;
