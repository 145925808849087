import React, { Component } from 'react';
import { SkeletonChart } from 'components/Pages/Reports/skeleton';
import './styles/card-reports-prescriptions-chart-skeleton.scss';

class CardprescriptionRequestsChartSkeleton extends Component<Props> {
  render() {
    return (
      <div className="card-reports-prescriptions-chart-skeleton">
        <div className="card-reports-prescriptions-chart-skeleton-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" />
        </div>
        <div className="card-reports-prescriptions-chart-skeleton-row">
          <SkeletonChart height={376} />
        </div>
        <div className="card-reports-prescriptions-chart-skeleton-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" />
        </div>
      </div>
    );
  }
};

export default CardprescriptionRequestsChartSkeleton;
