import React, { Component } from 'react';

import './styles/patient-care-feature-content-wrapper.scss';

type Props = {
  children: any,
  type: string,
};

class FeatureContentWrapper extends Component<Props> {
  render() {
    const { type, children } = this.props;
    return (
      <div className={`patient-care-feature-content-wrapper${type === 'medium' ? ' medium' : ''}`}>
        {children}
      </div>
    );
  }
};

export default FeatureContentWrapper;
