import * as CalendarsListConstants from 'constants/CalendarsListConstants';

type CalendarsList = {
  // TODO
};

const initialState: CalendarsList = {
  calendars: {
    data: [],
    pages: 0,
    pageNumber: 0,
    isLoading: false,
    isError: false,
  },
  services: {
    data: [],
    isLoading: false,
    isError: false,
  },
  filters: {
    searchValue: '',
    branchIds: [],
    serviceIds: [],
  },
  calendarDetails: {
    calendarId: '',
    branchId: '',
  },
};

export default (localState: CalendarsList = initialState, action: Action): CalendarsList => {
  switch (action.type) {
    // FILTERS
    case CalendarsListConstants.SET_CALENDARS_LIST_SEARCH_VALUE:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          searchValue: action.payload,
        },
      };
    case CalendarsListConstants.SET_CALENDARS_LIST_BRANCHES_IDS:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          branchIds: action.payload,
        },
      };
    case CalendarsListConstants.SET_CALENDARS_LIST_SERVICES_IDS:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          serviceIds: action.payload,
        },
      };

    // CALENDARS_LIST
    case CalendarsListConstants.GET_CALENDARS_LIST_PENDING:
      return {
        ...localState,
        calendars: {
          ...localState.calendars,
          isLoading: true,
          isError: false,
        },
      };
    case CalendarsListConstants.GET_CALENDARS_LIST_SUCCESS:
      return {
        ...localState,
        calendars: {
          ...localState.calendars,
          isLoading: false,
          isError: false,
        },
      };
    case CalendarsListConstants.GET_CALENDARS_LIST_ERROR:
      return {
        ...localState,
        calendars: {
          ...localState.calendars,
          isLoading: false,
          isError: true,
        },
      };
    case CalendarsListConstants.SET_CALENDARS_LIST_DATA:
      const { items, pages, pageNumber } = action.payload;
      return {
        ...localState,
        calendars: {
          ...localState.calendars,
          data: items,
          pages: pages === 0 ? localState.calendars.pages : pages,
          pageNumber,
        },
      };

    // CALENDARS_SERVICES
    case CalendarsListConstants.GET_CALENDARS_SERVICES_PENDING:
      return {
        ...localState,
        services: {
          ...localState.services,
          isLoading: true,
          isError: false,
        },
      };
    case CalendarsListConstants.GET_CALENDARS_SERVICES_SUCCESS:
      return {
        ...localState,
        services: {
          ...localState.services,
          isLoading: false,
          isError: false,
        },
      };
    case CalendarsListConstants.GET_CALENDARS_SERVICES_ERROR:
      return {
        ...localState,
        services: {
          ...localState.services,
          isLoading: false,
          isError: true,
        },
      };
    case CalendarsListConstants.SET_CALENDARS_SERVICES_DATA:
      return {
        ...localState,
        services: {
          ...localState.services,
          data: action.payload,
        },
      };

    // CALENDAR_DETAILS
    case CalendarsListConstants.SET_CALENDAR_DETAILS_OVERLAY_DATA:
      return {
        ...localState,
        calendarDetails: {
          ...localState.calendarDetails,
          ...action.payload,
        }
      };

    // DEFAULT
    default:
      return localState;
  }
};
