import React, { Component } from 'react';
import './styles.scss';

type Props = {
  children: any,
};

class Section extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <section className="section">
        {children}
      </section>
    );
  }
}

export default Section;
