import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import find from "lodash.find";
import { LinkIcon, IconClose } from "@patient-access/ui-kit";

import * as RolesConstants from 'constants/RolesConstants';
import locale from 'service/locale';

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

type Props = {
  handleDeleteUserRole: (id: string) => any;
  account: any;
};

class UserRoleItem extends Component<Props> {

  handleDeleteRole = (e: Event) => {
    e && e.preventDefault();
    const { account, handleDeleteUserRole } = this.props;
    handleDeleteUserRole(account);
  };

  render() {
    const { account } = this.props;
    const isOrgAdmin = account.role === RolesConstants.ORGANIZATION_ADMIN;
    return (
      <Fragment>
        <hr className="patient-care-roles-separator" />
        <div className="patient-care-modal-edit-row">
          <div className="patient-care-col-4">
            <span className="patient-care-filled-input">{find(RolesConstants.PROFILE_ROLES_LIST, { id: account.role}).label}</span>
          </div>
          <div className="patient-care-col-4">
            <span className="patient-care-filled-input">{account.organizationName || locale.UserDetails.notAvailableDescription}</span>
          </div>
          <div className="patient-care-col-4">
            <span className="patient-care-filled-input">{isOrgAdmin ? locale.UserDetails.notAvailableDescription : account.branchName}</span>
          </div>
          <div className="patient-care-btn-delete-row" onClick={this.handleDeleteRole}>
            <LinkIcon
              to="#delete"
              size="small"
              icon={<IconClose outline />}
              accessibilitySpan={locale.UserDetails.deleteRoleIcon}
              onClick={this.handleDeleteRole}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleItem);
