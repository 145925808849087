import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Select from "react-select";

import { Form, FormItem, InputError } from "@patient-access/ui-kit";
import { ORGANISATION_DROPDOWN_LIST } from 'constants/OrganisationsConstants';
import locale from 'service/locale';

type Props = {
  handleOrganisationTypeChange: () => any,
  form: any,
}

type State = {
  organisation_type_select: boolean
}

const mapStateToProps = state => ({
  form: state.form,
});

class AddOrganisationType extends Component<Props, State> {

  state = {
    organisation_type_select: false
  }

  handleOrganisationTypeChange = value => {
    const { handleOrganisationTypeChange } = this.props;
    handleOrganisationTypeChange(value);
    this.setState({ organisation_type_select: false });
  };

  handleBlur = () => {
    this.setState({ organisation_type_select: true });
  };

  render() {
    const { form } = this.props;
    const { organisation_type_select } = this.state;
    const isOrganisationTypeError = organisation_type_select && !form.organisationType;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddOrganisation.type.title}</h3>
          <p>{locale.AddOrganisation.type.hint}</p>
        </div>
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Select
              id="organisation_type_select"
              name="organisation_type_select"
              placeholder={locale.AddOrganisation.type.placeHolder}
              onChange={this.handleOrganisationTypeChange}
              classNamePrefix="patient-care"
              className={`patient-care-select ${isOrganisationTypeError ? "patient-care-select-error" : ""}`}
              options={ORGANISATION_DROPDOWN_LIST}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          </div>
          <Form>
            <FormItem type="item">
              {isOrganisationTypeError && <InputError message={locale.AddOrganisation.type.selectError} />}
            </FormItem>
          </Form>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(AddOrganisationType);