import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from '@patient-access/ui-kit';

import * as RoutesConstants from 'constants/RoutesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  organisationDetails: any,
  branchId: string,
  currentRole: any,
};

const mapStateToProps = (state) => ({
  organisationDetails: state.organisationDetails,
  currentRole: state.roles.profileCurrentRole,
});

class FeatureContentBreadcrumbs extends Component<Props> {
  render() {
    const { organisationId, organisationDetails, branchId, currentRole } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrganizationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    const branchDetails = organisationDetails.branches.find(branch => branch.id === branchId);

    let breadcrumbs = {};
    if (isSuperAdmin) {
      breadcrumbs.home = {
        name: locale.Breadcrumbs.homeSuperAdmin,
        path: RoutesConstants.ADMIN_ORGANISATIONS
      };
      breadcrumbs.organisation = {
        name: organisationDetails && organisationDetails.name ? organisationDetails.name : locale.Breadcrumbs.loading,
        path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`
      };
      breadcrumbs.branch = branchDetails ? {
        name: organisationDetails && branchDetails ? branchDetails.name : locale.Breadcrumbs.loading,
        path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.USERS}`
      } : null
    }
    if (isOrganizationAdmin) {
      breadcrumbs.home = {
        name: locale.Breadcrumbs.homeOrganizationAdmin,
        path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`
      };
      breadcrumbs.branch = branchDetails ? {
        name: organisationDetails && branchDetails ? branchDetails.name : locale.Breadcrumbs.loading,
        path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.USERS}`
      } : null;
    }

    if (isSuperAdmin || isOrganizationAdmin) {
      return (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      );
    }
    return null;
  }
};

export default connect(mapStateToProps)(FeatureContentBreadcrumbs);
