import type { Action } from 'types/actions';
import * as CalendarDropdownConstants from 'constants/CalendarDropdownConstants';
import type { Organisation, Branch } from 'types/organisation';

export const setDropdownOrganisation = (organisation: Organisation): Action => ({
  type: CalendarDropdownConstants.SET_DROPDOWN_ORGANISATION,
  payload: organisation
});

export const setDropdownBranch = (branch: Branch): Action => ({
  type: CalendarDropdownConstants.SET_DROPDOWN_BRANCH,
  payload: branch
});

export const openCalendarDropdown = () => ({
  type: CalendarDropdownConstants.OPEN_DROPDOWN
});

export const closeCalendarDropdown = () => ({
  type: CalendarDropdownConstants.CLOSE_DROPDOWN
});

export const resetCalendarDropdown = () => ({
  type: CalendarDropdownConstants.RESET_DROPDOWN
});
