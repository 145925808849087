import React, { Component, Fragment } from "react";

import { ORGANISATION_TYPES_LIST } from 'constants/OrganisationsConstants';
import locale from 'service/locale';
import find from "lodash.find";

type Props = {
  organisationType: number
};

export default class OrganisationType extends Component<Props, State> {
  render() {
    const {
      organisationType
    } = this.props;

    const orgType = find(ORGANISATION_TYPES_LIST, ['type', organisationType])

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.OrganisationsSettings.type.title}</h3>
          </div>
          <p>{locale.OrganisationsSettings.type.hint}</p>
        </div>
        <div className="patient-care-block">
          <span className="patient-care-filled-input patient-care-filled-input-full">
            {orgType && orgType.label}
          </span>
        </div>
      </Fragment>
    )
  }
}