import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ReportsMain } from 'components/Pages/Reports/wrappers';
import Basic from './Basic/Basic';
import SmartPharmacy from './SmartPharmacy/SmartPharmacy';
import Custom from './Custom/Custom';
import * as RoutesConstants from 'constants/RoutesConstants';
import { default as ReportsSidebar } from 'components/Pages/Reports/Sidebar/Sidebar';
import './styles.scss';

class AdminPage extends Component {
  render() {
    return (
      <ReportsMain>
        <ReportsSidebar location={this.props.location} />
        <Switch>
          <Route exact path={`${RoutesConstants.REPORTS}/${RoutesConstants.BASIC}`} component={() => (<Basic />)} />
          <Route exact path={`${RoutesConstants.REPORTS}/${RoutesConstants.SMART_PHARMACY}`} component={() => (<SmartPharmacy />)} />
          <Route exact path={`${RoutesConstants.REPORTS}/${RoutesConstants.CUSTOM}`} component={() => (<Custom />)} />
        </Switch>
      </ReportsMain>
    );
  }
}

export default AdminPage;
