import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentScroll, FeatureContentHeader } from 'components/Share/feature';
import { SkeletonTable } from 'components/Share/skeleton';
import BranchSidebar from '../BranchSidebar/BranchSidebar';
import FilterServices from './FilterServices/FilterServices';
import FilterServicesSkeleton from './FilterServices/FilterServicesSkeleton';
import { getBranchServicesSummary, setUI, setUserData } from 'actions/branchServices';
import { columns, TableServicesSummary } from './TableServicesSummary/TableServicesSummary';
import { columnsAffiliates, TableServicesSummaryAffiliates } from './TableServicesSummary/TableServicesSummaryAffiliates';
import SidebarAddService from './SidebarAddService/SidebarAddService';
import SidebarEditService from './SidebarEditService/SidebarEditService';
import OverlayRemoveService from './OverlayRemoveService/OverlayRemoveService';
import OverlayServiceDescription from './OverlayServiceDescription/OverlayServiceDescription';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import * as SearchConstants from 'constants/SearchConstants';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  branchId: string,
  data: any,
  searchValue: string,
  isSuperAdmin: boolean,
  isOrganizationAdmin: boolean,
  isBranchMember: boolean,
  isAffiliate: boolean,
  isLoading: boolean,
  isError: boolean,
  getBranchServicesSummary: (organisationId: string, branchId: string) => any,
  setUI: (value: any) => any,
  setUserData: (data: any) => any,
};

const mapStateToProps = (state) => ({
  data: state.branchServices.servicesSummary.data,
  searchValue: state.branchServices.user.search.servicesSummary,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  isOrganizationAdmin: [RolesConstants.ORGANIZATION_ADMIN].includes(state.roles.profileCurrentRole.role),
  isBranchMember: [RolesConstants.BRANCH_MEMBER].includes(state.roles.profileCurrentRole.role),
  isAffiliate: state.organisationDetails.organisationType === OrganisationsConstants.ORGANISATION_TYPE.AFFILIATE,
  isLoading: state.branchServices.servicesSummary.isLoading,
  isError: state.branchServices.servicesSummary.isError,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getBranchServicesSummary: (organisationId, branchId) => dispatch(getBranchServicesSummary(organisationId, branchId)),
  setUI: (value: any) => dispatch(setUI(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
});

class BranchServices extends Component<Props> {
  componentDidMount = () => {
    const { getBranchServicesSummary, organisationId, branchId } = this.props;
    getBranchServicesSummary(organisationId, branchId);
  }

  render() {
    const { organisationId, branchId, data, searchValue, isSuperAdmin, isOrganizationAdmin, isBranchMember, isAffiliate, isLoading, isError, setUI } = this.props;
    const isBreadcrumbsVisible = (isSuperAdmin || isOrganizationAdmin);
    const isEmpty = data.length === 0;

    let dataFiltered = data;
    if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
      dataFiltered = data.filter(service => service.name.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const renderTableServicesSummary = () => {
      const isDataFilteredEmpty = dataFiltered.length === 0;
      if (isDataFilteredEmpty) {
        return (
          <NoResults type="info">
            <h2>{locale.BranchServicesRedesign.noResults.servicesSummaryFilter}</h2>
          </NoResults>
        );
      }
      if (isAffiliate) {
        return <TableServicesSummaryAffiliates data={dataFiltered} columns={columnsAffiliates} props={this.props} />;
      }
      return <TableServicesSummary data={dataFiltered} columns={columns} props={this.props} />;
    }

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <FeatureContentSticky className="smallest">
              <FeatureContentHeader className="spacing-medium">
                <FilterServicesSkeleton organisationId={organisationId} branchId={branchId} isBranchMember={isBranchMember} isBreadcrumbsVisible={isBreadcrumbsVisible} />
              </FeatureContentHeader>
            </FeatureContentSticky>
            <FeatureContentScroll className="smallest" isGreyBackground={false}>
              <SkeletonTable className="spacing-medium" columns={6} isSticky={true} isGrey={true} />
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.BranchServicesRedesign.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.SERVICES}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmpty) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.BranchServicesRedesign.noResults.servicesSummary}</h2>
                <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.addService} onClick={() => { setUI({ isSidebarAddVisible: true }); }} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <FilterServices organisationId={organisationId} branchId={branchId} isBranchMember={isBranchMember} isBreadcrumbsVisible={isBreadcrumbsVisible} />
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableServicesSummary()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    return (
      <Feature>
        <FeatureMenu>
          <BranchSidebar organisationId={organisationId} branchId={branchId} />
        </FeatureMenu>
        <FeatureContent>
          {renderContent()}
          <SidebarAddService organisationId={organisationId} branchId={branchId} />
          <SidebarEditService organisationId={organisationId} branchId={branchId} />
          <OverlayRemoveService organisationId={organisationId} branchId={branchId} />
          <OverlayServiceDescription />
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchServices);
