import { differenceBy, intersectionBy, concat } from "lodash";
import moment from "moment";

import type { Action } from 'types/actions';
import agent from 'service/agent';
import * as RoomsConstants from 'constants/RoomsConstants';
import * as PanelConstants from "constants/PanelConstants";
import { showSnackbarStatus } from 'actions/snackbar';
import { getToken } from "helpers/common";
import locale from 'service/locale';
import { setOrganisationHeader, setBranchHeader } from "./router";

export const setRoomsListAction = (roomsList: any[]): Action => ({
  type: RoomsConstants.SET_ROOMS_LIST,
  payload: roomsList
});

export const deleteRoomAction = (roomId: string): Action => ({
  type: RoomsConstants.DELETE_ROOM,
  payload: roomId
});

export const updateRoomAction = (id: string, name: string) => ({
  type: RoomsConstants.UPDATE_ROOM,
  payload: { id, name }
});

export const createRoomAction = ({id, name}) => ({
  type: RoomsConstants.CREATE_ROOM,
  payload: { id, name }
});

export const updateBranchRoomsPending = (): Action => ({
  type: RoomsConstants.UPDATE_BRANCH_ROOMS_PENDING,
});

export const updateBranchRoomsSuccess = (): Action => ({
  type: RoomsConstants.UPDATE_BRANCH_ROOMS_SUCCESS,
});

export const updateBranchRoomsError = (): Action => ({
  type: RoomsConstants.UPDATE_BRANCH_ROOMS_ERROR
});

export const openBranchErrorDuplicateRoom = () : Action => ({
  type: PanelConstants.OPEN_BRANCH_ERROR_DUPLICATED_ROOM,
});

export const closeBranchErrorDuplicateRoom = () : Action => ({
  type: PanelConstants.CLOSE_BRANCH_ERROR_DUPLICATED_ROOM,
});

export const getRoomsList = (organisationId: string, branchId: string) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchHeader(branchId));
  getToken()
    .then(accessToken => {
      agent.Rooms.getRoomsList(branchId, accessToken)
        .then(({items})=> {
          dispatch(setRoomsListAction(items));
        })
        .catch(err => {
          console.log('getRoomsList error', err);
          dispatch(showSnackbarStatus(locale.Snackbar.roomsLoadingError));
        });
    });

};

export const createRoom = ({id, name}) => (dispatch: Function, getState: Function) => {
  dispatch(createRoomAction({id, name}))
};

export const updateRoomNameById = (id: string, name: string) => (dispatch: Function, getState: Function) => {
  dispatch(updateRoomAction(id, name))
};

export const updateBranchRooms = (branchId: string) => (dispatch: Function, getState: Function) => {
  const rooms = getState().rooms;
  const router = getState().router;
  const roomsListInit = rooms.roomsListLoadedInitially || [];
  // filter out empty names
  const roomsList = (rooms.roomsList || []).filter((room) => room.name && room.name.length > 0 );

  // delete rooms if any (array of promises)
  const roomsToBeDeletedPromises =
    differenceBy(roomsListInit, roomsList, 'id')
    .map((room)=>{
      return getToken(dispatch)
        .then(accessToken => {
          return agent.Rooms.deleteRoom(room.id, accessToken);
        });
    });

  // create rooms if any (array of promises)
  const roomsToBeCreatedPromises =
    differenceBy(roomsList, roomsListInit, 'id')
    .map((room)=>{
      return getToken(dispatch)
        .then(accessToken => {
          return agent.Rooms.createRoom({branchId, name: room.name}, accessToken);
        });
    });

  // update rooms if any (array of promises)
  const roomsToBeUpdatedPromises =
      intersectionBy(roomsList, roomsListInit, 'id')
      .filter((room) => {
          const _room = roomsListInit.find((_room) => _room.id === room.id);
          return _room.name !== room.name;
      })
      .map((room)=>{
        return getToken(dispatch)
          .then(accessToken => {
            return agent.Rooms.updateRoom(branchId, room, accessToken);
          });
      });

  let promises = concat(
      roomsToBeCreatedPromises,
      roomsToBeDeletedPromises,
      roomsToBeUpdatedPromises
  );

  const successHandler = result => ({ payload: result, resolved: true });
  const catchHandler = error => ({ payload: error, resolved: false });
  promises = promises.map(result =>
    result
      .then(successHandler)
      .catch(catchHandler)
  );

  const catchPromiseAll = (err) => {
    dispatch(updateBranchRoomsError());

    const { body } = err[0].payload && err[0].payload.response;
    const errorMessage = body && body[RoomsConstants.roomNameDuplicateErrorKey];
    errorMessage
      ? dispatch(openBranchErrorDuplicateRoom())
      : dispatch(getRoomsList(router.organisationId, branchId));

    console.log("updateBranchRooms error", err);
    dispatch(showSnackbarStatus(locale.Snackbar.roomNotUpdated));
  };

  dispatch(updateBranchRoomsPending());

  Promise.all(promises)
  .then((values)=>{

    const errors = values.filter( (v) => v.resolved !== true );
    if (errors.length === 0) {
      dispatch(updateBranchRoomsSuccess());
      dispatch(getRoomsList(router.organisationId, branchId));
      dispatch(showSnackbarStatus(locale.Snackbar.roomUpdated));
    } else {
      catchPromiseAll(errors);
    }

  })
  .catch(catchPromiseAll);
};

export const setRoomFutureAppointmentsAction = (roomId: string | null, listOfAppointments: any[]): Action => ({
  type: RoomsConstants.SET_ROOM_FUTURE_APPOITNMENTS,
  payload: { roomId, listOfAppointments }
});


export const setRoomFutureAppointments = (roomId: string) => (dispatch: Function) => {
  dispatch(setRoomFutureAppointmentsAction(null, []));
  getToken(dispatch)
  .then(accessToken => {
    const today = moment.utc().add(1, "minutes").toISOString();
    agent.Rooms.getFutureAppointmentsForRoom(roomId, today, accessToken)
      .then((result) => {
        dispatch(setRoomFutureAppointmentsAction(roomId, result.appointmentsByDate));
      })
      .catch(() => {
        dispatch(setRoomFutureAppointmentsAction("", []));
      })
  })
};

