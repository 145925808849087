import React, { Component } from "react";
import { Overlay, Button } from "@patient-access/ui-kit";
import type { AppointmentStatusType } from "constants/AppointmentDetailsConstants";
import locale from "service/locale";
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";

type Props = {
  handleCancel: () => any,
  handleOk: () => any,
  price?: number,
  type: AppointmentStatusType,
  isOnline: boolean
};

export default class ServiceProvidedOverlay extends Component<Props> {
  render() {
    const { handleCancel, handleOk, price, type, isOnline } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          {type === AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.PROVIDED ? (
            <h1>{locale.Modals.serviceProvidedOverlay.header}</h1>
          ) : isOnline ? (
            <h1>{locale.Modals.serviceNotProvidedOverlayOnline.header}</h1>
          ) : (
            <h1>{locale.Modals.serviceNotProvidedOverlay.header}</h1>
          )}
        </div>
        <div className="overlay-confirmation-content">
          {type === AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.PROVIDED ? (
            <h4>{locale.Modals.serviceProvidedOverlay.content}</h4>
          ) : isOnline ? (
            <h4>{locale.Modals.serviceNotProvidedOverlayOnline.content}</h4>
          ) : (
            <h4>{locale.Modals.serviceNotProvidedOverlay.content}</h4>
          )}
          {type === AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.PROVIDED && !isOnline && (
            <h4>
              {locale.Modals.serviceProvidedOverlay.price}: £{price.toFixed(2)}
            </h4>
          )}
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="cancel-btn"
            defaultMessage={
              type === AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.PROVIDED
                ? locale.Modals.serviceProvidedOverlay.buttonCancel
                : isOnline
                ? locale.Modals.serviceNotProvidedOverlayOnline.buttonCancel
                : locale.Modals.serviceNotProvidedOverlay.buttonCancel
            }
            onClick={handleCancel}
            data-id="cancel-btn"
            className="patient-care-btn-in-group"
          />
          <Button
            buttonType="secondary"
            messageKey="ok-btn"
            defaultMessage={
              type === AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.PROVIDED
                ? locale.Modals.serviceProvidedOverlay.buttonOk
                : isOnline
                ? locale.Modals.serviceNotProvidedOverlayOnline.buttonOk
                : locale.Modals.serviceNotProvidedOverlay.buttonOk
            }
            onClick={handleOk}
            data-id="ok-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    );
  }
}
