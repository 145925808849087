import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconInfo extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,15a1,1,0,0,1-1-1V12a1,1,0,0,1,2,0v4A1,1,0,0,1,12,17Zm1-8H11V7h2Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-info ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconInfo;
