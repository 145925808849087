import React, { Component } from 'react';

import './styles/patient-care-feature-content-table.scss';

type Props = {
  children: any,
};

class FeatureContentTable extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <table className="patient-care-feature-content-table">
        {children}
      </table>
    );
  }
};

export default FeatureContentTable;
