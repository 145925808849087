import { setOrganisationHeader, setBranchHeader } from 'actions/router';
import { showSnackbarStatus } from 'actions/snackbar';
import * as BranchesConstants from 'constants/BranchesConstants';
import * as BranchServicesConstants from 'constants/BranchServicesConstants';
import { getToken } from '../helpers/common';
import agent from 'service/agent';
import locale from 'service/locale';
import moment from "moment";

// UI
export const setUI = (value: string): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_UI,
  payload: value,
});

// USER
export const setUserSearch = (value: string): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_USER_SEARCH,
  payload: value,
});

export const setUserStep = (value: any): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_USER_STEP,
  payload: value,
});

export const setUserData = (data: any): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_USER_DATA,
  payload: data,
});

export const resetUserData = (): Action => ({
  type: BranchServicesConstants.RESET_BRANCH_SERVICES_USER_DATA,
});

// SERVICES_SUMMARY
export const setBranchServicesSummaryPending = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_SUMMARY_PENDING,
});

export const setBranchServicesSummarySuccess = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_SUMMARY_SUCCESS,
});

export const setBranchServicesSummaryError = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_SUMMARY_ERROR,
});

export const setBranchServicesSummaryData = (data: any): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_SUMMARY_DATA,
  payload: data,
});

export const getBranchServicesSummary = (organisationId: string, branchId: string) => (dispatch: Function) => {
  dispatch(setBranchServicesSummaryPending());
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchHeader(branchId));
  getToken(dispatch)
    .then(accessToken => {
      branchId !== BranchesConstants.DEFAULT_BRANCHID &&
      agent.Branches.getBranchDetails(branchId, accessToken)
        .then(branchDetails => {
          dispatch(setBranchServicesSummaryData(branchDetails.services));
          dispatch(setBranchServicesSummarySuccess());
        })
        .catch(err => {
          dispatch(setBranchServicesSummaryError());
          console.log("getBranchServicesSummary server error or branch is not found", err);
        });
    });
};

// SERVICES_LIST
export const setBranchServicesListPending = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_LIST_PENDING,
});

export const setBranchServicesListSuccess = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_LIST_SUCCESS,
});

export const setBranchServicesListError = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_LIST_ERROR,
});

export const setBranchServicesListData = (data: any): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_LIST_DATA,
  payload: data,
});

export const getBranchServicesList = () => (dispatch: Function) => {
  dispatch(setBranchServicesListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.getAllServicesList(accessToken)
        .then(allServicesList => {
          dispatch(setBranchServicesListData(allServicesList.items));
          dispatch(setBranchServicesListSuccess());
        })
        .catch(err => {
          dispatch(setBranchServicesListError());
          console.log("getBranchServicesList server error or branch is not found", err);
        });
    });
};

// SERVICES_UPDATE
export const setBranchServicesUpdatePending = (): Action => ({
  type: BranchServicesConstants.PUT_BRANCH_SERVICES_UPDATE_PENDING,
});

export const setBranchServicesUpdateSuccess = (): Action => ({
  type: BranchServicesConstants.PUT_BRANCH_SERVICES_UPDATE_SUCCESS,
});

export const setBranchServicesUpdateError = (): Action => ({
  type: BranchServicesConstants.PUT_BRANCH_SERVICES_UPDATE_ERROR,
});

export const putBranchServicesUpdate = (organisationId: string, branchId: string, servicesList: any[], snackbarText: string) => (dispatch: Function) => {
  dispatch(setBranchServicesUpdatePending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.updateBranchServicesList(
        branchId,
        servicesList,
        accessToken,
      )
      .then(data => {
        dispatch(setBranchServicesUpdateSuccess());
        dispatch(showSnackbarStatus(snackbarText));
        dispatch(getBranchServicesSummary(organisationId, branchId));
        dispatch(resetUserData());
        dispatch(setUserStep(1));
        dispatch(setUI({ isSidebarAddVisible: false, isSidebarEditVisible: false, isOverlayRemoveServiceVisible: false }));
      })
      .catch(err => {
        dispatch(setBranchServicesUpdateError());
        dispatch(showSnackbarStatus(locale.Snackbar.branchNotUpdated));
        dispatch(setUI({ isSidebarAddVisible: false, isSidebarEditVisible: false, isOverlayRemoveServiceVisible: false }));
        console.log("putBranchServicesUpdate server error or branch was not found", err);
      });
    });
};

// SERVICE_DESCRIPTION
export const setServiceDescriptionPending = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_DESCRIPTION_PENDING,
});

export const setServiceDescriptionSuccess = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_DESCRIPTION_SUCCESS,
});

export const setServiceDescriptionError = (): Action => ({
  type: BranchServicesConstants.GET_BRANCH_SERVICES_DESCRIPTION_ERROR,
});

export const setServiceDescriptionServiceId = (serviceId: string): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_DESCRIPTION_SERVICE_ID,
  payload: serviceId,
});

export const setServiceDescriptionData = (data: any): Action => ({
  type: BranchServicesConstants.SET_BRANCH_SERVICES_DESCRIPTION_DATA,
  payload: data,
});

export const getServiceDescription = (serviceId: string) => (dispatch: Function) => {
  dispatch(setServiceDescriptionServiceId(serviceId));
  dispatch(setServiceDescriptionPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.getPPLServiceDetails(
        serviceId,
        accessToken,
      )
      .then(data => {
        dispatch(setServiceDescriptionData(data));
        dispatch(setServiceDescriptionSuccess());
      })
      .catch(err => {
        dispatch(setServiceDescriptionError());
        console.log("getServiceDescription server error or branch was not found", err);
      });
    });
};

//SERVICE_REMOVE
export const setServiceFutureAppointmentsAction = (serviceId: string | null, listOfAppointments: any[]): Action => ({
  type: BranchServicesConstants.SET_SERVICE_FUTURE_APPOINTMENTS,
  payload: { serviceId, listOfAppointments }
});


export const setServiceFutureAppointments = (serviceId: string) => (dispatch: Function) => {
  dispatch(setServiceFutureAppointmentsAction(null, []));
  getToken(dispatch)
    .then(accessToken => {
      const today = moment.utc().add(1, "minutes").toISOString();
      agent.Branches.getFutureAppointmentsForService(serviceId, today, accessToken).then((result) => {
        dispatch(setServiceFutureAppointmentsAction(serviceId, result.appointmentsByDate));
      })
    })
};
