import React, { Component } from 'react';
import { Form } from '@patient-access/ui-kit';
import './styles/calendar-filters-section.scss';

type Props = {
  children: any,
};

class CalendarFiltersSection extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="calendar-filters-section">
        <Form noValidate>
          {children}
        </Form>
      </div>
    );
  }
};

export default CalendarFiltersSection;
