import * as CalendarsConstants from 'constants/CalendarsConstants';
import type {
  Action
} from 'types/actions';
import type {
  Calendar
} from 'types/calendars';

const initialState: Calendar = {
  calendars: {
    items: [],
    totalCount: 0,
    itemsPerPage: 30,
    pageNumber: 1,
    pages: 1,
    isEmpty: true,
  },
  openHours: CalendarsConstants.DefaultOpenHours,
  closedDays: [],
  blockedDays: [],
  bookingCutoff: {
    advance: CalendarsConstants.AdvancedOptions[3],
  },
  isGetPending: false,
  isUpdatePending: false,
  isUpdateCalendarPending: false,
  newBlockedPeriods: [],
  isUpdateConflictOverlayOpened: false,
  appointmentsWithConflict: [],
  isConfirmDeletionBlockedOpen: false,
  isTryingBlockToday: false,
  internalEvent: {},
  isDeleteEventPending: false,
  isInternalEventPending: false,
  isBranchTimeOffEnabled: false,
  timeOff: 0,
  isBranchControlToggle: false

};

export default (localState: Calendar = initialState, action: Action): Calendar => {
  switch (action.type) {
    case CalendarsConstants.SET_OPEN_HOURS:
      return {
        ...localState,
        openHours: action.payload.length ? action.payload : initialState.openHours,
      };
    case CalendarsConstants.SET_TIME_OFF_MINUTES:
      return {
        ...localState,
        timeOff: action.payload.timeOff ? action.payload.timeOff : initialState.timeOff,
        isBranchTimeOffEnabled: action.payload.isTimeOffEnabled ? action.payload.isTimeOffEnabled : initialState.isBranchTimeOffEnabled,
        isBranchControlToggle: action.payload.isBranchControlTimeOff ? action.payload.isBranchControlTimeOff : initialState.isBranchControlToggle,

      };
    case CalendarsConstants.ADD_BLOCKED_PEROID:
      return {
        ...localState,
        newBlockedPeriods: [...localState.newBlockedPeriods, action.payload]
      };
    case CalendarsConstants.CLEAR_BLOCKED_PEROIDS:
      return {
        ...localState,
        newBlockedPeriods: []
      };
    case CalendarsConstants.DELETE_BLOCKED_PEROID:
      const deletedPeriodIndex = localState.newBlockedPeriods.findIndex(period => period.id === action.payload);
      return {
        ...localState,
        newBlockedPeriods: [
          ...localState.newBlockedPeriods.slice(0, deletedPeriodIndex),
          ...localState.newBlockedPeriods.slice(deletedPeriodIndex + 1)
        ]
      };
    case CalendarsConstants.SET_CLOSED_DAYS:
      return {
        ...localState,
        closedDays: action.payload.length ? action.payload : initialState.closedDays,
      };
    case CalendarsConstants.SET_BOOKING_CUTOFF:
      return {
        ...localState,
        bookingCutoff: action.payload ? action.payload : initialState.bookingCutoff,
      };
    case CalendarsConstants.SET_BLOCKED_DAYS:
      return {
        ...localState,
        blockedDays: action.payload ? action.payload : initialState.blockedDays,
      };
    case CalendarsConstants.GET_AVAILABILITY_PENDING:
      return {
        ...localState,
        isGetPending: true,
      };
    case CalendarsConstants.GET_AVAILABILITY_SUCCESS:
      return {
        ...localState,
        isGetPending: false,
      };
    case CalendarsConstants.GET_AVAILABILITY_ERROR:
      return {
        ...localState,
        isGetPending: false,
      };
    case CalendarsConstants.UPDATE_AVAILABILITY_PENDING:
      return {
        ...localState,
        isUpdatePending: true,
      };
    case CalendarsConstants.UPDATE_AVAILABILITY_SUCCESS:
      return {
        ...localState,
        isUpdatePending: false,
      };
    case CalendarsConstants.UPDATE_AVAILABILITY_ERROR:
      return {
        ...localState,
        isUpdatePending: false,
      };
    case CalendarsConstants.UPDATE_CALENDAR_PENDING:
      return {
        ...localState,
        isUpdateCalendarPending: true,
      };
    case CalendarsConstants.UPDATE_CALENDAR_SUCCESS:
      return {
        ...localState,
        isUpdateCalendarPending: false,
      };
    case CalendarsConstants.UPDATE_CALENDAR_ERROR:
      return {
        ...localState,
        isUpdateCalendarPending: false,
      };
    case CalendarsConstants.OPEN_UPDATE_AVAILABILITY_CONFLICT:
      return {
        ...localState,
        isUpdateConflictOverlayOpened: true,
          appointmentsWithConflict: action.payload
      };
    case CalendarsConstants.CLOSE_UPDATE_AVAILABILITY_CONFLICT: {
      return {
        ...localState,
        isUpdateConflictOverlayOpened: false,
        appointmentsWithConflict: []
      }
    }
    case CalendarsConstants.OPEN_CONFIRM_DELETION_BLOCKED:
      return {
        ...localState,
        isConfirmDeletionBlockedOpen: true,
      };
    case CalendarsConstants.CLOSE_CONFIRM_DELETION_BLOCKED:
      return {
        ...localState,
        isConfirmDeletionBlockedOpen: false,
      };
    case CalendarsConstants.OPEN_ADD_BLOCKED_TODAY:
      return {
        ...localState,
        isTryingBlockToday: true,
      };
    case CalendarsConstants.CLOSE_ADD_BLOCKED_TODAY:
      return {
        ...localState,
        isTryingBlockToday: false,
      };
    default:
      return localState
  }
}
