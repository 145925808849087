import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button, Form, FormItem, SearchFilter, Checkbox, IconSearch } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarBodySection, SidebarBodyHeader, SidebarBodyContent, SidebarFooter } from 'components/Share/sidebar';
import { SkeletonSearchFilter, SkeletonTable } from 'components/Share/skeleton';
import { getBranchServicesList, getServiceDescription, setUserSearch, setUserData, setUserStep } from 'actions/branchServices';
import { columns, TableServicesList } from '../../TableServicesList/TableServicesList';
import * as SearchConstants from 'constants/SearchConstants';
import locale from 'service/locale';

type Props = {
  searchValue: string,
  dataSummary: any,
  dataList: any,
  dataUser: any,
  isLoading: boolean,
  isError: boolean,
  getBranchServicesList: () => any,
  getServiceDescription: (serviceId: string) => any,
  setUserSearch: (value: string) => any,
  setUserData: (data: any) => any,
  setUserStep: (value: any) => any,
  handleClose: any,
};

type State = {
  isCheckboxChecked: boolean,
  isValidationError: boolean,
};

const mapStateToProps = (state) => ({
  searchValue: state.branchServices.user.search.servicesList,
  dataSummary: state.branchServices.servicesSummary.data,
  dataList: state.branchServices.servicesList.data,
  dataUser: state.branchServices.user.data,
  isLoading: state.branchServices.servicesList.isLoading,
  isError: state.branchServices.servicesList.isError,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getBranchServicesList: () => dispatch(getBranchServicesList()),
  getServiceDescription: (serviceId: string) => dispatch(getServiceDescription(serviceId)),
  setUserSearch: (value: string) => dispatch(setUserSearch(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
});

class StepOne extends Component<Props, State> {
  state = {
    isCheckboxChecked: false,
    isValidationError: false,
  }

  componentDidMount = () => {
    const { getBranchServicesList } = this.props;
    getBranchServicesList();
  }

  componentWillUnmount = () => {
    const { setUserSearch } = this.props;
    setUserSearch({ servicesList: '' });
  }

  render() {
    const { searchValue, dataSummary, dataList, dataUser, isLoading, isError, setUserSearch, setUserStep, handleClose } = this.props;
    const { isCheckboxChecked, isValidationError } = this.state;

    // DATA_MASSAGE
    if (dataList.length) {
      dataList.forEach(service => {
        if (dataSummary.findIndex(branchService => branchService.id === service.id) !== -1) {
          service.isAlreadyAdded = true;
        }
      });
    }

    let dataChecked = dataList;
    if (isCheckboxChecked) {
      dataChecked = dataList.filter(service => !service.isAlreadyAdded);
    }

    let dataFiltered = dataChecked;
    if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
      dataFiltered = dataChecked.filter(service => service.name.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const isDataFilteredEmpty = dataFiltered.length === 0;

    const handleValidation = (isSelected) => {
      this.setState({ isValidationError: !isSelected });
    }

    const handleContinueButton = () => {
      if (dataUser.serviceId) {
        setUserStep(2);
      } else {
        this.setState({ isValidationError: true });
      }
    }

    const renderTableServicesList = () => {
      if (isDataFilteredEmpty) {
        return (
          <NoResults type="info">
            <h2>{locale.BranchServicesRedesign.noResults.servicesSummaryFilter}</h2>
          </NoResults>
        );
      }
      return (
        <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
          <FormItem type="item" error={isValidationError ? 'error' : null}>
            <TableServicesList data={dataFiltered} columns={columns} props={this.props} handleValidation={handleValidation} />
          </FormItem>
        </Form>
      );
    }

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.BranchServicesRedesign.filter.servicesList.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <div className="row">
                  <SkeletonSearchFilter />
                </div>
              </SidebarBodyContent>
              <SidebarBodyContent>
                <SkeletonTable columns={1} isSticky={false} isGrey={false} />
              </SidebarBodyContent>
            </SidebarBodySection>
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <NoResults type="info">
              <h2>{locale.BranchServicesRedesign.error.generic}</h2>
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.tryAgain} onClick={() => {}} />
            </NoResults>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <SidebarBodySection className={isDataFilteredEmpty ? 'sidebar-body-section-empty' : ''}>
            <SidebarBodyHeader>
              <h3>{locale.BranchServicesRedesign.filter.servicesList.header}</h3>
            </SidebarBodyHeader>
            <SidebarBodyContent>
              <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                <div className="row row-inline">
                  <SearchFilter
                    id="searchServicesList"
                    name="searchServicesList"
                    placeholder={locale.BranchServicesRedesign.filter.servicesList.placeholder}
                    autoComplete="off"
                    value={searchValue}
                    clearHandler={() => { setUserSearch({ servicesList: '' }); }}
                    onChange={(e) => { setUserSearch({ servicesList: e.target.value }); }}
                    icon={<IconSearch outline />}
                  />
                  <Checkbox
                    id="hideAdded"
                    name="hideAdded"
                    value="hideAdded"
                    label={locale.BranchServicesRedesign.filter.servicesList.labelHideAdded}
                    onChange={(e) => { this.setState({ isCheckboxChecked: e.target.checked }); }}
                  />
                </div>
              </Form>
            </SidebarBodyContent>
            <SidebarBodyContent>
              {renderTableServicesList()}
            </SidebarBodyContent>
          </SidebarBodySection>
        </Fragment>
      );
    }

    const renderContinueButton = () => {
      if (!isError) {
        return (
          <Button
            className={dataUser.serviceId ? '' : 'ui-kit-button-disabled-visually'}
            buttonType="secondary"
            messageKey="some-key"
            defaultMessage={locale.BranchServicesRedesign.button.continue}
            onClick={() => { handleContinueButton(); }}
            isLoading={isLoading}
          />
        );
      }
      return null;
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.BranchServicesRedesign.sidebar.addService.header}</h2>
        </SidebarHeader>
        <SidebarBody>
          {renderContent()}
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column">
              {isValidationError ? <p className="error">{locale.BranchServicesRedesign.validationError.selectService}</p> : null}
            </div>
            <div className="column">
              <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.cancel} onClick={handleClose} />
              {renderContinueButton()}
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
