import React, { Fragment } from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import IconSort from 'svg/IconSort';
import locale from 'service/locale';
import './styles.scss';

export const dataServicesAddService = [
  { serviceName: 'Asthma - emergency inhaler supply' },
  { serviceName: 'Back pain - tablets', isAlreadyAdded: true },
  { serviceName: 'Chlamydia treatment' },
  { serviceName: 'Service name' },
  { serviceName: 'Cholera - vaccination' },
  { serviceName: 'COVID 19 PCR test' },
  { serviceName: 'Asthma' },
  { serviceName: 'Hepatitis A - vaccination' },
  { serviceName: 'Impetigo treatment' },
  { serviceName: 'Flu vaccination' },
  { serviceName: 'Male hair loss - tablets' },
  { serviceName: 'Period delay - tablets' },
  { serviceName: 'Surgical operations' },
  { serviceName: 'Specialist medical treatments' },
  { serviceName: 'Maternity and neonatal' },
  { serviceName: 'Pathology' },
  { serviceName: 'Complex dental procedures', isAlreadyAdded: true },
  { serviceName: 'Liaison psychiatry' },
  { serviceName: 'IVF clinics providing surgical treatment or endoscopy' },
  { serviceName: 'Minor injuries units', isAlreadyAdded: true },
];

export const columnsServicesAddService = [
  { Header: locale.OrganisationServices.table.servicesList.headers.serviceName, accessor: 'serviceName', Cell: ({ cell: { value } }) => value },
];

export function TableServicesAddService({ columns, data, props }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'serviceName', desc: false }],
      },
    },
    useSortBy,
    useRowSelect,
  );

  return (
    <Fragment>
      <table className="react-table react-table-classNameTwo" {...getTableProps()}>
        <thead className="white">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                    <span>{column.render('Header')}</span>
                    {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        <div className="input-radio">
                          <label htmlFor={row.original.serviceName}>
                            <input type="radio" id={row.original.serviceName} name="add_service" disabled={row.original.isAlreadyAdded ? 'disabled' : null} onClick={() => { props.updateForm({ selectedService: row.id }); }} />
                            <span>{cell.render('Cell')}{row.original.isAlreadyAdded ? locale.OrganisationServices.filter.servicesList.labelAlreadyAdded : null}</span>
                          </label>
                        </div>
                        <a href="#description" onClick={(e) => { e.preventDefault(); props.updateForm({ isOverlayDescriptionVisible: true }); }}><span>{locale.OrganisationServices.sidebar.serviceDescription}</span></a>
                      </td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
