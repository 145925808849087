import React, { Component } from 'react';
import './styles/reports-sidebar.scss';

type Props = {
  isExpand: boolean,
  children: any,
};

class ReportsSidebar extends Component<Props> {
  render() {
    const { isExpand, children } = this.props;
    return (
      <div className={`reports-sidebar ${isExpand ? 'reports-sidebar-expand' : 'reports-sidebar-contract'}`}>
        {children}
      </div>
    );
  }
};

export default ReportsSidebar;
