import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Overlay } from "@patient-access/ui-kit";
import locale from 'service/locale';

type Props = {
    handleCloseMFAOverlay: () => any;
    handleSaveMFAOverlay: () => any;
    twoFactorOperation: boolean,
    isUpdatingOrganisationSettingsPending: boolean
};

const mapStateToProps = (state) => {
  return {
    isUpdatingOrganisationSettingsPending: state.organisationDetails.isUpdatingOrganisationSettingsPending
  }
}

class OrganisationMFAModal extends Component<Props> {
  render() {
    const { handleCloseMFAOverlay, handleSaveMFAOverlay, twoFactorOperation, isUpdatingOrganisationSettingsPending } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="mfa-status-confirm-overlay"
        doClose={handleCloseMFAOverlay}
      >
        <div className="overlay-confirmation-header">
          <h1>{twoFactorOperation ? locale.AddOrganisation.twoFactorConfirmOverlay.add_title : locale.AddOrganisation.twoFactorConfirmOverlay.remove_title}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{twoFactorOperation ? locale.AddOrganisation.twoFactorConfirmOverlay.add_desc : locale.AddOrganisation.twoFactorConfirmOverlay.remove_desc}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="go-back-btn"
            defaultMessage={locale.AddOrganisation.twoFactorConfirmOverlay.back}
            onClick={handleCloseMFAOverlay}
            isLoading={isUpdatingOrganisationSettingsPending}
            data-id="go-back-btn"
          />
          <Button
            buttonType="secondary"
            messageKey="confirm-mfa-status-btn"
            defaultMessage={locale.AddOrganisation.twoFactorConfirmOverlay.save}
            onClick={handleSaveMFAOverlay}
            isLoading={isUpdatingOrganisationSettingsPending}
            data-id="confirm-mfa-status-btn"
          />
        </div>
      </Overlay>
    );
  }
}

export default connect(mapStateToProps)(OrganisationMFAModal);
