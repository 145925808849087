import React, { Component, Fragment } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import isEqual from 'lodash.isequal';
import { connect } from 'react-redux';
import { Skeleton, Form } from '@patient-access/ui-kit';

import { getOrganisationDetails } from 'actions/organisationDetails';
import { getAvailabilityRules, setTimeOffOverlayVisibility, setTimeOffDataUpdate } from 'actions/availabilityRules';

import FloatingFooterButtons from 'components/Share/FloatingFooterButtons/FloatingFooterButtons';
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';

import TimeOffSection from './TimeOffSection/TimeOffSection';
import TimeOffOverlay from './TimeOffOverlay/TimeOffOverlay';

import './styles.scss';

type State = {
  isEditMode: boolean,
  isOverlayVisible: boolean,
};

type Props = {
  data: any,
  dataClone: any,
  organisationId: string,
  getOrganisationDetails: (organisationId: string) => Action,
  getAvailabilityRules: (organisationId: string) => Action,
  setTimeOffOverlayVisibility: (isOverlayVisible: boolean) => Action,
  setTimeOffDataUpdate: (data: any) => Action,
  isGetLoading: boolean,
};

const mapStateToProps = state => ({
  data: state.availabilityRules.data,
  dataClone: state.availabilityRules.dataClone,
  isGetLoading: state.availabilityRules.isGetLoading,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  getOrganisationDetails: (organisationId: string) => dispatch(getOrganisationDetails(organisationId)),
  getAvailabilityRules: (organisationId: string) => dispatch(getAvailabilityRules(organisationId)),
  setTimeOffOverlayVisibility: (isOverlayVisible: boolean) => dispatch(setTimeOffOverlayVisibility(isOverlayVisible)),
  setTimeOffDataUpdate: (data: any) => dispatch(setTimeOffDataUpdate(data)),
});

class AvailabilityRules extends Component<Props, State> {
  state = {
    isEditMode: false,
    isOverlayVisible: false,
  }

  componentDidMount = () => {
    const { getAvailabilityRules, getOrganisationDetails, organisationId } = this.props;
    getOrganisationDetails(organisationId);
    getAvailabilityRules(organisationId);
  }

  handleChangeMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  }

  render() {
    const { data, dataClone, organisationId, setTimeOffOverlayVisibility, setTimeOffDataUpdate, isGetLoading } = this.props;
    const { isEditMode, isOverlayVisible } = this.state;

    if (data && data.timeOff) {
      const isDataChanged = !isEqual(data, dataClone);
      // validation
      let isDataValid = true;
      if (data.timeOff.isTimeOffEnabled && data.timeOff.timeOff === 0) isDataValid = false;

      const renderDiscardChangesOverlay = () => {
        if (isOverlayVisible) {
          return (
            <DiscardChangesOverlay
              handleDiscardChanges={() => { setTimeOffDataUpdate(dataClone.timeOff); this.setState({ isEditMode: false, isOverlayVisible: false }); }}
              handleStay={() => { this.setState({ isOverlayVisible: false }); }}
            />
          );
        }
        return null;
      }

      const renderFloatingFooterButtons = () => {
        if (isDataChanged) {
          return (
            <FloatingFooterButtons
              handleDiscardChanges={() => { this.setState({ isOverlayVisible: true }); }}
              proceedFunction={() => { setTimeOffOverlayVisibility(true); }}
              isEditMode={!isDataValid}
              isLoading={isGetLoading}
            />
          );
        }
        return null;
      }

      return (
        <Fragment>
          <div className={isDataChanged ? 'patient-care-details-holder data-changed' : 'patient-care-details-holder'}>
            <div className="patient-care-container-sm">
              <Form>
                <TimeOffSection data={data.timeOff} isEditMode={isEditMode} handleChangeMode={this.handleChangeMode} />
              </Form>
              <TimeOffOverlay data={data.timeOff} organisationId={organisationId} handleChangeMode={this.handleChangeMode} />
            </div>
          </div>
          {renderDiscardChangesOverlay()}
          {renderFloatingFooterButtons()}

          <NavigationPrompt when={isDataChanged} afterConfirm={() => { setTimeOffDataUpdate(dataClone.timeOff); }}>
            {({ onConfirm, onCancel }) => (
              <DiscardChangesOverlay
                handleDiscardChanges={onConfirm}
                handleStay={onCancel}
              />
            )}
          </NavigationPrompt>
        </Fragment>
      );
    }
    return (
      <div className="patient-care-details-holder">
        <div className="patient-care-container-sm">
          <Skeleton type="basic" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityRules);
