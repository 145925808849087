import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconCapsule, IconShop } from '@patient-access/ui-kit';

import SectionBookingsPharmacy from '../SectionBookings/SectionBookingsPharmacy';
import SectionBookingsOther from '../SectionBookings/SectionBookingsOther';

import SectionFeaturePharmacyNominations from '../SectionFeature/SectionFeaturePharmacyNominations';
import SectionFeaturePharmacyManagement from '../SectionFeature/SectionFeaturePharmacyManagement';
import SectionFeaturePharmacyBusiness from '../SectionFeature/SectionFeaturePharmacyBusiness';
import SectionFeatureOtherManagement from '../SectionFeature/SectionFeatureOtherManagement';
import SectionFeatureOtherBusiness from '../SectionFeature/SectionFeatureOtherBusiness';
import SectionPackage from '../SectionPackage/SectionPackage';
import './styles.scss';

type State = {
  activeContent: string,
};

class SectionTabs extends Component<State> {
  state = {
    activeContent: 'pharmacy',
  }

  render() {
    const { activeContent } = this.state;

    const renderContent = (activeContent: string) => {
      switch (activeContent) {
        case 'pharmacy':
          return renderPharmacyContent();
        case 'other':
          return renderAlliedContent();
        default:
          return renderPharmacyContent();
      }
    }

    const renderPharmacyContent = () => {
      return (
        <Fragment>
          <SectionFeaturePharmacyNominations />
          <SectionBookingsPharmacy />
          <SectionFeaturePharmacyManagement />
          <SectionFeaturePharmacyBusiness />
          <SectionPackage />
        </Fragment>
      );
    }

    const renderAlliedContent = () => {
      return (
        <Fragment>
          <SectionBookingsOther />
          <SectionFeatureOtherManagement />
          <SectionFeatureOtherBusiness />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="section-block section-tabs">
          <div className="wrapper section-tabs-wrapper">
            <div className="section-tabs-header">
              <p><FormattedMessage id="landing.tabs.header" /></p>
            </div>
            <div className="section-tabs-content">
              <a className={activeContent === 'pharmacy' ? 'active' : ''} href="#pharmacy" onClick={(e) => { e.preventDefault(); this.setState({ activeContent: 'pharmacy' }); }}>
                <IconCapsule outline={false} />
                <span><FormattedMessage id="landing.tabs.tab_one" /></span>
              </a>
              <a className={activeContent === 'other' ? 'active' : ''} href="#other" onClick={(e) => { e.preventDefault(); this.setState({ activeContent: 'other' }); }}>
                <IconShop outline={false} />
                <span><FormattedMessage id="landing.tabs.tab_two" /></span>
              </a>
            </div>
          </div>
        </div>
        {renderContent(activeContent)}
      </Fragment>
    );
  }
}

export default SectionTabs;
