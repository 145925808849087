import React, { Component } from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import find from 'lodash.find';
import TimeInterval from "components/Pages/Admin/BranchContent/TimeInterval/TimeInterval" 
import { updateForm } from "actions/form";
import * as CalendarsConstants from 'constants/CalendarsConstants';
import type { Action } from 'types/actions';
import type { OpenHours } from "types/calendars";
import locale from 'service/locale';

type Props = {
  day: OpenHours,
  form: any,
  updateForm: (data: any) => Action,
  handleEditTime: () => Action,
};

type State = {
  isOpen: boolean,
  startTime: string,
  endTime: string,
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class EditDaySettings extends Component<Props, State> {

  state = {
    isOpen: false,
    startTime: "",
    endTime: "",
  };

  handleSelectStatus = (e: Event) => {
    const { value } = e;
    const { updateForm, form, day } = this.props;
    const changedOpenHours = form.openHours.slice(0);
    const index = form.openHours && form.openHours.findIndex(date => date.day === day.day);
    const daySettings = {
      day: day.day,
      timesSessions: value ? [
        {
          from: CalendarsConstants.EMPTY_VALUE,
          to: CalendarsConstants.EMPTY_VALUE
        }
      ] : []
    };

    changedOpenHours.splice(index, 1, daySettings);
    this.setState({
      isOpen: value,
    }, () => {
      updateForm({
        openHours: changedOpenHours,
      });
    });
  };

  render() {
    const { day, handleEditTime } = this.props;

    return (
      <div className="patient-care-block-row">
        <div className="patient-care-day-col">
          <span className="patient-care-day">{locale.AvailabilitySettings.daysOfWeek[day.day]}</span>
          <Select
            id={`${day.day}IsOpen`}
            name="isOpen"
            options={CalendarsConstants.AvailabilityOptions}
            defaulOption={CalendarsConstants.AvailabilityOptions[0]}
            onChange={this.handleSelectStatus}
            value={find(CalendarsConstants.AvailabilityOptions, { value: !!day.timesSessions.length })}
            classNamePrefix="patient-care"
            className={`patient-care-select`}
          />
        </div>
        <div className="patient-care-time-col edited">
          {
            day.timesSessions && day.timesSessions.map((timeSession, index) => {
              return (
                <TimeInterval
                  intervalIndex={index}
                  timesSessions={day.timesSessions}
                  dayOfWeek={day.day}
                  handleEditTime={handleEditTime}
                  key={index} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (EditDaySettings)
