import React, { Component } from 'react';
import Select from 'react-select';
import { FormItem, InputError } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import { lastMinuteOptions } from 'constants/BranchesConstants';
import locale from 'service/locale';

type Props = {
  userData: any,
  branchServiceData: any,
  isError: boolean,
  isAffiliate: boolean,
  setUserData: any,
  handleValidateCutOff: any,
  isFormEdited: any,
};

class SectionCutOff extends Component<Props,State> {

  render() {
    const { userData, branchServiceData, isError, isAffiliate, setUserData, handleValidateCutOff, isFormEdited } = this.props;

    const handleCutOff = (e: any) => {
      setUserData({ serviceCutOff: Number(e.value) });
      handleValidateCutOff(true);
      if (branchServiceData.lastMinute !== Number(e.value)) {
        isFormEdited(true);
      } else { isFormEdited(false); }
    }

    const handleUndoChanges = () => {
      isFormEdited(false);
      setUserData({ serviceCutOff: Number(branchServiceData.lastMinute) });
    }

    const isCutOffModified = () => {
      if (branchServiceData.lastMinute !== userData.serviceCutOff) {
        return true;
      } return false;
    }

    if (!isAffiliate) {
      return (
        <SidebarBodySection>
          <SidebarBodyHeader>
            {isCutOffModified() ? <button onClick={handleUndoChanges}> {locale.BranchServicesRedesign.button.undoChanges}</button> : null}
            <h3>{locale.BranchServicesRedesign.section.bookingCutOff.header}</h3>
            <p>{locale.BranchServicesRedesign.section.bookingCutOff.description}</p>
          </SidebarBodyHeader>
          <SidebarBodyContent>
            <FormItem type="item" error={isError ? 'error' : null}>
              <div className="row row-inline">
                <Select
                  id="bookingCutOff"
                  name="bookingCutOff"
                  className={`patient-care-select select-number ${isError ? 'patient-care-select-error' : ''}`}
                  classNamePrefix="patient-care"
                  placeholder={locale.BranchServicesRedesign.section.bookingCutOff.placeholder}
                  options={lastMinuteOptions}
                  onChange={handleCutOff}
                  value={lastMinuteOptions.find(option => option.value === userData.serviceCutOff)}
                />
              </div>
              {isError ? <InputError message={locale.BranchServicesRedesign.validationError.cutOff} /> : null}
            </FormItem>
          </SidebarBodyContent>
        </SidebarBodySection>
      );
    }
    return null;
  }
}

export default SectionCutOff;
