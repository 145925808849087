export const SET_APPOINTMENTS = "SET_APPOINTMENTS";
export const EDIT_APPOINTMENT_DETAILS = "EDIT_APPOINTMENT_DETAILS";
export const SET_APPOINTMENT_DETAILS = "SET_APPOINTMENT_DETAILS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const CHANGE_PAYMENT_STATUS = "CHANGE_PAYMENT_STATUS";
export const CHANGE_AGENDA_CANCELLATION_REASON = "CHANGE_AGENDA_CANCELLATION_REASON";
export const GET_APPOINTMENTS_PENDING = "GET_APPOINTMENTS_PENDING";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_ERROR = "GET_APPOINTMENTS_ERROR";

export const ONLINE = "Online";
export const WALK_IN = "Walk In";

export const AgendaDetails = "agenda";
export const SearchDetails = "search";

export type DetailsType = typeof AgendaDetails | typeof SearchDetails;
