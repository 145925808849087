import React, { Component } from "react";
import { connect } from 'react-redux';
import AsyncSelect from "react-select/lib/Async";

import { getOrganisationsForAsyncSelect } from "actions/organisations";
import type { Action } from 'types/actions';
import type { Organisation } from 'types/organisation';

import locale from "service/locale";

type Props = {
  handleSelectOrganisation: (organisation: Organisation) => Action,
  organisations: Organisation[],
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
};

const mapStateToProps = (state: any) => ({
  organisations: state.organisations.organisationsListAsync || [],
});

const mapDispatchToProps = (dispatch: Function) => ({
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
});

class OrganisationsFilter extends Component<Props> {

  state = {
    searchValue: "",
  };

  componentDidMount = () => {
    const { getOrganisationsForAsyncSelect } = this.props;
    getOrganisationsForAsyncSelect();
  };

  handleChangeOrganisation = (organisation: Organisation) => {
    const { handleSelectOrganisation } = this.props;
    handleSelectOrganisation(organisation);
  };

  handleLoadOptions = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchValue
      }, () => {
        if (searchValue && searchValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  render() {
    const { searchValue } = this.state;
    const { organisations } = this.props;

    return (
      <AsyncSelect
        defaultOptions={organisations}
        id="organisation-select"
        name="organisation-select"
        loadOptions={this.handleLoadOptions}
        placeholder={locale.AddUser.organisationSelect}
        onChange={this.handleChangeOrganisation}
        onInputChange={this.handleSearchChange}
        classNamePrefix="patient-care"
        inputValue={searchValue}
        openMenuOnFocus={true}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsFilter);
