import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NoResults, Button, Pagination } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentHeader, FeatureContentScroll, FeaturePagination } from 'components/Share/feature';
import { SkeletonTable } from 'components/Share/skeleton';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import FilterOrganisations from './FilterOrganisations/FilterOrganisations';
import { columns, TableOrganisations } from './TableOrganisations/TableOrganisations';
import { getOrganisationsList } from 'actions/organisationsList';
import { setOrganisationHeader, setBranchHeader } from "actions/router";
import * as SearchConstants from 'constants/SearchConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as BranchesConstants from 'constants/BranchesConstants';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import locale from 'service/locale';

// ADD_ORGANISATION_WORKFLOW
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';
import AddOrganisationModal from '../OrganisationsList/AddOrganisationModal/AddOrganisationModal';
import DuplicateOrganisationModal from '../OrganisationsList/DuplicateOrganisationModal/DuplicateOrganisationModal';
import { openAddOrganisation, closeAddOrganisation, closeAddOrganisationError } from 'actions/panel';
import { clearForm } from 'actions/form';

// TELEMETRY
import { ai } from 'service/telemetry';
import * as RolesConstants from 'constants/RolesConstants';

type State = {
  // ADD_ORGANISATION_WORKFLOW
  isDiscardModalOpen: boolean,
};

type Props = {
  searchValue: string,
  data: any,
  pages: number,
  isLoading: boolean,
  isError: boolean,
  currentRole: any,
  adminUrl: any,
  getOrganisationsList: (pageNumber: number, searchValue: string) => Action,
  setOrganisationHeader: (organisationId: string) => any,
  setBranchHeader: (branchId: string) => any,

  // ADD_ORGANISATION_WORKFLOW
  isCreationPending: boolean,
  isActiveAddOrganisation: boolean,
  isActiveAddOrganisationError: boolean,
  openAddOrganisation: () => Action,
  closeAddOrganisation: () => Action,
  closeAddOrganisationError: () => Action,
  clearForm: () => Action,

  // TELEMETRY
  aiUserId: string,
  aiSessionId: string,
  aiRoleName: string,
  aiOrganizationId: string,
  aiBranchId: string,
  aiCareProviderId: string,
};

const mapStateToProps = state => ({
  searchValue: state.organisationsList.searchValue,
  data: state.organisationsList.data,
  pages: state.organisationsList.pages,
  isLoading: state.organisationsList.isLoading,
  isError: state.organisationsList.isError,
  currentRole: state.roles.profileCurrentRole,
  adminUrl: state.profile.adminUrl,

  // ADD_ORGANISATION_WORKFLOW
  isCreationPending: state.organisationDetails.isCreationPending,
  isActiveAddOrganisation: state.panel.isActiveAddOrganisation,
  isActiveAddOrganisationError: state.panel.isActiveAddOrganisationError,

  // TELEMETRY
  aiUserId: state.profile.id,
  aiSessionId: state.profile.sessionId,
  aiRoleName: state.roles.profileCurrentRole.role,
  aiOrganizationId: state.profile.organisation.organisationId,
  aiBranchId: state.branchDetails.branchId,
  aiCareProviderId: state.branchDetails.careProviderId,
});

const mapDispatchToProps = (dispatch: any) => ({
  getOrganisationsList: (pageNumber: number, searchValue: string) => dispatch(getOrganisationsList(pageNumber, searchValue)),
  setOrganisationHeader: (organisationId) => dispatch(setOrganisationHeader(organisationId)),
  setBranchHeader: (branchId) => dispatch(setBranchHeader(branchId)),

  // ADD_ORGANISATION_WORKFLOW
  openAddOrganisation: () => dispatch(openAddOrganisation()),
  closeAddOrganisation: () => dispatch(closeAddOrganisation()),
  closeAddOrganisationError: () => dispatch(closeAddOrganisationError()),
  clearForm: () => dispatch(clearForm()),
});

class OrganisationsList extends Component<Props, State> {
  state = {
    isDiscardModalOpen: false,
  };

  componentDidMount = () => {
    const { getOrganisationsList, setOrganisationHeader, setBranchHeader, aiUserId, aiRoleName, aiOrganizationId, aiBranchId, aiCareProviderId, aiSessionId } = this.props;
    const isSuperAdmin = aiRoleName === RolesConstants.ADMIN;
    getOrganisationsList(1, '');

    if (isSuperAdmin) {
      setOrganisationHeader(OrganisationsConstants.DEFAULT_ORGANISATIONID);
      setBranchHeader(BranchesConstants.DEFAULT_BRANCHID);

      // TELEMETRY
      ai.appInsights.trackEvent({
        name: 'PAProAdmin',
      }, {
        UserId: aiUserId,
        SessionId: aiSessionId,
        RoleName: aiRoleName,
        OrganizationId: aiOrganizationId || locale.Telemetry.naOrganisationId,
        BranchId: aiBranchId || locale.Telemetry.naBranchId,
        CareProviderId: aiCareProviderId || locale.Telemetry.naCareProviderId,
      });
    }
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { searchValue, getOrganisationsList, isCreationPending } = this.props;
    if (searchValue !== nextProps.searchValue) {
      if (nextProps.searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
        getOrganisationsList(1, nextProps.searchValue);
      } else {
        if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
          getOrganisationsList(1, '');
        }
      }
    }

    // ADD_ORGANISATION_WORKFLOW
    if ((isCreationPending !== nextProps.isCreationPending) && (nextProps.isCreationPending === false)) {
      getOrganisationsList(1, '');
    }
  }

  // ADD_ORGANISATION_WORKFLOW
  handleAddOrganisation = () => {
    const { openAddOrganisation } = this.props;
    openAddOrganisation();
  };

  handleOpenDiscardDialog = () => {
    this.setState({ isDiscardModalOpen: true });
  };

  handleCloseAddOrganisationError = () => {
    const { closeAddOrganisationError } = this.props;
    closeAddOrganisationError();
  };

  handleDiscardChanges = () => {
    const { closeAddOrganisation, clearForm } = this.props;
    this.setState({ isDiscardModalOpen: false }, () => {
      clearForm();
      closeAddOrganisation();
    });
  };

  handleStay = () => {
    this.setState({ isDiscardModalOpen: false });
  };

  render() {
    const { isDiscardModalOpen } = this.state;
    const { searchValue, data, pages, isLoading, isError, adminUrl, currentRole, getOrganisationsList, isActiveAddOrganisation, isActiveAddOrganisationError } = this.props;
    const isEmptyData = data.length === 0;
    const isEmptySearch = searchValue.length === 0;
    const isEmptyOrganisations = isEmptyData && isEmptySearch && !isLoading;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;

    const handlePagination = (e: any) => {
      const pageNumber = e.selected + 1;
      getOrganisationsList(pageNumber, searchValue);
    }

    const renderPagination = () => {
      if (pages > 1) {
        return (
          <FeaturePagination hidden={isLoading || isError || isEmptyData}>
            <Pagination pageCount={pages} onPageChange={handlePagination} />
          </FeaturePagination>
        );
      }
      return null;
    }

    const renderTableOrganisations = () => {
      if (isLoading) {
        return (
          <SkeletonTable className="spacing-medium" columns={6} isSticky={true} isGrey={true} />
        );
      }
      if (isEmptyData) {
        return (
          <NoResults type="info">
            <h2>{locale.OrganisationsListRedesign.noResults.noOrganisationsFilter}</h2>
          </NoResults>
        );
      }
      return <TableOrganisations data={data} columns={columns} />;
    }

    const renderContent = () => {
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.OrganisationsListRedesign.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_ORGANISATIONS}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationsListRedesign.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmptyOrganisations) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.OrganisationsListRedesign.noResults.noOrganisations}</h2>
                <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationsListRedesign.button.addOrganisation} onClick={this.handleAddOrganisation} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <FilterOrganisations isLoading={isLoading} handleAddOrganisation={this.handleAddOrganisation} />
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableOrganisations()}
            {renderPagination()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    if (!isSuperAdmin) {
      return (
      <Redirect to={adminUrl}/>
      );
    }

    return (
      <Feature>
        <FeatureMenu>
          <AdminSidebar />
        </FeatureMenu>
        <FeatureContent>
          {renderContent()}
          {isActiveAddOrganisation ? <AddOrganisationModal handleOpenDiscardDialog={this.handleOpenDiscardDialog} /> : null}
          {isActiveAddOrganisationError ? <DuplicateOrganisationModal handleStay={this.handleCloseAddOrganisationError} /> : null}
          {isDiscardModalOpen ? <DiscardChangesOverlay handleDiscardChanges={this.handleDiscardChanges} handleStay={this.handleStay} /> : null}
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsList);
