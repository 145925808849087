import React, { Component } from 'react';
import './styles/calendar-filters-content.scss';

type State = {
  isScrollTop: boolean,
}

type Props = {
  children: any,
};

class CalendarFiltersContent extends Component<Props, State> {
  state = {
    isScrollTop: true,
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    const wrapperContent = document.querySelector('.calendar-filters-content');
    this.setState({
      isScrollTop: wrapperContent.scrollTop === 0,
    });
  }

  render() {
    const { children } = this.props;
    const { isScrollTop } = this.state;
    return (
      <div className={`calendar-filters-content ${isScrollTop ? 'calendar-filters-content-top' : 'calendar-filters-content-scroll'}`} onScroll={this.scrollHandler} id="calendar-filters-content">
        {children}
      </div>
    );
  }
};

export default CalendarFiltersContent;
