import React, { Component } from "react";
import { Form, FormItem, Input, IconClose, LinkIcon, InputError } from "@patient-access/ui-kit";
import type { ClosedDay } from 'types/calendars';

import { checkPastDate } from "helpers/checkValues";
import { getTomorrowDate } from "helpers/common"

import locale from 'service/locale';

type Props = {
  key: string,
  isEditMode: boolean,
  day: ClosedDay,
  handleDeleteRole: (name: string) => any,
  handleClosedDayChange: (name: string, value: string) => any,
};

type State = {
  isPastDate: boolean
};

export default class ClosedDaysEditItem extends Component<Props, State> {

  state = {
    isPastDate: false
  };

  handleDeleteRole = (e: Event) => {
    e && e.preventDefault();
    const { day, handleDeleteRole } = this.props;
    handleDeleteRole(day.name);
  };

  handleClosedDayChange = (e: Event) => {
    const { handleClosedDayChange } = this.props;
    const isPastDate  = checkPastDate(e.target.value);
    this.setState({isPastDate});
    const {
      target: { name, value }
    } = e;

    handleClosedDayChange(name, value, isPastDate);
  };

  render() {
    const { day, key } = this.props;
    const {isPastDate}  = this.state;
    const tomorrow = getTomorrowDate();
    return (
      <React.Fragment key={key}>
        <div className="patient-care-modal-edit-row">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem 
                type="item"
                error={
                  isPastDate ? "error" : null
                }
              >
                <Input
                  id={day.name}
                  name={day.name}
                  onChange={this.handleClosedDayChange}
                  data-id="new_closed_day"
                  placeholder={locale.AvailabilitySettings.newClosedDayPlaceholder}
                  type="date"
                  min={tomorrow}
                />
                {isPastDate ? (
                  <InputError
                    message={
                      locale.AvailabilitySettings.closedDaysValidationError
                    }
                  />
                ) : null}
                
              </FormItem>
            </Form>
          </div>
          <div className="patient-care-btn-delete-row" onClick={this.handleDeleteRole}>
            <LinkIcon
              to="#delete"
              size="small"
              icon={<IconClose outline />}
              accessibilitySpan={locale.Buttons.buttonDelete}
              onClick={this.handleDeleteRole}
            />
          </div>
        </div>
        <hr className="patient-care-separator-small" />
      </React.Fragment>
    )
  }
}
