import React, { Component } from "react";
import { connect } from "react-redux";
import { openBookingForm } from "actions/bookAppointment";
import Form from "@patient-access/ui-kit/lib/components/Form/Form";
import Radio from "@patient-access/ui-kit/lib/components/FormElements/Radio";
import FormItem from "@patient-access/ui-kit/lib/components/FormItem/FormItem";
import type { Action } from "types/actions";
import type { SlotCalendar} from "types/appointments";
import { showSelectedSlot } from "actions/calendarView";
import moment from "moment";

type Props = {
  openBookingForm: (
    startTime: string,
    endTime: string,
    defaultCalendar: SlotCalendar,
    slotCalendars: SlotCalendar[]
  ) => Action,
  slot: Object,
  date: string,
  isSlotTaken: boolean | null,
  isHovered: boolean,
  showSelectedSlot: (zIndex: number | null) => Action,
  holdAppointmentError: () => any,
};

const mapDispatchToProps = (dispatch: any): Object => ({
  showSelectedSlot: zIndex => dispatch(showSelectedSlot(zIndex)),

  openBookingForm: (startTime, endTime, defaultCalendar, slotCalendars) =>
    dispatch(
      openBookingForm(startTime, endTime, defaultCalendar, slotCalendars)
    )
});

class SlotItem extends Component<Props> {
  handleSelectTimeSlot = (e: Event) => {
    e && e.preventDefault();
    const { slot, openBookingForm, showSelectedSlot } = this.props;
    showSelectedSlot(slot.zIndex);
    openBookingForm(slot.start, slot.end, slot.calendars[0], slot.calendars);
  };

  render() {
    const { slot, isHovered } = this.props;
    return (
      <li
        key={slot.start}
        className={`patient-care-time-slots-item ${isHovered ? "hovered" : ""}`}
        onClick={this.handleSelectTimeSlot}
      >
        <Form noValidate>
          <FormItem type="item">
            <Radio
              onClick={this.handleSelectTimeSlot}
              id={slot.start}
              name={slot.start}
              value={slot.start}
              label={moment(slot.start).format("HH:mm")}
              className="input-radio-time"
            />
          </FormItem>
        </Form>
      </li>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SlotItem);
