import React, { Component } from 'react';
import { FormItem, InputError, Input, Checkbox, Radio } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import locale from 'service/locale';

type Props = {
  userData: any,
  branchServiceData: any,
  isError: boolean,
  isAffiliate: boolean,
  setUserData: any,
  handleValidatePrice: any,
  isFormEdited: any,
};

class SectionPrice extends Component<Props,State> {

  render() {
    const { userData, branchServiceData, isError, isAffiliate, setUserData, handleValidatePrice, isFormEdited } = this.props;
    const isNhsService = userData.serviceIsNhsCovered;

    const handlePrice = (e: any) => {
      if (e.target.value !== '' && e.target.value >= 0) {
        setUserData({ servicePrice: Number(e.target.value) });
        handleValidatePrice(true);
      } else {
        setUserData({ servicePrice: e.target.value });
        handleValidatePrice(false);
      }

      if (branchServiceData.price !== Number(e.target.value) || branchServiceData.vatIncluded !== userData.serviceIsVatIncluded 
        || branchServiceData.showPrice !== userData.serviceIsVatIncluded) {
        isFormEdited(true);
      } else { isFormEdited(false); }
    }

    const handleVatIncluded = (e: any) => {
      setUserData({ serviceIsVatIncluded: e.target.checked });

      if (branchServiceData.price !== userData.servicePrice || branchServiceData.vatIncluded !== e.target.checked) {
        isFormEdited(true);
      } else { isFormEdited(false); }
    }

    const handleUndoChanges = () => {
      setUserData({ servicePrice: Number(branchServiceData.price), serviceIsVatIncluded: branchServiceData.vatIncluded });
      isAffiliate && setUserData({ serviceIsShowPrice: branchServiceData.showPrice });
      handleValidatePrice(true);
      isFormEdited(false);
    }

    const isPriceModified = () => {
      if (branchServiceData.price !== userData.servicePrice || branchServiceData.vatIncluded !== userData.serviceIsVatIncluded || (isAffiliate && branchServiceData.showPrice !== userData.serviceIsShowPrice)) {
        return true;
      } return false;
    }

    const handleShowPrice = (value: any) => {
      setUserData({ serviceIsShowPrice: value });
      handleValidatePrice(true);

      if (branchServiceData.price !== userData.servicePrice || branchServiceData.showPrice !== value) {
        isFormEdited(true);
      } else { isFormEdited(false); }
    };

    const renderShowPrice = () => {
      if (isAffiliate) {
        return (
          <FormItem type="item" error={(isError && !userData.serviceIsShowPrice) ? 'error' : null}>
            <div className="row row-list">
              <Radio
                id="showServicePrice_inactive"
                name="serviceShowPrice"
                label={locale.BranchServicesRedesign.section.servicePrice.labelDontShowPrice}
                onChange={() => { handleShowPrice(false); }}
                value="serviceShowPrice_false"
                checked={userData.serviceIsShowPrice === false}
              />
              <Radio
                id="showServicePrice_active"
                name="serviceShowPrice"
                label={locale.BranchServicesRedesign.section.servicePrice.labelShowPrice}
                onChange={() => { handleShowPrice(true); }}
                value="serviceShowPrice_true"
                checked={userData.serviceIsShowPrice === true}
              />
            </div>
          </FormItem>
        );
      }
      return null;
    }

    const renderVatIncluded = () => {
      if (!isAffiliate) {
        return (
          <Checkbox
            className="no-validate"
            id="serviceIsVatIncluded"
            name="serviceIsVatIncluded"
            label={locale.BranchServicesRedesign.section.servicePrice.labelVAT}
            onChange={handleVatIncluded}
            value="vatIncluded"
            checked={userData.serviceIsVatIncluded}
            disabled={isNhsService}
          />
        );
      }
      return null;
    }

    const renderPrice = () => {
      if (!isAffiliate || userData.serviceIsShowPrice) {
        return (
          <FormItem type="item" error={isError ? 'error' : null}>
            <div className="row row-inline">
              <div className="input-before">
                <p><strong>{locale.BranchServicesRedesign.section.servicePrice.currency}</strong></p>
              </div>
              <Input
                type="number"
                id="servicePrice"
                name="servicePrice"
                placeholder={locale.BranchServicesRedesign.section.servicePrice.placeholder}
                onChange={handlePrice}
                value={userData.servicePrice}
                disabled={isNhsService}
              />
              {renderVatIncluded()}
            </div>
          </FormItem>
        );
      }
      return null;
    }

    const renderError = () => {
      if (isError) {
        if (isAffiliate && userData.serviceIsShowPrice === null) {
          return <InputError message={locale.BranchServicesRedesign.validationError.servicePriceShow} />;
        }
        if (userData.servicePrice < 0) {
          return <InputError message={locale.BranchServicesRedesign.validationError.servicePriceNegative} />;
        }
        return <InputError message={locale.BranchServicesRedesign.validationError.servicePrice} />;
      }
      return null;
    }

    return (
      <SidebarBodySection>
        <SidebarBodyHeader>
          {isPriceModified() ? <button onClick={handleUndoChanges}> {locale.BranchServicesRedesign.button.undoChanges}</button> : null}
          <h3>{locale.BranchServicesRedesign.section.servicePrice.header}</h3>
        </SidebarBodyHeader>
        <SidebarBodyContent>
          {renderShowPrice()}
          {renderPrice()}
          {renderError()}
        </SidebarBodyContent>
      </SidebarBodySection>
    );
  }
}

export default SectionPrice;
