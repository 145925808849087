import React, { Component } from 'react';

import './styles/sidebar-footer.scss';

type Props = {
  children: any,
};

class SidebarFooter extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="sidebar-footer">
        {children}
      </div>
    );
  }
};

export default SidebarFooter;
