import React, { Component } from "react";
import { connect } from 'react-redux';

import ClosedDaysItem from "components/Pages/Admin/BranchContent/AvailabilitySettings/ClosedDays/ClosedDaysItem/ClosedDaysItem";

import type { Calendar } from 'types/calendars';
import locale from 'service/locale';

type Props = {
  calendar: Calendar,
  handleRedirect: () => any
};

const mapStateToProps = (state) => ({
  calendar: state.calendar
});

class CalendarDetailsBranchClosedDays extends Component<Props> {

  handleRedirect = (e: Event) => {
    e && e.preventDefault();
    const { handleRedirect } = this.props;
    handleRedirect();
  };

  render() {
    const { calendar } = this.props;
    const { closedDays } = calendar;

    return (
      <div className="patient-care-closed-days">
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>
              {locale.AddNewCalendar.branchClosedDays.title}
            </h3>
          </div>
          <p>{locale.AddNewCalendar.branchClosedDays.description}</p>
          <button
            className="patient-care-btn-link"
            onClick={this.handleRedirect}>
            {locale.AddNewCalendar.branchClosedDays.link}
          </button>
        </div>

        <div className="patient-care-days-wrap">
          {
            closedDays.map(day =>
              <ClosedDaysItem
                key={day.name}
                day={day}
              />
            )
          }
        </div>
        <hr className="patient-care-separator-small" />
      </div>
    )
  }
}

export default connect(mapStateToProps) (CalendarDetailsBranchClosedDays);
