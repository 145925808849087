import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { updateForm, clearForm } from "actions/form";

import TwoFactorUserPreference from "./TwoFactorUserPreference/TwoFactorUserPreference";
import TwoFactorRemove from "./TwoFactorRemove/TwoFactorRemove";

import locale from "service/locale";

type Props = {
  userDetails: any,
  form: any,
  updateForm: (data: any) => any,
  clearForm: () => any,
  isCurrentUser: boolean,
  isBothSuperAdmins: boolean,
  canEditRoles: boolean,
  isSuperAdmin: boolean
};

type State = {
  isEditMode: boolean,
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
});

export class UserSecurity extends Component<Props, State> {
  state = {
    isEditMode: false,
  };

  handleChangeEditMode = (e: Event) => {
    e && e.preventDefault();
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const {
      userDetails,
      form,
      isCurrentUser,
      canEditRoles,
      isSuperAdmin
    } = this.props;

    const { isEditMode } = this.state;
    const displayTwoFactorPreference =
          isSuperAdmin ;
    const displayTwoFactorRemove =
      isCurrentUser || ( canEditRoles);

    return (
      <Fragment>
        {displayTwoFactorPreference || displayTwoFactorRemove ? (
          <Fragment>
            <hr className="patient-care-separator" />
            <div className="patient-care-heading-row">
              <h3>{locale.UserDetails.securityTitle}</h3>
              {displayTwoFactorPreference || (displayTwoFactorRemove && userDetails.isMFAPhoneRegistered) ? (
                <button
                  className="patient-care-btn-link"
                  onClick={this.handleChangeEditMode}
                >
                  {isEditMode
                    ? locale.Buttons.buttonDone
                    : locale.Buttons.buttonEdit}
                </button>
              ) : null }
            </div>
            {displayTwoFactorPreference ? (
              <div className="patient-care-block">
                <div className="patient-care-col-6">
                  <TwoFactorUserPreference
                    userDetails={userDetails}
                    isEditMode={isEditMode}
                    form={form}
                  />
                </div>
              </div>
            ) : null}

            {displayTwoFactorRemove ? (
              <div className="patient-care-block">
                  <TwoFactorRemove
                    userDetails={userDetails}
                    isEditMode={isEditMode}
                    form={form}
                  />
              </div>
            ) : null}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSecurity);
