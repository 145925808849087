import React, { Component } from 'react';
import './styles/skeleton-calendar-filters-item.scss';

type Props = {
  size: number,
};

class SkeletonCalendarFiltersItem extends Component<Props> {
  render() {
    const { size } = this.props;
    return (
      <div className="skeleton-calendar-filters-item">
        <i />
        <div className={`ui-kit-skeleton-line ui-kit-skeleton-line-${size}`} />
      </div>
    );
  }
};

export default SkeletonCalendarFiltersItem;
