import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import Modal from "components/Share/Modal/Modal";
import AppointmentDetails from "components/Share/AppointmentDetails/AppointmentDetails";
import OrganisationSidebar from './OrganisationSidebar/OrganisationSidebar';
import Breadcrumbs from 'components/Share/Breadcrumbs/Breadcrumbs';
import * as RolesConstants from 'constants/RolesConstants';

type Props = {
  children: any,
  isActiveAgenda: boolean,
  organisationId: string,
  currentRole: any,
  isActiveAgendaDetails: boolean,
}

const mapStateToProps = (state) => ({
  isActiveAgenda: state.panel.isActiveAgenda,
  currentRole: state.roles.profileCurrentRole,
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails,
});

class OrganisationContent extends Component<Props> {
  render() {
    const { children, isActiveAgenda, organisationId, currentRole, isActiveAgendaDetails } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    return (
      <Fragment>
        <div className={
          `patient-care-main-holder
          ${isSuperAdmin ? "with-breadcrumbs" : ""}
          ${isActiveAgenda ? "active" : ""}`
        }>
          {
            isSuperAdmin &&
              <Breadcrumbs organisationId={organisationId} />
          }
          { children }
        </div>
        <OrganisationSidebar organisationId={organisationId} />
        {isActiveAgendaDetails && (
          <Modal>
            <AppointmentDetails
              type={
                AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
              }
            />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(OrganisationContent);
