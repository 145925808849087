import React, { Component } from "react";
import Select from "react-select";

import type { Action } from 'types/actions';
import { Organisation, Branch } from 'types/organisation'
import * as BranchesConstants from 'constants/BranchesConstants';
import { formatListAsOptions } from "helpers/formatData";

type Props = {
  selectedOrganisation: Organisation;
  branches: Branch[];
  handleSelectBranch: (branch: Branch) => Action;
};

type State = {
  branch: Branch,
}

class BranchFilter extends Component<Props, State> {

  state = {
    branch: BranchesConstants.DROPDOWN_OPTION_DEFAULT,
  };

  shouldComponentUpdate = (nextProps: Props) => {
    const { selectedOrganisation } = this.props;
    if (nextProps.selectedOrganisation.value !== selectedOrganisation.value) {
      this.setState({
        branch: BranchesConstants.DROPDOWN_OPTION_DEFAULT,
      });
    }
    return true;
  };

  handleSelectBranch = (branch: Branch) => {
    const { handleSelectBranch } = this.props;

    this.setState({
      branch: branch
    });
    handleSelectBranch(branch);
  };

  render() {
    const { selectedOrganisation, branches } = this.props;
    const { branch } = this.state;

    let dropdownOpts = formatListAsOptions(branches.slice(0)); // clone array
    dropdownOpts.unshift(BranchesConstants.DROPDOWN_OPTION_DEFAULT);

    return (
      <Select
        id="branch-select"
        name="branch-select"
        value={branch}
        options={dropdownOpts}
        classNamePrefix="patient-care"
        isDisabled={!selectedOrganisation.value}
        onChange={this.handleSelectBranch}
      />
    )
  }
}

export default BranchFilter;

