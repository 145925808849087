import * as UserDetailsConstants from 'constants/UserDetailsConstants';
import type { Action } from 'types/actions';
import { cloneDeep } from 'lodash';

type UserDetailsState = {
  id: string,
  name: string,
  email: string,
  displayName: string,
  deleted: boolean,
  uniqueRoleCount: number,
  uniqueOrganizationCount: number,
  uniqueBranchCount: number,
  accounts: any[],
  accountsLoadedInitially: any[],
  isCreationPending: boolean,
  isUpdatePending: boolean,
  getUserDetailsPending: boolean,
}

const initialState: UserDetailsState = {
  id: "",
  name: "",
  email: "",
  displayName: "",
  deleted: false,
  uniqueRoleCount: 0,
  uniqueOrganizationCount: 0,
  uniqueBranchCount: 0,
  accounts: [],
  accountsLoadedInitially: [],
  isCreationPending: false,
  isUpdatePending: false,
  getUserDetailsPending: false,
};

export default (localState: UserDetailsState = initialState, action: Action): UserDetailsState => {
  switch (action.type) {
    case UserDetailsConstants.SET_USER_INFO: {
      const { id, name, email, displayName, deleted, uniqueRoleCount, uniqueOrganizationCount, uniqueBranchCount, accounts, requiresMFA, isMFAPhoneRegistered } = action.payload;
      return {
        ...localState,
        id,
        name,
        email,
        displayName,
        deleted,
        uniqueRoleCount,
        uniqueOrganizationCount,
        uniqueBranchCount,
        accounts,
        accountsLoadedInitially: cloneDeep(accounts),
        getUserDetailsPending: false,
        requiresMFA,
        isMFAPhoneRegistered
      };
    }
    case UserDetailsConstants.CLEAR_USER_INFO: {
      return null;
    }
    case UserDetailsConstants.CREATE_USER_PENDING:
      return {
        ...localState,
        isCreationPending: true,
      };
    case UserDetailsConstants.CREATE_USER_SUCCESS:
      return {
        ...localState,
        isCreationPending: false,
      };
    case UserDetailsConstants.CREATE_USER_ERROR:
      return {
        ...localState,
        isCreationPending: false,
      };
    case UserDetailsConstants.UPDATE_USER_PENDING:
      return {
        ...localState,
        isUpdatePending: true,
      };
    case UserDetailsConstants.UPDATE_USER_SUCCESS:
      return {
        ...localState,
        isUpdatePending: false,
      };
    case UserDetailsConstants.UPDATE_USER_ERROR:
      return {
        ...localState,
        isUpdatePending: false,
      };
    case UserDetailsConstants.GET_USER_PENDING:
      return {
        ...localState,
        getUserDetailsPending: true,
      };
    case UserDetailsConstants.GET_USER_ERROR:
      return {
        ...localState,
        getUserDetailsPending: false,
      };
    default: {
      return localState;
    }
  }
}
