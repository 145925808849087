// GET
export const GET_AVAILABILITY_RULES_PENDING = "GET_AVAILABILITY_RULES_PENDING";
export const GET_AVAILABILITY_RULES_SUCCESS = "GET_AVAILABILITY_RULES_SUCCESS";
export const GET_AVAILABILITY_RULES_ERROR = "GET_AVAILABILITY_RULES_ERROR";
export const SET_AVAILABILITY_RULES_DATA = "SET_AVAILABILITY_RULES_DATA";
export const SET_AVAILABILITY_RULES_DATA_CLONE = "SET_AVAILABILITY_RULES_DATA_CLONE";

// PUT
export const PUT_AVAILABILITY_RULES_PENDING = "PUT_AVAILABILITY_RULES_PENDING";
export const PUT_AVAILABILITY_RULES_SUCCESS = "PUT_AVAILABILITY_RULES_SUCCESS";
export const PUT_AVAILABILITY_RULES_ERROR = "PUT_AVAILABILITY_RULES_ERROR";

// UPDATE
export const SET_TIME_OFF_DATA_UPDATE = "SET_TIME_OFF_DATA_UPDATE";

// OVERLAY
export const SET_TIME_OFF_OVERLAY_VISIBILITY = "SET_TIME_OFF_OVERLAY_VISIBILITY";
