// TASK_MANAGER_IS_VISIBLE
export const SET_TASK_MANAGER_IS_VISIBLE = "SET_TASK_MANAGER_IS_VISIBLE";

// OVERLAY
export const SET_TASK_MANAGER_OVERLAY_TYPE = "SET_TASK_MANAGER_OVERLAY_TYPE";
export const SET_TASK_MANAGER_OVERLAY_VISIBLE = "SET_TASK_MANAGER_OVERLAY_VISIBLE";
export const SET_TASK_MANAGER_BRANCHES_OVERLAY_VISIBLE = "SET_TASK_MANAGER_BRANCHES_OVERLAY_VISIBLE";

// OVERLAY_TYPE
export const OVERLAY_TYPE = {
  ERROR: 'error',
  ERROR_PARTIAL: 'error_partial',
  CANCEL: 'cancel',
  CANCEL_CONFIRMATION: 'cancel_confirmation',
};

// Action type for tasks
export const TASK_ACTION_TYPE = {
  ADDSERVICEBULK: 'ServiceAddOrchestrator',
  EDITSERVICEBULK: 'ServiceEditOrchestrator',
  REMOVESERVICEBULK: 'ServiceDeleteOrchestrator'
};

// USER_DATA
// export const SET_TASK_MANAGER_USER_DATA = "SET_TASK_MANAGER_USER_DATA";
// export const RESET_TASK_MANAGER_USER_DATA = "RESET_TASK_MANAGER_USER_DATA";

// TASKS_LIST
export const GET_TASK_MANAGER_TASKS_LIST_PENDING = "GET_TASK_MANAGER_TASKS_LIST_PENDING";
export const GET_TASK_MANAGER_TASKS_LIST_SUCCESS = "GET_TASK_MANAGER_TASKS_LIST_SUCCESS";
export const GET_TASK_MANAGER_TASKS_LIST_ERROR = "GET_TASK_MANAGER_TASKS_LIST_ERROR";
export const SET_TASK_MANAGER_TASKS_LIST_DATA = "SET_TASK_MANAGER_TASKS_LIST_DATA";

// TASK_DETAIL
export const GET_TASK_MANAGER_TASK_DETAIL_PENDING = "GET_TASK_MANAGER_TASK_DETAIL_PENDING";
export const GET_TASK_MANAGER_TASK_DETAIL_SUCCESS = "GET_TASK_MANAGER_TASK_DETAIL_SUCCESS";
export const GET_TASK_MANAGER_TASK_DETAIL_ERROR = "GET_TASK_MANAGER_TASK_DETAIL_ERROR";
export const SET_TASK_MANAGER_TASK_DETAIL_DATA = "SET_TASK_MANAGER_TASK_DETAIL_DATA";

//CANCEL_TASKS
export const CANCEL_TASK_MANAGER_TASK_PENDING = "CANCEL_TASK_MANAGER_TASK_PENDING";
export const CANCEL_TASK_MANAGER_TASK_SUCCESS = "CANCEL_TASK_MANAGER_TASK_SUCCESS";
export const CANCEL_TASK_MANAGER_TASK_ERROR = "CANCEL_TASK_MANAGER_TASK_ERROR";

//DISMISS_TASKS
export const DISMISS_TASK_MANAGER_TASK_PENDING = "DISMISS_TASK_MANAGER_TASK_PENDING";
export const DISMISS_TASK_MANAGER_TASK_SUCCESS = "DISMISS_TASK_MANAGER_TASK_SUCCESS";
export const DISMISS_TASK_MANAGER_TASK_ERROR = "DISMISS_TASK_MANAGER_TASK_ERROR";

//RETRY_TASK
export const RETRY_TASK_MANAGER_TASK_PENDING = "RETRY_TASK_MANAGER_TASK_PENDING";
export const RETRY_TASK_MANAGER_TASK_SUCCESS = "RETRY_TASK_MANAGER_TASK_SUCCESS";
export const RETRY_TASK_MANAGER_TASK_ERROR = "RETRY_TASK_MANAGER_TASK_ERROR";