import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, FormItem, Input, InputLabel, InputError } from '@patient-access/ui-kit';

import { updateForm } from "actions/form";

import locale from 'service/locale';

type Props = {
  displayName: string,
  email: string,
  isCurrentUser: boolean,
  updateForm: (data: any) => any,
  handleChangeEditMode?: () => any
};

type State = {
  isEditMode: boolean,
  displayName: string,
  displayNameError: boolean,
};

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class UserInfo extends Component<Props, State> {

  state = {
    isEditMode: false,
    displayName: this.props.displayName,
    displayNameError: false,
  };

  handleChangeMode = (e: Event) => {
    e && e.preventDefault();
    const { displayNameError, isEditMode } = this.state;
    const handleChangeMode = this.props.handleChangeEditMode || (()=>{});

    if (displayNameError) return;

    const isEditModeUpdated = !isEditMode;
    this.setState({ isEditMode: isEditModeUpdated });
    handleChangeMode(isEditModeUpdated);
  };

  handleChange = (e: Event) => {
    e && e.preventDefault();
    const { updateForm } = this.props;
    const { name, value } = e.target;

    this.setState({ [name]: value, isEditMode: true }, () => {
      updateForm({ [name]: value });
    });
  };

  handleBlur = (e: Event) => {
    e && e.preventDefault();
    const { value } = e.target;

    this.setState({ displayNameError: !value.length });
  };

  render() {
    const { email, isCurrentUser } = this.props;
    const { isEditMode, displayName, displayNameError } = this.state;
    return (
      <div className="patient-care-details-wrap">
        <div className="patient-care-block">
          <Form noValidate>
            <FormItem type="item" error={displayNameError ? "true" : ""}>
              <InputLabel
                htmlFor="userDetails-name"
                message={locale.UserDetails.nameInput}
                size="small"
              />
              <div className="patient-care-row-align-content-center">
                <div className="patient-care-col-6">
                  { isCurrentUser && isEditMode ? (
                    <Input
                      type="name"
                      autoComplete="name"
                      id="userDetails-name"
                      name="displayName"
                      data-id="userDetails"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      value={displayName}
                    />
                    ) : (
                    <span className="patient-care-filled-input">
                      {displayName}
                    </span>
                    )}
                  {
                    displayNameError && <InputError message={locale.UserDetails.nameInputError} />
                  }
                </div>
                <div className="patient-care-col-6">
                  <div className="patient-care-align-right">
                    { isCurrentUser && (
                      <button
                        className="patient-care-btn-link"
                        onClick={this.handleChangeMode}
                      >
                        { isEditMode ? locale.UserDetails.editRolesButtons[0] : locale.UserDetails.editRolesButtons[1] }
                      </button>
                    ) }
                  </div>
                </div>
              </div>
            </FormItem>
          </Form>
          </div>
        <div className="patient-care-block">
          <Form noValidate>
            <FormItem type="item">
              <InputLabel
                htmlFor="userDetails-email"
                message={locale.UserDetails.email}
                size="small"
              />
            </FormItem>
          </Form>
          <span className="patient-care-filled-input">{email}</span>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(UserInfo);
