import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/card-reports-pharmacy.scss';
import locale from 'service/locale';

const mapStateToProps = (state) => ({
  summary: state.reportsSmartPharmacy.metrics.summary.data,
});

class CardPharmacy extends Component<Props> {
  render() {
    const { summary, isSuperAdmin, isOrgAdmin, isOrgVisible } = this.props;

    if(!(isSuperAdmin || isOrgAdmin)){
      return null;
    }

    if (isSuperAdmin && isOrgVisible) {

      return (
        <div className="card-reports-pharmacy">
          <div className="card-reports-pharmacy-item">
            <h3>{locale.Reports.smartPharmacyEnabledOrganisations}</h3>
            <p>{summary.smartPharmacyEnabledOrganisations}</p>
          </div>
          <div className="card-reports-pharmacy-item">
            <h3>{locale.Reports.smartPharmacyEnabledBranches}</h3>
            <p>{summary.smartPharmacyEnabledBranches}</p>
          </div>
        </div>
      );

    }
    
    return (
      <div className="card-reports-pharmacy">
        <div className="card-reports-pharmacy-item">
          <h3>{locale.Reports.smartPharmacyEnabledBranches}</h3>
          <p>{summary.smartPharmacyEnabledBranches}</p>
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps)(CardPharmacy);
