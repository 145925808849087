import React, { Component } from "react";
import { connect } from 'react-redux';

import { Button, Overlay } from "@patient-access/ui-kit";
import locale from 'service/locale';

type Props = {
    handleRemoveOverlayClose: () => any;
    handleRemoveOverlaySave: () => any;
    isUpdatePending: boolean
};

const mapStateToProps = (state) => ({
  isUpdatePending: state.userDetails.isUpdatePending,
});

class TwoFactorRemoveModal extends Component<Props> {
  
  render() {
    const { handleRemoveOverlayClose, handleRemoveOverlaySave, isUpdatePending } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="mfa-status-confirm-overlay"
        doClose={handleRemoveOverlayClose}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.UserDetails.twoFactorRemoveOverlay.add_title }</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.UserDetails.twoFactorRemoveOverlay.add_desc }</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="go-back-btn"
            defaultMessage={locale.UserDetails.twoFactorRemoveOverlay.back}
            onClick={handleRemoveOverlayClose}
            data-id="go-back-btn"
            isLoading={isUpdatePending}
          />
          <Button
            buttonType="secondary"
            messageKey="confirm-mfa-status-btn"
            defaultMessage={locale.UserDetails.twoFactorRemoveOverlay.save}
            onClick={handleRemoveOverlaySave}
            data-id="confirm-mfa-status-btn"
            isLoading={isUpdatePending}
          />
        </div>
      </Overlay>
    );
  }
}

export default connect(mapStateToProps)(TwoFactorRemoveModal);
