import React, { Component } from 'react';
import { IconAppointment } from '@patient-access/ui-kit';

import './styles/skeleton-date-picker.scss';

class SkeletonDatePicker extends Component {
  render() {
    return (
      <div className="skeleton-date-picker">
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-60" />
        <i><IconAppointment outline={false} /></i>
      </div>
    );
  }
};

export default SkeletonDatePicker;
