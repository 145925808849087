import moment from 'moment';
import agent from 'service/agent';
import { DEFAULT_BRANCHID } from "constants/BranchesConstants";
import { getAppointmentsListForAgenda, getToken } from "helpers/common";
import { getAppointmentsError } from "actions/appointments";
import * as AgendaConstants from 'constants/AgendaConstants';
import type { AppointmentsByDates } from 'types/appointments';
import type { Action } from 'types/actions';

export const setAppointments = (appointmentsList: AppointmentsByDates[]): Action => ({
  type: AgendaConstants.SET_AGENDA_APPOINTMENTS,
  payload: appointmentsList
});

export const getAgendaAppointments = () => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const branchId = currentState.calendarView.branchId;
  //TODO: use loader action especially for pending agenda appointments
  //dispatch(getAppointmentsPending());
  getToken(dispatch)
    .then(accessToken => {
      if (branchId !== DEFAULT_BRANCHID) {
        agent.Appointments.getAppointmentsForAgenda(
          branchId,
          moment.utc().add(1, "minutes").toISOString(),
          accessToken)
          .then(({ appointmentsByDate }) => {
            dispatch(setAppointments(getAppointmentsListForAgenda(appointmentsByDate)));
            //TODO: use loader action especially for pending agenda appointments
            //dispatch(getAppointmentsSuccess());
          })
          .catch(err => {
            dispatch(getAppointmentsError());
            console.log("getAppointmentsForAgenda server error ", err);
          });
      }
    })
};
