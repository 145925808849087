import React, { Component } from "react";
import { connect } from "react-redux";
import locale from "service/locale";
import { IconClose, LinkIcon} from '@patient-access/ui-kit';
import { IconAlert } from "svg";

import { getPendingAppointments, disableBranchPendingAppointmentAlert, setDisplayPendingAppointmentList } from "actions/branches";

type Props = {
  pendingAppointmentsCount: number,
  pendingAlertPage: string,
  isDisplayListView: boolean,
  getPendingAppointments: (pendingAlertPage: string) => Function,
  disableBranchPendingAppointmentAlert: (pendingAlertPage: string) => Function,
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => Function
};

const mapStateToProps = (state) => ({
  pendingAppointmentsCount: state.branches.pendingAppointmentsCount,
  isDisplayListView: state.branches.isDisplayPendingAppointmentList
});

const mapDispatchToProps = (dispatch: any): any => ({
  getPendingAppointments: (pendingAlertPage: string) => dispatch(getPendingAppointments(pendingAlertPage)),
  disableBranchPendingAppointmentAlert: (pendingAlertPage: string) => dispatch(disableBranchPendingAppointmentAlert(pendingAlertPage)),
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => dispatch(setDisplayPendingAppointmentList(isDisplayPendingAppointmentList))
});

class PendingAppointmentsNotification extends Component<Props> {

  componentDidMount = () => {
    const { getPendingAppointments, pendingAlertPage } = this.props;
    getPendingAppointments(pendingAlertPage);
  };

  closePendingAppointmentsAlert = () => {
    const { disableBranchPendingAppointmentAlert, pendingAlertPage } = this.props;
    disableBranchPendingAppointmentAlert(pendingAlertPage);
  }

  displayPendingAppointmentList = () => {
    const { setDisplayPendingAppointmentList } = this.props;
    setDisplayPendingAppointmentList(true);
  }

  render() {
    const { pendingAppointmentsCount, isDisplayListView } = this.props;

    return (<>
      { pendingAppointmentsCount !== 0 && !isDisplayListView && <div className="pending-appointments-alert">
        <div className="alert-close">
          <LinkIcon
            to="#"
            size="small"
            icon={<IconClose outline />}
            onClick={this.closePendingAppointmentsAlert}
          />
        </div>
        <div className="alert-content">
          <div className="alert-icon">
            <IconAlert outline={false} />
          </div>
          <div>
            <b>{locale.BranchSettings.pendingAppointments.actionText}</b><br />
            {locale.BranchSettings.pendingAppointments.description(pendingAppointmentsCount)}
            <text className="update-status-label" onClick={this.displayPendingAppointmentList} >{locale.BranchSettings.pendingAppointments.updateText}</text>
          </div>
        </div>
      </div> }
      </>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PendingAppointmentsNotification);
