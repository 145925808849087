import React, { Component } from "react";
import { connect } from "react-redux";

import UserDetailsContainer from './UserDetailsContainer/UserDetailsContainer';
import ChangeYourPasswordOverlay from './ChangeYourPasswordOverlay/ChangeYourPasswordOverlay';
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';

import { updateForm } from "actions/form";
import type { PanelActions } from "types/panel";
import { closeUserDetails, closeProfile } from "actions/panel";

type Props = {
  isActiveUserDetails: boolean,
  isChangePasswordOpened: boolean,
  closeUserDetails: () => PanelActions,
  closeProfile: () => PanelActions,
  updateForm: () => any,
  organisationId: string,
  branchId: string,
};

type State = {
  isEditMode: boolean,
  isUserDetailsOpened: boolean,
  isDiscardChangesOpened: boolean,
};

const mapStateToProps = (state: any) => ({
  isActiveUserDetails: state.panel.isActiveUserDetails,
  isChangePasswordOpened: state.panel.isChangePasswordOpened,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeUserDetails: () => dispatch(closeUserDetails()),
  closeProfile: () => dispatch(closeProfile()),
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class UserDetailsModal extends Component<Props, State> {
  state = {
    isEditMode: false,
    isUserDetailsOpened: true,
    isDiscardChangesOpened: false,
  };

  componentDidMount = () => {
    const { updateForm } = this.props;
    updateForm();
  };

  componentWillUnmount = () => {
    const { closeUserDetails } = this.props;
    closeUserDetails();
  };

  handleDiscardConfirmation = () => {
    this.setState({
      isDiscardChangesOpened: true,
    });
  };

  closeUserDetails = () => {
    const { closeUserDetails, updateForm, closeProfile } = this.props;
    this.setState({
      isUserDetailsOpened: true,
      isDiscardChangesOpened: false,
    }, () => {
      closeUserDetails();
      closeProfile();
      updateForm();
    })
  };

  handleStay = () => {
    this.setState({
      isDiscardChangesOpened: false,
    });
  };

  render() {
    const { isUserDetailsOpened, isDiscardChangesOpened } = this.state;
    const { isChangePasswordOpened, organisationId, branchId } = this.props;
    return (
      <React.Fragment>
        {
          isUserDetailsOpened &&
          <UserDetailsContainer
            handleDiscardConfirmation={this.handleDiscardConfirmation}
            organisationId={organisationId}
            branchId={branchId}
          />
        }
        {
          isDiscardChangesOpened &&
          <DiscardChangesOverlay
            handleDiscardChanges={this.closeUserDetails}
            handleStay={this.handleStay}
          />
        }
        {
          isChangePasswordOpened &&
          <ChangeYourPasswordOverlay />
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsModal);
