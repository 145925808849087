export const SET_ROOMS_LIST = "SET_ROOMS_LIST";
export const DELETE_ROOM = "DELETE_ROOM";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const CREATE_ROOM = "CREATE_ROOM";
export const SET_ROOMS_PENDING = "SET_ROOMS_PENDING";
export const UPDATE_BRANCH_ROOMS_PENDING = "UPDATE_BRANCH_ROOMS_PENDING";
export const UPDATE_BRANCH_ROOMS_SUCCESS = "UPDATE_BRANCH_ROOMS_SUCCESS";
export const UPDATE_BRANCH_ROOMS_ERROR = "UPDATE_BRANCH_ROOMS_ERROR";
export const SET_ROOM_FUTURE_APPOITNMENTS = "SET_ROOM_FUTURE_APPOITNMENTS";

export const roomNameDuplicateErrorKey = "Room name duplicate";
