import React, { Component, Fragment } from "react";
import { Icon, IconSortUp, IconSortDown } from "@patient-access/ui-kit";

import "./styles.scss";

type Props = {
  header: any[],
  data: any[],
  handleChangeMode: () => any,
  isEditMode: boolean,
  row: React$ComponentType<any>,
  handleDataChanged: () => any,
  isAbleToEdit: boolean,
  customSortList?: Function,
  isDataChanged:boolean
};

type State = {
  sorting: boolean,
  data: any[]
};

class CustomTable extends Component<Props, State> {
  state = {
    sorting: false,
    data: this.props.data
  };

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({ data: nextProps.data });
  };

  handleChangeSortingOrder = () => {
    this.setState(prevState => {
      return {
        sorting: !prevState.sorting
      };
    });
  };

  handleDeleteItem = (id: string) => {
    this.setState(prevState => {
      const deletedItemIndex = prevState.data.findIndex(
        item => item[0].id === id
      );
      return {
        data: [
          ...prevState.data.slice(0, deletedItemIndex),
          ...prevState.data.slice(deletedItemIndex + 1)
        ]
      };
    });
  };

  sortList = (el1: any, el2: any) => {
    const { sorting } = this.state;
    if (
      el1[0].text.includes("incomplete") &&
      el2[0].text.includes("incomplete")
    )
      return 0;
    if (
      el1[0].text.includes("incomplete") &&
      !el2[0].text.includes("incomplete")
    )
      return -1;
    if (
      !el1[0].text.includes("incomplete") &&
      el2[0].text.includes("incomplete")
    )
      return 1;
    if (el1[0].text > el2[0].text) {
      return sorting ? -1 : 1;
    }
    if (el1[0].text < el2[0].text) {
      return sorting ? 1 : -1;
    }
    return 0;
  };

  render() {
    const {
      header,
      handleChangeMode,
      isEditMode,
      row: RowItem,
      handleDataChanged,
      customSortList,
      isAbleToEdit,
      isDataChanged
    } = this.props;
    const { sorting, data } = this.state;
    return (
      <Fragment>
        <div className="patient-care-custom-table-header">
          {
            <div
              onClick={this.handleChangeSortingOrder}
              style={header[0].style}
              className="patient-care-custom-table-heading-col"
            >
              <span className="patient-care-link">
                {header[0].text}{" "}
                {sorting ? (
                  <Icon
                    size="smaller"
                    icon={<IconSortUp outline />}
                    type="inline"
                  />
                ) : (
                  <Icon
                    size="smaller"
                    icon={<IconSortDown outline />}
                    type="inline"
                  />
                )}
              </span>
            </div>
          }
          {[...header.slice(1, header.length - 1)].map((column, index) => {
            return (
              <div
                style={column.style}
                key={index}
                className="patient-care-custom-table-heading-col"
              >
                {column.text}
              </div>
            );
          })}
          <div
            className="patient-care-custom-table-heading-col"
            onClick={
              header[header.length - 1].text.length > 0
                ? handleChangeMode
                : () => {}
            }
            style={header[header.length - 1].style}
          >
            {isAbleToEdit && (
              <span className="patient-care-link">
                {isEditMode ? "Done" : header[header.length - 1].text}
              </span>
            )}
          </div>
        </div>
        <div className={`patient-care-custom-table-data ${isDataChanged ? "is-data-changed" : ""}`}>
          {data.sort(customSortList || this.sortList).map(row => {
            return (
              <div
                className="patient-care-custom-table-data-row"
                key={row[0].id}
              >
                {row.map((column, colIndex) => (
                  <RowItem
                    key={colIndex}
                    isEditMode={isEditMode}
                    data={column}
                    style={header[colIndex].style}
                    handleDeleteItem={this.handleDeleteItem}
                    handleDataChanged={handleDataChanged}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

export default CustomTable;
