import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from '@patient-access/ui-kit';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  organisationDetails: any,
  serviceId?: string,
  currentRole: any,
  userData: any,
};

const mapStateToProps = (state) => ({
  organisationDetails: state.organisationDetails,
  currentRole: state.roles.profileCurrentRole,
  userData: state.organisationServices.user.data,
});

class BreadcrumbsServices extends Component<Props> {
  render() {
    const { organisationId, organisationDetails, serviceId, currentRole, userData } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrganizationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    const isOrganisationDetails = organisationDetails && organisationDetails.name;
    const isUserData = userData && userData.serviceName;

    let breadcrumbs = {};
    if (isSuperAdmin) {
      breadcrumbs.home = {
        name: locale.Breadcrumbs.homeSuperAdmin,
        path: RoutesConstants.ADMIN_ORGANISATIONS
      };
      if (isOrganisationDetails) {
        breadcrumbs.organisation = {
          name: organisationDetails.name,
          path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`
        };
        breadcrumbs.services = {
          name: locale.Sidebars.services,
          path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICES}`
        };
        if (serviceId && isUserData) {
          breadcrumbs.service = {
            name: userData.serviceName,
            path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICE}/${serviceId}/${RoutesConstants.DETAILS}`
          };
        }
      } else {
        breadcrumbs.loading = {
          name: locale.Breadcrumbs.loading,
          path: `#loading`
        };
      }
    }
    if (isOrganizationAdmin) {
      breadcrumbs.services = {
        name: locale.Sidebars.services,
        path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICES}`
      };
      if (serviceId && isUserData) {
        breadcrumbs.service = {
          name: userData.serviceName,
          path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICE}/${serviceId}/${RoutesConstants.DETAILS}`
        };
      } else {
        breadcrumbs.loading = {
          name: locale.Breadcrumbs.loading,
          path: `#loading`
        };
      }
    }

    return (
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    );
  }
}

export default connect(mapStateToProps)(BreadcrumbsServices);
