import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import moment from "moment";
import { Form, FormItem, Checkbox, LinkExternal } from '@patient-access/ui-kit';

import { updateForm } from "actions/form";
import locale from 'service/locale';

type Props = {
  name: string,
  tosAcceptance: {
    dateOfAcceptance: string,
  } | null,
  organisationDetails: Organisation,
  handleAcceptanceEdit: () => any,
  form: any,
  updateForm: (data: any) => any,
};

type State = {
  isAccepted: boolean,
};

const mapStateToProps = (state) => ({
  form: state.form,
  organisationDetails: state.organisationDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

const checkAcceptance = (prop: Object | undefined): boolean => {
  return prop ? Object.keys(prop).includes("dateOfAcceptance") : false;
};

class StripeServiceAgreement extends Component<Props, State> {

  state = {
    isAccepted: checkAcceptance(this.props.tosAcceptance),
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.tosAcceptance !== null) {
      this.setState({
        isAccepted: checkAcceptance(nextProps.tosAcceptance)
      });
    }
  };

  handleChangeAcceptance = (e: Event) => {
    const {
      target: { checked }
    } = e;
    const { updateForm, form, organisationDetails, handleAcceptanceEdit } = this.props;

    this.setState({
      isAccepted: checked,
    }, () => {
      updateForm({
        ...form,
        tosAcceptance: checked,
        requiresMFA: organisationDetails.requiresMFA,
      });
      handleAcceptanceEdit();
    });
  };

  render() {
    const { name, tosAcceptance, form } = this.props;
    const { isAccepted } = this.state;
    const day = tosAcceptance && moment.utc(tosAcceptance.dateOfAcceptance).format("DD MMM");
    const time = tosAcceptance && moment.utc(tosAcceptance.dateOfAcceptance).format("HH:mm");
    const label = tosAcceptance
      ? (
        <Fragment>
          <LinkExternal
            to="https://stripe.com/gb/connect-account/legal"
            message={locale.PayoutDetails.stripeServiceLink}
            baseClassName="patient-care-link"
            target={locale.Share.targetBlank}
          />
          {locale.PayoutDetails.stripeServiceLabel1}
          <b>{name}</b>
          {locale.PayoutDetails.stripeServiceLabel2}
          {`${day} at ${time}`}
        </Fragment>
      )
      : (
        <Fragment>
          <b>{name}</b>
          {locale.PayoutDetails.stripeServiceLabel3}
          <LinkExternal
            to={locale.PayoutDetails.stripeServiceUrl}
            message={locale.PayoutDetails.stripeServiceLink}
            baseClassName="patient-care-link"
            target={locale.Share.targetBlank}
          />
          {locale.PayoutDetails.stripeServiceLabel4}
          <b>{name}</b>.
          {locale.PayoutDetails.stripeServiceLabel5}
        </Fragment>
      );

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading">
            <h4>
              {locale.PayoutDetails.payoutStep3}
            </h4>
          </div>
        </div>
        <Form noValidate>
          <FormItem type="item">
            <Checkbox
              id={locale.PayoutDetails.checkboxAgreement}
              name={locale.PayoutDetails.checkboxAgreement}
              value={name || ""}
              onChange={this.handleChangeAcceptance}
              checked={form.tosAcceptance || isAccepted}
              label={label}
              disabled={checkAcceptance(this.props.tosAcceptance)}
              data-id={locale.PayoutDetails.checkboxAgreement}
              className="input-checkbox-large"
            />
          </FormItem>
        </Form>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeServiceAgreement);
