import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { LinkIcon, IconClose } from "@patient-access/ui-kit";

import AppointmentInfo from 'components/Share/AppointmentDetailsContent/AppointmentInfo/AppointmentInfo';
import ClinicianInfo from 'components/Share/AppointmentDetailsContent/ClinicianInfo/ClinicianInfo';
import NewAppointmentForm from './NewAppointmentForm/NewAppointmentForm';

import locale from 'service/locale';
import './styles.scss';
import CustomAppointmentInfo from './CustomAppointmentInfo/CustomAppointmentInfo';
import * as ServiceConstants from "constants/ServicesConstants";
import * as RoutesConstants from 'constants/RoutesConstants';

type State = {
  isDataChanged: boolean,
  isDiscardChangesBookingOverlayOpen: boolean
}

type Props = {
  isActiveAgenda: boolean,
  selectedServiceId: string,
  handleOpenDiscardChangesOverlay: () => any,
  handleCloseExpiredSlotDialog: () => any,
  handleDiscardChanges: () => any,
}

const mapStateToProps = (state: any) => ({
  isActiveAgenda: state.panel.isActiveAgenda,
  selectedServiceId: state.book.service.id,
});

export class BookAppointment extends Component<Props, State> {

  state = {
    isDataChanged: false,
    isDiscardChangesBookingOverlayOpen: false
  };

  handleDataChange = () => {
    const { isDataChanged } = this.state;
    !isDataChanged && this.setState({ isDataChanged: true });
  };

  render() {
    const { isActiveAgenda, handleOpenDiscardChangesOverlay, handleCloseExpiredSlotDialog, handleDiscardChanges, selectedServiceId } = this.props;
    const { isDataChanged } = this.state;
    const isCustomAppointment = selectedServiceId === ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID;

    return (
      <div className={`patient-care-appointment-booking ${isActiveAgenda ? "patient-care-modal-full-height-with-agenda-holder" : "patient-care-modal-full-height-holder"}`}>
        <div className="patient-care-modal-full-height">
          <div className="patient-care-modal-header">
            <h1>{isCustomAppointment ? locale.BookAppointment.customAppointmentHeader : locale.BookAppointment.header}</h1>
            <div className="patient-care-btn-close">
              <LinkIcon
                to={RoutesConstants.HOME}
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.BookAppointment.closeIcon}
                onClick={isDataChanged ? handleOpenDiscardChangesOverlay : handleDiscardChanges}
              />
            </div>
          </div>
          <div className="patient-care-modal-content">
            {isCustomAppointment
              ? <CustomAppointmentInfo handleDataChange={this.handleDataChange} />
              : (
                <Fragment>
                  <AppointmentInfo />
                  <ClinicianInfo />
                </Fragment>)
            }
            <NewAppointmentForm
              isCustomAppointment={isCustomAppointment}
              handleDataChange={this.handleDataChange}
              handleCloseExpiredSlotDialog={handleCloseExpiredSlotDialog}
              handleCancel={isDataChanged ? handleOpenDiscardChangesOverlay : handleDiscardChanges}
            />
          </div>
        </div>
        <span className="patient-care-modal-overlay" />
      </div>
    )
  }
}



export default connect(mapStateToProps)(BookAppointment);
