import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, IconChevronDown, IconChevronUp, LogoPatientAccess, IconLaunch, IconMessages, IconFeedback, IconOrderDetails } from '@patient-access/ui-kit';
import type { Action } from 'types/actions';
import * as RolesConstants from 'constants/RolesConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';
import MainNavigation from './MainNavigation/MainNavigation';
import SnackbarWrapper from './SnackbarWrapper/SnackbarWrapper';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import UserDetailsModal from 'components/Pages/Admin/AdminUsers/UserDetailsModal/UserDetailsModal';
import { setTaskManagerIsVisible } from 'actions/taskManager';
import { closeCalendarDropdown } from 'actions/calendarDropdown';
import { getAgendaAppointments } from 'actions/agenda';
import { toggleAgenda } from 'actions/panel';
import locale from 'service/locale';
import { IconSupport, IconAccount, IconTask, IconTaskActive, IconTaskError, IconAgenda, IconAlertCircle } from 'svg/';
import './styles.scss';

type State = {
  isSupportOpen: boolean,
  isAccountOpen: boolean,
};

type Props = {
  isVisible: boolean,
  isActiveAgenda: boolean,
  isProfileDropdownOpen: boolean,
  isCalendarLinkOpened: boolean,
  currentUser: any,
  currentRole: any,
  calendarBranch: string,
  tasksList: any,
  closeCalendarDropdown: () => Action,
  setTaskManagerIsVisible: () => Action,
  getAgendaAppointments: () => any,
  toggleAgenda: () => any,
};

const mapStateToProps = (state) => ({
  isVisible: state.taskManager.isVisible,
  isActiveAgenda: state.panel.isActiveAgenda,
  isProfileDropdownOpen: state.panel.isProfileDropdownOpen,
  isActiveProfile: state.panel.isActiveProfile,
  isCalendarLinkOpened: state.calendarDropdown.isOpened,
  currentUser: state.profile,
  currentRole: state.roles.profileCurrentRole,
  calendarBranch: state.calendarView.branchName,
  tasksList: state.taskManager.tasksList.data,
  tasksListApiError: state.taskManager.tasksList.isError,
});

const mapDispatchToProps = (dispatch: Function) => ({
  closeCalendarDropdown: () => dispatch(closeCalendarDropdown()),
  setTaskManagerIsVisible: (isVisible: boolean) => dispatch(setTaskManagerIsVisible(isVisible)),
  getAgendaAppointments: () => dispatch(getAgendaAppointments()),
  toggleAgenda: () => dispatch(toggleAgenda()),
});

class Header extends Component<Props, State> {
  wrapperRefSupport = React.createRef();
  wrapperRefAccount = React.createRef();

  state = {
    isSupportOpen: false,
    isAccountOpen: false,
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutsideSupport);
    document.addEventListener('mousedown', this.handleClickOutsideAccount);
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutsideSupport);
    document.removeEventListener('mousedown', this.handleClickOutsideAccount);
  }

  handleClickOutsideSupport = (event: Event) => {
    if (this.wrapperRefSupport && this.wrapperRefSupport.current && !this.wrapperRefSupport.current.contains(event.target) && !event.target.closest(`.header-navigation-button-support`)) {
      this.setState({ isSupportOpen: false });
    }
  }

  handleClickOutsideAccount = (event: Event) => {
    if (this.wrapperRefAccount && this.wrapperRefAccount.current && !this.wrapperRefAccount.current.contains(event.target) && !event.target.closest(`.header-navigation-button-account`)) {
      this.setState({ isAccountOpen: false });
    }
  }

  handleClickSupport = (event: Event) => {
    const { isSupportOpen } = this.state;
    const { isCalendarLinkOpened, closeCalendarDropdown } = this.props;
    event.preventDefault();
    this.setState({ isSupportOpen: !isSupportOpen });
    if (isCalendarLinkOpened) closeCalendarDropdown();
  }

  handleClickAccount = (event: Event) => {
    const { isAccountOpen } = this.state;
    const { isCalendarLinkOpened, closeCalendarDropdown } = this.props;
    event.preventDefault();
    this.setState({ isAccountOpen: !isAccountOpen });
    if (isCalendarLinkOpened) closeCalendarDropdown();
  }

  handleTaskManagerButton = (event: Event) => {
    const { toggleAgenda, setTaskManagerIsVisible, isVisible, isActiveAgenda } = this.props;
    event.preventDefault();
    if (isActiveAgenda) toggleAgenda();
    setTaskManagerIsVisible(!isVisible);
  }

  handleAgendaButton = (event: Event) => {
    const { toggleAgenda, setTaskManagerIsVisible, getAgendaAppointments, isVisible, isActiveAgenda } = this.props;
    event.preventDefault();
    if (!isActiveAgenda) getAgendaAppointments();
    if (isVisible) setTaskManagerIsVisible(!isVisible);
    toggleAgenda();
  };

  render() {
    const { isSupportOpen, isAccountOpen } = this.state;
    const { currentUser, isActiveProfile, currentRole, calendarBranch, tasksList, tasksListApiError } = this.props;
    const isAdmin = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role);
    const isCalendarBranchSelected = !!(calendarBranch && calendarBranch.length);
    const canSeeAgendaOpener = !(currentRole.organisationType === ORGANISATION_TYPE.AFFILIATE) && (isCalendarBranchSelected || !isAdmin);

    const isTaskManagerError = tasksListApiError ? true : false;
    
    const fnIsTaskManagerActive = () => {
      const taskRunStatus = tasksList.some(task => task.runtimeStatus === "Pending" || task.runtimeStatus === "Running");
      return tasksListApiError ? false : taskRunStatus;
    }

    const fnIsTaskManagerErrorPartial = () => {
      const taskRunStatus = tasksList.some(task => (task.runtimeStatus === "Failed" && (task.customStatus === null || (task.customStatus &&  task.customStatus.Succeed !== task.customStatus.Total))) || (task.runtimeStatus === "Completed" && task.customStatus.Failed > 0));
      return isTaskManagerError || isTaskManagerActive ? false : taskRunStatus;
    }

    const isTaskManagerActive = fnIsTaskManagerActive();
    const isTaskManagerErrorPartial = fnIsTaskManagerErrorPartial();

    const noTaskInProgress = tasksList && tasksList.length > 0 ? tasksList.filter(task => task.runtimeStatus === "Running" && task.customStatus &&  task.customStatus.Queued < task.customStatus.Total).length : 0;
    const noTaskInQueue = tasksList && tasksList.length > 0 ? tasksList.filter(task => task.runtimeStatus === "Pending" || (task.runtimeStatus === "Running" && task.customStatus &&  task.customStatus.Queued === task.customStatus.Total)).length : 0;
    const noTaskWithErrors = tasksList && tasksList.length > 0 ? tasksList.filter(task => (task.runtimeStatus === "Failed" && (task.customStatus === null || (task.customStatus &&  task.customStatus.Succeed !== task.customStatus.Total))) || (task.runtimeStatus === "Completed" && task.customStatus &&  task.customStatus.Failed > 0)).length : 0;

    const renderSupportButton = () => {
      return (
        <Link className="header-navigation-button header-navigation-button-support" to="#support" onClick={this.handleClickSupport}>
          <Icon type="block" size="larger" icon={<IconSupport outline={true} />} />
          <p>
            <span>{locale.Header.support}</span>
            {isSupportOpen ? <IconChevronUp outline={true} /> : <IconChevronDown outline={true} />}
          </p>
        </Link>
      );
    }

    const renderSupportDropdown = () => {
      if (isSupportOpen) {
        return (
          <div className="header-navigation-support" ref={this.wrapperRefSupport}>
            <ul>
              <li>
                <a rel="noopener noreferrer" href="https://supportpro.patientaccess.com/contact-support-form" target="_blank">
                  <Icon type="inline" size="large" icon={<IconMessages outline={false} />} />
                  <span>{locale.Header.supportLinkOne}</span>
                  <Icon type="inline" size="smaller" icon={<IconLaunch outline={true} />} />
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="https://supportpro.patientaccess.com/professionals" target="_blank">
                  <Icon type="inline" size="large" icon={<IconOrderDetails outline={false} />} />
                  <span>{locale.Header.supportLinkTwo}</span>
                  <Icon type="inline" size="smaller" icon={<IconLaunch outline={true} />} />
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="https://patientplatform.typeform.com/to/DCDwyS" target="_blank">
                  <Icon type="inline" size="large" icon={<IconFeedback outline={false} />} />
                  <span>{locale.Header.supportLinkThree}</span>
                  <Icon type="inline" size="smaller" icon={<IconLaunch outline={true} />} />
                </a>
              </li>
            </ul>
          </div>
        );
      }
      return null;
    }

    const renderAccoutButton = () => {
      return (
        <Link className="header-navigation-button header-navigation-button-account" to="#account" onClick={this.handleClickAccount}>
          <Icon type="block" size="larger" icon={<IconAccount outline={true} />} />
          <p>
            <span>{currentUser.displayName}</span>
            {isAccountOpen ? <IconChevronUp outline={true} /> : <IconChevronDown outline={true} />}
          </p>
        </Link>
      );
    }

    const renderAccountDropdown = () => {
      if (isAccountOpen) return (
        <div className="header-navigation-account" ref={this.wrapperRefAccount}>
          <ProfileDropdown />
        </div>
      );
      return null;
    }

    const renderTaskManagerIcon = () => {
      if (isTaskManagerActive) return <IconTaskActive outline={true} />;
      if (isTaskManagerError) return <IconTaskError outline={true} />;
      if (isTaskManagerErrorPartial) return <IconAlertCircle outline={false} />;
      return <IconTask outline={true} />;
    }

    const renderTaskManagerTooltip = () => {
      return (
        <div className="header-navigation-tooltip">
          <p>{locale.TaskManager.tooltip.inProgress(noTaskInProgress)}</p>
          <p>{locale.TaskManager.tooltip.inQueue(noTaskInQueue)}</p>
          <p>{locale.TaskManager.tooltip.errors(noTaskWithErrors)}</p>
        </div>
      );
    }

    const renderTaskManagerButton = () => {
      return (
        <div className="header-navigation-item">
          <Link className={`header-navigation-button${isTaskManagerActive ? ' header-navigation-button-active' : ''}${isTaskManagerErrorPartial ? ' header-navigation-button-warning' : ''}`} to="#task-manager" onClick={this.handleTaskManagerButton}>
            <Icon type="block" size="larger" icon={renderTaskManagerIcon()} />
            <p><span>{locale.Header.tasks}</span></p>
          </Link>
          {renderTaskManagerTooltip()}
        </div>
      );
    }

    const renderAgendaButton = () => {
      if (canSeeAgendaOpener) {
        return (
          <div className="header-navigation-item">
            <Link className="header-navigation-button" to="#agenda" onClick={this.handleAgendaButton}>
              <Icon type="block" size="larger" icon={<IconAgenda outline={true} />} />
              <p><span>{locale.Header.agenda}</span></p>
            </Link>
          </div>
        );
      }
      return null;
    }

    return (
      <header className="patient-care-header">
        <div className="patient-care-header-container">
          <div className="patient-care-nav-col">
            <div className="patient-care-row-align-content-center">
              <div className="patient-care-logo-wrap">
                <div className="patient-care-logo">
                  <a href={RoutesConstants.HOME}><LogoPatientAccess /></a>
                </div>
              </div>
              <MainNavigation />
            </div>
          </div>
          <div className="patient-care-user-col">
            <div className="header-navigation">
              <div className="header-navigation-item">
                {renderSupportButton()}
                {renderSupportDropdown()}
              </div>
              <div className="header-navigation-item">
                {renderAccoutButton()}
                {renderAccountDropdown()}
              </div>
              {isAdmin && renderTaskManagerButton()}
              {renderAgendaButton()}
            </div>
          </div>
        </div>
        {isActiveProfile ? <UserDetailsModal /> : null}
        <SnackbarWrapper />
      </header>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
