import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ScrollTo } from 'react-scroll';
import './styles.scss';

class SectionConnect extends Component {
  render() {
    return (
      <div className="section-block section-connect">
        <div className="wrapper section-connect-wrapper">
          <div className="section-connect-blob" style={{ top: '-24px', right: '30px' }}>
            <img src="/assets/images/blobs/blob-one.svg" alt="" width="1000" />
          </div>
          <div className="section-connect-image">
            <img src="/assets/images/landing/section-connect.png" alt="" />
          </div>
          <div className="section-connect-content">
            <h1><FormattedMessage id="landing.connect.header" /></h1>
            <p><FormattedMessage id="landing.connect.copy.line_one" /></p>
            <p><FormattedMessage id="landing.connect.copy.line_two" /></p>
            <ScrollTo to="section-more" className="ui-kit-button ui-kit-button-secondary ui-kit-button-large" offset={-80} duration={500} smooth="easeInOutCubic">
              <span><FormattedMessage id="landing.connect.button" /></span>
            </ScrollTo>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionConnect;
