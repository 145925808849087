import type { Action } from 'types/actions';
import agent from 'service/agent';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import type { Organisation } from 'types/organisation';
import { modifyOrganisationsList, getToken } from 'helpers/common';

const setOrganisationsList = (organisationsList: Organisation[], searchValue?: string): Action => ({
  type: OrganisationsConstants.SET_ORGANISATIONS_LIST,
  payload: { organisationsList, searchValue }
});

const setOrganisationsListForAsyncSelect = (organisationsList: Organisation[], searchValue?: string): Action => ({
  type: OrganisationsConstants.SET_ORGANISATIONS_LIST_ASYNC,
  payload: { organisationsListAsync: organisationsList, searchValueAsync: searchValue }
});

export const setOrganisationsCHList = (chOrganisationsList: Organisation[], chSearchValue?: string): Action => ({
  type: OrganisationsConstants.SET_ORGANISATIONSCH_LIST,
  payload: { chOrganisationsList, chSearchValue }
});

export const setOrganisationsPending = (): Action => ({
  type: OrganisationsConstants.SET_ORGANISATIONS_PENDING,
});

export const setOrganisationsCHPending = (): Action => ({
  type: OrganisationsConstants.SET_ORGANISATIONSCH_PENDING,
});

export const getOrganisations = () => (dispatch: Function) => {
  dispatch(setOrganisationsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getOrganisations(accessToken)
        .then(organisationsList => {
          organisationsList.items = modifyOrganisationsList(organisationsList);
          dispatch(setOrganisationsList(organisationsList));
        })
        .catch(err => {
          console.log('getOrganisations server error', err);
        });
    });
};

export const getFilteredOrganisations = (
  pageNumber: number,
  searchValue: string,
  mode: number,
  organisationId?: string,
  callback?: Function,
) => (dispatch: Function) => {
  dispatch(setOrganisationsPending());
  getToken(dispatch)
    .then(accessToken => {
      const itemsPerPage = 30;
      agent.Organisations.getFilteredOrganisations(pageNumber, searchValue, itemsPerPage, accessToken, mode, organisationId)
        .then(organisationsList => {
          organisationsList.items = modifyOrganisationsList(organisationsList);
          !callback && dispatch(setOrganisationsList(organisationsList, searchValue));
          callback && callback(organisationsList.items);
        })
        .catch(err => {
          console.log('getFilteredOrganisations server error or organisation was not found', err);
          callback && callback();
        });
    });
};

export const getOrganisationsForAsyncSelect = (searchValue?: string, callback?: Function) => (dispatch: Function) => {
  getToken(dispatch)
    .then(accessToken => {
      const pageNumber = 1;
      const itemsPerPage = 999;
      const searchMode = OrganisationsConstants.SEARCH_MODE.ORGANISATIONS;

      agent.Organisations.getFilteredOrganisations(pageNumber, (searchValue || ""), itemsPerPage, accessToken, searchMode)
        .then(organisationsList => {
          organisationsList.items = modifyOrganisationsList(organisationsList);
          dispatch(setOrganisationsListForAsyncSelect(organisationsList, (searchValue || "")));
          callback && callback(organisationsList.items);
        })
        .catch(err => {
          console.log('getFilteredOrganisations server error or organisation was not found', err);
          callback && callback();
        });
    });
};

export const getFilteredOrganisationsCH = (searchValue: string, callback: Function) => (dispatch: Function) => {
  if (searchValue.length < 3) {
    dispatch(setOrganisationsCHList([], searchValue));
    return;
  }

  dispatch(setOrganisationsCHPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getFilteredOrganisationsCH(searchValue, accessToken)
        .then(searchResults => {
          const { items } = searchResults;

          items.forEach(organisation => {
            organisation.label = organisation.companyName;
            organisation.value = organisation.companyNumber;
          });
          dispatch(setOrganisationsCHList(items, searchValue));
          callback(items);
        })
        .catch(err => {
          console.log('getFilteredOrganisationsCH ', err);
          callback();
        });
    });

};
