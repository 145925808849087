import React, { Component } from "react";
import { connect } from 'react-redux';
import NavigationPrompt from "react-router-navigation-prompt";
import { Button, Skeleton } from "@patient-access/ui-kit";
import { uniqBy, isEqual } from "lodash";

import OpeningHours from './OpeningHours/OpeningHours';
import ClosedDays from './ClosedDays/ClosedDays';
import BookingCutoff from "./BookingCutoff/BookingCutoff";
import TimeOff from "./TimeOff/TimeOff";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";

import { updateAvailabilitySettings, getAvailabilitySettings, closeUpdateAvailabilityConflictOverlay } from 'actions/calendars';
import { updateForm, clearForm } from "actions/form";
import type { Action } from 'types/actions';
import { closeModalOverlay } from 'actions/panel';
import type { Calendar, ClosedDay } from 'types/calendars';
import * as CalendarsConstants from 'constants/CalendarsConstants';
import { checkOpenHours } from "helpers/validateData";

import locale from 'service/locale';

type Props = {
  branchId: string,
  organisationId: string,
  updateForm: (data: any) => Action,
  clearForm: () => Action,
  currentRole: Object,
  isActiveAgenda: boolean,
  calendar: Calendar,
  form: Object,
  getAvailabilitySettings: (organisationId: string, branchId: string) => Action,
  updateAvailabilitySettings: (branchId: string) => Action,
  closeModalOverlay: () => Action,
  isGetPending: false,
  isUpdatePending: false,
  appointmentsWithConflict: string[],
  isUpdateConflictOverlayOpened: boolean,
  closeUpdateAvailabilityConflictOverlay: () => Action
};

type State = {
  isEditOpeningHoursMode: boolean,
  isEditTimeOffMode: boolean,
  isEditClosedDaysMode: boolean,
  isEditBookingCutoffMode: boolean,
  isDataChanged: boolean,
  isDiscardModalOpen: boolean,
  hasAvailabilityErrors: boolean,
  isPastDate: boolean
}

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  isActiveAgenda: state.panel.isActiveAgenda,
  calendar: state.calendar,
  form: state.form,
  isGetPending: state.calendar.isGetPending,
  isUpdatePending: state.calendar.isUpdatePending,
  appointmentsWithConflict: state.calendar.appointmentsWithConflict,
  isUpdateConflictOverlayOpened: state.calendar.isUpdateConflictOverlayOpened
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  getAvailabilitySettings: (organisationId: string, branchId: string) => dispatch(getAvailabilitySettings(organisationId, branchId)),
  updateAvailabilitySettings: (branchId: string) => dispatch(updateAvailabilitySettings(branchId)),
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  closeUpdateAvailabilityConflictOverlay: () => dispatch(closeUpdateAvailabilityConflictOverlay()),
});

class AvailabilitySettings extends Component<Props,State> {

  state = {
    isEditOpeningHoursMode: false,
    isEditTimeOffMode: false,
    isEditClosedDaysMode: false,
    isEditBookingCutoffMode: false,
    isDataChanged: false,
    isDiscardModalOpen: false,
    hasAvailabilityErrors: false,
    newClosedDays: [],
    isPastDate: false
  };

  componentDidMount = () => {
    const { getAvailabilitySettings, organisationId, branchId } = this.props;
    getAvailabilitySettings(organisationId, branchId);
  };

  componentWillUnmount = () => {
    const { clearForm } = this.props;
    clearForm();
  };

  handleChangeOpeningHours = () => {
    const { updateForm, calendar, form } =  this.props;
    const { isEditOpeningHoursMode } = this.state;
    const { openHours } = calendar;

    this.setState(() => {
      !form.openHours && updateForm({
        openHours: openHours || CalendarsConstants.DefaultOpenHours,
      });
      return {
        isEditOpeningHoursMode: !isEditOpeningHoursMode,
        isDataChanged: true
      };
    });
  };

  handleBranchTimeOff = (dataChanged?: TimeOff) => {
    this.setState(() => {
      return {
        isDataChanged: dataChanged
      };
    });

  }

  handleEditTimeOff = (editmode?: boolean) => {
    const { isEditTimeOffMode } = this.state;

    this.setState(() => {
      return {
        isEditTimeOffMode: !isEditTimeOffMode
      };
    });
  }

  handleEditClosedDays = (closedDays?: ClosedDay[] = [], isPastDate: boolean) => {
    const { form , calendar } = this.props;
    const { isEditClosedDaysMode, newClosedDays } = this.state;
    const isDataChanged = !!closedDays.length || !!newClosedDays.length ||
      (!isEqual(form.closedDays, calendar.closedDays) && Object.keys(form).includes("closedDays"));

    this.setState({
      isEditClosedDaysMode: closedDays.length ? true : !isEditClosedDaysMode,
      isDataChanged,
      newClosedDays: closedDays.length ? closedDays : newClosedDays,
      isPastDate,
    });
  };

  handleEditBookingCutoff = () => {
    const { isEditBookingCutoffMode } = this.state;
    const { updateForm, calendar, form } =  this.props;
    const { bookingCutoff } = calendar;

    this.setState(() => {
      !form.bookingCutoff && updateForm({
        bookingCutoff: bookingCutoff,
      });
      return {
        isEditBookingCutoffMode: !isEditBookingCutoffMode,
        isDataChanged: true,
      };
    });
  };

  handleOpenDiscardDialog = () => {
    this.setState({ isDiscardModalOpen: true });
  };

  handleDiscardChanges = (e: any) => {
    e && e.preventDefault();
    const { clearForm, closeModalOverlay } = this.props;
    this.setState({
      isEditClosedDaysMode: false,
      isEditTimeOffMode: false,
      isEditOpeningHoursMode: false,
      isEditBookingCutoffMode: false,
      isDataChanged: false,
      isDiscardModalOpen: false,
      hasAvailabilityErrors: false,
    }, () => {
      clearForm();
      closeModalOverlay();
    });
  };

  handleStay = () => {
    this.setState({ isDiscardModalOpen: false });
  };

  handleUpdateBranch = (e: Event) => {
    e && e.preventDefault();
    const { newClosedDays } = this.state;
    const { form, updateAvailabilitySettings, branchId, calendar, updateForm } = this.props;
    const { openHours, closedDays, bookingCutoff } = form;

    if (!openHours) form.openHours = calendar.openHours;
    if (!bookingCutoff) form.bookingCutoff = calendar.bookingCutoff;
    if (!closedDays) {
      form.closedDays = newClosedDays.length ? calendar.closedDays.concat(newClosedDays) : calendar.closedDays;
    } else {
      const updatedDays = uniqBy(form.closedDays.concat(newClosedDays), "date");
      newClosedDays.length && updateForm({
        ...form,
        closedDays: updatedDays
      });
    }

    this.setState({
      newClosedDays: [],
      isEditOpeningHoursMode: false,
      isEditTimeOffMode: false,
      isEditClosedDaysMode: false,
      isEditBookingCutoffMode: false
    }, () => {
      updateAvailabilitySettings(branchId)
        .then(hasConflict => {
          this.setState({
            isDataChanged: hasConflict,
            hasAvailabilityErrors: false
          });
        });
    });
  };

  getAppointmentsWithConflictMessage = () => {
    let { appointmentsWithConflict } = this.props;
    const bodyMessageConstructor = locale.Modals.updateAvailabilityConflictOverlay.body;
    const oneOrMany = (appointmentsWithConflict || []).length === 1
      ? bodyMessageConstructor[1]
      : bodyMessageConstructor[2];
    return (
      <div>
        {`${bodyMessageConstructor[0]} ${(appointmentsWithConflict || []).length} ${oneOrMany} ${bodyMessageConstructor[3]}`}
      </div>
    );
  };

  handleUpdateAvailabilityConflictBack = () => {
    const { closeUpdateAvailabilityConflictOverlay } = this.props;
    closeUpdateAvailabilityConflictOverlay();
  };

  handleDeleteDay = () => {
    this.setState({
      isDataChanged: true
    });
  };

  render() {
    const {
      isEditOpeningHoursMode,
      isEditTimeOffMode,
      isEditClosedDaysMode,
      isEditBookingCutoffMode,
      isDataChanged,
      isDiscardModalOpen,
      hasAvailabilityErrors,
      isPastDate,
    } = this.state;
    const { isActiveAgenda, isGetPending, isUpdatePending, isUpdateConflictOverlayOpened, form, calendar } = this.props;
    const openHours = form.openHours || calendar.openHours;
    const {
      isNotMultipleByFive,
      isEqualTime,
      isStartTimeLessThanEndTime,
      hasEmptyPeriod,
      isIntervalInvalid,
      hasInvalidTime
    } = checkOpenHours(openHours);

    return (
      <div className={isDataChanged ? "patient-care-details-holder data-changed" : "patient-care-details-holder" }>
        { isGetPending
          ? <Skeleton type="basic" className="availability-skeleton"/>
          : <div className="patient-care-container-sm">
              <OpeningHours handleChangeOpeningHours={this.handleChangeOpeningHours} isEditMode={isEditOpeningHoursMode} hasAvailabilityErrors={hasAvailabilityErrors} />
              <TimeOff handleBranchTimeOff={this.handleBranchTimeOff} handleEditTimeOff={this.handleEditTimeOff} isEditMode={isEditTimeOffMode}/>
              <ClosedDays handleEditClosedDays={this.handleEditClosedDays} isEditMode={isEditClosedDaysMode} handleDeleteDay={this.handleDeleteDay} />
              <BookingCutoff handleEditBookingCutoff={this.handleEditBookingCutoff} isEditMode={isEditBookingCutoffMode} />
            </div>
        }

      {( isDataChanged || isUpdatePending) &&
        <div className={`patient-care-buttons-fixed-bottom ${isActiveAgenda ? "is-active-agenda" : ""}`}>
          <div className="patient-care-container-sm">
            <div className="patient-care-row-align-right">
              <Button
                buttonType="blueline"
                messageKey="discard-btn"
                defaultMessage={locale.Appointment.buttons.discardChanges}
                onClick={this.handleOpenDiscardDialog}
                isLoading={isUpdatePending}
                data-id="discard-btn"
                className="patient-care-btn-in-group"
              />
              <Button
                buttonType="secondary"
                messageKey="save-changes-btn"
                defaultMessage={locale.Appointment.buttons.saveChanges}
                onClick={this.handleUpdateBranch}
                isLoading={isUpdatePending}
                data-id="save-changes-btn"
                className="patient-care-btn-in-group"
                isDisabled={ isNotMultipleByFive || isEqualTime || isStartTimeLessThanEndTime || hasEmptyPeriod || isIntervalInvalid || isPastDate || hasInvalidTime}
                tabIndex={isNotMultipleByFive || isEqualTime || isStartTimeLessThanEndTime || hasEmptyPeriod || isIntervalInvalid || hasInvalidTime? -1 : 0}
              />
            </div>
          </div>
        </div>
      }
        {
          isDiscardModalOpen && (
          <DiscardChangesOverlay
            handleDiscardChanges={this.handleDiscardChanges}
            handleStay={this.handleStay}
          />
        )}
        {
          isUpdateConflictOverlayOpened &&
          <InformationalOverlay
            {...locale.Modals.updateAvailabilityConflictOverlay}
            content={this.getAppointmentsWithConflictMessage()}
            handleOk={this.handleUpdateAvailabilityConflictBack}
          />
        }
        <NavigationPrompt when={isDataChanged || isUpdatePending} >
          {({ onConfirm, onCancel }) => (
            <DiscardChangesOverlay
              handleStay={onCancel}
              handleDiscardChanges={onConfirm}
            />
          )}
        </NavigationPrompt>
      </div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilitySettings);
