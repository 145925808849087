import moment from "moment";

import * as BranchesConstants from "constants/BranchesConstants";
import * as RolesConstants from "constants/RolesConstants";
import * as ProfileConstants from "constants/ProfileConstants";
import type { Action } from "types/actions";
import type { UserState } from "types/user";
import { showSnackbarStatus } from "actions/snackbar";
import { clearForm } from "actions/form";
import { setProfileRoles, saveRoleToCookie } from "actions/roles";
import { closeUserDetails, closeChangePassword, resetGeneralError } from "actions/panel";
import { setOrganisationHeader, setBranchHeader } from "actions/router";
import { getToken } from "helpers/common";
import {
  loginUserPending,
  loginUserPendingSuccess,
  loginUserPendingError,
  clearLoginUserError,
  hideLoginUserWarning,
  loginUserSuccess,
  loginUserError,
  logoutUser
} from "actions/login";
import { setCancellationStatus, setCalendarFilters, setServiceFilters, setStatusFilters, setBranchName, setBranchId, setOrganisationId } from "actions/calendarView";
import agent from "service/agent";
import locale from "service/locale";
import { setRefreshTokenCookie, getRefreshTokenCookie } from "service/cookie";

const setCurrentProfile = (currentProfile: UserState): Action => ({
  type: ProfileConstants.SET_CURRENT_PROFILE,
  payload: currentProfile,
});

export const setConfirmationStatus = (status: string): Action => ({
  type: ProfileConstants.SET_CONFIRMATION_STATUS,
  payload: status
});

export const setCurrentAdminUrl = (adminUrl: string): Action => ({
  type: ProfileConstants.SET_CURRENT_PROFILE_URL,
  payload: adminUrl,
});

export const setActiveAdminMenu = (status: boolean): Action => ({
  type: ProfileConstants.SET_ACTIVE_ADMIN_MENU,
  payload: status,
});

export const setActiveReportsMenu = (status: boolean): Action => ({
  type: ProfileConstants.SET_ACTIVE_REPORTS_MENU,
  payload: status,
});

export const userLogin = (email: string, password: string, recaptchaToken: string) => (dispatch: Function) => {
  dispatch(resetGeneralError());
  dispatch(loginUserPending());
  dispatch(clearLoginUserError());
  dispatch(hideLoginUserWarning());
  agent.Auth.login(email, password, recaptchaToken)
    .then(authData => {
      setTokenDetails(authData);
      const { access_token, expires_in, refresh_token } = authData;
      loadUser(access_token, expires_in, refresh_token)(dispatch);
    })
    .catch(err => {
      dispatch(loginUserPendingError());
      if (err && err.response) {
        const { ErrorCode, NextUnsuccessfulAttemptWillLockUser } = err.response.body;
        dispatch(loginUserError(ErrorCode, NextUnsuccessfulAttemptWillLockUser));
      } else
          console.log('User login server error', err);
    });
};

export const setSessionIdInSessionStorage = (sessionId) => {
  sessionStorage.setItem('x-sessionId', sessionId);
};

export const loadUser = (access_token, expires_in, refresh_token) => (dispatch) => {
  Promise.all([ agent.Auth.getCurrentUser(access_token), agent.Auth.getCurrentUserRoles(access_token) ])
  .then(([currentUser, userRoles]) => {
    dispatch(loginUserPendingSuccess());
    setSessionIdInSessionStorage(currentUser.sessionId);
    currentUser = saveRoleToCookie(currentUser, currentUser.email);
    dispatch(setCalendarFilters(currentUser.calendarFilters));
    dispatch(setServiceFilters(currentUser.serviceFilters));
    dispatch(setStatusFilters(currentUser.statusFilters));
    dispatch(setCancellationStatus(currentUser.showCancellation));
    dispatch(setCurrentProfile(currentUser));
    dispatch(setUsersOrganisation(currentUser.currentRole));
    dispatch(setOrganisationHeader(currentUser.currentRole.organizationId));
    dispatch(setBranchHeader(currentUser.currentRole.branchId));
    dispatch(setBranchName(currentUser.currentRole.branchName));
    dispatch(setOrganisationId(currentUser.currentRole.organizationId));
    dispatch(setBranchId(currentUser.currentRole.branchId));
    dispatch(setProfileRoles(currentUser, userRoles));
    dispatch(loginUserSuccess(currentUser.email, access_token, expires_in, refresh_token));
  })
  .catch(err => {
    dispatch(loginUserPendingError());
    if (err.response && err.response.body && err.response.body[RolesConstants.NO_ROLE_ERROR.errorCode]) {
      dispatch(loginUserError(RolesConstants.NO_ROLE_ERROR.errorCode, false));
    } else
      console.log('User login server error', err);
  });
}

export const requestUserData = () => (dispatch: Function) => {
  getToken(dispatch)
    .then(access_token => {
      const expires_in = sessionStorage.getItem("expires_in");
      const refresh_token = getRefreshTokenCookie();

      dispatch(setBranchHeader(BranchesConstants.DEFAULT_BRANCHID));
      Promise.all([ agent.Auth.getCurrentUser(access_token), agent.Auth.getCurrentUserRoles(access_token) ])
        .then(([currentUser, userRoles]) => {
          currentUser = saveRoleToCookie(currentUser, currentUser.email);
          dispatch(setCancellationStatus(currentUser.showCancellation));
          dispatch(setCalendarFilters(currentUser.calendarFilters));
          dispatch(setServiceFilters(currentUser.serviceFilters));
          dispatch(setStatusFilters(currentUser.statusFilters));
          dispatch(setCurrentProfile(currentUser));
          dispatch(setUsersOrganisation(currentUser.currentRole));
          dispatch(setOrganisationHeader(currentUser.currentRole.organizationId));
          dispatch(setBranchHeader(currentUser.currentRole.branchId));
          dispatch(setBranchName(currentUser.currentRole.branchName));
          dispatch(setOrganisationId(currentUser.currentRole.organizationId));
          dispatch(setBranchId(currentUser.currentRole.branchId));
          dispatch(setProfileRoles(currentUser, userRoles));
          dispatch(loginUserSuccess(currentUser.email, access_token, expires_in, refresh_token));
        })
        .catch(err => {
          dispatch(logoutUser());
          console.log('Request user credentials server error', err);
        });
    })
    .catch(err => {
      dispatch(logoutUser());
      console.log('Request user credentials server error', err);
    });
};

export const setPasswordMismatch = (code: string): Action => ({
  type: ProfileConstants.SET_PASSWORD_MISMATCH,
  payload: code
});

export const resetPasswordMismatch = (): Action => ({
  type: ProfileConstants.RESET_PASSWORD_MISMATCH
});

export const sendResetPasswordEmail = (email: string, recaptchaToken: string) => (dispatch: Function) => {
  agent.Auth.sendResetPasswordEmail(email, recaptchaToken)
    .then(() => {
      dispatch(setConfirmationStatus("EmailSent"));
    })
    .catch(() => {
      dispatch(setConfirmationStatus("WrongEmail"));
    });
};

export const confirmNewPassword = (data: any, recaptchaToken: string) => (dispatch: Function) => {
  agent.Auth.confirmNewPassword(data)
    .then(confirmationResult => {
        const { email } = confirmationResult;
        const { newPassword } = data;
        dispatch(userLogin(email, newPassword, recaptchaToken));
    })
    .catch(err => {
      if (err && err.response && err.response.body.errors) {
        const { code, description } = err.response.body.errors[0];
        if (code === "InvalidToken")
          dispatch(setConfirmationStatus("InvalidToken"));
        if (description === "Password can't contain user name.")
          dispatch(setConfirmationStatus("ContainsEmail"));
      } else {
        console.log('Confirm new password server error', err);
      }
    });
};

export const sendResetPasswordEmailFromAdmin = (email: string) => (dispatch: Function) => {
  getToken(dispatch)
    .then(accessToken => {
      agent.Auth.sendResetPasswordEmailFromAdmin(email, accessToken)
        .then(() => {
          dispatch(closeUserDetails());
          dispatch(showSnackbarStatus(locale.UserDetails.resetPasswordSnackbar));
        })
        .catch(() => {
          dispatch(showSnackbarStatus(locale.UserDetails.resetPasswordSnackbarError));
        })
    }).catch(err => {
      dispatch(showSnackbarStatus(locale.UserDetails.resetPasswordSnackbarError));
    });
};

export const changePassword = (data: any) => (dispatch: Function) => {
  getToken(dispatch)
    .then(accessToken => {
      agent.Auth.changePassword(data, accessToken)
        .then(() => {
          dispatch(clearForm());
          dispatch(closeChangePassword());
          dispatch(showSnackbarStatus(locale.Snackbar.passwordUpdated));
        })
        .catch(err => {
          dispatch(showSnackbarStatus(locale.Snackbar.passwordNotUpdated));
          console.log('Change password server error', err);
          if (err && err.response) {
            const { code } = err.response.body.errors[0];
            if (code === "PasswordMismatch") {
              dispatch(setPasswordMismatch("PasswordMismatch"));
            }
          }
        });
    });

};

export const setTokenDetails = (authData: any) => {
  const { access_token, expires_in, refresh_token } = authData;
  const expires = moment().unix() + expires_in;
  sessionStorage.setItem("token", access_token);
  // set the expired time as (now + value from server)
  sessionStorage.setItem("expires_in", expires);
  setRefreshTokenCookie(refresh_token);
};

export const setUsersOrganisation = (currentRole: any) => (dispatch: Function) => {
  if (currentRole.role !== RolesConstants.ADMIN) {
    getToken(dispatch)
      .then(accessToken => {
        agent.Organisations.getOrganisationDetails(currentRole.organizationId, accessToken)
          .then(organisationDetails => {
            dispatch({
              type: ProfileConstants.SET_CURRENT_PROFILE_ORGANISATION,
              payload: organisationDetails,
            });
          })
          .catch(err => {
            console.log('setUsersOrganisation server error or organisation is not found', err);
          });
      })
  } else {
    dispatch({
      type: ProfileConstants.SET_CURRENT_PROFILE_ORGANISATION,
      payload: {},
    })
  }
};

export const checkForgotPasswordToken = (data: any) => (dispatch: Function) => {
  agent.Auth.checkExpiredLink(data)
    .catch(err => {
      if (err.response && err.response.body) {
        dispatch({
          type: ProfileConstants.SET_PASSWORD_LINK_EXPIRED,
          payload: err.response.body.errors
        });
      } else {
        console.log("checkForgotPasswordToken server error ", err);
      }
    })
};
