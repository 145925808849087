import React, { Component } from 'react';

import './styles/sidebar-header.scss';

type Props = {
  children: any,
};

class SidebarHeader extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="sidebar-header">
        {children}
      </div>
    );
  }
};

export default SidebarHeader;
