const defaultES = {

};

const es = Object.assign(
  {},
  defaultES,
);

export default es;
