import * as ReportsConstants from "constants/ReportsConstants";
import type {
  Reports
} from "types/reports";

const initialState: Reports = {
  revenue: {},
  appointment: {},
  lastRunDate: null,
  filters: {
    organisationIds: [],
    branchIds: [],
    serviceIds: [],
    statuses: [],
    startDate: ReportsConstants.DEFAULT_REPORT_STARTDATE,
    endDate: ReportsConstants.DEFAULT_REPORT_ENDDATE
  },
  selectedOrganisation: {},
  selectedBranch: {},
  selectedService: {},
  selectedStatus: {},
  download: {
    data: [],
    isOverlayVisible: false,
    isLoading: false,
    isError: false
  },
  isLoading: false,
  isError: false
};

export default (
  localState: Reports = initialState,
  action: Action
): Reports => {
  switch (action.type) {
    case ReportsConstants.GET_REPORTS_PENDING:
      return {
        ...localState,
        isLoading: true,
        isError: false
      };
    case ReportsConstants.GET_REPORTS_SUCCESS:
      return {
        ...localState,
        isLoading: false,
        isError: false
      };
    case ReportsConstants.GET_REPORTS_ERROR:
      return {
        ...localState,
        isLoading: false,
        isError: true
      };
    case ReportsConstants.SET_REPORTS_ITEMS:
      const { revenue, appointment, lastRunDate } = action.payload;
      return {
        ...localState,
        revenue: {
            ...revenue,
            detail: {
              ...revenue.detail,
              totalFees: revenue.detail.commission + revenue.detail.stripeFees
            }
          },
          appointment,
          lastRunDate
      };
    case ReportsConstants.SET_REPORT_DROPDOWN_ORGANISATION:
      return {
        ...localState,
        selectedOrganisation: action.payload,
      };
    case ReportsConstants.SET_REPORT_DROPDOWN_BRANCH:
      return {
        ...localState,
        selectedBranch: action.payload,
      };
    case ReportsConstants.SET_REPORT_DROPDOWN_SERVICE:
      return {
        ...localState,
        selectedService: action.payload,
      };
    case ReportsConstants.SET_REPORT_DROPDOWN_STATUS:
      return {
        ...localState,
        selectedStatus: action.payload
      }
    case ReportsConstants.CLEAR_REPORT_DROPDOWN_BRANCH:
      return {
        ...localState,
        selectedBranch: initialState.selectedBranch,
      };
    case ReportsConstants.CLEAR_REPORT_DROPDOWN_SERVICE:
      return {
        ...localState,
        selectedService: initialState.selectedService,
      };
    case ReportsConstants.SET_REPORT_FILTER:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          ...action.payload
        }
      };
    case ReportsConstants.SET_REPORTS_DOWNLOAD_OVERLAY:
      return {
        ...localState,
        download: {
          ...localState.download,
          isOverlayVisible: action.payload,
        },
      };
    case ReportsConstants.GET_REPORTS_DOWNLOAD_PENDING:
      return {
        ...localState,
        download: {
          ...localState.download,
          data: initialState.download.data,
          isLoading: true,
          isError: false
        },
      };
    case ReportsConstants.GET_REPORTS_DOWNLOAD_SUCCESS:
      return {
        ...localState,
        download: {
          ...localState.download,
          isLoading: false,
          isError: false
        },
      };
    case ReportsConstants.GET_REPORTS_DOWNLOAD_ERROR:
      return {
        ...localState,
        download: {
          ...localState.download,
          isLoading: false,
          isError: true
        },
      };
    case ReportsConstants.SET_REPORTS_DOWNLOAD_ITEMS:
      return {
        ...localState,
        download: {
          ...localState.download,
          data: action.payload
        },
      };
    case ReportsConstants.RESET_REPORT_FILTER_ORGANISATION:
      return {
        ...localState,
        selectedOrganisation: {},
        filters: {
           ...localState.filters,
           organisationIds: [],
        }
      };
    case ReportsConstants.RESET_REPORT_FILTER_BRANCH:
      return {
        ...localState,
        selectedBranch: {},
        filters: {
           ...localState.filters,
           branchIds: [],
        }
      };
    case ReportsConstants.RESET_REPORT_FILTER_REST:
      return {
        ...localState,
        selectedService: {},
        selectedStatus: {},
        filters: {
          ...localState.filters,
          serviceIds: [],
          statuses: [],
          startDate: ReportsConstants.DEFAULT_REPORT_STARTDATE,
          endDate: ReportsConstants.DEFAULT_REPORT_ENDDATE
        }
      };
    default:
      return localState;
  }
};
