import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { NoResults, Button, Pagination } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentHeader, FeatureContentScroll, FeaturePagination } from 'components/Share/feature';
import { SkeletonTable } from 'components/Share/skeleton';
import { columns, TableBranches } from './TableBranches/TableBranches';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import FilterBranches from './FilterBranches/FilterBranches';
import { getBranchesList } from 'actions/branchesList';
import * as SearchConstants from 'constants/SearchConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import locale from 'service/locale';

// ADD_BRANCH_WORKFLOW
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';
import AddBranchModal from '../BranchesList/AddBranchModal/AddBranchModal';
import DuplicateBranchModal from '../BranchesList/DuplicateBranchModal/DuplicateBranchModal';
import AddNewOnlineBranchOverlay from '../BranchesList/AddNewOnlineBranchOverlay/AddNewOnlineBranchOverlay';
import { openModalOverlay, closeModalOverlay, closeAddBranchError, closeAddBranchOdsError } from 'actions/panel';
import { createBranch } from 'actions/branches';
import { clearForm } from 'actions/form';
import * as RolesConstants from 'constants/RolesConstants';
import * as BranchesConstants from 'constants/BranchesConstants';

type State = {
  // ADD_BRANCH_WORKFLOW
  redirectUrl: string;
  isDiscardModalOpen: boolean;
};

type Props = {
  searchValue: string,
  data: any,
  pages: number,
  isLoading: boolean,
  isError: boolean,
  getBranchesList: (pageNumber: number, searchValue: string) => Action,

  // ADD_BRANCH_WORKFLOW
  createdBranch: Object,
  currentRole: any,
  adminUrl: any,
  form: any,
  isModalOverlayOpened: boolean,
  isActiveAddBranchError: boolean,
  isActiveAddBranchOdsError: boolean,
  clearForm: () => Action,
  openModalOverlay: () => Action,
  closeModalOverlay: () => Action,
  closeAddBranchError: () => Action,
  closeAddBranchOdsError: () => Action,
  createBranch: (form: Branch, redirect: boolean, organisationId?: string) => any,
};

const mapStateToProps = state => ({
  searchValue: state.branchesList.searchValue,
  data: state.branchesList.data,
  pages: state.branchesList.pages,
  isLoading: state.branchesList.isLoading,
  isError: state.branchesList.isError,

  // ADD_BRANCH_WORKFLOW
  createdBranch: state.branches.createdBranch,
  currentRole: state.roles.profileCurrentRole,
  adminUrl: state.profile.adminUrl,
  form: state.form,
  isModalOverlayOpened: state.panel.isModalOverlayOpened,
  isActiveAddBranchError: state.panel.isActiveAddBranchError,
  isActiveAddBranchOdsError: state.panel.isActiveAddBranchOdsError,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBranchesList: (pageNumber: number, searchValue: string) => dispatch(getBranchesList(pageNumber, searchValue)),

  // ADD_BRANCH_WORKFLOW
  clearForm: () => dispatch(clearForm()),
  openModalOverlay: () => dispatch(openModalOverlay()),
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  closeAddBranchError: () => dispatch(closeAddBranchError()),
  closeAddBranchOdsError: () => dispatch(closeAddBranchOdsError()),
  createBranch: (form: Branch, redirect: boolean, organisationId?: string, param?: any) => dispatch(createBranch(form, redirect, organisationId, param)),
});

class BranchesList extends Component<Props, State> {
  state = {
    // ADD_BRANCH_WORKFLOW
    redirectUrl: '#',
    isDiscardModalOpen: false,
  };

  componentDidMount = () => {
    const { getBranchesList } = this.props;
    getBranchesList(1, '');
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { searchValue, getBranchesList, createdBranch } = this.props;
    if (searchValue !== nextProps.searchValue) {
      if (nextProps.searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
        getBranchesList(1, nextProps.searchValue);
      } else {
        if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
          getBranchesList(1, '');
        }
      }
    }

    // ADD_BRANCH_WORKFLOW
    if (createdBranch !== nextProps.createdBranch) {
      if (nextProps.createdBranch.isRedirect) {
        this.setState({
          redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${nextProps.createdBranch.newOrganisationId}/${nextProps.createdBranch.newBranchId}/${RoutesConstants.USERS}`
        });
      }
    }
  }

  // ADD_BRANCH_WORKFLOW
  handleAddBranch = () => {
    const { openModalOverlay } = this.props;
    openModalOverlay();
  };

  handleCreateBranch = (redirect?: boolean = false) => {
    const { form, createBranch, currentRole, organisationId, getBranchesList } = this.props;
    const { address } = form;

    if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) {
      form.organisationId = currentRole.organizationId;
    }

    form.email = form.email || ""
    address.country = BranchesConstants.COUNTRY;
    createBranch(form, redirect, organisationId, getBranchesList);
  };

  handleOpenDiscardDialog = () => {
    this.setState({ isDiscardModalOpen: true });
  };

  handleDiscardChanges = () => {
    const { closeModalOverlay, clearForm } = this.props;
    this.setState({ isDiscardModalOpen: false }, () => {
      clearForm();
      closeModalOverlay();
    });
  };

  handleStay = () => {
    this.setState({ isDiscardModalOpen: false });
  };

  handleCloseAddBranchError = () => {
    const { isActiveAddBranchOdsError, isActiveAddBranchError, closeAddBranchError, closeAddBranchOdsError } = this.props;
    isActiveAddBranchError && closeAddBranchError();
    isActiveAddBranchOdsError && closeAddBranchOdsError();
  };

  render() {
    const { searchValue, data, pages, isLoading, isError, adminUrl, currentRole, getBranchesList, isModalOverlayOpened, isActiveAddBranchError, isActiveAddBranchOdsError } = this.props;
    const { redirectUrl, isDiscardModalOpen } = this.state;
    const isEmptyData = data.length === 0;
    const isEmptySearch = searchValue.length === 0;
    const isEmptyBranches = isEmptyData && isEmptySearch && !isLoading;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;

    const handlePagination = (e: any) => {
      const pageNumber = e.selected + 1;
      getBranchesList(pageNumber, searchValue);
    }

    const renderPagination = () => {
      if (pages > 1) {
        return (
          <FeaturePagination hidden={isLoading || isError || isEmptyData}>
            <Pagination pageCount={pages} onPageChange={handlePagination} />
          </FeaturePagination>
        );
      }
      return null;
    }

    const renderTableBranches = () => {
      if (isLoading) {
        return (
          <SkeletonTable className="spacing-medium" columns={7} isSticky={true} isGrey={true} />
        );
      }
      if (isEmptyData) {
        return (
          <NoResults type="info">
            <h2>{locale.BranchesListRedesign.noResults.noBranchesFilter}</h2>
          </NoResults>
        );
      }
      return <TableBranches data={data} columns={columns} />;
    }

    const renderContent = () => {
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.BranchesListRedesign.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_BRANCHES}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchesListRedesign.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmptyBranches) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.BranchesListRedesign.noResults.noBranches}</h2>
                <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchesListRedesign.button.addBranch} onClick={this.handleAddBranch} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <FilterBranches isLoading={isLoading} handleAddBranch={this.handleAddBranch} />
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableBranches()}
            {renderPagination()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    if (redirectUrl !== '#') {
      return <Redirect to={redirectUrl} push />;
    }
    if (!isSuperAdmin) {
      return (
      <Redirect to={adminUrl}/>
      );
    }
    return (
      <Feature>
        <FeatureMenu>
          <AdminSidebar />
        </FeatureMenu>
        <FeatureContent>
          {renderContent()}
          {isModalOverlayOpened ? <AddBranchModal handleOpenDiscardDialog={this.handleOpenDiscardDialog} handleCreateBranch={this.handleCreateBranch} /> : null}
          {isDiscardModalOpen ? <DiscardChangesOverlay handleDiscardChanges={this.handleDiscardChanges} handleStay={this.handleStay} /> : null}
          {(isActiveAddBranchError || isActiveAddBranchOdsError) ? <DuplicateBranchModal handleStay={this.handleCloseAddBranchError} isActiveAddBranchError={isActiveAddBranchError} isActiveAddBranchOdsError={isActiveAddBranchOdsError} />: null}
          <AddNewOnlineBranchOverlay handleCreateBranch={this.handleCreateBranch} />
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchesList);
