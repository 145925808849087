import type { Action } from 'types/actions';
import type { Filter } from 'types/filter';
import * as FilterConstants from 'constants/FilterConstants';

export const updateFilter = (filter: Filter): Action => ({
  type: FilterConstants.SET_FILTER,
  payload: filter,
});

export const clearFilter = (): Action => ({
  type: FilterConstants.CLEAR_FILTER,
});
