import * as PanelConstants from 'constants/PanelConstants';
import type { PanelActions } from 'types/panel';
import { getScreenWidth } from "helpers/common";

type PanelState = {
  isActiveAgenda: boolean | null,
  isActiveSearchResults: boolean,
  isSearchCompleted: boolean,
  isActiveAppointmentSlots: boolean,
  isActiveBooking: boolean,
  isActiveAddUser: boolean,
  isActiveAddOrganisation: boolean,
  isActiveAddOrganisationError: boolean,
  isActiveAddBranchError: boolean,
  isActiveAddBranchNameError: boolean,
  isActiveAddBranchOdsError: boolean,
  isActiveUserDetails: boolean,
  isActiveAgendaDetails: boolean,
  isActiveSearchDetails: boolean,
  isActiveProfile: boolean,
  isChangePasswordOpened: boolean,
  isModalOverlayOpened: boolean,
  isProfileDropdownOpen: boolean,
  isConfirmationDeleteUserOpened: boolean,
  isConfirmationOpened: boolean,
  isCalendarNameConflictOpen: boolean,
  isCalendarModalOverlayOpened: boolean,
  isBranchErrorDuplicatedOds: boolean,
  branchErrorDuplicatedOdsMessage: string | null,
  isBranchErrorDuplicatedRoom: boolean,
  hasUnhandledError: boolean,
  isCalendarAvailabilityConflictOpen: boolean,
  isClosingAgendaWithNoSavedChanges: boolean,
  isCalendarOpened: boolean,
  isLowResolution: boolean,
  isActiveInternalEvent: boolean,
  isActiveInternalEventDetails: boolean,
  isRemoveTwoFactorOverlay: boolean
}

const initialState: PanelState = {
  isActiveAgenda: null,
  isActiveSearchResults: false,
  isSearchCompleted: false,
  isActiveAppointmentSlots: false,
  isActiveBooking: false,
  isActiveAddUser: false,
  isActiveAddOrganisation: false,
  isActiveAddOrganisationError: false,
  isActiveAddBranchError: false,
  isActiveAddBranchNameError: false,
  isActiveAddBranchOdsError: false,
  isActiveUserDetails: false,
  isActiveAgendaDetails: false,
  isActiveSearchDetails: false,
  isActiveProfile: false,
  isChangePasswordOpened: false,
  isModalOverlayOpened: false,
  isProfileDropdownOpen: false,
  isConfirmationDeleteUserOpened: false,
  isConfirmationOpened: false,
  isCalendarNameConflictOpen: false,
  isCalendarModalOverlayOpened: false,
  isConfirmationDuplicateConflictOpened: false,
  conflictUserId: null,
  isDeleteConflictOverlayOpened: false,
  appointmentWithConflict: [],
  isBranchErrorDuplicatedOds: false,
  branchErrorDuplicatedOdsMessage: null,
  isBranchErrorDuplicatedRoom: false,
  hasUnhandledError: false,
  isCalendarAvailabilityConflictOpen: false,
  isClosingAgendaWithNoSavedChanges: false,
  isCalendarOpened: false,
  isLowResolution: !getScreenWidth(),
  isActiveInternalEvent: false,
  isActiveInternalEventDetails: false,
  isRemoveTwoFactorOverlay: false
};

export default (localState: PanelState = initialState, action: PanelActions): PanelState => {
  switch (action.type) {
    case PanelConstants.OPEN_AGENDA: {
      return {
        ...localState,
        isActiveAgenda: true
      }
    }
    case PanelConstants.CLOSE_AGENDA: {
      return {
        ...localState,
        isActiveAgenda: false
      }
    }
    case PanelConstants.OPEN_DISCARD_CHANGES: {
      return {
        ...localState,
        isClosingAgendaWithNoSavedChanges: true
      }
    }
    case PanelConstants.CLOSE_DISCARD_CHANGES: {
      return {
        ...localState,
        isClosingAgendaWithNoSavedChanges: false
      }
    }
    case PanelConstants.COMPLETE_SEARCH: {
      return {
        ...localState,
        isSearchCompleted: true
      }
    }
    case PanelConstants.OPEN_SEARCH: {
      return {
        ...localState,
        isActiveSearchResults: true
      }
    }
    case PanelConstants.CLOSE_SEARCH: {
      return {
        ...localState,
        isActiveSearchResults: false
      }
    }
    case PanelConstants.OPEN_SLOTS: {
      return {
        ...localState,
        isActiveAppointmentSlots: true
      }
    }
    case PanelConstants.CLOSE_SLOTS: {
      return {
        ...localState,
        isActiveAppointmentSlots: false
      }
    }
    case PanelConstants.OPEN_BOOKING: {
      return {
        ...localState,
        isActiveBooking: true
      }
    }
    case PanelConstants.CLOSE_BOOKING: {
      return {
        ...localState,
        isActiveBooking: false
      }
    }
    case PanelConstants.OPEN_ADD_USER: {
      return {
        ...localState,
        isActiveAddUser: true
      }
    }
    case PanelConstants.CLOSE_ADD_USER: {
      return {
        ...localState,
        isActiveAddUser: false
      }
    }
    case PanelConstants.OPEN_USER_DETAILS: {
      return {
        ...localState,
        isActiveUserDetails: true
      }
    }
    case PanelConstants.CLOSE_USER_DETAILS: {
      return {
        ...localState,
        isActiveUserDetails: false
      }
    }
    case PanelConstants.OPEN_AGENDA_DETAILS: {
      return {
        ...localState,
        isActiveAgendaDetails: true
      }
    }
    case PanelConstants.CLOSE_AGENDA_DETAILS: {
      return {
        ...localState,
        isActiveAgendaDetails: false
      }
    }
    case PanelConstants.OPEN_SEARCH_DETAILS: {
      return {
        ...localState,
        isActiveSearchDetails: true
      }
    }
    case PanelConstants.CLOSE_SEARCH_DETAILS: {
      return {
        ...localState,
        isActiveSearchDetails: false
      }
    }
    case PanelConstants.OPEN_ADD_ORGANISATION: {
      return {
        ...localState,
        isActiveAddOrganisation: true
      }
    }
    case PanelConstants.CLOSE_ADD_ORGANISATION: {
      return {
        ...localState,
        isActiveAddOrganisation: false
      }
    }
    case PanelConstants.OPEN_ADD_ORGANISATION_ERROR: {
      return {
        ...localState,
        isActiveAddOrganisationError: true
      }
    }
    case PanelConstants.CLOSE_ADD_ORGANISATION_ERROR: {
      return {
        ...localState,
        isActiveAddOrganisationError: false
      }
    }
    case PanelConstants.OPEN_ADD_BRANCH_ERROR: {
      return {
        ...localState,
        isActiveAddBranchError: true
      }
    }
    case PanelConstants.CLOSE_ADD_BRANCH_ERROR: {
      return {
        ...localState,
        isActiveAddBranchError: false
      }
    }
    case PanelConstants.OPEN_ADD_BRANCH_NAME_ERROR: {
      return {
        ...localState,
        isActiveAddBranchNameError: true
      }
    }
    case PanelConstants.CLOSE_ADD_BRANCH_NAME_ERROR: {
      return {
        ...localState,
        isActiveAddBranchNameError: false
      }
    }
    case PanelConstants.OPEN_ADD_BRANCH_ODS_ERROR: {
      return {
        ...localState,
        isActiveAddBranchOdsError: true
      }
    }
    case PanelConstants.CLOSE_ADD_BRANCH_ODS_ERROR: {
      return {
        ...localState,
        isActiveAddBranchOdsError: false
      }
    }
    case PanelConstants.OPEN_MODAL_OVERLAY: {
      return {
        ...localState,
        isModalOverlayOpened: true
      }
    }
    case PanelConstants.CLOSE_MODAL_OVERLAY: {
      return {
        ...localState,
        isModalOverlayOpened: false
      }
    }
    case PanelConstants.OPEN_CALENDAR_MODAL_OVERLAY: {
      return {
        ...localState,
        isCalendarModalOverlayOpened: true
      }
    }
    case PanelConstants.CLOSE_CALENDAR_MODAL_OVERLAY: {
      return {
        ...localState,
        isCalendarModalOverlayOpened: false
      }
    }
    case PanelConstants.OPEN_PROFILE: {
      return {
        ...localState,
        isActiveProfile: true
      }
    }
    case PanelConstants.CLOSE_PROFILE: {
      return {
        ...localState,
        isActiveProfile: false
      }
    }
    case PanelConstants.OPEN_CHANGE_PASSWORD: {
      return {
        ...localState,
        isChangePasswordOpened: true
      }
    }
    case PanelConstants.CLOSE_CHANGE_PASSWORD: {
      return {
        ...localState,
        isChangePasswordOpened: false
      }
    }
    case PanelConstants.OPEN_PROFILE_DROPDOWN: {
      return {
        ...localState,
        isProfileDropdownOpen: true
      }
    }
    case PanelConstants.CLOSE_PROFILE_DROPDOWN: {
      return {
        ...localState,
        isProfileDropdownOpen: false
      }
    }
    case PanelConstants.OPEN_CONFIRMATION_DELETE_USER: {
      return {
        ...localState,
        isConfirmationDeleteUserOpened: true
      }
    }
    case PanelConstants.CLOSE_CONFIRMATION_DELETE_USER: {
      return {
        ...localState,
        isConfirmationDeleteUserOpened: false
      }
    }
    case PanelConstants.OPEN_ROLE_CONFLICT: {
      return {
        ...localState,
        isConfirmationOpened: true,
      }
    }
    case PanelConstants.CLOSE_ROLE_CONFLICT: {
      return {
        ...localState,
        isConfirmationOpened: false,
      }
    }
    case PanelConstants.OPEN_DUPLICATE_CONFLICT: {
      return {
        ...localState,
        isConfirmationDuplicateConflictOpened: true,
        conflictUserId: action.userId
      }
    }
    case PanelConstants.CLOSE_DUPLICATE_CONFLICT: {
      return {
        ...localState,
        isConfirmationDuplicateConflictOpened: false,
        conflictUserId: null
      }
    }
    case PanelConstants.OPEN_DELETE_ORGANIZATION_CONFLICT: {
      return {
        ...localState,
        isDeleteConflictOverlayOpened: true,
        appointmentWithConflict: action.appointmentIds
      }
    }
    case PanelConstants.CLOSE_DELETE_ORGANIZATION_CONFLICT: {
      return {
        ...localState,
        isDeleteConflictOverlayOpened: false,
        appointmentWithConflict: []
      }
    }

    case PanelConstants.OPEN_CALENDAR_NAME_CONFLICT: {
      return {
        ...localState,
        isCalendarNameConflictOpen: true,
      }
    }
    case PanelConstants.CLOSE_CALENDAR_NAME_CONFLICT: {
      return {
        ...localState,
        isCalendarNameConflictOpen: false,
      }
    }

    case PanelConstants.OPEN_CALENDAR_AVAILABILITY_CONFLICT: {
      return {
        ...localState,
        isCalendarAvailabilityConflictOpen: true,
      }
    }
    case PanelConstants.CLOSE_CALENDAR_AVAILABILITY_CONFLICT: {
      return {
        ...localState,
        isCalendarAvailabilityConflictOpen: false,
      }
    }

    case PanelConstants.OPEN_BRANCH_ERROR_DUPLICATED_ODS: {
      return {
        ...localState,
        isBranchErrorDuplicatedOds: true,
        branchErrorDuplicatedOdsMessage: action.payload
      }
    }
    case PanelConstants.CLOSE_BRANCH_ERROR_DUPLICATED_ODS: {
      return {
        ...localState,
        isBranchErrorDuplicatedOds: false,
        branchErrorDuplicatedOdsMessage: null
      }
    }

    case PanelConstants.OPEN_BRANCH_ERROR_DUPLICATED_ROOM: {
      return {
        ...localState,
        isBranchErrorDuplicatedRoom: true,
      }
    }
    case PanelConstants.CLOSE_BRANCH_ERROR_DUPLICATED_ROOM: {
      return {
        ...localState,
        isBranchErrorDuplicatedRoom: false,
      }
    }
    case PanelConstants.THROW_GENERAL_ERROR: {
      return {
        ...localState,
        hasUnhandledError: true
      }
    }
    case PanelConstants.RESET_GENERAL_ERROR: {
      return {
        ...localState,
        hasUnhandledError: false
      }
    }
    case PanelConstants.OPEN_CALENDAR_DETAILS: {
      return {
        ...localState,
        isCalendarOpened: true
      }
    }
    case PanelConstants.CLOSE_CALENDAR_DETAILS: {
      return {
        ...localState,
        isCalendarOpened: false
      }
    }
    case PanelConstants.SET_SCREEN_WIDTH: {
      return {
        ...localState,
        isLowResolution: action.payload
      }
    }
    case PanelConstants.OPEN_INTERNAL_EVENT: {
      return {
        ...localState,
        isActiveInternalEvent: true
      }
    }
    case PanelConstants.CLOSE_INTERNAL_EVENT: {
      return {
        ...localState,
        isActiveInternalEvent: false
      }
    }
    case PanelConstants.OPEN_INTERNAL_EVENT_DETAILS: {
      return {
        ...localState,
        isActiveInternalEventDetails: true
      }
    }
    case PanelConstants.CLOSE_INTERNAL_EVENT_DETAILS: {
      return {
        ...localState,
        isActiveInternalEventDetails: false
      }
    }
    case PanelConstants.OPEN_REMOVE_TWO_FACTOR_OVERLAY: {
      return {
        ...localState,
        isRemoveTwoFactorOverlay: true
      }
    }
    case PanelConstants.CLOSE_REMOVE_TWO_FACTOR_OVERLAY: {
      return {
        ...localState,
        isRemoveTwoFactorOverlay: false
      }
    }
    default: {
      return localState;
    }
  }
}
