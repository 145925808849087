import React, { Component } from 'react'
import {
  Overlay,
  Button,
} from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  header?: string,
  content: string,
  buttonOk?: string,
  handleOk: () => any,
}

export default class InformationalOverlay extends Component<Props> {
    render() {
        const { header, content, buttonOk, handleOk } = this.props;
        return (
            <Overlay
                isOpen={true}
                showCloseButton={false}
                background="dark"
                type="confirmation"
                dataId="appointment-overlay"
                doClose={() => { }}
            >
                <div className="overlay-confirmation-header">
                    <h1>{header || ""}</h1>
                </div>
                <div className="overlay-confirmation-content">
                    <h4>{content}</h4>
                </div>
                <div className="overlay-confirmation-buttons">
                    <Button
                        buttonType="blueline"
                        messageKey="ok-btn"
                        defaultMessage={buttonOk || locale.Modals.completeOverlay.buttonOk}
                        onClick={handleOk}
                        data-id="ok-btn"
                        className="patient-care-btn-in-group"
                    />
                </div>
            </Overlay>
        )
    }
}
