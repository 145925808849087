import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button
} from "@patient-access/ui-kit";
import Select from "react-select";

import * as BranchesConstants from "constants/BranchesConstants";
import ConfirmByPasswordOverlay from "components/Share/ConfirmByPasswordOverlay/ConfirmByPasswordOverlay";
import OrganisationMFAModal from "./OrganisationMFAModal/OrganisationMFAModal"
import { deleteOrganisation, setPasswordConfirmationStatus, getOrganisationDetails, updateOrganisationSettings, closeEmptyBookingUrlWarning, openTwoFactorModal, closeTwoFactorModal } from "actions/organisationDetails";
import { closeDeleteOrganisationConflictOverlay } from 'actions/panel';
import type { Organisation } from "types/organisation";

import locale from "service/locale";
import { updateForm, clearForm } from 'actions/form';
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";
import OrganisationType from "./OrganisationType/OrganisationType";
import * as RolesConstants from "constants/RolesConstants";
import OrganisationBookingUrl from "./OrganisationBookingUrl/OrganisationBookingUrl";
import { ORGANISATION_TYPE, ORGANISATION_TWO_FACTOR_LIST} from 'constants/OrganisationsConstants';

import ConfirmationOverlay from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import { checkURL } from "helpers/checkValues";

import * as RoutesConstants from 'constants/RoutesConstants';

type Props = {
  form: any,
  isActiveAgenda: boolean,
  organisationId: string,
  organisationsList: Organisation[],
  deleteOrganisation: (organisationId: string) => any,
  setPasswordConfirmationStatus: (status: string) => any,
  confirmationStatus: string | null,
  getOrganisationDetails: (organisationId: string) => Organisation,
  organisationDetails: Organisation,
  isDeleteConflictOverlayOpened: boolean,
  isOrganisationDeletionPending: boolean,
  updateForm: (data: any) => any,
  clearForm: () => any,
  isBookingURLEmpty: boolean,
  updateOrganisationSettings: (organisationId: string) => any,
  isUpdatingOrganisationSettingsPending: boolean,
  closeEmptyBookingUrlWarning: () => Action,
  organisationType: number,
  requiresMFA: boolean,
  isTwoFactorOverlay: boolean,
  openTwoFactorModal: () => Action,
  closeTwoFactorModal: () => Action
};

type State = {
  organisation: Object | "Loading...";
  isConfirmDialogOpened: boolean;
  isDeleteConflictOverlayOpened: boolean;
  isConfirmationError: boolean;
  errorMessage: string;
  isRedirect: {
    status: boolean,
    redirectUrl: string
  },
  appointmentWithConflict: Array<string>;
  isDataChanged: boolean,
  openDiscardChanges: boolean,
  isEditMode: boolean,
  isTwoFactorStatusChanged: boolean,
  twoFactorOperation: boolean
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
    isActiveAgenda: state.panel.isActiveAgenda,
    organisationsList: state.organisations.organisationsList,
    confirmationStatus: state.organisationDetails.confirmationStatus,
    organisationDetails: state.organisationDetails,
    isDeleteConflictOverlayOpened: state.panel.isDeleteConflictOverlayOpened,
    appointmentWithConflict: state.panel.appointmentWithConflict,
    isOrganisationDeletionPending: state.organisations.isOrganisationDeletionPending,
    currentRole: state.roles.profileCurrentRole,
    isBookingURLEmpty: state.organisationDetails.isBookingURLEmpty,
    isUpdatingOrganisationSettingsPending: state.organisationDetails.isUpdatingOrganisationSettingsPending,
    organisationType: state.organisationDetails.organisationType,
    requiresMFA: state.organisationDetails.requiresMFA,
    isTwoFactorOverlay: state.organisationDetails.isTwoFactorOverlay
  }
};

const mapDispatchToProps = (dispatch: Function): any => ({
  deleteOrganisation: (organisationId) => dispatch(deleteOrganisation(organisationId)),
  setPasswordConfirmationStatus: (status) => dispatch(setPasswordConfirmationStatus(status)),
  getOrganisationDetails: (organisationId: string) => dispatch(getOrganisationDetails(organisationId)),
  closeDeleteOrganisationConflictOverlay: () => dispatch(closeDeleteOrganisationConflictOverlay()),
  updateOrganisationSettings: (organisationId: string, showEmptyURLWarning: boolean) => dispatch(updateOrganisationSettings(organisationId, showEmptyURLWarning)),
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  closeEmptyBookingUrlWarning: () => dispatch(closeEmptyBookingUrlWarning()),
  openTwoFactorModal: () => dispatch(openTwoFactorModal()),
  closeTwoFactorModal: () => dispatch(closeTwoFactorModal())
});

class OrganisationSettings extends Component<Props, State> {

  state = {
    organisation: this.props.organisationDetails,
    isDeleteConflictOverlayOpened: false,
    isConfirmDialogOpened: false,
    isConfirmationError: false,
    errorMessage: "",
    isRedirect: {
      status: false,
      redirectUrl: ""
    },
    isDataChanged: false,
    openDiscardChanges: false,
    isEditMode: false,
    isTwoFactorStatusChanged: false,
    twoFactorOperation: false
  };

  componentDidMount = () => {
    const { setPasswordConfirmationStatus, getOrganisationDetails, organisationId } = this.props;
    getOrganisationDetails(organisationId);
    setPasswordConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING);
  };


  componentWillReceiveProps = (nextProps: Props) => {
    const { confirmationStatus, organisationDetails } = nextProps;
    const { isUpdatingOrganisationSettingsPending } = this.props;

    if (confirmationStatus === BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING) {
      this.setState({
        isConfirmationError: false,
      });
      return;
    }

    if (nextProps.isDeleteConflictOverlayOpened) {
      this.setState({
        isConfirmDialogOpened: false,
      });
    }

    if (confirmationStatus === BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.LAST_ATTEMPT ||
      confirmationStatus === BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.WRONG ||
      confirmationStatus === BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.BLOCKED) {
      let errorMessage = "";
      switch (confirmationStatus) {
        case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.LAST_ATTEMPT:
          errorMessage = locale.Modals.confirmByPasswordOverlay.lastAttemptWarning;
          break;
        case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.WRONG:
          errorMessage = locale.Modals.confirmByPasswordOverlay.wrongPasswordWarning;
          break;
        default:
          errorMessage = locale.Modals.confirmByPasswordOverlay.lockedWarning;
      }
      this.setState({
        isConfirmationError: true,
        errorMessage
      });
    } else if (confirmationStatus === BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED) {
      this.setState({
        isConfirmDialogOpened: false,
        isRedirect :
        {
          status: true,
          redirectUrl: RoutesConstants.ADMIN_ORGANISATIONS
        }
      });
    } else if(confirmationStatus === BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.UPDATED) {
      this.setState({
        isRedirect :
        {
          status: true,
          redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationDetails.organisationId}/${RoutesConstants.SETTINGS}`
        }
      });
    }

    if (organisationDetails && !isUpdatingOrganisationSettingsPending) {
      this.setState({
        organisation: organisationDetails
      });
    }
  };

  componentWillUnmount = () => {
    const { clearForm } = this.props;
    clearForm();
  };

  handleOpenConfirmDialog = (e: any) => {
    e && e.preventDefault();
    this.setState({ isConfirmDialogOpened: true });
  };

  handleCloseConfirmDialog = (e: any) => {
    e && e.preventDefault();
    this.setState({ isConfirmDialogOpened: false });
  };

  handleDeleteOrganisation = () => {
    const { organisation } = this.state;
    const { deleteOrganisation } = this.props;
    deleteOrganisation(organisation.organisationId);
  };

  getBranchesWithConflictMessage = () => {
    let { appointmentWithConflict } = this.props;
    let branches = appointmentWithConflict.map(appItem => `${appItem.branchName}`);
    return locale.Modals.deleteOrganisationConflictOverlay.body(branches.join(', '));
  };

  handleDeleteOrganisationConflictBack = () => {
    const { closeDeleteOrganisationConflictOverlay } = this.props;
    closeDeleteOrganisationConflictOverlay();
  };

  handleOrganisationBookingUrlEditMode = () => {
    const { isEditOrganisationBookingUrlMode } = this.state;

    if (isEditOrganisationBookingUrlMode) {
      this.setState({ isEditOrganisationBookingUrlMode: false });
    }
    else {
      this.setState({ isEditOrganisationBookingUrlMode: true });
    }
  }

  handleOrganisationUrlChange = (name, value) => {
    const { updateForm, form, requiresMFA } = this.props;
    this.setState({isDataChanged: true });
    updateForm({
      booking: {
        ...form.booking,
        point: {
          ...form.point,
          [name]: value,
        }
      },
      requiresMFA: requiresMFA
    });
  }

  handleDiscardChanges = (e: any) => {
    e && e.preventDefault();
    this.setState({ openDiscardChanges: true });
  };

  handleConfirm = () => {
    const { clearForm } = this.props;
    this.setState(
      {
        isEditOrganisationBookingUrlMode: false,
        isDataChanged: false,
        openDiscardChanges: false
      },
      clearForm
    );
  };

  handleCancel = () => {
    this.setState({
      openDiscardChanges: false
    });
  };

  handleUpdateOrganisationSettings = (showEmptyURLWarning?: boolean = true) => {
    const { updateOrganisationSettings, organisationId } = this.props;
    updateOrganisationSettings(organisationId, showEmptyURLWarning);
  }

  handleUpdateWithoutBookingUrl = () => {
    this.handleUpdateOrganisationSettings(false)
  }

  handleChangeMode = (e: Event) => {
    e && e.preventDefault();
    const { isEditMode } = this.state;
    const { updateForm, form, requiresMFA } = this.props;

    this.setState({
      isEditMode: !isEditMode,
    });
    if(!form.requiresMFA){
      updateForm({
        requiresMFA: requiresMFA ? 
        ORGANISATION_TWO_FACTOR_LIST[1] :
        ORGANISATION_TWO_FACTOR_LIST[0]
      });
    }
  }

  handleTwoFactorSelect = (option) => {
    const { updateForm } = this.props;
    updateForm({
      requiresMFA: option
    })

    this.setState({
      isDataChanged: true,
      isTwoFactorStatusChanged: true,
      twoFactorOperation: option.value
    });

  }

  callMFAOverlay = () => {
    const { openTwoFactorModal } = this.props;
    openTwoFactorModal();
    
  }

  handleCloseMFAOverlay = () => {
    const { closeTwoFactorModal } = this.props;
    closeTwoFactorModal();
  }

  handleSaveMFAOverlay = () => {
    this.handleUpdateOrganisationSettings();

  }

  render() {
    const {
      isConfirmDialogOpened,
      isConfirmationError,
      errorMessage,
      isRedirect,
      isEditOrganisationBookingUrlMode,
      isDataChanged,
      openDiscardChanges,
      organisation,
      isEditMode,
      isTwoFactorStatusChanged,
      twoFactorOperation
     } = this.state;

    const {
      isDeleteConflictOverlayOpened,
      isOrganisationDeletionPending,
      currentRole,
      isActiveAgenda,
      isBookingURLEmpty,
      isUpdatingOrganisationSettingsPending,
      closeEmptyBookingUrlWarning,
      organisationType,
      form,
      requiresMFA,
      isTwoFactorOverlay
       } = this.props;

    const isAbleToEditOrgUrl = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role);
    const canRemoveOrganisation = currentRole.role === RolesConstants.ADMIN;

    const orgBookingPoint = organisation && organisation.booking && organisation.booking.point;
    const bookingPoint = form.booking && form.booking.point;
    const isEmptyBookingUrl = bookingPoint && !bookingPoint.url.length;
    const isEditedBookingUrl = bookingPoint && Object.keys(bookingPoint).includes('url');
    const isUrlCorrect = isEditedBookingUrl ? (bookingPoint && bookingPoint.url.length === 0) || checkURL(bookingPoint.url) : true;

    return isRedirect.status
      ? <Redirect to={isRedirect.redirectUrl} />
      : (
        <div
          className={
            isDataChanged
              ? "patient-care-details-holder data-changed"
              : "patient-care-details-holder"
          }
        >
          <div className="patient-care-container-sm">
            <OrganisationType
              organisationType={organisationType}/>
            {
              organisationType === ORGANISATION_TYPE.AFFILIATE &&
              (
                <OrganisationBookingUrl
                  isAbleToEdit={isAbleToEditOrgUrl}
                  isEditOrganisationBookingUrlMode={isEditOrganisationBookingUrlMode}
                  url={orgBookingPoint && orgBookingPoint.url}
                  isEmptyBookingUrl={isEmptyBookingUrl}
                  isUrlCorrect={isUrlCorrect}
                  handleOrganisationBookingUrlEditMode={this.handleOrganisationBookingUrlEditMode}
                  handleOrganisationUrlChange={this.handleOrganisationUrlChange} />
              )
            }
              <Fragment>
                <hr className="patient-care-separator" />
                <div className="patient-care-description-block">
                  <div className="patient-care-heading-row">
                    <h3>
                      {locale.OrganisationsSettings.twoFactorAuthentication.title}
                    </h3>
                    
                    <button
                      className="patient-care-btn-link"
                      onClick={this.handleChangeMode}>
                      { isEditMode
                        ? locale.Buttons.buttonDone
                        : locale.Buttons.buttonEdit
                      }
                    </button>
                  </div>
                  <p>{locale.OrganisationsSettings.twoFactorAuthentication.hint}</p>
                </div>

                <div className="patient-care-col-6">
                  {isEditMode?
                    <Fragment>
                      <Select
                      id="two-factor-org"
                      name="twoFactorOrg"
                      options={ORGANISATION_TWO_FACTOR_LIST}
                      onChange={this.handleTwoFactorSelect}
                      classNamePrefix="patient-care"
                      className={`patient-care-select`}
                      defaultValue={ form.requiresMFA }
                      value={ form.requiresMFA }

                    />
                    </Fragment>
                  :
                    <Fragment>
                      <span className="patient-care-filled-input">
                        {
                          form.requiresMFA? 
                          form.requiresMFA.label:
                          requiresMFA ? 
                          ORGANISATION_TWO_FACTOR_LIST[1].label :
                          ORGANISATION_TWO_FACTOR_LIST[0].label
                        }
                      </span>
                    </Fragment>
                    }
                  
                </div>
              </Fragment>

            {canRemoveOrganisation &&
              (<Fragment>
                <hr className="patient-care-separator" />
                <div className="patient-care-description-block">
                  <h3>
                    {locale.OrganisationsSettings.removeOrganisation}
                  </h3>
                  <p>{locale.OrganisationsSettings.removeDescription1}</p>
                  <div className="patient-care-block">
                    <p>{locale.OrganisationsSettings.removeDescription2}</p>
                  </div>
                </div>

                <Button
                  className="patient-care-btn-remove"
                  buttonType="outline"
                  messageKey="remove-btn"
                  defaultMessage={locale.OrganisationsSettings.removeOrganisation}
                  onClick={this.handleOpenConfirmDialog}
                  data-id="remove-btn"
                />
              </Fragment>
              )}
          </div>

          {isDataChanged && (
            <div
              className={`patient-care-buttons-fixed-bottom ${
                isActiveAgenda ? "is-active-agenda" : ""
                }`}
            >
              <div className="patient-care-container-sm">
                <div className="patient-care-row-align-right">
                  <Button
                    buttonType="blueline"
                    messageKey="discard-btn"
                    defaultMessage={locale.Appointment.buttons.discardChanges}
                    isLoading={isUpdatingOrganisationSettingsPending}
                    data-id="discard-btn"
                    onClick={this.handleDiscardChanges}
                    className="patient-care-btn-in-group"
                    isDisabled={!isUrlCorrect}
                  />
                  <Button
                    buttonType="secondary"
                    messageKey="save-changes-btn"
                    defaultMessage={locale.Appointment.buttons.saveChanges}
                    onClick={isTwoFactorStatusChanged? this.callMFAOverlay : this.handleUpdateOrganisationSettings}
                    isLoading={isUpdatingOrganisationSettingsPending}
                    data-id="save-changes-btn"
                    className="patient-care-btn-in-group"
                    isDisabled={!isUrlCorrect}
                  />
                </div>
              </div>
            </div>
          )}
          {
            isConfirmDialogOpened &&
            <ConfirmByPasswordOverlay
              type="organisation"
              handleDoNotConfirm={this.handleCloseConfirmDialog}
              handleConfirm={this.handleDeleteOrganisation}
              isConfirmationError={isConfirmationError}
              errorMessage={errorMessage}
              isLoading={isOrganisationDeletionPending}
              entityName={organisation.name}
            />
          }

          {
            isDeleteConflictOverlayOpened &&
            <InformationalOverlay
              header={locale.Modals.deleteOrganisationConflictOverlay.header}
              buttonOk={locale.Modals.deleteOrganisationConflictOverlay.buttonOk}
              content={this.getBranchesWithConflictMessage()}
              handleOk={this.handleDeleteOrganisationConflictBack}
            />
          }

          {openDiscardChanges ? (
            <ConfirmationOverlay
              handleConfirm={this.handleConfirm}
              handleCancel={this.handleCancel}
              content={locale.Modals.discardChangesOverlay.content}
              header={locale.Modals.discardChangesOverlay.header}
              buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
              buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
            />
          ) : null}

          {isBookingURLEmpty ? (
            <ConfirmationOverlay
              handleConfirm={this.handleUpdateWithoutBookingUrl}
              handleCancel={closeEmptyBookingUrlWarning}
              content={locale.OrganisationsSettings.confirmationWithoutBookingURL}
              header={locale.OrganisationsSettings.confirmationWithoutBookingURLHeader}
              buttonConfirm={locale.OrganisationsSettings.confirmationWithoutBookingURLConfirm}
              buttonCancel={locale.OrganisationsSettings.confirmationWithoutBookingURLCancelbtn}
            />
          ) : null}

          { isTwoFactorOverlay ? (<OrganisationMFAModal 
          twoFactorOperation={twoFactorOperation} 
          handleCloseMFAOverlay={this.handleCloseMFAOverlay}
          handleSaveMFAOverlay={this.handleSaveMFAOverlay}
          />) : null }

        </div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSettings);
