import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { setUI, setServiceDetails, resetUserData, setUserStep, getServicesSummary, getBranchesSummary } from 'actions/organisationServices';
import { showSnackbarStatus } from 'actions/snackbar';
import locale from 'service/locale';
import { setAddBranchHeaderText, setAddBranchButtonText } from '../helpers/OrganisationService';

type Props = {
  organisationId: string,
  isLoading: boolean,
  isBulkUpdateCancel: boolean,
  isBulkUpdateComplete: boolean,
  userData: any,
  branchList: any,
  isAddBranches: boolean,
  responseFromApi: any,
  setUI: (value: any) => any,
  resetUserData: () => any,
  showSnackbarStatus: () => any,
  setUserStep: (value: any) => any,
  getServicesSummary: (organisationId: string) => any,
  getBranchesSummary: (organisationId: string, serviceId: string) => any,
  setServiceDetails: (organisationId: string, isAddBranches: boolean) => any,
};

const mapStateToProps = state => ({
  branchList: state.organisationServices.branchesList.data,
  userData: state.organisationServices.user.data,
  responseFromApi: state.organisationServices.ui.responseFromApi,
  isLoading: state.organisationServices.user.isAddServiceDetailsPending,
  isBulkUpdateCancel: state.organisationServices.ui.isBulkUpdateCancel,
  isBulkUpdateComplete: state.organisationServices.ui.isBulkUpdateComplete,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserData: () => dispatch(resetUserData()),
  showSnackbarStatus: (status: any) => dispatch(showSnackbarStatus(status)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  getServicesSummary: (organisationId: string) => dispatch(getServicesSummary(organisationId)),
  getBranchesSummary: (organisationId: string, serviceId: string) => dispatch(getBranchesSummary(organisationId, serviceId)),
  setServiceDetails: (organisationId: string, isAddBranches: boolean) => dispatch(setServiceDetails(organisationId, isAddBranches)),
});

class OverlayConfirmationAdd extends Component<Props> {

  renderConfirmationButtons = () => {
    const { userData, branchList, isLoading, setUI } = this.props;
    const branchCount = userData.serviceBranches.length;
    const availableBranchesCount = branchList.filter(service => service.isEnabled === false).length;

    return (
      <Fragment>
        <Button
          buttonType="blueline"
          messageKey="goBack"
          defaultMessage={locale.OrganisationServices.button.goBack}
          onClick={() => { setUI({ isOverlayConfirmationVisible: false }); }}
          isLoading={isLoading}
        />
        <Button
          buttonType="secondary"
          messageKey="addService"
          defaultMessage={setAddBranchButtonText(branchCount, availableBranchesCount)}
          onClick={() => { this.handleUpdate() }}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }

  handleUpdate () {
    const { organisationId, isAddBranches, setServiceDetails } = this.props;
    setServiceDetails(organisationId, isAddBranches);
    // this.handleClose();
  }

  handleClose () {
    const { setUI, setUserStep } = this.props;
    setUserStep(1);
    setUI({ isOverlayConfirmationVisible: false, isSidebarAddVisible: false, responseFromApi: [], isBulkUpdateComplete: false });
  }

  render() {
    const { userData, branchList } = this.props;
    const branchCount = userData.serviceBranches.length;
    const availableBranchesCount = branchList.filter(service => service.isEnabled === false).length;

    return (
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{setAddBranchHeaderText( branchCount, availableBranchesCount, userData.serviceName)}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.OrganisationServices.overlay.confirmation.content}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          {this.renderConfirmationButtons()}
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayConfirmationAdd);
