import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Overlay, Button, Form, FormItem, Input, InputError, Checkbox, Radio, SearchFilter, Tooltip, Markup, LinkExternal, IconSearch } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentScroll, FeatureContentHeader, FeatureContentBreadcrumbs, FeatureSidebar } from 'components/Share/feature';
import { SidebarHeader, SidebarBody, SidebarBodySection, SidebarBodyHeader, SidebarBodyContent, SidebarFooter } from 'components/Share/sidebar';
import { OverlayDescription } from 'components/Share/overlay';
import { ButtonDelete } from 'components/Share/button';
import OrganisationSidebar from 'components/Pages/Admin/OrganisationContent/OrganisationSidebar/OrganisationSidebar';
import { dataServicesList, columnsServicesList, TableServicesList } from './TableServicesList/TableServicesList';
import { dataServicesEdit, columnsServicesEdit, TableServicesEdit } from './TableServicesEdit/TableServicesEdit';
import { dataServicesAddService, columnsServicesAddService, TableServicesAddService } from './TableServicesAddService/TableServicesAddService';
import { dataServicesAddBranch, columnsServicesAddBranch, TableServicesAddBranch } from './TableServicesAddBranch/TableServicesAddBranch';
import { updateForm } from 'actions/form';
import locale from 'service/locale';
import './styles.scss';

type Props = {
  form: any,
  updateForm: (data: any) => any,
  organisationId: string,
};

type State = {
  step: number,
  valueSearchFilterList: string,
  valueSearchFilterAddService: string,
  valueSearchFilterAddBranch: string,
};

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class Playground extends Component<Props, State> {
  state = {
    step: 1,
    valueSearchFilterList: '',
    valueSearchFilterAddService: '',
    valueSearchFilterAddBranch: '',
    isShowErrorState: false, // testing error states
    isIndeterminateCheckbox: true,
    isIndeterminateRadio: true,
  }

  render() {
    const { form, organisationId, updateForm } = this.props;
    const { step, valueSearchFilterList, valueSearchFilterAddService, valueSearchFilterAddBranch, isShowErrorState, isIndeterminateCheckbox, isIndeterminateRadio } = this.state;

    const renderFeatureSidebarContent = (step) => {
      switch (step) {
        case 1:
          return (
            <Fragment>
              <SidebarHeader>
                <h2>{locale.OrganisationServices.sidebar.addService.header}</h2>
              </SidebarHeader>
              <SidebarBody>
                <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.filter.servicesList.header}</h3>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <div className="row row-inline">
                        <SearchFilter id="valueSearchFilterAddService" name="valueSearchFilterAddService" placeholder={locale.OrganisationServices.filter.servicesList.placeholder} autoComplete="off" value={valueSearchFilterAddService} clearHandler={() => { this.setState({ valueSearchFilterAddService: '' }); }} onChange={(e) => { this.setState({ valueSearchFilterAddService: e.target.value }); }} icon={<IconSearch outline />} />
                        <Checkbox id="hide_added" name="hide_added" value="hide_added" label={locale.OrganisationServices.filter.servicesList.labelHideAdded} onChange={() => {}} />
                      </div>
                    </SidebarBodyContent>
                    <SidebarBodyContent>
                      <FormItem type="item" error={isShowErrorState ? 'error' : null}>
                        <TableServicesAddService data={dataServicesAddService} columns={columnsServicesAddService} props={this.props} />
                      </FormItem>
                    </SidebarBodyContent>
                  </SidebarBodySection>
                </Form>
              </SidebarBody>
              <SidebarFooter>
                <div className="row">
                  <div className="column">
                    {isShowErrorState ? <p className="error">Please fill in this field</p> : null}
                  </div>
                  <div className="column">
                    <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.cancel} onClick={() => { updateForm({ isSidebarVisible: false, selectedService: null }); }} />
                    <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.continue} onClick={() => { this.setState({ step: 2 }); }} isDisabled={!form.selectedService} />
                  </div>
                </div>
              </SidebarFooter>
            </Fragment>
          );
        case 2:
          return (
            <Fragment>
              <SidebarHeader>
                <h2>{locale.OrganisationServices.sidebar.addService.header}</h2>
                <p><span>Chickenpox - vaccination</span> <a href="#description" onClick={(e) => { e.preventDefault(); updateForm({ isOverlayDescriptionVisible: true }); }}>{locale.OrganisationServices.sidebar.serviceDescription}</a></p>
              </SidebarHeader>
              <SidebarBody>
                <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.section.appointmentTypes.header}</h3>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <FormItem type="item" error={isShowErrorState ? 'error' : null}>
                        <div className="row row-list">
                          <Checkbox id="app_type_01" name="app_type_01" value="app_type_01" label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={() => {}} />
                          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipVideo}>
                            <Checkbox id="app_type_02" name="app_type_02" value="app_type_02" label={locale.OrganisationServices.section.appointmentTypes.labelVideo} onChange={() => {}} disabled />
                          </Tooltip>
                          <Checkbox id="app_type_03" name="app_type_03" value="app_type_03" label={locale.OrganisationServices.section.appointmentTypes.labelPhone} onChange={() => {}} />

                          <Checkbox
                            id="indeterminate_checkbox"
                            name="indeterminate_checkbox"
                            value="indeterminate_checkbox"
                            label={locale.OrganisationServices.section.appointmentTypes.labelF2F}
                            onChange={() => { this.setState({ isIndeterminateCheckbox: false }); }}
                            indeterminate={isIndeterminateCheckbox ? 'true' : null}
                          />
                        </div>
                        {isShowErrorState ? <InputError message="At least 1 appointment type is required." /> : null}
                      </FormItem>
                    </SidebarBodyContent>
                  </SidebarBodySection>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.section.serviceStatus.header}</h3>
                      <p>{locale.OrganisationServices.section.serviceStatus.description}</p>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <FormItem type="item" error={isShowErrorState ? 'error' : null}>
                        <div className="row row-list">
                          <Radio id="app_status_01" name="app_status" value="app_status_01" label={locale.OrganisationServices.section.serviceStatus.labelPaused} onChange={() => {}} />
                          <Radio id="app_status_02" name="app_status" value="app_status_02" label={locale.OrganisationServices.section.serviceStatus.labelActive} onChange={() => {}} />

                          <Radio
                            id="indeterminate_radio_01"
                            name="indeterminate_radio"
                            value="indeterminate_radio_01"
                            label={locale.OrganisationServices.section.serviceStatus.labelPaused}
                            onChange={() => { this.setState({ isIndeterminateRadio: false }); }}
                            indeterminate={isIndeterminateRadio ? 'true' : null}
                          />
                          <Radio
                            id="indeterminate_radio_02"
                            name="indeterminate_radio"
                            value="indeterminate_radio_02"
                            label={locale.OrganisationServices.section.serviceStatus.labelActive}
                            onChange={() => { this.setState({ isIndeterminateRadio: false }); }}
                            indeterminate={isIndeterminateRadio ? 'true' : null}
                          />
                        </div>
                        {isShowErrorState ? <InputError message="Please fill in this field." /> : null}
                      </FormItem>
                    </SidebarBodyContent>
                  </SidebarBodySection>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.section.servicePrice.header}</h3>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <FormItem type="item" error={isShowErrorState ? 'error' : null}>
                        <div className="row row-inline">
                          <div className="input-before"><p><strong>{locale.OrganisationServices.section.servicePrice.currency}</strong></p></div>
                          <Input type="number" id="app_price" name="app_price" placeholder={locale.OrganisationServices.section.servicePrice.placeholder} onChange={() => {}} />
                          <Checkbox id="app_vat" name="app_vat" value="VAT" label={locale.OrganisationServices.section.servicePrice.labelVAT} onChange={() => {}} />
                        </div>
                        {isShowErrorState ? <InputError message="Enter a service price." /> : null}
                      </FormItem>
                    </SidebarBodyContent>
                  </SidebarBodySection>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.section.appointmentDuration.header}</h3>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <FormItem type="item" error={isShowErrorState ? 'error' : null}>
                        <div className="row row-inline">
                          <Input type="number" id="app_duration" name="app_duration" placeholder={locale.OrganisationServices.section.appointmentDuration.placeholder} onChange={() => {}} />
                          <div className="input-after"><p><strong>{locale.OrganisationServices.section.appointmentDuration.labelMinutes}</strong></p></div>
                        </div>
                        {isShowErrorState ? <InputError message="Enter an appointment duration." /> : null}
                      </FormItem>
                    </SidebarBodyContent>
                  </SidebarBodySection>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.section.bookingCutOff.header}</h3>
                      <p>{locale.OrganisationServices.section.bookingCutOff.description}</p>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <FormItem type="item" error={isShowErrorState ? 'error' : null}>
                        <div className="row row-inline">
                          <Input type="number" id="app_cut_off" name="app_cut_off" placeholder={locale.OrganisationServices.section.bookingCutOff.placeholder} onChange={() => {}} />
                          <div className="input-after"><p><strong>{locale.OrganisationServices.section.bookingCutOff.labelHours}</strong></p></div>
                        </div>
                        {isShowErrorState ? <InputError message="Please fill in this field." /> : null}
                      </FormItem>
                    </SidebarBodyContent>
                  </SidebarBodySection>
                </Form>
              </SidebarBody>
              <SidebarFooter>
                <div className="row">
                  <div className="column">
                    <ButtonDelete onClick={(e) => { e.preventDefault(); }} />
                  </div>
                  <div className="column">
                    <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.goBack} onClick={() => { this.setState({ step: 1 }); }} />
                    <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.continue} onClick={() => { this.setState({ step: 3 }); }} />
                  </div>
                </div>
              </SidebarFooter>
            </Fragment>
          );
        case 3:
          return (
            <Fragment>
              <SidebarHeader>
                <h2>{locale.OrganisationServices.sidebar.addService.header}</h2>
                <p><span>Chickenpox - vaccination</span> <a href="#description" onClick={(e) => { e.preventDefault(); updateForm({ isOverlayDescriptionVisible: true }); }}>{locale.OrganisationServices.sidebar.serviceDescription}</a></p>
              </SidebarHeader>
              <SidebarBody>
                <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                  <SidebarBodySection>
                    <SidebarBodyHeader>
                      <h3>{locale.OrganisationServices.filter.branchesList.header}</h3>
                    </SidebarBodyHeader>
                    <SidebarBodyContent>
                      <div className="row">
                        <SearchFilter id="valueSearchFilterAddBranch" name="valueSearchFilterAddBranch" placeholder={locale.OrganisationServices.filter.branchesList.placeholder} autoComplete="off" value={valueSearchFilterAddBranch} clearHandler={() => { this.setState({ valueSearchFilterAddBranch: '' }); }} onChange={(e) => { this.setState({ valueSearchFilterAddBranch: e.target.value }); }} icon={<IconSearch outline />} />
                      </div>
                    </SidebarBodyContent>
                    <SidebarBodyContent>
                      <TableServicesAddBranch data={dataServicesAddBranch} columns={columnsServicesAddBranch} props={this.props} />
                    </SidebarBodyContent>
                  </SidebarBodySection>
                </Form>
              </SidebarBody>
              <SidebarFooter>
                <div className="row">
                  <div className="column" />
                  <div className="column">
                    <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.goBack} onClick={() => { this.setState({ step: 2 }); }} />
                    <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.saveChanges} onClick={() => { updateForm({ isOverlayConfirmationVisible: true }); }} />
                  </div>
                </div>
              </SidebarFooter>
            </Fragment>
          );
        default:
          return null;
      }
    }

    const renderFilterButtons = () => {
      if (form.isEditMode) {
        return (
          <Fragment>
            <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.addToBranches} onClick={() => { updateForm({ isEditMode: false }); }} />
            <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.editService} onClick={() => { updateForm({ isSelectionVisible: !form.isSelectionVisible }); }} />
            {/*
            <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.editAtNoBranches(4)} onClick={() => {}} />
            */}
          </Fragment>
        );
      }
      return <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.addService} onClick={() => { updateForm({ isSidebarVisible: true }); }} />;
    }

    const renderTableServices = () => {
      if (form.isEditMode) return <TableServicesEdit data={dataServicesEdit} columns={columnsServicesEdit} props={this.props} />;
      return <TableServicesList data={dataServicesList} columns={columnsServicesList} props={this.props} />;
    }

    return (
      <Feature>
        <FeatureMenu>
          <OrganisationSidebar organisationId={organisationId} />
        </FeatureMenu>
        <FeatureContent>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <div className="row">
                <FeatureContentBreadcrumbs organisationId={organisationId} />
              </div>
              <div className="row">
                <div className="column column-full">
                  <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                    <SearchFilter id="valueSearchFilterList" name="valueSearchFilterList" placeholder={locale.OrganisationServices.filter.servicesSummary.placeholder} autoComplete="off" value={valueSearchFilterList} clearHandler={() => { this.setState({ valueSearchFilterList: '' }); }} onChange={(e) => { this.setState({ valueSearchFilterList: e.target.value }); }} icon={<IconSearch outline />} />
                  </Form>
                </div>
                <div className="column">
                  {renderFilterButtons()}
                </div>
              </div>
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableServices()}
          </FeatureContentScroll>
          <FeatureSidebar isOpen={form.isSidebarVisible} doClose={() => { this.setState({ step: 1 }); updateForm({ isSidebarVisible: false, selectedService: null }); }}>
            {renderFeatureSidebarContent(step)}
          </FeatureSidebar>
          <OverlayDescription isOpen={form.isOverlayDescriptionVisible} doClose={() => { updateForm({ isOverlayDescriptionVisible: false }); }}>
            <div className="overlay-description-header" />
            <div className="overlay-description-content">
              <div className="overlay-description-content-heading">
                <h1>Chickenpox - vaccination</h1>
              </div>
              <div className="overlay-description-content-body">
                <Markup>
                  <h2>Introduction</h2>
                  <p>Blood pressure is the pressure at which the heart pushes the blood round the body within the blood vessels.  If your blood pressure is raised, you are highly unlikely to have any symptoms. But all the time your blood pressure is high, it’s increasing your risk of heart attack and stroke.</p>
                  <h2>Why is it important to know my blood pressure?</h2>
                  <p>Knowing your blood pressure can help you protect yourself against heart attack and stroke. You should have your blood pressure measured at least every 5 years – your pharmacist can often do this, and advise you on what your results mean.</p>
                  <p>A healthy blood pressure reading is 120/80 or less, but anything under 140/90 is considered normal for most people. There are a number of conditions (e.g. diabetes, kidney disease) where a slightly lower blood pressure is advisable. </p>
                  <p>Blood pressure readings do fluctuate, so high blood pressure is only diagnosed after several readings over 140/90. There are a number of medications that can be taken to lower the blood pressure and these work very well for most people.</p>
                  <p>High blood pressure is a risk factor for a number of serious health problems. As well as being a risk factor for heart attack and stroke, it can increase your risk of heart failure and chronic kidney disease.</p><h2>Getting your Blood Pressure checked</h2><p>You can have your blood pressure checked by seeing your pharmacist, GP or Practice Nurse. You can also purchase a blood pressure machine so that you can check your blood pressure yourself at home.</p>
                </Markup>
              </div>
              <div className="overlay-description-content-link">
                <LinkExternal to="#some-link" message="Read more about Chickenpox - vaccination" />
              </div>
            </div>
            <div className="overlay-description-footer">
              <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.close} onClick={() => { updateForm({ isOverlayDescriptionVisible: false }); }} />
            </div>
          </OverlayDescription>
          <Overlay background="dark" type="confirmation" isOpen={form.isOverlayConfirmationVisible} showCloseButton={false} doClose={() => {}}>
            <div className="overlay-confirmation-header">
              <h1>{locale.OrganisationServices.overlay.confirmation.header(4)}</h1>
            </div>
            <div className="overlay-confirmation-content">
              <h4>{locale.OrganisationServices.overlay.confirmation.content}</h4>
            </div>
            <div className="overlay-confirmation-buttons">
              <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.goBack} onClick={() => { updateForm({ isOverlayConfirmationVisible: false }); }} />
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.addServiceToNoBranches(4)} onClick={() => { this.setState({ step: 1 }); updateForm({ isOverlayConfirmationVisible: false, isSidebarVisible: false, selectedService: null }); }} />
            </div>
          </Overlay>
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Playground);
