import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, FormItem, InputError } from "@patient-access/ui-kit";
import isEqual from "lodash.isequal";

import BlockAvailabilityItem from "components/Share/BlockAvailabilityItem/BlockAvailabilityItem";
import { addBlockedPeriod, clearBlockedPeriods } from "actions/calendars";
import { validateCalendarBlockPeriods, checkBlockedPeriods } from "helpers/validateData";
import { updateForm } from "actions/form";
import type { Action } from "types/actions";
import type { BlockedPeriod } from "types/calendars";

import locale from "service/locale";

type Props = {
  handleChangeMode: (type: string, status: boolean) => any,
  addBlockedPeriod: (blockedPeriod: BlockedPeriod) => Action,
  clearBlockedPeriods: () => Action,
  updateForm: (data: any) => Action,
  blockedPeriods: BlockedPeriod[],
  isBranchMember: boolean,
  newBlockedPeriods: BlockedPeriod[],
  form: any,
  isConfirmDeletionBlockedOpen: boolean,
  handleOpenBlockedPeriodConfirmation: (blockedPeriod: number) => Action,
  isProcessing: boolean
};

type State = {
  isEditMode: boolean
};

const mapStateToProps = state => ({
  newBlockedPeriods: state.calendar.newBlockedPeriods,
  form: state.form,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): Object => ({
  addBlockedPeriod: blockedPeriod => dispatch(addBlockedPeriod(blockedPeriod)),
  clearBlockedPeriods: () => dispatch(clearBlockedPeriods()),
  updateForm: data => dispatch(updateForm(data))
});

class CalendarDetailsBlockAvailability extends Component<Props, State> {
  state = {
    isEditMode: false
  };

  componentDidMount = () => {
    const { blockedPeriods, clearBlockedPeriods, addBlockedPeriod } = this.props;
    clearBlockedPeriods();
    blockedPeriods.forEach(period => {
      addBlockedPeriod(period);
    });
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { blockedPeriods } = nextProps;
    if (!isEqual(this.props.blockedPeriods, blockedPeriods)) {
      const { addBlockedPeriod, clearBlockedPeriods } = nextProps;
      clearBlockedPeriods();
      blockedPeriods.forEach(period => {
        addBlockedPeriod(period);
      });
    }
  };

  handleAddNewPeriod = () => {
    const { addBlockedPeriod, updateForm, form } = this.props;
    const id = Date.now();
    addBlockedPeriod({
      id,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      isAllDay: false
    });
    const blockedPeriods = form.blockedPeriods || {};
    blockedPeriods[id] = {
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
    };
    updateForm({ edited: "edited", blockedPeriods });
  };

  handleChangeMode = (e?: Event) => {
    e && e.preventDefault();
    const { isEditMode } = this.state;
    const { handleChangeMode, form, newBlockedPeriods } = this.props;
    if (isEditMode) {
      validateCalendarBlockPeriods(form.blockedPeriods, newBlockedPeriods) &&
        this.setState({ isEditMode: false }, () => {
          handleChangeMode("isEditingBlock", false);
        });
    } else {
      this.setState({ isEditMode: true }, () => {
        handleChangeMode("isEditingBlock", true);
      });
    }
  };

  handleDeletePeriod = (blockedPeriodId: number) => {
    const { handleOpenBlockedPeriodConfirmation } = this.props;
    handleOpenBlockedPeriodConfirmation(blockedPeriodId);
  };

  render() {
    const { isBranchMember, newBlockedPeriods, form, isProcessing } = this.props;
    const { isEditMode } = this.state;
    const  { isNotMultipleByFive, isEqualTime, isEndTimeLessThanStartTime } = checkBlockedPeriods(form.blockedPeriods);
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.AddNewCalendar.blockedTimes.title}</h3>
            {!isBranchMember && !isProcessing ? (
              <button
                className="patient-care-btn-link"
                onClick={this.handleChangeMode}
              >
                {isEditMode
                  ? locale.Buttons.buttonDone
                  : locale.Buttons.buttonEdit}
              </button>
            ) : null}
          </div>
          <p>{locale.AddNewCalendar.blockedTimes.description}</p>
        </div>
        {newBlockedPeriods.length > 0 &&
          newBlockedPeriods.map(period => (
            !period.isInternalEvent && (
              <BlockAvailabilityItem
                key={period.id}
                period={period}
                handleDeletePeriod={this.handleDeletePeriod}
                isEditMode={isEditMode}
              />)
          ))}
        {isNotMultipleByFive ? (
          <Form noValidate>
            <FormItem type="item">
              <InputError
                message={locale.AvailabilitySettings.timePeriodValidationError}
              />
            </FormItem>
          </Form>
        ) : null}
        {!isNotMultipleByFive && isEqualTime ? (
          <Form noValidate>
            <FormItem type="item">
              <InputError
                message={locale.AvailabilitySettings.equalTimeValidationError}
              />
            </FormItem>
          </Form>
        ) : null}
        {!isNotMultipleByFive && !isEqualTime && isEndTimeLessThanStartTime ? (
          <Form noValidate>
            <FormItem type="item">
              <InputError
                message={locale.AvailabilitySettings.startTimeValidationError}
              />
            </FormItem>
          </Form>
        ) : null}
        {isEditMode && (
          <div className="patient-care-block">
            <button
              className="patient-care-btn-link"
              onClick={this.handleAddNewPeriod}
            >
              {locale.AddNewCalendar.addBlockedPeriodText}
            </button>
          </div>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetailsBlockAvailability);
