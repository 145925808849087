import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconTick } from '@patient-access/ui-kit';
import './styles.scss';

class SectionPackage extends Component<Props> {
  render() {
    return (
      <div className="section-block section-package">
        <div className="wrapper section-package-wrapper">
          <div className="section-package-header">
            <h2><FormattedMessage id="landing.package.header" /></h2>
          </div>
          <div className="section-package-body">
            <div className="section-package-thead">
              <ul>
                <li />
                <li><FormattedMessage id="landing.package.type_services" /></li>
                <li><FormattedMessage id="landing.package.type_smart" /></li>
              </ul>
            </div>
            <div className="section-package-tbody">
              <ul>
                <li><FormattedMessage id="landing.package.feature_01.name" /></li>
                <li><IconTick outline /></li>
                <li></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_02.name" /></li>
                <li><IconTick outline /></li>
                <li></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_03.name" /></li>
                <li><IconTick outline /></li>
                <li></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_04.name" /></li>
                <li><IconTick outline /></li>
                <li></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_05.name" /></li>
                <li><IconTick outline /></li>
                <li></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_06.name" /></li>
                <li><IconTick outline /></li>
                <li></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_07.name" /></li>
                <li></li>
                <li><IconTick outline /></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_08.name" /></li>
                <li></li>
                <li><IconTick outline /></li>
              </ul>
              <ul>
                <li><FormattedMessage id="landing.package.feature_09.name" /></li>
                <li></li>
                <li><IconTick outline /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionPackage;
