import * as ReportsCustomConstants from 'constants/ReportsCustomConstants';

type ReportsCustom = {
  // TODO
}

const initialState: ReportsCustom = {
  organisations: {
    data: [],
    isLoading: false,
    isError: false
  },
  services: {
    data: [],
    isLoading: false,
    isError: false,
    selectedService: {}
  },
};

export default (localState: ReportsCustom = initialState, action: Action): ReportsCustom => {
  switch (action.type) {

    // REPORTS_CUSTOM_ORGANISATIONS
    case ReportsCustomConstants.GET_REPORTS_CUSTOM_ORGANISATIONS_PENDING:
      return {
        ...localState,
        organisations: {
          ...localState.organisations,
          isLoading: true,
          isError: false,
        },
      };
    case ReportsCustomConstants.GET_REPORTS_CUSTOM_ORGANISATIONS_SUCCESS:
      return {
        ...localState,
        organisations: {
          ...localState.organisations,
          isLoading: false,
          isError: false,
        },
      };
    case ReportsCustomConstants.GET_REPORTS_CUSTOM_ORGANISATIONS_ERROR:
      return {
        ...localState,
        organisations: {
          ...localState.organisations,
          isLoading: false,
          isError: true,
        },
      };
    case ReportsCustomConstants.SET_REPORTS_CUSTOM_ORGANISATIONS_ITEMS:
      return {
        ...localState,
        organisations: {
          ...localState.organisations,
          data: action.payload,
        },
      };

    // REPORTS_CUSTOM_SERVICES
    case ReportsCustomConstants.GET_REPORTS_CUSTOM_SERVICES_PENDING:
      return {
        ...localState,
        services: {
          ...localState.services,
          isLoading: true,
          isError: false,
        },
      };
    case ReportsCustomConstants.GET_REPORTS_CUSTOM_SERVICES_SUCCESS:
      return {
        ...localState,
        services: {
          ...localState.services,
          isLoading: false,
          isError: false,
        },
      };
    case ReportsCustomConstants.GET_REPORTS_CUSTOM_SERVICES_ERROR:
      return {
        ...localState,
        services: {
          ...localState.services,
          isLoading: false,
          isError: true,
        },
      };
    case ReportsCustomConstants.SET_REPORTS_CUSTOM_SERVICES_ITEMS:
      return {
        ...localState,
        services: {
          ...localState.services,
          data: action.payload,
        },
      };
      case ReportsCustomConstants.SET_REPORTS_CUSTOM_SELECTED_SERVICE:
      return {
        ...localState,
        services: {
          ...localState.services,
          selectedService: action.payload,
        },
      };

    // DEFAULT
    default:
      return localState;
  }
};
