import React, { Component } from 'react';
import './styles/task-progress-bar.scss';

type Props = {
  status: string,
  percentageCompleted: number,
  label: string,
};

class TaskProgressBar extends Component<Props> {
  render() {
    const { status, percentageCompleted, label } = this.props;

    return (
      <div className={`task-progress-bar ${status}`}>
        <span><em style={{ width: `${percentageCompleted}%` }} /></span>
        <p>{label}</p>
      </div>
    );
  }
}

export default TaskProgressBar;
