import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from 'components/Share/Modal/Modal';
import AppointmentDetails from 'components/Share/AppointmentDetails/AppointmentDetails';
import * as AppointmentDetailsConstants from 'constants/AppointmentDetailsConstants';

type Props = {
  isActiveAgendaDetails: boolean,
};

const mapStateToProps = (state) => ({
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails,
});

class FeatureAgenda extends Component<Props> {
  render() {
    const { isActiveAgendaDetails } = this.props;
    if (isActiveAgendaDetails) {
      return (
        <Modal>
          <AppointmentDetails type={AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA} />
        </Modal>
      );
    }
    return null;
  }
};

export default connect(mapStateToProps)(FeatureAgenda);
