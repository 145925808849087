import agent from 'service/agent';
import { setOrganisationHeader } from 'actions/router';
import { getToken } from 'helpers/common';
import * as CalendarSearchConstants from 'constants/CalendarSearchConstants';

export const setCalendarSearchPending = (): Action => ({
  type: CalendarSearchConstants.GET_CALENDAR_SEARCH_PENDING,
});

export const setCalendarSearchSuccess = (): Action => ({
  type: CalendarSearchConstants.GET_CALENDAR_SEARCH_SUCCESS,
});

export const setCalendarSearchError = (): Action => ({
  type: CalendarSearchConstants.GET_CALENDAR_SEARCH_ERROR,
});

export const setCalendarSearchItems = (calendarsList: Items[]): Action => ({
  type: CalendarSearchConstants.SET_CALENDAR_SEARCH_ITEMS,
  payload: { calendarsList }
});

export const setCalendarSearchServices = (servicesList: Items[]): Action => ({
  type: CalendarSearchConstants.SET_CALENDAR_SEARCH_SERVICES,
  payload: { servicesList }
});

export const setCalendarSearchFilter = (filter: Filter): Action => ({
  type: CalendarSearchConstants.SET_CALENDAR_SEARCH_FILTER,
  payload: filter,
});

export const clearCalendarSearchFilter = (): Action => ({
  type: CalendarSearchConstants.CLEAR_CALENDAR_SEARCH_FILTER,
});

export const getCalendarSearch = (organisationId?: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setCalendarSearchPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Calendars.getCalendarSearch(
        accessToken,
        organisationId
      )
      .then(calendarsList => {
        dispatch(setCalendarSearchItems(calendarsList));
        dispatch(setCalendarSearchSuccess());
      })
      .catch(err => {
        dispatch(setCalendarSearchError());
        console.log("getCalendarSearch server error or branch was not found", err);
      });
    });
};

export const getCalendarServices = (organisationId?: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getOrganisationServices(
        accessToken,
        organisationId
      )
      .then(servicesList => {
        dispatch(setCalendarSearchServices(servicesList));
      })
      .catch(err => {
        console.log("getCalendarServices server error or branch was not found", err);
      });
    });
};

export const getFilteredCalendarSearch = (query: string, branchIds: Object, serviceIds: Object, pageNumber: number, organisationId?: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setCalendarSearchPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Calendars.getFilteredCalendarSearch(
        accessToken,
        query,
        branchIds,
        serviceIds,
        pageNumber,
        organisationId
      )
      .then(calendarsList => {
        dispatch(setCalendarSearchItems(calendarsList));
        dispatch(setCalendarSearchSuccess());
      })
      .catch(err => {
        dispatch(setCalendarSearchError());
        console.log("getFilteredCalendarSearch server error or branch was not found", err);
      });
    });
};
