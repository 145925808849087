import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  form: any;
};

const mapStateToProps = (state: any) => ({
  form: state.form || {},
});

class AddOrganisationAddressInfo extends Component<Props> {
  render() {
    const { form } = this.props;
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddOrganisation.companyInfo.addressTitle}</h3>
          <p>{locale.AddOrganisation.companyInfo.addressText}</p>
        </div>
        <ul className="patient-care-unstyled-list">
          {form.companyAddress.addressLine1 ? (
            <li className="patient-care-block">
              <Form>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="branch_name"
                    message={locale.AddOrganisation.companyInfo.addressLine1}
                    size="small"
                  />
                </FormItem>
              </Form>
              <b className="patient-care-filled-input">
                {form.companyAddress.addressLine1}
              </b>
            </li>
          ) : null}
          {form.companyAddress.addressLine2 ? (
            <li className="patient-care-block">
              <Form>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="branch_name"
                    message={locale.AddOrganisation.companyInfo.addressLine2}
                    size="small"
                  />
                </FormItem>
              </Form>
              <b className="patient-care-filled-input">
                {form.companyAddress.addressLine2}
              </b>
            </li>
          ) : null}
          {form.companyAddress.town ? (
            <li className="patient-care-block">
              <Form>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="branch_name"
                    message={locale.AddOrganisation.companyInfo.addressTown}
                    size="small"
                  />
                </FormItem>
              </Form>
              <b className="patient-care-filled-input">
                {form.companyAddress.town}
              </b>
            </li>
          ) : null}
          {form.companyAddress.postcode ? (
            <li className="patient-care-block">
              <Form>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="branch_name"
                    message={locale.AddOrganisation.companyInfo.addressPostcode}
                    size="small"
                  />
                </FormItem>
              </Form>
              <b className="patient-care-filled-input">
                {form.companyAddress.postcode}
              </b>
            </li>
          ) : null}
          {form.companyAddress.country ? (
            <li className="patient-care-block">
              <Form>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="branch_name"
                    message={locale.AddOrganisation.companyInfo.addressCountry}
                    size="small"
                  />
                </FormItem>
              </Form>
              <b className="patient-care-filled-input">
                {form.companyAddress.country}
              </b>
            </li>
          ) : null}
        </ul>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(AddOrganisationAddressInfo);
