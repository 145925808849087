import React, { Component } from 'react';
import './styles/card-reports-nominations-skeleton.scss';

class CardNominationsSkeleton extends Component<Props> {
  render() {
    return (
      <div className="card-reports-nominations-skeleton">
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-30" />
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" />
      </div>
    );
  }
};

export default CardNominationsSkeleton;
