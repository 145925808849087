import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, Input, IconClose, LinkIcon, InputError } from "@patient-access/ui-kit";
import InputMask from 'react-input-mask';

import { updateForm } from "actions/form";
import type { DayTimeSession } from "types/calendars";
import * as CalendarsConstants from 'constants/CalendarsConstants';
import { checkTimeFormat } from "helpers/checkValues";
import { checkIntervals, checkIntervalsBranchAvailibility } from "helpers/validateData";
import { beforeMaskedValueChange } from "helpers/formatData";
import type { Action } from "types/actions";

import locale from 'service/locale';

type Props = {
  intervalIndex: number,
  timesSessions: DayTimeSession[],
  dayOfWeek: string,
  handleEditTime: () => Action,
  form: any,
  updateForm: (data: any) => Action
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class TimeInterval extends Component<Props> {

  handleTimeChange = ({ target: { name, value } }) => {
    const { updateForm, form, timesSessions, dayOfWeek, intervalIndex } = this.props;  
    const { from, to } = timesSessions[intervalIndex];    
    const changedOpenHours = form.openHours.slice(0); 
    const dayIndex = changedOpenHours.findIndex(date => date.day === dayOfWeek);
    const intervalSettings = {
      from,
      to,
    };              

    intervalSettings[name] = value;
    changedOpenHours[dayIndex].timesSessions.splice(intervalIndex, 1, intervalSettings);
    updateForm({
      openHours: changedOpenHours,
      isCalenderEdited: true
    });  
    this.setState({
      [name]: value
    });
  };

  addNewTimeInterval = (e: Event) => {
    e && e.preventDefault();
    const { form, updateForm, dayOfWeek, timesSessions } = this.props;        
    const changedOpenHours = form.openHours.slice(0);
    const dayIndex = changedOpenHours.findIndex(date => date.day === dayOfWeek);                
    const changedTimesSessions = timesSessions.slice(0); 

    if (timesSessions.length === 3) return;
    changedTimesSessions.push({
      from: "",
      to: ""
    });
    changedOpenHours[dayIndex].timesSessions = changedTimesSessions;        
    updateForm({
      openHours: changedOpenHours,
      isCalenderEdited: true
    });   
  };

  handleDeleteInterval = (e: Event) => {
    e && e.preventDefault();
    const { form, updateForm, timesSessions, intervalIndex, dayOfWeek } = this.props;
    const changedOpenHours = form.openHours.slice(0);
    const dayIndex = changedOpenHours.findIndex(date => date.day === dayOfWeek);      

    changedOpenHours[dayIndex].timesSessions = [
      ...timesSessions.slice(0, intervalIndex),
      ...timesSessions.slice(intervalIndex + 1)
    ];            
    updateForm({
      openHours: changedOpenHours,
      isCalenderEdited: true
    });  
  };

  render() {
    const { timesSessions, handleEditTime, intervalIndex, form, dayOfWeek } = this.props; 
    const { from, to } = timesSessions[intervalIndex];
    let isIntervalInvalid = false; 
    if(timesSessions.length >= 1 && intervalIndex !== 0 ) isIntervalInvalid  = timesSessions[intervalIndex-1].to >= from;
    let showedStartTime = from.indexOf(":", 3) !== -1 ? from.slice(0, from.indexOf(":", 3)) : from;
    let showedEndTime = to.indexOf(":", 3) !== -1 ? to.slice(0, to.indexOf(":", 3)) : to;
    if (showedStartTime === CalendarsConstants.EMPTY_VALUE) showedStartTime = "";
    if (showedEndTime === CalendarsConstants.EMPTY_VALUE) showedEndTime = "";
    const openHours = form && form.openHours;
    const branchOpenHours = form && form.branchOpenHours;
    const { isValidHours } = checkIntervals(openHours);
    const { isValidBranchAvailability } = checkIntervalsBranchAvailibility(branchOpenHours, openHours);
    const isValidInterval = isValidHours && isValidHours.find(day => (day.dayOfWeek === dayOfWeek && day.startTime === showedStartTime && day.endTime === showedEndTime));
    const isValidBranchInterval = isValidBranchAvailability && isValidBranchAvailability.find(day => (day.dayOfWeek === dayOfWeek && day.startTime === showedStartTime && day.endTime === showedEndTime));
    const isStartTimeInvalid = !checkTimeFormat(showedStartTime) || (isValidInterval && (isValidInterval.isIntervalInvalid  || isValidInterval.isEqualTime)) || (isValidBranchInterval && isValidBranchInterval.isCalendarIntervalInvalidWithBranchAvailability) || isIntervalInvalid;
    const isEndTimeInvalid = !checkTimeFormat(showedEndTime) || (isValidInterval && (isValidInterval.isIntervalInvalid || isValidInterval.isEndTimeLessThanStartTime || isValidInterval.isEqualTime)) || (isValidBranchInterval && isValidBranchInterval.isCalendarIntervalInvalidWithBranchAvailability);
    const isInvalidBranchAvailability = isValidInterval && !isValidInterval.hasEmptyPeriod && isValidBranchInterval.isCalendarIntervalInvalidWithBranchAvailability;

    const renderAddButton = () => {
      if (intervalIndex === 0) {
        return (
          <FormItem type="item">
              <div className={`patient-care-block ${timesSessions.length === 3 ? "hidden" : ""}`}>
                <button
                  className="patient-care-btn-link"
                  onClick={this.addNewTimeInterval}
                >
                  {locale.AvailabilitySettings.addNewTimeInterval}
                </button>
              </div>
          </FormItem>
        );
      };
      return null;
    }

    const renderDeleteButton = () => {
      if (intervalIndex > 0) {
        return (
          <FormItem type="item">
            <div className="patient-care-btn-delete-row" onClick={this.handleDeleteInterval}>
              <LinkIcon
                to="#delete"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.Buttons.buttonDelete}
                onClick={e => e.preventDefault()}              
              />
            </div>
          </FormItem>
        );
      };
      return null;
    }

    const renderEqualTimeError = () => {
      if (isValidInterval && isValidInterval.isEqualTime) {
        return (
          <Form noValidate>
              <FormItem type="item">
                <InputError
                  message={locale.AvailabilitySettings.equalTimeValidationError}
                />
              </FormItem>
          </Form>
        );
      };
      return null;
    };
  
    const renderTimeNotMultipleByFiveError = () => {
      if (isValidInterval && isValidInterval.isNotMultipleByFive) {
        return (
          <Form noValidate>
              <FormItem type="item">
                <InputError
                  message={locale.AvailabilitySettings.timePeriodValidationError}
                />
              </FormItem>
          </Form>
        );
      };
      return null;
    };
  
    const renderEndTimeLessThanStartTimeError = () => {
      if (isValidInterval && isValidInterval.isEndTimeLessThanStartTime) {
        return (
          <Form noValidate>
              <FormItem type="item">
                <InputError
                  message={locale.AvailabilitySettings.startTimeValidationError}
                />
              </FormItem>
          </Form>
        );
      };
      return null;
    };
  
    const renderIntervalInvalidError = () => {
      if (isValidInterval && isValidInterval.isIntervalInvalid) {
        return (
          <Form noValidate className={`patient-care-time-input-error`}>
              <FormItem type="item">
                <InputError
                  message={locale.AvailabilitySettings.intervalValidationError}
                />
              </FormItem>
          </Form>
        );
      };
      return null;
    };
  
    const renderInvalidBranchAvailabilityError = () => {
      if (isInvalidBranchAvailability) {
        return (
          <Form noValidate className={`patient-care-time-input-error`}>
              <FormItem type="item">
                <InputError
                  message={locale.AvailabilitySettings.calendarIntervalValidationError}
                />
              </FormItem>
          </Form>
        );
      };
      return null;
    };

    return (
      <Fragment>
        <Form noValidate className={`patient-care-time-inputs edited`}>
          <FormItem type="item" error={isStartTimeInvalid ? "true" : ""}>
            <InputMask
              mask={`m${showedStartTime[0] === "2" ? "M" : "9"}:sS`}
              maskChar=" "
              formatChars={CalendarsConstants.timeMaskOptions}
              onChange={this.handleTimeChange}
              value={showedStartTime}
              alwaysShowMask={false}
              beforeMaskedValueChange={beforeMaskedValueChange}
              onBlur={handleEditTime}
            >
              { () => <Input
                id="from"
                name="from"
                data-id="calendar_name"
                placeholder={locale.AvailabilitySettings.placeholderTime}
                type="time"
              />}
            </InputMask>
          </FormItem>
          <FormItem type="item" error={isEndTimeInvalid ? "true" : ""}>
            <InputMask
              mask={`m${showedEndTime[0] === "2" ? "M" : "9"}:sS`}
              maskChar=" "
              formatChars={CalendarsConstants.timeMaskOptions}
              onChange={this.handleTimeChange}
              value={showedEndTime}
              alwaysShowMask={false}
              beforeMaskedValueChange={beforeMaskedValueChange}
              onBlur={handleEditTime}
            >
              { () => <Input
                id="to"
                name="to"
                data-id="calendar_name"
                placeholder={locale.AvailabilitySettings.placeholderTime}
                type="time"
              />}
            </InputMask>
          </FormItem>
          {renderDeleteButton()}
          {renderAddButton()}
        </Form>
        {renderEndTimeLessThanStartTimeError()}
        {renderTimeNotMultipleByFiveError()}
        {renderEqualTimeError()}
        {renderIntervalInvalidError()}
        {renderInvalidBranchAvailabilityError()}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeInterval);
