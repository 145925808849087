import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { ScrollRestoration } from '@patient-access/ui-kit';
import 'date-input-polyfill-react';
// import ChartBotScripts from "components/Share/ChatBot/ChatBot";
import NotSupported from "components/Pages/NotSupported/NotSupported";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { history } from "store/history";
import { ai } from "service/telemetry";

// Include common styles
// import 'index.scss'; //bug/26535
import './index.scss';

import { Store } from 'store';
import Routes from 'routes';

import messages from './i18n/i18n';

addLocaleData([...en]);

ai.initialize();

let app =
  <Provider store={Store}>
    <IntlProvider locale="en" messages={messages['en']} textComponent={Fragment}>
      <Router history={history}>
        <ScrollRestoration>
          <Routes />
        </ScrollRestoration>
      </Router>
    </IntlProvider>
  </Provider>;

const appRoot: Element | null = document.getElementById('root');

const isIE = /*@cc_on!@*/false || !!window.document.documentMode;

isIE
  ? ReactDOM.render(<NotSupported />, appRoot)
  : appRoot
    ? ReactDOM.render(app, appRoot)
    : ReactDOM.render(<div>Can't render the App.</div>, window.document.body);

// HMR for CRA
// if (module.hot) {
//   module.hot.accept('./routes', () => {
//     let NextApp = require('./routes').default;

//     if (appRoot.hasChildNodes()) {
//       hydrate(
//         <Provider store={Store}>
//           <IntlProvider locale="en" messages={messages['en']} textComponent={Fragment}>
//             <Router history={history}>
//               <ScrollRestoration>
//                 <NextApp />
//               </ScrollRestoration>
//             </Router>
//           </IntlProvider>
//         </Provider>,
//         appRoot
//       );
//     } else {
//       render(
//         <Provider store={Store}>
//           <IntlProvider locale="en" messages={messages['en']} textComponent={Fragment}>
//             <Router history={history}>
//               <ScrollRestoration>
//                 <NextApp />
//               </ScrollRestoration>
//             </Router>
//           </IntlProvider>
//         </Provider>,
//         appRoot
//       );
//     }
//   });
// }

// a dirty way to make chat bot working
// Beacon v2 lib by helpscout.com is not supported by React ecosystem yet
// https://docs.helpscout.com/category/1205-get-started-with-beacon-20
// so, have to document.write() it like this ...
// if (process.env.NODE_ENV === "production")
//   document.write(ChartBotScripts);
