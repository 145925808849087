import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormItem, InputLabel, InputError } from "@patient-access/ui-kit";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";

import { updateForm } from "actions/form";
import { getOrganisationsForAsyncSelect } from 'actions/organisations';
import * as RolesConstants from "constants/RolesConstants";
import type { Action } from "types/actions";
import type { Organisation, Branch } from "types/organisation";
import { getOrganisationOption, getBranchOption } from 'helpers/common';

import locale from "service/locale";
import "./styles.scss";

type Props = {
  form: any,
  currentUserRoles: any[],
  roles: any,
  organisations: any[],
  updateForm: (data: any) => Action,
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
  organisationId?: string,
  branchId?: string,
  organisationDetails: Organisation,
  branchDetails: Branch,
};

const mapStateToProps = (state) => ({
  form: state.form,
  roles: state.roles,
  organisations: state.organisations.organisationsListAsync,
  currentUserRoles: state.roles.profileRoles,
  organisationDetails: state.organisationDetails,
  branchDetails: state.branchDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data) => dispatch(updateForm(data)),
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
});

class AddUserAssignRole extends Component<Props> {

  state = {
    new_user_role: "",
    new_user_organization: "",
    new_user_branch: "",
    organisationSearch: "",
  };

  componentDidMount = () => {
    const { getOrganisationsForAsyncSelect } = this.props;
    getOrganisationsForAsyncSelect();
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { organisationDetails } = this.props;

    if (organisationDetails.organisationId !== nextProps.organisationDetails.organisationId) {
      this.setState({
        new_user_organization: nextProps.organisationDetails
      });
    }
  };

  handleAddUserRoleChange = value => {
    const { updateForm, organisationId, branchId, organisationDetails } = this.props;
    const organisationOption = (organisationId && value.level > 0) && getOrganisationOption(organisationDetails);
    const branchOption = (branchId && value.level > 1) && getBranchOption(organisationOption, branchId);

    this.setState({
      new_user_role: !Array.isArray(value) ? value : {},
      new_user_organization: (organisationId && value.level > 0) ? organisationOption : "",
      new_user_branch: (branchId && value.level > 1) ? branchOption : ""
    });
    updateForm({
      new_user_role: !Array.isArray(value) ? value : {},
      new_user_organization: (value.level > 0 && organisationOption) || "",
      new_user_branch: (value.level > 1 && branchOption) || ""
    });
  };

  handleAddUserOrganisationChange = value => {
    const { updateForm } = this.props;

    this.setState({ new_user_organization: value, new_user_branch: "" });
    updateForm({ new_user_organization: value, new_user_branch: "" });
  };

  handleAddUserBranchChange = value => {
    const { updateForm } = this.props;

    this.setState({ new_user_branch: value });
    updateForm({ new_user_branch: value });
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        organisationSearch: searchValue,
      }, () => {
        if (searchValue && searchValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  handleLoadOrganisations = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  render() {
    const { currentUserRoles, organisations, roles, organisationId, branchId, form } = this.props;
    const { new_user_role, new_user_organization, new_user_branch, organisationSearch } = this.state;
    const currentRole = roles.profileCurrentRole;

    const relevantUserRoles = currentUserRoles.filter(role => role.level >= currentRole.roleLevel);
    const isRoleEdited = Object.keys(form).includes("new_user_role");
    const isRoleSelected = typeof new_user_role === "object" && new_user_role.name;

    return (
      <div className="patient-care-user-role-block">
        <div className="patient-care-block-row">
          <div className={
            new_user_role ?
              (new_user_role.level === RolesConstants.PROFILE_ROLES_LEVEL.SuperAdmin ? "patient-care-col-4" : "patient-care-col-6") :
            "patient-care-col-4"
          }>
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="new_user_role"
                  message={locale.AddUser.roleLabel}
                  size="small"
                />
              </FormItem>
            </Form>
            <Select
              id="new_user_role"
              name="new_user_role"
              options={relevantUserRoles}
              placeholder={locale.AddUser.roleSelect}
              onChange={this.handleAddUserRoleChange}
              backspaceRemovesValue = {false}
              classNamePrefix="patient-care"
              isSearchable={false}
              className={`patient-care-select ${isRoleEdited && !isRoleSelected ? "patient-care-select-error" : ""}`}
            />
            <Form>
              <FormItem type="item">
                {isRoleEdited && !isRoleSelected && <InputError message={locale.AddUser.roleError} />}
              </FormItem>
            </Form>
          </div>
          {
            isRoleSelected && new_user_role.level !== RolesConstants.PROFILE_ROLES_LEVEL.SuperAdmin &&
              (
                <div className={(new_user_role && new_user_organization && new_user_branch) ? "patient-care-col-4" : "patient-care-col-6"}>
                  <Form noValidate>
                    <FormItem type="item">
                      <InputLabel
                        htmlFor="new_user_organization"
                        message={locale.AddUser.organisationLabel}
                        size="small"
                      />
                    </FormItem>
                  </Form>

                  <AsyncSelect
                    id="new_user_organization"
                    name="new_user_organization"
                    defaultValue={organisationId ? new_user_organization : null}
                    loadOptions={this.handleLoadOrganisations}
                    defaultOptions={organisations}
                    placeholder={locale.AddUser.organisationSelect}
                    onChange={this.handleAddUserOrganisationChange}
                    onInputChange={this.handleSearchChange}
                    inputValue={organisationSearch}
                    classNamePrefix="patient-care"
                    isDisabled={!!organisationId}
                    openMenuOnFocus={true}
                  />

                </div>
              )
          }
          {
            new_user_role.level === RolesConstants.PROFILE_ROLES_LEVEL.BranchAdmin &&
              (
                <div className={(new_user_role && new_user_organization && new_user_branch) ? "patient-care-col-4" : "patient-care-col-6"}>
                  <Form noValidate>
                    <FormItem type="item">
                      <InputLabel
                        htmlFor="new_user_branch"
                        message={locale.AddUser.branchLabel}
                        size="small"
                      />
                    </FormItem>
                  </Form>
                  <Select
                    id="new_user_branch"
                    name="new_user_branch"
                    defaultValue={branchId ? new_user_branch : null}
                    options={new_user_organization.branches && new_user_organization.branches.filter(branch => branch.value)}
                    placeholder={locale.AddUser.branchSelect}
                    isDisabled={!new_user_organization || !!branchId}
                    onChange={this.handleAddUserBranchChange}
                    classNamePrefix="patient-care"
                  />
                </div>
              )
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserAssignRole);
