import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FeatureSidebar } from 'components/Share/feature';
import { setUI, setUserData, setUserStep, getBranchesSummary } from 'actions/organisationServices';
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';

type Props = {
  organisationId: string,
  serviceId: string,
  isAffiliate: boolean,
  isSidebarAddVisible: boolean,
  isDiscardModalOpen: boolean,
  userData: any,
  step: any,
  setUI: (value: any) => any,
  setUserStep: (value: any) => any,
  setUserData: (data: any) => any,
  getBranchesSummary: (organisationId: string, serviceId: string) => any,
};

const mapStateToProps = (state) => ({
  isSidebarAddVisible: state.organisationServices.ui.isSidebarAddVisible,
  step: state.organisationServices.user.step,
  userData: state.organisationServices.user.data,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setUI: (value: any) => dispatch(setUI(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  getBranchesSummary: (organisationId: string, serviceId: string) => dispatch(getBranchesSummary(organisationId, serviceId)),
});

class SidebarAddBranches extends Component<Props> {
  state = {
    isDiscardModalOpen: false,
  };

  resetServiceData = () => {
    const { setUserData } = this.props;
    setUserData({
      serviceModes: [],
      serviceIsActive: null,
      servicePrice: null,
      serviceDuration: null,
      serviceCutOff: null,
      serviceIsVatIncluded: null,
      serviceIsShowPrice: null,
      serviceBranches: [],
      bookingUrl: {
        isEdited: false,
        value: null
      }
    });
  };

  handleOpenDiscardDialog = () => {
    const { setUserStep, setUI, userData, getBranchesSummary, organisationId, serviceId } = this.props;
    let dataKeys = Object.keys(userData);
    const defaultCheckedDataKeys = ['serviceIsVatIncluded'];
    const ignoredKeys = ['serviceAvailableModes', 'serviceName', 'serviceId', 'serviceIsNhsCovered','serviceBranches'];
    const arrItems = ['serviceModes'];

    const filteruserData = (d) => {
      if ((!ignoredKeys.includes(d)) &&
        (!(defaultCheckedDataKeys.includes(d) && userData[d] === false) &&
        (!arrItems.includes(d)|| (userData[d] && userData[d].length > 0)) &&
          userData[d] !== null)) {
        return true;
      }
      return false;
    };

    const userDataKeys = dataKeys.filter((d) => filteruserData(d));

    if (!userDataKeys || userDataKeys.length > 0) {
      this.setState({ isDiscardModalOpen: true });
    }
    else {
      this.setState(
        {
          isDiscardModalOpen: false
        },
        () => {
          setUserStep(1);
          setUI({ isSidebarAddVisible: false });
          getBranchesSummary(organisationId, serviceId);
        }
      );
    }
  };

  handleDiscardChanges = () => {
    const { setUI, setUserStep, getBranchesSummary, organisationId, serviceId } = this.props;
    this.setState(
      {
        isDiscardModalOpen: false
      },
      () => {
        this.resetServiceData();
        setUserStep(1);
        setUI({ isSidebarAddVisible: false });
        getBranchesSummary(organisationId, serviceId);
      }
    );
  };
  
  handleStay = () => {
    this.setState({
      isDiscardModalOpen: false
    });
  };

  componentWillUnmount = () => {
    const { setUserStep } = this.props;
    setUserStep(1);
  }
  
  render() {
    const { isSidebarAddVisible, step, organisationId, serviceId, isAffiliate } = this.props;
    const { isDiscardModalOpen } = this.state;

    const renderContent = () => {
      switch (step) {
        case 1:
          return <StepOne isAffiliate={isAffiliate} organisationId={organisationId} serviceId={serviceId} />;
        case 2:
          return <StepTwo organisationId={organisationId} />;

        default:
          return null;
      }
    }

    if (isDiscardModalOpen) {
      return (
        <DiscardChangesOverlay
        handleDiscardChanges={this.handleDiscardChanges}
        handleStay={this.handleStay}
      />
      );
    }

    if (isSidebarAddVisible) {
      return (
        <FeatureSidebar isOpen={true} doClose={() => { this.handleOpenDiscardDialog(); }}>
          {renderContent()}
        </FeatureSidebar>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAddBranches);
