import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Checkbox } from '@patient-access/ui-kit';
import { CalendarFiltersBodyItem } from '../wrappers';
import { ButtonSettings } from '../button';
import { getCalendarColor } from 'helpers/common';
import { filterEventsByCalendars } from 'actions/calendarView';
import type { Action } from 'types/actions';

type Props = {
  calendar: Object,
  branchCalendars: any[],
  calendarFilters: string[],
  isAbleToAddAndEdit: boolean,
  filterEventsByCalendars: (calendar: Object) => Action,
  handleEditCalendar: (calendarId: string) => any,
};

const mapStateToProps = state => ({
  calendarFilters: state.calendarView.calendarFilters,
  branchCalendars: state.calendarView.branchResources,
});

const mapDispatchToProps = dispatch => ({
  filterEventsByCalendars: (calendar) => dispatch(filterEventsByCalendars(calendar)),
});

class SectionCalendarsItem extends Component<Props> {
  handleEditCalendar = (e: any) => {
    e && e.preventDefault();
    const { handleEditCalendar, calendar } = this.props;
    handleEditCalendar(calendar.id);
  };

  handleToggleCalendar = (e: any) => {
    const { calendar, branchCalendars, filterEventsByCalendars } = this.props;
    const selectedCalendar = branchCalendars.find(branchCalendar => branchCalendar.resourceId === calendar.id);
    filterEventsByCalendars(selectedCalendar);
  };

  render() {
    const { calendar, calendarFilters, isAbleToAddAndEdit } = this.props;
    const isCalendarSelected = calendarFilters.findIndex(calendarFilter => calendarFilter === calendar.id) === -1;

    return (
      <Fragment>
        <CalendarFiltersBodyItem>
          {isAbleToAddAndEdit ? <ButtonSettings onClick={this.handleEditCalendar} /> : null}
          <Checkbox
            className={`input-checkbox-avatar-${calendar.order ? getCalendarColor(calendar.order) : 1}`}
            size="small"
            id={calendar.id}
            name={calendar.name}
            value={calendar.id}
            label={calendar.name}
            onChange={this.handleToggleCalendar}
            checked={isCalendarSelected}
          />
        </CalendarFiltersBodyItem>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCalendarsItem);
