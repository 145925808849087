import locale from 'service/locale';
import * as BranchesConstants from 'constants/BranchesConstants';

export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const SET_ORGANISATIONS_LIST = "SET_ORGANISATIONS_LIST";
export const SET_ORGANISATIONSCH_LIST = "SET_ORGANISATIONSCH_LIST";
export const SET_ORGANISATIONS_DETAILS = "SET_ORGANISATIONS_DETAILS";
export const SET_ORGANISATIONS_ACTION_STATUS = "SET_ORGANISATIONS_ACTION_STATUS";
export const UPDATE_ORGANISATIONS_LIST = "UPDATE_ORGANISATIONS_LIST";
export const SET_DELETE_ORGANISATION_CONFIRMATION_STATUS = "SET_DELETE_ORGANISATION_CONFIRMATION_STATUS";
export const SET_ORGANISATIONS_PENDING = "SET_ORGANISATIONS_PENDING";
export const SET_ORGANISATIONSCH_PENDING = "SET_ORGANISATIONSCH_PENDING";
export const CREATE_ORGANISATION_PENDING = "CREATE_ORGANISATION_PENDING";
export const CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS";
export const CREATE_ORGANISATION_ERROR = "CREATE_ORGANISATION_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const UPDATE_PAYOUT_SETTINGS_PENDING = "UPDATE_PAYOUT_SETTINGS_PENDING";
export const UPDATE_PAYOUT_SETTINGS_SUCCESS = "UPDATE_PAYOUT_SETTINGS_SUCCESS";
export const UPDATE_PAYOUT_SETTINGS_ERROR = "UPDATE_PAYOUT_SETTINGS_ERROR";
export const DELETE_ORGANISATION_PENDING = "DELETE_ORGANISATION_PENDING";
export const DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS";
export const DELETE_ORGANISATION_ERROR = "DELETE_ORGANISATION_ERROR";
export const SET_ORGANISATIONS_LIST_ASYNC = "SET_ORGANISATIONS_LIST_ASYNC";
export const GET_ORGANISATIONS_DETAILS_PENDING = "GET_ORGANISATIONS_DETAILS_PENDING";
export const GET_ORGANISATIONS_DETAILS_SUCCESS = "GET_ORGANISATIONS_DETAILS_SUCCESS";
export const GET_ORGANISATIONS_DETAILS_ERROR = "GET_ORGANISATIONS_DETAILS_ERROR";
export const UPDATE_SETTINGS_PENDING = "UPDATE_SETTINGS_PENDING";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";
export const UPDATE_DETAILS_PENDING = "UPDATE_DETAILS_PENDING";
export const UPDATE_DETAILS_SUCCESS = "UPDATE_DETAILS_SUCCESS";
export const UPDATE_DETAILS_ERROR = "UPDATE_DETAILS_ERROR";
export const SET_ORGANISATION_CONFIRMATION_STATUS = "SET_ORGANISATION_CONFIRMATION_STATUS";
export const OPEN_EMPTY_BOOKING_URL_WARNING = "OPEN_EMPTY_BOOKING_URL_WARNING";
export const CLOSE_EMPTY_BOOKING_URL_WARNING = "CLOSE_EMPTY_BOOKING_URL_WARNING";
export const OPEN_TWO_FACTOR_MODAL = "OPEN_TWO_FACTOR_MODAL";
export const CLOSE_TWO_FACTOR_MODAL = "CLOSE_TWO_FACTOR_MODAL";

// ORGANISATION_SUPPORTED_FEATURES
export const SET_ORGANISATION_SUPPORTED_FEATURES_DATA = "SET_ORGANISATION_SUPPORTED_FEATURES_DATA";
export const SET_ORGANISATION_SUPPORTED_FEATURES_DATA_CLONE = "SET_ORGANISATION_SUPPORTED_FEATURES_DATA_CLONE";
export const UPDATE_ORGANISATION_SUPPORTED_FEATURES = "UPDATE_ORGANISATION_SUPPORTED_FEATURES";
export const RESET_ORGANISATION_SUPPORTED_FEATURES = "RESET_ORGANISATION_SUPPORTED_FEATURES";
export const PUT_ORGANISATION_SUPPORTED_FEATURES_PENDING = "PUT_ORGANISATION_SUPPORTED_FEATURES_PENDING";
export const PUT_ORGANISATION_SUPPORTED_FEATURES_SUCCESS = "PUT_ORGANISATION_SUPPORTED_FEATURES_SUCCESS";
export const PUT_ORGANISATION_SUPPORTED_FEATURES_ERROR = "PUT_ORGANISATION_SUPPORTED_FEATURES_ERROR";
export const SET_SMART_PHARMACY_OVERLAY_VISIBILITY = "SET_SMART_PHARMACY_OVERLAY_VISIBILITY";
export const UPDATE_ORGANISATION_SUPPORTED_FEATURES_DISABLE_ALL_BRANCHES = "UPDATE_ORGANISATION_SUPPORTED_FEATURES_DISABLE_ALL_BRANCHES";


export const ELEMENTS_PER_PAGE = 30;
export const SEARCH_MODE = {
  BOTH: 0,
  ORGANISATIONS: 1,
  BRANCHES: 2,
};

export const DROPDOWN_OPTION_DEFAULT = {
  value: undefined,
  label: locale.Share.defaultOrganisationSelect,
  branches: [ BranchesConstants.DROPDOWN_OPTION_DEFAULT ]
};

export const DEFAULT_ORGANISATIONID = "00000000-0000-0000-0000-000000000000";

export const BANK_ACCOUNT_VERIFIED = "verified";
export const BANK_ACCOUNT_PENDING = "pending";
export const PAYMENT_METHOD = [
  { id: 0, value: false, enumValue: "none" },
  { id: 1, value: true, enumValue: "online" },
  { id: 2, value: false, enumValue: "payOnSpot" },
];

export const ORGANISATION_DROPDOWN_LIST = [
  { value: 0, label: "PAP organisation", type: 0, mode: 1 },
  { value: 1, label: "PAP affiliate organisation", type: 2, mode: 2 },
];

export const ORGANISATION_TWO_FACTOR_LIST = [
  { value: 0, label: "Not required"},
  { value: 1, label: "Required"},
];

export const ORGANISATION_TYPES_LIST = [
  { value: 0, label: "PAP organisation", type: 0, mode: 1 },
  { value: 1, label: "PAP integrated organisation", type: 1, mode: 1 },
  { value: 2, label: "PAP affiliate organisation", type: 2, mode: 2 },
];

export const periodsList = [
  { value: 5, label: '5 min' },
  { value: 10, label: '10 min' },
  { value: 15, label: '15 min' },
  { value: 20, label: '20 min' },
  { value: 25, label: '25 min' },
  { value: 30, label: '30 min' },
  { value: 35, label: '35 min' },
  { value: 40, label: '40 min' },
  { value: 45, label: '45 min' },
  { value: 50, label: '50 min' },
  { value: 55, label: '55 min' },
  { value: 60, label: '60 min' },
];

export const ORGANISATION_TYPE = {
  INTERNAL: 0,
  INTEGRATE: 1,
  AFFILIATE: 2
}

export const ORGANISATION_DETAILS_UPDATE_STATUS = {
  VIEW: "view",
  UPDATED: "updated"
}

export const SMART_PHARMACY = "SmartPharmacy";
export const SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS = "MedicationNotifications";
export const SUPPORTEDFEATURE_ENHANCEDPROFILE = "EnhancedProfile";

export type ConfirmationStatus = $Values<typeof ORGANISATION_DETAILS_UPDATE_STATUS>;
