import React, { Component } from 'react';
import Select from 'react-select';
import { FormItem, InputError } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import { periodsList } from 'constants/BranchesConstants';
import locale from 'service/locale';

type Props = {
  userData: any,
  branchServiceData: any,
  isError: boolean,
  setUserData: any,
  handleValidateDuration: any,
  isFormEdited: any,
};

class SectionDuration extends Component<Props,State> {

  render() {
    const { userData, branchServiceData, isError, setUserData, handleValidateDuration, isFormEdited } = this.props;

    const handleDuration = (e: any) => {
      setUserData({ serviceDuration: Number(e.value) });
      handleValidateDuration(e.value);
      if (branchServiceData.duration !== Number(e.value)) {
        isFormEdited(true);
      } else { isFormEdited(false); }
    }

    const handleUndoChanges = () => {
      isFormEdited(false);
      setUserData({ serviceDuration: Number(branchServiceData.duration) });
    }

    const isDurationModified = () => {
      if (branchServiceData.duration !== userData.serviceDuration) {
        return true;
      } return false;
    }

    return (
      <SidebarBodySection>
        <SidebarBodyHeader>
          {isDurationModified() ? <button onClick={handleUndoChanges}> {locale.BranchServicesRedesign.button.undoChanges}</button> : null}
          <h3>{locale.BranchServicesRedesign.section.appointmentDuration.header}</h3>
        </SidebarBodyHeader>
        <SidebarBodyContent>
          <FormItem type="item" error={isError ? 'error' : null}>
            <div className="row row-inline">
              <Select
                id="appointmentDuration"
                name="appointmentDuration"
                className={`patient-care-select select-number ${isError ? 'patient-care-select-error' : ''}`}
                classNamePrefix="patient-care"
                placeholder={locale.BranchServicesRedesign.section.appointmentDuration.placeholder}
                options={periodsList}
                onChange={handleDuration}
                value={periodsList.find(option => option.value === userData.serviceDuration)}
              />
            </div>
            {isError ? <InputError message={locale.BranchServicesRedesign.validationError.appointmentDuration} /> : null}
          </FormItem>
        </SidebarBodyContent>
      </SidebarBodySection>
    );
  }
}

export default SectionDuration;
