import React, { Component } from 'react';

import './styles/skeleton-table.scss';

type Props = {
  className?: string,
  columns: number,
  isSticky: boolean,
  isGrey: boolean,
};

class SkeletonTable extends Component<Props> {
  render() {
    const { className, columns, isSticky, isGrey } = this.props;

    const renderHeadClassName = () => {
      return `skeleton-table-row skeleton-table-row-head${isSticky ? ' skeleton-table-row-head-sticky' : ''}${isGrey ? ' skeleton-table-row-head-grey' : ''}`;
    }

    const renderHead = (columns) => {
      switch (columns) {
        case 7:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        case 6:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        case 5:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        case 4:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        case 3:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        case 2:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        case 1:
          return (
            <div className={renderHeadClassName()}>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" /></div>
            </div>
          );
        default:
          return null;
      }
    }

    const renderBody = (columns) => {
      switch (columns) {
        case 7:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        case 6:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        case 5:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        case 4:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        case 3:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        case 2:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-80" /></div>
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        case 1:
          return (
            <div className="skeleton-table-row skeleton-table-row-body">
              <div className="skeleton-table-cell"><div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" /></div>
            </div>
          );
        default:
          return null;
      }
    }

    return (
      <div className={`skeleton-table${className ? ` ${className}` : ''}`}>
        {renderHead(columns)}
        {renderBody(columns)}
        {renderBody(columns)}
        {renderBody(columns)}
        {renderBody(columns)}
        {renderBody(columns)}
        {renderBody(columns)}
      </div>
    );
  }
};

export default SkeletonTable;
