import React, { Component } from 'react';
import { FormItem, InputError, Input } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import { checkURL } from 'helpers/checkValues';
import locale from 'service/locale';

type Props = {
  userData: any,
  branchServiceData: any,
  isError: boolean,
  isAffiliate: boolean,
  setUserData: any,
  handleValidateBookingUrl: any,
  defaultUrl:string,
};

class SectionBookingUrl extends Component<Props> {
  render() {
    const { userData, branchServiceData, isError, isAffiliate, setUserData, handleValidateBookingUrl, defaultUrl } = this.props;

    const handleBookingUrl = (e: any) => {
      setUserData({ serviceBookingUrl: e.target.value });
      if (e.target.value === '') {
        handleValidateBookingUrl(true);
      } else {
        if (checkURL(e.target.value)) {
          handleValidateBookingUrl(true);
        } else {
          handleValidateBookingUrl(false);
        }
      }
    }

    const handleUndoChanges = () => {
      setUserData({ serviceBookingUrl: (branchServiceData.bookingpoint && branchServiceData.bookingpoint.url) });
    }

    const isbookingUrlModified = () => {
      if (branchServiceData.bookingpoint && branchServiceData.bookingpoint.url && 
        branchServiceData.bookingpoint.url !== userData.serviceBookingUrl) {
        return true;
      } return false;
    }

    if (isAffiliate) {
      return (
        <SidebarBodySection>
          <SidebarBodyHeader>
            {isbookingUrlModified() ? <button onClick={handleUndoChanges}> {locale.BranchServicesRedesign.button.undoChanges}</button> : null}
            <h3>{locale.BranchServicesRedesign.section.bookingUrl.header}</h3>
            <p>{locale.BranchServicesRedesign.section.bookingUrl.descriptionOne}</p>
            <p>{locale.BranchServicesRedesign.section.bookingUrl.descriptionTwo}</p>
          </SidebarBodyHeader>
          <SidebarBodyContent>
            <FormItem type="item" error={isError ? 'error' : null}>
              <div className="row">
                <Input
                  type="text"
                  id="some-id"
                  name="some-name"
                  placeholder={locale.BranchServicesRedesign.section.bookingUrl.placeholder}
                  onChange={handleBookingUrl}
                  value={defaultUrl}
                />
              </div>
              {isError ? <InputError message={locale.BranchServicesRedesign.validationError.bookingUrl} /> : null}
            </FormItem>
          </SidebarBodyContent>
        </SidebarBodySection>
      );
    }
    return null;
  }
}

export default SectionBookingUrl;
