import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, SearchFilter, IconSearch } from '@patient-access/ui-kit';
import { setUserSearch, setUI } from 'actions/organisationServices';
import BreadcrumbsServices from '../BreadcrumbsServices/BreadcrumbsServices';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  searchValue: string,
  isSuperAdmin: boolean,
  setUserSearch: (value: string) => any,
  setUI: (value: any) => any,
};

const mapStateToProps = state => ({
  searchValue: state.organisationServices.user.search.servicesSummary,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserSearch: (value: string) => dispatch(setUserSearch(value)),
  setUI: (value: any) => dispatch(setUI(value)),
});

class FilterServicesSummary extends Component<Props> {
  componentWillUnmount = () => {
    const { setUserSearch } = this.props;
    setUserSearch({ servicesSummary: '' });
  }

  render() {
    const { organisationId, searchValue, isSuperAdmin, setUserSearch, setUI } = this.props;

    return (
      <Fragment>
        {isSuperAdmin ? <div className="row"><BreadcrumbsServices organisationId={organisationId} /></div> : null}
        <div className="row">
          <div className="column column-full">
            <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
              <SearchFilter
                autoComplete="off"
                id="searchServicesSummary"
                name="searchServicesSummary"
                placeholder={locale.OrganisationServices.filter.servicesSummary.placeholder}
                clearHandler={() => { setUserSearch({ servicesSummary: '' }); }}
                onChange={(e) => { setUserSearch({ servicesSummary: e.target.value }); }}
                icon={<IconSearch outline />}
                value={searchValue}
              />
            </Form>
          </div>
          <div className="column">
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.addService}
              onClick={() => { setUI({ isSidebarAddVisible: true }); }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterServicesSummary);
