import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Form, FormItem, Checkbox } from "@patient-access/ui-kit";
import { setEnabledServiceDetails } from "actions/enabledServices";
import { modesOptions } from "constants/BranchesConstants";
import locale from "service/locale";

type Props = {
  row: any[],
  isEditMode: boolean,
  setEnabledServiceDetails: (serviceId: string, data: any) => Action,
  handleDataChanged: () => any
  
};

type State = {
  errorModes: boolean,
};

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({  
  setEnabledServiceDetails: (serviceId, data) => dispatch(setEnabledServiceDetails(serviceId, data)),  
});

class EnabledServicesTableRow extends Component<Props, State> { 

  state = {
    errorModes: false
  }

  componentDidMount = () => {
    const { row } = this.props;
    if (row.allowedModes === undefined || row.allowedModes.length === 0) this.setState({ errorModes: true });
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { row } = nextProps;
    // prevent false errorModes on 'Discard changes'
    if (row.allowedModes && row.allowedModes.length !== 0) this.setState({ errorModes: false });
    else this.setState({ errorModes: true });
  }

  handleModes = (e: any) => {
    const { setEnabledServiceDetails, row, handleDataChanged } = this.props;
    const modesArray = e.map(option => option.value);
    setEnabledServiceDetails(row.id, { allowedModes: modesArray });
    handleDataChanged();
    if (modesArray.length === 0) {
      this.setState({ errorModes: true });
    } else {
      this.setState({ errorModes: false });
    }
  }

  handleDefault = () => {    
    const { setEnabledServiceDetails, row, handleDataChanged } = this.props;
    setEnabledServiceDetails(row.id, { enabled: !row.enabled });
    handleDataChanged();
  }  

  renderName = () => {
    const { row } = this.props;    
    return <b className="patient-care-service-table column">{row.name}</b>
  }  

  renderDefault = () => {
    const { row, isEditMode } = this.props;
    return (
        <Form >
          <FormItem type="item">
              <Checkbox
                id={`default-id-${row.id}`}
                name={`default-name-${row.id}`}
                checked={row.enabled ? row.enabled : false}
                value={row.id}
                disabled={!isEditMode}
                className="patient-care-hidden-label service-check-col"
                label={row.id}
                onChange={this.handleDefault}
              />
          </FormItem>
        </Form>
    );
  }
  
  renderModes = () => {
    const { row, isEditMode } = this.props;
    const { errorModes } = this.state;
    if (isEditMode) {
      return (
        <Select
          id={`modes-id-${row.id}`}
          name={`modes-name-${row.id}`}
          placeholder={locale.AddNewServices.modesPlaceholder}
          defaultValue={row.allowedModes ? modesOptions.filter(el => row.allowedModes.indexOf(el.value) !== -1) : ''}
          options={modesOptions}
          classNamePrefix="patient-care"
          onChange={this.handleModes}
          className={`patient-care-select patient-care-select-multi ${errorModes ? 'patient-care-select-error' : ''}`}
          hideSelectedOptions={false}
          isSearchable={false}
          closeMenuOnSelect={false}
          isMulti
        />
      );
    }
    if (row.allowedModes && row.allowedModes.length !== 0) {
      if (row.allowedModes.length > 1) return <b>{row.allowedModes.length} {locale.BranchServices.modes}</b>;
      return <b>{modesOptions.find(option => option.value === row.allowedModes[0]).label}</b>;
    }
    return <b>{locale.BranchServices.modesNone}</b>;
  }

  render() {
    const { row } = this.props;
    return (
      <tr key={row.id}>
        <td className="first" >{this.renderName()}</td>
        <td className="extra" >{this.renderDefault()}</td>
        <td className="patient-care-services-custom-table-left">
          <div className="patient-care-services-modes">
            {this.renderModes()}
          </div>
        </td>
        <td className="last"></td>
      </tr>
    );
  }
}

export default connect(
  undefined,
  mapDispatchToProps
)(EnabledServicesTableRow);
