import * as LoginConstants from "constants/LoginConstants";
import type { LoginState } from "types/user";
import type { LoginAction } from "types/login";

const initialState: LoginState = {
  isAuth: false,
  token: null,
  refreshToken: null,
  expires: null,
  email: null,
  errorCode: null,
  isLastAttempt: false,
  isPending: false,
};

export default (localState: LoginState = initialState, action: LoginAction): LoginState => {
  switch (action.type) {
    case LoginConstants.LOGIN_SUCCESS: {
      const { email, token, expires, refreshToken } = action.payload;
      return {
        ...localState,
        email,
        token,
        refreshToken,
        expires,
        isAuth: true,
        isLastAttempt: false,
        errorCode: null,
        isPending: false,
      };
    }
    case LoginConstants.LOGIN_ERROR: {
      const { errorCode, isLastAttempt } = action.payload;
      return {
        ...localState,
        errorCode,
        isLastAttempt
      };
    }
    case LoginConstants.LOGIN_PENDING: {
      return {
        ...localState,
        isPending: true,
      }
    }
    case LoginConstants.LOGIN_PENDING_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      }
    }
    case LoginConstants.LOGIN_PENDING_ERROR: {
      return {
        ...localState,
        isPending: false,
      }
    }
    case LoginConstants.CLEAR_LOGIN_ERROR: {
      return {
        ...localState,
        errorCode: null
      }
    }
    case LoginConstants.HIDE_LOGIN_WARNING: {
      return {
        ...localState,
        isLastAttempt: false
      }
    }
    default: {
      return localState;
    }
  }
};
