import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, LogoNHS } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarFooter } from 'components/Share/sidebar';
import { putBranchServicesUpdate, setUserStep, setUserData, setUI, setServiceFutureAppointments } from 'actions/branchServices';
import { ButtonDelete } from 'components/Share/button';
import { SectionModes, SectionStatus, SectionPrice, SectionDuration, SectionCutOff, SectionBookingUrl } from './section';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as RolesConstants from 'constants/RolesConstants';
import locale from 'service/locale';
import { checkURL } from 'helpers/checkValues';

type Props = {
  organisationId: string,
  branchId: string,
  serviceId: string,
  userData: any,
  servicesSummary: any,
  isSuperAdmin: boolean,
  isAffiliate: boolean,
  handleClose: any,
  setUserStep: (value: any) => any,
  setUserData: (data: any) => any,
  setUI: (value: any) => any,
  setServiceFutureAppointments: (id: string) => Action,
};

type State = {
  isErrorModes: boolean,
  isErrorStatus: boolean,
  isErrorPrice: boolean,
  isErrorDuration: boolean,
  isErrorCutOff: boolean,
  isErrorBookingUrl: boolean,
  isModesEdited: boolean,
  isStatusEdited: boolean,
  isPriceEdited: boolean,
  isDurationEdited: boolean,
  isCutOffEdited: boolean,
  isErrorBookingURL:boolean,
  isBookingUrlEdited:boolean,
};

const mapStateToProps = (state) => ({
  userData: state.branchServices.user.data,
  servicesSummary: state.branchServices.servicesSummary.data,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  isAffiliate: state.organisationDetails.organisationType === OrganisationsConstants.ORGANISATION_TYPE.AFFILIATE,
  isLoading: state.branchServices.servicesUpdate.isLoading,
  orgBookingDetails: state.organisationDetails.booking,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  putBranchServicesUpdate: (organisationId: string, branchId: string, servicesList: any[], snackbarText: string) => dispatch(putBranchServicesUpdate(organisationId, branchId, servicesList, snackbarText)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUI: (value: any) => dispatch(setUI(value)),
  setServiceFutureAppointments: (id) => dispatch(setServiceFutureAppointments(id)),
});

class StepOne extends Component<Props, State> {
  state = {
    isErrorModes: false,
    isErrorStatus: false,
    isErrorPrice: false,
    isErrorDuration: false,
    isErrorCutOff: false,
    isModesEdited: false,
    isStatusEdited: false,
    isPriceEdited: false,
    isDurationEdited: false,
    isCutOffEdited: false,
    isErrorBookingURL:!((this.props.userData.serviceBookingUrl && checkURL(this.props.userData.serviceBookingUrl)) || !this.props.userData.serviceBookingUrl),
    isBookingUrlEdited: false,
  }

  render() {
    const { organisationId, branchId, serviceId, userData, servicesSummary, isSuperAdmin, isAffiliate, isLoading, handleClose, putBranchServicesUpdate, setUserData, setUI, orgBookingDetails } = this.props;
    const { isErrorModes, isErrorStatus, isErrorPrice, isErrorDuration, isErrorCutOff, isModesEdited, isStatusEdited, isPriceEdited, isDurationEdited, isCutOffEdited, isErrorBookingURL, isBookingUrlEdited } = this.state;
    

    const isEmptyModes = userData.serviceModes.length === 0;
    const isEmptyStatus = userData.serviceIsActive === null;
    const isEmptyShowPrice = userData.serviceIsShowPrice === null;
    const isEmptyPrice = userData.servicePrice === null;
    const isValidPrice = !(userData.servicePrice !== '' && userData.servicePrice >= 0);
    const isEmptyDuration = userData.serviceDuration === null;
    const isEmptyCutOff = userData.serviceCutOff === null;
    
    const branchServiceData = servicesSummary.find(service => service.id.includes(serviceId));

    const isFormValid = () => {
      if (isAffiliate) {
        return !isEmptyModes && !isEmptyStatus && (userData.serviceIsShowPrice ? !isEmptyPrice && !isValidPrice : !isEmptyShowPrice) && !isEmptyDuration && !isErrorBookingURL;
      } else {
        return !isEmptyModes && !isEmptyStatus && !isEmptyPrice && !isValidPrice && !isEmptyDuration && !isEmptyCutOff;
      }
    }

    const handleValidateForm = () => {
      if (isAffiliate) {
        handleValidateModes(userData.serviceModes);
        handleValidateStatus(!isEmptyStatus);
        handleValidatePrice(userData.serviceIsShowPrice ? !isEmptyPrice && !isValidPrice : !isEmptyShowPrice);
        handleValidateDuration(!isEmptyDuration);
      } else {
        handleValidateModes(userData.serviceModes);
        handleValidateStatus(!isEmptyStatus);
        handleValidatePrice(!isEmptyPrice && !isValidPrice);
        handleValidateDuration(!isEmptyDuration);
        handleValidateCutOff(!isEmptyCutOff);
      }
    }

    const handleValidateModes = (value) => {
      this.setState({ isErrorModes: !value.length });
    }

    const handleValidateStatus = (value) => {
      this.setState({ isErrorStatus: !value });
    }

    const handleValidatePrice = (value) => {
      this.setState({ isErrorPrice: !value });
    }

    const handleValidateDuration = (value) => {
      this.setState({ isErrorDuration: !value });
    }

    const handleValidateCutOff = (value) => {
      this.setState({ isErrorCutOff: !value });
    }

    const handleEditService = () => {
      let servicesList = [...servicesSummary];
      let serviceData = servicesList.find(service => service.id.includes(userData.serviceId));
      
      serviceData.allowedModes = userData.serviceAvailableModes;
      serviceData.nhsCovered = userData.serviceIsNhsCovered;
      serviceData.modes = userData.serviceModes;
      serviceData.paused = !userData.serviceIsActive;
      serviceData.showPrice = userData.serviceIsShowPrice || false; // API_DOESNT_ACCEPT_NULL_VALUES
      serviceData.price = userData.servicePrice;
      serviceData.vatIncluded = userData.serviceIsVatIncluded || false; // API_DOESNT_ACCEPT_NULL_VALUES
      serviceData.duration = userData.serviceDuration;
      serviceData.lastMinute = userData.serviceCutOff || 0; // API_DOESNT_ACCEPT_NULL_VALUES
      serviceData.bookingpoint = {
        url: userData.serviceBookingUrl,
      };

      putBranchServicesUpdate(organisationId, branchId, servicesList, locale.Snackbar.branchUpdateService(userData.serviceName));
    }

    const handleRemoveService = () => {
      const { userData, setServiceFutureAppointments } = this.props;
      setServiceFutureAppointments(userData.serviceId);
      setUI({ isOverlayRemoveServiceVisible: true });
    }

    const setModesEdited = (value) => {
      this.setState({ isModesEdited: value });
    }

    const setStatusEdited = (value) => {
      this.setState({ isStatusEdited: value });
    }

    const setPriceEdited = (value) => {
      this.setState({ isPriceEdited: value });
    }

    const setDurationEdited = (value) => {
      this.setState({ isDurationEdited: value });
    }

    const setCutOffEdited = (value) => {
      this.setState({ isCutOffEdited: value });
    }

    const isFormEdited = () => {
      if (isModesEdited ||isStatusEdited ||isPriceEdited ||isDurationEdited ||isCutOffEdited || 
        (isAffiliate && isBookingUrlEdited && userData.serviceBookingUrl!== branchServiceData.bookingUrl)) {
        return true;
      }
      return false;
    }

    const handleValidateBookingUrl = (isUrlValue) => {
      this.setState({ isErrorBookingURL: !isUrlValue, isBookingUrlEdited: true });
    }

    const getDefaultBookingUrl = () => {
      if ((userData.serviceBookingUrl !== '' && userData.serviceBookingUrl != null) || isBookingUrlEdited)
        return userData.serviceBookingUrl;
      else
        return ((branchServiceData.bookingpoint != null && branchServiceData.bookingpoint !== '') ?
          ((branchServiceData.bookingUrl != null && branchServiceData.bookingUrl !== '') ?
            branchServiceData.bookingUrl : (orgBookingDetails.point && orgBookingDetails.point.url))
          : '');
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.BranchServicesRedesign.sidebar.editService.header}</h2>
          <p>
            <span>{userData.serviceName}</span>
            {userData.serviceIsNhsCovered ? <i><LogoNHS /></i> : null}
          </p>
        </SidebarHeader>
        <SidebarBody>
          <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
            <SectionModes userData={userData} branchServiceData={branchServiceData} setUserData={setUserData} isError={isErrorModes} isSuperAdmin={isSuperAdmin} handleValidateModes={handleValidateModes} isFormEdited={setModesEdited} />
            <SectionStatus userData={userData} branchServiceData={branchServiceData} setUserData={setUserData} isError={isErrorStatus} handleValidateStatus={handleValidateStatus} isFormEdited={setStatusEdited} />
            <SectionPrice userData={userData} branchServiceData={branchServiceData} setUserData={setUserData} isError={isErrorPrice} isAffiliate={isAffiliate} handleValidatePrice={handleValidatePrice} isFormEdited={setPriceEdited} />
            <SectionDuration userData={userData} branchServiceData={branchServiceData} setUserData={setUserData} isError={isErrorDuration} handleValidateDuration={handleValidateDuration} isFormEdited={setDurationEdited} />
            <SectionCutOff userData={userData} branchServiceData={branchServiceData} setUserData={setUserData} isError={isErrorCutOff} isAffiliate={isAffiliate} handleValidateCutOff={handleValidateCutOff} isFormEdited={setCutOffEdited} />
            {isAffiliate && <SectionBookingUrl userData={userData} 
                                               setUserData={setUserData} 
                                               isError={isErrorBookingURL} 
                                               isAffiliate={isAffiliate} 
                                               branchServiceData={branchServiceData}
                                               defaultUrl = { getDefaultBookingUrl() } 
                                               handleValidateBookingUrl={handleValidateBookingUrl} />}
          </Form>
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column">
              <ButtonDelete onClick={ () => handleRemoveService() } />
            </div>
            <div className="column">
              <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.cancel} onClick={handleClose} />
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.editBranchService} onClick={isFormValid() && isFormEdited() ? handleEditService : handleValidateForm} className={isFormValid() && isFormEdited() ? '' : 'ui-kit-button-disabled-visually'} isLoading={isLoading} />
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
