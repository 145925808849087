import * as TaskManagerConstants from 'constants/TaskManagerConstants';
import { getToken } from 'helpers/common';
import agent from 'service/agent';
import { Store } from 'store/index';
import { showSnackbarStatus } from 'actions/snackbar';
import locale from "service/locale";

// TASK_MANAGER_IS_VISIBLE
export const setTaskManagerIsVisible = (isVisible: boolean): Action => ({
  type: TaskManagerConstants.SET_TASK_MANAGER_IS_VISIBLE,
  payload: isVisible,
});

// OVERLAY
export const setTaskManagerOverlayType = (type: string): Action => ({
  type: TaskManagerConstants.SET_TASK_MANAGER_OVERLAY_TYPE,
  payload: type,
});

export const setTaskManagerOverlayIsVisible = (isVisible: boolean): Action => ({
  type: TaskManagerConstants.SET_TASK_MANAGER_OVERLAY_VISIBLE,
  payload: isVisible,
});

export const setBranchesOverlayIsVisible = (isVisible: boolean): Action => ({
  type: TaskManagerConstants.SET_TASK_MANAGER_BRANCHES_OVERLAY_VISIBLE,
  payload: isVisible,
});

// TASKS_LIST
export const setTasksListPending = (): Action => ({
  type: TaskManagerConstants.GET_TASK_MANAGER_TASKS_LIST_PENDING,
});

export const setTasksListSuccess = (): Action => ({
  type: TaskManagerConstants.GET_TASK_MANAGER_TASKS_LIST_SUCCESS,
});

export const setTasksListError = (): Action => ({
  type: TaskManagerConstants.GET_TASK_MANAGER_TASKS_LIST_ERROR,
});

export const setTasksListData = (data: any): Action => ({
  type: TaskManagerConstants.SET_TASK_MANAGER_TASKS_LIST_DATA,
  payload: data,
});

//TASK_DETAIL
export const setTaskDetailPending = (): Action => ({
  type: TaskManagerConstants.GET_TASK_MANAGER_TASK_DETAIL_PENDING,
});

export const setTaskDetailSuccess = (): Action => ({
  type: TaskManagerConstants.GET_TASK_MANAGER_TASK_DETAIL_SUCCESS,
});

export const setTaskDetailError = (): Action => ({
  type: TaskManagerConstants.GET_TASK_MANAGER_TASK_DETAIL_ERROR,
});

export const setTaskDetailData = (data: any): Action => ({
  type: TaskManagerConstants.SET_TASK_MANAGER_TASK_DETAIL_DATA,
  payload: data,
});

//CANCEL_TASKS
export const cancelTaskPending = (): Action => ({
  type: TaskManagerConstants.CANCEL_TASK_MANAGER_TASK_PENDING,
});

export const cancelTaskSuccess = (): Action => ({
  type: TaskManagerConstants.CANCEL_TASK_MANAGER_TASK_SUCCESS,
});

export const cancelTaskError = (): Action => ({
  type: TaskManagerConstants.CANCEL_TASK_MANAGER_TASK_ERROR,
});

//DISMISS_TASKS
export const dismissTaskPending = (): Action => ({
  type: TaskManagerConstants.DISMISS_TASK_MANAGER_TASK_PENDING,
});

export const dismissTaskSuccess = (): Action => ({
  type: TaskManagerConstants.DISMISS_TASK_MANAGER_TASK_SUCCESS,
});

export const dismissTaskError = (): Action => ({
  type: TaskManagerConstants.DISMISS_TASK_MANAGER_TASK_ERROR,
});

export const getTasksList = () => (dispatch: Function) => {
  // dispatch(setTasksListPending());
  getToken(dispatch)
    .then(accessToken => {
      asyncGetTasksList(accessToken);
    });
};

async function asyncGetTasksList (accessToken) {
  const promises = [];
  const { dispatch } = Store;

  promises.push(await getTasksFromApi(accessToken));
  Promise.allSettled(promises)
    .then((response) => {
      const failedApiResponses = response.filter(resp => resp.value === "FAILED");
      if (failedApiResponses && failedApiResponses.length > 0) {
        dispatch(setTasksListError());
      } else {
        const successApiResponses = response.filter(resp => resp.status === "fulfilled");
        dispatch(setTasksListData(successApiResponses[0].value.result));
        dispatch(setTasksListSuccess());
      }
    });
}

function getTasksFromApi (accessToken) {
  return new Promise((resolve) => {
    agent.TaskManager.getTasksList(accessToken)
      .then((response) => {
        resolve(response);
      })
      .catch(err => {
        resolve("FAILED");
        console.log('getTasksList server error or branch was not found', err.response && err.response.body);
      })
  });
}

export const getTaskDetail = (taskId: string) => (dispatch: Function) => {
  dispatch(setTaskDetailPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.TaskManager.getTaskDetail(
        taskId,
        accessToken,
      )
      .then(data => {
        dispatch(setTaskDetailData(data.result));
        dispatch(setTaskDetailSuccess());
      })
      .catch(err => {
        dispatch(setTaskDetailError());
        console.log("getTaskDetail server error or specified task is not found", err);
      });
    });
};

export const cancelTask = (taskId: string) => (dispatch: Function) => {
  dispatch(cancelTaskPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.TaskManager.cancelTask(
        taskId,
        accessToken,
      )
      .then(() => {
        dispatch(cancelTaskSuccess());
      })
      .catch(err => {
        dispatch(cancelTaskError());
        console.log("The specified task is completed or failed or not found", err);
      });
    });
};

export const dismissTask = (taskIds: any) => (dispatch: Function) => {
  const dismissTaskRequestModel = {
    taskIds: taskIds
    };
  dispatch(dismissTaskPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.TaskManager.dismissTask(
        dismissTaskRequestModel,
        accessToken,
      )
      .then(() => {
        dispatch(dismissTaskSuccess());
      })
      .catch(err => {
        dispatch(dismissTaskError());
        console.log("The specified task is not found or dismissTask server error", err);
      });
    });
};

//Tryagain Task

export const retryTaskPending = (): Action => ({
  type: TaskManagerConstants.RETRY_TASK_MANAGER_TASK_PENDING,
});

export const retryTaskSuccess = (): Action => ({
  type: TaskManagerConstants.RETRY_TASK_MANAGER_TASK_SUCCESS,
});

export const retryTaskError = (): Action => ({
  type: TaskManagerConstants.RETRY_TASK_MANAGER_TASK_ERROR,
});

export const retryTask = (taskId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const userProfile = currentState.profile;
  const { displayName } = userProfile;
  const retryTaskRequestModel = {
    taskId: taskId,
    userName: displayName
    };
  dispatch(retryTaskPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.TaskManager.retryTask(
        retryTaskRequestModel,
        accessToken,
      )
      .then((res) => {
        dispatch(retryTaskSuccess());
        if(res.hasErrors || res.errors != null){
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksError));
        }
        else {
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksSuccess));
        }
      })
      .catch(err => {
        dispatch(retryTaskError());
        console.log("The specified task is completed or failed or not found", err);
        dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksError));
      });
    });
};
