import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ResponsiveContainer, BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, LabelList } from 'recharts';
import { SkeletonChart } from 'components/Pages/Reports/skeleton';
import moment from 'moment';
import { ReportsTooltip } from 'components/Pages/Reports/wrappers';
import { formatNumber, formatNumberAbbreviate } from 'helpers/formatData';
import * as ColorConstants from 'constants/ColorConstants';
import './styles.scss';
import locale from 'service/locale';

type Props = {
  isResizing: boolean,
  medicationRequests: any,
};

const mapStateToProps = (state) => ({
  medicationRequests: state.reportsSmartPharmacy.metrics.medication.data,
});

class ChartMedication extends Component<Props> {
  formatXAxis = (tickItem) => {
    return moment(tickItem).format('MMMM');
  }

  formatYAxis = (tickItem) => {
    return formatNumberAbbreviate(tickItem);
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      return (
        <ReportsTooltip>
          <h4>{payload[0] ? <Fragment>{moment(payload[0].payload.date).format('MMMM YYYY')} breakdown</Fragment> : null}</h4>
          <ul>
            {payload.map((item, index) => {
              return <li key={index}><span><strong>{item.name}:</strong></span><span>{formatNumber(item.value)}</span></li>;
            })}
          </ul>
        </ReportsTooltip>
      );
    }
    return null;
  }

  formatLabelList = (value) => {
    return formatNumberAbbreviate(value);
  }

  render() {
    const { isResizing, medicationRequests } = this.props;

    if (isResizing) {
      return (
        <SkeletonChart height={320} />
      );
    }

    return (
      <ResponsiveContainer className="recharts-medication" width="99%" height={320}>
        <BarChart data={medicationRequests} barGap={5} barSize={40} margin={{ top: 16 }}>
          <XAxis tickFormatter={this.formatXAxis} tickLine={false} tickSize={16} axisLine={false} dataKey="date" />
          <YAxis tickFormatter={this.formatYAxis} tickLine={false} tickSize={16} axisLine={false} />
          <CartesianGrid strokeDasharray="0 0" stroke={ColorConstants.COLOR_GREY_LIGHT} vertical={false} />
          <Tooltip content={this.formatTooltip} cursor={false} />
          <Bar isAnimationActive={false} dataKey="requestedMedication" fill={ColorConstants.COLOR_GRAPH_TWO} name={locale.Reports.smartPharmacyMedicationGraphTooltip1}>
            <LabelList dataKey="requestedMedication" position="top" offset={4} formatter={this.formatLabelList} />
          </Bar>
          <Bar isAnimationActive={false} dataKey="itemsRequested" fill={ColorConstants.COLOR_GRAPH_THREE} name={locale.Reports.smartPharmacyMedicationGraphTooltip2}>
            <LabelList dataKey="itemsRequested" position="top" offset={4} formatter={this.formatLabelList} />
          </Bar>
          <Bar isAnimationActive={false} dataKey="itemsPrepared" fill={ColorConstants.COLOR_GRAPH_ONE} name={locale.Reports.smartPharmacyMedicationGraphTooltip3}>
            <LabelList dataKey="itemsPrepared" position="top" offset={4} formatter={this.formatLabelList} />
          </Bar>
          <Bar isAnimationActive={false} dataKey="itemsDispensed" fill={ColorConstants.COLOR_GRAPH_FOUR} name={locale.Reports.smartPharmacyMedicationGraphTooltip4}>
            <LabelList dataKey="itemsDispensed" position="top" offset={4} formatter={this.formatLabelList} />
          </Bar>
          <Bar isAnimationActive={false} dataKey="noOfItemsWithEstimatedCollectionTime" fill={ColorConstants.COLOR_GRAPH_FIVE} name={locale.Reports.smartPharmacyMedicationGraphTooltip5}>
            <LabelList dataKey="noOfItemsWithEstimatedCollectionTime" position="top" offset={4} formatter={this.formatLabelList} />
          </Bar>
          <Bar isAnimationActive={false} dataKey="noOfItemsWithEstimatedDeliveryTime" fill={ColorConstants.COLOR_GRAPH_SIX} name={locale.Reports.smartPharmacyMedicationGraphTooltip6}>
            <LabelList dataKey="noOfItemsWithEstimatedDeliveryTime" position="top" offset={4} formatter={this.formatLabelList} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default connect(mapStateToProps)(ChartMedication);
