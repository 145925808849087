import * as BranchesConstants from "constants/BranchesConstants";
import type {
  Action
} from "types/actions";

type ServicesState = {
  servicesList: any[],
  newServicesList: any[],
  servicesListAsync: any[],
  searchValueAsync: string
};

const initialState: ServicesState = {
  servicesList: [],
  newServicesList: [],
  servicesListAsync: [],
  searchValueAsync: ''
};

export default (localState: ServicesState = initialState, action: Action): ServicesState => {
  switch (action.type) {
    case BranchesConstants.SET_ALL_SERVICES_LIST:
      return {
        ...localState,
        servicesList: action.payload
      };
    case BranchesConstants.ADD_NEW_BRANCH_SERVICE:
      return {
        ...localState,
        newServicesList: [...localState.newServicesList, action.payload]
      };
    case BranchesConstants.DELETE_NEW_BRANCH_SERVICE:
      const deletedNewServiceIndex = localState.newServicesList.findIndex(
        service => service.id === action.payload.id
      );
      return {
        ...localState,
        newServicesList: [
          ...localState.newServicesList.slice(0, deletedNewServiceIndex),
          ...localState.newServicesList.slice(deletedNewServiceIndex + 1)
        ]
      };
    case BranchesConstants.CLEAR_NEW_BRANCH_SERVICE:
      return {
        ...localState,
        newServicesList: []
      };
    case BranchesConstants.SET_SERVICES_LIST_ASYNC:
      const {
        servicesListAsync,
        searchValueAsync
      } = action.payload;
      return {
        ...localState,
        servicesListAsync: servicesListAsync.items,
        searchValueAsync
      };
    default:
      return localState;
  }
};
