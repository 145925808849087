export const EDIT_APPOINTMENT_DETAILS = "EDIT_APPOINTMENT_DETAILS";
export const SET_APPOINTMENT_DETAILS = "SET_APPOINTMENT_DETAILS";
export const CHANGE_APPOINTMENT_STATUS = "CHANGE_APPOINTMENT_STATUS";
export const CHANGE_PAYMENT_STATUS = "CHANGE_PAYMENT_STATUS";
export const CHANGE_AGENDA_CANCELLATION_REASON = "CHANGE_AGENDA_CANCELLATION_REASON";
export const SET_APPOINTMENT_CHANGES = "SET_APPOINTMENT_CHANGES";
export const SET_APPOINTMENT_REFUND_DETAILS = "SET_APPOINTMENT_REFUND_DETAILS";
export const REFUND_CUSTOMER_PENDING = "REFUND_CUSTOMER_PENDING";
export const REFUND_CUSTOMER_SUCCESS = "REFUND_CUSTOMER_SUCCESS";
export const REFUND_CUSTOMER_ERROR = "REFUND_CUSTOMER_ERROR";
export const GET_REFUND_AMOUNT_PENDING = "GET_REFUND_AMOUNT_PENDING";
export const GET_REFUND_AMOUNT_SUCCESS = "GET_REFUND_AMOUNT_SUCCESS";
export const GET_REFUND_AMOUNT_ERROR = "GET_REFUND_AMOUNT_ERROR";
export const SET_INTERNAL_EVENT = "SET_INTERNAL_EVENT";
export const DELETE_INTERNAL_EVENT_PENDING = "DELETE_INTERNAL_EVENT_PENDING";
export const DELETE_INTERNAL_EVENT_SUCCESS = "DELETE_INTERNAL_EVENT_SUCCESS";
export const DELETE_INTERNAL_EVENT_ERROR = "DELETE_INTERNAL_EVENT_ERROR";

export const APPOINTMENT_DETAILS_TYPES = {
  AGENDA: "agenda",
  SEARCH: "search"
};

export const APPOINTMENT_STATUS_TYPES = {
  PROVIDED: "provided",
  NOT_PROVIDED: "not provided"
};

export const CANCELLATION_TYPE_CLINICIAN = "Clinician";
export const CANCELLATION_TYPE_CUSTOMER = "Customer";
export const cancellationTypeOptions = [
  { value: CANCELLATION_TYPE_CLINICIAN, label: CANCELLATION_TYPE_CLINICIAN },
  { value: CANCELLATION_TYPE_CUSTOMER, label: CANCELLATION_TYPE_CUSTOMER }
];

export const PAYMENT_METHOD = [
  { id: 0, value: false, enumValue: "none" },
  { id: 1, value: true, enumValue: "online" },
  { id: 2, value: false, enumValue: "payOnSpot" },
];

export const VIDEO_WEB_TEMPLATE_FILENAME = "index.html"

export const DEFAULT_APPOINTMENTID = "00000000-0000-0000-0000-000000000000";

export type AppointmentStatusType = $Values<typeof APPOINTMENT_STATUS_TYPES>;

export type AppointmentDetailsType = $Values<typeof APPOINTMENT_DETAILS_TYPES>;
