import React, { Component, Fragment } from 'react';
import { Button } from '@patient-access/ui-kit';
import { SkeletonSelect, SkeletonDatePicker } from 'components/Share/skeleton';
import './styles/reports-content-filters.scss';

class ReportsFilterSkeleton extends Component {
  render() {
    return (
      <Fragment>
        <div className="reports-content-filters">
          <div className="reports-content-filters-select">
            <SkeletonSelect />
            <SkeletonSelect />
          </div>
          <div className="reports-content-filters-date">
            <SkeletonDatePicker />
            <p>to</p>
            <SkeletonDatePicker />
          </div>
          <div className="reports-content-filters-buttons">
            <Button buttonType="secondary" messageKey="some-key" defaultMessage="Download as CSV" onClick={(e) => { e.preventDefault(); }} isLoading={true} />
          </div>
        </div>
      </Fragment>
    );
  }
};

export default ReportsFilterSkeleton;
