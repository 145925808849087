import type { Action } from 'types/actions';

export const updateForm = (data: any): Action => ({
  type: "FORM_UPDATE",
  payload: data
});

export const clearForm = (): Action => ({
  type: "FORM_CLEAR"
});

