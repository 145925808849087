import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IconChevronDown, IconChevronUp } from '@patient-access/ui-kit';
import './styles/button-toggle.scss';

type Props = {
  isExpanded: boolean,
  onClick: any,
};

class ButtonToggle extends Component<Props> {
  render() {
    const { isExpanded, onClick } = this.props;
    return (
      <Link className="button-toggle" to="#toggle" onClick={onClick}>
        {isExpanded ? <IconChevronUp outline /> : <IconChevronDown outline />}
      </Link>
    );
  }
};

export default ButtonToggle;
