import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, SearchFilter, IconSearch } from '@patient-access/ui-kit';
import { setUserSearch, setUI, setUserData, resetUserEditData, setUserEditData } from 'actions/organisationServices';
import BreadcrumbsServices from '../BreadcrumbsServices/BreadcrumbsServices';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  serviceId: string,
  searchValue: string,
  branchesList: any,
  setUserSearch: (value: string) => any,
  setUI: (value: any) => any,
  addBranches: () => any,
  editAllBranches: () => any,
  setUserData: (data: any) => any,
  setUserEditData: (data: any) => any,
  resetUserEditData: () => any,
};

const mapStateToProps = state => ({
  searchValue: state.organisationServices.user.search.branchesSummary,
  dataBranchesSummary: state.organisationServices.branchesSummary.data,
  branchesList: state.organisationServices.branchesList.data,
  userData: state.organisationServices.user.data,
  userEditData: state.organisationServices.user.editData,
  dataServicesSummary: state.organisationServices.servicesSummary.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserSearch: (value: string) => dispatch(setUserSearch(value)),
  setUI: (value: any) => dispatch(setUI(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  resetUserEditData: () => dispatch(resetUserEditData()),
  setUserEditData: (data: any) => dispatch(setUserEditData(data)),
});

class FilterBranchesSummary extends Component<Props> {
  componentWillUnmount = () => {
    const { setUserSearch } = this.props;
    setUserSearch({ branchesSummary: '' });
  }

  render() {
    const { organisationId, serviceId, searchValue, dataBranchesSummary, branchesList, userData, dataServicesSummary, addBranches, editAllBranches, setUserSearch, setUI, setUserData, setUserEditData } = this.props;
    const isEnableAddtoBranches = branchesList.filter(service => service.isEnabled === false).length > 0 ? true : false;
    const serviceData = dataServicesSummary.find(d => d.serviceId === serviceId);
    const isServiceEnabled = serviceData ? serviceData.isEnabled : true;

    const handleAddtoBranches = () => {
      addBranches();
      setUI({ isSidebarAddVisible: true });
    }

    const setEditBranchesData = () => {
      editAllBranches();
      setUI({ isSidebarEditVisible: true });

      if (userData.serviceBranches.length <= 0) {
        const branchIds = dataBranchesSummary.map((d) => d.branchId);
        setUserData({ serviceBranches: branchIds });
      }
      setUserEditData({ selectedSummaryBranches: userData.serviceBranches });
    }

    return (
      <Fragment>
        <div className="row">
          <BreadcrumbsServices organisationId={organisationId} serviceId={serviceId} />
        </div>
        <div className="row">
          <div className="column column-full">
            <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
              <SearchFilter
                autoComplete="off"
                id="searchServicesSummary"
                name="searchServicesSummary"
                placeholder={locale.OrganisationServices.filter.branchesSummary.placeholder}
                clearHandler={() => { setUserSearch({ branchesSummary: '' }); }}
                onChange={(e) => { setUserSearch({ branchesSummary: e.target.value }); }}
                icon={<IconSearch outline />}
                value={searchValue}
              />
            </Form>
          </div>
          <div className="column">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.addToBranches}
              onClick={() => { handleAddtoBranches() }}
              isDisabled={!isEnableAddtoBranches || !isServiceEnabled}
            />
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.editService}
              onClick={() => { setEditBranchesData() }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBranchesSummary);
