import React, { Component, Fragment } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { Form, FormItem, Switch, Skeleton, Radio } from '@patient-access/ui-kit';
import {
  Feature,
  FeatureMenu,
  FeatureContent,
  FeatureContentSticky,
  FeatureContentScroll,
  FeatureContentHeader,
  FeatureContentBreadcrumbs,
  FeatureContentWrapper,
  FeatureContentBlock,
} from 'components/Share/feature';
import { getOrganisationDetails } from 'actions/organisationDetails';
import {
  updateOrganisationSupportedFeatures,
  resetOrganisationSupportedFeatures,
  setSmartPharmacyOverlayVisibility,
  updateOrganisationIsEnabledForAllBranches
} from 'actions/organisationSupportedFeatures';
import OrganisationSidebar from '../OrganisationSidebar/OrganisationSidebar';
import FloatingFooterButtons from 'components/Share/FloatingFooterButtons/FloatingFooterButtons';
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';
import SmartPharmacyOverlay from './SmartPharmacyOverlay';
import locale from 'service/locale';

type State = {
  isEditMode: boolean,
  isOverlayVisible: boolean,
};

type Props = {
  organisationId: string,
  data: any,
  dataClone: any,
  isPutLoading: boolean,
  IsEnabledForAllBranches: boolean,
  getOrganisatonDetailsPending: boolean,
  getOrganisationDetails: (organisationId: string) => Action,
  updateOrganisationSupportedFeatures: (featureName: string, data: any) => Action,
  resetOrganisationSupportedFeatures: () => Action,
  setSmartPharmacyOverlayVisibility: (isOverlayVisible: boolean) => Action,
  updateOrganisationIsEnabledForAllBranches: (IsEnabledForAllBranches: boolean) => Action,
};

const mapStateToProps = state => ({
  data: state.organisationSupportedFeatures.data,
  dataClone: state.organisationSupportedFeatures.dataClone,
  IsEnabledForAllBranches: state.organisationSupportedFeatures.IsEnabledForAllBranches,
  organisationDetails: state.organisationDetails,
  getOrganisatonDetailsPending: state.organisationDetails.getOrganisatonDetailsPending,
  isPutLoading: state.organisationSupportedFeatures.isPutLoading,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  getOrganisationDetails: (organisationId: string) => dispatch(getOrganisationDetails(organisationId)),
  updateOrganisationSupportedFeatures: (featureName, data) => dispatch(updateOrganisationSupportedFeatures(featureName, data)),
  resetOrganisationSupportedFeatures: () => dispatch(resetOrganisationSupportedFeatures()),
  setSmartPharmacyOverlayVisibility: (isOverlayVisible: boolean) => dispatch(setSmartPharmacyOverlayVisibility(isOverlayVisible)),
  updateOrganisationIsEnabledForAllBranches: (IsEnabledForAllBranches: boolean) => dispatch(updateOrganisationIsEnabledForAllBranches(IsEnabledForAllBranches)),
});

class SmartPharmacy extends Component<Props, State> {
  state = {
    isEditMode: false,
    isOverlayVisible: false,
  }

  componentDidMount = () => {
    const { getOrganisationDetails, organisationId } = this.props;
    getOrganisationDetails(organisationId);
  }

  handleChangeMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  }

  render() {
    const { isEditMode, isOverlayVisible } = this.state;
    const {
      organisationId,
      data,
      dataClone,
      isPutLoading,
      getOrganisatonDetailsPending,
      IsEnabledForAllBranches,
      organisationDetails,
      updateOrganisationSupportedFeatures,
      resetOrganisationSupportedFeatures,
      setSmartPharmacyOverlayVisibility,
      updateOrganisationIsEnabledForAllBranches
    } = this.props;

    const branchCount = (organisationDetails.branches && organisationDetails.branches.length) || 0;
    const spBranchCount = (organisationDetails.smartPharmacyBranchCount) || 0;
    const areAllBranchesDisabled = (branchCount > 0) && (spBranchCount === 0);
    const areAllBranchesEnabled = (branchCount > 0) && (spBranchCount === branchCount);
    const initialIsEnabledForAllBranches = areAllBranchesDisabled ? false : areAllBranchesEnabled ? true : undefined;

    const isDataChanged = !isEqual(data, dataClone) || (IsEnabledForAllBranches !== undefined && IsEnabledForAllBranches !== initialIsEnabledForAllBranches);
    const isDataValid = true; // no validation
    const featureSmartPharmacy = data.find(feature => feature.name === 'SmartPharmacy');
    const featureSmartPharmacyClone = dataClone.find(feature => feature.name === 'SmartPharmacy');
    const featureBillingModel = data.find(feature => feature.name === 'SubscriptionModel');

    const renderLastModifiedDate = () => {
      if (featureSmartPharmacyClone.lastModifiedDate === null) return locale.SmartPharmacy.neverEnabled;
      return (
        <Fragment>
          {featureSmartPharmacyClone.isEnabled ? locale.SmartPharmacy.lastEnabled : locale.SmartPharmacy.lastDisabled}&nbsp;
          {moment(featureSmartPharmacyClone.lastModifiedDate).format("DD/MM/YYYY")}
        </Fragment>
      );
    }

    const renderFloatingFooterButtons = () => {
      if (isDataChanged) {
        return (
          <FloatingFooterButtons
            handleDiscardChanges={() => { this.setState({ isOverlayVisible: true }); }}
            proceedFunction={() => { setSmartPharmacyOverlayVisibility(true); }}
            isEditMode={!isDataValid}
            isLoading={isPutLoading}
          />
        );
      }
      return null;
    }

    const renderDiscardChangesOverlay = () => {
      if (isOverlayVisible) {
        return (
          <DiscardChangesOverlay
            handleDiscardChanges={() => { resetOrganisationSupportedFeatures(); this.setState({ isEditMode: false, isOverlayVisible: false }); }}
            handleStay={() => { this.setState({ isOverlayVisible: false }); }}
          />
        );
      }
      return null;
    }

    const renderRadioButtons = () => { 
      if (!(featureSmartPharmacyClone && featureSmartPharmacyClone.isEnabled)) {
        return null;
      }

      if (featureSmartPharmacy && featureSmartPharmacy.isEnabled === false) {
        return null;
      }

      return (
        <div key={`radio-seciton-${IsEnabledForAllBranches}`} style={{ paddingTop: '20px' }}>
          <Form noValidate>
            <FormItem type="item">
              <Radio
                onClick={(e) => updateOrganisationIsEnabledForAllBranches(false)}
                id="disable-all"
                name="disable-all"
                value="disable-all"
                label={locale.SmartPharmacy.radio.disabled}
                className=""
                disabled={!isEditMode}
                defaultChecked={IsEnabledForAllBranches !== undefined ? !IsEnabledForAllBranches : areAllBranchesDisabled}
              />
              <Radio
                onClick={(e) => updateOrganisationIsEnabledForAllBranches(true)}
                id="enable-all"
                name="disable-all"
                value="enable-all"
                label={locale.SmartPharmacy.radio.enabled}
                className=""
                disabled={!isEditMode}
                defaultChecked={IsEnabledForAllBranches !== undefined ? IsEnabledForAllBranches : areAllBranchesEnabled}
              />
            </FormItem>
          </Form>
        </div>
      );
    }

    const renderBillingModelRadioButtons = () => {
      if (!(featureSmartPharmacyClone && featureSmartPharmacyClone.isEnabled)) {
        return null;
      }

      if (featureSmartPharmacy && featureSmartPharmacy.isEnabled === false) {
        return null;
      }

      return (
        <div className="patient-care-feature-content-block-billing-radio" key={`radio-seciton-billing-model`}>
          <h3>{locale.SmartPharmacy.billingModel.title}</h3>
          <Form className="patient-care-feature-content-block-form" noValidate>
            <FormItem type="item">
              <Radio
                onClick={(e) => updateOrganisationSupportedFeatures('SubscriptionModel', { name: 'SubscriptionModel', isEnabled: true })}
                id="subscription-model"
                name="billing-model"
                value="subscription-model"
                label={locale.SmartPharmacy.billingModel.subscriptionRadio}
                disabled={!isEditMode}
                defaultChecked={(featureBillingModel && featureBillingModel.isEnabled)}
              />
              <p className="small small-bottom-padding">{locale.SmartPharmacy.billingModel.subscriptionRadioDesc}</p>
              <Radio
                onClick={(e) => updateOrganisationSupportedFeatures('SubscriptionModel', { name: 'SubscriptionModel', isEnabled: false })}
                id="transaction-model"
                name="billing-model"
                value="transaction-model"   
                label={locale.SmartPharmacy.billingModel.transactionRadio}
                disabled={!isEditMode}
                defaultChecked={!(featureBillingModel && featureBillingModel.isEnabled)}
              />
              <p className="small">{locale.SmartPharmacy.billingModel.transactionRadioDesc}</p>
            </FormItem>
          </Form>
        </div>
      );
    }

    if (getOrganisatonDetailsPending) {
      return (
        <Feature>
          <FeatureMenu>
            <OrganisationSidebar organisationId={organisationId} />
          </FeatureMenu>
          <FeatureContent>
            <FeatureContentSticky>
              <FeatureContentHeader>
                <FeatureContentBreadcrumbs organisationId={organisationId} />
              </FeatureContentHeader>
            </FeatureContentSticky>
            <FeatureContentScroll isGreyBackground={true}>
              <FeatureContentWrapper type="medium">
                <Skeleton type="basic" className="transparent" />
              </FeatureContentWrapper>
            </FeatureContentScroll>
          </FeatureContent>
        </Feature>
      );
    }

    if (!(data && data.length > 0 && dataClone && dataClone.length > 0)) {
      return null;
    }

    return (
      <Fragment>
        <Feature>
          <FeatureMenu>
            <OrganisationSidebar organisationId={organisationId} />
          </FeatureMenu>
          <FeatureContent>
            <FeatureContentSticky>
              <FeatureContentHeader>
                <FeatureContentBreadcrumbs organisationId={organisationId} />
              </FeatureContentHeader>
            </FeatureContentSticky>
            <FeatureContentScroll isDataChanged={isDataChanged} isGreyBackground={true}>
              <FeatureContentWrapper type="medium">
                <FeatureContentBlock>
                  <div className="patient-care-feature-content-block-header">
                    <h3>&nbsp;</h3>
                    <span className="patient-care-feature-content-block-edit" onClick={this.handleChangeMode}>
                      {!isEditMode ? locale.Buttons.buttonEdit : locale.Buttons.buttonDone}
                    </span>
                  </div>
                  <div className="patient-care-feature-content-block-card">
                    <div className="label">
                      <h3>{locale.SmartPharmacy.title}</h3>
                      <p>{locale.SmartPharmacy.body}</p>
                      <p className="small">{renderLastModifiedDate()}</p>
                      {renderRadioButtons()} 
                      {renderBillingModelRadioButtons()}
                    </div>
                    <div className="option">
                      <Form noValidate>
                        <FormItem type="item">
                          <Switch
                            id="SmartPharmacy"
                            name="SmartPharmacy"
                            value="SmartPharmacy"
                            accessibilitySpan="some_label"
                            onChange={(e) => {
                              updateOrganisationSupportedFeatures('SmartPharmacy', { isEnabled: e.target.checked });
                              updateOrganisationIsEnabledForAllBranches(undefined)
                            }}
                            disabled={!isEditMode ? 'disabled' : null}
                            checked={featureSmartPharmacy.isEnabled}
                          />
                        </FormItem>
                      </Form>
                    </div>
                  </div>
                </FeatureContentBlock>
              </FeatureContentWrapper>
            </FeatureContentScroll>
          </FeatureContent>
          {renderFloatingFooterButtons()}
          {renderDiscardChangesOverlay()}
          <SmartPharmacyOverlay
            organisationId={organisationId}
            handleChangeMode={this.handleChangeMode}
            branchCount={branchCount}
            spBranchCount={spBranchCount}
          />
        </Feature>
        <NavigationPrompt when={isDataChanged} afterConfirm={() => { resetOrganisationSupportedFeatures(); }}>
          {({ onConfirm, onCancel }) => (
            <DiscardChangesOverlay
              handleDiscardChanges={onConfirm}
              handleStay={onCancel}
            />
          )}
        </NavigationPrompt>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartPharmacy);
