import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import { showSnackbarStatus } from 'actions/snackbar';
import { getToken } from 'helpers/common';
import agent from 'service/agent';
import locale from 'service/locale';
import { getOrganisationDetails } from 'actions/organisationDetails';

// SET
export const setOrganisationSupportedFeaturesData = (data: any): Action => ({
  type: OrganisationsConstants.SET_ORGANISATION_SUPPORTED_FEATURES_DATA,
  payload: data
});

export const setOrganisationSupportedFeaturesDataClone = (data: any): Action => ({
  type: OrganisationsConstants.SET_ORGANISATION_SUPPORTED_FEATURES_DATA_CLONE,
  payload: data
});

// UPDATE
export const updateOrganisationSupportedFeatures = (featureName: string, data: any): Action => ({
  type: OrganisationsConstants.UPDATE_ORGANISATION_SUPPORTED_FEATURES,
  payload: { featureName, data }
});

// UPDATE
export const updateOrganisationIsEnabledForAllBranches = (IsEnabledForAllBranches: boolean): Action => ({
  type: OrganisationsConstants.UPDATE_ORGANISATION_SUPPORTED_FEATURES_DISABLE_ALL_BRANCHES,
  payload: IsEnabledForAllBranches,
});

// RESET
export const resetOrganisationSupportedFeatures = (): Action => ({
  type: OrganisationsConstants.RESET_ORGANISATION_SUPPORTED_FEATURES,
});

// OVERLAY
export const setSmartPharmacyOverlayVisibility = (isOverlayVisible: boolean): Action => ({
  type: OrganisationsConstants.SET_SMART_PHARMACY_OVERLAY_VISIBILITY,
  payload: isOverlayVisible,
});

// PUT
export const setPutOrganisationSupportedFeaturesPending = (): Action => ({
  type: OrganisationsConstants.PUT_ORGANISATION_SUPPORTED_FEATURES_PENDING,
});

export const setPutOrganisationSupportedFeaturesSuccess = (): Action => ({
  type: OrganisationsConstants.PUT_ORGANISATION_SUPPORTED_FEATURES_SUCCESS,
});

export const setPutOrganisationSupportedFeaturesError = (): Action => ({
  type: OrganisationsConstants.PUT_ORGANISATION_SUPPORTED_FEATURES_ERROR,
});

export const putOrganisationSupportedFeatures = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const supportedFeatures = currentState.organisationSupportedFeatures.data;
  const IsEnabledForAllBranches = !!(currentState.organisationSupportedFeatures.IsEnabledForAllBranches);
  dispatch(setPutOrganisationSupportedFeaturesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.putSupportedFeatures(organisationId, supportedFeatures, IsEnabledForAllBranches, accessToken)
        .then(supportedFeatures => {
          dispatch(setPutOrganisationSupportedFeaturesSuccess());
          dispatch(getOrganisationDetails(organisationId));
          dispatch(showSnackbarStatus(locale.Snackbar.organisationSupportedFeaturesUpdated));
        })
        .catch(err => {
          console.log('putOrganisationSupportedFeatures server error', err);
          dispatch(setPutOrganisationSupportedFeaturesError());
          dispatch(showSnackbarStatus(locale.Snackbar.organisationSupportedFeaturesError));
        });
    });
};
