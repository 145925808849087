import * as BranchesListConstants from 'constants/BranchesListConstants';
import { getToken } from 'helpers/common';
import agent from 'service/agent';

// SEARCH_VALUE
export const setSearchValue = (value: string): Action => ({
  type: BranchesListConstants.SET_BRANCHES_LIST_SEARCH_VALUE,
  payload: value,
});

// BRANCHES_LIST
export const setBranchesListPending = (): Action => ({
  type: BranchesListConstants.GET_BRANCHES_LIST_PENDING,
});

export const setBranchesListSuccess = (): Action => ({
  type: BranchesListConstants.GET_BRANCHES_LIST_SUCCESS,
});

export const setBranchesListError = (): Action => ({
  type: BranchesListConstants.GET_BRANCHES_LIST_ERROR,
});

export const setBranchesListData = (data: any): Action => ({
  type: BranchesListConstants.SET_BRANCHES_LIST_DATA,
  payload: data,
});

export const getBranchesList = (pageNumber: number, searchValue: string) => (dispatch: Function) => {
  dispatch(setBranchesListPending());
  getToken(dispatch)
    .then(accessToken => {
      const itemsPerPage = 30;
      agent.Branches.getFilteredBranches(pageNumber, searchValue, itemsPerPage, accessToken)
        .then(branchesList => {
          dispatch(setBranchesListData(branchesList));
          dispatch(setBranchesListSuccess());
        })
        .catch(err => {
          dispatch(setBranchesListError());
          console.log("getBranchesList server error or branch was not found", err);
        });
    });
};

export const getOrganisationBranchesList = (pageNumber: number, searchValue: string, organisationId: string) => (dispatch: Function) => {
  dispatch(setBranchesListPending());
  getToken(dispatch)
    .then(accessToken => {
      const itemsPerPage = 30;
      agent.Branches.getFilteredBranches(pageNumber, searchValue, itemsPerPage, accessToken, organisationId)
        .then(branchesList => {
          dispatch(setBranchesListData(branchesList));
          dispatch(setBranchesListSuccess());
        })
        .catch(err => {
          dispatch(setBranchesListError());
          console.log("getBranchesList server error or branch was not found", err);
        });
    });
};
