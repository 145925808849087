import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { OverlayDescription } from 'components/Share/overlay';
import locale from 'service/locale';
import { setUI } from 'actions/organisationServices';

type Props = {
  isOverlayVisible: boolean,
  responseFromApi: any,
  setUI: (value: any) => any,
};

const mapStateToProps = state => ({
  isOverlayVisible: state.organisationServices.ui.isOverlayBranchesVisible,
  responseFromApi: state.organisationServices.ui.responseFromApi,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUI: (value: any) => dispatch(setUI(value)),
});

class OverlayBranches extends Component<Props> {
  render() {
    const { isOverlayVisible, setUI, responseFromApi } = this.props;

    const renderContent = () => {
      const affectedBranches = responseFromApi.filter(branch => branch.isSuccess === false);
      return (
        <Fragment>
          {<div className="overlay-description-content-heading"><h1>{locale.OrganisationServices.overlay.confirmation.branchNotUpdated}</h1></div>}
          {<div className="overlay-description-content-body">{affectedBranches.map((branch) =>
            <li>{branch.branchName}</li>
          )}</div>}
        </Fragment>
      );
    }

    if (isOverlayVisible) {
      return (
        <OverlayDescription isOpen={true} doClose={() => { setUI({ isOverlayBranchesVisible: false }); }}>
          <div className="overlay-description-header" />
          <div className="overlay-description-content">
            {renderContent()}
          </div>
          <div className="overlay-description-footer">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.goBack}
              onClick={() => { setUI({ isOverlayBranchesVisible: false }); }}
            />
          </div>
        </OverlayDescription>
      );
    };
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayBranches);
