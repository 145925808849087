import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Overlay, Button } from '@patient-access/ui-kit';
import { setReportsDownloadOverlay } from 'actions/reportsSmartPharmacy';
import locale from 'service/locale';
import './styles.scss';
import { ai } from "service/telemetry";
import * as RolesConstants from 'constants/RolesConstants';


type Props = {
  data: any,
  selectedOrganisation: any,
  selectedBranch: any,
  selectedStartDate: any,
  selectedEndDate: any,
  isOverlayVisible: boolean,
  isLoading: boolean,
  isError: boolean,
  currentRole: any,
  setReportsDownloadOverlay: (isOverlayVisible: boolean) => Action,

    // Telemetry
    aiUserId : string,
    aiSessionId: string,
    aiRoleName: string,

    isSubscriptionModel: boolean
};

const mapStateToProps = (state) => ({
  data: state.reportsSmartPharmacy.download.data,
  selectedOrganisation: state.reportsSmartPharmacy.search.selectedOrganisation,
  selectedBranch: state.reportsSmartPharmacy.search.selectedBranch,
  selectedStartDate: state.reportsSmartPharmacy.filters.startDate,
  selectedEndDate: state.reportsSmartPharmacy.filters.endDate,
  isOverlayVisible: state.reportsSmartPharmacy.download.isOverlayVisible,
  isLoading: state.reportsSmartPharmacy.download.isLoading,
  isError: state.reportsSmartPharmacy.download.isError,
  lastUpdatedDateTime: state.reportsSmartPharmacy.metrics.summary.data.lastUpdatedDateTime,
  currentRole: state.roles.profileCurrentRole,

  // Telemetry
  aiUserId: state.profile.id,
  aiSessionId: state.profile.sessionId,
  aiRoleName: state.roles.profileCurrentRole.role,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setReportsDownloadOverlay: (isOverlayVisible) => dispatch(setReportsDownloadOverlay(isOverlayVisible)),
});

class DownloadOverlay extends Component<Props, State> {

  handlerTelemetry = () => {
    const { aiUserId, aiRoleName, aiSessionId, selectedOrganisation, selectedBranch } = this.props;

    // Telemetry
    ai.appInsights.trackEvent({
      name: 'PAProSpDownloadReport',
    }, {
      UserId: aiUserId,
      SessionId: aiSessionId,
      RoleName: aiRoleName,
      OrganizationId: selectedOrganisation.id || locale.Telemetry.naOrganisationId,
      BranchId: selectedBranch.id || locale.Telemetry.naBranchId,
    });
  }

  render() {
    const { data, selectedOrganisation, selectedBranch, selectedStartDate, selectedEndDate, isOverlayVisible, isLoading, isError, setReportsDownloadOverlay, currentRole, isSubscriptionModel } = this.props;
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const isWin = navigator.platform.toUpperCase().indexOf('WIN') >= 0;
    const isFirefox = navigator.userAgent.toUpperCase().indexOf('FIREFOX') >= 0;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const headers = [
      { label: "Organisation", key: "Organisation" },
      { label: "Branch", key: "Branch" },
      { label: "Date", key: "Date" },
      { label: "Latest SmartPharmacyEnabled Time", key: "Latest SmartPharmacyEnabled Time" },
      { label: "Sum of Nominations", key: "Sum of Nominations" },
      { label: "Sum of Requests", key: "Sum of Requests" },
      { label: "Sum of RequestedItems", key: "Sum of RequestedItems" },
      { label: "Sum of DispensedRequests", key: "Sum of DispensedRequests" },
      { label: "Sum of DispensedItems", key: "Sum of DispensedItems" }
    ];

    if(!isSubscriptionModel){
      headers.push({ label: "Sum of TotalCost", key: "Sum of TotalCost" });
    }

    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const scvFilename = () => {
      var fileName = `SmartPharmacy_Reports_${moment(this.props.lastUpdatedDateTime).format('DD/MM/YY hh:mm')}`;
      if (isMac) {
        if (isFirefox || isSafari) return `${fileName}.csv`;
      }
      if (isWin) return `${fileName}.csv`;
      return `${fileName}`;
    }

    return (
      <Overlay
        background="dark"
        type="confirmation"
        isOpen={isOverlayVisible}
        showCloseButton
        doClose={() => { setReportsDownloadOverlay(false); }}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.Reports.downloadOverlay.smartPharmacyHeading}</h1>
        </div>
        <div className="overlay-confirmation-content reports-basic-download">
          <h4>{locale.Reports.downloadOverlay.smartPharmacyBody}</h4>
          {isSuperAdmin ?
            <p>
              <strong>
                {locale.Reports.downloadOverlay.organisationLabel}
              </strong>
              {(selectedOrganisation && selectedOrganisation.companyName) ?
                selectedOrganisation.companyName : locale.Reports.downloadOverlay.organisationAll}
            </p> : null}
          <p><strong>{locale.Reports.downloadOverlay.branchLabel}</strong> {(selectedBranch && selectedBranch.name) ? selectedBranch.name : locale.Reports.downloadOverlay.branchAll}</p>
          <p><strong>{locale.Reports.downloadOverlay.dateLabel}</strong> {moment(selectedStartDate).format('DD/MM/YYYY')} - {moment(selectedEndDate).format('DD/MM/YYYY')}</p>
        </div>
        <div className="overlay-confirmation-buttons reports-basic-download">
          <Button
            to="#back"
            buttonType="blueline"
            messageKey="some-key"
            defaultMessage={locale.Reports.buttonBack}
            onClick={(e) => { e.preventDefault(); setReportsDownloadOverlay(false); }}
          />
          <CSVLink className={`ui-kit-button ui-kit-button-secondary ui-kit-button-medium ${(isLoading || isError) ? 'ui-kit-button-loading' : ''}`} data={data} headers={headers} filename={scvFilename()} onClick={() => { this.handlerTelemetry(); }}>
            {(isLoading || isError) ? <em><span className="ui-kit-accessibility-span">{locale.Reports.loading}</span></em> : null}
            <span>{locale.Reports.buttonDownload}</span>
          </CSVLink>
        </div>
      </Overlay>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadOverlay);
