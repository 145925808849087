import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";

import type { Organisation } from 'types/organisation';
import { modifyBranchesList, getBranchOption } from "helpers/common";

import locale from 'service/locale';

type Props = {
  organisationDetails: Organisation,
  branchId: string
};

const mapStateToProps = state => ({
  organisationDetails: state.organisationDetails
});

const mapDispatchToProps = (dispatch: any) => ({
});


class CalendarDetailsBranchName extends Component<Props, State> {

  render() {
    const { organisationDetails, branchId } = this.props;
    const branchesList = organisationDetails ? modifyBranchesList({ items: organisationDetails.branches || [] }) : [];
    const selectedBranch = branchId && getBranchOption(organisationDetails, branchId);

    return (
      <Fragment>
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="branch_select"
                  message={locale.AddNewCalendar.branchTitle}
                  size="small"
                />
              </FormItem>
            </Form>
            <Select
              id="branch-select"
              name="branch-select"
              defaultValue={selectedBranch}
              options={branchesList.filter(branch => branch.value)}
              placeholder={locale.AddNewCalendar.placeholderBranch}
              isDisabled={true}
              classNamePrefix="patient-care"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetailsBranchName);
