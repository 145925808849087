import { connect } from "react-redux";

import { loadUser } from "actions/profile";
import AADChangePasswordCallback from "./AADChangePasswordCallback";

const mapStateToProps = (state) => ({
  login: state.login,
  isAuth: state.login.isAuth,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (access_token) => dispatch(loadUser(access_token)),
});

const AADChangePasswordCallbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AADChangePasswordCallback);

export default AADChangePasswordCallbackContainer;