import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
  Form,
  FormItem,
  Input,
  InputError
} from "@patient-access/ui-kit";
import { updateForm } from "actions/form";
import locale from 'service/locale';
import {
  checkURL
} from "helpers/checkValues";

type Props = {
  form: any,
}

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: data => dispatch(updateForm(data))
});

class AddOrganisationBookingUrl extends Component<Props, State> {
  handleFormChange = (e: any) => {
    const { updateForm, form } = this.props;
    const {
      target: { name, value }
    } = e;
    updateForm({
      booking: {
        ...form.booking,
        point: {
          ...form.point,
          [name]: value,
        }
      }
    });
  };

  render() {
    const {
      form
    } = this.props;

    const isEditedBookingUrl = form.booking && form.booking.point && Object.keys(form.booking.point).includes('url');
    const isUrlCorrect = isEditedBookingUrl ? (form.booking && form.booking.point && form.booking.point.url.length === 0) || checkURL(form.booking.point.url) : true;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddOrganisation.bookingUrl.title}</h3>
          <p>{locale.AddOrganisation.bookingUrl.hint}</p>
        </div>
        <div className="patient-care-block">
          <Form>
            <FormItem
              type="item"
              error={!isUrlCorrect ? "error" : null}>
              <Input
                type="text"
                id="url"
                name="url"
                data-id="url"
                placeholder={locale.AddOrganisation.bookingUrl.placeHolder}
                onChange={this.handleFormChange}
              />
              {!isUrlCorrect && <InputError message={locale.OrganisationsSettings.bookingUrl.invalidError} />}
            </FormItem>
          </Form>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisationBookingUrl);