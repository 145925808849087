import * as AppointmentsConstants from "constants/AppointmentsConstants";
import type { AppointmentsAction } from "types/actions";
import type { AppointmentsByDates } from 'types/appointments';

type AppointmentsState = AppointmentsByDates[];

const initialState = [];

export default (localState: AppointmentsState = initialState, action: AppointmentsAction): AppointmentsState => {
  switch (action.type) {
    case AppointmentsConstants.SET_APPOINTMENTS:
      return action.payload;
    default:
      return localState;
  }
};
