import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { LinkExternal } from '@patient-access/ui-kit';
import './styles/card-reports-fees.scss';
import locale from 'service/locale';

const mapStateToProps = (state) => ({
  summary: state.reportsSmartPharmacy.metrics.summary.data,
});
class CardFees extends Component<Props> {
  render() {
    const { summary } = this.props;
    const { isSubscriptionModel} = summary;

    if (!isSubscriptionModel) {
      return (
        <div className="card-reports-fees">
          {/*
        <div className="card-reports-fees-info">
          <LinkExternal to="#fees" message={locale.Reports.smartPharmacyAboutFees} />
        </div>
        */}
          <div className="card-reports-fees-total">
            <h3>{locale.Reports.smartPharmacyFeePeriod}</h3>
            <p>{locale.Reports.smartPharmacyFeeCurrency}{summary.totalFees}</p>
          </div>
          <div className="card-reports-fees-item">
            <h3>{locale.Reports.smartPharmacyFeePerItem}</h3>
            <p>{locale.Reports.smartPharmacyFeeCurrency}{summary.unitPrice}</p>
          </div>
        </div>
      );
    }
    return "";
  }
};

export default connect(mapStateToProps)(CardFees);
