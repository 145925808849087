import locale from 'service/locale';

export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";
export const SET_CURRENT_PROFILE_URL = "SET_CURRENT_PROFILE_URL";
export const SET_CONFIRMATION_STATUS = "SET_CONFIRMATION_STATUS";
export const SET_PASSWORD_MISMATCH = "SET_PASSWORD_MISMATCH";
export const RESET_PASSWORD_MISMATCH = "RESET_PASSWORD_MISMATCH";
export const SET_ACTIVE_ADMIN_MENU = "SET_ACTIVE_ADMIN_MENU";
export const SET_ACTIVE_REPORTS_MENU = "SET_ACTIVE_REPORTS_MENU";
export const SET_CURRENT_PROFILE_ORGANISATION = "SET_CURRENT_PROFILE_ORGANISATION";
export const SET_PASSWORD_LINK_EXPIRED = "SET_PASSWORD_LINK_EXPIRED";

export const USER_FILTERS =  {
  all: { title: locale.UsersListRedesign.usersListFilters[0], value: 0, filter: "" },
  admins: { title: locale.UsersListRedesign.usersListFilters[1], value: 1, filter: "superAdmin" },
  orgAdmins: { title: locale.UsersListRedesign.usersListFilters[2], value: 2, filter: "OrganizationAdmin" },
  branchAdmins: { title: locale.UsersListRedesign.usersListFilters[3], value: 3, filter: "BranchAdmin" },
  branchMembers: { title: locale.UsersListRedesign.usersListFilters[4], value: 4, filter: "BranchMember" },
};
