import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Button } from '@patient-access/ui-kit';
import find from 'lodash.find';

import { logoutUser } from 'actions/login';
import { setCurrentUserRole } from 'actions/roles';
import { closeProfileDropdown } from 'actions/panel';
import { getUserDetails } from 'actions/userDetails';
import { setOrganisationHeader, setBranchHeader } from "actions/router";
import { setBranchName, setBranchId, setOrganisationId } from "actions/calendarView";
import { requestUserData } from "actions/profile";
import { resetCalendarDropdown } from "actions/calendarDropdown";
import type { Action } from 'types/actions';
import type { Account } from 'types/user';
import * as RolesConstants from 'constants/RolesConstants';

import * as RoutesConstants from 'constants/RoutesConstants';

import locale from 'service/locale';

type Props = {
  currentUser: any,
  roles: any,
  getUserDetails: (userId: string, isCurrentUser?: boolean) => Action;
  logoutUser: () => Action,
  setCurrentUserRole: (account: Account, currentUser: any) => Action,
  closeProfileDropdown: () => Action,
  setBranchHeader: (branchId: string) => Action,
  setOrganisationHeader: (organisationId: string) => Action,
  setBranchName: (branchName: string) => Action,
  setOrganisationId: (organisationId: string) => Action,
  setBranchId: (branchId: string) => Action,
  requestUserData: () => Action,
  resetCalendarDropdown: () => Action
}

type State = {
  isRedirect: boolean,
};

const mapStateToProps = (state) => ({
  currentUser: state.profile,
  roles: state.roles,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getUserDetails: (userId: string, isCurrentUser?: boolean) => dispatch(getUserDetails(userId, isCurrentUser)),
  logoutUser: () => dispatch(logoutUser()),
  setCurrentUserRole: (account: Account, currentUser: any) => dispatch(setCurrentUserRole(account, currentUser)),
  closeProfileDropdown: () => dispatch(closeProfileDropdown()),
  setBranchHeader: (branchId: string) => dispatch(setBranchHeader(branchId)),
  setOrganisationHeader: (organisationId: string) => dispatch(setOrganisationHeader(organisationId)),
  setBranchName: (branchName: string) => dispatch(setBranchName(branchName)),
  setOrganisationId: (organisationId: string) => dispatch(setOrganisationId(organisationId)),
  setBranchId: (branchId: string) => dispatch(setBranchId(branchId)),
  requestUserData: () => dispatch(requestUserData()),
  resetCalendarDropdown: () => dispatch(resetCalendarDropdown())
});

class ProfileDropdown extends Component<Props, State> {

  state = {
    isRedirect: false,
  };

  handleLogOut = () => {
    const { logoutUser } = this.props;
    logoutUser();
  };

  handleViewProfile = () => {
    const { getUserDetails, currentUser } = this.props;
    getUserDetails(currentUser.id, true);
  };

  handleBranchChange = (account: Account) => {
    const {
      setCurrentUserRole,
      closeProfileDropdown,
      currentUser,
      setBranchHeader,
      setOrganisationHeader,
      setBranchName,
      setBranchId,
      setOrganisationId,
      requestUserData,
      resetCalendarDropdown
    } = this.props;

    resetCalendarDropdown();
    requestUserData();
    setCurrentUserRole(account, currentUser);
    setOrganisationHeader(account.organizationId);

    if (account.branchName) {
      setBranchHeader(account.branchId);
      setBranchName(account.branchName);
      setOrganisationId(account.organizationId);
      setBranchId(account.branchId);
    }

    this.setState({
      isRedirect: true,
    }, () => {
      closeProfileDropdown();
    });
  };

  createNamesListElement = (account: any) => {
    let roleTarget = "";

    if (account.role === RolesConstants.ADMIN) {
      roleTarget = find(RolesConstants.PROFILE_ROLES_LIST, { id: RolesConstants.ADMIN }).label;
    } else if (account.role === RolesConstants.ORGANIZATION_ADMIN) {
      roleTarget = account.organizationName;
    } else {
      roleTarget = account.branchName;
    }

    return roleTarget;
  };

  createRolesListElement = (account: any) => {
    const role = find(RolesConstants.PROFILE_ROLES_LIST, { id: account.role }).label;
    let roleTarget = "";

    if (account.role === RolesConstants.ADMIN) {
      roleTarget = find(RolesConstants.PROFILE_ROLES_LIST, { id: RolesConstants.ADMIN }).label;
    } else if (account.role === RolesConstants.ORGANIZATION_ADMIN) {
      roleTarget = account.organizationName;
    } else {
      roleTarget = account.branchName;
    }

    return `${roleTarget}${account.role !== RolesConstants.ADMIN ? ` (${role})` : ""}`;
  };

  createRoleHeaderElement = (account: any) => {
    const role = find(RolesConstants.PROFILE_ROLES_LIST, { id: account.role }).label;
    return account.role !== RolesConstants.ADMIN ? role : "";
  };

  render() {
    const { roles, currentUser } = this.props;
    const { isRedirect } = this.state;
    const visibleRoles = roles.profileAccounts.filter(
      account =>
        !(account.role === roles.profileCurrentRole.role &&
        account.branchId === roles.profileCurrentRole.branchId &&
        account.organizationId === roles.profileCurrentRole.organizationId));

    return isRedirect
      ? <Redirect to={roles.profileCurrentRole.branchName ? RoutesConstants.CALENDAR : currentUser.adminUrl} />
      : (
          <Fragment>
            <div className="patient-care-dropdown-content-bordered">
              <b className="patient-care-profile-name">
                {this.createNamesListElement(roles.profileCurrentRole)}
              </b>
              <b className="patient-care-profile-role">
                {this.createRoleHeaderElement(roles.profileCurrentRole)}
              </b>

              <button
                className="patient-care-btn-link"
                onClick={this.handleViewProfile}
              >
                {locale.Share.myProfileDropdown}
              </button>
            </div>
            {
              visibleRoles && visibleRoles.length > 0 ? (
                <ul className="patient-care-profile-list">
                  { visibleRoles.filter(account => {
                    if (account.role === RolesConstants.ORGANIZATION_ADMIN && account.organizationName !== null) return true;
                    return [RolesConstants.BRANCH_ADMIN, RolesConstants.BRANCH_MEMBER].includes(account.role) && account.branchName !== null;
                  }).map(account => (
                    <li
                      key={(account.role === RolesConstants.ADMIN || account.role === RolesConstants.ORGANIZATION_ADMIN) ? account.organizationId : account.branchId}
                      onClick={() => this.handleBranchChange(account)}
                    >
                      <span>{this.createRolesListElement(account)}</span>
                    </li>
                  ))}
                </ul>
              ) : null
            }

            <div className="patient-care-dropdown-content">
              <Button
                buttonType="blueline"
                messageKey="signout-btn"
                defaultMessage={locale.Share.logOut}
                onClick={this.handleLogOut}
                data-id="signout-btn"
                className="patient-care-btn-greyline"
                buttonSize="small"
              />
            </div>
          </Fragment>
        )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
