const initialState = {};

export default (localState: Object = initialState, action: any): Object => {
  switch (action.type) {
    case "FORM_UPDATE":
      if (action.payload)
        return {
          ...localState,
          ...action.payload
        };
      return initialState;
    case "FORM_CLEAR":
      return {};
    default:
      return localState
  }
}
