import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import { Button } from "@patient-access/ui-kit";
import { getOrganisationDetails, updateOrganisationDetails, setOrganisationConfirmationStatus, deleteOrganisationLogo, uploadLogoToBlob, deleteLogoFromBlob } from "actions/organisationDetails";
import type { Organisation } from "types/organisation";
import type { ConfirmationStatus } from "constants/OrganisationsConstants";
import { updateForm, clearForm } from "actions/form";
import * as RolesConstants from "constants/RolesConstants";
import * as OrganisationsConstants from "constants/OrganisationsConstants";
import * as RoutesConstants from 'constants/RoutesConstants';
import ConfirmationOverlay from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import RemoveOrganisationLogoOverlay from "./RemoveOrganisationLogoOverlay/RemoveOrganisationLogoOverlay"
import OrganisationLogoUploader from "./OrganisationLogoUploader/OrganisationLogoUploader";
import FeaturedServices from "./FeaturedServices/FeaturedServices";
import SaveFeaturedServicesOverlay from "./FeaturedServices/SaveFeaturedServicesOverlay";
import CompanyName from "./CompanyName/CompanyName";
import RegisteredAddress from "./RegisteredAddress/RegisteredAddress";
import SmartProfileVisibility from './SmartProfileVisibility/SmartProfileVisibility';
import { checkPostcode } from 'helpers/checkValues';
import locale from "service/locale";
import "./styles.scss";

type Props = {
  organisationId: string,
  organisationsList: Organisation[],
  updateForm: (data: any) => Action,
  clearForm: () => Action,
  getOrganisationDetails: (organisationId: string) => Organisation,
  organisationDetails: Organisation,
  getOrganisatonDetailsPending: Boolean,
  currentRole: Object,
  isActiveAgenda: boolean,
  form: Object,
  isUpdateOrganisationDetailsPending: boolean,
  updateOrganisationDetails: (organisationId: string, organisationData: Object) => any,
  setOrganisationConfirmationStatus: (status: ConfirmationStatus) => Action,
  confirmationStatus: ConfirmationStatus,
  uploadLogoToBlob: (file, organisationName) => Action,
  deleteLogoFromBlob: (file, organisationName) => Action,
  deleteOrganisationLogo: (organisationId: string, organisationData: Object) => any,
};

type State = {
  openDiscardChanges: boolean,
  isRedirect: {
    status: boolean,
    redirectUrl: string
  },
  isConfirmationError: boolean,
  openRemoveLogoOverlay: boolean
};

const mapStateToProps = (state) => {
  return {
    organisationsList: state.organisations.organisationsList,
    organisationDetails: state.organisationDetails,
    getOrganisatonDetailsPending: state.organisationDetails.getOrganisatonDetailsPending,
    currentRole: state.roles.profileCurrentRole,
    isActiveAgenda: state.panel.isActiveAgenda,
    form: state.form,
    isUpdateOrganisationDetailsPending: state.organisationDetails.isUpdateOrganisationDetailsPending,
    confirmationStatus: state.organisationDetails.confirmationStatus,
  }
};

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: data => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  getOrganisationDetails: (organisationId: string) => dispatch(getOrganisationDetails(organisationId)),
  updateOrganisationDetails: (organisationId, organisationData) => dispatch(updateOrganisationDetails(organisationId, organisationData)),
  setOrganisationConfirmationStatus: status => dispatch(setOrganisationConfirmationStatus(status)),
  uploadLogoToBlob: (file, organisationName) => dispatch(uploadLogoToBlob(file, organisationName)),
  deleteLogoFromBlob: (logo) => dispatch(deleteLogoFromBlob(logo)),
  deleteOrganisationLogo: (organisationId, organisationData) => dispatch(deleteOrganisationLogo(organisationId, organisationData)),
});

class OrganisationDetails extends Component<Props, State> {
  state = {
    openDiscardChanges: false,
    isRedirect: {
      status: false,
      redirectUrl: ""
    },
    isConfirmationError: false,
    openRemoveLogoOverlay: false
  }

  componentDidMount = () => {
    const { getOrganisationDetails, organisationId, setOrganisationConfirmationStatus } = this.props;
    setOrganisationConfirmationStatus(OrganisationsConstants.ORGANISATION_DETAILS_UPDATE_STATUS.VIEW);
    getOrganisationDetails(organisationId);
  }

  componentWillUnmount = () => {
    const { clearForm } = this.props;
    clearForm();
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { confirmationStatus, organisationId } = nextProps;

    switch (confirmationStatus) {
      case OrganisationsConstants.ORGANISATION_DETAILS_UPDATE_STATUS.VIEW:
        this.setState({ isConfirmationError: false });
        return;
      case OrganisationsConstants.ORGANISATION_DETAILS_UPDATE_STATUS.UPDATED:
        this.setState({
          isRedirect: {
            status: true,
            redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.DETAILS}`
          }
        },
          this.clearUpdatePending
        );
        return;
      default:
        break;
    }
    this.setState({ isConfirmationError: true });
  }

  clearUpdatePending = () => {
    const { setOrganisationConfirmationStatus } = this.props;
    setOrganisationConfirmationStatus(OrganisationsConstants.ORGANISATION_DETAILS_UPDATE_STATUS.VIEW);
  }

  handleDiscardChanges = (e: any) => {
    e && e.preventDefault();
    this.setState({ openDiscardChanges: true });
  }

  handleUpdateOrganisationDetails = async (e: any) => {
    e && e.preventDefault();
    const { updateOrganisationDetails, organisationId, organisationDetails } = this.props;
    await this.uploadLogoToStorage();
    updateOrganisationDetails(organisationId, organisationDetails);
  }

  handleConfirm = () => {
    const { organisationId, getOrganisationDetails, clearForm } = this.props;
    this.setState({ openDiscardChanges: false });
    clearForm();
    getOrganisationDetails(organisationId);
  }

  handleCancel = () => {
    this.setState({ openDiscardChanges: false });
  }

  uploadLogoToStorage = async () => {
    const { organisationDetails, form, updateForm, uploadLogoToBlob } = this.props;
    if (form.organisation_logo && form.organisation_logo.size) {
      const logoUrl = await uploadLogoToBlob(form.organisation_logo, organisationDetails.name);
      logoUrl && updateForm({ organisation_logoUrl: logoUrl });
    }
  }

  handleRemoveLogo = (e: any) => {
    e && e.preventDefault();
    const { form, updateForm, organisationDetails } = this.props;

    if (organisationDetails.logo) {
      this.setState({ openRemoveLogoOverlay: true });
    } else if (form.organisation_logo) {
      updateForm({ organisation_logo: null, imagePreviewUrl: '' });
    }
  }

  handleLogoDiscardChanges = (e: any) => {
    e && e.preventDefault();
    this.setState({ openRemoveLogoOverlay: false });
  }

  handleRemoveLogoConfirm = async () => {
    const { deleteOrganisationLogo, organisationId, deleteLogoFromBlob, organisationDetails } = this.props;

    if (organisationDetails.logo) {
      const response = await deleteOrganisationLogo(organisationId, organisationDetails);
      if (response) {
        await deleteLogoFromBlob(organisationDetails.logo);
      }
    }
  }

  renderRemoveLogoOverlay = () => {
    if (!this.state.openRemoveLogoOverlay) return null;
    return (
      <RemoveOrganisationLogoOverlay
        handleLogoDiscardChanges={this.handleLogoDiscardChanges}
        handleRemoveLogo={this.handleRemoveLogoConfirm}
        isLoading={this.props.isUpdateOrganisationDetailsPending}
      />
    );
  }

  handleSave = async (e: any) => {
    e && e.preventDefault();
    const { form, updateForm } = this.props;
    if (form.organisation_featuredServices || form.organisation_are_all_smartprofiles_visible !== undefined) {
      updateForm({ openSaveFeaturedServicesOverlay: true });
      return;
    }
    this.handleUpdateOrganisationDetails();
  }

  handleSaveFeaturedServicesDiscardChanges = (e: any) => {
    e && e.preventDefault();
    this.props.updateForm({ openSaveFeaturedServicesOverlay: false });
  }

  renderSaveFeaturedServicesOverlay = () => {
    const { form, organisationDetails} = this.props;
    const { isDataChanged, openSaveFeaturedServicesOverlay } = form;
    const spBranchCount = (form.isEditSmartProfileVisibility ? organisationDetails.smartPharmacyBranchVisibility && 
      (form.organisation_are_all_smartprofiles_visible ? organisationDetails.smartPharmacyBranchVisibility.disabledCount :
      organisationDetails.smartPharmacyBranchVisibility.enabledCount) : organisationDetails.branches && organisationDetails.branches.length) || 0;
    if (!(isDataChanged && openSaveFeaturedServicesOverlay)) return null;
    return (
      <SaveFeaturedServicesOverlay
        branchesCount={(organisationDetails.branches && spBranchCount) || 0}
        handleDiscardChanges={this.handleSaveFeaturedServicesDiscardChanges}
        handleSave={this.handleUpdateOrganisationDetails}
        isLoading={this.props.isUpdateOrganisationDetailsPending}
        isEditSmartProfileVisibility={form.isEditSmartProfileVisibility}
        isEditFeaturedServices={form.isEditFeaturedServices}
      />
    );
  }

  renderActions = () => {
    const { isActiveAgenda, form, isUpdateOrganisationDetailsPending } = this.props;

    const isEmptyOrganisationName = (form.organisation_name && !form.organisation_name.length) === "";
    const isEmptyAddressLine = (form.organisation_address_1 && !form.organisation_address_1.length) === "";
    const isEmptyAddressCity = (form.organisation_city && !form.organisation_city.length) === "";
    const isEmptyPostCode = (form.organisation_postcode && !form.organisation_postcode.length) === "";
    const isValidPostCode = form.organisation_postcode && form.organisation_postcode.length > 0 ? checkPostcode(form.organisation_postcode) : true;
    const isEmptyCountry = (form.organisation_country && !form.organisation_country.length) === "";
    const areInvalidFeaturedServices = (form.organisation_featuredServices && form.organisation_featuredServices.some(s => s.id === null || s.name === null));
    const isFormValid = !isEmptyOrganisationName && !isEmptyAddressLine && !isEmptyAddressCity && !isEmptyPostCode && isValidPostCode && !isEmptyCountry && !areInvalidFeaturedServices;

    if (!form.isDataChanged) return null;
    return (
      <Fragment>
        <div className={`patient-care-buttons-fixed-bottom ${isActiveAgenda ? "is-active-agenda" : ""}`}>
          <div className="patient-care-container-sm">
            <div className="patient-care-row-align-right">
              <Button
                buttonType="blueline"
                messageKey="discard-btn"
                defaultMessage={locale.Appointment.buttons.discardChanges}
                onClick={this.handleDiscardChanges}
                isLoading={isUpdateOrganisationDetailsPending}
                data-id="discard-btn"
                className="patient-care-btn-in-group"
              />
              <Button
                buttonType="secondary"
                messageKey="save-changes-btn"
                defaultMessage={locale.Appointment.buttons.saveChanges}
                onClick={this.handleSave}
                isLoading={isUpdateOrganisationDetailsPending}
                data-id="save-changes-btn"
                className="patient-care-btn-in-group"
                isDisabled={!isFormValid}
                tabIndex={!isFormValid ? -1 : 0}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    const { openDiscardChanges, isRedirect } = this.state;
    const { currentRole, form, organisationDetails } = this.props;
    const isAbleToEdit = currentRole.role === RolesConstants.ADMIN;
    const isOrgAdminAbleToEdit = RolesConstants.ORGANIZATION_ADMIN;

    const renderConfirmationOverlay = () => {
      if (openDiscardChanges) {
        return (
          <ConfirmationOverlay
            handleConfirm={this.handleConfirm}
            handleCancel={this.handleCancel}
            content={locale.Modals.discardChangesOverlay.content}
            header={locale.Modals.discardChangesOverlay.header}
            buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
            buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
          />
        );
      }
      return null;
    }

    if (isRedirect.status) return <Redirect to={isRedirect.redirectUrl} />;
    return (
      <div className={form.isDataChanged ? "patient-care-details-holder data-changed" : "patient-care-details-holder"}>
        <div className="patient-care-container-sm">
          <CompanyName
            organisationDetails={organisationDetails}
            isAbleToEdit={isAbleToEdit}
          />
          <OrganisationLogoUploader
            organisationDetails={organisationDetails}
            handleRemoveLogo={this.handleRemoveLogo}
            isAbleToEdit={isAbleToEdit && (!form.isEditFeaturedServices && !form.isEditSmartProfileVisibility)}
          />
          <SmartProfileVisibility
            organisationDetails={organisationDetails}
            isAbleToEdit={(isAbleToEdit || isOrgAdminAbleToEdit) && !form.isEditCompanyLogo}
          />
          <FeaturedServices
            organisationId={this.props.organisationId}
            organisationDetails={organisationDetails}
            isAbleToEdit={(isAbleToEdit || isOrgAdminAbleToEdit) && !form.isEditCompanyLogo}
          />
          <RegisteredAddress
            organisationDetails={organisationDetails}
            isAbleToEdit={isAbleToEdit}
          />
        </div>

        {this.renderActions()}
        {this.renderRemoveLogoOverlay()}
        {this.renderSaveFeaturedServicesOverlay()}
        {renderConfirmationOverlay()}

        <NavigationPrompt when={form.isDataChanged}>
          {({ onConfirm, onCancel }) => (
            <ConfirmationOverlay
              handleConfirm={onConfirm}
              handleCancel={onCancel}
              content={locale.Modals.discardChangesOverlay.content}
              header={locale.Modals.discardChangesOverlay.header}
              buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
              buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
            />
          )}
        </NavigationPrompt>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationDetails);
