import * as RouterConstants from 'constants/RouterConstants';
import type { Action } from 'types/actions';

type RouterState = {
  organisationId: string;
  branchId: string;
}

const initialState = {
  organisationId: "",
  branchId: "",
};

export default (localState: RouterState = initialState, action: Action): RouterState =>  {
  switch (action.type) {
    case RouterConstants.SET_ORGANISATION_ID:
      return {
        ...localState,
        organisationId: action.payload
      };
    case RouterConstants.SET_BRANCH_ID:
      return {
        ...localState,
        branchId: action.payload
      };
    case RouterConstants.REMOVE_ORGANISATION_ID:
      return {
        ...localState,
        organisationId: initialState.organisationId
      };
    case RouterConstants.REMOVE_BRANCH_ID:
      return {
        ...localState,
        branchId: initialState.branchId
      };
    default:
      return localState
  }
}
