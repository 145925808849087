import React, { Component, Fragment } from "react";
import Select from "react-select";
import values from "lodash.values";
import find from "lodash.find";

import locale from "service/locale";
import "./styles.scss";

const allStatusesOptions = values(locale.Appointment.status);

type Props = {
  isDisabled?: boolean,
  status: number,
  outcomeStatus: number,
  isPastAppointment: boolean,
  isTodayAppointment: boolean,
  handleAppointmentStatusChange: (value: any) => any,
  isPastAndEndTimeAppointment: boolean,
  bookingMethod: number,
};

class AppointmentStatus extends Component<Props> {

  render() {
    const {
      isDisabled,
      status,
      handleAppointmentStatusChange,
      isPastAppointment,
      isTodayAppointment,
      outcomeStatus,
      isPastAndEndTimeAppointment,
      bookingMethod
    } = this.props;
    const isSelect = isTodayAppointment || isPastAppointment;
    let currentStatusesOptions = allStatusesOptions;
    if (isTodayAppointment) {
      currentStatusesOptions = [
        locale.Appointment.status.provided,
        locale.Appointment.status.notProvided
      ];
    } else if (isPastAppointment) {
      currentStatusesOptions = [
        locale.Appointment.status.provided,
        locale.Appointment.status.notProvided,
        locale.Appointment.status.missed
      ];
    } else {
      currentStatusesOptions = [];
    }

    let selectedStatus = find(allStatusesOptions, {
      status: status,
      outcomeStatus: outcomeStatus
    }) || {
      label: "Select status",
      value: 0,
      color: "neutral-grey",
      status: 0,
      outcomeStatus: 0
    };

    let rowStatusColor = selectedStatus.color;
    if (isPastAppointment && isPastAndEndTimeAppointment && !isTodayAppointment && status === locale.Appointment.status.booked.status) { // pending action
      rowStatusColor = locale.Appointment.status.pending.color;
      selectedStatus = locale.Appointment.status.pending;
    }
    // upcoming booked
    else if (!isPastAppointment && !isPastAndEndTimeAppointment && !isTodayAppointment && status === locale.Appointment.status.booked.status) {
      rowStatusColor = locale.Appointment.status.booked.color;
    }

    return (
      <Fragment>
        <div className={`${(isPastAndEndTimeAppointment && status === locale.Appointment.status.booked.status)
          ? 'patient-care-block-overlay' : ''}`}>
          {((isPastAppointment &&
            status === locale.Appointment.status.booked.status) ||
            selectedStatus.status === 0) && (
              <div className="patient-care-block">
                <p>
                  {locale.Appointment.pastAppointmentStatusWarning[0]}
                  <br />
                  {bookingMethod === locale.Appointment.type.online.value && locale.Appointment.pastAppointmentStatusWarning[1]}
                </p>
              </div>
            )}
          <div className={`${(isPastAndEndTimeAppointment && status === locale.Appointment.status.booked.status)
            ? 'patient-care-row-status-overlay' : ''}`}>
            <div className={`patient-care-row-status  ${rowStatusColor}`}>
              <h3 className="patient-care-status-title">
                {locale.Appointment.appointmentStatusTitle}:
              </h3>
              {
                isSelect
                  ? <Select
                    id="appointment-status"
                    name="appointment-status"
                    options={currentStatusesOptions}
                    onChange={handleAppointmentStatusChange}
                    classNamePrefix="patient-care"
                    className="patient-care-select"
                    isDisabled={isDisabled}
                    value={selectedStatus}
                  />
                  : <h3 className="patient-care-status">{selectedStatus.label}</h3>
              }
            </div>
          </div>
        </div>

        <hr className="patient-care-separator-small" />
      </Fragment>
    );
  }
}

export default AppointmentStatus;
