import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as OrganisationsListConstants from 'constants/OrganisationsListConstants';
import { getToken } from 'helpers/common';
import agent from 'service/agent';

// SEARCH_VALUE
export const setSearchValue = (value: string): Action => ({
  type: OrganisationsListConstants.SET_ORGANISATIONS_LIST_SEARCH_VALUE,
  payload: value,
});

// ORGANISATIONS_LIST
export const setOrganisationsListPending = (): Action => ({
  type: OrganisationsListConstants.GET_ORGANISATIONS_LIST_PENDING,
});

export const setOrganisationsListSuccess = (): Action => ({
  type: OrganisationsListConstants.GET_ORGANISATIONS_LIST_SUCCESS,
});

export const setOrganisationsListError = (): Action => ({
  type: OrganisationsListConstants.GET_ORGANISATIONS_LIST_ERROR,
});

export const setOrganisationsListData = (data: any): Action => ({
  type: OrganisationsListConstants.SET_ORGANISATIONS_LIST_DATA,
  payload: data,
});

export const getOrganisationsList = (pageNumber: number, searchValue: string) => (dispatch: Function) => {
  dispatch(setOrganisationsListPending());
  getToken(dispatch)
    .then(accessToken => {
      const itemsPerPage = 30;
      const searchMode = OrganisationsConstants.SEARCH_MODE.ORGANISATIONS;

      agent.Organisations.getFilteredOrganisations(pageNumber, searchValue, itemsPerPage, accessToken, searchMode)
        .then(organisationsList => {
          dispatch(setOrganisationsListData(organisationsList));
          dispatch(setOrganisationsListSuccess());
        })
        .catch(err => {
          dispatch(setOrganisationsListError());
          console.log("getOrganisationsList server error or branch was not found", err);
        });
    });
};
