import locale from 'service/locale';

export const DROPDOWN_OPTION_DEFAULT = {
  value: undefined,
  label: locale.Share.defaultServiceSelect,
};

export const CUSTOM_APPOINTMENT_SERVICE_ID = "00000000-0000-0000-0000-000000000111";

export const INTERNAL_EVENTS_AS_SERVICE_ID = "00000000-0000-0000-0000-000000000222";

export const INTERNAL_EVENTS_AS_SERVICE = {
  label: "Internal event",
  value: "00000000-0000-0000-0000-000000000222",
  duration: 5
}
