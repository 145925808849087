import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
  Form,
  FormItem,
  Input,
  InputError
} from "@patient-access/ui-kit";
import locale from 'service/locale';


type Props = {
  form: any,
  handleOrganisationUrlChange: (name: string, value: string) => any,
}

const mapStateToProps = state => ({
  form: state.form,
});

class OrganisationBookingUrl extends Component<Props, State> {

  handleOrganisationUrlChange = ({ target: { name, value } }) => {
    const { handleOrganisationUrlChange } = this.props;
    handleOrganisationUrlChange(name, value)
  }

  render() {
    const {
      isAbleToEdit,
      isEditOrganisationBookingUrlMode,
      handleOrganisationBookingUrlEditMode,
      url,
      form,
      isEmptyBookingUrl,
      isUrlCorrect
    } = this.props;

    const isEditedBookingUrl = form.booking && form.booking.point && Object.keys(form.booking.point).includes('url');

    return (
      <Fragment>
        <hr className="patient-care-separator" />
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.OrganisationsSettings.bookingUrl.title}</h3>
            {isAbleToEdit && (
              <button
                className="patient-care-btn-link"
                onClick={handleOrganisationBookingUrlEditMode}
              >
                {isEditOrganisationBookingUrlMode
                  ? locale.Appointment.customerDetails.buttonDone
                  : locale.Appointment.customerDetails.buttonEdit}
              </button>
            )}
          </div>
          <p>{locale.OrganisationsSettings.bookingUrl.hint1}</p>
          <div className="patient-care-block">
            <p>{locale.OrganisationsSettings.bookingUrl.hint2}</p>
          </div>
        </div>
        {
          isEditOrganisationBookingUrlMode ? (
            <Fragment>
              <div className="patient-care-block">
                <Form>
                  <FormItem
                    type="item"
                    error={!isUrlCorrect ? "error" : null}>
                    <Input
                      type="text"
                      id="url"
                      name="url"
                      data-id="url"
                      placeholder={locale.OrganisationsSettings.bookingUrl.placeHolder}
                      onChange={this.handleOrganisationUrlChange}
                      defaultValue={ isEditedBookingUrl
                        ? form.booking.point.url
                        : url}
                    />
                    {!isUrlCorrect && <InputError message={locale.OrganisationsSettings.bookingUrl.invalidError} />}
                  </FormItem>
                </Form>
              </div>
            </Fragment>
          ) : (
              <Fragment>
                <div className="patient-care-block">
                  <span className="patient-care-filled-input patient-care-filled-input-full">
                    { (isEditedBookingUrl && isEmptyBookingUrl) || (!isEditedBookingUrl && !url)
                      ? locale.OrganisationsSettings.bookingUrl.notProvided
                      : isEditedBookingUrl
                        ? form.booking.point.url
                        : url
                    }
                  </span>
                </div>
              </Fragment>
            )
        }
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(OrganisationBookingUrl);