import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { setUI, deleteOrgService } from 'actions/organisationServices';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  isLoading: boolean,
  userData: any,
  setUI: (value: any) => any,
  deleteOrgService: (organisationId: string) => any,
};

const mapStateToProps = state => ({
  userData: state.organisationServices.user.data,
  isLoading: state.organisationServices.user.isDeleteServicePending,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUI: (value: any) => dispatch(setUI(value)),
  deleteOrgService: (organisationId: string) => dispatch(deleteOrgService(organisationId)),
});

class OverlayConfirmationDelete extends Component<Props> {
  render() {
    const { organisationId, isLoading, userData, setUI, deleteOrgService } = this.props;
    const branchCount = userData.serviceBranches.length;

    return (
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{locale.OrganisationServices.overlay.confirmation.deleteHeader(userData.serviceName, branchCount)}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.OrganisationServices.overlay.confirmation.deleteContent}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="goBack"
            defaultMessage={locale.OrganisationServices.button.goBack}
            onClick={() => { setUI({ isOverlayConfirmationVisible: false }); }}
            isLoading={isLoading}
          />
          <Button
            buttonType="secondary"
            messageKey="removeService"
            defaultMessage={locale.OrganisationServices.button.removeAtNoBranches(branchCount)}
            onClick={() => { deleteOrgService(organisationId); }}
            isLoading={isLoading}
          />
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayConfirmationDelete);
