import React, { Component } from 'react';

import './styles/patient-care-feature-content-scroll.scss';

type Props = {
  children: any,
  isGreyBackground?: boolean,
  isDataChanged?: boolean,
  className?: string,
};

class FeatureContentScroll extends Component<Props> {
  render() {
    const {
      children,
      isDataChanged,
      isGreyBackground,
      className,
      ...args
    } = this.props;

    return (
      <div className={`patient-care-feature-content-scroll${isGreyBackground ? ' grey' : ''}${isDataChanged ? ' changed' : ''}${className ? ` ${className}` : ''}`}  {...args}>
        {children}
      </div>
    );
  }
};

export default FeatureContentScroll;
