// FILTERS
export const SET_CALENDARS_LIST_SEARCH_VALUE = "SET_CALENDARS_LIST_SEARCH_VALUE";
export const SET_CALENDARS_LIST_BRANCHES_IDS = "SET_CALENDARS_LIST_BRANCHES_IDS";
export const SET_CALENDARS_LIST_SERVICES_IDS = "SET_CALENDARS_LIST_SERVICES_IDS";

// CALENDARS_LIST
export const GET_CALENDARS_LIST_PENDING = "GET_CALENDARS_LIST_PENDING";
export const GET_CALENDARS_LIST_SUCCESS = "GET_CALENDARS_LIST_SUCCESS";
export const GET_CALENDARS_LIST_ERROR = "GET_CALENDARS_LIST_ERROR";
export const SET_CALENDARS_LIST_DATA = "SET_CALENDARS_LIST_DATA";

// CALENDARS_SERVICES
export const GET_CALENDARS_SERVICES_PENDING = "GET_CALENDARS_SERVICES_PENDING";
export const GET_CALENDARS_SERVICES_SUCCESS = "GET_CALENDARS_SERVICES_SUCCESS";
export const GET_CALENDARS_SERVICES_ERROR = "GET_CALENDARS_SERVICES_ERROR";
export const SET_CALENDARS_SERVICES_DATA = "SET_CALENDARS_SERVICES_DATA";

// CALENDAR_DETAILS
export const SET_CALENDAR_DETAILS_OVERLAY_DATA = "SET_CALENDAR_DETAILS_OVERLAY_DATA";
