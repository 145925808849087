import moment from 'moment';
import type { Action } from 'types/actions';
import agent from 'service/agent';
import type { AppointmentsByDates } from 'types/appointments';
import * as AppointmentsConstants from 'constants/AppointmentsConstants';
import * as SearchConstants from 'constants/SearchConstants';
import { closeAgenda, openSearch, completeSearch, closeSlots } from 'actions/panel';
import locale from 'service/locale';
import { getToken } from "helpers/common";

export const getAppointmentsPending = (): Action => ({
  type: AppointmentsConstants.GET_APPOINTMENTS_PENDING,
});

export const getAppointmentsSuccess = (): Action => ({
  type: AppointmentsConstants.GET_APPOINTMENTS_SUCCESS,
});

export const getAppointmentsError = (): Action => ({
  type: AppointmentsConstants.GET_APPOINTMENTS_ERROR,
});

export const setAppointments = (appointmentsList: AppointmentsByDates[]): Action => ({
  type: AppointmentsConstants.SET_APPOINTMENTS,
  payload: appointmentsList
});

export const setSearchResults = (searchResultsList: AppointmentsByDates[]): Action => ({
  type: SearchConstants.SET_SEARCH_RESULTS,
  payload: searchResultsList
});

export const setSearchEvents = (searchEventssList: string[]): Action => ({
  type: SearchConstants.SET_SEARCH_EVENTS,
  payload: searchEventssList
});

const getSearchResultsPending = (): Action => ({
  type: SearchConstants.SEARCH_RESULTS_PENDING,
});

const getSearchResultsError = (): Action => ({
  type: SearchConstants.SEARCH_RESULTS_ERROR,
});

export const getAppointments = (endDate?: Date) => (dispatch: Function) => {
  const filter = { startDate: moment().valueOf() };

  if (endDate) {
    filter.endDate = moment(endDate).valueOf();
  }
  getToken(dispatch)
    .then(accessToken => {
      agent.Appointments.getAppointments(filter, accessToken)
        .then(appointmentsList => {
          dispatch(setAppointments(appointmentsList));
        })
        .catch(err => {
          console.log(err);
        });
    });

};

export const filterAppointments = (id: string) => (dispatch: Function) => {
  const filter = { calendarId: id };

  getToken(dispatch)
    .then(accessToken => {
      agent.Appointments.getFilteredAppointments(filter, accessToken)
        .then(appointmentsList => {
          dispatch(setAppointments(appointmentsList));
        })
        .catch(err => {
          console.log(err);
        });
    });

};

export const searchAppointments = (searchText: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { isActiveAgenda, isActiveSearchResults, isActiveAppointmentSlots } = currentState.panel;
  dispatch(getSearchResultsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Search.search(searchText, accessToken)
        .then(searchResults => {
          dispatch(setSearchResults(searchResults.appointmentsByDate));
          const searchEvents = [];
          searchResults.appointmentsByDate.forEach(day => {
            day.appointments.forEach(appointment => {
              searchEvents.push(appointment.appointmentId);
            })
          });
          dispatch(setSearchEvents(searchEvents));
          isActiveAgenda && !isActiveSearchResults && dispatch(closeAgenda());
          isActiveAppointmentSlots && dispatch(closeSlots());
          !isActiveSearchResults && dispatch(openSearch());
          dispatch(completeSearch());
        })
        .catch(err => {
          dispatch(getSearchResultsError());
          console.log("searchAppointments server error", err);
        });
    });
};

export const filterSearchResultsByStatus = (value: number, status: number, outcomeStatus: number) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const newSearchEvents = [];
  const newSearchResults = [...currentState.search.results];
  newSearchResults.forEach(day => {
    let resultsWithStatus = 0;
    day.appointments.forEach(appointment => {
      appointment.isHiddenSearch = false;
      switch (value) {
        case locale.Search.searchFilterOptions[0].value:
            newSearchEvents.push(appointment.appointmentId);
            resultsWithStatus++;
            return;
        case locale.Search.searchFilterOptions[1].value:
          if (moment().isSameOrAfter(appointment.startTime.split('+')[0], "second")) {
            appointment.isHiddenSearch = true;
          } else {
            resultsWithStatus++;
            newSearchEvents.push(appointment.appointmentId);
          }
          break;
        case locale.Search.searchFilterOptions[2].value:
          if (
            appointment.status === status &&
            appointment.outcomeStatus === outcomeStatus
          ) {
            resultsWithStatus++;
            newSearchEvents.push(appointment.appointmentId);
          } else {
            appointment.isHiddenSearch = true;
          }
          break;
        case locale.Search.searchFilterOptions[3].value:
          if (
            moment().isAfter(appointment.endTime.split('+')[0], "second") &&
            appointment.status === status &&
            appointment.outcomeStatus === outcomeStatus
          ) {
            resultsWithStatus++;
            newSearchEvents.push(appointment.appointmentId);
          } else {
            appointment.isHiddenSearch = true;
          }
          break;
        case locale.Search.searchFilterOptions[4].value:
          if (
            appointment.status === status &&
            appointment.outcomeStatus === outcomeStatus
          ) {
            resultsWithStatus++;
            newSearchEvents.push(appointment.appointmentId);
          } else {
            appointment.isHiddenSearch = true;
          }
          break;
        case locale.Search.searchFilterOptions[5].value:
          if (
            appointment.status === status &&
            appointment.outcomeStatus === outcomeStatus
          ) {
            resultsWithStatus++;
            newSearchEvents.push(appointment.appointmentId);
          } else {
            appointment.isHiddenSearch = true;
          }
          break;
        case locale.Search.searchFilterOptions[6].value:
          if (
            appointment.status === status &&
            appointment.outcomeStatus === outcomeStatus
          ) {
            resultsWithStatus++;
            newSearchEvents.push(appointment.appointmentId);
          } else {
            appointment.isHiddenSearch = true;
          }
          break;
        default:
          if (
            appointment.status === status &&
            appointment.outcomeStatus === outcomeStatus) {
              newSearchEvents.push(appointment.appointmentId);
              resultsWithStatus++;
          } else {
            appointment.isHiddenSearch = true;
          }
          break;
      }
    });
    day.isHiddenDay = !resultsWithStatus;
  });
  dispatch(setSearchResults(newSearchResults));
  dispatch(setSearchEvents(newSearchEvents));
};

