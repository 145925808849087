import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FeatureSidebar } from 'components/Share/feature';
import { setUI, setUserStep, setUserData, resetUserEditData, getBranchesSummary } from 'actions/organisationServices';
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';

type Props = {
  organisationId: string,
  serviceId: string,
  isAffiliate: boolean,
  isSidebarEditVisible: boolean,
  isEditByBranch: boolean,
  setUI: (value: any) => any,
  step: any,
  setUserData: (value: any) => any,
  setUserStep: (value: any) => any,
  isDiscardModalOpen: boolean;
  resetUserEditData: () => any,
  getBranchesSummary: (organisationId: string, serviceId: string) => any,
};

const mapStateToProps = (state) => ({
  isSidebarEditVisible: state.organisationServices.ui.isSidebarEditVisible,
  isEditByBranch: state.organisationServices.ui.isEditByBranch,
  step: state.organisationServices.user.step,
  userEditData: state.organisationServices.user.editData,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setUI: (value: any) => dispatch(setUI(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  resetUserEditData: () => dispatch(resetUserEditData()),
  getBranchesSummary: (organisationId: string, serviceId: string) => dispatch(getBranchesSummary(organisationId, serviceId)),
});

class SidebarEditService extends Component<Props> {
  state = {
    isDiscardModalOpen: false,
  };

  resetServiceData = () => {
    const { setUserData } = this.props;
    setUserData({
      serviceModes: [],
      serviceIsActive: null,
      servicePrice: null,
      serviceDuration: null,
      serviceCutOff: null,
      serviceIsVatIncluded: null,
      serviceIsShowPrice: null,
      serviceBranches: [],
      bookingUrl: {
        isEdited: false,
        value: null
      }
    });
  };

  componentWillUnmount = () => {
    this.resetServiceData();
    this.props.resetUserEditData();
    setUserStep(1);
  }
  
  handleOpenDiscardDialog = () => {
    const { setUserStep, setUI, isEditByBranch } = this.props;
    let editedData = this.props.userEditData;
    let dataKeys = Object.keys(editedData);

    const filterEditedData = (d) => {
      const edited = editedData[d];
      if (edited.hasOwnProperty('isEdited') && edited.isEdited === true) {
        return true;
      }
      return false;
    };

    const editedDataKeys = dataKeys.filter((d) => filterEditedData(d));

    if (!editedDataKeys || editedDataKeys.length > 0) {
      this.setState({ isDiscardModalOpen: true });
    }
    else {
      if (isEditByBranch) {
        this.handleDiscardChanges();
      } else {
        this.setState(
          {
            isDiscardModalOpen: false
          },
          () => {
            setUserStep(1);
            setUI({ isSidebarEditVisible: false });
          }
        );
      }
    }
  };

  handleDiscardChanges = () => {
    const { setUserStep, setUI, resetUserEditData, getBranchesSummary, organisationId, serviceId } = this.props;
    this.setState(
      {
        isDiscardModalOpen: false
      },
      () => {
        getBranchesSummary(organisationId, serviceId);
        resetUserEditData();
        setUserStep(1);
        setUI({ isSidebarEditVisible: false, isEditByBranch: false }); 
      }
    );
  };
  
  handleStay = () => {
    this.setState({
      isDiscardModalOpen: false
    });
  };
  
  render() {
    const { isSidebarEditVisible, step, organisationId, isAffiliate, serviceId } = this.props;
    const { isDiscardModalOpen } = this.state;

    const renderContent = () => {
      switch (step) {
        case 1:
          return <StepOne isAffiliate={isAffiliate} organisationId={organisationId} serviceId={serviceId} />;
        case 2:
          return <StepTwo organisationId={organisationId} />;

        default:
          return null;
      }
    }

    if (isDiscardModalOpen) {
      return (
        <DiscardChangesOverlay
        handleDiscardChanges={this.handleDiscardChanges}
        handleStay={this.handleStay}
      />
      );
    }

    if (isSidebarEditVisible) {
      return (
        <FeatureSidebar isOpen={true} doClose={() => { this.handleOpenDiscardDialog(); }}>
          {renderContent()}
        </FeatureSidebar>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarEditService);
