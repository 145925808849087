import type { SnackbarState } from "types/snackbar";

const initialState: SnackbarState = {
  message: "",
  isActive: false
};

export default (
  localState: SnackbarState = initialState,
  action: any
): SnackbarState => {
  switch (action.type) {
    case "SNACKBAR_MESSAGE":
      if (action.payload)
        return {
          message: action.payload,
          isActive: true
        };
      return {
        ...localState,
        isActive: false
      };
    default:
      return localState;
  }
};
