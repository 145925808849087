import React, { Component, Fragment } from "react";

import locale from "service/locale";
import "./styles.scss";

type Props = {
  reason: string,
  validType: boolean,
};

class AppointmentReason extends Component<Props> {
  render() {
    const { reason, validType } = this.props;
    return (
      <Fragment>
        {validType ? (
          <Fragment>
          <div className="patient-care-block appointment-reason-wrap">
            <h3 className="patient-care-title-in-row">
              {locale.BookAppointment.appointmentReason}
            </h3>
            <div className="patient-care-appointment-reason">
              {reason
                ? reason
                : locale.BookAppointment.appointmentReasonNotProvided}
            </div>
          </div>
          <hr className="patient-care-separator-small" />
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default AppointmentReason;
