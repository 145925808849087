import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

import * as RolesConstants from "constants/RolesConstants";
import { setBranchName, setBranchId, setOrganisationId } from "actions/calendarView";
import { setOrganisationHeader, setBranchHeader } from "actions/router";
import { getOrganisationsForAsyncSelect } from "actions/organisations";
import { setDropdownOrganisation, setDropdownBranch } from "actions/calendarDropdown";
import { closeSlots, closeBooking, closeInternalEvent } from "actions/panel";
import { modifyBranchesList } from "helpers/common";
import type { Organisation, Branch } from "types/organisation";
import type { Action } from "types/actions";

import * as RoutesConstants from 'constants/RoutesConstants';

import locale from "service/locale";

type Props = {
  currentRole: any,
  organisations: Organisation[],
  handleNavigateToCalendar: (value: Branch) => any,
  setBranchName: (branchName: string) => any,
  setOrganisationId: (organisationId: string) => any,
  setBranchId: (branchId: string) => any,
  calendarOrganisationId: string,
  userOrganisationId?: string,
  userBranchId?: string,
  setOrganisationHeader: (organisationId: string) => any,
  setBranchHeader: (branchId: string) => any,
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
  closeSlots: () => Action,
  setDropdownOrganisation: (organisation: Organisation) => Action,
  setDropdownBranch: (branch: Branch) => Action,
  selectedOrganisation: Organisation,
  selectedBranch: Branch,
  profileOrganisation: Organisation,
  closeInternalEvent: () => Action,
  closeBooking: () => Action
};

type State = {
  searchValue: string,
  isRedirect: boolean
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  organisations: state.organisations.organisationsListAsync,
  selectedOrganisation: state.calendarDropdown.selectedOrganisation,
  selectedBranch: state.calendarDropdown.selectedBranch,
  profileOrganisation: state.profile.organisation
});

const mapDispatchToProps = (dispatch: Function) => ({
  setBranchName: (branchName) => dispatch(setBranchName(branchName)),
  setOrganisationId: (organisationId) => dispatch(setOrganisationId(organisationId)),
  setBranchId: (branchId) => dispatch(setBranchId(branchId)),
  setOrganisationHeader: (organisationId) => dispatch(setOrganisationHeader(organisationId)),
  setBranchHeader: (branchId) => dispatch(setBranchHeader(branchId)),
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
  closeSlots: () => dispatch(closeSlots()),
  closeBooking: () => dispatch(closeBooking()),
  closeInternalEvent: () => dispatch(closeInternalEvent()),
  setDropdownOrganisation: (organisation) => dispatch(setDropdownOrganisation(organisation)),
  setDropdownBranch: (branch) => dispatch(setDropdownBranch(branch)),
});

class CalendarDropdown extends Component<Props, State> {

  state = {
    searchValue: "",
    isRedirect: false,
  };

  componentDidMount = () => {
    const { getOrganisationsForAsyncSelect } = this.props;
    getOrganisationsForAsyncSelect();
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { userOrganisationId, userBranchId, profileOrganisation } = this.props;

    if (userOrganisationId) {
      this.handleOrganisationChange(profileOrganisation);
    } else if (userBranchId) {
      const branch = (profileOrganisation.branches && profileOrganisation.branches.find(branch => branch.id === userBranchId)) || {};
      this.handleBranchChange(branch);
    }
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchValue
      }, () => {
        if (searchValue && searchValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  handleOrganisationChange = value => {
    const { setBranchHeader, setDropdownOrganisation, currentRole, profileOrganisation, setDropdownBranch, setBranchName } = this.props;
    const isOrgAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;

    setDropdownOrganisation(value);

    if (isOrgAdmin && profileOrganisation.branches && profileOrganisation.branches.length === 1) {
      setBranchHeader(profileOrganisation.branches[0].id);
      setDropdownBranch(profileOrganisation.branches[0]);
      setBranchName(profileOrganisation.branches[0].name);
    }
  };

  handleBranchChange = value => {
    const {
      setBranchName,
      setOrganisationId,
      setBranchId,
      handleNavigateToCalendar,
      setOrganisationHeader,
      setBranchHeader,
      closeSlots,
      setDropdownBranch,
      selectedOrganisation,
      closeBooking,
      closeInternalEvent
    } = this.props;

    this.setState({
      isRedirect: true
    }, () => {
      setDropdownBranch(value);
      setBranchName(value.name);
      setOrganisationId(selectedOrganisation.id || selectedOrganisation.organisationId);
      setBranchId(value.id);
      setOrganisationHeader(selectedOrganisation.id || selectedOrganisation.organisationId);
      setBranchHeader(value.id);
      closeSlots();
      closeBooking();
      closeInternalEvent();
      handleNavigateToCalendar(value);
    });
  };

  handleLoadOptions = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  render() {
    const { organisations, userOrganisationId, selectedOrganisation } = this.props;
    const { searchValue, isRedirect } = this.state;
    const branchesList = selectedOrganisation ? modifyBranchesList({ items: selectedOrganisation.branches || [] }) : [];

    return isRedirect
      ? <Redirect to={RoutesConstants.CALENDAR} />
      : (
        <div className={`patient-care-dropdown-wrap ${userOrganisationId ? "small" : ""}`}>
          <div className="patient-care-dropdown-content">
            <div className="patient-care-row">
              {
                !userOrganisationId ? (
                  <div className="patient-care-col-6">
                    <Form noValidate>
                      <FormItem type="item">
                        <InputLabel
                          htmlFor="organisation_select"
                          message={locale.Header.labelOrganisation}
                          size="small"
                        />
                      </FormItem>
                    </Form>
                    <AsyncSelect
                      defaultOptions={organisations}
                      id="organisation-select"
                      inputId="organisation-select"
                      name="organisation-select"
                      loadOptions={this.handleLoadOptions}
                      placeholder={locale.AddUser.organisationSelect}
                      onChange={this.handleOrganisationChange}
                      onInputChange={this.handleSearchChange}
                      classNamePrefix="patient-care"
                      inputValue={searchValue}
                      openMenuOnFocus={true}
                    />
                  </div>
                ) : null
              }
              <div className={userOrganisationId ? "patient-care-col-12" : "patient-care-col-6"}>
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="branch_select"
                      message={locale.Header.labelBranch}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <Select
                  id="branch-select"
                  inputId="branch-select"
                  name="branch-select"
                  options={branchesList.filter(branch => branch.value)}
                  placeholder={locale.AddUser.branchSelect}
                  onChange={this.handleBranchChange}
                  isDisabled={!selectedOrganisation.organisationId && !selectedOrganisation.id}
                  classNamePrefix="patient-care"
                />
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDropdown);
