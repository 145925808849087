import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Select from 'react-select';
import { createSelector } from 'reselect';
import { ButtonRemove } from 'components/Share/button';
import IconDragVerical from 'svg/IconDragVerical';
import locale from 'service/locale';
import arrayMove from 'array-move';
import { updateForm } from "actions/form";

const getServices = state => state.branchDetails.services;

export const getAllServices = createSelector(
  [getServices],
  services => {
    return services && services.map((service) => {
      service.value = service.id;
      service.serviceId = service.id;
      service.label = service.name;

      return service;
    });
  },
);

type Props = {
  isAbleToEdit: boolean,
  isEditMode: boolean,

  organisationId: string,

  form: Object,
  updateForm: (data: any) => Action,

  servicesForFeatured: any[],
  isServicesPending: boolean,
};

const mapStateToProps = (state) => ({
  form: state.form,
  branchDetails: state.branchDetails,
  servicesForFeatured: getAllServices(state),
  isServicesPending: state.enabledServices.isServicesPending,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: data => dispatch(updateForm(data)),
});

const SortableList = SortableContainer(({ children }) => <Fragment>{children}</Fragment>);
const SortableItem = SortableElement(({ children }) => <Fragment>{children}</Fragment>);
const SortableDragHandle = SortableHandle(() => {
  return (
    <i className="move">
      <IconDragVerical outline={false} />
    </i>
  );
});

class FeaturedServices extends Component<Props> {
  handleFormChange = (e: any) => {
    const { updateForm } = this.props;
    const {
      target: { name, value }
    } = e;
    updateForm({ isDataChanged: true, [name]: value });
  };

  handleAddFeaturedService = (e) => {
    e.preventDefault();
    const { form, branchDetails } = this.props;

    const services = (form.branch_featuredServices === undefined ?
      (branchDetails.featuredServices && branchDetails.featuredServices.services) :
      form.branch_featuredServices) || [];
    services.push({ name: null, id: null });

    this.handleChange(services);
  };

  handleChange = (value) => {
    var e = { target: { name: 'branch_featuredServices', value: value } };
    this.handleFormChange(e);
  }

  getDropDownList = () => {
    const { servicesForFeatured, form, branchDetails } = this.props;
    const selectedServices = form.branch_featuredServices ||
      (branchDetails.featuredServices && branchDetails.featuredServices.services);
    const selectedIds = selectedServices && selectedServices.map(s => s.serviceId);

    if (selectedIds && selectedIds.length > 0) {
      return servicesForFeatured && servicesForFeatured.filter(x => !selectedIds.includes(x.serviceId));
    }

    return servicesForFeatured;
  }


  handleDropDownChange = (e, index) => {
    const { servicesForFeatured, form, branchDetails } = this.props;

    const services = form.branch_featuredServices === undefined ?
      (branchDetails.featuredServices && branchDetails.featuredServices.services) :
      form.branch_featuredServices;
    const service = servicesForFeatured.find(option => option.serviceId === e.value);
    if (service) {
      services[index] = service;

      this.handleChange(services);
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { form, branchDetails } = this.props;
    let services = form.branch_featuredServices === undefined ?
      (branchDetails.featuredServices && branchDetails.featuredServices.services) :
      form.branch_featuredServices;
    services = arrayMove(services, oldIndex, newIndex);
    this.handleChange(services);
  };

  handleRemoveButtonClick = (e, index) => {
    e.preventDefault();
    const { form, branchDetails } = this.props;

    const services = form.branch_featuredServices === undefined ?
      (branchDetails.featuredServices && branchDetails.featuredServices.services) :
      form.branch_featuredServices;
    services.splice(index, 1);

    this.handleChange(services);
  };

  renderAddButton = () => {
    const { servicesForFeatured, form, branchDetails } = this.props;
    const selectedServices = form.branch_featuredServices ||
      (branchDetails.featuredService && branchDetails.featuredService.services);
    const servicesCount = (servicesForFeatured && servicesForFeatured.length) || 0;
    const selectedServicesCount = (selectedServices && selectedServices.length) || 0;
    if (servicesCount > selectedServicesCount) {
      return (
        <div className="patient-care-featured-services">
          <Link to="#add" onClick={(e) => { this.handleAddFeaturedService(e); }}>{locale.BranchSettings.featuredServices.addFeaturedServices}</Link>
        </div>
      );
    }

    return null;
  }

  render() {
    const { isAbleToEdit, isEditMode, handleFeaturedServicesEditMode, form, branchDetails, servicesForFeatured } = this.props;
    const keys = Object.keys(form);
    const services = keys.includes("branch_featuredServices") ?
    form.branch_featuredServices :
    (branchDetails.featuredServices && branchDetails.featuredServices.services);

    const isEditSupported = isAbleToEdit && branchDetails.featuredServices && branchDetails.featuredServices.isBranchControlFeaturedService;

    const renderEditMode = () => {
      return (
        <Fragment>
          <SortableList onSortEnd={this.onSortEnd} lockAxis="y" useDragHandle>
            <div className="patient-care-sortable-wrapper">
              {services && services.map((service, index) => {
                return (
                  <SortableItem key={`item-${index}-${service.serviceId}`} index={index}>
                    <div key={`item-${index}`} className="patient-care-sortable-item">
                      <SortableDragHandle />
                      <div className="index">{index + 1}.</div>
                      <div className="value">
                        <Select
                          id="some_id"
                          name="some_name"
                          placeholder={locale.BranchSettings.featuredServices.addFeaturedServicesPlaceholder}
                          defaultValue={servicesForFeatured && servicesForFeatured.find(option => option.serviceId === service.serviceId)}
                          options={this.getDropDownList()}
                          classNamePrefix="patient-care"
                          className="patient-care-select"
                          onChange={(e) => this.handleDropDownChange(e, index)}
                        />
                      </div>
                      <ButtonRemove onClick={(e) => this.handleRemoveButtonClick(e, index)} />
                    </div>
                  </SortableItem>
                )
              })}
            </div>
          </SortableList>
          {this.renderAddButton()}
        </Fragment>
      );
    }

    const renderViewMode = () => {
      if (services && services.length > 0) {
        return (
          <div className="patient-care-featured-services">
            {services.map((service, index) => (
              <p key={index}>{index + 1}. {service.name}</p>
            ))}
          </div>
        );
      }
      return (
        <div className="patient-care-featured-services">
          <p className="info">{locale.BranchSettings.featuredServices.noFeaturedServices}</p>
        </div>
      );
    }

    return (
      <Fragment>
        <hr className="patient-care-separator" />
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.BranchSettings.featuredServices.header}</h3>
            {isEditSupported ? <button className="patient-care-btn-link" onClick={handleFeaturedServicesEditMode} >{isEditMode ? locale.Buttons.buttonDone : locale.Buttons.buttonEdit}</button> : null}
          </div>
          <p>{locale.BranchSettings.featuredServices.description}</p>
          {isEditMode ? renderEditMode() : renderViewMode()}
        </div>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(FeaturedServices);
