import { connect } from "react-redux";

import { loadUser } from "actions/profile";
import AADLoginCallback from "./AADLoginCallback";

const mapStateToProps = (state) => ({
  login: state.login,
  isAuth: state.login.isAuth,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (access_token) => dispatch(loadUser(access_token)),
});

const AADLoginCallbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AADLoginCallback);

export default AADLoginCallbackContainer;
