import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Icon, IconDelete } from "@patient-access/ui-kit";

import { updateForm } from "actions/form";
import locale from "service/locale";

import "./styles.scss";

type Props = {
  isAbleToEdit: boolean,
  organisationDetails: Organisation,
  handleRemoveLogo: Function,

  form: Object,
  updateForm: (data: any) => Action,
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
   updateForm: data => dispatch(updateForm(data)),
});

class OrganisationLogoUploader extends Component<Props, State> {

  handleImageChange = async (e) => {
    const { name, files } = (e && e.target && e.target) || { };
    const file = files && files[0];

    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        this.props.updateForm({
          isDataChanged: true,
          [name]: file,
          imagePreviewUrl: fileReader.result
        });
      }
    };
    fileReader.readAsDataURL(file);
  };

  handleRemoveLogoClick = (e) => {
    e && e.preventDefault();
    this.props.handleRemoveLogo();
  }

  renderImageSelector = () => {
    return (
      <div className="logo-wrap no-image">
        <label className="label-image-upload" htmlFor="company-logo-upload"            >
          <img
            src="/assets/images/company-logo/upload_image.svg"
            className="image-upload"
            alt="Company Logo uploader"
          />
        </label>
        <input
          id="company-logo-upload"
          className="company-logo-upload"
          type="file"
          accept="image/*"
          name="organisation_logo"
          onChange={this.handleImageChange}
        />
      </div>
    );
  }

  renderEditMode = (img) => {
    if (img) {
      return (
        <Fragment>
          <div className="logo-wrap" >
            <img
              src={img}
              alt=""
              id="company-logo"
              className="company-logo"
            />
            <div className="company-logo-delete">
              <span onClick={this.handleRemoveLogoClick}>
                <Icon
                  size="medium"
                  icon={<IconDelete outline />}
                  type="inline"
                />
              </span>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {this.renderImageSelector()}
      </Fragment>
    );
  };

  renderReadMode = (img) => {
    if (img) {
      return (
        <div className="logo-wrap" >
          <img
            src={img}
            alt=""
            id="company-logo"
            className="company-logo"
          />
        </div>
      );
    }

    return (
      <div className="logo-wrap">
        <span className="no-image-message">No logo uploaded</span>
      </div>
    );
  };

  handleCompanyLogoEditMode = (e: any) => {
    e && e.preventDefault();
    this.props.updateForm({ isEditCompanyLogo: !this.props.form.isEditCompanyLogo });
  };

  renderLogoActions = (isAbleToEdit) => {
    const { buttonDone, buttonEdit } = locale.OrganisationsSettings.organisationDetails;
    if (!isAbleToEdit) return null;
    return (
      <button className="patient-care-btn-link" onClick={this.handleCompanyLogoEditMode} >
        {this.props.form.isEditCompanyLogo ? buttonDone : buttonEdit}
      </button>
    );
  };

  renderLogoDetails = (isAbleToEdit) => {
    const { line1, line2 } = locale.OrganisationsSettings.organistionLogoDescription;
    if (!isAbleToEdit) return null;
    return (
      <div className="company_logo_details">
        <p>{line1}</p>
        <p>{line2}</p>
      </div>
    );
  };

  render() {
    const { form, isAbleToEdit, organisationDetails } = this.props;
    const { imagePreviewUrl } = form;
    const img = imagePreviewUrl || (organisationDetails && organisationDetails.logo);

    const feature = organisationDetails.supportedFeatures && organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy')
    if (!(feature && feature.isEnabled)) return null;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.OrganisationsSettings.organisationLogoTitle}</h3>
            {this.renderLogoActions(isAbleToEdit)}
          </div>
          {this.renderLogoDetails(isAbleToEdit)}
          {form.isEditCompanyLogo ? this.renderEditMode(img) : this.renderReadMode(img)}
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganisationLogoUploader);