import * as CalendarSearchConstants from 'constants/CalendarSearchConstants';
import type { CalendarSearch } from 'types/calendarSearch';

const initialState: CalendarSearch = {
  calendars: {
    items: [],
    totalCount: 0,
    itemsPerPage: 0,
    pageNumber: 0,
    pages: 0,
    isEmpty: true,
  },
  services: {
    items: [],
  },
  filters: {
    query: "",
    branchIds: [],
    serviceIds: [],
  },
  isGetPending: false,
};

export default (localState: CalendarSearch = initialState, action: Action): CalendarSearch => {
  switch (action.type) {
    case CalendarSearchConstants.SET_CALENDAR_SEARCH_FILTER:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          ...action.payload
        }
      };
    case CalendarSearchConstants.CLEAR_CALENDAR_SEARCH_FILTER:
      return {
        ...localState,
        filters: {
          ...initialState.filters
        }
      };
    case CalendarSearchConstants.SET_CALENDAR_SEARCH_ITEMS:
      const { calendarsList } = action.payload;
      const { items, totalCount, itemsPerPage, pageNumber, pages, isEmpty } = calendarsList;
      return {
        ...localState,
        calendars: {
          items,
          totalCount,
          itemsPerPage,
          pageNumber,
          pages: pages === 0 ? localState.calendars.pages : pages,
          isEmpty
        }
      };
    case CalendarSearchConstants.SET_CALENDAR_SEARCH_SERVICES:
      const { servicesList } = action.payload;
      return {
        ...localState,
        services: {
          items: servicesList
        }
      };
    case CalendarSearchConstants.GET_CALENDAR_SEARCH_PENDING:
      return {
        ...localState,
        isGetPending: true
      };
    case CalendarSearchConstants.GET_CALENDAR_SEARCH_SUCCESS:
      return {
        ...localState,
        isGetPending: false
      };
    case CalendarSearchConstants.GET_CALENDAR_SEARCH_ERROR:
      return {
        ...localState,
        isGetPending: false
      };
    default:
      return localState
  }
}
