import React, { Component } from 'react';
import './styles/reports-sidebar-menu.scss';

type Props = {
  children: any,
};

class ReportsSidebarMenu extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="reports-sidebar-menu">
        {children}
      </div>
    );
  }
};

export default ReportsSidebarMenu;
