import React, { Component } from 'react';
import './styles.scss';

type Props = {
  className: string,
  children: any,
};

class Wrapper extends Component<Props> {
  render() {
    const { className, children } = this.props;
    return (
      <div className={className ? `wrapper ${className}` : `wrapper`}>
        {children}
      </div>
    );
  }
}

export default Wrapper;
