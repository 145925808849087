import React, { Component } from 'react';
import './styles/calendar-filters-body.scss';

type Props = {
  isExpanded: boolean,
  children: any,
};

class CalendarFiltersBody extends Component<Props> {
  render() {
    const { isExpanded, children } = this.props;
    return (
      <div className={`calendar-filters-body ${isExpanded ? 'calendar-filters-body-expanded' : 'calendar-filters-body-collapsed'}`}>
        {children}
      </div>
    );
  }
};

export default CalendarFiltersBody;
