import type { Action } from 'types/actions';
import agent from 'service/agent';
import { closeAddOrganisation, openAddOrganisationError, openDeleteOrganisationConflictOverlay } from 'actions/panel';
import { clearForm } from 'actions/form';
import { showSnackbarStatus } from 'actions/snackbar';
import { setOrganisationHeader } from "actions/router";
import { uploadBlobBlock, deleteBlobBlock } from "actions/storage";
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as BranchesConstants from 'constants/BranchesConstants';
import type { Organisation } from 'types/organisation';
import { getFilteredOrganisations } from "./organisations";
import { getToken } from 'helpers/common';
import { checkDuplicateError } from 'helpers/validateData';
import { setOrganisationSupportedFeaturesData, setOrganisationSupportedFeaturesDataClone } from 'actions/organisationSupportedFeatures';

import locale from 'service/locale';

export const setOrganisationDetailsInitialState = (): Action => ({
  type: OrganisationsConstants.SET_INITIAL_STATE,
});

export const setOrganisationDetails = (organisationDetails: Organisation): Action => ({
  type: OrganisationsConstants.SET_ORGANISATIONS_DETAILS,
  payload: organisationDetails
});

export const setPasswordConfirmationStatus = (status: string): Action => ({
  type: OrganisationsConstants.SET_DELETE_ORGANISATION_CONFIRMATION_STATUS,
  payload: status
});

const updateOrganisationsList = (organisationsList: Organisation[]): Action => ({
  type: OrganisationsConstants.UPDATE_ORGANISATIONS_LIST,
  payload: organisationsList
});

const createOrganisationPending = (): Action => ({
  type: OrganisationsConstants.CREATE_ORGANISATION_PENDING,
});

const createOrganisationSuccess = (): Action => ({
  type: OrganisationsConstants.CREATE_ORGANISATION_SUCCESS,
});

const createOrganisationError = (): Action => ({
  type: OrganisationsConstants.CREATE_ORGANISATION_ERROR,
});

const updatePayoutSettingsPending = (): Action => ({
  type: OrganisationsConstants.UPDATE_PAYOUT_SETTINGS_PENDING,
});

const updatePayoutSettingsSuccess = (): Action => ({
  type: OrganisationsConstants.UPDATE_PAYOUT_SETTINGS_SUCCESS,
});

const updatePayoutSettingsError = (): Action => ({
  type: OrganisationsConstants.UPDATE_PAYOUT_SETTINGS_ERROR,
});

const getOrganisationDetailsPending = (): Action => ({
  type: OrganisationsConstants.GET_ORGANISATIONS_DETAILS_PENDING,
});

const getOrganisationDetailsSuccess = (): Action => ({
  type: OrganisationsConstants.GET_ORGANISATIONS_DETAILS_SUCCESS,
});

const getOrganisationDetailsError = (): Action => ({
  type: OrganisationsConstants.GET_ORGANISATIONS_DETAILS_ERROR,
});

export const deleteOrganisationPending = (): Action => ({
  type: OrganisationsConstants.DELETE_ORGANISATION_PENDING,
});

export const deleteOrganisationSuccess = (): Action => ({
  type: OrganisationsConstants.DELETE_ORGANISATION_SUCCESS,
});

export const deleteOrganisationError = (): Action => ({
  type: OrganisationsConstants.DELETE_ORGANISATION_ERROR,
});

const updateSettingsPending = (): Action => ({
  type: OrganisationsConstants.UPDATE_SETTINGS_PENDING,
});

const updateSettingsSuccess = (): Action => ({
  type: OrganisationsConstants.UPDATE_SETTINGS_SUCCESS,
});

const updateSettingsError = (): Action => ({
  type: OrganisationsConstants.UPDATE_SETTINGS_ERROR,
});

const updateDetailsPending = (): Action => ({
  type: OrganisationsConstants.UPDATE_DETAILS_PENDING,
});

const updateDetailsSuccess = (): Action => ({
  type: OrganisationsConstants.UPDATE_DETAILS_SUCCESS,
});

const updateDetailsError = (): Action => ({
  type: OrganisationsConstants.UPDATE_DETAILS_ERROR,
});

export const setOrganisationConfirmationStatus = (status: string): Action => ({
  type: OrganisationsConstants.SET_ORGANISATION_CONFIRMATION_STATUS,
  payload: status
});

export const openEmptyBookingUrlWarning = (): Action => ({
  type: OrganisationsConstants.OPEN_EMPTY_BOOKING_URL_WARNING
});

export const closeEmptyBookingUrlWarning = (): Action => ({
  type: OrganisationsConstants.CLOSE_EMPTY_BOOKING_URL_WARNING
});

export const openTwoFactorModal= (): Action => ({
  type: OrganisationsConstants.OPEN_TWO_FACTOR_MODAL
});

export const closeTwoFactorModal = (): Action => ({
  type: OrganisationsConstants.CLOSE_TWO_FACTOR_MODAL
});

export const getOrganisationDetails = (organisationId: string) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(getOrganisationDetailsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getOrganisationDetails(organisationId, accessToken)
        .then(organisationDetails => {
          organisationDetails.value = organisationDetails.organisationId;
          dispatch(setOrganisationDetails(organisationDetails));
          dispatch(setOrganisationSupportedFeaturesData(organisationDetails));
          dispatch(setOrganisationSupportedFeaturesDataClone(organisationDetails.supportedFeatures));
          dispatch(getOrganisationDetailsSuccess());
        })
        .catch(err => {
          dispatch(getOrganisationDetailsError());
          console.log('getOrganisationDetails server error or organisation is not found', err);
        });
    });

};

export const createOrganisation = (organisation: any) => (dispatch: Function) => {
  const { organisationType, twoFactorValue  } = organisation;
  if (organisationType)
    organisation.organisationType = organisation.organisationType.type;

  if(twoFactorValue)
    organisation.twoFactorValue = organisation.twoFactorValue.value ? true: false;

  dispatch(createOrganisationPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.createOrganisation(organisation, accessToken)
        .then(() => {
          const pageNumber = 1;
          dispatch(createOrganisationSuccess());
          dispatch(getFilteredOrganisations(pageNumber, ""));
          dispatch(closeAddOrganisation());
          dispatch(clearForm());
          dispatch(showSnackbarStatus(locale.Snackbar.createOrganisation));
        })
        .catch(err => {
          dispatch(createOrganisationError());
          if (checkDuplicateError(err)) {
            dispatch(openAddOrganisationError());
            return;
          }
          console.log('createOrganisation ', err.response && err.response.body);
          dispatch(closeAddOrganisation());
          dispatch(clearForm());
        })
    });

};

export const updateOrganisationDetails = (organisationId: string, OrganisationData: Object) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const formData = currentState.form;

  const {
    organisation_name,
    organisation_address_1,
    organisation_address_2,
    organisation_city,
    organisation_postcode,
    organisation_country,
    organisation_logoUrl,
    organisation_featuredServices,
    organisation_featuredServices_BranchControl,
    organisation_are_all_smartprofiles_visible,
  } = formData;

  const sortedOragnisation_featuredServices = (organisation_featuredServices &&
    organisation_featuredServices.map((service, index) => {
      return {
        index: index + 1,
        serviceId: service.serviceId,
        name: service.name,
      };
    })
  );

  const keys = Object.keys(formData);


  const { name, companyAddress, logo, featuredService, areAllSmartProfilesVisible } = OrganisationData;
  const { services, isBranchControlFeaturedService } = featuredService || {};
  const newOrganisationData = {
    name: keys.includes("organisation_name") ? organisation_name : name,
    address: {
      country: keys.includes("organisation_country") ? organisation_country : companyAddress.country,
      postcode: keys.includes("organisation_postcode") ? organisation_postcode : companyAddress.postcode,
      town: keys.includes("organisation_city") ? organisation_city : companyAddress.town,
      addressLine1: keys.includes("organisation_address_1") ? organisation_address_1 : companyAddress.addressLine1,
      addressLine2: keys.includes("organisation_address_2") ? organisation_address_2 : companyAddress.addressLine2
    },
    logo: keys.includes("organisation_logoUrl") ? organisation_logoUrl : logo,
    featuredService: {
     services: keys.includes("organisation_featuredServices") ? sortedOragnisation_featuredServices : services,
     isBranchControlFeaturedService: keys.includes("organisation_featuredServices_BranchControl") ? organisation_featuredServices_BranchControl : isBranchControlFeaturedService,
    },
    areAllSmartProfilesVisible: keys.includes("organisation_are_all_smartprofiles_visible") ? organisation_are_all_smartprofiles_visible : areAllSmartProfilesVisible,
  };

  dispatch(updateDetailsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.updateOrganisationDetails(organisationId, newOrganisationData , accessToken)
        .then(() => {
          dispatch(setOrganisationConfirmationStatus(OrganisationsConstants.ORGANISATION_DETAILS_UPDATE_STATUS.UPDATED));
          dispatch(showSnackbarStatus(locale.Snackbar.organisationDetailsUpdated));
          dispatch(clearForm());
          dispatch(updateDetailsSuccess());
        })
        .catch(err => {
          dispatch(updateDetailsError());
          console.log('Update Organisaton details server error or branch is not found ', err.response && err.response.body);
          dispatch(clearForm());
          dispatch(updateDetailsError());
        })
    });
};

export const deleteOrganisationLogo = (organisationId: string, organisationData: Object) => (dispatch: Function, getState: Function) => {
  const newOrganisationData = { ...organisationData, address: organisationData.companyAddress, logo: '' };
  dispatch(updateDetailsPending());
  return getToken(dispatch)
    .then(accessToken => {
      return agent.Organisations.updateOrganisationDetails(organisationId, newOrganisationData , accessToken)
        .then(() => {
          dispatch(setOrganisationConfirmationStatus(OrganisationsConstants.ORGANISATION_DETAILS_UPDATE_STATUS.UPDATED));
          dispatch(showSnackbarStatus(locale.Snackbar.organisationLogoRemoved));
          dispatch(clearForm());
          dispatch(updateDetailsSuccess());
          return true;
        })
        .catch(err => {
          dispatch(updateDetailsError());
          dispatch(clearForm());
          dispatch(updateDetailsError());
          return false;
        })
    });
};

export const deleteOrganisation = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();

  dispatch(deleteOrganisationPending());
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setPasswordConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING));
  getToken(dispatch)
    .then(accessToken => {
          agent.Organisations.deleteOrganisation(organisationId, accessToken)
            .then(() => {
              const removedOrganisationIndex = currentState.organisations.organisationsList.findIndex(organisation => organisation.id === organisationId);
              const newOrganisationsList = [
                ...currentState.organisations.organisationsList.slice(0, removedOrganisationIndex),
                ...currentState.organisations.organisationsList.slice(removedOrganisationIndex + 1)
              ];
              dispatch(deleteOrganisationSuccess());
              dispatch(updateOrganisationsList(newOrganisationsList));
              dispatch(setPasswordConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED));
              dispatch(showSnackbarStatus(locale.Snackbar.organisationDeleted));
            })
            .catch(err => {
              dispatch(deleteOrganisationError());
              if (err.status === 400) {
                let { result: appointments } = err.response.body;
                if (appointments) {
                  dispatch(openDeleteOrganisationConflictOverlay(appointments));
                  dispatch(setPasswordConfirmationStatus(null));
                  return;
                }
              }

              dispatch(setPasswordConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED));
              dispatch(showSnackbarStatus(locale.Snackbar.organisationNotDeleted));
              console.log('deleteOrganisation server error or organisation is not found', err);
            });
        })
        .catch(err => {
          dispatch(deleteOrganisationError());
          if (err && err.response) {
            const isLastAttempt = err.response.body.NextUnsuccessfulAttemptWillLockUser;
            if (err.response.body.ErrorCode === 2)
              dispatch(setPasswordConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.BLOCKED));
            else
              dispatch(setPasswordConfirmationStatus(isLastAttempt ?
                BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.LAST_ATTEMPT :
                BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.WRONG));
          } else
            console.log('Confirm password server error', err);
        });

};

export const updateOrganisationPayoutSettings = (organisationId: string, settings: any) => (dispatch: Function, getState: Function) => {
  const organisationDetails = getState().organisationDetails;
  dispatch(setOrganisationHeader(organisationId));
  dispatch(updatePayoutSettingsPending());
  const { paymentMethod } = settings;

  if (paymentMethod) {
    settings.paymentMethod = OrganisationsConstants.PAYMENT_METHOD[1].enumValue;
  } else if (paymentMethod === false) {
    settings.paymentMethod = OrganisationsConstants.PAYMENT_METHOD[2].enumValue;
  } else {
    settings.paymentMethod = OrganisationsConstants.PAYMENT_METHOD.find(method => method.id === organisationDetails.payment.paymentMethod).enumValue;
  }

  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.updatePayoutSettings(organisationId, settings, accessToken)
        .then(() => {
          dispatch(getOrganisationDetails(organisationId));
          dispatch(updatePayoutSettingsSuccess());
          dispatch(clearForm());
          dispatch(showSnackbarStatus(locale.Snackbar.payoutSettingsUpdated));
        })
        .catch(err => {
          dispatch(updatePayoutSettingsError());
          dispatch(clearForm());
          console.log('Update payout settings server error', err);
        });
    })
};


export const updateOrganisationSettings = (organisationId: string, showEmptyURLWarning: boolean) => (dispatch: Function, getState: Function) => {
  const form = getState().form;

  const bookingPoint = form.booking && form.booking.point;
  const keys =  bookingPoint && Object.keys(form.booking.point);
  const isEmptyBookingUrl = (bookingPoint && form.booking.point.url && !form.booking.point.url.length) === "";
  const isEditedBookingUrl = bookingPoint && keys.includes('url');

  if (showEmptyURLWarning && isEditedBookingUrl && isEmptyBookingUrl) {
    dispatch(openEmptyBookingUrlWarning());
    return;
  }
  dispatch(updateSettingsPending());
  getToken(dispatch)
    .then(accessToken => {
      Promise.all([
        agent.Organisations.updateOrganisation(organisationId, form, accessToken),
        agent.Organisations.updateOrganisationMFASetting(organisationId, form, accessToken)

      ])
        .then(() => {
          dispatch(getOrganisationDetails(organisationId));
          dispatch(closeEmptyBookingUrlWarning());
          dispatch(updateSettingsSuccess());
          dispatch(setPasswordConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.UPDATED));
          dispatch(clearForm());
          dispatch(closeTwoFactorModal());
          dispatch(showSnackbarStatus(locale.Snackbar.organisationSettingsUpdated));
        })
        .catch(err => {
          dispatch(closeEmptyBookingUrlWarning());
          dispatch(updateSettingsError());
          dispatch(clearForm());
          console.log('Update settings server error', err);
        });
    });
};

export const uploadLogoToBlob = (file, fileName) => async (dispatch: Function) => {
  dispatch(updateDetailsPending());
  try {
    const accessToken = await getToken(dispatch);
    const options = await agent.Organisations.getStorageOptions(accessToken);
    const url = await uploadBlobBlock(file, options, fileName);
    return url;
  }
  catch {
    return null;
  }
};

export const deleteLogoFromBlob = (logoUrl) => async (dispatch: Function) => {
  try {
    const accessToken = await getToken(dispatch);
    const options = await agent.Organisations.getStorageOptions(accessToken);
    await deleteBlobBlock(logoUrl, options);
  }
  catch {
    return null;
  }
};
