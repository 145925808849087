import React, { Component } from "react";
import { FeatureContentTable } from "components/Share/feature";

import EnabledServicesTableRow from './EnabledServicesTableRow'
import "./styles.scss";
import locale from "service/locale";

type Props = {
  data: any[],
  isEditMode: boolean,
  isSorting: boolean,
  handleDataChanged: () => any,
};

class EnabledServiceTableBody extends Component<Props> {
  sortList = (el1: any, el2: any) => {
    const { isSorting } = this.props;
    if (el1.isActive === false && el1.name < el2.name) return -1;
    if (el2.isActive === false && el1.name > el2.name) return 1;
    if (el1.isActive === false) return -1;
    if (el2.isActive === false) return 1;
    if (el1.name > el2.name) return isSorting ? -1 : 1;
    if (el1.name < el2.name) return isSorting ? 1 : -1;

    return 0;
  };

  render() {
    const {
      data,
      isEditMode,
      handleDataChanged,
    } = this.props;
    return (
      <FeatureContentTable>
        <colgroup>
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.name.width }} />
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.enabledService.width }} />
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.modes.width }} />
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.edit.width }} />
        </colgroup>
        <tbody>
          {data.sort(this.sortList).map((row) => {
            return (
              <EnabledServicesTableRow
                key={row.id}
                row={row}
                isEditMode={isEditMode}
                checked={row.enabled ? row.enabled : false}
                handleDataChanged={handleDataChanged}
              />
            );
          })}
        </tbody>
      </FeatureContentTable>
    );
  }
}

export default EnabledServiceTableBody;
