import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconAgenda extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="M4,10.5 C3.17,10.5 2.5,11.17 2.5,12 C2.5,12.83 3.17,13.5 4,13.5 C4.83,13.5 5.5,12.83 5.5,12 C5.5,11.17 4.83,10.5 4,10.5 Z M4,4.5 C3.17,4.5 2.5,5.17 2.5,6 C2.5,6.83 3.17,7.5 4,7.5 C4.83,7.5 5.5,6.83 5.5,6 C5.5,5.17 4.83,4.5 4,4.5 Z M4,16.5 C3.17,16.5 2.5,17.18 2.5,18 C2.5,18.82 3.18,19.5 4,19.5 C4.82,19.5 5.5,18.82 5.5,18 C5.5,17.18 4.83,16.5 4,16.5 Z M9.5,19 L20.5,19 C21.0522847,19 21.5,18.5522847 21.5,18 C21.5,17.4477153 21.0522847,17 20.5,17 L9.5,17 C8.94771525,17 8.5,17.4477153 8.5,18 C8.5,18.5522847 8.94771525,19 9.5,19 Z M9.5,13 L20.5,13 C21.0522847,13 21.5,12.5522847 21.5,12 C21.5,11.4477153 21.0522847,11 20.5,11 L9.5,11 C8.94771525,11 8.5,11.4477153 8.5,12 C8.5,12.5522847 8.94771525,13 9.5,13 Z M8.5,6 C8.5,6.55228475 8.94771525,7 9.5,7 L20.5,7 C21.0522847,7 21.5,6.55228475 21.5,6 C21.5,5.44771525 21.0522847,5 20.5,5 L9.5,5 C8.94771525,5 8.5,5.44771525 8.5,6 Z" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-agenda ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconAgenda;
