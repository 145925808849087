import * as ProfileConstants from "./ProfileConstants";

export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_USERS_PENDING = "SET_USERS_PENDING";

export const SET_USERS_LIST_DATA = "SET_USERS_LIST_DATA";
export const GET_USERS_LIST_PENDING = "GET_USERS_LIST_PENDING";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";

export const ELEMENTS_PER_PAGE = 30;
export const DUPLICATE_ERROR_CODES = {
  organisation: 2,
  branch: 3,
  branchName: "DuplicateBranchName",
  odsCode: "BranchOdsCodeNonUnique"
};

export const USERS_FILTER_OPTIONS = [
  {
    label: ProfileConstants.USER_FILTERS.all.title,
    value: ProfileConstants.USER_FILTERS.all
  },
  {
    label: ProfileConstants.USER_FILTERS.admins.title,
    value: ProfileConstants.USER_FILTERS.admins
  },
  {
    label: ProfileConstants.USER_FILTERS.orgAdmins.title,
    value: ProfileConstants.USER_FILTERS.orgAdmins
  },
  {
    label: ProfileConstants.USER_FILTERS.branchAdmins.title,
    value: ProfileConstants.USER_FILTERS.branchAdmins
  },
  {
    label: ProfileConstants.USER_FILTERS.branchMembers.title,
    value: ProfileConstants.USER_FILTERS.branchMembers
  },
];

export const MFA_STATUS_FILTER_OPTIONS = [
  {
    value: 0,
    label: "All 2fa statuses"
  },
  {
    value: 1,
    label: "2fa not enabled"
  },
  {
    value: 2,
    label: "2fa enabled"
  }
]
