import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox } from '@patient-access/ui-kit';
import { CalendarFiltersSection, CalendarFiltersTitle, CalendarFiltersBody, CalendarFiltersBodyEmpty } from '../wrappers';
import { ButtonAdd, ButtonToggle } from '../button';
import SectionCalendarsItem from './SectionCalendarsItem';
import CalendarDetailsModal from 'components/Pages/Admin/BranchContent/BranchCalendars/CalendarDetailsModal/CalendarDetailsModal';
import AddNewCalendarModal from 'components/Pages/Admin/BranchContent/BranchCalendars/AddNewCalendarModal/AddNewCalendarModal';
import * as RolesConstants from 'constants/RolesConstants';
import { CALENDAR_UPDATED, CALENDAR_UPDATE_ERROR } from 'constants/CalendarsConstants';
import type { Action } from 'types/actions';
import { clearForm } from 'actions/form';
import { clearNewBranchServiceList } from 'actions/services';
import { openModalOverlay, closeModalOverlay } from 'actions/panel';
import { filterEventsByCalendars } from 'actions/calendarView';
import { setBranchConfirmationStatus, getBranchCalendarsList } from 'actions/branchDetails';
import locale from 'service/locale';

type Props = {
  currentRole: Object,
  organisationId: string,
  branchId: string,
  calendarsList: any[],
  calendarFilters: string[],
  branchCalendars: any[],
  isModalOverlayOpened: boolean,
  isCalendarUpdated: boolean,
  isCalendarUpdateError: boolean,
  clearForm: () => Action,
  clearNewBranchServiceList: () => Action,
  openModalOverlay: () => Action,
  closeModalOverlay: () => Action,
  filterEventsByCalendars: (calendar: Object) => Action,
  setBranchConfirmationStatus: (status: string) => Action,
  getBranchCalendarsList: (organisationId: string, branchId: string, includeCustom: boolean) => Function,
};

type State = {
  isCalendarOpened: boolean,
  isSectionExpanded: boolean,
  calendarId: string,
}

const mapStateToProps = state => ({
  currentRole: state.roles.profileCurrentRole,
  organisationId: state.router.organisationId,
  branchId: state.router.branchId,
  calendarsList: state.branchDetails.calendarsList,
  calendarFilters: state.calendarView.calendarFilters,
  branchCalendars: state.calendarView.branchResources,
  isModalOverlayOpened: state.panel.isModalOverlayOpened,
  isCalendarUpdated: state.branchDetails.confirmationStatus === CALENDAR_UPDATED,
  isCalendarUpdateError: state.branchDetails.confirmationStatus === CALENDAR_UPDATE_ERROR,
});

const mapDispatchToProps = dispatch => ({
  clearForm: () => dispatch(clearForm()),
  clearNewBranchServiceList: () => dispatch(clearNewBranchServiceList()),
  openModalOverlay: () => dispatch(openModalOverlay()),
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  setBranchConfirmationStatus: status => dispatch(setBranchConfirmationStatus(status)),
  getBranchCalendarsList: (organisationId, branchId, includeCustom) => dispatch(getBranchCalendarsList(organisationId, branchId, includeCustom)),
  filterEventsByCalendars: (calendar) => dispatch(filterEventsByCalendars(calendar)),
});

class SectionCalendars extends Component<Props, State> {
  state = {
    isCalendarOpened: false,
    isSectionExpanded: true, // TODO: Redux
    calendarId: '',
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { isCalendarUpdateError, isCalendarUpdated, organisationId, branchId, setBranchConfirmationStatus, getBranchCalendarsList } = nextProps;
    const includeCustom = true;
    if (isCalendarUpdateError || (isCalendarUpdated && !this.props.isCalendarUpdated) ) {
      this.setState({ isCalendarOpened: false }, () => {
        setBranchConfirmationStatus('');
        getBranchCalendarsList(organisationId, branchId, includeCustom);
      });
    }
  };

  handleSelectAll = (e: any) => {
    const { calendarsList, calendarFilters, filterEventsByCalendars, branchCalendars } = this.props;
    if (calendarFilters.length === 0) {
      calendarsList.forEach(calendar => {
        const selectedCalendar = branchCalendars.find(branchCalendar => branchCalendar.resourceId === calendar.id);
        filterEventsByCalendars(selectedCalendar);
      });
    } else {
      calendarFilters.forEach(filteredCalendar => {
        const selectedCalendar = branchCalendars.find(branchCalendar => branchCalendar.resourceId === filteredCalendar);
        filterEventsByCalendars(selectedCalendar)
      });
    }
  }

  handleDiscardChanges = () => {
    const { clearForm, clearNewBranchServiceList, closeModalOverlay } = this.props;
    this.setState({ isCalendarOpened: false }, () => {
      clearForm();
      clearNewBranchServiceList();
      closeModalOverlay();
    });
  };

  handleAddCalendar = (e?: Event) => {
    e && e.preventDefault();
    const { openModalOverlay } = this.props;
    openModalOverlay();
  };

  handleEditCalendar = (calendarId: string) => {
    this.setState({ isCalendarOpened: true, calendarId });
  };

  render() {
    const { currentRole, organisationId, branchId, calendarsList, calendarFilters, isModalOverlayOpened } = this.props;
    const { isSectionExpanded, isCalendarOpened, calendarId } = this.state;
    const existingCalendarNamesList = calendarsList.map(calendar => calendar.name);
    const isAbleToAddAndEdit = currentRole.role !== RolesConstants.BRANCH_MEMBER;
    const noSelectedCalendars = calendarsList.length - calendarFilters.length;

    const renderOverlayDetails = () => {
      if (isCalendarOpened) {
        return (
          <CalendarDetailsModal
            calendarId={calendarId}
            existingCalendarNamesList={existingCalendarNamesList}
            handleCloseModal={this.handleDiscardChanges}
            handleDiscardChanges={this.handleDiscardChanges}
          />
        );
      }
      return null;
    }

    const renderOverlayAdd = () => {
      if (isModalOverlayOpened) {
        return (
          <AddNewCalendarModal
            organisationId={organisationId}
            branchId={branchId}
            existingCalendarNamesList={existingCalendarNamesList}
            handleCloseModal={this.handleDiscardChanges}
            handleDiscardChanges={this.handleDiscardChanges}
          />
        );
      }
      return null;
    }

    const renderCalendarList = () => {
      if (calendarsList && calendarsList.length > 0) {
        return (
          <Fragment>
            {calendarsList.map(calendar => (
              <SectionCalendarsItem
                key={calendar.id}
                calendar={calendar}
                isAbleToAddAndEdit={isAbleToAddAndEdit}
                handleEditCalendar={this.handleEditCalendar}
              />
            ))}
          </Fragment>
        );
      }
      return (
        <CalendarFiltersBodyEmpty>
          <p>{locale.Sidebars.noCalendars}</p>
          {isAbleToAddAndEdit ? <Button buttonType="secondary" buttonLength="full" buttonSize="small" messageKey="some-key" defaultMessage={locale.Sidebars.createCalendars} onClick={this.handleAddCalendar} /> : null}
        </CalendarFiltersBodyEmpty>
      );
    }

    return (
      <Fragment>
        <CalendarFiltersSection>
          <CalendarFiltersTitle>
            <Checkbox
              size="small"
              id="section_calendars"
              name="section_calendars"
              value={calendarFilters.length === 0 ? 'true' : 'false'}
              label={`Calendars (${noSelectedCalendars})`}
              onChange={this.handleSelectAll}
              checked={calendarFilters.length === 0}
            />
            {isAbleToAddAndEdit ? <ButtonAdd onClick={this.handleAddCalendar} /> : null}
            <ButtonToggle onClick={(e) => { e.preventDefault(); this.setState({ isSectionExpanded: !isSectionExpanded }); }} isExpanded={isSectionExpanded} />
          </CalendarFiltersTitle>
          <CalendarFiltersBody isExpanded={isSectionExpanded}>
            {renderCalendarList()}
          </CalendarFiltersBody>
        </CalendarFiltersSection>
        {renderOverlayDetails()}
        {renderOverlayAdd()}
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCalendars);
