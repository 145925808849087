import type {
  Action
} from "types/actions";
import type {
  EnabledServicesState
} from 'types/enabledServices';
import * as EnabledServicesConstants from "constants/EnabledServicesConstants";
import {
  cloneDeep
} from "lodash";

const initialState: EnabledServicesState = {
  services: [],
  isServicesPending:false,
  isUpdateServicesPending: false,  
}

export default (localState: EnabledServicesState = initialState, action: Action): EnabledServicesState => {        
 switch (action.type) {    
     case EnabledServicesConstants.SET_ENABLED_SERVICE_DETAILS:
      const {
        payload
      } = action;      
      if (payload) {
        payload.servicesLoadedInitially = cloneDeep(payload.services);
        // start TODO: Make a separate api call to fetch dropdown service list
        let servicesCloned = cloneDeep(payload.services);
        servicesCloned = servicesCloned.map((s) => { s.value = s.id; s.label = s.name; return s; });
        payload.servicesForFeatured = servicesCloned;
        // end
      }
      return {
        ...localState, 
        ...payload,
        isServicesPending: false,
      };

     case EnabledServicesConstants.SET_ENABLED_SERVICE_DATA:
      const newEnabledServiceDetails = {
        ...localState
      };
      const editedServiceIndex = newEnabledServiceDetails.services.findIndex(
        service => service.id === action.payload.serviceId
      );
      newEnabledServiceDetails.services[editedServiceIndex] = {
        ...newEnabledServiceDetails.services[editedServiceIndex],
        ...action.payload.data
      };
      return newEnabledServiceDetails;

      case EnabledServicesConstants.SET_SERVICES_PENDING:
      return {
        ...localState,
        isServicesPending: true,
      };
      case EnabledServicesConstants.UPDATE_SERVICES_PENDING:
      return {
        ...localState,
        isUpdateServicesPending: true,
      };
      case EnabledServicesConstants.UPDATE_SERVICES_SUCCESS:
        return {
          ...localState,
          isUpdateServicesPending: false,
        };
      case EnabledServicesConstants.UPDATE_SERVICES_ERROR:
        return {
          ...localState,
          isUpdateServicesPending: false,
        }; 

      default:
      return localState;
 }
}