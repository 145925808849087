import React, { Component } from 'react';

import './styles/sidebar-body-content.scss';

type Props = {
  children: any,
  className?: string,
};

class SidebarBodyContent extends Component<Props> {
  render() {
    const { children, className } = this.props;
    return (
      <div className={`sidebar-body-content${className ? ` ${className}` : ''}`}>
        {children}
      </div>
    );
  }
};

export default SidebarBodyContent;
