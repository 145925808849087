import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import locale from 'service/locale';
import * as RoutesConstants from 'constants/RoutesConstants';

export default class AddSoleTraderHeader extends Component<Props, State> {
  render() {
    const { handleRegisteredCompany } = this.props;
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-block">
            <p>{locale.AddOrganisation.addOrganisationSearchHint}</p>
            <p>or <b><Link onClick={handleRegisteredCompany} to={RoutesConstants.HOME}>{locale.AddOrganisation.addRegisteredCompanyLink}</Link></b></p>
          </div>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

