import React, { Component } from 'react'
import {
  Overlay,
  Button,
} from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleDiscardChanges: () => any;
  handleSave: () => any;
  branchesCount: Number,
  isLoading: Boolean,
  isEditSmartProfileVisibility: Boolean,
  isEditFeaturedServices: Boolean
};

export default class SaveFeaturedServicesOverlay extends Component<Props> {
  render() {
    const { handleDiscardChanges, handleSave, branchesCount, isLoading, isEditSmartProfileVisibility, isEditFeaturedServices } = this.props;

    const renderOverlayTitle = () => {
      if (isEditFeaturedServices !== undefined && isEditSmartProfileVisibility !== undefined) return locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.title;
      return locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.header(branchesCount);
    }

    const renderOverlayCopy = () => {
      if (isEditFeaturedServices !== undefined && isEditSmartProfileVisibility !== undefined) return locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.contentBoth;
      if (isEditSmartProfileVisibility !== undefined) return locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.contentSmartProfile;
      if (isEditFeaturedServices !== undefined) return locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.contentFeaturedServices;
      return null;
    }

    return (
      <Overlay isOpen={true} showCloseButton={false} background="dark" type="confirmation" doClose={() => {}}>
        <div className="overlay-confirmation-header">
          <h2>{renderOverlayTitle()}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{renderOverlayCopy()}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="discard-btn"
            defaultMessage={locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.discard}
            onClick={handleDiscardChanges}
            data-id="discard-btn"
            className="patient-care-btn-in-group"
            isLoading={isLoading}
          />
          <Button
            buttonType="secondary"
            messageKey="stay-btn"
            defaultMessage={locale.OrganisationsSettings.OrganisationFeaturedServicesOverlay.confirm}
            data-id="stay-btn"
            className="patient-care-btn-in-group"
            onClick={handleSave}
            isLoading={isLoading}
          />
        </div>
      </Overlay>
    )
  }
}
