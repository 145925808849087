import * as BranchesConstants from "constants/BranchesConstants";
import type { Action } from "types/actions";

type CalendarDetailsState = {};

const initialState: CalendarDetailsState = {};

export default (localState: CalendarDetailsState = initialState, action: Action): CalendarDetailsState => {
  switch (action.type) {
    case BranchesConstants.SET_BRANCH_CALENDAR:
      return action.payload;
    default:
      return localState;
  }
};
