import type { AppointmentsByDates } from "types/appointments";
import * as SearchConstants from 'constants/SearchConstants';
import type { Action } from 'types/actions';

type SearchState = {
  search: AppointmentsByDates[] | null,
  details: any | null,
  isSearchResultsPending: boolean,
  events: string[]
}

const initialState = {
  search: null,
  details: null,
  isSearchResultsPending: false,
  events: []
};

export default (localState: SearchState = initialState, action: Action): SearchState =>  {
  switch (action.type) {

    case SearchConstants.SET_SEARCH_RESULTS:
      return {
        ...localState,
        results: action.payload,
        isSearchResultsPending: false,
      };
    case SearchConstants.SET_SEARCH_DETAILS:
      return {
        ...localState,
        details: action.payload
      };
    case SearchConstants.SET_SEARCH_EVENTS:
      return {
        ...localState,
        events: action.payload
      };
    case SearchConstants.CHANGE_SEARCH_DETAILS_PAYMENT_STATUS:
      return {
        ...localState,
        details: {
          ...localState.details,
          payment: {
            ...localState.details.payment,
            paymentStatus: action.payload
          }
        }
      };
    case SearchConstants.CHANGE_SEARCH_DETAILS_STATUS:
      return {
        ...localState,
        details: {
          ...localState.details,
          status: action.payload.status,
          outcomeStatus: action.payload.outcomeStatus,
          behalfOfPatient: action.payload.behalfOfPatient
        }
      };
    case SearchConstants.CHANGE_SEARCH_CANCELLATION_REASON:
      return {
        ...localState,
        details: {
          ...localState.details,
          cancellationReason: action.payload
        }
      };
    case SearchConstants.SEARCH_RESULTS_PENDING:
      return {
        ...localState,
        isSearchResultsPending: true,
      };
    case SearchConstants.SEARCH_RESULTS_ERROR:
      return {
        ...localState,
        isSearchResultsPending: false,
      };
    default:
      return localState
  }
}
