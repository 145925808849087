import React, { Component } from 'react';

import './styles/skeleton-description.scss';

class SkeletonDescription extends Component {
  render() {
    return (
      <div className="skeleton-description">
        <div className="skeleton-description-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" />
        </div>
        <div className="skeleton-description-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-70" />
        </div>
        <div className="skeleton-description-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-30" />
        </div>
        <div className="skeleton-description-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-60" />
        </div>
        <div className="skeleton-description-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-70" />
        </div>
        <div className="skeleton-description-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-30" />
        </div>
      </div>
    );
  }
};

export default SkeletonDescription;
