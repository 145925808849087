import * as OrganisationsListConstants from 'constants/OrganisationsListConstants';

type OrganisationsList = {
  // TODO
};

const initialState: OrganisationsList = {
  searchValue: '',
  data: [],
  pages: 0,
  pageNumber: 0,
  isLoading: false,
  isError: false,
};

export default (localState: OrganisationsList = initialState, action: Action): OrganisationsList => {
  switch (action.type) {
    // SEARCH_VALUE
    case OrganisationsListConstants.SET_ORGANISATIONS_LIST_SEARCH_VALUE:
      return {
        ...localState,
        searchValue: action.payload,
      };

    // ORGANISATIONS_LIST
    case OrganisationsListConstants.GET_ORGANISATIONS_LIST_PENDING:
      return {
        ...localState,
        isLoading: true,
        isError: false,
      };
    case OrganisationsListConstants.GET_ORGANISATIONS_LIST_SUCCESS:
      return {
        ...localState,
        isLoading: false,
        isError: false,
      };
    case OrganisationsListConstants.GET_ORGANISATIONS_LIST_ERROR:
      return {
        ...localState,
        isLoading: false,
        isError: true,
      };
    case OrganisationsListConstants.SET_ORGANISATIONS_LIST_DATA:
      const { items, pages, pageNumber } = action.payload;
      return {
        ...localState,
        data: items,
        pages: pages === 0 ? localState.pages : pages,
        pageNumber,
      };

    // DEFAULT
    default:
      return localState;
  }
};
