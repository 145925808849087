import React, { Component } from 'react';
import './styles/skeleton-calendar-filters-title.scss';

class SkeletonCalendarFiltersTitle extends Component {
  render() {
    return (
      <div className="skeleton-calendar-filters-title">
        <i />
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-60" />
      </div>
    );
  }
};

export default SkeletonCalendarFiltersTitle;
