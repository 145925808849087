import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { LogoNHS } from '@patient-access/ui-kit';
import IconSort from 'svg/IconSort';
import locale from 'service/locale';
import './styles.scss';

export const columns = [
  {
    className: 'cell-main',
    Header: locale.BranchServicesRedesign.table.servicesList.headers.name,
    accessor: 'name',
    Cell: ({ cell: { value } }) => value
  },
];

export function TableServicesList({ columns, data, props, handleValidation }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: 'name', desc: false }],
      },
    },
    useSortBy,
    useRowSelect,
  );

  const renderServiceDescription = (serviceId) => {
    if (props.setUI && props.getServiceDescription) {
      return (
        <Link
          to="#description"
          onClick={(e) => {
            e.preventDefault();
            props.setUI({ isOverlayDescriptionVisible: true });
            props.getServiceDescription(serviceId);
        }}>
          <span>{locale.BranchServicesRedesign.sidebar.serviceDescription}</span>
        </Link>
      );
    }
    return null;
  }

  return (
    <Fragment>
      <table className="react-table branch-services-list" {...getTableProps()}>
        <thead className="white">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} title={locale.BranchServicesRedesign.table.sortByLabel(column.Header)}>
                  <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                    <span>{column.render('Header')}</span>
                    {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        <div className="container">
                          <div className="input-radio">
                            <label htmlFor={row.original.id}>
                              <input
                                type="radio"
                                id={row.original.id}
                                name="addService"
                                onChange={(e) => {
                                  props.setUserData({
                                    serviceId: row.original.id,
                                    serviceName: row.original.name,
                                    serviceAvailableModes: row.original.allowedModes,
                                    serviceIsNhsCovered: row.original.nhsCovered,
                                    serviceModes: [],
                                    serviceIsActive: null,
                                    serviceIsShowPrice: null,
                                    servicePrice: null,
                                    serviceIsVatIncluded: null,
                                    serviceDuration: null,
                                    serviceCutOff: null,
                                    serviceBookingUrl: '',
                                  });
                                  handleValidation(e.currentTarget.value);
                                }}
                                checked={row.original.id === props.dataUser.serviceId}
                                disabled={row.original.isAlreadyAdded ? 'disabled' : null}
                              />
                              <span>{cell.render('Cell')}{row.original.isAlreadyAdded ? locale.BranchServicesRedesign.filter.servicesList.labelAlreadyAdded : null}</span>
                              {row.original.nhsCovered ? <i><LogoNHS /></i> : null}
                            </label>
                          </div>
                          {renderServiceDescription(row.original.id)}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
