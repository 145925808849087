import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button, LinkExternal } from '@patient-access/ui-kit';
import { OverlayDescription } from 'components/Share/overlay';
import { SkeletonDescription } from 'components/Share/skeleton';
import { getServiceDescription, setUI } from 'actions/branchServices';
import Markup from 'components/Share/Markup/Markup';
import locale from 'service/locale';

type Props = {
  serviceId: string,
  data: any,
  isLoading: boolean,
  isError: boolean,
  isOverlayVisible: boolean,
  getServiceDescription: (serviceId: string) => any,
  setUI: (value: any) => any,
};

const mapStateToProps = state => ({
  serviceId: state.branchServices.serviceDescription.serviceId,
  data: state.branchServices.serviceDescription.data,
  isLoading: state.branchServices.serviceDescription.isLoading,
  isError: state.branchServices.serviceDescription.isError,
  isOverlayVisible: state.branchServices.ui.isOverlayDescriptionVisible,
});

const mapDispatchToProps = (dispatch: any) => ({
  getServiceDescription: (serviceId: string) => dispatch(getServiceDescription(serviceId)),
  setUI: (value: any) => dispatch(setUI(value)),
});

class OverlayServiceDescription extends Component<Props> {
  render() {
    const { serviceId, data, isLoading, isError, isOverlayVisible, getServiceDescription, setUI } = this.props;

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <SkeletonDescription />
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <NoResults type="info">
              <h2>{locale.BranchServicesRedesign.overlay.serviceDescription.error}</h2>
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.tryAgain} onClick={() => { getServiceDescription(serviceId); }} />
            </NoResults>
          </Fragment>
        );
      }
      return (
        <Fragment>
          {data.name ? <div className="overlay-description-content-heading"><h1>{data.name}</h1></div> : null}
          {data.description ? <div className="overlay-description-content-body"><Markup text={data.description} /></div> : null}
          {(data.piLink && data.name) ? <div className="overlay-description-content-link"><LinkExternal to={data.piLink} message={locale.BranchServicesRedesign.overlay.serviceDescription.readMore(data.name)} /></div> : null}
        </Fragment>
      );
    }

    if (isOverlayVisible) {
      return (
        <OverlayDescription isOpen={true} doClose={() => { setUI({ isOverlayDescriptionVisible: false }); }}>
          <div className="overlay-description-header" />
          <div className="overlay-description-content">
            {renderContent()}
          </div>
          <div className="overlay-description-footer">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.BranchServicesRedesign.button.close}
              onClick={() => { setUI({ isOverlayDescriptionVisible: false }); }}
            />
          </div>
        </OverlayDescription>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayServiceDescription);
