import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkIcon, IconClose } from "@patient-access/ui-kit";

import { closeModalOverlay } from 'actions/panel';
import { createUser } from 'actions/userDetails';
import { clearForm } from 'actions/form';

import AddUserAssignRole from './AddUserAssignRole/AddUserAssignRole';
import RoleDescription from './RoleDescription/RoleDescription';
import AddUserForm from './AddUserForm/AddUserForm';
import AddUserButtons from './AddUserButtons/AddUserButtons';
import { drawerScrollHandler } from 'helpers/common';

import locale from 'service/locale';
import './styles.scss';

type Props = {
  form: any,
  closeModalOverlay: () => any,
  createUser: (data: any) => any,
  handleDiscardChanges: () => any,
  clearForm: () => any,
  isActiveAgenda: boolean,
  addNewUser: (data: any, organisationId?: string, branchId?: string) => any,
  organisationId?: string,
  branchId?: string,
};

type State = {
  isDataChanged: boolean;
  isScrolledStart: boolean;
  isScrolledEnd: boolean;
}

const mapStateToProps = (state) => ({
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  addNewUser: (data, organisationId?, branchId?) => dispatch(createUser(data, organisationId, branchId)),
  clearForm: () => dispatch(clearForm()),
});

export class AddUserModal extends Component<Props, State> {

  state = {
    isDataChanged: false,
    isScrolledStart: false,
    isScrolledEnd: false
  };

  componentDidMount = () => {
    drawerScrollHandler.bind(this)();
  };

  componentWillUnmount = () => {
    const { closeModalOverlay, clearForm } = this.props;
    closeModalOverlay();
    clearForm();
  };

  handleSaveAddUser = () => {
    const { addNewUser, form, organisationId, branchId } = this.props;
    addNewUser(form, organisationId, branchId);
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { form } = nextProps;
    const formKeys = Object.keys(form);
    const isEditedFirstName = formKeys.includes('new_user_firstName');
    const isEditedEmail = formKeys.includes('new_user_email');
    const isEditedRole = formKeys.includes('new_user_role');
    const isDataChanged = isEditedFirstName || isEditedEmail || isEditedRole;
    this.setState({ isDataChanged });
  };

  closeClicked = (e) => {
    e.preventDefault();
    const { handleDiscardChanges, closeModalOverlay } = this.props;
    const { isDataChanged } = this.state;
    return isDataChanged ? handleDiscardChanges(e) : closeModalOverlay(e);
  };

  render() {
    const { form, handleDiscardChanges, closeModalOverlay, isActiveAgenda, organisationId, branchId } = this.props;
    const { isDataChanged, isScrolledStart, isScrolledEnd } = this.state;
    const { new_user_role } = form;
    const isRoleEdited = Object.keys(form).includes("new_user_role");
    const isRoleSelected = typeof new_user_role === "object" && new_user_role.name;

    return (
      <div className={isActiveAgenda ? "patient-care-modal-full-height-with-agenda-holder" : "patient-care-modal-full-height-holder"}>
        <div
          className="patient-care-modal-full-height"
          onScroll={drawerScrollHandler.bind(this)}
        >
          <div
            className={`patient-care-modal-header ${isScrolledStart ? "has-shadow" : ""}`}
            ref="modalHeader"
          >
            <h2>
              {locale.AddUser.addUserTitle}
            </h2>
            <div className="patient-care-btn-close">
              <LinkIcon
                to="#close"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.AddUser.addUserCloseIcon}
                onClick={this.closeClicked}
              />
            </div>
          </div>
          <div className="patient-care-modal-content" ref="modalContent">
            <div id="patient-care-scrolling-content" ref="modalContentScroll">
              <AddUserForm />
              <hr className="patient-care-separator" />
              <div className="patient-care-block">
                <div className="patient-care-description-block">
                  <h3 className="patient-care-modal-title-in-row">
                    {locale.AddUser.userRoleTitle}
                  </h3>
                  <p>{locale.AddUser.userRoleDescription}</p>
                </div>
                <AddUserAssignRole
                  organisationId={organisationId}
                  branchId={branchId}
                />
              </div>
              {isRoleEdited && isRoleSelected && <RoleDescription selectedRole={new_user_role} />}
            </div>
          </div>
          <AddUserButtons
            isDataChanged={isDataChanged}
            handleDiscardChanges={handleDiscardChanges}
            handleCloseAddUser={closeModalOverlay}
            handleSaveAddUser={this.handleSaveAddUser}
            ref="modalAddOrDiscardButtons"
            customClassName={isScrolledEnd ? "has-shadow" : ""}
          />
        </div>
        <span className="patient-care-modal-overlay" />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
