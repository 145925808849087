import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { getSlots } from "actions/bookAppointment";
import { NoResults, Button, Skeleton } from '@patient-access/ui-kit';
import DailySlots from "./DailySlots/DailySlots";
import { Redirect } from "react-router-dom";
import locale from "service/locale";
import moment from "moment";
import { checkTodayDate } from "helpers/validateData";
import ScrollUpToToday from "components/Share/ScrollUpToToday/ScrollUpToToday";
import * as RolesConstants from 'constants/RolesConstants';
import CalendarDetailsModal from "components/Pages/Admin/BranchContent/BranchCalendars/CalendarDetailsModal/CalendarDetailsModal";
import Modal from "components/Share/Modal/Modal";
import { clearForm } from "actions/form";
import { clearNewBranchServiceList } from "actions/services";
import type { Action } from "types/actions";
import "./styles.scss";
import CustomAppointmentFilter from "./CustomAppointmentFilters";
import * as ServiceConstants from "constants/ServicesConstants";

type Props = {
  bookedService: Object,
  getSlots: (branchId: string) => Function,
  slots: any[],
  branchId: string,
  organisationId: string,
  selectedCalendarId: string,
  currentRole: any,
  calendarsList: any[],
  clearForm: () => Action,
  clearNewBranchServiceList: () => Action,
  isGetSlotsPending: boolean
};

type State = {
  showScrollButton: boolean,
  filteredSlots: any[],
  filteredSlotDateRef: any[],
  slotDateStickHeaderRefId: string | null,
  isRedirect: boolean,
  redirectUrl: string,
  isCalendarOpened: boolean
};

const mapStateToProps = state => ({
  calendarsList: state.branchDetails.calendarsList,
  bookedService: state.book.service,
  slots: state.book.slots,
  branchId: state.router.branchId,
  organisationId: state.router.organisationId,
  currentRole: state.roles.profileCurrentRole,
  selectedCalendarId: state.book.calendar.id,
  isGetSlotsPending: state.book.isGetSlotsPending
});

const mapDispatchToProps = (dispatch: any) => ({
  getSlots: (branchId) => dispatch(getSlots(branchId)),
  clearForm: () => dispatch(clearForm()),
  clearNewBranchServiceList: () => dispatch(clearNewBranchServiceList()),
});

const SLOT_DATE_REF_PREFIX = "slot-date-ref-";

// here top positions of date elements are stored
// moved that out from the react class in order to reduce complexity
// it's updated on every componentDidUpdate
let slotDateOffsetTop = {};

class SlotsList extends PureComponent<Props, State> {
  state = {
    showScrollButton: false,
    filteredSlots: this.props.slots,
    filteredSlotDateRef: [],
    slotDateStickHeaderRefId: null,
    isRedirect: false,
    redirectUrl: `admin/organisations/${this.props.organisationId}/${this.props.branchId}/calendars`,
    isCalendarOpened: false
  };

  events = [
    "scroll",
    "mousewheel",
    "DOMMouseScroll",
    "MozMousePixelScroll",
    "resize",
    "touchmove",
    "touchend"
  ];

  componentDidMount = () => {
    const { getSlots, branchId } = this.props;
    getSlots(branchId)
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.bookedService.id !== this.props.bookedService.id) {
      const { getSlots, branchId } = nextProps;
      getSlots(branchId);
    }
    if (this.props.slots !== nextProps.slots) {
      const filteredSlots = nextProps.slots.filter(dailySlots => !dailySlots.isHiddenDay);
      const filteredSlotDateRef = filteredSlots.map((d, i) => `${SLOT_DATE_REF_PREFIX}${i}`);
      this.setState({
        filteredSlots,
        filteredSlotDateRef,
        slotDateStickHeaderRefId: filteredSlotDateRef[0]
      });
    }
  };

  getSlotDateStickHeaderRefId = () => {
    const { slotsList, header } = this.refs;
    const { filteredSlotDateRef } = this.state;

    let newSlotDateStickHeaderRefId = null;
    const scrollTop = slotsList.scrollTop + header.clientHeight;
    for (let i = 0; i < filteredSlotDateRef.length; i++) {
      const refId = filteredSlotDateRef[i];
      const refOffsetTop = slotDateOffsetTop[refId];

      if (i === 0 || refOffsetTop <= scrollTop + 48) {
        newSlotDateStickHeaderRefId = refId;
      }
    }

    return newSlotDateStickHeaderRefId;
  };

  onScroll = (e) => {
    const { slotsList } = this.refs;
    const {
      filteredSlots,
      showScrollButton,
      filteredSlotDateRef
    } = this.state;

    const newSlotDateStickHeaderRefId = this.getSlotDateStickHeaderRefId();

    let newShowScrollButton = showScrollButton;
    if (filteredSlots && filteredSlots[0]) {
      const topElem = this.refs[filteredSlotDateRef[0]] || slotsList;
      const topPosition = slotsList.offsetTop;
      const position = topElem.getBoundingClientRect().top;
      newShowScrollButton = position < topPosition;
    }

    this.setState({
      showScrollButton: newShowScrollButton,
      slotDateStickHeaderRefId: newSlotDateStickHeaderRefId
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    const { filteredSlotDateRef } = this.state;

    // save loaded offsets when loaded for different views: day/week/month
    // otherwise return
    const keys = Object.keys(slotDateOffsetTop);
    if (keys.length === filteredSlotDateRef.length)
      return;

    let newSlotDateOffsetTop = {};
    filteredSlotDateRef.forEach((refId) => {
      const ref = this.refs[refId];
      newSlotDateOffsetTop[refId] = ref.offsetTop;
    });

    slotDateOffsetTop = newSlotDateOffsetTop
  };

  handleScrollClick = () => {
    const { slotsList } = this.refs;
    slotsList.scrollTop = 0;
  };

  handleManageAvailabilityClick = (e: Event) => {
    const { selectedCalendarId } = this.props;
    const isAllCalendarsSelected = selectedCalendarId === "" || selectedCalendarId === "all";
    this.setState({
      isRedirect: isAllCalendarsSelected,
      isCalendarOpened: !isAllCalendarsSelected
    })
  };

  handleDiscardChanges = () => {
    const { clearForm, clearNewBranchServiceList } = this.props;
    this.setState({ isCalendarOpened: false }, () => {
      clearForm();
      clearNewBranchServiceList();
    });
  };

  render() {
    const { bookedService, currentRole, selectedCalendarId, calendarsList, isGetSlotsPending } = this.props;
    const {
      showScrollButton,
      filteredSlots,
      isRedirect,
      redirectUrl,
      isCalendarOpened,
      filteredSlotDateRef,
      slotDateStickHeaderRefId
    } = this.state;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;
    const existingCalendarNamesList = calendarsList.map(
      calendar => calendar.name
    );

    const isCustomAppointment = [ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID, ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID].includes(bookedService.id);

    const renderWarningMessage = () => {
      const todayDate = moment().utc().toISOString();      
      const isServiceNotMappedWithCalendar = !calendarsList.some((cal)=> cal && cal.services && cal.services.includes(bookedService.id) && (selectedCalendarId === cal.id || selectedCalendarId === "all" || selectedCalendarId === ""));
      const areCalandarsHavingFutureStartDate = calendarsList.some((cal) =>  moment(cal.start).format('YYYY-MM-DD') > moment(todayDate).format('YYYY-MM-DD'));
      
      if (isServiceNotMappedWithCalendar) {
        return <h2>{locale.AppointmentSlots.noSlotsForServiceNotMappedWithCalendarWarning}</h2>;
      }

      else if(areCalandarsHavingFutureStartDate) {
        return <h2>{locale.AppointmentSlots.noSlotsForFutureCalendarWarning}</h2>;
      }

      return<h2>{locale.AppointmentSlots.noSlotsWarning}</h2>;
    };

    return isRedirect
      ? <Redirect to={redirectUrl} />
      : (
        <div className={`patient-care-appointments-holder ${
          isCustomAppointment ? 'patient-care-custom-appointment' : ""}`}>
          <div className="patient-care-sidebar-header" ref="header">
            {isCustomAppointment ?
              <CustomAppointmentFilter />
              : (<Fragment>
                <h2>{bookedService.name}</h2>
                <span className="patient-care-subtitle">
                  {bookedService.duration} {locale.AppointmentSlots.duration}
                </span>
              </Fragment>)
            }
          </div>
          {
            filteredSlots && filteredSlots.length > 0
              ? (
                <div
                  className={`patient-care-sidebar-agenda-body${
                    showScrollButton ? "-with-btn" : ""
                    }`}
                  ref="slotsList"
                  onScroll={this.onScroll}
                >
                  {filteredSlots.map((date, index) => {
                    return (
                      <Fragment key={date.date}>
                        <div
                          className={
                            [
                              "patient-care-sidebar-date",
                              checkTodayDate(date.date) ? "active" : "",
                              slotDateStickHeaderRefId === filteredSlotDateRef[index] ? "sticky-top" : ""
                            ].join(' ')
                          }
                        >
                          <span>
                            {moment(date.date).format("dddd D MMM")}{" "}
                            {checkTodayDate(date.date) ? "(today)" : ""}
                          </span>
                        </div>
                        <div
                          className="patient-care-time-slots-block"
                          ref={filteredSlotDateRef[index]}
                        >
                          <DailySlots slots={date.slots} />
                        </div>
                      </Fragment>
                    );
                  })}
                  {showScrollButton && (
                    <Fragment>
                      <div className="patient-care-time-slots-mask" />
                      <ScrollUpToToday onScrollClick={this.handleScrollClick} />
                    </Fragment>
                  )}
                </div>
              ) : isGetSlotsPending
                ? (
                  <Fragment>
                    <Skeleton type="base" />
                    <Skeleton type="base" />
                    <Skeleton type="base" />
                  </Fragment>
                )
                : (
                  <Fragment>
                    <NoResults type="info">
                      {renderWarningMessage()}
                      {
                        !isBranchMember && (
                          <Button
                            buttonType="secondary"
                            messageKey="manage-availability-btn"
                            defaultMessage={locale.AppointmentSlots.manageAvailabilityBtn}
                            onClick={this.handleManageAvailabilityClick}
                            data-id="manage-availability-btn"
                            buttonSize="small"
                          />
                        )
                      }
                    </NoResults>
                  </Fragment>
                )
          }
          {
            isCalendarOpened && (
              <Modal>
                <CalendarDetailsModal
                  calendarId={selectedCalendarId}
                  existingCalendarNamesList={existingCalendarNamesList}
                  handleCloseModal={this.handleDiscardChanges}
                  handleDiscardChanges={this.handleDiscardChanges}
                />
              </Modal>
            )
          }
        </div>
      );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlotsList);
