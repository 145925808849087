import React, { Component } from "react";
import {
  Checkbox,
  FormItem,
  Icon,
  IconSortUp,
  IconSortDown,
  Form,
} from "@patient-access/ui-kit";

import { FeatureContentTable } from "components/Share/feature";
import locale from "service/locale";
import "./styles.scss";

type Props = {
  isEditMode: boolean,
  isAbleToEdit: boolean,
  isSorting: boolean,
  isAffiliateOrganisation: boolean,
  handleChangeMode: () => any,
  handleChangeSortingOrder: () => any,
  handleSelectionClick: () => any,
  defaultChecked: boolean,
  data: any[]
};


class DefaultServicesTableHeader extends Component<Props, State> {
  render() {
    const {
      isEditMode,
      isAbleToEdit,
      isSorting,
      handleChangeMode,
      handleChangeSortingOrder,
      handleSelectionClick,
      data
    } = this.props;

    return <FeatureContentTable>
        <colgroup>
          <col style={{ width: locale.DefaultServices.servicesTableHeaders.alert.width }} />
          <col style={{ width: locale.DefaultServices.servicesTableHeaders.name.width }} />
          <col style={{ width: locale.DefaultServices.servicesTableHeaders.defaultService.width }} />
          <col style={{ width: locale.DefaultServices.servicesTableHeaders.modes.width }} />
          <col style={{ width: locale.DefaultServices.servicesTableHeaders.edit.width }} />
        </colgroup>
        <thead className="default-service-table-header">
          <tr>
            <td className="first">
              {locale.DefaultServices.servicesTableHeaders.alert.label}
            </td>
            <td className="extra">
              <span className="patient-care-link" onClick={handleChangeSortingOrder}>
                {locale.DefaultServices.servicesTableHeaders.name.label}
                &nbsp;
                {isSorting ? <Icon size="smaller" icon={<IconSortUp outline />} type="inline" /> : <Icon size="smaller" icon={<IconSortDown outline />} type="inline" />}
              </span>
            </td>
            <td className="extra">
              <span className="header-span-wrap">
                <Form>
                  <FormItem type="item">
                    <Checkbox 
                      id="select_all" 
                      name="select_all" 
                      label={locale.DefaultServices.servicesTableHeaders.defaultService.label} 
                      checked={data.every((data) => data.isStandard === true)} 
                      value={locale.DefaultServices.servicesTableHeaders.defaultService.label} 
                      onChange={handleSelectionClick} 
                      data-id="select_all" 
                      disabled={!isEditMode} 
                      className="select-all-checkbox" />
                  </FormItem>
                </Form>
              </span>
            </td>
            <td className="extra">
              {locale.DefaultServices.servicesTableHeaders.modes.label}
            </td>
            <td className="last right">
              {isAbleToEdit ? <span className="patient-care-link" onClick={handleChangeMode}>
                  {isEditMode ? `${locale.DefaultServices.servicesTableHeaders.edit.done}` : `${locale.DefaultServices.servicesTableHeaders.edit.edit}`}
                </span> : null}
            </td>
          </tr>
        </thead>
      </FeatureContentTable>;
  }
}

export default DefaultServicesTableHeader;
