import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Overlay,
  Button,
  Password,
  Form,
  FormItem,
  InputLabel,
  InputError
} from "@patient-access/ui-kit";

import type { Action } from "types/actions";
import { changePassword, setPasswordMismatch, resetPasswordMismatch } from "actions/profile";
import { updateForm, clearForm } from "actions/form";
import { closeChangePassword } from "actions/panel";
import { validateChangePassword } from "helpers/validateData";
import { checkPassword, passwordContainsEmail } from "helpers/checkValues";

import locale from "service/locale";

const inputNames = {
  password: "password",
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword"
};

type Props = {
  changePassword: (data: any) => Action,
  closeChangePassword: () => Action,
  clearForm: () => Action,
  form: any,
  updateForm: (data: any) => Action,
  passwordMismatchError: boolean,
  setPasswordMismatch: (code: string) => Action,
  resetPasswordMismatch: (code: string) => Action,
  email: string,
};

type State = {
  confirmNewPassword: string,
  confirmNewPasswordError: boolean,
  password: string,
  passwordError: boolean,
  newPassword: string,
  newPasswordEmpty: boolean,
  newPasswordError: boolean
};

const mapStateToProps = state => ({
  form: state.form,
  email: state.profile.email,
  passwordMismatchError: state.profile.passwordMismatchCode === "PasswordMismatch"
});

const mapDispatchToProps = (dispatch: Function) => ({
  changePassword: (data: any) => dispatch(changePassword(data)),
  closeChangePassword: () => dispatch(closeChangePassword()),
  clearForm: () => dispatch(clearForm()),
  updateForm: (data: any) => dispatch(updateForm(data)),
  setPasswordMismatch: (code: string) => dispatch(setPasswordMismatch(code)),
  resetPasswordMismatch: () => dispatch(resetPasswordMismatch()),
});

class ChangeYourPasswordOverlay extends Component<Props, State> {
  state = {
    confirmNewPassword: "",
    confirmNewPasswordError: false,
    password: "",
    passwordError: false,
    newPassword: "",
    newPasswordEmpty: false,
    newPasswordError: false
  };

  handleCancel = (e: Event) => {
    const { closeChangePassword, clearForm } = this.props;

    e && e.preventDefault();
    clearForm();
    closeChangePassword();
  };

  handleSendConfirmationRequest = (e: Event) => {
    const { changePassword, form } = this.props;

    e && e.preventDefault();
    changePassword(form);
  };

  isFormValidated = () => {
    const { form, email } = this.props;
    return validateChangePassword(form, email);
  };

  handlePasswordChangeOnEnter = (e: Event) => {
    if (e.key === 'Enter' && this.isFormValidated()) {
      this.handleSendConfirmationRequest() ;
    } 
  };

  handleBlur = (e: Event) => {
    e && e.preventDefault();
    const { name, value } = e.target;
    this.validateForm(name, value);
  };

  handleInputChange = (e: Event) => {
    e && e.preventDefault();
    const { newPasswordError, passwordError, confirmNewPasswordError } = this.state;
    const { name, value } = e.target;
    const { updateForm, resetPasswordMismatch } = this.props;

    this.setState({
      [name]: value,
      passwordError: name === "password" ? false : passwordError,
      newPasswordError: name === "newPassword" ? false : newPasswordError,
      confirmNewPasswordError: name === "confirmNewPassword" ? false : confirmNewPasswordError
    }, () => {
      name === "password" && resetPasswordMismatch();
      updateForm({ [name]: value });
    });
  };

  validateForm = (name: string, value: string) => {
    const { form, setPasswordMismatch, email } = this.props;

    switch (name) {
      case inputNames.password:
        setPasswordMismatch("");
        this.setState({
          passwordError: !value.length
        });
        break;
      case inputNames.newPassword:
        this.setState({
          newPasswordEmpty: !value.length,
          newPasswordError: !checkPassword(value) || passwordContainsEmail(email, value),
          confirmNewPasswordError: form.confirmNewPassword !== value
        });
        break;
      case inputNames.confirmNewPassword:
        this.setState({
          confirmNewPasswordError: form.newPassword !== value
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { passwordMismatchError } = this.props;
    const {
      password,
      passwordError,
      newPassword,
      newPasswordEmpty,
      newPasswordError,
      confirmNewPassword,
      confirmNewPasswordError
    } = this.state;

    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="confirm-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{locale.Modals.changeYourPasswordOverlay.header}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.Modals.changeYourPasswordOverlay.content}</h4>
        </div>
        <div className="patient-care-block">
          <Form>
            <FormItem type="item" error={passwordError ? "true" : ""}>
              <InputLabel
                htmlFor="current_password"
                message={locale.Modals.changeYourPasswordOverlay.labelPassword}
                size="small"
              />
              <Password
                id="current_password"
                name="password"
                data-id="current_password"
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                value={password}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {passwordError && (
                <InputError
                  message={
                    locale.Modals.changeYourPasswordOverlay.passwordEmpty
                  }
                />
              )}
              {passwordMismatchError && (
                <InputError
                  message={
                    locale.Modals.changeYourPasswordOverlay.passwordMismatch
                  }
                />
              )}
            </FormItem>
            <FormItem
              type="item"
              error={newPasswordEmpty || newPasswordError ? "true" : ""}
            >
              <InputLabel
                htmlFor="new_password"
                message={
                  locale.Modals.changeYourPasswordOverlay.labelNewPassword
                }
                size="small"
              />
              <Password
                id="new_password"
                name="newPassword"
                data-id="new_password"
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                value={newPassword}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {newPasswordEmpty && (
                <InputError
                  message={
                    locale.Modals.changeYourPasswordOverlay.newPasswordEmpty
                  }
                />
              )}
              {!newPasswordEmpty && newPasswordError && (
                <div className="patient-care-password-rules">
                  <p>{locale.Modals.changeYourPasswordOverlay.newPasswordError[0]}</p>
                  <p>&bull; {locale.Modals.changeYourPasswordOverlay.newPasswordError[1]}</p>
                  <p>&bull; {locale.Modals.changeYourPasswordOverlay.newPasswordError[2]}</p>
                  <p>&bull; {locale.Modals.changeYourPasswordOverlay.newPasswordError[3]}</p>
                  <p>&bull; {locale.Modals.changeYourPasswordOverlay.newPasswordError[4]}</p>
                  <p>&bull; {locale.Modals.changeYourPasswordOverlay.newPasswordError[5]}</p>
                  <p>&bull; {locale.Modals.changeYourPasswordOverlay.newPasswordError[6]}</p>
                </div>
              )}
            </FormItem>
            <FormItem type="item" error={confirmNewPasswordError ? "true" : ""}>
              <InputLabel
                htmlFor="confirm_new_password"
                message={
                  locale.Modals.changeYourPasswordOverlay
                    .labelConfirmNewPassword
                }
                size="small"
              />
              <Password
                id="confirm_new_password"
                name="confirmNewPassword"
                data-id="confirm_new_password"
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                value={confirmNewPassword}
                onKeyPress={this.handlePasswordChangeOnEnter}
              />
              {confirmNewPasswordError && (
                <InputError
                  message={
                    locale.Modals.changeYourPasswordOverlay
                      .confirmNewPasswordError
                  }
                />
              )}
            </FormItem>
            <FormItem type="buttons">
              <div className="overlay-confirmation-buttons">
                <div className="patient-care-row-align-right">
                  <Button
                    buttonType="blueline"
                    messageKey="cancel-btn"
                    defaultMessage={locale.Buttons.buttonCancel}
                    onClick={this.handleCancel}
                    data-id="cancel-btn"
                    className="patient-care-btn-in-group"
                  />
                  <Button
                    buttonType="secondary"
                    messageKey="change-btn"
                    defaultMessage={locale.Buttons.buttonChangePassword}
                    data-id="change-btn"
                    className="patient-care-btn-in-group"
                    isDisabled={!this.isFormValidated()}
                    onClick={this.handleSendConfirmationRequest}
                    tabIndex={!this.isFormValidated() ? -1 : 0}
                  />
                </div>
              </div>
            </FormItem>
          </Form>
        </div>
      </Overlay>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeYourPasswordOverlay);
