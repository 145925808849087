import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  FormItem,
  InputLabel,
  Input,
  Button,
  InputError,
  Form,
  Textarea
} from "@patient-access/ui-kit";

import { updateForm } from "actions/form";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";
import { bookAppointment } from "actions/bookAppointment";
import {
  checkEmail,
  checkPhoneNumber,
  checkFirstName,
  checkLastName,
  checkDOB,
  checkPostcode,
  checkServicePrice
} from "helpers/checkValues";
import { modesOptions } from "constants/BranchesConstants";
import locale from "service/locale";

type Props = {
  form: any,
  updateForm: (data: any) => any,
  bookAppointment: () => any,
  handleDataChange: () => any,
  price: number,
  isAppointmentPending: boolean,
  isSlotExpired: boolean,
  handleCancel: () => any,
  handleCloseExpiredSlotDialog: () => any,
  isCustomAppointment: boolean,
  modes: any,
};

type State = {
  book_firstName: boolean,
  book_lastName: boolean,
  book_email: boolean,
  book_phoneNumber: boolean,
  book_postcode: boolean,
  book_DOB: boolean,
  book_servicePrice: boolean,
  book_appointmentReason: boolean,
  appointmentReason: string,
  isAppointmentTypeInValid: boolean
};

const mapStateToProps = (state: any) => ({
  form: state.form,
  isAppointmentPending: state.book.isAppointmentPending,
  isSlotExpired: state.book.isSlotExpired,
  price: state.book.service.price,
  modes: state.book.service.modes,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  bookAppointment: () => dispatch(bookAppointment())
});

export class NewAppointmentData extends Component<Props, State> {
  state = {
    book_firstName: true,
    book_lastName: true,
    book_email: true,
    book_postcode: false,
    book_DOB: false,
    book_phoneNumber: true,
    book_servicePrice: false,
    book_appointmentReason: true,
    appointmentReason: "",    
    isAppointmentTypeInValid:false
  };

  componentDidMount = () => {
    const { modes, updateForm, handleDataChange } = this.props;
    if (modes.length === 1) {
      handleDataChange();
      updateForm({ book_appointmentType: modesOptions.find(el => el.value === modes[0]) });
    }
  };

  componentWillUnmount = () => {
    this.setState({
      appointmentReason: ""
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.form) {
      const { appointmentReason } = nextProps.form;
      this.setState({
        appointmentReason
      });
    }
  }

  handleAppointmentType = (appointmentType) => {
    const { updateForm, handleDataChange } = this.props;
    const isFacetoFaceService = appointmentType.value === 0;
    if(isFacetoFaceService)
      updateForm({ appointmentReason: "" });
    handleDataChange();
    const selectedMode = modesOptions.filter(el => el.value === appointmentType.value);
    updateForm({ book_appointmentType: appointmentType });
    if(selectedMode.length)
      this.setState({ isAppointmentTypeInValid: false });
    else
      this.setState({ isAppointmentTypeInValid: true });
  }

  handleBookAppointment = (e: Event) => {
    e && e.preventDefault();
    const { bookAppointment, form } = this.props;

    const isTelePhoneService = form.book_appointmentType && form.book_appointmentType.value === 2;
    const isVideoService = form.book_appointmentType && form.book_appointmentType.value === 1;
    const isEmptyDOB = !form.book_DOB || (form.book_DOB && form.book_DOB.length === 0);
    const isCheckedDOB = checkDOB(form.book_DOB);
    const isValidDOB = isEmptyDOB || isCheckedDOB;

    const isEmptyPostcode = !form.book_postcode || (form.book_postcode && form.book_postcode.length === 0);
    const isCheckedPostcode = checkPostcode(form.book_postcode);
    const isValidPostcode = isEmptyPostcode || isCheckedPostcode;

    const isEmptyEmail = !form.book_email || (form.book_email && form.book_email.length === 0);
    const isCheckedEmail = checkEmail(form.book_email);
    const isValidEmail = (isTelePhoneService || isVideoService) ? isCheckedEmail : isEmptyEmail || isCheckedEmail;

    const isEmptyPhone = !form.book_phoneNumber || (form.book_phoneNumber && form.book_phoneNumber.length === 0);
    const isCheckedPhone = checkPhoneNumber(form.book_phoneNumber);
    const isValidPhone = (isTelePhoneService || isVideoService)
      ? isCheckedPhone
      : isEmptyPhone || isCheckedPhone;
    if (isValidDOB && isValidPostcode && isValidEmail && isValidPhone) {
      bookAppointment();
    }
  }

  handleFocus = ({ target: { id } }) => {
    this.setState({ [id]: true });
  };

  handleBlur = ({ target: { name, value, id } }) => {
    const { form } = this.props;
    const isTelePhoneService = form.book_appointmentType && form.book_appointmentType.value === 2;
    const isVideoService = form.book_appointmentType && form.book_appointmentType.value === 1;
    
    if(value.length && id === "book_phoneNumber")
      this.setState({ [id]: checkPhoneNumber(form.book_phoneNumber) });

    else  if(value.length && id === "book_email")
      this.setState({ [id]: checkEmail(form.book_email) });

    else if(!value.length && (id === "book_firstName" || id === "book_lastName" || id === "book_appointmentReason"))
      this.setState({ [id]: false });

    else
      this.setState({ [id]: !value.length && (isTelePhoneService || isVideoService) ? false : true });
  };

  handleAppointmentTypeBlur = () => {
     const { form } = this.props;
     if(!form.book_appointmentType)    
       this.setState({ isAppointmentTypeInValid: true });
  };

  handleBookFormChange = ({ target: { name, value } }) => {
    const { updateForm, handleDataChange } = this.props;
    handleDataChange();
    updateForm({ [name]: value });
  };

  render() {
    const {
      form,
      price,
      isAppointmentPending,
      isSlotExpired,
      handleCloseExpiredSlotDialog,
      handleCancel,
      isCustomAppointment,
      modes,
    } = this.props;
    const {
      book_firstName,
      book_lastName,
      book_email,
      book_phoneNumber,
      book_DOB,
      book_postcode,
      book_servicePrice,
      book_appointmentReason,
      appointmentReason,
      isAppointmentTypeInValid
    } = this.state;   
    
    const isTelePhoneService = form.book_appointmentType && form.book_appointmentType.value === 2;
    const isVideoService = form.book_appointmentType && form.book_appointmentType.value === 1;
    const isValidFName = checkFirstName(form.book_firstName);
    const isValidLName = checkLastName(form.book_lastName);
    const isEmptyPhone = !form.book_phoneNumber || (form.book_phoneNumber && form.book_phoneNumber.length === 0);
    const isCheckedPhone = checkPhoneNumber(form.book_phoneNumber);
    const isValidPhone = (isTelePhoneService || isVideoService)
      ? isCheckedPhone
      : isEmptyPhone || isCheckedPhone;
    const isEmptyEmail = !form.book_email || (form.book_email && form.book_email.length === 0);
    const isCheckedEmail = checkEmail(form.book_email);
    const isValidEmail = (isTelePhoneService || isVideoService) ? isCheckedEmail : isEmptyEmail || isCheckedEmail;    
    const isValidServicePrice = checkServicePrice(form.book_servicePrice);
    const isValidReason = form.appointmentReason && form.appointmentReason.length > 0;
    const isEmptyAppointmentTitle = !form.book_appointment_title || (form.book_appointment_title && form.book_appointment_title.length === 0);
    const isValidAppointmentTitle = !isEmptyAppointmentTitle;
    const isFirstNameError = !book_firstName;
    const isLastNameError = !book_lastName;
    const isFirstNameValidError = !isValidFName && form.book_firstName;
    const isLastNameValidError = !isValidLName && form.book_lastName;
    const isEmailError =
      !checkEmail(form.book_email) &&
      !book_email;
    const isPhoneError =
      !checkPhoneNumber(form.book_phoneNumber) &&
      !book_phoneNumber;
    const isDOBError =
      !checkDOB(form.book_DOB) &&
      !book_DOB &&
      form.book_DOB &&
      form.book_DOB.length > 0;
    const isPostcodeError =
      !checkPostcode(form.book_postcode) &&
      !book_postcode &&
      form.book_postcode &&
      form.book_postcode.length > 0;
    const isServicePriceError =
      !isValidServicePrice &&
      !book_servicePrice &&
      Object.keys(form).includes("book_servicePrice") &&
      (form.book_servicePrice &&
      form.book_servicePrice.length) === "";
    const isReasonError =
      (!form.book_appointmentReason || (form.book_appointmentReason && form.book_appointmentReason.length === 0)) &&
      !book_appointmentReason;
    const availableModesOptions = modesOptions.filter(el => modes.indexOf(el.value) !== -1);
    const isAppointmentTypeError = availableModesOptions.length === 1 ? false : isAppointmentTypeInValid ||  !form.book_appointmentType;
    const isValidForm = isCustomAppointment ? (isValidFName && isValidLName && isValidServicePrice && isValidAppointmentTitle && !isAppointmentTypeError)
    : (isTelePhoneService|| isVideoService )? (isValidFName && isValidLName && isValidPhone && isValidEmail && isValidReason && !isAppointmentTypeError)
    : (isValidFName && isValidLName && isValidPhone && isValidEmail && !isAppointmentTypeError);

    return (
      <Form noValidate>
        <div className="patient-care-heading">
          <h3 className="patient-care-title-in-row">
            {locale.BookAppointment.appointmentTypeHeader}
          </h3>
        </div>
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Select
              id="appointment_type_select"
              name="appointment_type_select"
              placeholder={locale.BookAppointment.appointmentTypePlaceholder}
              defaultValue={modes.length === 1 ? availableModesOptions[0] : null}
              options={availableModesOptions}
              onChange={this.handleAppointmentType}
              classNamePrefix="patient-care"
              className={`patient-care-select ${isAppointmentTypeInValid ? "patient-care-select-error" : ""}`}
              onBlur={this.handleAppointmentTypeBlur}
            />
            { isAppointmentTypeInValid && <InputError message={locale.BookAppointment.appointmentTypeError} />}
          </div>
        </div>
        <hr className="patient-care-separator" />
        <div className="patient-care-heading">
          <h3 className="patient-care-title-in-row">
            {locale.BookAppointment.customerDetailsHeader}
          </h3>
        </div>
        <div className="patient-care-block-row">
          <div className="patient-care-col-6">
            <FormItem type="item" error={isFirstNameError || isFirstNameValidError ? "error" : null}>
              <InputLabel
                htmlFor="book_firstName"
                message={locale.BookAppointment.nameInput}
                size="small"
              />
              <Input
                type="text"
                id="book_firstName"
                name="book_firstName"
                data-id="book_firstName"
                onChange={this.handleBookFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {isFirstNameError && (
                <InputError message={locale.BookAppointment.nameError} />
              )}
              { isFirstNameValidError && (
                <InputError message={locale.BookAppointment.validNameError} />
              )}
            </FormItem>
          </div>
          <div className="patient-care-col-6">
            <FormItem type="item" error={isLastNameError || isLastNameValidError ? "error" : null}>
              <InputLabel
                htmlFor="book_lastName"
                message={locale.BookAppointment.familyNameInput}
                size="small"
              />
              <Input
                type="text"
                id="book_lastName"
                name="book_lastName"
                data-id="book_lastName"
                onChange={this.handleBookFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {isLastNameError && (
                <InputError message={locale.BookAppointment.familyNameError} />
              )}
              {isLastNameValidError && (
                <InputError message={locale.BookAppointment.validFamilyNameError} />
              )}
            </FormItem>
          </div>
        </div>

        {
          //TODO: uncomment if Book Appointment button will be always enabled
          // (submitNameError || submitLastNameError) && (
          //   <div className="patient-care-block-row">
          //     <InputError
          //       message={locale.BookAppointment.firstNameAndLastNameError}
          //     />
          //   </div>
          // )
        }
        <div className="patient-care-block-row">
          <div className="patient-care-col-6">
            <FormItem type="item" error={isDOBError ? "error" : null}>
              <InputLabel
                htmlFor="book_DOB"
                message={locale.BookAppointment.DOBInput}
                size="small"
              />
              <Input
                type="date"
                id="book_DOB"
                name="book_DOB"
                data-id="book_DOB"
                onChange={this.handleBookFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {isDOBError && (
                <InputError message={locale.BookAppointment.DOBError} />
              )}
            </FormItem>
          </div>
          <div className="patient-care-col-6">
            <FormItem type="item" error={isPostcodeError ? "error" : null}>
              <InputLabel
                htmlFor="book_postcode"
                message={locale.BookAppointment.postcodeInput}
                size="small"
              />
              <Input
                type="text"
                id="book_postcode"
                name="book_postcode"
                data-id="book_postcode"
                onChange={this.handleBookFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {isPostcodeError && (
                <InputError message={locale.BookAppointment.postcodeError} />
              )}
            </FormItem>
          </div>
        </div>
        <div className="patient-care-block-row">
          <div className="patient-care-col-6">
            <FormItem type="item" error={isPhoneError ? "error" : null}>
              <InputLabel
                htmlFor="book_phoneNumber"
                message={ (isTelePhoneService || isVideoService) ? locale.BookAppointment.phoneNumberMandatory : locale.BookAppointment.phoneNumber}
                size="small"
              />
              <Input
                type="text"
                id="book_phoneNumber"
                name="book_phoneNumber"
                data-id="book_phoneNumber"
                onChange={this.handleBookFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {isPhoneError && (
                <InputError message={isEmptyPhone ? locale.BookAppointment.phoneNumberEmptyError : locale.BookAppointment.phoneNumberError} />
              )}
            </FormItem>
          </div>
          <div className="patient-care-col-6">
            <FormItem type="item" error={isEmailError ? "error" : null}>
              <InputLabel
                htmlFor="book_email"
                message={ (isTelePhoneService || isVideoService) ? locale.BookAppointment.emailMandatory : locale.BookAppointment.email}
                size="small"
              />
              <Input
                type="text"
                id="book_email"
                name="book_email"
                data-id="book_email"
                onChange={this.handleBookFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {isEmailError && (
                <InputError message={ isEmptyEmail ? locale.BookAppointment.emailEmptyError : locale.BookAppointment.emailError} />
              )}
            </FormItem>
          </div>
        </div>

        {
          //TODO: uncomment if Book Appointment button will be always enabled
          // submitEmailError && submitPhoneError && (
          //   <div className="patient-care-block-row">
          //     <InputError
          //       message={locale.BookAppointment.emailAndPasswordError}
          //     />
          //   </div>
          // )
        }
        { isTelePhoneService || isVideoService ? 
            <Fragment>
              <hr className="patient-care-separator" />
              <div className="patient-care-block-row">
                <div className="patient-care-col-12">
                  <div className="patient-care-heading">
                    <h3 className="patient-care-title-in-row">
                      {locale.BookAppointment.appointmentReason}
                    </h3>
                  </div>
                  <FormItem type="item" error={isReasonError ? "error" : null}>
                  <Textarea
                    id="book_appointmentReason"
                    name="appointmentReason"
                    data-id="book_appointmentReason"
                    value={appointmentReason || ""}
                    maxLength={400}
                    countValue={appointmentReason ? appointmentReason.length : 0}
                    countMax={400}
                    onChange={this.handleBookFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  </FormItem>
                  {isReasonError && (
                    <InputError message={locale.BookAppointment.reasonError} />
                  )}
                </div>
              </div>
            </Fragment>
          : null
        }
        
        <hr className="patient-care-separator" />
        <div className="patient-care-block-row">
          {isCustomAppointment
            ? (<Fragment>
              <div className="patient-care-col-6 patient-care-row-align-content-center">
                <h3 className="patient-care-title-in-row">
                  {locale.BookAppointment.servicePriceWithSymbol}
                </h3>
                <FormItem type="item" error={isServicePriceError ? "error" : null}>
                  <Input
                    id="book_servicePrice"
                    name="book_servicePrice"
                    data-id="book_servicePrice"
                    type="number"
                    placeholder={locale.AddNewServices.pricePlaceholder}
                    onChange={this.handleBookFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                </FormItem>
              </div>
              <div className="patient-care-col-6">
                {isServicePriceError && (
                  <InputError message={locale.BookAppointment.servicePriceError} />
                )}
              </div>
            </Fragment>
            )
            : (<Fragment>
              <h3 className="patient-care-title-in-row">
                {locale.BookAppointment.servicePrice}
              </h3>
              <h1>£{price.toFixed(2)}</h1>
            </Fragment>)
          }
        </div>
        <hr className="patient-care-separator" />
        <div className="patient-care-modal-footer">
          <div className="patient-care-row-align-right">
            <Button
              buttonType="blueline"
              messageKey="cancel-btn"
              defaultMessage={locale.BookAppointment.cancelButtonText}
              onClick={handleCancel}
              isLoading={isAppointmentPending}
              data-id="cancel-btn"
            />
            <Button
              buttonType="secondary"
              messageKey="book-btn"
              defaultMessage={locale.BookAppointment.bookButtonText}
              onClick={this.handleBookAppointment}
              isLoading={isAppointmentPending}
              data-id="book-appointment-btn"
              isDisabled={!isValidForm}
            />
          </div>
        </div>
        {
          isSlotExpired && (
            <InformationalOverlay
              header={locale.BookAppointment.expiredSlotModalHeader}
              content={locale.BookAppointment.expiredSlotModalContent}
              buttonOk={locale.BookAppointment.expiredSlotModalButton}
              handleOk={handleCloseExpiredSlotDialog}
            />
          )
        }
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentData);
