import React, { Component, Fragment } from 'react';

import MetaData from './MetaData';
import Header from './Header/Header';
import Section from './Section/Section';
import SectionConnect from './SectionConnect/SectionConnect';
import SectionTabs from './SectionTabs/SectionTabs';
import SectionMore from './SectionMore/SectionMore';
import Footer from './Footer/Footer';
import FooterSticky from './Footer/FooterSticky';

class Register extends Component {
  componentDidMount = () => {
    document.body.style.overflowY = "auto";
    document.body.style.minWidth = "320px";
  }

  componentWillUnmount = () => {
    document.body.style.overflowY = null;
    document.body.style.minWidth = null;
  }

  render() {
    return (
      <Fragment>
        <MetaData />
        <Header />
        <Section>
          <SectionConnect />
          <SectionTabs />
          <SectionMore />
        </Section>
        <FooterSticky />
        <Footer />
      </Fragment>
    );
  }
}

export default Register;
