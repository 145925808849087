import React, { Component } from 'react'
import { Overlay, Button } from "@patient-access/ui-kit";

type Props = {
  handleStay: () => any,
  title: string,
  body: string,
  buttonText: string,
}

export default class ConflictModal extends Component<Props> {
  handleStay = (e: Event) => {
    e && e.preventDefault();
    const { handleStay } = this.props;
    handleStay();
  };

  render() {
    const { title, body, buttonText } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{title}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{body}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="secondary"
            messageKey="stay-btn"
            defaultMessage={buttonText}
            onClick={this.handleStay}
            data-id="stay-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    )
  }
}
