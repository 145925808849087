import agent from 'service/agent';
import { setOrganisationHeader } from 'actions/router';
import { getToken } from 'helpers/common';
import * as ReportsConstants from 'constants/ReportsSmartPharmacyConstants';
import type { Organisation, Branch } from "types/organisation";
import type { ReportSmartPharmacyFilter } from "types/reports"

export const setDropdownOrganisation = (organisation: Organisation) => ({
  type: ReportsConstants.SET_REPORTSMARTPHARMACY_DROPDOWN_ORGANISATION,
  payload: organisation,
});

export const setDropdownBranch = (branch: Branch): Action => ({
  type: ReportsConstants.SET_REPORTSMARTPHARMACY_DROPDOWN_BRANCH,
  payload: branch
});

export const clearDropdownBranch = (): Action => ({
  type: ReportsConstants.CLEAR_REPORTSMARTPHARMACY_DROPDOWN_BRANCH,
});

export const setReportFilter = (filter: ReportSmartPharmacyFilter): Action => ({
  type: ReportsConstants.SET_REPORTSMARTPHARMACY_FILTER,
  payload: filter,
});

export const resetReportFiltersOrganisation = (): Action => ({
  type: ReportsConstants.RESET_REPORTSMARTPHARMACY_FILTER_ORGANISATION
});

export const resetReportFiltersBranch = (): Action => ({
  type: ReportsConstants.RESET_REPORTSMARTPHARMACY_FILTER_BRANCH
});

export const resetReportFiltersRest = (): Action => ({
  type: ReportsConstants.RESET_REPORTSMARTPHARMACY_FILTER_REST
});

export const setSummaryItems = (data: any) => ({
  type: ReportsConstants.SET_SMARTPHARMACY_SUMMARYREPORT,
  payload: data,
});

export const setNominationRequests = (data: any) => ({
  type: ReportsConstants.SET_SMARTPHARMACY_NOMINATIONREPORT,
  payload: data,
});

export const setMedicationRequests = (data: any) => ({
  type: ReportsConstants.SET_SMARTPHARMACY_MEDICATIONREPORT,
  payload: data,
});

export const setSummaryItemsPending = (): Action => ({
  type: ReportsConstants.GET_SUMMARY_REPORT_PENDING,
});

export const setSummaryItemsSuccess = (): Action => ({
  type: ReportsConstants.GET_SUMMARY_REPORT_SUCCESS,
});

export const setSummaryItemsError = (): Action => ({
  type: ReportsConstants.GET_SUMMARY_REPORT_ERROR,
});

export const setNominationRequestsPending = (): Action => ({
  type: ReportsConstants.GET_NOMINATION_REQUESTS_PENDING,
});

export const setNominationRequestsSuccess = (): Action => ({
  type: ReportsConstants.GET_NOMINATION_REQUESTS_SUCCESS,
});

export const setNominationRequestsError = (): Action => ({
  type: ReportsConstants.GET_NOMINATION_REQUESTS_ERROR,
});

export const setMedicationRequestsPending = (): Action => ({
  type: ReportsConstants.GET_MEDICATION_REQUESTS_PENDING,
});

export const setMedicationRequestsSuccess = (): Action => ({
  type: ReportsConstants.GET_MEDICATION_REQUESTS_SUCCESS,
});

export const setMedicationRequestsError = (): Action => ({
  type: ReportsConstants.GET_MEDICATION_REQUESTS_ERROR,
});

export const setPrescriptionRequests = (data: any) => ({
  type: ReportsConstants.SET_SMARTPHARMACY_PRESCRIPTIONREPORT,
  payload: data,
});

export const setPrescriptionRequestsPending = (): Action => ({
  type: ReportsConstants.GET_PRESCRIPTION_REQUESTS_PENDING,
});

export const setPrescriptionRequestsSuccess = (): Action => ({
  type: ReportsConstants.GET_PRESCRIPTION_REQUESTS_SUCCESS,
});

export const setPrescriptionRequestsError = (): Action => ({
  type: ReportsConstants.GET_PRESCRIPTION_REQUESTS_ERROR,
});


// TO DO
export const getSmartPharmacySummaryReport = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsSmartPharmacy;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setSummaryItemsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsSmartPharmacy.getSmartPharmacySummaryReport(
        accessToken,
        filters
      )
        .then(summaryItems => {
          dispatch(setSummaryItems(summaryItems));
          dispatch(setSummaryItemsSuccess());
        })
        .catch(err => {
          dispatch(setSummaryItemsError());
          console.log("getSmartPharmacySummaryReport server error or branch was not found", err);
        });
    });
};

export const getSmartPharmacyNominationReport = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsSmartPharmacy;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setNominationRequestsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsSmartPharmacy.getSmartPharmacyNominationReport(
        accessToken,
        filters
      )
        .then(nominationRequests => {
          dispatch(setNominationRequests(nominationRequests));
          dispatch(setNominationRequestsSuccess());
        })
        .catch(err => {
          dispatch(setNominationRequestsError());
          console.log("getSmartPharmacyNominationReport server error or branch was not found", err);
        });
    });
};

export const getSmartPharmacyMedicationReport = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsSmartPharmacy;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setMedicationRequestsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsSmartPharmacy.getSmartPharmacyMedicationReport(
        accessToken,
        filters
      )
        .then(medicationRequests => {
          dispatch(setMedicationRequests(medicationRequests));
          dispatch(setMedicationRequestsSuccess());
        })
        .catch(err => {
          dispatch(setMedicationRequestsError());
          console.log("getSmartPharmacyMedicationReport server error or branch was not found", err);
        });
    });
};

export const getSmartPharmacyPrescriptionReport = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsSmartPharmacy;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setPrescriptionRequestsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsSmartPharmacy.getSmartPharmacyPrescriptionReport(
        accessToken,
        filters
      )
        .then(PrescriptionRequests => {
          dispatch(setPrescriptionRequests(PrescriptionRequests));
          dispatch(setPrescriptionRequestsSuccess());
        })
        .catch(err => {
          dispatch(setPrescriptionRequestsError());
          console.log("getSmartPharmacyPrescriptionReport server error or branch was not found", err);
        });
    });
};

//Download
export const setReportsDownloadOverlay = (isOverlayVisible: boolean): Action => ({
  type: ReportsConstants.SET_REPORTSMARTPHARMACY_DOWNLOAD_OVERLAY,
  payload: isOverlayVisible,
});

export const setReportsDownloadPending = (): Action => ({
  type: ReportsConstants.GET_REPORTSMARTPHARMACY_DOWNLOAD_PENDING,
});

export const setReportsDownloadSuccess = (): Action => ({
  type: ReportsConstants.GET_REPORTSMARTPHARMACY_DOWNLOAD_SUCCESS,
});

export const setReportsDownloadError = (): Action => ({
  type: ReportsConstants.GET_REPORTSMARTPHARMACY_DOWNLOAD_ERROR,
});

export const setReportsDownloadItems = (reportItems: any): Action => ({
  type: ReportsConstants.SET_REPORTSMARTPHARMACY_DOWNLOAD_ITEMS,
  payload: reportItems,
});

export const getSmartPharmcyReportsDownload = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsSmartPharmacy;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setReportsDownloadPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsSmartPharmacy.getSmartPharmcyReportsDownload(
        accessToken,
        filters
      )
        .then(reportItems => {
          dispatch(setReportsDownloadItems(reportItems));
          dispatch(setReportsDownloadSuccess());
        })
        .catch(err => {
          dispatch(setReportsDownloadError());
          console.log("getSmartPharmcyReportsDownload server error or branch was not found", err);
        });
    });
};


