import * as CookieConstants from "constants/CookieConstants";

const setRefreshTokenCookie = (refresh_token) => {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    document.cookie = `${CookieConstants.REFRESH_TOKEN_NAME}=${refresh_token}; expires=${expiryDate.toUTCString()}; path=/;`;
}

const getRefreshTokenCookie = () => {
    const cookie = document.cookie.split('; ');
    const getRefreshToken = cookie.filter(item => item.includes(`${CookieConstants.REFRESH_TOKEN_NAME}=`));
    const valueRefreshToken = getRefreshToken.map(item => decodeURIComponent(item.substring(item.indexOf('=') + 1)))[0] || '';
    return valueRefreshToken;
}

const removeRefreshTokenCookie = () => {
    document.cookie = `${CookieConstants.REFRESH_TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
}

const setUsersCookie = (users) => {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    document.cookie = `${CookieConstants.USERS_NAME}=${users}; expires=${expiryDate.toUTCString()}; path=/;`;
}

const getUsersCookie = () => {
    const cookie = document.cookie.split('; ');
    const getUsers = cookie.filter(item => item.includes(`${CookieConstants.USERS_NAME}=`));
    const valueUsers = getUsers.map(item => decodeURIComponent(item.substring(item.indexOf('=') + 1)))[0] || '';
    return valueUsers;
}

const removeUsersCookie = () => {
    document.cookie = `${CookieConstants.USERS_NAME}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
}

export {
    setRefreshTokenCookie,
    getRefreshTokenCookie,
    removeRefreshTokenCookie,
    setUsersCookie,
    getUsersCookie,
    removeUsersCookie
};
