// UI
export const SET_BRANCH_SERVICES_UI = "SET_BRANCH_SERVICES_UI";

// USER
export const SET_BRANCH_SERVICES_USER_SEARCH = "SET_BRANCH_SERVICES_USER_SEARCH";
export const SET_BRANCH_SERVICES_USER_STEP = "SET_BRANCH_SERVICES_USER_STEP";
export const SET_BRANCH_SERVICES_USER_DATA = "SET_BRANCH_SERVICES_USER_DATA";
export const RESET_BRANCH_SERVICES_USER_DATA = "RESET_BRANCH_SERVICES_USER_DATA";

// SERVICES_SUMMARY
export const GET_BRANCH_SERVICES_SUMMARY_PENDING = "GET_BRANCH_SERVICES_SUMMARY_PENDING";
export const GET_BRANCH_SERVICES_SUMMARY_SUCCESS = "GET_BRANCH_SERVICES_SUMMARY_SUCCESS";
export const GET_BRANCH_SERVICES_SUMMARY_ERROR = "GET_BRANCH_SERVICES_SUMMARY_ERROR";
export const SET_BRANCH_SERVICES_SUMMARY_DATA = "SET_BRANCH_SERVICES_SUMMARY_DATA";

// SERVICES_LIST
export const GET_BRANCH_SERVICES_LIST_PENDING = "GET_BRANCH_SERVICES_LIST_PENDING";
export const GET_BRANCH_SERVICES_LIST_SUCCESS = "GET_BRANCH_SERVICES_LIST_SUCCESS";
export const GET_BRANCH_SERVICES_LIST_ERROR = "GET_BRANCH_SERVICES_LIST_ERROR";
export const SET_BRANCH_SERVICES_LIST_DATA = "SET_BRANCH_SERVICES_LIST_DATA";

// SERVICES_UPDATE
export const PUT_BRANCH_SERVICES_UPDATE_PENDING = "PUT_BRANCH_SERVICES_UPDATE_PENDING";
export const PUT_BRANCH_SERVICES_UPDATE_SUCCESS = "PUT_BRANCH_SERVICES_UPDATE_SUCCESS";
export const PUT_BRANCH_SERVICES_UPDATE_ERROR = "PUT_BRANCH_SERVICES_UPDATE_ERROR";

// SERVICE_DESCRIPTION
export const GET_BRANCH_SERVICES_DESCRIPTION_PENDING = "GET_BRANCH_SERVICES_DESCRIPTION_PENDING";
export const GET_BRANCH_SERVICES_DESCRIPTION_SUCCESS = "GET_BRANCH_SERVICES_DESCRIPTION_SUCCESS";
export const GET_BRANCH_SERVICES_DESCRIPTION_ERROR = "GET_BRANCH_SERVICES_DESCRIPTION_ERROR";
export const SET_BRANCH_SERVICES_DESCRIPTION_SERVICE_ID = "SET_BRANCH_SERVICES_DESCRIPTION_SERVICE_ID";
export const SET_BRANCH_SERVICES_DESCRIPTION_DATA = "SET_BRANCH_SERVICES_DESCRIPTION_DATA";

//REMOVE_SERVICE
export const SET_SERVICE_FUTURE_APPOINTMENTS = "SET_SERVICE_FUTURE_APPOINTMENTS";
