import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { LinkIcon, IconClose } from '@patient-access/ui-kit';
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

import * as RolesConstants from 'constants/RolesConstants';
import type { Branch, Organisation } from "types/organisation";
import type { Action } from "types/actions";
import { getOrganisationsForAsyncSelect } from "actions/organisations";
import { getOrganisationOption, getBranchOption } from 'helpers/common';

import locale from 'service/locale';
import { updateForm, clearForm } from "actions/form";

type Props = {
  handleDeleteNewUserRole: (index: number) => Action,
  index: any,
  form: any,
  updateForm: (data: any) => Action,
  clearForm: () => any,
  organisations: Organisation[],
  currentUserRoles: any[],
  profileCurrentRole: Object,
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
  organisationId: string,
  branchId: string,
  organisationDetails: Organisation
};

type State = {
  role: number,
  organisation: number,
  branch: number,
  branchList: Branch[],
  organisationSearch: string,
  filteredOrganisations: Organisation[],
};

const mapStateToProps = (state: any) => ({
  form: state.form,
  organisations: state.organisations.organisationsListAsync,
  currentUserRoles: state.roles.profileRoles,
  profileCurrentRole: state.roles.profileCurrentRole,
  organisationDetails: state.organisationDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
});

class NewUserRoleItem extends Component<Props, State> {
  state = {
    role: "",
    organisation: "",
    branch: "",
    branchList: [],
    organisationSearch: "",
    filteredOrganisations: [],
  };

  componentDidMount = () => {
    const { getOrganisationsForAsyncSelect } = this.props;
    getOrganisationsForAsyncSelect();
  };

  handleDeleteRole = (e: Event) => {
    e && e.preventDefault();
    const { index, handleDeleteNewUserRole } = this.props;
    this.setState({ role: "", organisation: "", branch: "" }, () => {
      handleDeleteNewUserRole(index);
    });
  };

  handleUserRoleChange = value => {
    const { index, updateForm, form, organisationId, organisationDetails, branchId } = this.props;
    const { newAccounts } = form;
    const organisationOption = (organisationId && value.level > 0) && getOrganisationOption(organisationDetails);
    const branchOption = (branchId && value.level > 1) && getBranchOption(organisationOption, branchId);

    this.setState({
      role: value,
      organisation: (organisationId && value.level > 0) ? organisationOption : "",
      branch: (branchId && value.level > 1) ? branchOption : ""
    }, () => {
      updateForm({
        newAccounts: {
          ...newAccounts,
          [index]: {
            role: value,
            organisation: (organisationId && value.level > 0) ? organisationOption : "",
            branch: (branchId && value.level > 1) ? branchOption : ""
          },
        },
      });
    })
  };

  handleUserOrganizationChange = value => {
    const { index, updateForm, form } = this.props;
    const { newAccounts } = form;

    this.setState(prevState => {
      const { role } = prevState;
      let branches = this.getBranchesList();
      const selectedBranch = role.value && role.value.level > 1
        ? branches.length === 1
          ? branches[0]
          : ''
        : '';

      updateForm({
        newAccounts: {
          ...newAccounts,
          [index]: { role, organisation: value, branch: selectedBranch },
        },
      });
      return { role, organisation: value, branch: selectedBranch, branchList: branches }
    });
  };

  handleUserBranchChange = value => {
    const { index, updateForm, form } = this.props;
    const { newAccounts } = form;

    this.setState(prevState => {
      const { role, organisation } = prevState;

      updateForm({
        newAccounts: {
          ...newAccounts,
          [index]: { role, organisation, branch: value },
        },
      });
      return { role, organisation, branch: value }
    });
  };

  getOrganisationsList = (allOrganisations: any[], level: string, currentOrgId: string) => {
    const ROLES = RolesConstants.PROFILE_ROLES_LEVEL;
    if (level === ROLES[RolesConstants.ADMIN])
      return allOrganisations;
    else // if BRANCH_ADMIN or ORGANIZATION_ADMIN
      return allOrganisations.filter(org => org.id === currentOrgId);
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {

    const { organisations, profileCurrentRole } = nextProps;

    const organisationsList = this.getOrganisationsList(
      organisations,
      profileCurrentRole.roleLevel,
      profileCurrentRole.organizationId
    );

    let newState = { organisationsList };
    if (organisationsList.length === 1){
      newState.organisation = organisationsList[0];
    }

    this.setState(newState);
  }

  getBranchesList = () => {
    const {organisation} = this.state;
    const {profileCurrentRole} = this.props;

    if (!organisation.branches) { return []; }

    if (profileCurrentRole.roleLevel >= 2) {
      return organisation.branches.filter(b => b.id === profileCurrentRole.branchId);
    }

    return organisation.branches.filter(b => b.value);
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        organisationSearch: searchValue,
      }, () => {
        if (searchValue && searchValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  handleLoadOrganisations = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  render() {
    const { currentUserRoles, profileCurrentRole, organisations, organisationId, branchId } = this.props;
    const { role, branch, organisation, organisationSearch } = this.state;

    const relevantUserRoles = currentUserRoles.filter(role => role.level >= profileCurrentRole.roleLevel);

    return (
      <Fragment>
        <hr className="patient-care-roles-separator" />
        <div className="patient-care-modal-edit-row">
          <div className="patient-care-col-4">
            <Select
              onChange={this.handleUserRoleChange}
              backspaceRemovesValue = {false}
              options={relevantUserRoles}
              placeholder={locale.UserDetails.roleSelect}
              classNamePrefix="patient-care"
              isSearchable={false}
            />
          </div>
          <div className="patient-care-col-4">
            {(!!role && role.level !== RolesConstants.PROFILE_ROLES_LEVEL.SuperAdmin) && (
              <AsyncSelect
                defaultValue={organisationId ? organisation : null}
                loadOptions={this.handleLoadOrganisations}
                defaultOptions={organisations}
                placeholder={locale.UserDetails.organisationSelect}
                onChange={this.handleUserOrganizationChange}
                onInputChange={this.handleSearchChange}
                inputValue={organisationSearch}
                classNamePrefix="patient-care"
                isDisabled={!!organisationId}
                openMenuOnFocus={true}
              />
            )}
          </div>
          <div className="patient-care-col-4">
            {(!!role && (role.level === RolesConstants.PROFILE_ROLES_LEVEL.BranchAdmin)) && (
              <Select
                defaultValue={branchId ? branch : null}
                onChange={this.handleUserBranchChange}
                options={this.getBranchesList()}
                value={branch}
                isDisabled={!organisation || !!branchId}
                placeholder={locale.UserDetails.branchSelect}
                classNamePrefix="patient-care"
              />
            )}
          </div>
          <div className="patient-care-btn-delete-row">
            <LinkIcon
              to="#delete"
              size="smaller"
              icon={<IconClose outline />}
              accessibilitySpan={locale.UserDetails.deleteRoleIcon}
              onClick={this.handleDeleteRole}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUserRoleItem);
