import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from "@patient-access/ui-kit";

import * as RolesConstants from "constants/RolesConstants";
import type { OrganisationDetails, Branch } from 'types/organisation';

import locale from 'service/locale';
import './styles.scss';

import * as RoutesConstants from 'constants/RoutesConstants';

type Props = {
  organisationId: string,
  branchId?: string,
  organisationDetails: OrganisationDetails,
  branchesList: Branch[],
  currentRole: any,
  currentPage: string,
  calendarsList: any[],
  services: any[],
  roomsList: any[],
};

type State = {
  branch: Object | "Loading...";
  organisation: Object | "Loading...";
}

const mapStateToProps = (state) => ({
  organisationsList: state.organisations.organisationsList,
  branchesList: state.branches.branchesList,
  organisationDetails: state.organisationDetails,
  currentRole: state.roles.profileCurrentRole,
  calendarsList: state.branchDetails.calendarsList,
  services: state.branchDetails.services,
  roomsList: state.rooms.roomsList,
});

class Bcrumbs extends Component<Props, State> {

  state = {
    branch: this.props.organisationDetails.branches.length
      ? this.props.organisationDetails.branches.find(branch => branch.id === this.props.branchId)
      : "Loading...",
    organisation: this.props.organisationDetails.organisationId
      ? this.props.organisationDetails
      : "Loading...",
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.organisationDetails.organisationId) {
      this.setState({
        organisation: nextProps.organisationDetails
      });
    }
    if (nextProps.organisationDetails.branches.length) {
      this.setState({
        branch:
          nextProps.organisationDetails.branches.find(branch => branch.id === nextProps.branchId)
      });
    }
  };

  getBreadcrumbsClassName = (page) => {
    const { calendarsList, services, roomsList } = this.props;

    if (page === locale.CurrentPage.branchCalendars) {
      if (calendarsList.length !== 0) {
        return "has-button";
      }
    }

    if (page === locale.CurrentPage.branchServices) {
      if (services.length !== 0) {
        return "has-button";
      }
    }

    if (page === locale.CurrentPage.branchRooms) {
      if (roomsList.length !== 0) {
        return "has-button";
      }
    }

    return "";
  }

  render() {
    const { branchId, organisationId, currentRole, currentPage } = this.props;

    let { organisation, branch } = this.state;
    const isAdmin = currentRole.role === RolesConstants.ADMIN;
    organisation = organisation ? organisation : {};
    branch = branch ? branch : {};
    const breadcrumbs: Object = {};

    if (isAdmin) {
      breadcrumbs.adminLevel = {
        name: locale.Sidebars.superAdminPanel,
        path: RoutesConstants.ADMIN_ORGANISATIONS,
      };
    }

    breadcrumbs.organisationLevel = {
      name: isAdmin? organisation.name || organisation : "Organisation panel",
      path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`,
    };

    if (branchId) {
      breadcrumbs.branchLevel = {
        name: branch.name,
        path: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.USERS}`,
      };
    }

    return (
      <div className={`
        patient-care-user-breadcrumbs-holder
        ${this.getBreadcrumbsClassName(currentPage)}
      `}>
        <div className="patient-care-container">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps)(Bcrumbs);
