import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';

type OrganisationServices = {
  // TODO
}

const initialState: OrganisationServices = {
  servicesSummary: {
    data: [],
    isLoading: false,
    isError: false,
  },
  servicesList: {
    data: [],
    isLoading: false,
    isError: false,
  },
  serviceDescription: {
    serviceId: '',
    data: [],
    isLoading: false,
    isError: false,
  },
  branchesSummary: {
    data: [],
    isLoading: false,
    isError: false,
  },
  branchesList: {
    data: [],
    isLoading: false,
    isError: false,
  },
  ui: {
    overlayCofirmationMode: '', // OneOff: OVERLAY_MODE.ADD || OVERLAY_MODE.EDIT || OVERLAY_MODE.DELETE
    isOverlayDescriptionVisible: false,
    isOverlayConfirmationVisible: false,
    isSidebarAddVisible: false,
    isSidebarEditVisible: false,
    isEditByBranch: false,
    responseFromApi: [],
    requestMode: '', // OneOff: OVERLAY_MODE.ADD || OVERLAY_MODE.EDIT || OVERLAY_MODE.DELETE
    isOverlayBranchesVisible: false,
    isBulkUpdateCancel: false,
    isBulkUpdateComplete: false,
  },
  user: {
    step: 1,
    search: {
      servicesSummary: '',
      servicesList: '',
      branchesSummary: '',
      branchesList: '',
    },
    data: {
      serviceId: '',
      serviceName: '',
      serviceAvailableModes: [],
      serviceIsNhsCovered: false,
      serviceModes: [],
      serviceIsActive: null, // boolean
      servicePrice: null, // number
      serviceIsVatIncluded: null, // boolean
      serviceDuration: null, // number
      serviceCutOff: null, // number
      serviceBranches: [],
      serviceIsShowPrice: null, // boolean
      bookingUrl: {
        isEdited: false,
        value: null
      }
    },
    editData: {
      serviceModes: {
        isEdited: false,
        value: []
      },
      serviceIsActive: {
        isEdited: false,
        value: false
      },
      servicePrice: {
        isEdited: false,
        value: null
      },
      serviceIsVatIncluded: {
        isEdited: false,
        value: false
      },
      serviceDuration: {
        isEdited: false,
        value: null
      },
      serviceCutOff: {
        isEdited: false,
        value: null
      },
      serviceIsShowPrice:{
        isEdited: false,
        value: false
      },
      bookingUrl:{
        isEdited: false,
        value: null
      },
      selectedSummaryBranches:[]
    },
    isAddServiceDetailsPending: false,
    isAddServiceDetailsError: false,
    isDeleteServicePending: false,
    isDeleteServiceError: false,
    isUpdateServiceDetailsPending: false,
    isUpdateServiceDetailsError: false,
  },
};

export default (localState: OrganisationServices = initialState, action: Action): OrganisationServices => {
  switch (action.type) {
    // UI
    case OrganisationServicesConstants.SET_ORG_SER_UI:
      return {
        ...localState,
        ui: {
          ...localState.ui,
          ...action.payload,
        }
      };

    // USER
    case OrganisationServicesConstants.SET_ORG_SER_USER_SEARCH:
      return {
        ...localState,
        user: {
          ...localState.user,
          search: {
            ...localState.user.search,
            ...action.payload
          }
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_USER_STEP:
      return {
        ...localState,
        user: {
          ...localState.user,
          step: action.payload,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_USER_DATA:
      return {
        ...localState,
        user: {
          ...localState.user,
          data: {
            ...localState.user.data,
            ...action.payload
          }
        }
      };
    case OrganisationServicesConstants.RESET_ORG_SER_USER_DATA:
      return {
        ...localState,
        user: {
          ...localState.user,
          data: {
            ...initialState.user.data,
          }
        },
      };
    // Edit data
    case OrganisationServicesConstants.SET_ORG_SER_USER_EDIT_DATA:
      return {
        ...localState,
        user: {
          ...localState.user,
          ...localState.editData,
          editData: {
            ...localState.user.editData,
            ...action.payload
          },
        }
      };
    case OrganisationServicesConstants.RESET_ORG_SER_USER_EDIT_DATA:
      return {
        ...localState,
        user: {
          ...localState.user,
          editData: {
            ...initialState.user.editData,
          }
        },
      };
    // Add a service
    case OrganisationServicesConstants.SET_SERVICE_DETAILS_PENDING:
      return {
        ...localState,
        user: {
          ...localState.user,
          isAddServiceDetailsPending: true,
          isAddServiceDetailsError: false
        }
      };
    case OrganisationServicesConstants.SET_SERVICE_DETAILS_SUCCESS:
      return {
        ...localState,
        user: {
          ...localState.user,
          isAddServiceDetailsPending: false,
          isAddServiceDetailsError: false
        }
      };
    case OrganisationServicesConstants.SET_SERVICE_DETAILS_ERROR:
      return {
        ...localState,
        user: {
          ...localState.user,
          isAddServiceDetailsPending: false,
          isAddServiceDetailsError: true
        }
      };
  
    //DELETE_SERVICE
    case OrganisationServicesConstants.DELETE_ORG_SERVICES_PENDING:
      return {
        ...localState,
        user: {
          ...localState.user,
          isDeleteServicePending: true,
          isDeleteServiceError: false
        }
      };
    case OrganisationServicesConstants.DELETE_ORG_SERVICES_SUCCESS:
      return {
        ...localState,
        user: {
          ...localState.user,
          isDeleteServicePending: false,
          isDeleteServiceError: false
        }
      };
    case OrganisationServicesConstants.DELETE_ORG_SERVICES_ERROR:
      return {
        ...localState,
        user: {
          ...localState.user,
          isDeleteServicePending: false,
          isDeleteServiceError: true
        }
      };
    //UPDATE_SERVICE
    case OrganisationServicesConstants.UPDATE_SERVICES_DETAILS_PENDING:
      return {
        ...localState,
        user: {
          ...localState.user,
          isUpdateServiceDetailsPending: true,
          isUpdateServiceDetailsError: false
        }
      };
    case OrganisationServicesConstants.UPDATE_SERVICES_DETAILS_SUCCESS:
      return {
        ...localState,
        user: {
          ...localState.user,
          isUpdateServiceDetailsPending: false,
          isUpdateServiceDetailsError: false
        }
      };
    case OrganisationServicesConstants.UPDATE_SERVICES_DETAILS_ERROR:
      return {
        ...localState,
        user: {
          ...localState.user,
          isUpdateServiceDetailsPending: false,
          isUpdateServiceDetailsError: true
        }
      };
    // SERVICES_SUMMARY
    case OrganisationServicesConstants.GET_ORG_SER_SERVICES_SUMMARY_PENDING:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          isLoading: true,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_SERVICES_SUMMARY_SUCCESS:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          isLoading: false,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_SERVICES_SUMMARY_ERROR:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          isLoading: false,
          isError: true,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_SERVICES_SUMMARY_DATA:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          data: action.payload,
        }
      };

    // SERVICES_LIST
    case OrganisationServicesConstants.GET_ORG_SER_SERVICES_LIST_PENDING:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          isLoading: true,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_SERVICES_LIST_SUCCESS:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          isLoading: false,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_SERVICES_LIST_ERROR:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          isLoading: false,
          isError: true,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_SERVICES_LIST_DATA:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          data: action.payload,
        }
      };

    // BRANCHES_SUMMARY
    case OrganisationServicesConstants.GET_ORG_SER_BRANCHES_SUMMARY_PENDING:
      return {
        ...localState,
        branchesSummary: {
          ...localState.branchesSummary,
          isLoading: true,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_BRANCHES_SUMMARY_SUCCESS:
      return {
        ...localState,
        branchesSummary: {
          ...localState.branchesSummary,
          isLoading: false,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_BRANCHES_SUMMARY_ERROR:
      return {
        ...localState,
        branchesSummary: {
          ...localState.branchesSummary,
          isLoading: false,
          isError: true,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_BRANCHES_SUMMARY_DATA:
      return {
        ...localState,
        branchesSummary: {
          ...localState.branchesSummary,
          data: action.payload,
        }
      };

    // BRANCHES_LIST
    case OrganisationServicesConstants.GET_ORG_SER_BRANCHES_LIST_PENDING:
      return {
        ...localState,
        branchesList: {
          ...localState.branchesList,
          isLoading: true,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_BRANCHES_LIST_SUCCESS:
      return {
        ...localState,
        branchesList: {
          ...localState.branchesList,
          isLoading: false,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_BRANCHES_LIST_ERROR:
      return {
        ...localState,
        branchesList: {
          ...localState.branchesList,
          isLoading: false,
          isError: true,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_BRANCHES_LIST_DATA:
      return {
        ...localState,
        branchesList: {
          ...localState.branchesList,
          data: action.payload,
        }
      };

    // SERVICE_DESCRIPTION
    case OrganisationServicesConstants.GET_ORG_SER_SERVICE_DESCRIPTION_PENDING:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          isLoading: true,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_SERVICE_DESCRIPTION_SUCCESS:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          isLoading: false,
          isError: false,
        }
      };
    case OrganisationServicesConstants.GET_ORG_SER_SERVICE_DESCRIPTION_ERROR:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          isLoading: false,
          isError: true,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_SERVICE_DESCRIPTION_SERVICE_ID:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          serviceId: action.payload,
        }
      };
    case OrganisationServicesConstants.SET_ORG_SER_SERVICE_DESCRIPTION_DATA:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          data: action.payload,
        }
      };

    // DEFAULT
    default:
      return localState;
  }
};
