import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import find from "lodash.find";
import { Button } from "@patient-access/ui-kit";
import type { Action } from "types/actions";
import type { ConfirmationStatus } from "constants/BranchesConstants";
import * as RolesConstants from "constants/RolesConstants";
import * as BranchesConstants from "constants/BranchesConstants";
import * as RoutesConstants from 'constants/RoutesConstants';
import * as OrganisationsConstants from "constants/OrganisationsConstants";
import { deleteBranch, removeRedirect, getFutureAppointmentsForBranch } from "actions/branches";
import { setBranchConfirmationStatus, getBranchDetails, updateBranch, setBranchPublishStatus, setBranchSmartPharmacyEnabled } from "actions/branchDetails";
import { setBranchHeader } from "actions/router";
import ConfirmationOverlay from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import ConfirmByPasswordOverlay from "components/Share/ConfirmByPasswordOverlay/ConfirmByPasswordOverlay";
import ConflictModal from "components/Share/ConflictModal/ConflictModal";
import BranchType from "./BranchType/BranchType";
import BranchVisibility from "./BranchVisibility/BranchVisibility";
import SmartPharmacy from './SmartPharmacy/SmartPharmacy';
import locale from "service/locale";

type Props = {
  branchId: string,
  organisationId: string,
  getFutureAppointmentsForBranch: (branchId: string) => any,
  deleteBranch: (branchId: string) => any,
  updateBranch: (branchId: string, branchData: Object) => any,
  setBranchConfirmationStatus: (status: ConfirmationStatus) => Action,
  getBranchDetails: (organisationId: string, branchId: string) => any,
  currentRole: Object,
  form: Object,
  confirmationStatus: ConfirmationStatus,
  isActiveAgenda: boolean,
  branchDetails: Object,
  organisationDetails: any,
  branches: any[],
  removeRedirect: () => Action,
  setBranchHeader: (branchId: string) => any,
  isUpdateBranchSettingsPending: boolean,
  isBranchDeletionPending: boolean,
  setBranchPublishStatus: (status: Boolean) => Action,
  isVirtual: boolean
};

type State = {
  branch: Object,
  isConfirmDialogOpened: boolean,
  isConfirmationError: boolean,
  isEmptyBranchAddress: boolean,
  isRedirect: {
    status: boolean,
    redirectUrl: string
  },
  errorMessage: string,
  isBookingsWarningOpened: boolean,
  isUncompletedWarningOpened: boolean,
  isEditPublishBranchMode: Boolean,
  isDataChanged: boolean,
  isSmartPharmacyOverlayVisible: boolean,
  openDiscardChanges: boolean
};

const mapStateToProps = state => ({
  branches: state.branches.branchesList,
  branchDetails: state.branchDetails,
  organisationDetails: state.organisationDetails,
  currentRole: state.roles.profileCurrentRole,
  confirmationStatus: state.branchDetails.confirmationStatus,
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda,
  isUpdateBranchSettingsPending: state.branchDetails.isUpdateBranchSettingsPending,
  isBranchDeletionPending: state.branches.isBranchDeletionPending,
  isVirtual: state.branchDetails.isVirtual
});

const mapDispatchToProps = (dispatch: Function): any => ({
  getFutureAppointmentsForBranch: (branchId) => dispatch(getFutureAppointmentsForBranch(branchId)),
  deleteBranch: (branchId) => dispatch(deleteBranch(branchId)),
  updateBranch: (branchId, branchData) => dispatch(updateBranch(branchId, branchData)),
  setBranchConfirmationStatus: status => dispatch(setBranchConfirmationStatus(status)),
  getBranchDetails: (organisationId, branchId) => dispatch(getBranchDetails(organisationId, branchId)),
  removeRedirect: () => dispatch(removeRedirect()),
  setBranchHeader: (branchId) => dispatch(setBranchHeader(branchId)),
  setBranchPublishStatus: (status) => dispatch(setBranchPublishStatus(status)),
  setBranchSmartPharmacyEnabled: (feature) => dispatch(setBranchSmartPharmacyEnabled(feature)),
});

class BranchSettings extends Component<Props, State> {
  state = {
    branch: this.props.branchDetails,
    isConfirmDialogOpened: false,
    isConfirmationError: false,
    isEmptyBranchAddress: false,
    isRedirect: {
      status: false,
      redirectUrl: ""
    },
    errorMessage: "",
    isBookingsWarningOpened: false,
    isUncompletedWarningOpened: false,
    isEditPublishBranchMode: false,
    isEditSmartPharmacy: false,
    isDataChanged: false,
    isSmartPharmacyOverlayVisible: false,
    openDiscardChanges: false,
  }

  componentDidMount = () => {
    const { getBranchDetails, branchId, setBranchConfirmationStatus, setBranchHeader, organisationId } = this.props;
    setBranchHeader(branchId);
    setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING);
    getBranchDetails(organisationId, branchId);
  }

  componentWillUnmount = () => {
    const { removeRedirect } = this.props;
    removeRedirect();
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { confirmationStatus, organisationId, branchDetails, branchId } = nextProps;

    if (branchDetails && !branchDetails.isUpdateBranchSettingsPending) {
      this.setState({ branch: branchDetails });
    }

    let errorMessage = "";
    switch (confirmationStatus) {
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING:
        this.setState({ isConfirmationError: false });
        return;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.LAST_ATTEMPT:
        errorMessage = locale.Modals.confirmByPasswordOverlay.lastAttemptWarning;
        break;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.WRONG:
        errorMessage = locale.Modals.confirmByPasswordOverlay.wrongPasswordWarning;
        break;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.BLOCKED:
        errorMessage = locale.Modals.confirmByPasswordOverlay.lockedWarning;
        break;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED:
        this.setState(
          {
            isConfirmDialogOpened: false,
            isRedirect: {
              status: true,
              redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`
            }
          },
          this.clearConfirmationStatus
        );
        return;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.UPDATED:
        this.setState(
          {
            isConfirmDialogOpened: false,
            isRedirect: {
              status: true,
              redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.SETTINGS}`
            }
          },
          this.clearConfirmationStatus
        );
        return;
      case BranchesConstants.PASSWORD_WARNING_STATUSES.NO_BOOKINGS:
        this.setState({ isConfirmDialogOpened: true }, this.clearConfirmationStatus);
        return;
      case BranchesConstants.PASSWORD_WARNING_STATUSES.HAS_BOOKINGS:
        this.setState({ isBookingsWarningOpened: true }, this.clearConfirmationStatus);
        return;
      case BranchesConstants.PASSWORD_WARNING_STATUSES.HAS_UNCOMPLETED:
        this.setState({ isUncompletedWarningOpened: true }, this.clearConfirmationStatus);
        return;
      default:
        break;
    }

    this.setState({ isConfirmationError: true, errorMessage });
  }

  handleCloseBookingsWarning = () => {
    this.setState({ isBookingsWarningOpened: false });
  }

  handleCloseUncompletedWarning = () => {
    this.setState({
      isUncompletedWarningOpened: false,
      isConfirmDialogOpened: false
    });
  }

  clearConfirmationStatus = () => {
    const { setBranchConfirmationStatus } = this.props;
    setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING);
  }

  handleDeleteBranch = () => {
    const { deleteBranch, branchId } = this.props;
    deleteBranch(branchId);
  }

  handleOpenConfirmDialog = (e: any) => {
    e && e.preventDefault();
    const { getFutureAppointmentsForBranch, branchId } = this.props;
    getFutureAppointmentsForBranch(branchId);
  }

  handleCloseConfirmDialog = (e: any) => {
    e && e.preventDefault();
    this.setState({ isConfirmDialogOpened: false });
  }

  handleDiscardChanges = (e: any) => {
    e && e.preventDefault();
    this.setState({ openDiscardChanges: true });
  }

  handleUpdateBranch = (e: any) => {
    e && e.preventDefault();
    const { branch } = this.state;
    const { updateBranch, branchId, branchDetails } = this.props;
    const featureMedicationNotifications = branchDetails && branchDetails.supportedFeatures && branchDetails.supportedFeatures.find(feature => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS);
    const featureMedicationNotificationsClone = branchDetails && branchDetails.supportedFeatures && branchDetails.supportedFeaturesClone.find(feature => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS);
    if ((featureMedicationNotifications && featureMedicationNotifications.isEnabled) !== (featureMedicationNotificationsClone && featureMedicationNotificationsClone.isEnabled)) {
      this.setState({ isSmartPharmacyOverlayVisible: true });
    } else {
      updateBranch(branchId, branch);
    }
  }

  handleSelectPublishBranchEditMode = (e: any) => {
    const { isEditPublishBranchMode } = this.state;
    if (isEditPublishBranchMode) {
      this.setState({ isEditPublishBranchMode: false });
    } else {
      this.setState({ isEditPublishBranchMode: true });
    }
  }

  handlePublishStatusChange = (publish: boolean) => {
    const { setBranchPublishStatus } = this.props;
    this.setState({ isDataChanged: true });
    setBranchPublishStatus(!publish);
  }

  handleToggleSmartPharmacy = (isEnabled: boolean) => {
    const { setBranchSmartPharmacyEnabled } = this.props;
    this.setState({ isDataChanged: true });
    setBranchSmartPharmacyEnabled({ name: OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS, isEnabled: isEnabled });
  }

  handleConfirm = () => {
    const { branches, branchId, organisationId, branchDetails, getBranchDetails } = this.props;
    this.setState({
        isDataChanged: false,
        openDiscardChanges: false,
        isEditPublishBranchMode: false,
        isEditSmartPharmacy: false,
        branch: find(branches, { id: branchId }) || branchDetails,
      }, () => { getBranchDetails(organisationId, branchId); }
    );
  }

  handleCancel = () => {
    this.setState({ openDiscardChanges: false });
  }

  handleIsEditSmartPharmacy = () => {
    const { isEditSmartPharmacy } = this.state;
    this.setState({ isEditSmartPharmacy: !isEditSmartPharmacy });
  }

  render() {
    const { branch, isConfirmDialogOpened, isConfirmationError, isRedirect, errorMessage, isBookingsWarningOpened, isUncompletedWarningOpened, isEditPublishBranchMode, isDataChanged, openDiscardChanges, isEditSmartPharmacy } = this.state;
    const { currentRole, branchDetails, organisationDetails, isBranchDeletionPending, isActiveAgenda, isUpdateBranchSettingsPending, isVirtual } = this.props;

    const orgSmartPharmacyFeature = organisationDetails && organisationDetails.supportedFeatures && organisationDetails.supportedFeatures.find(feature => feature.name === OrganisationsConstants.SMART_PHARMACY);
    const isOrganisationSmartPharmacy = orgSmartPharmacyFeature && orgSmartPharmacyFeature.isEnabled;
    const isAbleToDelete = currentRole.role === RolesConstants.ADMIN;
    const isAbleToEdit = currentRole.role === RolesConstants.ADMIN;
    const isPublished = !branch.isDraft;

    const featureMedicationNotifications = branchDetails && branchDetails.supportedFeatures && branchDetails.supportedFeatures.find(feature => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS);
    const featureMedicationNotificationsClone = branchDetails && branchDetails.supportedFeatures && branchDetails.supportedFeaturesClone.find(feature => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS);

    const renderSmartPharmacy = () => {
      if (isOrganisationSmartPharmacy) {
        return (
          <SmartPharmacy
            isAbleToEdit={isAbleToEdit}
            isEditMode={isEditSmartPharmacy}
            featureMedicationNotifications={featureMedicationNotifications}
            featureMedicationNotificationsClone={featureMedicationNotificationsClone}
            handleIsEditSmartPharmacy={this.handleIsEditSmartPharmacy}
            handleToggleSmartPharmacy={this.handleToggleSmartPharmacy}
          />
        );
      }
      return null;
    }

    const renderDeleteBranch = () => {
      if (isAbleToDelete) {
        return (
          <Fragment>
            <div className="patient-care-description-block">
              <div className="patient-care-heading-row">
                <h3>{locale.BranchSettings.removeBranch}</h3>
              </div>
              <p>{locale.BranchSettings.removeDescription1}</p>
              <div className="patient-care-block">
                <p>{locale.BranchSettings.removeDescription2}</p>
              </div>
            </div>
            <Button
              className="patient-care-btn-remove"
              buttonType="outline"
              messageKey="remove-btn"
              defaultMessage={locale.BranchSettings.removeBranch}
              onClick={this.handleOpenConfirmDialog}
              data-id="remove-btn"
            />
          </Fragment>
        );
      }
      return null;
    }

    const renderConfirmDialogOverlay = () => {
      if (isConfirmDialogOpened) {
        return (
          <ConfirmByPasswordOverlay
            type="branch"
            handleDoNotConfirm={this.handleCloseConfirmDialog}
            handleConfirm={this.handleDeleteBranch}
            isConfirmationError={isConfirmationError}
            errorMessage={errorMessage}
            isLoading={isBranchDeletionPending}
            entityName={branch.name}
          />
        );
      }
      return null;
    }

    const renderBookingsWarningOverlay = () => {
      if (isBookingsWarningOpened) {
        return (
          <ConflictModal
            title={locale.Modals.deleteBranchConflictOverlay.header}
            body={locale.Modals.deleteBranchConflictOverlay.content}
            buttonText={locale.Modals.deleteBranchConflictOverlay.buttonOk}
            handleStay={this.handleCloseBookingsWarning}
          />
        );
      }
      return null;
    }

    const renderUncompletedWarningOverlay = () => {
      if (isUncompletedWarningOpened) {
        return (
          <ConflictModal
            title={locale.Modals.deleteBranchWithUncompletedConflictOverlay.header}
            body={locale.Modals.deleteBranchWithUncompletedConflictOverlay.content}
            buttonText={locale.Modals.deleteBranchWithUncompletedConflictOverlay.buttonOk}
            handleStay={this.handleCloseUncompletedWarning}
          />
        );
      }
      return null;
    }

    const renderDiscardChangesOverlay = () => {
      if (openDiscardChanges) {
        return (
          <ConfirmationOverlay
            handleConfirm={this.handleConfirm}
            handleCancel={this.handleCancel}
            content={locale.Modals.discardChangesOverlay.content}
            header={locale.Modals.discardChangesOverlay.header}
            buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
            buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
          />
        );
      }
      return null;
    }

    const renderSmartPharmacyOverlay = () => {
      const { branch, isSmartPharmacyOverlayVisible } =  this.state;
      const { updateBranch, branchId } = this.props;
      const featureMedicationNotifications = branch && branch.supportedFeatures && branch.supportedFeatures.find(feature => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS);

      if (isSmartPharmacyOverlayVisible) {
        return (
          <ConfirmationOverlay
            handleConfirm={() => { updateBranch(branchId, branch); this.setState({ isSmartPharmacyOverlayVisible: false }); }}
            handleCancel={() => { this.setState({ isSmartPharmacyOverlayVisible: false }); }}
            header={!featureMedicationNotifications.isEnabled ? locale.BranchSettings.smartPharmacyOverlay.headerDisabled : locale.BranchSettings.smartPharmacyOverlay.headerEnabled}
            content={!featureMedicationNotifications.isEnabled ? locale.BranchSettings.smartPharmacyOverlay.descriptionDisabled : locale.BranchSettings.smartPharmacyOverlay.descriptionEnabled}
            buttonConfirm={!featureMedicationNotifications.isEnabled ? locale.BranchSettings.smartPharmacyOverlay.buttonDisabled : locale.BranchSettings.smartPharmacyOverlay.buttonEnabled}
            buttonCancel={locale.BranchSettings.smartPharmacyOverlay.buttonBack}
          />
        );
      }
      return null;
    }

    const renderFooterButtons = () => {
      if (isDataChanged) {
        return (
          <div className={`patient-care-buttons-fixed-bottom ${isActiveAgenda ? "is-active-agenda" : ""}`}>
            <div className="patient-care-container-sm">
              <div className="patient-care-row-align-right">
                <Button
                  buttonType="blueline"
                  messageKey="discard-btn"
                  defaultMessage={locale.Appointment.buttons.discardChanges}
                  onClick={this.handleDiscardChanges}
                  isLoading={isUpdateBranchSettingsPending}
                  data-id="discard-btn"
                  className="patient-care-btn-in-group"
                />
                <Button
                  buttonType="secondary"
                  messageKey="save-changes-btn"
                  defaultMessage={locale.Appointment.buttons.saveChanges}
                  onClick={this.handleUpdateBranch}
                  isLoading={isUpdateBranchSettingsPending}
                  data-id="save-changes-btn"
                  className="patient-care-btn-in-group"
                />
              </div>
            </div>
          </div>
        );
      }
      return null;
    }

    if (isRedirect.status) return <Redirect to={isRedirect.redirectUrl} />;
    return (
      <div className={isDataChanged ? "patient-care-details-holder data-changed" : "patient-care-details-holder"}>
        <div className="patient-care-container-sm">
          <BranchType isVirtual={isVirtual}/>
          <BranchVisibility
            isEditPublishBranchMode={isEditPublishBranchMode}
            isPublished={isPublished}
            isAbleToEdit={isAbleToEdit}
            branchName={branch.name}
            handlePublishStatusChange={this.handlePublishStatusChange}
            handleSelectPublishBranchEditMode={this.handleSelectPublishBranchEditMode}
          />
          {renderSmartPharmacy()}
          {renderDeleteBranch()}
        </div>
        {renderConfirmDialogOverlay()}
        {renderBookingsWarningOverlay()}
        {renderUncompletedWarningOverlay()}
        {renderDiscardChangesOverlay()}
        {renderSmartPharmacyOverlay()}
        {renderFooterButtons()}

        <NavigationPrompt when={isDataChanged} >
          {({ onConfirm, onCancel }) => (
            <ConfirmationOverlay
              handleConfirm={onConfirm}
              handleCancel={onCancel}
              content={locale.Modals.discardChangesOverlay.content}
              header={locale.Modals.discardChangesOverlay.header}
              buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
              buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
            />
          )}
        </NavigationPrompt>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchSettings);
