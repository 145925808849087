import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Tabs as TabsWrapper } from "@patient-access/ui-kit";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import { updateFilter } from 'actions/filter';
import { openModalOverlay } from 'actions/panel';

import type { Action } from 'types/actions';
import type { Filter } from 'types/filter';
import * as ProfileConstants from 'constants/ProfileConstants';
import * as RolesConstants from 'constants/RolesConstants';
import * as UserConstants from "constants/UsersConstants";
import * as RoutesConstants from 'constants/RoutesConstants';

import locale from 'service/locale';

type Props = {
  currentRole: Object,
  organisationId?: string,
  branchId?: string,
  filter: Filter,
  updateFilter: (data: any) => Action,
  isLowResolution: boolean,
  openModalOverlay: () => Action,
}

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  filter: state.filter,
  isLowResolution: state.panel.isLowResolution,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (data) => dispatch(updateFilter(data)),
  openModalOverlay: () => dispatch(openModalOverlay()),
});

class UsersTab extends Component<Props> {

  filterUsersByRole = (e: Event, params: Object) => {
    const { updateFilter } = this.props;

    e.preventDefault();
    updateFilter({
      role: params.filter
    });
  };

  filterUsersByRoleSelect = (option: Object) => {
    const { updateFilter } = this.props;

    updateFilter({
      role: option.value.filter
    });
  };

  handleAddUser = () => {
    const { openModalOverlay } = this.props;
    openModalOverlay();
  };

  render() {
    const { currentRole, isLowResolution } = this.props;

    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;

    const renderTabs = () => {
      return (
        <Fragment>
          <div className="patient-care-user-filters-holder">
            <div className="patient-care-container-flex">
              {!isLowResolution ? (
                <TabsWrapper>
                  <Tabs className="ui-kit-tabs">
                    <TabList className="ui-kit-tabs-list">
                      <Tab
                        className="ui-kit-tabs-item"
                        selectedClassName="ui-kit-tabs-item-selected"
                        onClick={(e: Event) => this.filterUsersByRole(e, ProfileConstants.USER_FILTERS.all)}
                      >
                        <a href={RoutesConstants.ADMIN}>
                          {ProfileConstants.USER_FILTERS.all.title}
                        </a>
                      </Tab>
                      {
                        isSuperAdmin &&
                        <Tab
                          className="ui-kit-tabs-item"
                          selectedClassName="ui-kit-tabs-item-selected"
                          onClick={(e: Event) => this.filterUsersByRole(e, ProfileConstants.USER_FILTERS.admins)}
                        >
                          <a href={RoutesConstants.ADMIN}>
                            {ProfileConstants.USER_FILTERS.admins.title}
                          </a>
                        </Tab>
                      }
                      <Tab
                        className="ui-kit-tabs-item"
                        selectedClassName="ui-kit-tabs-item-selected"
                        onClick={(e: Event) => this.filterUsersByRole(e, ProfileConstants.USER_FILTERS.orgAdmins)}
                      >
                        <a href={RoutesConstants.ADMIN}>
                          {ProfileConstants.USER_FILTERS.orgAdmins.title}
                        </a>
                      </Tab>
                      <Tab
                        className="ui-kit-tabs-item"
                        selectedClassName="ui-kit-tabs-item-selected"
                        onClick={(e: Event) => this.filterUsersByRole(e, ProfileConstants.USER_FILTERS.branchAdmins)}
                      >
                        <a href={RoutesConstants.ADMIN}>
                          {ProfileConstants.USER_FILTERS.branchAdmins.title}
                        </a>
                      </Tab>
                      <Tab
                        className="ui-kit-tabs-item"
                        selectedClassName="ui-kit-tabs-item-selected"
                        onClick={(e: Event) => this.filterUsersByRole(e, ProfileConstants.USER_FILTERS.branchMembers)}
                      >
                        <a href={RoutesConstants.ADMIN}>
                          {ProfileConstants.USER_FILTERS.branchMembers.title}
                        </a>
                      </Tab>
                    </TabList>
                    <TabPanel className="ui-kit-tabs-panel" selectedClassName="ui-kit-tabs-panel-selected" />
                    {isSuperAdmin && <TabPanel className="ui-kit-tabs-panel" selectedClassName="ui-kit-tabs-panel-selected" />}
                    <TabPanel className="ui-kit-tabs-panel" selectedClassName="ui-kit-tabs-panel-selected" />
                    <TabPanel className="ui-kit-tabs-panel" selectedClassName="ui-kit-tabs-panel-selected" />
                    <TabPanel className="ui-kit-tabs-panel" selectedClassName="ui-kit-tabs-panel-selected" />
                  </Tabs>
                </TabsWrapper>
              ) : (
                <Select
                  id="users-select"
                  name="users-select"
                  defaultValue={UserConstants.USERS_FILTER_OPTIONS[0]}
                  options={UserConstants.USERS_FILTER_OPTIONS}
                  classNamePrefix="patient-care"
                  className="patient-care-select"
                  onChange={this.filterUsersByRoleSelect}
                />
              )}

              <div className="patient-care-align-right">
                {!isBranchMember && (
                  <Button
                    buttonType="secondary"
                    messageKey="add-user-btn"
                    defaultMessage={locale.UsersListRedesign.button.addUser}
                    onClick={this.handleAddUser}
                    data-id="add-user-btn"
                    className="patient-care-btn-in-group"
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
          {renderTabs()}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
