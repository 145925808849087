import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Overlay, Button, Skeleton } from '@patient-access/ui-kit';
import { setUI, putBranchServicesUpdate } from 'actions/branchServices';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  branchId: string,
  isLoading: boolean,
  userData: any,
  servicesSummary: any,
  isOverlayRemoveServiceVisible: boolean,
  serviceFutureAppointments: any[],
  setUI: (value: any) => any,
  putBranchServicesUpdate: (organisationId: string, branchId: string, servicesList: any[], snackbarText: string) => Action,
};

const mapStateToProps = state => ({
  userData: state.branchServices.user.data,
  servicesSummary: state.branchServices.servicesSummary.data,
  isLoading: state.branchServices.servicesUpdate.isLoading,
  isOverlayRemoveServiceVisible: state.branchServices.ui.isOverlayRemoveServiceVisible,
  serviceFutureAppointments: state.branchServices.servicesUpdate.serviceFutureAppointments,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUI: (value: any) => dispatch(setUI(value)),
  putBranchServicesUpdate: (organisationId: string, branchId: string, servicesList: any[], snackbarText: string) => dispatch(putBranchServicesUpdate(organisationId, branchId, servicesList, snackbarText)),
});

class OverlayRemoveService extends Component<Props> {
  removeService = () => {
    const { putBranchServicesUpdate, organisationId, branchId, servicesSummary, userData } = this.props;
    const servicesList = servicesSummary.filter(service => service.id !== userData.serviceId)
    putBranchServicesUpdate(organisationId, branchId, servicesList, locale.Snackbar.branchRemoveService(userData.serviceName));
  };

  getFutureAppointmentsCount = () => {
    const { serviceFutureAppointments } = this.props;
    const { listOfAppointments } = serviceFutureAppointments;

    let allAppointments = [];
    listOfAppointments.forEach(({date, appointments}) => {
      allAppointments = allAppointments.concat(appointments);
    });

    const listOfAppointmentsLen = allAppointments.length;
    const cancelledAppointmentsList = allAppointments.filter(appointment => appointment.status === locale.Appointment.status.cancelled.status);
    const futureAppointmentsLength = `${listOfAppointmentsLen - cancelledAppointmentsList.length}`;

    return  { appointmentsCount: futureAppointmentsLength, isServiceHasNoAppointments: listOfAppointmentsLen === 0 || listOfAppointmentsLen === cancelledAppointmentsList.length };
  }

  renderDescription = () => {
    const { serviceFutureAppointments } = this.props;
    let isSetFutureAppointmentsLoading = !(serviceFutureAppointments && serviceFutureAppointments.serviceId);
    const isServiceHasNoAppointments = this.getFutureAppointmentsCount().isServiceHasNoAppointments;

    if (isSetFutureAppointmentsLoading) {
      return (
        <h4><Skeleton type="body" /></h4>
      );
    } else {
      if (isServiceHasNoAppointments) {
        return (
          <h4>{locale.BranchServicesRedesign.overlay.removeService.descriptionWithoutAppointments}</h4>
        );
        } else {
          return (
            <h4>{locale.BranchServicesRedesign.overlay.removeService.descriptionWithAppointments(this.getFutureAppointmentsCount().appointmentsCount)}</h4>
          );
        }
    }
  }
      
  renderContent = () => {
    const { isLoading, userData, serviceFutureAppointments, setUI } = this.props;
    let isSetFutureAppointmentsLoading = !(serviceFutureAppointments && serviceFutureAppointments.serviceId);
    const isServiceHasNoAppointments = this.getFutureAppointmentsCount().isServiceHasNoAppointments;
    
    return(
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{locale.BranchServicesRedesign.overlay.removeService.header(userData.serviceName)}</h1>
        </div>
        <div className="overlay-confirmation-content">
          {this.renderDescription()}
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="goBack"
            defaultMessage={locale.BranchServicesRedesign.button.goBack}
            onClick={() => { setUI({ isOverlayRemoveServiceVisible: false }); }}
            isLoading={isLoading}
          />
          {isServiceHasNoAppointments &&
          <Button
            buttonType="destructive"
            messageKey="removeService"
            defaultMessage={locale.BranchServicesRedesign.button.removeService}
            onClick={() => { this.removeService(); }}
            isLoading={isSetFutureAppointmentsLoading || isLoading}
          /> }
        </div>
      </Fragment>
    );
  }

  render() {
    const { isOverlayRemoveServiceVisible } = this.props;

    if (isOverlayRemoveServiceVisible) {
      return (
        <Overlay background="dark" type="confirmation" isOpen={true} showCloseButton={false} doClose={() => { }}>
          {this.renderContent()}
        </Overlay>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayRemoveService);
