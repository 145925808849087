import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Task,
  TaskHeader,
  TaskContent,
  TaskProgressBar,
  TaskFooter,
  TaskInfo,
} from "./task";
import * as ProgressBarConstants from "constants/ProgressBarConstants";
import * as TaskManagerConstants from "constants/TaskManagerConstants";
import {
  getTaskDetail,
  setTaskManagerOverlayIsVisible,
  setTaskManagerOverlayType,
  dismissTask,
} from "actions/taskManager";
import type { Organisation } from "types/organisation";
import locale from "service/locale";

type Props = {
  tasksList: any,
  isSuperAdmin: boolean,
  organisationList: Organisation[],
  selectedOrgId: string,
  setOverlayData: () => any,
  setTaskManagerIsVisible: () => Action,
  getTaskDetail: (taskId: any) => any,
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => any,
  setTaskManagerOverlayType: (type: string) => any,
  dismissTask: (taskId: any) => any,
};

const mapStateToProps = (state) => ({
  tasksList: state.taskManager.tasksList.data,
  organisationList: state.organisations.organisationsListAsync,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTaskDetail: (taskId: any) => dispatch(getTaskDetail(taskId)),
  setTaskManagerOverlayIsVisible: (isVisible: boolean) =>
    dispatch(setTaskManagerOverlayIsVisible(isVisible)),
  setTaskManagerOverlayType: (type: string) =>
    dispatch(setTaskManagerOverlayType(type)),
  dismissTask: (taskId: any) => dispatch(dismissTask(taskId)),
});

class Tasks extends Component<Props> {
  calculateProgress = (task) => {
    var percentageCompleted = 0;
    var status = ProgressBarConstants.STATUS.QUEUED;
    if (task.runtimeStatus !== "Pending") {
      percentageCompleted =
        task.runtimeStatus === "Failed"
          ? 100
          : parseInt(
              ((task.customStatus.Succeed + task.customStatus.Failed) /
                task.customStatus.Total) *
                100
            );
      if (
        percentageCompleted === 100 &&
        task.runtimeStatus !== "Failed" &&
        task.runtimeStatus !== "Terminated"
      ) {
        if (task.customStatus.Succeed === task.customStatus.Total) {
          status = ProgressBarConstants.STATUS.COMPLETED;
        } else if (task.customStatus.Failed === task.customStatus.Total) {
          status = ProgressBarConstants.STATUS.FAILED;
        } else if (
          task.customStatus.Succeed < task.customStatus.Total &&
          task.customStatus.Succeed !== 0
        ) {
          status = ProgressBarConstants.STATUS.PARTIALLY_FAILED;
        }
      } else if (
        task.runtimeStatus === "Terminated"
      ) {
        status = ProgressBarConstants.STATUS.FAILED;
      } else if (
        task.runtimeStatus === "Failed"
      ) {
        if (task.customStatus && task.customStatus.Succeed > 0) {
          percentageCompleted = parseInt(
            ((task.customStatus.Succeed + task.customStatus.Failed) /
              task.customStatus.Total) *
              100
          );
          if (task.customStatus.Succeed === task.customStatus.Total) {
            status = ProgressBarConstants.STATUS.COMPLETED;
          } else {
            status = ProgressBarConstants.STATUS.PARTIALLY_FAILED;
          }
        } else {
          status = ProgressBarConstants.STATUS.FAILED;
        }
      } else {
        if (task.customStatus.Queued === task.customStatus.Total) {
          status = ProgressBarConstants.STATUS.QUEUED;
        } else if (
          task.customStatus.Queued !== 0 &&
          task.customStatus.Queued < task.customStatus.Total
        ) {
          status = ProgressBarConstants.STATUS.IN_PROGRESS;
        }
      }
    }

    var progress = { percentageCompleted, status };
    return progress;
  };

  renderProgress = (task) => {
    var progress = this.calculateProgress(task);
    var branchesProcessed =
      task.runtimeStatus !== "Pending" && task.customStatus
        ? progress.status === ProgressBarConstants.STATUS.IN_PROGRESS ||
          task.runtimeStatus === "Terminated"
          ? `${task.customStatus.Succeed + task.customStatus.Failed}/${
              task.customStatus.Total
            }`
          : `${task.customStatus.Succeed}/${task.customStatus.Total}`
        : "0";
    const progressText =
      progress.percentageCompleted === 100 &&
      progress.status === ProgressBarConstants.STATUS.FAILED
        ? locale.TaskManager.progressBarLabel.error
        : task.runtimeStatus === "Terminated"
        ? locale.TaskManager.progressBarLabel.branchesProcessed(
            `Cancelled, ${branchesProcessed}`
          )
        : progress.status === ProgressBarConstants.STATUS.PARTIALLY_FAILED
        ? locale.TaskManager.progressBarLabel.branchesUpdatedSuccesfully(
            branchesProcessed
          )
        : locale.TaskManager.progressBarLabel.branchesProcessed(
            branchesProcessed
          );
    return (
      <TaskProgressBar
        status={progress.status}
        percentageCompleted={progress.percentageCompleted}
        label={progressText}
      />
    );
  };

  cancelRunningTask = (task) => {
    const {
      setOverlayData,
      setTaskManagerOverlayIsVisible,
      setTaskManagerOverlayType,
    } = this.props;
    setOverlayData(task);
    setTaskManagerOverlayType(
      TaskManagerConstants.OVERLAY_TYPE.CANCEL_CONFIRMATION
    );
    setTaskManagerOverlayIsVisible(true);
  };

  dismissCompletedTask = (taskId) => {
    const { dismissTask } = this.props;
    dismissTask([taskId]);
  };

  setErrorPartialOverlay = (task) => {
    const {
      getTaskDetail,
      setOverlayData,
      setTaskManagerOverlayIsVisible,
      setTaskManagerOverlayType,
    } = this.props;
    getTaskDetail(task.input.TaskId);
    setOverlayData(task.input.TaskId);
    setTaskManagerOverlayType(
      task.runtimeStatus === "Terminated"
        ? TaskManagerConstants.OVERLAY_TYPE.CANCEL
        : TaskManagerConstants.OVERLAY_TYPE.ERROR_PARTIAL
    );
    setTaskManagerOverlayIsVisible(true);
  };

  setErrorOverlay = (task) => {
    const {
      setOverlayData,
      setTaskManagerOverlayIsVisible,
      setTaskManagerOverlayType,
    } = this.props;
    setOverlayData(task);
    setTaskManagerOverlayType(TaskManagerConstants.OVERLAY_TYPE.ERROR);
    setTaskManagerOverlayIsVisible(true);
  };

  renderTaskFooter = (task) => {
    var progress = this.calculateProgress(task);
    const isTaskCancelled = task.runtimeStatus === "Terminated" ? true : false;
    const isTaskError =
      progress.status === ProgressBarConstants.STATUS.FAILED ? true : false;
    const isTaskComplete =
      progress.percentageCompleted === 100 && !isTaskError ? true : false;
    const isTaskInProgress =
      progress.status === ProgressBarConstants.STATUS.IN_PROGRESS
        ? true
        : false;
    const isTaskInQueue =
      progress.status === ProgressBarConstants.STATUS.QUEUED ? true : false;
    const labelQueued = "Queued";

    if (isTaskInQueue) {
      return (
        <TaskFooter>
          <span>{labelQueued}</span>
        </TaskFooter>
      );
    } else if (isTaskInProgress) {
      return (
        <TaskFooter>
          <span />
        </TaskFooter>
      );
    } else if (isTaskComplete) {
      return (
        <TaskFooter>
          {progress.status === ProgressBarConstants.STATUS.COMPLETED ? (
            <span />
          ) : (
            <Link
              className="link-details"
              to="#details"
              onClick={(e) => {
                e.preventDefault();
                this.setErrorPartialOverlay(task);
              }}
            >
              {locale.TaskManager.button.viewDetails}
            </Link>
          )}
          <Link
            className="link-dismiss"
            to="#dismiss"
            onClick={(e) => {
              e.preventDefault();
              this.dismissCompletedTask(task.input.TaskId);
            }}
          >
            {locale.TaskManager.button.dismiss}
          </Link>
        </TaskFooter>
      );
    } else if (isTaskError) {
      return (
        <TaskFooter>
          <Link
            className="link-details"
            to="#details"
            onClick={(e) => {
              e.preventDefault();
              isTaskCancelled
                ? this.setErrorPartialOverlay(task)
                : this.setErrorOverlay(task);
            }}
          >
            {locale.TaskManager.button.viewDetails}
          </Link>
          <Link
            className="link-dismiss"
            to="#dismiss"
            onClick={(e) => {
              e.preventDefault();
              this.dismissCompletedTask(task.input.TaskId);
            }}
          >
            {locale.TaskManager.button.dismiss}
          </Link>
        </TaskFooter>
      );
    }
  };

  render() {
    const { tasksList, selectedOrgId, isSuperAdmin } = this.props;
    var tasks = tasksList;
    if (isSuperAdmin && selectedOrgId) {
      tasks = tasks.filter((t) => t.input.OrganisationId === selectedOrgId);
    }
    tasks = tasks.sort((a, b) => moment(b.createdTime) - moment(a.createdTime));
    var inprogressTasks = tasks.filter(
      (t) => t.runtimeStatus === "Pending" || t.runtimeStatus === "Running"
    );
    if (inprogressTasks.length > 0) {
      var index = 0;
      inprogressTasks.reverse();
      inprogressTasks.map(
        (task) => (
          tasks.splice(
            tasks.indexOf(
              tasks.find((t) => t.input.TaskId === task.input.TaskId)
            ),
            1
          ), // eslint-disable-line no-sequences
          tasks.splice(index, 0, task),
          ++index
        )
      );
    }

    const renderTaskInfo = (task) => {
      const createdTime = moment(task.createdTime)
        .local()
        .format("HH:mm DD/MM/YYYY");
      const taskInfoLabel = `Task started by ${task.input.UserName} at ${createdTime}`;
      return taskInfoLabel;
    };

    const renderOrganisationName = (orgId) => {
      const { isSuperAdmin, organisationList } = this.props;
      if (isSuperAdmin && organisationList && organisationList.length > 0) {
        if (orgId !== undefined && orgId !== null) {
          return (
            <h3>
              {organisationList.find((org) => org.id === orgId).companyName}
            </h3>
          );
        }
      }
      return null;
    };

    return (
      <Fragment>
        {tasks.map(
          (task) =>
            task &&
            task.input && (
              <Task>
                <TaskInfo label={renderTaskInfo(task)} />
                <TaskHeader>
                  <h2>{task.input.TaskName}</h2>
                  {renderOrganisationName(task.input.OrganisationId)}
                </TaskHeader>
                <TaskContent>{this.renderProgress(task)}</TaskContent>
                {this.renderTaskFooter(task)}
              </Task>
            )
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
