import React, { Component, Fragment } from 'react';

type Props = {
  outline: boolean,
};

class IconCheckboxIntermediate extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <Fragment>
        <path d="M18,0H6A6,6,0,0,0,0,6V18a6,6,0,0,0,6,6H18a6,6,0,0,0,6-6V6A6,6,0,0,0,18,0Zm5,18a5,5,0,0,1-5,5H6a5,5,0,0,1-5-5V6A5,5,0,0,1,6,1H18a5,5,0,0,1,5,5Z" />
        <path d="M18,11H6a1,1,0,0,0,0,2H18a1,1,0,0,0,0-2Z" />
      </Fragment>
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-checkbox-intermediate ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconCheckboxIntermediate;
