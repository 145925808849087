export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SEARCH_EVENTS = "SET_SEARCH_EVENTS";
export const SET_SEARCH_DETAILS = "SET_SEARCH_DETAILS";
export const CHANGE_SEARCH_DETAILS_PAYMENT_STATUS = "CHANGE_SEARCH_DETAILS_PAYMENT_STATUS";
export const CHANGE_SEARCH_DETAILS_STATUS = "CHANGE_SEARCH_DETAILS_STATUS";
export const CHANGE_SEARCH_CANCELLATION_REASON = "CHANGE_SEARCH_CANCELLATION_REASON";
export const SEARCH_RESULTS_PENDING = "SEARCH_RESULTS_PENDING";
export const SEARCH_RESULTS_ERROR = "SEARCH_RESULTS_ERROR";

export const MINIMUN_SEARCH_VALUE_LENGTH = 2;
