import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Overlay, Button, Form, FormItem, Checkbox } from '@patient-access/ui-kit';

import { setTimeOffDataUpdate, setTimeOffOverlayVisibility, putAvailabilityRules } from 'actions/availabilityRules';

import locale from 'service/locale';

type Props = {
  data: any,
  organisationId: string,
  setTimeOffDataUpdate: (data: any) => Action,
  setTimeOffOverlayVisibility: (isOverlayVisible: boolean) => Action,
  putAvailabilityRules: (organisationId: string) => Action,
  handleChangeMode: () => any,
  isOverlayVisible: boolean,
  isPutLoading: boolean,
};

const mapStateToProps = state => ({
  isOverlayVisible: state.availabilityRules.isTimeOffOverlayVisible,
  isPutLoading: state.availabilityRules.isPutLoading,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  setTimeOffDataUpdate: (data) => dispatch(setTimeOffDataUpdate(data)),
  setTimeOffOverlayVisibility: (isOverlayVisible) => dispatch(setTimeOffOverlayVisibility(isOverlayVisible)),
  putAvailabilityRules: (organisationId: string) => dispatch(putAvailabilityRules(organisationId)),
});

class TimeOffOverlay extends Component<Props> {
  render() {
    const { data, organisationId, setTimeOffDataUpdate, setTimeOffOverlayVisibility, putAvailabilityRules, handleChangeMode, isOverlayVisible, isPutLoading } = this.props;

    const renderCheckbox = () => {
      if (data.countBranchUsingOwnTimeOff > 0) {
        return (
          <div className="overlay-confirmation-content">
            <Form>
              <FormItem type="item">
                <Checkbox
                  id="timeOffCheckboxUpdateAll"
                  name="timeOffCheckboxUpdateAll"
                  value="timeOffCheckboxUpdateAll"
                  label={`${locale.AvailabilityRules.timeOffOverlay.ckeckboxLabelBeforeValue} ${data.countBranchUsingOwnTimeOff} ${locale.AvailabilityRules.timeOffOverlay.ckeckboxLabelAfterValue}`}
                  onChange={(e) => { setTimeOffDataUpdate({ overrideTimeOffToAllBranches: e.target.checked }); }}
                  checked={data.overrideTimeOffToAllBranches}
                />
              </FormItem>
            </Form>
          </div>
        );
      }
      return null;
    }

    return (
      <Overlay
        background="dark"
        type="confirmation"
        isOpen={isOverlayVisible}
        showCloseButton={false}
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.AvailabilityRules.timeOffOverlay.headingBeforeValue} {data.countBranchUsingOrganisationTimeOff} {locale.AvailabilityRules.timeOffOverlay.headingAfterValue}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.AvailabilityRules.timeOffOverlay.description}</h4>
        </div>
        {renderCheckbox()}
        <div className="overlay-confirmation-buttons">
          <Button
            to="#back"
            buttonType="blueline"
            messageKey="buttonBack"
            defaultMessage={locale.AvailabilityRules.timeOffOverlay.buttonBack}
            onClick={(e) => { e.preventDefault(); setTimeOffOverlayVisibility(false); }}
          />
          <Button
            to="#save"
            buttonType="secondary"
            messageKey="buttonSave"
            defaultMessage={locale.AvailabilityRules.timeOffOverlay.buttonSave}
            onClick={(e) => { e.preventDefault(); putAvailabilityRules(organisationId); setTimeOffOverlayVisibility(false); handleChangeMode(); }}
            isLoading={isPutLoading}
          />
        </div>
      </Overlay>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeOffOverlay);
