// ALL ROUTES SHOULD USE THESE CONSTANTS - NO HARD-CODED PATHS
// DO NOT USE THIS IN AGENT CALLS

// MAIN_ROUTES
export const ADMIN = "/admin";
export const CALENDAR = "/calendar";
export const COMPLETE_REGISTRATION = "/complete-registration";
export const HOME = "/";
export const LOGIN = "/login";
export const LOGINCAllBACK = "/logincallback";
export const FORGOTPASSWORDCAllBACK = "/forgotpasswordcallback";
export const CHANGEPASSWORDCAllBACK = "/changepasswordcallback";
export const REGISTER = "/register";
export const REPORTS = "/reports";
export const RESET_PASSWORD = "/reset-password";
export const LOGOUT = "/logout";

// ADMIN_ROUTES
export const ADMIN_BRANCHES = "/admin/branches";
export const ADMIN_ORGANISATIONS = "/admin/organisations";
export const ADMIN_DEFAULT_SERVICES = "/admin/defaultservices";
export const ADMIN_USERS = "/admin/users";

// EXTRA_PARAMETERS
export const AVAILABILITY = "availability";
export const AVAILABILITY_RULES = "availability-rules";
export const BRANCHES = "branches";
export const BASIC = "basic";
export const CALENDARS = "calendars";
export const DETAILS = "details";
export const PAYOUT = "payout";
export const ROOMS = "rooms";
export const SETTINGS = "settings";
export const SERVICES = "services";
export const SERVICE = "service";
export const USERS = "users";
export const SMART_PHARMACY = "smart-pharmacy";
export const SMART_PHARMACY_REGISTER = "smart-pharmacy-landing";
export const ENABLED_SERVICES = "enabled-services";
export const CUSTOM = "custom";
