import React, { Component } from 'react';
import { IconPerson, IconPhone, IconVideo, IconHome } from '@patient-access/ui-kit';
import { formatNumber, formatAsCurrency } from 'helpers/formatData';
import locale from 'service/locale';

type Props = {
  column: string,
  data: any,
};

class TableHeaderCellSummary extends Component<Props> {
  render() {
    const { column, data } = this.props;

    const renderSummary = (column, data) => {
      const noBranches          = data.length;
      const noShowPrice         = data.filter(service => service.isShowPrice).length;
      const noActive            = data.filter(service => !service.paused).length;
      const minPrice            = Math.min.apply(Math, data.map(service => service.servicePrice));
      const maxPrice            = Math.max.apply(Math, data.map(service => service.servicePrice));
      const minDuration         = Math.min.apply(Math, data.map(service => service.duration));
      const maxDuration         = Math.max.apply(Math, data.map(service => service.duration));
      const minCutOff           = Math.min.apply(Math, data.map(service => service.cutOff));
      const maxCutOff           = Math.max.apply(Math, data.map(service => service.cutOff));
      const hasModeF2F          = data.filter(service => service.modes.includes(0)).length > 0;
      const hasModeVideo        = data.filter(service => service.modes.includes(1)).length > 0;
      const hasModePhone        = data.filter(service => service.modes.includes(2)).length > 0;
      const hasModeHomeDelivery = data.filter(service => service.modes.includes(5)).length > 0;
      const noURL               = [...new Set(data.filter(service => service.bookingUrl !== null).map(service => service.bookingUrl))].length;
      const noVatIncluded       = data.filter(service => service.vatIncluded).length;
      const noAlerts            = data.filter(service => !service.isServiceAdded).length;

      switch (column.id) {
        case 'selection':
          return <span>&nbsp;</span>;
        case 'branchName':
          return <span>{formatNumber(noBranches)} {locale.OrganisationServices.table.branchesSummary.labels.branchSummary}</span>;
        case 'isShowPrice':
          return <span>{noShowPrice} {locale.OrganisationServices.table.branchesSummary.labels.showPriceSummary}</span>;
        case 'servicePrice':
          if (minPrice === maxPrice) {
            return <span>{locale.OrganisationServices.table.branchesSummary.labels.servicePrice}{formatAsCurrency(minPrice)}</span>;
          }
          return <span>{locale.OrganisationServices.table.branchesSummary.labels.servicePrice}{formatAsCurrency(minPrice)} - {formatAsCurrency(maxPrice)}</span>;
        case 'paused':
          return <span>{formatNumber(noActive)} {locale.OrganisationServices.table.branchesSummary.labels.statusSummary}</span>;
        case 'duration':
          if (minDuration === maxDuration) {
            return <span>{minDuration} {locale.OrganisationServices.table.branchesSummary.labels.duration}</span>;
          }
          return <span>{minDuration} - {maxDuration} {locale.OrganisationServices.table.branchesSummary.labels.duration}</span>;
        case 'cutOff':
          if (minCutOff === maxCutOff) {
            if (minCutOff === 0) {
              return <span>{locale.OrganisationServices.table.branchesSummary.labels.cutOffZero}</span>
            }
            return <span>{minCutOff} {locale.OrganisationServices.table.branchesSummary.labels.cutOff}</span>;
          }
          return <span>{minCutOff} - {maxCutOff} {locale.OrganisationServices.table.branchesSummary.labels.cutOff}</span>;
        case 'modes':
          return (
            <i>
              {hasModeF2F ? <IconPerson outline={false} /> : null}
              {hasModeVideo ? <IconVideo outline={false} /> : null}
              {hasModePhone ? <IconPhone outline={false} /> : null}
              {hasModeHomeDelivery ? <IconHome outline={false} /> : null}
            </i>
          );
        case 'vatIncluded':
          return <span>{noVatIncluded} {locale.OrganisationServices.table.branchesSummary.labels.vatIncluded}</span>
        case 'bookingUrl':
          return <span>{noURL} {locale.OrganisationServices.table.branchesSummary.labels.bookingUrl}</span>
        case 'isServiceAdded':
          return <span>{noAlerts} {locale.OrganisationServices.table.branchesSummary.labels.alert}</span>

        default:
          return null;
      }
    }

    return (
      <div className="summary">
        {renderSummary(column, data)}
      </div>
    );
  }
}

export default TableHeaderCellSummary;
