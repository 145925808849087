import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FeatureSidebar } from 'components/Share/feature';
import { setUI, resetUserData, setUserStep } from 'actions/organisationServices';
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThree from './StepThree/StepThree';

type Props = {
  organisationId: string,
  isAffiliate: boolean,
  isSidebarAddVisible: boolean,
  setUI: (value: any) => any,
  step: any,
  resetUserData: () => any,
  setUserStep: (value: any) => any,
  isDiscardModalOpen: boolean;
};

const mapStateToProps = (state) => ({
  isSidebarAddVisible: state.organisationServices.ui.isSidebarAddVisible,
  step: state.organisationServices.user.step,
  userData: state.organisationServices.user.data,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserData: () => dispatch(resetUserData()),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
});

class SidebarAddService extends Component<Props> {
  state = {
    isDiscardModalOpen: false,
  };

  componentWillUnmount = () => {
    const { resetUserData, setUserStep } = this.props;
    resetUserData();
    setUserStep(1);
  }

  handleOpenDiscardDialog = () => {
    const { setUserStep, setUI, userData } = this.props;
    let dataKeys = Object.keys(userData);
    const defaultCheckedDataKeys = ['serviceIsVatIncluded'];
    const ignoredKeys = ['serviceAvailableModes','serviceBranches', 'serviceIsNhsCovered'];
    const arrItems = ['serviceModes'];

    const filteruserData = (d) => {
      if ((!ignoredKeys.includes(d)) &&
        (!(defaultCheckedDataKeys.includes(d) && userData[d] === false) &&
          (!arrItems.includes(d)|| (userData[d] && userData[d].length > 0)) &&
          userData[d] !== null && userData[d] !== "")) {
        return true;
      }
      return false;
    };

    const userDataKeys = dataKeys.filter((d) => filteruserData(d));

    if (!userDataKeys || userDataKeys.length > 0) {
      this.setState({ isDiscardModalOpen: true });
    }
    else {
      this.setState(
        {
          isDiscardModalOpen: false
        },
        () => {
          setUserStep(1);
          setUI({ isSidebarAddVisible: false });
        }
      );
    }
  };

  handleDiscardChanges = () => {
    const { setUI, resetUserData, setUserStep } = this.props;
    this.setState(
      {
        isDiscardModalOpen: false
      },
      () => {
        setUserStep(1);
        setUI({ isSidebarAddVisible: false });
        resetUserData();
      }
    );
  };
  
  handleStay = () => {
    this.setState({
      isDiscardModalOpen: false
    });
  };

  render() {
    const { organisationId, isSidebarAddVisible, isAffiliate, step } = this.props;
    const { isDiscardModalOpen } = this.state;
    const renderContent = () => {
      switch (step) {
        case 1:
          return <StepOne organisationId={organisationId} />;
        case 2:
          return <StepTwo isAffiliate={isAffiliate} />;
        case 3:
          return <StepThree organisationId={organisationId} />;

        default:
          return null;
      }
    }

    if (isDiscardModalOpen) {
      return (
        <DiscardChangesOverlay
        handleDiscardChanges={this.handleDiscardChanges}
        handleStay={this.handleStay}
      />
      );
    }

    if (isSidebarAddVisible) {
      return (
        <FeatureSidebar isOpen={isSidebarAddVisible} doClose={() => { this.handleOpenDiscardDialog(); }}>
          {renderContent()}
        </FeatureSidebar>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAddService);
