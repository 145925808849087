import React, { Component } from 'react';
import './styles/task-manager.scss';

type Props = {
  children: any,
};

class TaskManager extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <aside className="task-manager">
        {children}
      </aside>
    );
  }
};

export default TaskManager;
