import { connect } from "react-redux";

import { loadUser } from "actions/profile";
import AADForgotPasswordCallback from "./AADForgotPasswordCallback";

const mapStateToProps = (state) => ({
  login: state.login,
  isAuth: state.login.isAuth,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (access_token) => dispatch(loadUser(access_token)),
});

const AADForgotPasswrodCallbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AADForgotPasswordCallback);

export default AADForgotPasswrodCallbackContainer;