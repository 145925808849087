import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Overlay, Button } from '@patient-access/ui-kit';
import { setReportsDownloadOverlay } from 'actions/reports';
import locale from 'service/locale';
import './styles.scss';

import { ai } from "service/telemetry";

type Props = {
  data: any,
  selectedOrganisation: any,
  selectedBranch: any,
  selectedService: any,
  selectedStatus: any,
  selectedStartDate: any,
  selectedEndDate: any,
  isOverlayVisible: boolean,
  isLoading: boolean,
  isError: boolean,
  setReportsDownloadOverlay: (isOverlayVisible: boolean) => Action,

  // Telemetry
  aiUserId : string,
  aiSessionId: string,
  aiRoleName: string,
};

const mapStateToProps = (state) => ({
  data: state.reportsBasic.download.data,
  selectedOrganisation: state.reportsBasic.selectedOrganisation,
  selectedBranch: state.reportsBasic.selectedBranch,
  selectedService: state.reportsBasic.selectedService,
  selectedStatus: state.reportsBasic.selectedStatus,
  selectedStartDate: state.reportsBasic.filters.startDate,
  selectedEndDate: state.reportsBasic.filters.endDate,
  isOverlayVisible: state.reportsBasic.download.isOverlayVisible,
  isLoading: state.reportsBasic.download.isLoading,
  isError: state.reportsBasic.download.isError,
  lastRunDate: state.reportsBasic.lastRunDate,

  // Telemetry
  aiUserId: state.profile.id,
  aiSessionId: state.profile.sessionId,
  aiRoleName: state.roles.profileCurrentRole.role,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setReportsDownloadOverlay: (isOverlayVisible) => dispatch(setReportsDownloadOverlay(isOverlayVisible)),
});

class DownloadOverlay extends Component<Props> {
  handlerTelemetry = () => {
    const { aiUserId, aiRoleName, aiSessionId, selectedOrganisation, selectedBranch } = this.props;

    // Telemetry
    ai.appInsights.trackEvent({
      name: 'PAProDownloadReport',
    }, {
      UserId: aiUserId,
      SessionId: aiSessionId,
      RoleName: aiRoleName,
      OrganizationId: selectedOrganisation.id || locale.Telemetry.naOrganisationId,
      BranchId: selectedBranch.id || locale.Telemetry.naBranchId,
    });
  }

  render() {
    const { data, selectedBranch, selectedService, selectedStatus, selectedStartDate, selectedEndDate, isOverlayVisible, isLoading, isError, setReportsDownloadOverlay } = this.props;
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const isWin = navigator.platform.toUpperCase().indexOf('WIN') >= 0;
    const isFirefox = navigator.userAgent.toUpperCase().indexOf('FIREFOX') >= 0;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const scvFilename = () => {
      var fileName = `Services_Reports_${moment(this.props.lastRunDate).format('DD/MM/YY hh:mm')}`;
      if (isMac) {
        if (isFirefox || isSafari) return `${fileName}.csv`;
      }
      if (isWin) return `${fileName}.csv`;
      return `${fileName}`;
    }

    return (
      <Overlay
        background="dark"
        type="confirmation"
        isOpen={isOverlayVisible}
        showCloseButton
        doClose={() => { setReportsDownloadOverlay(false); }}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.Reports.downloadOverlay.heading}</h1>
        </div>
        <div className="overlay-confirmation-content reports-basic-download">
          <h4>{locale.Reports.downloadOverlay.body}</h4>
          <p><strong>{locale.Reports.downloadOverlay.branchLabel}</strong> {selectedBranch.name ? selectedBranch.name : locale.Reports.downloadOverlay.branchAll}</p>
          <p><strong>{locale.Reports.downloadOverlay.serviceLabel}</strong> {selectedService.name ? selectedService.name : locale.Reports.downloadOverlay.serviceAll}</p>
          <p><strong>{locale.Reports.downloadOverlay.statusLabel}</strong> {selectedStatus.label ? selectedStatus.label : locale.Reports.downloadOverlay.statusAll}</p>
          <p><strong>{locale.Reports.downloadOverlay.dateLabel}</strong> {moment(selectedStartDate).format('DD/MM/YYYY')} - {moment(selectedEndDate).format('DD/MM/YYYY')}</p>
        </div>
        <div className="overlay-confirmation-buttons reports-basic-download">
          <Button
            to="#back"
            buttonType="blueline"
            messageKey="some-key"
            defaultMessage={locale.Reports.buttonBack}
            onClick={(e) => { e.preventDefault(); setReportsDownloadOverlay(false); }}
          />
          <CSVLink className={`ui-kit-button ui-kit-button-secondary ui-kit-button-medium ${(isLoading || isError) ? 'ui-kit-button-loading' : ''}`} data={data} filename={scvFilename()} onClick={this.handlerTelemetry}>
            {(isLoading || isError) ? <em><span className="ui-kit-accessibility-span">{locale.Reports.loading}</span></em> : null}
            <span>{locale.Reports.buttonDownload}</span>
          </CSVLink>
        </div>
      </Overlay>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadOverlay);
