import React, { Component } from "react";
import { connect } from "react-redux";
import IdleTimer from 'react-idle-timer';

import { ai } from "service/telemetry";
import { logoutUser } from 'actions/login';

type Props = {
  // Telemetry
  aiUserId : string,
  aiSessionId: string,
};

type State = {
  timeout: number,
  isTimedOut: boolean,
}

const mapStateToProps = (state) => ({
  // Telemetry
  aiUserId: state.profile.id,
  aiSessionId: state.profile.sessionId,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

class IdleTimerContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 1000 * 60 * 15, // milliSec * sec * min
      isTimedOut: false
    };
    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);
  }

  onAction(e) {
    this.setState({isTimedOut: false});
  }
 
  onActive(e) {
    this.setState({isTimedOut: false});
  }
 
  onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      // Telemetry
      ai.appInsights.trackEvent({
        name: 'PAProIdleTimeOff',
      }, {
        UserId: this.props.aiUserId,
        SessionId: this.props.aiSessionId,
      });

      this.props.logoutUser();
    } else {
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  render() {
    return (
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={this.state.timeout} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdleTimerContainer);
