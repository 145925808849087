import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import type { LegalEntity } from "types/organisation";
import * as OrganisationsConstants from "constants/OrganisationsConstants";
import * as UserConstants from "constants/RolesConstants";
import locale from "service/locale";

type Props = {
  legalEntity: LegalEntity,
  currentRole: any,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
});

class BankAccountVerificationStatus extends Component<Props> {
  render() {
    const { currentRole } = this.props;
    const isSuperAdmin = currentRole.role === UserConstants.ADMIN;
    const { legalEntity } = this.props;
    let status = locale.PayoutDetails.verificationStatusUnverified;
    let className = "error";
    let description1 = isSuperAdmin
      ? locale.PayoutDetails.paymentVerificationAdmin.paymentUnverified
      : locale.PayoutDetails.paymentVerificationOrganisationAdmin.paymentUnverified;

    switch (legalEntity && legalEntity.verification.status) {
      case OrganisationsConstants.BANK_ACCOUNT_VERIFIED:
        className = "success";
        status = locale.PayoutDetails.verificationStatusVerified;
        description1 = isSuperAdmin
          ? locale.PayoutDetails.paymentVerificationAdmin.paymentVerified
          : locale.PayoutDetails.paymentVerificationOrganisationAdmin.paymentVerified;
        break;
      case OrganisationsConstants.BANK_ACCOUNT_PENDING:
        className = "pending";
        status = locale.PayoutDetails.verificationStatusPending;
        description1 = isSuperAdmin
          ? locale.PayoutDetails.paymentVerificationAdmin.paymentPending
          : locale.PayoutDetails.paymentVerificationOrganisationAdmin.paymentPending;
        break;
      default:
        break;
    }

    return (
      <Fragment>
        <div className="patient-care-heading">
          <h4>
            {locale.PayoutDetails.payoutStep2}
          </h4>
        </div>
        <b className={`patient-care-status ${className}`}>{status}</b>
        <p>
          {description1}
        </p>
        { !isSuperAdmin && (
          <p>
            {locale.PayoutDetails.payoutStep2OrgAdmin}
            <b>{locale.PayoutDetails.verificationEmail}</b>
          </p>
        ) }
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(BankAccountVerificationStatus);
