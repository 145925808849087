import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Loader } from "@patient-access/ui-kit";

import * as RoutesConstants from 'constants/RoutesConstants';
import { isAuthorized } from "../aadAuthHelper";
import '../../styles.scss';

class AADLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    }
  }

  async componentDidMount() {
    try {
      if (isAuthorized()) {
        this.props.logoutUser();
      }
    }
    catch (e) {
      this.setState({ error: 'Please signin again' });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          {this.state.error}
        </div>
      );
    }
    
    if(!isAuthorized()) {
      return <Redirect to={RoutesConstants.LOGIN} />;
    }
    return (
      <div className="patient-care-aadlogin-loader">
        <Loader size="small" type="inline" />
      </div>
    );
  }
}

export default AADLogout;
