import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, SearchFilter, IconSearch } from "@patient-access/ui-kit";
import { getFilteredDefaultServices } from 'actions/defaultServices';
import locale from 'service/locale';

type State = {
  searchValue: string;
}

type Props = {
  getFilteredDefaultServices: (page: number, searchValue: string) => any,  
  isLowResolution: boolean
}

const mapStateToProps = (state: any) => ({
  isLowResolution: state.panel.isLowResolution,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getFilteredDefaultServices: (page: number, searchValue: string) => dispatch(getFilteredDefaultServices(page, searchValue)),
});

class DefaultServicesFilter extends Component<Props,State> {

  state = {
    searchValue: ""
  };

  handleChangeSearch = (e: Event) => {
    e && e.preventDefault();
    const { getFilteredDefaultServices } = this.props;
    const { value } = e && e.target;

    this.setState({ searchValue: value });
    getFilteredDefaultServices(1, value);
  };

  handleClearSearch = () => {
    const { getFilteredDefaultServices } = this.props;
    const pageNumber = 1;

    this.setState({ searchValue: "" });
    getFilteredDefaultServices(pageNumber, "");
  };

  render() {
    const { isLowResolution } = this.props;
    const { searchValue } = this.state;
    return (
      <Fragment>
        <Form
          noValidate
          className="patient-care-organisationsearch-form"
          onSubmit={(e) => { e.preventDefault(); }}
        >
          <FormItem type="item">
            <SearchFilter
                id="patient-care-organisationsearch-form"
                name="search-organisations"
                value={searchValue}
                placeholder={isLowResolution ? locale.DefaultServices.searchFilter.messageXS : locale.DefaultServices.searchFilter.message}
                autoComplete="off"
                clearHandler={this.handleClearSearch}
                onChange={this.handleChangeSearch}
                icon={<IconSearch outline />}
              />
          </FormItem>
        </Form>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultServicesFilter);