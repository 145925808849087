import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Form, FormItem, Input, InputError, Checkbox, Radio, Tooltip, LogoNHS } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarBodySection, SidebarBodyHeader, SidebarBodyContent, SidebarFooter } from 'components/Share/sidebar';
import { setUI, getServiceDescription, setUserData, setUserStep } from 'actions/organisationServices';
import locale from 'service/locale';
import { periodsList, lastMinuteOptions } from 'constants/BranchesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import {
  checkURL
} from "helpers/checkValues";

type Props = {
  userData: any,
  isAffiliate: boolean,
  isSuperAdmin: boolean,
  orgBookingDetails: any,
  dataBranchesList: any,
  getServiceDescription: (serviceId: string) => any,
  setUI: (value: any) => any,
  setUserData: (data: any) => any,
  setUserStep: (value: any) => any,
};

type State = {
  modesArray: any,
  cutOff: number,
  duration: number,
  isErrorPrice: boolean,
  isErrorDuration: boolean,
  isErrorCutOff: boolean,
  isErrorPaused: boolean,
  isErrorModes: boolean,
  isErrorNegativePrice: boolean,
  isErrorBookingUrl: boolean,
  isBookingUrlEdited: boolean
};

const mapStateToProps = (state) => ({
  userData: state.organisationServices.user.data,
  orgBookingDetails: state.organisationDetails.booking,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  dataBranchesList: state.organisationDetails.branches,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getServiceDescription: (serviceId: string) => dispatch(getServiceDescription(serviceId)),
  setUI: (value: any) => dispatch(setUI(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
});

class StepTwo extends Component<State, Props> {
  state = {
    modesArray: this.props.userData.serviceModes,
    cutOff: null,
    duration: null,
    isErrorPrice: false,
    isErrorDuration: false,
    isErrorCutOff: false,
    isErrorPaused: false,
    isErrorModes: false,
    isErrorAffiliatePriceOption: false,
    isErrorNegativePrice: false,
    isErrorBookingUrl: false,
    isBookingUrlEdited: this.props.userData.bookingUrl.isEdited ? true : false
  }

  componentDidMount = () => {
    const { userData, setUserData } = this.props;
    const isNhsService = userData.serviceIsNhsCovered;

    if (userData.serviceDuration) {
      this.setState({ duration: periodsList.find(option => option.value === userData.serviceDuration)});
    }
    if (userData.serviceCutOff !== null) {
      this.setState({ cutOff: lastMinuteOptions.find(option => option.value === userData.serviceCutOff)});
    }
    if (isNhsService) {
      setUserData({ servicePrice: 0, serviceIsVatIncluded: false });
    }
  }

  handleStatusChange = (value: any) => {
    const { setUserData } = this.props;
    setUserData({ serviceIsActive: value });
    this.setState({ isErrorPaused: false });
  };

  handlePrice = (e: Event) => {
    const { setUserData } = this.props;
    var t = e.target.value;
    e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    if (e.target.value !== '' && e.target.value >= 0) {
      setUserData({ servicePrice: Number(e.target.value) });
      this.setState({ isErrorPrice: false, isErrorNegativePrice: false });
    } else if (e.target.value !== '' && e.target.value < 0) {
      setUserData({ servicePrice: Number(e.target.value) });
      this.setState({ isErrorNegativePrice: true, isErrorPrice: false });
    } else {
      setUserData({ servicePrice: e.target.value });
      this.setState({ isErrorPrice: true, isErrorNegativePrice: false });
    }
  }

  handleDuration = (e: Event) => {
    const { setUserData } = this.props;
    if (e.value) {
      setUserData({ serviceDuration: Number(e.value) });
      this.setState({ isErrorDuration: false, duration: periodsList.find(option => option.value === e.value) });
    } else {
      setUserData({ serviceDuration: null });
      this.setState({ isErrorDuration: true, duration: null });
    }
  }

  handleVATChange = (e: Event) => {
    const { setUserData } = this.props;
    if (e.target.checked !== '') {
      setUserData({ serviceIsVatIncluded: e.target.checked });
    }
  };

  handleCutoff = (e: Event) => {
    const { setUserData } = this.props;
    if (e.value || e.value === 0) {
      setUserData({ serviceCutOff: Number(e.value) });
      this.setState({ isErrorCutOff: false, cutOff: lastMinuteOptions.find(option => option.value === e.value) });
    } else {
      setUserData({ serviceCutOff: null });
      this.setState({ isErrorCutOff: true, cutOff: null });
    }
  }

  handleModes = (e: any) => {
    const { setUserData } = this.props;
    const target = e.target;
    var value = parseInt(target.value);
    let { modesArray } = this.state;
    if (target.checked) {
      modesArray.push(value);
      this.setState({ modesArray });
    } else {
      var index = modesArray.indexOf(value)
      modesArray.splice(index, 1);
    }
    modesArray = [...new Set(modesArray)];
    setUserData({ serviceModes: modesArray });
    if (modesArray.length === 0) {
      this.setState({ isErrorModes: true });
    } else {
      this.setState({ isErrorModes: false });
    }
  }

  handleAffiliatesShowPriceChange = (value: any) => {
    const { setUserData, userData } = this.props;
    const isNhsService = userData.serviceIsNhsCovered;
    if (!value) {
      setUserData({ servicePrice: null });
    } else {
      if (isNhsService) {
        setUserData({ servicePrice: 0, serviceIsVatIncluded: false });
      }
    }
    setUserData({ serviceIsShowPrice: value });
    this.setState({ isErrorAffiliatePriceOption: false, isErrorPrice: false });
  };

  handleUrlChange = (e: any) => {
    const { setUserData } = this.props;
    
    if(e.target.value){
      setUserData({ bookingUrl: {isEdited:true, value: e.target.value }});
      if(checkURL(e.target.value))
      {
        this.setState({ isErrorBookingUrl: false, isBookingUrlEdited: true });
      }
      else{
        this.setState({ isErrorBookingUrl: true, isBookingUrlEdited: true });
      }
    }
    else{
      setUserData({ bookingUrl: {isEdited:true, value: null }});
      this.setState({ isErrorBookingUrl: false, isBookingUrlEdited: true });
    }
  }


  renderDuration = () => {
    const { isErrorDuration, duration } = this.state;
    return (
      <Select
        id="appointmentDuration"
        name="appointmentDuration"
        placeholder={locale.OrganisationServices.section.appointmentDuration.placeholder}
        options={periodsList}
        classNamePrefix="patient-care"
        onChange={this.handleDuration}
        value={duration}
        className={`patient-care-select select-number ${isErrorDuration ? 'patient-care-select-error' : ''}`}
      />
    );
  }

  renderBookingCutOff = () => {
    const { isErrorCutOff, cutOff } = this.state;
    return (
      <Select
        id="bookingCutOff"
        name="bookingCutOff"
        placeholder={locale.OrganisationServices.section.bookingCutOff.placeholder}
        options={lastMinuteOptions}
        classNamePrefix="patient-care"
        onChange={this.handleCutoff}
        value={cutOff}
        className={`patient-care-select select-number ${isErrorCutOff ? 'patient-care-select-error' : ''}`}
      />
    );
  }

  renderBookingUrl = () => {
    const { isErrorBookingUrl, isBookingUrlEdited } = this.state;
    const { userData, orgBookingDetails } = this.props;
    return (
        <FormItem
          type="item"
          error={isErrorBookingUrl ? "error" : null}>
          <Input
            type="text"
            id="url"
            name="url"
            data-id="url"
            placeholder={locale.OrganisationsSettings.bookingUrl.placeHolder1}
            onChange={this.handleUrlChange}
            value={isBookingUrlEdited ? userData.bookingUrl.value : (orgBookingDetails.point && orgBookingDetails.point.url)}
          />
          {isErrorBookingUrl && <InputError message={locale.OrganisationsSettings.bookingUrl.invalidError1} />}
        </FormItem>
    );
  }

  render() {
    const { isErrorModes, isErrorPrice, isErrorDuration, isErrorCutOff, isErrorPaused, isErrorAffiliatePriceOption, isErrorNegativePrice } = this.state;
    const { userData, isAffiliate, getServiceDescription, setUI, setUserStep, isSuperAdmin, dataBranchesList } = this.props;
    const isNhsService = userData.serviceIsNhsCovered;

    const isFormValid = () => {
      const { isAffiliate } = this.props;
      if (!isAffiliate) {
        return userData && userData.serviceIsActive !== null &&
          userData.serviceModes.length !== 0 &&
          userData.serviceCutOff !== null &&
          userData.serviceDuration &&
          userData.servicePrice !== null && userData.servicePrice !== '' && userData.servicePrice >= 0
      } else {
        if (userData.serviceIsShowPrice) {
          return userData && userData.serviceIsActive !== null &&
          userData.serviceModes.length !== 0 &&
          userData.serviceDuration !== null && userData.serviceDuration !== '' && userData.serviceDuration > 0 && Number.isInteger(Number(userData.serviceDuration)) && userData.serviceDuration % 5 === 0 &&
          userData.servicePrice !== null && userData.servicePrice !== '' && userData.servicePrice >= 0 &&
          (userData.bookingUrl.isEdited ? (userData.bookingUrl.value === null || checkURL(userData.bookingUrl.value)) : true)
        } else {
          return userData && userData.serviceIsActive !== null &&
          userData.serviceModes.length !== 0 &&
          userData.serviceDuration !== null && userData.serviceDuration !== '' && userData.serviceDuration > 0 && Number.isInteger(Number(userData.serviceDuration)) && userData.serviceDuration % 5 === 0 &&
          userData.serviceIsShowPrice !== null &&
          (userData.bookingUrl.isEdited ? (userData.bookingUrl.value === null || checkURL(userData.bookingUrl.value)) : true)
        }
        
      }
      
    }

    const handleContinueStep = () => {
      const { setUserData, isAffiliate } = this.props;
      userData.serviceIsVatIncluded === null && setUserData({ serviceIsVatIncluded: false });
      if (!isAffiliate) {
        setUserData({ serviceIsShowPrice: true });
      } else {
        setUserData({ serviceCutOff: 0 });
      }
      setUserStep(3);
    }

    const validateForm = () => {
      const { userData, isAffiliate } = this.props;
      if (!isAffiliate && (userData.servicePrice === null || userData.servicePrice === '')) {
        this.setState({ isErrorPrice: true });
      }
      if (!isAffiliate && (userData.servicePrice !== null || userData.servicePrice !== '') && userData.servicePrice < 0) {
        this.setState({ isErrorNegativePrice: true });
      }
      if (!userData.serviceDuration) {
        this.setState({ isErrorDuration: true });
      }
      if (!isAffiliate && userData.serviceCutOff === null ) {
        this.setState({ isErrorCutOff: true });
      }
      if (userData.serviceModes.length === 0) {
        this.setState({ isErrorModes: true });
      }
      if (userData.serviceIsActive === null) {
        this.setState({ isErrorPaused: true });
      }
      if (isAffiliate) {
        if (userData.serviceIsShowPrice === null) {
          this.setState({ isErrorAffiliatePriceOption: true });
        } else if(userData.serviceIsShowPrice && (userData.servicePrice === null || userData.servicePrice === '')) {
          this.setState({ isErrorPrice: true, isErrorNegativePrice: false });
        } else if (userData.serviceIsShowPrice && (userData.servicePrice !== null || userData.servicePrice !== '') && userData.servicePrice < 0) {
          this.setState({ isErrorNegativePrice: true, isErrorPrice: false });
        }
        if (userData.bookingUrl.isEdited && (userData.bookingUrl.value !== null && !checkURL(userData.bookingUrl.value))) {
          this.setState({ isErrorBookingUrl: true });
        }
      }
    }
  
    const renderF2FOption = () => {
      const isModeF2F = userData.serviceAvailableModes.includes(0);
      const onlineBranchesLength = dataBranchesList.filter(d => d.isVirtual === true).length;
      const isAllOnlineBranches = onlineBranchesLength === dataBranchesList.length;
      if (!isAllOnlineBranches && isModeF2F) {
        if (onlineBranchesLength > 0) {
          return (
            <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipOnlineF2F}>
              <Checkbox id="appointmentType_F2F_active" name="appointmentType_F2F_active" value={0} label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={this.handleModes} defaultChecked={userData.serviceModes.includes(0)}/>
            </Tooltip>
          );
        }
        return (
          <Checkbox id="appointmentType_F2F_active" name="appointmentType_F2F_active" value={0} label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={this.handleModes} defaultChecked={userData.serviceModes.includes(0)}/>
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipF2F}>
            <Checkbox id="appointmentType_F2F_inactive" name="appointmentType_F2F_inactive" value="appointmentType_F2F_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderVideoOption = () => {
      const isModeVideo = userData.serviceAvailableModes.includes(1);
      if (isModeVideo) {
        return (
          <Checkbox id="appointmentType_Video_active" name="appointmentType_Video_active" value={1} label={locale.OrganisationServices.section.appointmentTypes.labelVideo} onChange={this.handleModes} defaultChecked={userData.serviceModes.includes(1)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipVideo}>
            <Checkbox id="appointmentType_Video_inactive" name="appointmentType_Video_inactive" value="appointmentType_Video_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelVideo} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderPhoneOption = () => {
      const isModePhone = userData.serviceAvailableModes.includes(2);
      if (isModePhone) {
        return (
          <Checkbox id="appointmentType_Phone_active" name="appointmentType_Phone_active" value={2} label={locale.OrganisationServices.section.appointmentTypes.labelPhone} onChange={this.handleModes} defaultChecked={userData.serviceModes.includes(2)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipPhone}>
            <Checkbox id="appointmentType_Phone_inactive" name="appointmentType_Phone_inactive" value="appointmentType_Phone_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelPhone} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderHomeDeliveryOption = () => {
      const isModeHomeDelivery = userData.serviceAvailableModes.includes(5);
      if (isModeHomeDelivery) {
        return (
          <Checkbox id="appointmentType_HomeDelivery_active" name="appointmentType_HomeDelivery_active" value={5} label={locale.OrganisationServices.section.appointmentTypes.labelHomeDelivery} onChange={this.handleModes} defaultChecked={userData.serviceModes.includes(5)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipHomeDelivery}>
            <Checkbox id="appointmentType_HomeDelivery_inactive" name="appointmentType_HomeDelivery_inactive" value="appointmentType_HomeDelivery_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelHomeDelivery} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderShowPriceOption = () => {
      const { isAffiliate } = this.props;
      if (isAffiliate) {
        return (
          <Fragment>
            <FormItem type="item" error={isErrorAffiliatePriceOption ? 'error' : null}>
              <div className="row row-list">
                <Radio id="showServicePrice_inactive" name="servicePriceInActive" value="showServicePrice_inactive" label={locale.OrganisationServices.section.servicePrice.labelDontShowPrice} onChange={() => { this.handleAffiliatesShowPriceChange(false); }} checked={userData.serviceIsShowPrice === false} />
                <Radio id="showServicePrice_active" name="servicePriceActive" value="showServicePrice_active" label={locale.OrganisationServices.section.servicePrice.labelShowPrice} onChange={() => { this.handleAffiliatesShowPriceChange(true); }} checked={userData.serviceIsShowPrice} />
              </div>
            </FormItem>
            {renderServicePrice(userData.serviceIsShowPrice)}
          </Fragment>
        );
      } else {
        return (
          renderServicePrice(true)
        );
      }
    }

    const renderServicePrice = (isVisible) => {
      if (isVisible){
        return (
          <FormItem type="item" error={isErrorPrice || isErrorNegativePrice ? 'error' : null}>
            <div className="row row-inline">
              <div className="input-before"><p><strong>{locale.OrganisationServices.section.servicePrice.currency}</strong></p></div>
              <Input type="number" id="servicePrice" name="servicePrice" placeholder={locale.OrganisationServices.section.servicePrice.placeholder} value={userData.servicePrice} onChange={this.handlePrice} disabled={isNhsService} />
              {!isAffiliate ? <Checkbox className="no-validate" id="serviceIsVatIncluded" name="serviceIsVatIncluded" value="VAT" label={locale.OrganisationServices.section.servicePrice.labelVAT} onChange={this.handleVATChange} defaultChecked={userData.serviceIsVatIncluded} disabled={isNhsService} /> : null}
            </div>
          </FormItem>
        );
      }
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.OrganisationServices.sidebar.addService.header}</h2>
          <p>
            <span>{userData.serviceName}</span>
            {userData.serviceIsNhsCovered ? <i><LogoNHS /></i> : null}
            <a
              href="#description"
              onClick={(e) => {
                e.preventDefault();
                setUI({ isOverlayDescriptionVisible: true });
                getServiceDescription(userData.serviceId);
              }}>
              {locale.OrganisationServices.sidebar.serviceDescription}
            </a>
          </p>
        </SidebarHeader>
        <SidebarBody>
          <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationServices.section.appointmentTypes.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorModes ? 'error' : null}>
                  <div className="row row-list">
                    {renderF2FOption()}
                    {renderVideoOption()}
                    {renderPhoneOption()}
                    {isSuperAdmin && renderHomeDeliveryOption()}
                  </div>
                  {isErrorModes ? <InputError message={locale.OrganisationServices.validationError.appointmentType} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationServices.section.serviceStatus.header}</h3>
                <p>{locale.OrganisationServices.section.serviceStatus.description}</p>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorPaused ? 'error' : null}>
                  <div className="row row-list">
                    <Radio id="serviceStatus_paused" name="serviceStatus" value="serviceStatus_paused" label={locale.OrganisationServices.section.serviceStatus.labelPaused} onChange={() => { this.handleStatusChange(false); }} defaultChecked={userData.serviceIsActive === false} />
                    <Radio id="serviceStatus_active" name="serviceStatus" value="serviceStatus_active" label={locale.OrganisationServices.section.serviceStatus.labelActive} onChange={() => { this.handleStatusChange(true); }} defaultChecked={userData.serviceIsActive === true} />
                  </div>
                  {isErrorPaused ? <InputError message={locale.OrganisationServices.validationError.paused} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationServices.section.servicePrice.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <Fragment>
                  {renderShowPriceOption()}
                  {isErrorAffiliatePriceOption ? <InputError message={locale.OrganisationServices.validationError.affiliatePriceOption} /> : null}
                  {isErrorPrice ? <InputError message={locale.OrganisationServices.validationError.servicePrice} /> : null}
                  {isErrorNegativePrice ? <InputError message={locale.OrganisationServices.validationError.negativePrice} /> : null}
                </Fragment>
              </SidebarBodyContent>
            </SidebarBodySection>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationServices.section.appointmentDuration.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorDuration ? 'error' : null}>
                  <div className="row row-inline">
                    {this.renderDuration()}
                  </div>
                  {isErrorDuration ? <InputError message={locale.OrganisationServices.validationError.appointmentDuration} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection>
            {!isAffiliate ?
              <SidebarBodySection>
                <SidebarBodyHeader>
                  <h3>{locale.OrganisationServices.section.bookingCutOff.header}</h3>
                  <p>{locale.OrganisationServices.section.bookingCutOff.description}</p>
                </SidebarBodyHeader>
                <SidebarBodyContent>
                  <FormItem type="item" error={isErrorCutOff ? 'error' : null}>
                    <div className="row row-inline">
                      {this.renderBookingCutOff()}
                    </div>
                    {isErrorCutOff ? <InputError message={locale.OrganisationServices.validationError.cutOff} /> : null}
                  </FormItem>
                </SidebarBodyContent>
              </SidebarBodySection> : null}
            {isAffiliate ? <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationsSettings.bookingUrl.title1}</h3>
                <p>{locale.OrganisationsSettings.bookingUrl.hint4}</p>
                <p>{locale.OrganisationsSettings.bookingUrl.hint3}</p>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                {this.renderBookingUrl()}
              </SidebarBodyContent>
            </SidebarBodySection> : null}
          </Form>
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column" />
            <div className="column">
              <Button
                buttonType="blueline"
                messageKey="some-key"
                defaultMessage={locale.OrganisationServices.button.goBack}
                onClick={() => { setUserStep(1); }}
              />
              <Button
                className={`patient-care-btn-in-group ${!isFormValid() && "pretend-disabled"}`}
                buttonType="secondary"
                messageKey="some-key"
                defaultMessage={locale.OrganisationServices.button.continue}
                onClick={isFormValid() ? handleContinueStep : validateForm}
              />
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
