import React, { Component } from 'react'
import {
  Overlay,
  Button,
} from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleLogoDiscardChanges: () => any;
  handleRemoveLogo: () => any;
  isLoading: Boolean,
}

export default class RemoveOrganisationLogoOverlay extends Component<Props> {
  render() {
    const { handleLogoDiscardChanges, handleRemoveLogo, isLoading } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{locale.OrganisationsSettings.RemoveLogoOverlay.header}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.OrganisationsSettings.RemoveLogoOverlay.content}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          
          <Button
            buttonType="blueline"
            messageKey="discard-btn"
            defaultMessage={locale.OrganisationsSettings.RemoveLogoOverlay.discard}
            onClick={handleLogoDiscardChanges}
            data-id="discard-btn"
            className="patient-care-btn-in-group"
            isLoading={isLoading}
          />
          <Button
            buttonType="secondary"
            messageKey="stay-btn"
            defaultMessage={locale.OrganisationsSettings.RemoveLogoOverlay.confirm}
            data-id="stay-btn"
            className="patient-care-btn-in-group"
            onClick={handleRemoveLogo}
            isLoading={isLoading}
          />
        </div>
      </Overlay>
    )
  }
}
