import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Skeleton } from "@patient-access/ui-kit";
import * as RolesConstants from "constants/RolesConstants";
import {
  getEnabledServiceDetails,
  updateEnabledServiceList,
} from "actions/enabledServices";
import FloatingFooterButtons from "components/Share/FloatingFooterButtons/FloatingFooterButtons";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import { clearForm } from "actions/form";
import OrganisationSidebar from "../OrganisationSidebar/OrganisationSidebar";
import EnabledServicesTableHeader from "./EnabledServicesTable/EnabledServicesTableHeader";
import EnabledServiceTableBody from "./EnabledServicesTable/EnabledServicesTableBody";

import {
  Feature,
  FeatureMenu,
  FeatureContent,
  FeatureContentSticky,
  FeatureContentScroll,
  FeatureContentHeader,
  FeatureContentBreadcrumbs,
  FeatureAgenda,
} from "components/Share/feature";

import isEqual from "lodash.isequal";

import "./EnabledServicesTable/styles.scss";

type Props = {
  organisationId: string,
  currentRole: any,
  services: any[],
  servicesLoadedInitially: any[],
  isServicesPending: boolean,
  isLoading: boolean,
  clearForm: () => Action,
  getEnabledServiceDetails: (organisationId: string) => Action,
  updateEnabledServiceList: (organisationId: string) => Action,
};

type State = {
  isEditMode: boolean,
  isDataChanged: boolean,
  isSorting: boolean,
  defaultChecked: boolean,
  initialSelectAllValue: boolean
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  services: state.enabledServices.services,
  servicesLoadedInitially: state.enabledServices.servicesLoadedInitially,
  isLoading: state.enabledServices.isUpdateServicesPending,
  isServicesPending: state.enabledServices.isServicesPending,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  clearForm: () => dispatch(clearForm()),
  getEnabledServiceDetails: (organisationId: string) =>
    dispatch(getEnabledServiceDetails((organisationId: string))),
  updateEnabledServiceList: (organisationId: string) =>
    dispatch(updateEnabledServiceList((organisationId: string))),
});

class EnabledServices extends Component<Props, State> {
  state = {
    isEditMode: false,
    isDataChanged: false,
    isSorting: false,
    defaultChecked: false,
    initialSelectAllValue: false,
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { services, organisationType } = this.props;
    if (
      services !== nextProps.services ||
      organisationType !== nextProps.organisationType
    ) {
      const services = nextProps.services;
      const servicesLoadedInitially = nextProps.servicesLoadedInitially;
      this._dataChangeUpdate(services, servicesLoadedInitially);
    }
  };

  componentDidMount = () => {
    const { getEnabledServiceDetails, organisationId } = this.props;
    getEnabledServiceDetails(organisationId);
  };

  handleChangeMode = (e: any) => {
    const { isEditMode } = this.state;
    if (isEditMode) {
      let isDataChecked = true;
      this.setState({
        isEditMode: !isDataChecked,
      });
    } else {
      this.setState({ isEditMode: true });
    }
  };

  handleDiscardChanges = () => {
    const { clearForm, getEnabledServiceDetails, organisationId } = this.props;
    this.setState(
      {
        isDiscardChangesOverlayOpen: false,
        isDataChanged: false,
        isEditMode: false,
      },
      () => {
        clearForm();
        getEnabledServiceDetails(organisationId);
      }
    );
  };

  handleStay = () => {
    this.setState({ isDiscardChangesOverlayOpen: false });
  };

  openDiscardChangesOverlay = () => {
    this.setState({ isDiscardChangesOverlayOpen: true });
  };

  _dataChangeUpdate = (services, servicesLoadedInitially) => {
    this.setState({
      isDataChanged: !isEqual(
        services.sort(this.alphabetizeList),
        servicesLoadedInitially.sort(this.alphabetizeList)
      ),
    });
  };

  handleDataChanged = () => {
    const { services, servicesLoadedInitially } = this.props;
    this._dataChangeUpdate(services, servicesLoadedInitially);
  };

  handleSelectionClick = (e: any) => {
    const { services } = this.props;
    const isChecked = e && e.target && e.target.checked;
    if (isChecked) {
      services.forEach((service) => (service["enabled"] = true));
    } else {
      services.forEach((service) => (service["enabled"] = false));
    }
    this.setState({
      defaultChecked: isChecked,
    });

    this.handleDataChanged();
  };

  updateEnabledServices = () => {
    const { updateEnabledServiceList, organisationId } = this.props;
    this.setState(
      {
        isDataChanged: true,
        isEditMode: false,
      },
      updateEnabledServiceList(organisationId)
    );
  };

  handleChangeSortingOrder = () => {
    this.setState((prevState) => {
      return { isSorting: !prevState.isSorting };
    });
  };

  render() {
    const {
      isEditMode,
      isDataChanged,
      isDiscardChangesOverlayOpen,
      isSorting,
    } = this.state;
    const {
      currentRole,
      services,
      isLoading,
      isServicesPending,
      organisationId,
    } = this.props;
    const isAbleToEdit = currentRole.role === RolesConstants.ADMIN;
    
    let isDataValid = true;
    services.forEach((service) => {
      if (service.allowedModes && service.allowedModes.length === 0) isDataValid = false;
    });
    
    const renderTableHeader = () => {
      if (services.length) {
        return (
          <Fragment>
            <FeatureContentHeader>
              <div className="row">
                <div className="column">
                  <FeatureContentBreadcrumbs organisationId={organisationId} />
                </div>
              </div>
            </FeatureContentHeader>
            <EnabledServicesTableHeader
              isAbleToEdit={isAbleToEdit}
              isSorting={isSorting}
              isEditMode={isEditMode}
              handleChangeSortingOrder={this.handleChangeSortingOrder}
              handleChangeMode={this.handleChangeMode}
              handleSelectionClick={this.handleSelectionClick}
              data={services}
            />
          </Fragment>
        );
      }
      return (
        <FeatureContentHeader>
          <FeatureContentBreadcrumbs organisationId={organisationId} />
        </FeatureContentHeader>
      );
    };

    const renderTableContent = () => {
      if (services.length) {
        return (
          <EnabledServiceTableBody
            data={services}
            isSorting={isSorting}
            isEditMode={isEditMode}
            handleDataChanged={this.handleDataChanged}
          />
        );
      }
      if (isServicesPending) {
        return <Skeleton type="basic" />;
      }
    };

    return (
      <Fragment>
        <Feature>
          <FeatureMenu>
            <OrganisationSidebar organisationId={organisationId} />
          </FeatureMenu>
          <FeatureContent>
            <FeatureContentSticky>{renderTableHeader()}</FeatureContentSticky>
            <FeatureContentScroll isDataChanged={isDataChanged}>
              {renderTableContent()}
            </FeatureContentScroll>
          </FeatureContent>
        </Feature>
        <FeatureAgenda />

        {isDataChanged && (
          <FloatingFooterButtons
            handleDiscardChanges={this.openDiscardChangesOverlay}
            proceedFunction={this.updateEnabledServices}
            isEditMode={!isDataValid}
            isLoading={isLoading}
          />
        )}
        {isDiscardChangesOverlayOpen && (
          <DiscardChangesOverlay
            handleDiscardChanges={this.handleDiscardChanges}
            handleStay={this.handleStay}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnabledServices);
