import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, SearchFilter, IconSearch } from '@patient-access/ui-kit';
import { setSearchValue } from 'actions/branchesList';
import locale from 'service/locale';
import * as RolesConstants from 'constants/RolesConstants';

type Props = {
  searchValue: string,
  isLoading: boolean,
  currentRole: any,
  setSearchValue: (value: string) => any,

  // ADD_BRANCH_WORKFLOW
  handleAddBranch: () => Action,
};

const mapStateToProps = state => ({
  searchValue: state.branchesList.searchValue,
  currentRole: state.roles.profileCurrentRole,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSearchValue: (value: string) => dispatch(setSearchValue(value)),
});

class FilterBranches extends Component<Props> {
  componentWillUnmount = () => {
    const { setSearchValue } = this.props;
    setSearchValue('');
  }

  render() { 
    const { searchValue, isLoading, currentRole, setSearchValue, handleAddBranch} = this.props;

    const renderAddBranchButton = () => {
      if (currentRole.role === RolesConstants.ADMIN) {
        return (
          <div className="column">
            <Button
              buttonType="secondary"
              messageKey="btn-addBranch"
              defaultMessage={locale.BranchesListRedesign.button.addBranch}
              onClick={handleAddBranch}
              isLoading={isLoading}
            />
          </div>
        );
      }
    }

    return (
      <Fragment>
        <div className="row">
          <div className="column column-full">
            <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
              <SearchFilter
                autoComplete="off"
                id="searchBranchesList"
                name="searchBranchesList"
                placeholder={locale.BranchesListRedesign.filter.branchesList.placeholder}
                clearHandler={() => { setSearchValue(''); }}
                onChange={(e) => { setSearchValue(e.target.value); }}
                icon={<IconSearch outline />}
                value={searchValue}
              />
            </Form>
          </div>
          {renderAddBranchButton()}
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBranches);
