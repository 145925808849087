import React, { Component } from "react";
import { Button, Overlay, Form, FormItem } from "@patient-access/ui-kit";

import locale from "service/locale";

type Props = {
  handleCloseModal: () => any;
  handleConfirmDeleteCalendar: () => any;
  isLoading: boolean;
};

class RemoveInternalEventWarningModal extends Component<Props> {

  render() {
    const { handleCloseModal, handleConfirmDeleteEvent, isLoading } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="confirm-overlay"
        doClose={() => { }}
      >
        <div className="overlay-confirmation-header">
          <h2>{locale.InternalEventDetails.warningModalHeader}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>
            {locale.InternalEventDetails.warningContent}
          </h4>
        </div>
        <div className="patient-care-block">
          <Form noValidate>
            <FormItem type="buttons">
              <div className="overlay-confirmation-buttons">
                <Button
                  buttonType="blueline"
                  messageKey="discard-btn"
                  defaultMessage={locale.InternalEventDetails.warningModalBack}
                  onClick={handleCloseModal}
                  data-id="discard-btn"
                  className="patient-care-btn-in-group"
                  isLoading={isLoading}
                />
                <Button
                  buttonType="secondary"
                  messageKey="stay-btn"
                  defaultMessage={locale.InternalEventDetails.warningModalContinue}
                  data-id="stay-btn"
                  className="patient-care-btn-in-group"
                  onClick={handleConfirmDeleteEvent}
                  isLoading={isLoading}
                />
              </div>
            </FormItem>
          </Form>
        </div>
      </Overlay>
    );
  }
}

export default RemoveInternalEventWarningModal;
