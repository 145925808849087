import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button, Form, FormItem, Checkbox, SearchFilter, IconSearch } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarBodySection, SidebarBodyHeader, SidebarBodyContent, SidebarFooter } from 'components/Share/sidebar';
import { SkeletonSearchFilter, SkeletonTable } from 'components/Share/skeleton';
import { setUI, setUserSearch, getServicesList, getServiceDescription, setUserData, setUserStep, resetUserData } from 'actions/organisationServices';
import { columns, TableServicesList } from '../../TableServicesList/TableServicesList';
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import * as SearchConstants from 'constants/SearchConstants';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  searchValue: string,
  data: any,
  isLoading: boolean,
  isError: boolean,
  isDiscardModalOpen: boolean,
  getServicesList: (organisationId: string) => any,
  getServiceDescription: (serviceId: string) => any,
  setUserSearch: (value: string) => any,
  setUserData: (data: any) => any,
  setUI: (value: any) => any,
  setUserStep: (value: any) => any,
  resetUserData: () => any,
};

type State = {
  isCheckboxChecked: boolean,
  isValidationError: boolean,
  isDiscardModalOpen: boolean,
};

const mapStateToProps = (state) => ({
  searchValue: state.organisationServices.user.search.servicesList,
  data: state.organisationServices.servicesList.data,
  isLoading: state.organisationServices.servicesList.isLoading,
  isError: state.organisationServices.servicesList.isError,
  userData: state.organisationServices.user.data,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getServicesList: (organisationId: string) => dispatch(getServicesList(organisationId)),
  getServiceDescription: (serviceId: string) => dispatch(getServiceDescription(serviceId)),
  setUserSearch: (value: string) => dispatch(setUserSearch(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUI: (value: any) => dispatch(setUI(value)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  resetUserData: () => dispatch(resetUserData()),
});

class StepOne extends Component<Props, State> {
  state = {
    isCheckboxChecked: false,
    isValidationError: false,
    isDiscardModalOpen:false,
  }

  componentDidMount = () => {
    const { organisationId, getServicesList } = this.props;
    getServicesList(organisationId);
  }

  componentWillUnmount = () => {
    const { setUserSearch } = this.props;
    setUserSearch({ servicesList: '' });
  }

  checkServiceSelected = (isSelected) => {
    this.setState({ isValidationError: !isSelected });
  }

  handleClick = () => {
    const { userData, setUserStep } = this.props
    if (userData.serviceId) {
      setUserStep(2);
    } else {
      this.setState({ isValidationError: true });
    }
  }

  handleOpenDiscardDialog = () => {
    const { resetUserData, setUI, userData } = this.props;
    let dataKeys = Object.keys(userData);
    const defaultCheckedDataKeys = ['serviceIsVatIncluded'];
    const ignoredKeys = ['serviceAvailableModes','serviceBranches', 'serviceIsNhsCovered'];
    const arrItems = ['serviceModes'];

    const filteruserData = (d) => {
      if ((!ignoredKeys.includes(d)) &&
        (!(defaultCheckedDataKeys.includes(d) && userData[d] === false) &&
          (!arrItems.includes(d)|| (userData[d] && userData[d].length > 0)) &&
          userData[d] !== null && userData[d] !== '')) {
        return true;
      }
      return false;
    };

    const userDataKeys = dataKeys.filter((d) => filteruserData(d));

    if (!userDataKeys || userDataKeys.length > 0) {
      this.setState({ isDiscardModalOpen: true });
    }
    else {
      this.setState(
        {
          isDiscardModalOpen: false
        },
        () => {
          resetUserData();
          setUI({ isSidebarAddVisible: false });
        }
      );
    }
  };

  handleDiscardChanges = () => {
    const { setUI, resetUserData } = this.props;
    this.setState(
      {
        isDiscardModalOpen: false
      },
      () => {
        resetUserData();
        setUI({ isSidebarAddVisible: false });
        //getBranchesSummary(organisationId, serviceId);
      }
    );
  };
  
  handleStay = () => {
    this.setState({
      isDiscardModalOpen: false
    });
  };



  render() {
    const { isCheckboxChecked, isValidationError, isDiscardModalOpen } = this.state;
    const { organisationId, searchValue, data, isLoading, isError, userData, getServicesList, setUserSearch, resetUserData } = this.props;

    if (isDiscardModalOpen) {
      return (
        <DiscardChangesOverlay
        handleDiscardChanges={this.handleDiscardChanges}
        handleStay={this.handleStay}
      />
      );
    }

    let dataChecked = data;
    if (isCheckboxChecked) {
      dataChecked = data.filter(service => !service.isAlreadyAdded);
    }

    let dataFiltered = dataChecked;
    if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
      dataFiltered = dataChecked.filter(service => service.serviceName.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const isDataFilteredEmpty = dataFiltered.length === 0;

    const renderTableServicesList = () => {
      if (isDataFilteredEmpty) {
        return (
          <NoResults type="info">
            <h2>{locale.OrganisationServices.noResults.servicesSummaryFilter}</h2>
          </NoResults>
        );
      }
      return (
        <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
          <FormItem type="item" error={isValidationError ? 'error' : null}>
            <TableServicesList data={dataFiltered} columns={columns} props={this.props} checkServiceSelected={this.checkServiceSelected} />
          </FormItem>
        </Form>
      );
    }

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationServices.filter.servicesList.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <div className="row">
                  <SkeletonSearchFilter />
                </div>
              </SidebarBodyContent>
              <SidebarBodyContent>
                <SkeletonTable columns={1} isSticky={false} isGrey={false} />
              </SidebarBodyContent>
            </SidebarBodySection>
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <NoResults type="info">
              <h2>{locale.OrganisationServices.error.generic}</h2>
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.tryAgain} onClick={() => { getServicesList(organisationId); }} />
            </NoResults>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <SidebarBodySection className={isDataFilteredEmpty ? 'sidebar-body-section-empty' : ''}>
            <SidebarBodyHeader>
              <h3>{locale.OrganisationServices.filter.servicesList.header}</h3>
            </SidebarBodyHeader>
            <SidebarBodyContent>
              <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                <div className="row row-inline">
                  <SearchFilter
                    id="searchServicesList"
                    name="searchServicesList"
                    placeholder={locale.OrganisationServices.filter.servicesList.placeholder}
                    autoComplete="off"
                    value={searchValue}
                    clearHandler={() => { setUserSearch({ servicesList: '' }); }}
                    onChange={(e) => { setUserSearch({ servicesList: e.target.value }); }}
                    icon={<IconSearch outline />}
                  />
                  <Checkbox
                    id="hideAdded"
                    name="hideAdded"
                    value="hideAdded"
                    label={locale.OrganisationServices.filter.servicesList.labelHideAdded}
                    onChange={(e) => { this.setState({ isCheckboxChecked: e.target.checked }); }}
                  />
                </div>
              </Form>
            </SidebarBodyContent>
            <SidebarBodyContent>
              {renderTableServicesList()}
            </SidebarBodyContent>
          </SidebarBodySection>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.OrganisationServices.sidebar.addService.header}</h2>
        </SidebarHeader>
        <SidebarBody>
          {renderContent()}
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column">
              {isValidationError ? <p className="error">{locale.OrganisationServices.validationError.selectService}</p> : null}
            </div>
            <div className="column">
              <Button
                buttonType="blueline"
                messageKey="some-key"
                defaultMessage={locale.OrganisationServices.button.cancel}
                onClick={() => { this.handleOpenDiscardDialog(); resetUserData(); }}
              />
              <Button
                className={userData.serviceId ? "" : "ui-kit-button-disabled-visually"}
                buttonType="secondary"
                messageKey="some-key"
                defaultMessage={locale.OrganisationServices.button.continue}
                onClick={() => { this.handleClick(); }}
                isLoading={isLoading}
              />
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
