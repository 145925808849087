import React, { Component } from "react";
import { connect } from "react-redux";

import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import { getAppointmentTime } from "helpers/formatData";
import { getCalendarColor } from "helpers/common";
import type { Appointment } from "types/appointments";
import { getAppointmentDetails } from "actions/appointmentDetails";
import type { Action } from "types/actions";

import locale from 'service/locale';
import "./styles.scss";

type Props = {
  appointment: Appointment,
  selected: boolean,
  getAppointmentDetails: (appointmentId: string, type: string) => Action,
  calendarsList: any[]
};

const mapStateToProps = state => ({
  calendarsList: state.branchDetails.calendarsList
});

const mapDispatchToProps = (dispatch: any): any => ({
  getAppointmentDetails: (appointmentId, type) => dispatch(getAppointmentDetails(appointmentId, type))
});

const makeAvatarSymbols = (calendarName: string) => {
  return calendarName[0].toUpperCase();
};

export class AppointmentItem extends Component<Props> {

  openAppointmentDetails = () => {
    const { getAppointmentDetails, appointment } = this.props;
    getAppointmentDetails(appointment.appointmentId, AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA);
  };

  render() {
    const { appointment, selected, calendarsList } = this.props;
    const {
      service,
      startTime,
      endTime,
      room,
      patient,
      calendar,
    } = appointment;
    const appointmentCalendar = calendarsList.find(cal => cal.id === calendar.id);
    return (
      <div
        className={
          `patient-care-search-item-holder
          ${selected ? "selected" : ""}
          ${appointment.status === locale.Appointment.status.cancelled.status ? "cancelled" : ""}`
        }
        onClick={this.openAppointmentDetails}
      >
        <div className="patient-care-avatar-holder">
          <i className={`ui-kit-avatar ui-kit-avatar-small ui-kit-avatar-${appointmentCalendar ? getCalendarColor(appointmentCalendar.order) : 1}`}>
            <span>{makeAvatarSymbols(calendar.name || "A")}</span>
          </i>
        </div>
        <div className="patient-care-info-search">
          <span className="patient-care-time">
            {getAppointmentTime(startTime, endTime)}
          </span>
          <span className="patient-care-service">
            {appointment.status === locale.Appointment.status.cancelled.status ? (locale.Appointment.status.cancelled.label + ": ") : ""}
            {service.name ? service.name : locale.Appointment.removedServiceText}
          </span>
          {patient && (
            <span className="patient-care-person">{`${patient.firstName} ${
              patient.lastName
            }`}</span>
          )}
          {room && <span className="patient-care-room">{room.name}</span>}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentItem);
