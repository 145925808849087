import React, { Component } from 'react';

import './styles/patient-care-feature-content-block.scss';

type Props = {
  children: any,
};

class FeatureContentBlock extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="patient-care-feature-content-block">
        {children}
      </div>
    );
  }
};

export default FeatureContentBlock;
