import React, { Component } from 'react'
import {
  Overlay,
  Button,
} from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleOk: () => any,
  handleCancel: () => any,
}

export default class CompleteOverlay extends Component<Props> {
  render() {
    const { handleOk, handleCancel } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.Modals.completeOverlay.header}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.Modals.completeOverlay.content}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="cancel-btn"
            defaultMessage={locale.Modals.completeOverlay.buttonCancel}
            onClick={handleCancel}
            data-id="cancel-btn"
            className="patient-care-btn-in-group"
          />
          <Button
            buttonType="secondary"
            messageKey="ok-btn"
            defaultMessage={locale.Modals.completeOverlay.buttonOk}
            onClick={handleOk}
            data-id="ok-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    )
  }
}
