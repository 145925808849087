import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Icon,
  IconSortUp,
  IconSortDown,
  NoResults,
  Form,
  FormItem,
  Checkbox
} from "@patient-access/ui-kit";

import type { Action } from "types/actions";
import { updateForm } from "actions/form";
import {
  clearNewBranchServiceList,
  addNewBranchService,
  deleteNewBranchService
} from "actions/services";
import CalendarDetailsServiceItem from "./CalendarDetailsServiceItem/CalendarDetailsServiceItem";

import locale from "service/locale";
import * as ServiceConstants from "constants/ServicesConstants";
import './styles.scss';

type Props = {
  branchServices: any[],
  currentRole: any,
  branchId: string,
  addNewBranchService: (service: Object) => Action,
  deleteNewBranchService: (service: Object) => Action,
  clearNewBranchServiceList: () => Action,
  handleChangeMode: (type: string, status: boolean) => any,
  updateForm: (data: any) => Action,
  services: any[],
  newServicesList: any[],
  isBranchMember: boolean,
  isProcessing: boolean,
};

type State = {
  sorting: boolean,
  isAllServicesSelected: boolean,
  isEditMode: boolean,
};

const mapStateToProps = state => ({
  branchServices: state.branchDetails.services,
  newServicesList: state.services.newServicesList
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  addNewBranchService: service => dispatch(addNewBranchService(service)),
  deleteNewBranchService: service => dispatch(deleteNewBranchService(service)),
  clearNewBranchServiceList: () => dispatch(clearNewBranchServiceList()),
  updateForm: data => dispatch(updateForm(data))
});

class CalendarDetailsServices extends Component<Props, State> {
  state = {
    sorting: false,
    isAllServicesSelected: false,
    isEditMode: false,
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { services, addNewBranchService, branchServices } = nextProps;
    if (this.props.services !== services) {
      this.setState({
        isAllServicesSelected: branchServices.length === services.length },
      () => {
        services.forEach(service => {
          const existingServiceData = branchServices.find(
            branchService => branchService.id === service
          );
          addNewBranchService(existingServiceData || {});
        });
      });
    }
  };

  componentWillUnmount = () => {
    const { clearNewBranchServiceList } = this.props;
    clearNewBranchServiceList();
  };

  handleChangeMode = (e: Event) => {
    e && e.preventDefault();
    const { isEditMode } = this.state;
    const { handleChangeMode } = this.props;
    this.setState({ isEditMode: !isEditMode }, () => {
      handleChangeMode("isEditingServices", !isEditMode);
    });
  };

  sortServicesList = (el1, el2) => {
    const { sorting } = this.state;
    if (el1.name > el2.name) {
      return sorting ? -1 : 1;
    }
    if (el1.name < el2.name) {
      return sorting ? 1 : -1;
    }
    return 0;
  };

  handleChangeSortingOrder = () => {
    this.setState(prevState => {
      return {
        sorting: !prevState.sorting
      };
    });
  };

  handleSelectionClick = () => {
    const { isAllServicesSelected } = this.state;
    const { addNewBranchService, clearNewBranchServiceList, branchServices, updateForm } = this.props;
    this.setState({ isAllServicesSelected: !isAllServicesSelected },
      () => {
        updateForm({ edited: "edited "});
        clearNewBranchServiceList();
        if (!isAllServicesSelected) {
          branchServices.forEach(service => { addNewBranchService(service); });
        }
      }
    );
  };

  handleUpdateServiceList = (type: string, service: Object) => {
    const { branchServices, newServicesList, addNewBranchService, deleteNewBranchService, updateForm } = this.props;
    const isAddingService = type === "add";
    this.setState({
      isAllServicesSelected: isAddingService
        ? branchServices.length === newServicesList.length + 1
        : branchServices.length === newServicesList.length - 1
    },
    () => {
      updateForm({ edited: "edited" });
      isAddingService
        ? addNewBranchService(service)
        : deleteNewBranchService(service)
    });
  };

  render() {
    const { isBranchMember, isProcessing, newServicesList, branchServices } = this.props;
    const {
      sorting,
      isAllServicesSelected,
      isEditMode,
    } = this.state;
    const branchServiceList = branchServices.filter(service => !(service.id === ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID));

    const renderEditButton = () => {
      if (!isBranchMember && !isProcessing) {
        return (
          <button className="patient-care-btn-link" onClick={this.handleChangeMode}>
            {branchServiceList.length ? isEditMode ? locale.Buttons.buttonDone : locale.Buttons.buttonEdit : null}
          </button>
        );
      }
      return null;
    }

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.AddNewCalendar.servicesTitle}</h3>
            {isProcessing ? null : renderEditButton()}
          </div>
          <p>{locale.AddNewCalendar.servicesDescription}</p>
        </div>

        {branchServiceList.length ? (
          <div className="patient-care-modal-table-block">
            <div className="patient-care-custom-table">
              <div className="patient-care-table-header-with-checkbox">
                {
                  isEditMode && (
                    <Form noValidate>
                      <FormItem type="item">
                        <Checkbox
                          id="select_all"
                          name="select_all"
                          label=""
                          value={locale.AddNewCalendar.checkboxValue}
                          checked={isAllServicesSelected}
                          onChange={this.handleSelectionClick}
                          data-id="select_all"
                          className="patient-care-checkbox-col-header"
                        />
                      </FormItem>
                    </Form>
                  )
                }
                <span
                  className="patient-care-link"
                  onClick={this.handleChangeSortingOrder}
                >
                  {locale.AddNewCalendar.colHeader}{" "}
                  {sorting ? (
                    <Icon
                      size="smaller"
                      icon={<IconSortUp outline />}
                      type="inline"
                    />
                  ) : (
                    <Icon
                      size="smaller"
                      icon={<IconSortDown outline />}
                      type="inline"
                    />
                  )}
                </span>
              </div>
            </div>
            <ul className="patient-care-unstyled-list">
              {branchServiceList.sort(this.sortServicesList).map(service => (
                <CalendarDetailsServiceItem
                  key={service.id}
                  service={service}
                  checked={!!newServicesList.find(newService => newService.id === service.id)}
                  isEditMode={isEditMode}
                  handleUpdateServiceList={this.handleUpdateServiceList}
                />
              ))}
            </ul>
          </div>
        ) : (
          <div className="patient-care-empty-block">
            <NoResults type="info">
              <h3>{locale.AddNewCalendar.emptyListTitle}</h3>
              <p>{locale.AddNewCalendar.emptyListDescription}</p>
            </NoResults>
          </div>
        )}
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarDetailsServices);
