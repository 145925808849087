import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { values, intersection } from "lodash";

import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import ServiceProvidedOrNotOverlay from "components/Share/AppointmentDetailsOverlays/ServiceProvidedOrNotOverlay/ServiceProvidedOrNotOverlay";
import CancelOverlay from "components/Share/AppointmentDetailsOverlays/CancelOverlay/CancelOverlay";
import NotUpdatedPastOverlay from "components/Share/AppointmentDetailsOverlays/NotUpdatedPastOverlay/NotUpdatedPastOverlay";
import MissedOverlay from "components/Share/AppointmentDetailsOverlays/MissedOverlay/MissedOverlay";
import CompleteOverlay from "components/Share/AppointmentDetailsOverlays/CompleteOverlay/CompleteOverlay";
import RefundCustomerOverlay from "components/Share/AppointmentDetailsOverlays/RefundCustomerOverlay/RefundCustomerOverlay";
import AppointmentDetailsContent from "components/Share/AppointmentDetailsContent/AppointmentDetailsContent";
import type { PanelActions } from "types/panel";
import type { Action } from "types/actions";
import type { Appointment } from "types/appointments";
import type { AppointmentDetailsType } from "constants/AppointmentDetailsConstants";
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import { closeAgendaDetails, closeSearchDetails, closeDiscardAppointmentChanges } from "actions/panel";
import {
  setAppointmentPaymentStatus,
  setAppointmentStatus,
  setAppointmentCancellationReason,
  updateAppointment,
  updateAppointmentCalendar,
  refundCustomer,
  calculateAppointmentRefund
} from "actions/appointmentDetails";
import { clearForm } from "actions/form";
import locale from "service/locale";
import formFields from "components/Share/AppointmentDetailsContent/CustomerDetails/CustomerDetails";
import { setAppointmentChanges } from "actions/appointmentDetails";

import "./styles.scss";

type Props = {
  type: AppointmentDetailsType,
  agendaAppointment: Appointment,
  searchAppointment: Appointment,
  updateAppointment: (
    appointment: Appointment,
    isPaid: boolean,
    type: AppointmentDetailsType,
    startStatus: number
  ) => any,
  clearForm: () => Action,
  setAppointmentPaymentStatus: (
    status: number,
    type: AppointmentDetailsType
  ) => Action,
  setAppointmentStatus: (
    status: number,
    outcomeStatus: number,
    type: AppointmentDetailsType,
    behalfOfPatient: any
  ) => Action,
  setAppointmentCancellationReason: (
    reason?: string,
    type: AppointmentDetailsType
  ) => Action,
  closeAgendaDetails: () => PanelActions,
  closeSearchDetails: () => PanelActions,
  updateAppointmentCalendar: (
    appointment: Appointment,
    isPaid: boolean,
    slotId: string,
    type: AppointmentDetailsType,
    startStatus: number
  ) => Action,
  setAppointmentChanges:(isAppointmentDataChanged: boolean) => Action,
  form: any,
  refundCustomer: (
    isFullRefund: boolean,
    refundReason: string,
    appointmentId: string,
    type: AppointmentDetailsType
  ) => Action,
  calculateAppointmentRefund: (
    appointmentId: string,
    refundStatus: string,
  ) => Action,
  isRefundCustomerPending: boolean
};

type State = {
  isDiscardChangesOverlayOpen: boolean,
  isCompleteConfirmationOpened: boolean,
  isServiceNotProvidedOpened: boolean,
  isServiceNotProvidedOnlineOpened: boolean,
  isCancelOnlineOpened: boolean,
  isCancelOpened: boolean,
  isMissedOpened: boolean,
  isCompleteOpened: boolean,
  isProvidedWithNoPayment: boolean,
  startStatus: number,
  startPaymentStatus: number,
  isStatusNotUpdatedForPastAppointment: boolean,
  isRefundCustomerOverlayOpened: boolean
};

const mapStateToProps = state => ({
  agendaAppointment: state.appointmentDetails,
  searchAppointment: state.search.details,
  form: state.form,
  isRefundCustomerPending: state.appointmentDetails.isRefundCustomerPending
});

const mapDispatchToProps = (dispatch: any): any => ({
  setAppointmentPaymentStatus: (status, type) =>
    dispatch(setAppointmentPaymentStatus(status, type)),
  closeAgendaDetails: () => dispatch(closeAgendaDetails()),
  closeSearchDetails: () => dispatch(closeSearchDetails()),
  setAppointmentStatus: (status, outcomeStatus, type, behalfOfPatient) =>
    dispatch(setAppointmentStatus(status, outcomeStatus, type, behalfOfPatient)),
  setAppointmentCancellationReason: (reason, type) =>
    dispatch(setAppointmentCancellationReason(reason, type)),
  updateAppointment: (appointment, isPaid, type, startStatus) =>
    dispatch(updateAppointment(appointment, isPaid, type, startStatus)),
  clearForm: () => dispatch(clearForm()),
  closeDiscardAppointmentChanges: () => dispatch(closeDiscardAppointmentChanges()),
  setAppointmentChanges: isAppointmentDataChanged => dispatch(setAppointmentChanges(isAppointmentDataChanged)),
  updateAppointmentCalendar: (appointment, isPaid, slotId, type, startStatus) =>
    dispatch(updateAppointmentCalendar(appointment, isPaid, slotId, type, startStatus)),
  refundCustomer: (isFullRefund, refundReason, appointmentId, type) =>
    dispatch(refundCustomer(isFullRefund, refundReason, appointmentId, type)),
  calculateAppointmentRefund: (appointmentId, refundStatus) =>
    dispatch(calculateAppointmentRefund(appointmentId, refundStatus))
});

export class AppointmentDetails extends Component<Props, State> {
  state = {
    isCompleteOpened: false,
    isDiscardChangesOverlayOpen: false,
    isCompleteConfirmationOpened: false,
    isServiceNotProvidedOpened: false,
    isServiceNotProvidedOnlineOpened: false,
    isCancelOnlineOpened: false,
    isCancelOpened: false,
    isMissedOpened: false,
    isProvidedWithNoPayment: false,
    startStatus: 0,
    startPaymentStatus: 0,
    isStatusNotUpdatedForPastAppointment: false,
    isRefundCustomerOverlayOpened: false
  };

  componentWillMount = () => {
    const {
      agendaAppointment,
      searchAppointment,
      type
    } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    this.setState({
      startStatus: viewedAppointment && viewedAppointment.status,
      startPaymentStatus: viewedAppointment && viewedAppointment.payment.paymentStatus
    });
  };

  componentWillUnmount = () => {
    this.setState({
      isCompleteOpened: false,
      isDiscardChangesOverlayOpen: false,
      isCompleteConfirmationOpened: false,
      isServiceNotProvidedOpened: false,
      isServiceNotProvidedOnlineOpened: false,
      isCancelOnlineOpened: false,
      isCancelOpened: false,
      isMissedOpened: false,
      isProvidedWithNoPayment: false,
      isRefundCustomerOverlayOpened: false
    });
  };

  handleSaveAppointmentDetails = (skipWarning?: boolean = false) => {
    const { type, updateAppointment, form, updateAppointmentCalendar, agendaAppointment, searchAppointment } = this.props;
    const { slotId } = form;
    const { isProvidedWithNoPayment, startStatus } = this.state;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    if (!skipWarning && this.isStatusNotUpdatedForPastAppointment(viewedAppointment, startStatus)) {
      this.setState({  isStatusNotUpdatedForPastAppointment: true });
    } else {
      const isPaid = viewedAppointment.payment.paymentStatus === locale.Appointment.paymentStatus.paid.value;
      const isServiceProvided = viewedAppointment.status === locale.Appointment.status.provided.status &&
        viewedAppointment.outcomeStatus === locale.Appointment.status.provided.outcomeStatus;
      const isFree = viewedAppointment.payment.totalAmount === 0;

      if (isServiceProvided && !isPaid && !isFree && !isProvidedWithNoPayment && startStatus !== locale.Appointment.status.provided.status) {
        this.setState({ isCompleteConfirmationOpened: true });
      } else {
        slotId
          ? updateAppointmentCalendar(viewedAppointment, isPaid, slotId, type, startStatus)
          : updateAppointment(viewedAppointment, isPaid, type, startStatus);
      }
    }
  };

  isStatusNotUpdatedForPastAppointment = (viewedAppointment: Appointment, startStatus: number): boolean => {
    const currentTime = moment().format().split("+")[0];
    const isPastAppointment = viewedAppointment.endTime.split('+')[0] <= currentTime;
    return (
      startStatus === locale.Appointment.status.booked.status &&
      viewedAppointment.status === locale.Appointment.status.booked.status &&
      isPastAppointment
    );
  };

  handlePaymentTaken = (e?: Event) => {
    e && e.preventDefault();
    const { setAppointmentPaymentStatus, type } = this.props;
    setAppointmentPaymentStatus(
      locale.Appointment.paymentStatus.paid.value,
      type
    );
    this.setState(
      { isCompleteConfirmationOpened: false },
      () => {
        this.handleSaveAppointmentDetails();
      });
  };

  handlePaymentNotTaken = (e?: Event) => {
    e && e.preventDefault();
    this.setState(
      { isCompleteConfirmationOpened: false, isProvidedWithNoPayment: true },
      () => {
        this.handleSaveAppointmentDetails();
      });
  };

  handleCancelAppointment = (reason: string, behalfOfPatient: boolean) => {
    const {
      setAppointmentStatus,
      agendaAppointment,
      searchAppointment,
      setAppointmentPaymentStatus,
      setAppointmentCancellationReason,
      type
    } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    const isWalkInAppointment = viewedAppointment.booking.bookingMethod === locale.Appointment.type.walkIn.value;
    if (!isWalkInAppointment) {
      setAppointmentPaymentStatus(locale.Appointment.paymentStatus.refunded.value, type);
    }

    const { status, outcomeStatus } = locale.Appointment.status.cancelled;

    setAppointmentStatus(status, outcomeStatus, type, behalfOfPatient);
    setAppointmentCancellationReason(reason, type);

    this.setState({ isCancelOpened: false, isCancelOnlineOpened: false });
  };

  handleRefundDialog = (e?: Event) => {
    e && e.preventDefault();
    this.setState({ isServiceNotProvidedOnlineOpened: true });
  };

  handleRefund = (e?: Event) => {
    e && e.preventDefault();
    const { setAppointmentPaymentStatus, type } = this.props;
    setAppointmentPaymentStatus(locale.Appointment.paymentStatus.refunded.value, type);
    this.setState({ isServiceNotProvidedOnlineOpened: false });
  };

  openDiscardChangesOverlay = () => {
    this.setState({ isDiscardChangesOverlayOpen: true });
  };

  closeAppointmentDetails = (e: Event) => {
    e && e.preventDefault();
    const { closeAgendaDetails, closeSearchDetails, clearForm, type, agendaAppointment, searchAppointment, closeDiscardAppointmentChanges, setAppointmentChanges } = this.props;
    const { startStatus } = this.state;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    if (this.isStatusNotUpdatedForPastAppointment(viewedAppointment, startStatus)) {
      this.setState({  isStatusNotUpdatedForPastAppointment: true });
    } else {
      this.setState({
        isProvidedWithNoPayment: false
      }, () => {
        type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
        ? closeAgendaDetails()
        : closeSearchDetails();
      clearForm();
      setAppointmentChanges(false);
      closeDiscardAppointmentChanges();
      })
    }
  };

  handleDiscardChanges = () => {
    this.setState({ isDiscardChangesOverlayOpen: false }, this.closeAppointmentDetails);
  };

  handleCloseOverlay = (overlay: string) => {
    const { closeAgendaDetails, closeSearchDetails, clearForm, type, agendaAppointment, searchAppointment, closeDiscardAppointmentChanges, form, setAppointmentChanges } = this.props;
    const { startStatus } = this.state;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    this.setState({ [overlay]: false },
      () => {
        closeDiscardAppointmentChanges();
        if (overlay !== "isStatusNotUpdatedForPastAppointment") return;

        const keys = Object.keys(form);

        if (intersection(keys, values(formFields)).length) {
          this.handleSaveAppointmentDetails(true);
        } else {
          type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA && viewedAppointment.status === startStatus
            ? closeAgendaDetails()
            : closeSearchDetails();
          clearForm();
          setAppointmentChanges(false);
        }
      });
  };

  handleDoNotChangeAppointment = () => {
    const { setAppointmentStatus, type, agendaAppointment, searchAppointment } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    setAppointmentStatus(viewedAppointment.status, viewedAppointment.outcomeStatus, type);
    this.setState({
      isCompleteOpened: false,
      isCancelOnlineOpened: false,
      isCancelOpened: false,
      isServiceNotProvidedOpened: false,
      isServiceNotProvidedOnlineOpened: false,
      isMissedOpened: false,
      isRefundCustomerOverlayOpened: false
    });
  };

  handleSetAppointmentStatus = (status: number, outcomeStatus: number, behalfOfPatient: boolean, isForced?: boolean) => {
    const { setAppointmentStatus, agendaAppointment, searchAppointment, type } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    const isOnlineAppointment = viewedAppointment.booking.bookingMethod === locale.Appointment.type.online.value;

    this.setState({
      isStatusNotUpdatedForPastAppointment: false
    });

    if (status === locale.Appointment.status.provided.status) {
      // excluded calling setAppointmentStatus() for cancelled status,
      // will be evaluated later when user selects reason and by whom was cancellation
      // see the method handleCancelAppointment() for details
      setAppointmentStatus(status, outcomeStatus, type, behalfOfPatient);
      switch (outcomeStatus) {
          case locale.Appointment.status.notProvided.outcomeStatus:
            isOnlineAppointment
              ? this.setState({ isServiceNotProvidedOnlineOpened: true })
              : this.setState({ isServiceNotProvidedOpened: true });
            break;
          case locale.Appointment.status.missed.outcomeStatus:
            this.setState({ isMissedOpened: true });
            break;
          default:
            this.setState({ isCompleteOpened: true });
            break;
        }
    } else if (locale.Appointment.status.cancelled.status && !behalfOfPatient) {
      isOnlineAppointment
          ? this.setState({ isCancelOnlineOpened: true })
          : this.setState({ isCancelOpened: true });
    }
  };

  handleQuickCancelAppointment = () => {
    const { agendaAppointment, searchAppointment, type } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    this.setState({
      isCancelOpened: viewedAppointment.booking.bookingMethod === locale.Appointment.type.walkIn.value,
      isCancelOnlineOpened: viewedAppointment.booking.bookingMethod === locale.Appointment.type.online.value
    });
  };

  handleQuickAppointmentRefund = () => {
    const { setAppointmentStatus, type, agendaAppointment, searchAppointment } = this.props;
    const behalfOfPatient = false;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    setAppointmentStatus(
      viewedAppointment.status,
      viewedAppointment.outcomeStatus,
      type,
      behalfOfPatient
    );
    this.setState({
      isRefundCustomerOverlayOpened: true
    });
  }

  handleAppointmentRefund = (isFullRefund: boolean, refundReason: string) => {
     const {
      agendaAppointment,
      searchAppointment,
      setAppointmentPaymentStatus,
      type,
      refundCustomer
    } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    const isWalkInAppointment = viewedAppointment.booking.bookingMethod === locale.Appointment.type.walkIn.value;
    if (!isWalkInAppointment) {
      setAppointmentPaymentStatus(locale.Appointment.paymentStatus.refundedbySupportTeam.value, type);
    this.setState({
      isRefundCustomerOverlayOpened: true,
      isFullRefund: isFullRefund,
      refundReason: refundReason,
    });
    refundCustomer(isFullRefund, refundReason, viewedAppointment.appointmentId, type)
    }
  }

  handleChangeRefundType = (isFullRefund: boolean) => {
    const { calculateAppointmentRefund, agendaAppointment, searchAppointment, type } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    calculateAppointmentRefund(viewedAppointment.appointmentId, isFullRefund);
  };

  render() {
    const {
      isDiscardChangesOverlayOpen,
      isCompleteConfirmationOpened,
      isCancelOnlineOpened,
      isCancelOpened,
      isServiceNotProvidedOpened,
      isServiceNotProvidedOnlineOpened,
      isMissedOpened,
      isCompleteOpened,
      startStatus,
      startPaymentStatus,
      isStatusNotUpdatedForPastAppointment,
      isRefundCustomerOverlayOpened,
      refundReason,
    } = this.state;
    const { agendaAppointment, searchAppointment, setAppointmentPaymentStatus, type, isRefundCustomerPending } = this.props;
    const viewedAppointment = type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;
    if (!viewedAppointment.appointmentId)
      return null;
    const price = viewedAppointment && viewedAppointment.payment.totalAmount;
    const isPaidUpfront = startPaymentStatus === locale.Appointment.paymentStatus.paid.value;
    return (
      <React.Fragment>
        <AppointmentDetailsContent
          type={type}
          startStatus={startStatus}
          appointment={viewedAppointment}
          isStatusNotUpdatedForPastAppointment={isStatusNotUpdatedForPastAppointment}
          handleRefundDialog={this.handleRefundDialog}
          handleDiscardChanges={this.openDiscardChangesOverlay}
          handleSaveAppointmentDetails={this.handleSaveAppointmentDetails}
          handleCloseAppointmentDetails={this.closeAppointmentDetails}
          handleSetAppointmentPaymentStatus={setAppointmentPaymentStatus}
          handleSetAppointmentStatus={this.handleSetAppointmentStatus}
          handleQuickCancelAppointment={this.handleQuickCancelAppointment}
          handleQuickAppointmentRefund = {this.handleQuickAppointmentRefund}
          refundReason = {refundReason}
        />

        {isDiscardChangesOverlayOpen && (
          <DiscardChangesOverlay
            handleDiscardChanges={this.handleDiscardChanges}
            handleStay={() =>
              this.handleCloseOverlay("isDiscardChangesOverlayOpen")
            }
          />
        )}
        {isCompleteConfirmationOpened && (
          <ServiceProvidedOrNotOverlay
            type={AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.PROVIDED}
            isOnline={false}
            price={price}
            handleCancel={this.handlePaymentNotTaken}
            handleOk={this.handlePaymentTaken}
          />
        )}
        {isCancelOpened && (
          <CancelOverlay
            isOnline={false}
            handleCancelAppointment={this.handleCancelAppointment}
            handleDoNotCancelAppointment={this.handleDoNotChangeAppointment}
            paymentStatus={viewedAppointment.payment.paymentStatus}
          />
        )}
        {isCancelOnlineOpened && (
          <CancelOverlay
            isOnline={true}
            isPaidUpfront={isPaidUpfront}
            price={price}
            handleCancelAppointment={this.handleCancelAppointment}
            handleDoNotCancelAppointment={this.handleDoNotChangeAppointment}
            paymentStatus={viewedAppointment.payment.paymentStatus}
          />
        )}
        {isServiceNotProvidedOpened && (
          <ServiceProvidedOrNotOverlay
            type={AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.NOT_PROVIDED}
            isOnline={false}
            handleOk={() => this.handleCloseOverlay("isServiceNotProvidedOpened")}
            handleCancel={this.handleDoNotChangeAppointment}
          />
        )}
        {isServiceNotProvidedOnlineOpened && (
          <ServiceProvidedOrNotOverlay
            type={AppointmentDetailsConstants.APPOINTMENT_STATUS_TYPES.NOT_PROVIDED}
            isOnline={true}
            handleCancel={this.handleDoNotChangeAppointment}
            handleOk={this.handleRefund}
          />
        )}
        {isRefundCustomerOverlayOpened && (
          <RefundCustomerOverlay
            handleCancel={this.handleDoNotChangeAppointment}
            handleAppointmentRefund={this.handleAppointmentRefund}
            handleChangeRefundType={this.handleChangeRefundType}
            isLoading={isRefundCustomerPending}
          />
        )}
        {isMissedOpened && (
          <MissedOverlay
            handleOk={() => this.handleCloseOverlay("isMissedOpened")}
            handleCancel={this.handleDoNotChangeAppointment}
          />
        )}
        {isCompleteOpened && (
          <CompleteOverlay
            handleOk={() => this.handleCloseOverlay("isCompleteOpened")}
            handleCancel={this.handleDoNotChangeAppointment}
          />
        )}
        {isStatusNotUpdatedForPastAppointment && (
          <NotUpdatedPastOverlay
            handleSetAppointmentStatus={this.handleSetAppointmentStatus}
            handleCloseOverlay={() => this.handleCloseOverlay("isStatusNotUpdatedForPastAppointment")}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDetails);
