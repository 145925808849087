import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { SkeletonChart } from 'components/Pages/Reports/skeleton';
import * as ColorConstants from 'constants/ColorConstants';
import * as RolesConstants from 'constants/RolesConstants';
import { ReportsTooltip } from 'components/Pages/Reports/wrappers';
import './styles.scss';

// SuperAdmin: 376px

type Props = {
  currentRole:any,
  isResizing: boolean,
  nominationRequests: any,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  filters: state.reportsSmartPharmacy.filters,
  prescriptionRequests: state.reportsSmartPharmacy.metrics.prescription.data,
});

class ChartPrescriptionRequests extends Component<Props> {
  formatXAxis = (tickItem) => {
    return moment(tickItem).format('DD-MMM');
  }

  formatYAxis = (tickItem) => {
    return tickItem + ' days';
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      let days = Math.floor(payload[0].payload.averageTime);
      let hours = Math.round((payload[0].payload.averageTime - Math.floor(payload[0].payload.averageTime)) * 24);

      return (
        <ReportsTooltip>
          <h4>{payload[0] ? <Fragment>Prescription requests {moment(payload[0].payload.weekDate).format('DD/MM/YYYY')}</Fragment> : null}</h4>
          <ul>
            <li><span><strong>Average time per prescription request:</strong></span><span>{days} days {hours} hours</span></li>
          </ul>
        </ReportsTooltip>
      );
    }
    return null;
  }

  render() {
    const { isResizing, filters, prescriptionRequests, currentRole } = this.props;
    const isOrgVisible = filters.organisationId === "" ? true : false;

    let height = 376;
    if (currentRole.role === RolesConstants.BRANCH_ADMIN) {
      height = 167;
    }
    else if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) {
      height = 283;
    } else if (currentRole.role === RolesConstants.ADMIN && !isOrgVisible) {
      height = 283;
    }

    if (isResizing) {
      return (
        <SkeletonChart height={height} />
      );
    }
    
    return (
      <ResponsiveContainer className="recharts-prescriptionRequests" width="99%" height={height}>
        <BarChart data={prescriptionRequests}>
          <CartesianGrid strokeDasharray="0 0" stroke={ColorConstants.COLOR_GREY_LIGHT} vertical={false} />
          <XAxis tickFormatter={this.formatXAxis} tickLine={false} tickSize={16} axisLine={false} dataKey="weekDate" />
          <YAxis width={65} tickFormatter={this.formatYAxis} tickCount={5} tickLine={false} tickSize={16} axisLine={false} />
          <Tooltip content={this.formatTooltip} cursor={false} />
          <Bar dataKey="averageTime" fill={ColorConstants.COLOR_GRAPH_TWO} name="averageTime" maxBarSize={40} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default connect(mapStateToProps)(ChartPrescriptionRequests);
