import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { OverlayDescription } from 'components/Share/overlay';
import locale from 'service/locale';
import { setBranchesOverlayIsVisible } from 'actions/taskManager';

type Props = {
    isOverlayBranchesVisible: boolean,
    taskDetail: any,
    setBranchesOverlayIsVisible: (isOverlayBranchesVisible: any) => any,
};

const mapStateToProps = state => ({
    isOverlayBranchesVisible: state.taskManager.overlay.isOverlayBranchesVisible,
    taskDetail: state.taskManager.taskDetail.data,
});

const mapDispatchToProps = (dispatch: any) => ({
    setBranchesOverlayIsVisible: (isOverlayBranchesVisible: any) => dispatch(setBranchesOverlayIsVisible(isOverlayBranchesVisible)),
});

class OverlayBranches extends Component<Props> {
    render() {
        const { isOverlayBranchesVisible, setBranchesOverlayIsVisible, taskDetail } = this.props;

        const renderContent = () => {
            var failedBranches = taskDetail.output.filter(branch => branch.isSuccess === false);
            return (
                <Fragment>
                    {<div className="overlay-description-content-heading"><h1>{locale.OrganisationServices.overlay.confirmation.branchNotUpdated}</h1></div>}
                    {<div className="overlay-description-content-body">{failedBranches.map((branch) =>
                        <li>{branch.branchName}</li>
                    )}</div>}
                </Fragment>
            );
        }

        if (isOverlayBranchesVisible) {
            return (
                <OverlayDescription isOpen={true} doClose={() => { setBranchesOverlayIsVisible(false) }}>
                    <div className="overlay-description-header" />
                    <div className="overlay-description-content">
                        {renderContent()}
                    </div>
                    <div className="overlay-description-footer">
                        <Button
                            buttonType="blueline"
                            messageKey={locale.OrganisationServices.button.goBack}
                            defaultMessage={locale.OrganisationServices.button.goBack}
                            onClick={() => { setBranchesOverlayIsVisible(false) }}
                        />
                    </div>
                </OverlayDescription>
            );
        };
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayBranches);
