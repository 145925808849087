import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy, useRowSelect } from "react-table";
import {
  Form,
  IconCog,
  IconPerson,
  IconPhone,
  IconVideo,
  IconCheckbox,
  IconHome,
} from "@patient-access/ui-kit";
import { formatCurrency, formatAsCurrency } from "helpers/formatData";
import TableHeaderCellSummary from "./TableHeaderCellSummary";
import IconSort from "svg/IconSort";
import locale from "service/locale";
import "./styles.scss";

export const columnsAffiliates = [
  {
    className: "cell-main",
    Header:
      locale.OrganisationServices.table.branchesSummary.headers.branchName,
    accessor: "branchName",
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.showPrice,
    accessor: "isShowPrice",
    Cell: ({ cell: { value } }) => (
      <i className="group">
        {value ? (
          <IconCheckbox outline={false} />
        ) : (
          <IconCheckbox outline={true} />
        )}
      </i>
    ),
  },
  {
    className: "cell-default",
    Header:
      locale.OrganisationServices.table.branchesSummary.headers.servicePrice,
    accessor: "servicePrice",
    Cell: ({ cell: { value } }) => (
      <span>
        {value !== null
          ? formatCurrency(value)
          : locale.OrganisationServices.table.branchesSummary.labels
              .servicePriceNull}
      </span>
    ),
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.status,
    accessor: "paused",
    Cell: ({ cell: { value } }) => (
      <span>
        {value
          ? locale.OrganisationServices.table.branchesSummary.labels
              .statusPaused
          : locale.OrganisationServices.table.branchesSummary.labels
              .statusActive}
      </span>
    ),
    sortType: "basic",
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.duration,
    accessor: "duration",
    Cell: ({ cell: { value } }) => (
      <span>
        {value}{" "}
        {locale.OrganisationServices.table.branchesSummary.labels.duration}
      </span>
    ),
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.modes,
    accessor: "modes",
    Cell: ({ cell: { value } }) => (
      <i className="group">
        {value.sort().map((item) => (
          <Fragment key={item}>
            {item === 0 ? (
              <span
                title={
                  locale.OrganisationServices.section.appointmentTypes.labelF2F
                }
              >
                <IconPerson outline={false} />
              </span>
            ) : null}
            {item === 1 ? (
              <span
                title={
                  locale.OrganisationServices.section.appointmentTypes
                    .labelVideo
                }
              >
                <IconVideo outline={false} />
              </span>
            ) : null}
            {item === 2 ? (
              <span
                title={
                  locale.OrganisationServices.section.appointmentTypes
                    .labelPhone
                }
              >
                <IconPhone outline={false} />
              </span>
            ) : null}
            {item === 5 ? (
              <span
                title={
                  locale.OrganisationServices.section.appointmentTypes
                    .labelHomeDelivery
                }
              >
                <IconHome outline={false} />
              </span>
            ) : null}
          </Fragment>
        ))}
      </i>
    ),
    sortType: "basic",
  },
  {
    className: "cell-default",
    Header:
      locale.OrganisationServices.table.branchesSummary.headers.bookingUrl,
    accessor: "bookingUrl",
    Cell: ({ cell: { value } }) => (
      <span>
        {value ? (
          value
        ) : (
          <em>
            {
              locale.OrganisationServices.table.branchesSummary.labels
                .bookingUrlEmpy
            }
          </em>
        )}
      </span>
    ),
  },
];

const IndeterminateCheckbox = React.forwardRef(
  (
    { checkServiceSelected, props, branchIds, data, indeterminate, ...rest },
    ref
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="input-checkbox input-checkbox-medium">
        <label>
          <input
            type="checkbox"
            onClick={(e) => onCheckBoxClick(e, props, branchIds, data)}
            ref={resolvedRef}
            {...rest}
          />
          <span>&nbsp;</span>
        </label>
      </div>
    );
  }
);

const onCheckBoxClick = (e, props, branchId, data) => {
  let userDataBranches = props.userData.serviceBranches;
  if (branchId && typeof branchId == "string") {
    if (e.target.checked) {
      if (data.length === userDataBranches.length) {
        userDataBranches = [];
      }

      if (
        userDataBranches.length === 0 ||
        userDataBranches.indexOf(branchId) < 0
      )
        userDataBranches.push(branchId);
    } else {
      userDataBranches = userDataBranches.filter((d) => d !== branchId);
    }
  } else if (branchId && typeof branchId == "object" && branchId.length > 0) {
    if (e.target.checked) {
      userDataBranches = userDataBranches.concat(branchId);
      userDataBranches = userDataBranches.filter(
        (item, pos) => userDataBranches.indexOf(item) === pos
      );
    } else {
      userDataBranches = userDataBranches.filter(
        (d) => branchId.indexOf(d) < 0
      );
    }
  }
  props.userData.serviceBranches = userDataBranches;
  if (userDataBranches.length <= 0) {
    userDataBranches = data.map((d) => d.branchId);
  }

  props.setUserData({ serviceBranches: userDataBranches });
  updateStateData(
    props,
    data.filter((d) => userDataBranches.indexOf(d.branchId) >= 0)
  );
};

const updateStateData = (props, data) => {
  let mode = [];
  const defaultServicePrice =
    data.filter((service) => service.servicePrice !== null).length > 0;
  const hasModeF2F = data.filter((service) => service.modes.includes(0)).length;
  const hasModeVideo = data.filter((service) =>
    service.modes.includes(1)
  ).length;
  const hasModePhone = data.filter((service) =>
    service.modes.includes(2)
  ).length;
  const hasModeHomeDelivery = data.filter((service) =>
    service.modes.includes(5)
  ).length;
  hasModeF2F === data.length
    ? mode.push(0)
    : data.length > hasModeF2F && hasModeF2F > 0
    ? mode.push("F")
    : (mode = [...new Set(mode)]);
  hasModeVideo === data.length
    ? mode.push(1)
    : data.length > hasModeVideo && hasModeVideo > 0
    ? mode.push("V")
    : (mode = [...new Set(mode)]);
  hasModePhone === data.length
    ? mode.push(2)
    : data.length > hasModePhone && hasModePhone > 0
    ? mode.push("P")
    : (mode = [...new Set(mode)]);
  hasModeHomeDelivery === data.length
    ? mode.push(5)
    : data.length > hasModeHomeDelivery && hasModeHomeDelivery > 0
    ? mode.push("H")
    : (mode = [...new Set(mode)]);
  const hasIsPaused = data.filter((service) => service.paused === true).length;
  const hasIsActive = data.filter((service) => service.paused === false).length;
  const isPaused = hasIsPaused === data.length && hasIsPaused > 0;
  const isActive = hasIsActive === data.length && hasIsActive > 0;
  const hasPriceShown = data.filter(
    (service) => service.isShowPrice === true
  ).length;
  const hasNotPriceShown = data.filter(
    (service) => service.isShowPrice === false
  ).length;
  const isShowPrice = hasPriceShown === data.length && hasPriceShown > 0;
  const isNotShowPrice =
    hasNotPriceShown === data.length && hasNotPriceShown > 0;
  const minPrice = Math.min.apply(
    Math,
    data.map((service) => service.servicePrice)
  );
  const maxPrice = Math.max.apply(
    Math,
    data.map((service) => service.servicePrice)
  );
  const hasVatIncluded = data.filter(
    (service) => service.vatIncluded === true
  ).length;
  const hasNotVatIncluded = data.filter(
    (service) => service.vatIncluded === false
  ).length;
  const isVatIncluded = hasVatIncluded === data.length && hasVatIncluded > 0;
  const isNotVatIncluded =
    hasNotVatIncluded === data.length && hasNotVatIncluded > 0;
  const minDuration = Math.min.apply(
    Math,
    data.map((service) => service.duration)
  );
  const maxDuration = Math.max.apply(
    Math,
    data.map((service) => service.duration)
  );
  const minCutOff = Math.min.apply(
    Math,
    data.map((service) => service.cutOff)
  );
  const maxCutOff = Math.max.apply(
    Math,
    data.map((service) => service.cutOff)
  );
  const url = [...new Set(data.map((service) => service.bookingUrl))];
  props.setUserData({
    serviceModes: mode,
    serviceIsActive: isActive ? true : isPaused ? false : "indeterminate",
    servicePrice: defaultServicePrice
      ? minPrice === maxPrice
        ? formatAsCurrency(minPrice)
        : formatAsCurrency(minPrice) + " - " + formatAsCurrency(maxPrice)
      : null,
    serviceIsVatIncluded: isVatIncluded
      ? true
      : isNotVatIncluded
      ? false
      : "indeterminate",
    serviceDuration:
      minDuration === maxDuration
        ? minDuration
        : minDuration + " - " + maxDuration,
    serviceCutOff:
      minCutOff === maxCutOff ? minCutOff : minCutOff + " - " + maxCutOff,
    serviceBranches: data && data.map((b) => b.branchId),
    serviceIsShowPrice: isShowPrice
      ? true
      : isNotShowPrice
      ? false
      : "indeterminate",
    bookingUrl: {
      isEdited: false,
      value: url.length > 1 ? "Multiple URLs" : url[0],
    },
  });
};

const setEditBranchesData = (branchId, props, data, editAllBranches) => {
  editAllBranches();
  props.setUI({ isSidebarEditVisible: true, isEditByBranch: true });
  props.setUserData({ serviceBranches: [ branchId ] });
  updateStateData(
    props,
    data.filter((d) => d.branchId === branchId)
  );
  props.setUserEditData({ selectedSummaryBranches: [ branchId ] });
}

export function TableBranchesSummaryAffiliates({ columns, data, props, editAllBranches }) {
  React.useEffect(() => {
    updateStateData(props, data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: "branchName", desc: false }],
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          className: "cell-checkbox",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              data={data}
              branchIds={data.map((d) => d.branchId)}
              {...getToggleAllRowsSelectedProps()}
              props={props}
            />
          ),
          Cell: ({ row }) => {
            if (
              props.userData.serviceBranches != null &&
              props.userData.serviceBranches.indexOf(row.original.branchId) >=
                0 &&
              (!row.isNewSelection || (row.isNewSelection && !row.isSelected))
            ) {
              row.isSelected = true;
              selectedFlatRows.push(row);
              row.toggleRowSelected(row.id, true);
              selectedRowIds[row.id] = true;
              row.isNewSelection = true;
            }
            return (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                props={props}
                data={data}
                branchIds={row.original.branchId}
              />
            );
          },
          disableSortBy: true,
        },
        ...columns,
      ]);
    }
  );

  const [isMenuOn, setMenuOn] = useState(false);

  const renderCell = (row, cell) => {
    if (
      cell.column.Header ===
      locale.OrganisationServices.table.branchesSummary.headers.branchName
    ) {
      return (
        <Link
          to="#edit"
          title={cell.value}
          onClick={(e) => {
            e.preventDefault();
            setEditBranchesData(row.original.branchId, props, data, editAllBranches);
          }}
        >
          {cell.render("Cell")}
        </Link>
      );
    }
    return <Fragment>{cell.render("Cell")}</Fragment>;
  };

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div
            className="react-table-settings-background"
            onClick={() => {
              setMenuOn(!isMenuOn);
            }}
          />
          <div className="react-table-settings-menu">
            <h2>{locale.OrganisationServices.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map((column) => {
                if (column.id === "selection" || column.id === "branchName")
                  return null;
                return (
                  <div
                    className="input-checkbox input-checkbox-small"
                    key={column.id}
                  >
                    <label htmlFor={column.id}>
                      <input
                        id={column.id}
                        name={column.id}
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      <span>
                        {column.id === "selection"
                          ? "Selection"
                          : column.Header}
                      </span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div
          className="react-table-settings-button"
          onClick={() => {
            setMenuOn(!isMenuOn);
          }}
        >
          <i>
            <IconCog outline={false} />
          </i>
        </div>
      </div>
      <Form noValidate>
        <table
          className="react-table react-table-branches-summary spacing-medium"
          columns={visibleColumns.length}
          {...getTableProps()}
        >
          <thead className="sticky grey">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className={column.className}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title={
                        column.className !== "cell-checkbox"
                          ? locale.OrganisationServices.table.sortByLabel(
                              column.Header
                            )
                          : "Select all"
                      }
                    >
                      <div
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sorting-down"
                              : "sorting-up"
                            : "sorting-none"
                        }
                      >
                        {column.id === "selection" ? (
                          <Fragment>{column.render("Header")}</Fragment>
                        ) : (
                          <span>{column.render("Header")}</span>
                        )}
                        {column.disableSortBy ? null : (
                          <i>
                            <IconSort outline={false} />
                          </i>
                        )}
                      </div>
                      <TableHeaderCellSummary column={column} data={data} />
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={row.original.paused ? "highlighted" : ""}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={cell.column.className}
                        {...cell.getCellProps()}
                      >
                        {renderCell(row, cell)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Form>
    </Fragment>
  );
}
