import React, { Component } from "react";
import { connect } from 'react-redux';

import DaySettings from "./DaySettings/DaySettings";
import EditDaySettings from "./EditDaySettings/EditDaySettings";

import type { Action } from 'types/actions';
import type { Calendar } from 'types/calendars';
import * as RolesConstants from 'constants/RolesConstants';
import { checkTimeFormat } from "helpers/checkValues";
import { checkOpenHours } from "helpers/validateData";
import { removeEmptyTimesIntervals } from "helpers/formatData";
import { updateForm } from "actions/form";

import locale from 'service/locale';

type Props = {
  currentRole: Object,
  isEditMode: boolean,
  handleChangeOpeningHours: () => Action,
  calendar: Calendar,
  form: any,
  hasAvailabilityErrors: boolean,
  updateForm: (data: any) => Action,
};

type State = {
  hasErrors: boolean,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  calendar: state.calendar,
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class OpeningHours extends Component<Props, State> {

  state = {
    hasErrors: false,
  };

  checkOpenHours = (openHours: any): boolean => {
    return openHours.some(day => {
      return day.timesSessions.forEach((timeSession, index, array) => {
        const { from, to } = timeSession;
        const formattedStartTime = from.indexOf(":", 3) ? from.slice(0, 5) : from;
        const formattedEndTime = to.indexOf(":", 3) ? to.slice(0, 5) : to;

        return array.length && (!checkTimeFormat(formattedStartTime) || !checkTimeFormat(formattedEndTime));
      });
    });
  };

  handleEditTime = () => {
    const { form } = this.props;
    this.setState({
      hasErrors: this.checkOpenHours(form.openHours)
    });
  };

  handleChangeOpeningHours = (e: Event) => {
    e && e.preventDefault();
    const { handleChangeOpeningHours, updateForm, form, isEditMode } = this.props;
    this.setState({
      hasErrors: false
    });
    isEditMode && updateForm({
      openHours: removeEmptyTimesIntervals(form.openHours)
    });
    handleChangeOpeningHours();
  };

  handleChangeMode = (e: Event) => {
    e && e.preventDefault();
    const { calendar, form } = this.props;
    const keys = Object.keys(form.openHours || {});
    const openHours = form.openHours || calendar.openHours;
    const {
      isNotMultipleByFive,
      isEqualTime,
      isStartTimeLessThanEndTime,
      isIntervalInvalid,
      hasEmptyPeriod,
      hasInvalidTime
    } = checkOpenHours(openHours);

    if ((isNotMultipleByFive || isEqualTime || isStartTimeLessThanEndTime || isIntervalInvalid || hasEmptyPeriod || hasInvalidTime) && keys.length) {
      return;
    } else {
      this.handleChangeOpeningHours();
    }
  };

  render() {
    const { calendar, currentRole, isEditMode, form } = this.props;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;
    const openHours = form.openHours || calendar.openHours;

    return (
      <React.Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>
              {locale.AvailabilitySettings.openingHours}
            </h3>
            {
              !isBranchMember &&
              <button
                className="patient-care-btn-link"
                onClick={this.handleChangeMode}>
                { isEditMode
                  ? locale.Buttons.buttonDone
                  : locale.Buttons.buttonEdit
                }
              </button>
            }
          </div>
          <p>{locale.AvailabilitySettings.hoursDescription}</p>
        </div>

        <div className="patient-care-open-hours">
          {
            openHours
              .map(day => {
                if (isEditMode) {
                  return (
                    <EditDaySettings key={day.day} day={day} handleEditTime={this.handleEditTime} />
                  );
                } else {
                  return (                    
                    <DaySettings key={day.day} day={day} />                                   
                  );
                }
              })
          }
        </div>

        <hr className="patient-care-separator" />
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (OpeningHours);
