import React, { Component, Fragment } from "react";
import { getAppointmentDateAndTime } from "helpers/formatData";
import { connect } from "react-redux";
import locale from 'service/locale';
import {
  FormItem,
  Input,
  Form,
  InputError
} from "@patient-access/ui-kit";
import { updateForm } from "actions/form";

type Props = {
  appointmentDetails?: {
    endTime: string,
    startTime: string,
    serviceName: string,
    branchName: string,
  },
  booking: Object,
  handleDataChange: () => any,
  updateForm: (data: any) => any,
  form: any,
};

const mapStateToProps = (state: any) => ({
  booking: state.book,
  form: state.form,
});

const mapDispatchToProps =(dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data))
})

class CustomAppointmentInfo extends Component<Props> {
  state = {
    book_appointment_title: false
  };

  handleBookFormChange = ({ target: { name, value } }) => {
    const { updateForm, handleDataChange } = this.props;
    handleDataChange();
    updateForm({ [name]: value });
  };

  render() {
    const { booking, appointmentDetails, form } = this.props;
    const keys = Object.keys(form);
    const isEmptyAppointmentTitle = keys.includes("book_appointment_title") && (!form.book_appointment_title 
      || (form.book_appointment_title && form.book_appointment_title.length === 0))

    return (
      <Fragment>
        <div className="patient-care-block">
          <div className="patient-care-heading-row">
            <h3>{locale.BookAppointment.dateTimeLabel}</h3>
          </div>
          <b>
            {
              appointmentDetails
                ? getAppointmentDateAndTime(appointmentDetails.startTime, appointmentDetails.endTime)
                : getAppointmentDateAndTime(booking.startTime, booking.endTime)
            }
          </b>
        </div>
        <hr className="patient-care-separator" />
        <div className="patient-care-block">
          <div className="patient-care-heading-row">
            <h3>{locale.BookAppointment.title}</h3>
          </div>
          <Fragment>
            <Form noValidate>
              <FormItem type="item" error={isEmptyAppointmentTitle ? "error" : null}>
                <Input
                  type="text"
                  id="book_appointment_title"
                  name="book_appointment_title"
                  data-id="book_appointment_title"
                  onChange={this.handleBookFormChange}
                />
                {isEmptyAppointmentTitle && (
                  <InputError message={locale.BookAppointment.titleRequired} />
                )}
              </FormItem>
            </Form>
          </Fragment>
        </div>
        <hr className="patient-care-separator" />
      </Fragment >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAppointmentInfo);
