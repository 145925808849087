import { connect } from "react-redux";

import { logoutUser } from 'actions/login';
import AADLogout from "./AADLogout";

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

const AADLogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AADLogout);

export default AADLogoutContainer;
