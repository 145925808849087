import React, { Component, Fragment } from "react";
import { Overlay, Button } from "@patient-access/ui-kit";
import locale from "service/locale";
import { Form, FormItem, InputLabel, Textarea, InputError, Skeleton } from "@patient-access/ui-kit";
import { updateForm } from 'actions/form';
import Select from "react-select";
import * as AppointmentConstants from 'constants/AppointmentConstants';
import { connect } from 'react-redux';


type Props = {
  form: any,
  updateForm: (date: any) => any,
  handleCancel: () => any,
  handleOk: () => any,
  handleAppointmentRefund: (refundReason: string) => any,
  handleChangeRefundType: (isFullRefund: boolean) => any,
  isLoading: boolean,
  getRefundAmountPending: boolean
};

type State = {
  reason: string,
  refundTypeOptions: any[],
  refundTypeOptionSelected: Object,
}

const mapStateToProps = (state) => ({
  form: state.form,
  branchesList: state.branches.branchesList,
  refundDetails: state.appointmentDetails.refundDetails || "",
  getRefundAmountPending: state.appointmentDetails.getRefundAmountPending
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data))
});


class RefundCustomerOverlay extends Component<Props, State>{

  state = {
    reason: "",
    refundTypeOptions: AppointmentConstants.refundTypeOptions,
    refundTypeOptionSelected: { value: null }
  };

  componentWillUnmount = () => {
    this.setState({
      reason: ""
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.form) {
      const { reason } = nextProps.form;
      this.setState({
        reason: reason || ""
      });
    }
  };

  handleInputChange = (e: Event) => {
    const { updateForm } = this.props;
    const {
      target: { name, value }
    } = e;
    updateForm({
      [name]: value,
    })
  };

  handleChangeRefundType = (RefundTypeOption?: Event) => {
    const { handleChangeRefundType } = this.props;
    const { refundTypeOptions } = this.state;
    const isFullRefund = RefundTypeOption.label === refundTypeOptions[0].label;
    handleChangeRefundType(isFullRefund);
    this.setState({
      refundTypeOptionSelected: RefundTypeOption,
      isFullRefund: isFullRefund
    })
  }

  handleAppointmentRefund = (refundReason: string) => {
    const { handleAppointmentRefund } = this.props;
    const { isFullRefund } = this.state;
    handleAppointmentRefund(isFullRefund, refundReason);
  };

  render() {
    const { form, refundDetails, isLoading } = this.props;
    const refundAmount = refundDetails.refundAmount || "";
    const { handleCancel, getRefundAmountPending } = this.props;
    const { refundTypeOptionSelected, refundTypeOptions, reason } = this.state;
    const isEditedReason = Object.keys(form).includes('reason');
    const isreasondisabled = (refundTypeOptionSelected.label === refundTypeOptions[0].label || refundTypeOptionSelected.label === refundTypeOptions[1].label);
    const isRefundDisabled = !refundTypeOptionSelected.value || !reason.length;

    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="refund-customer-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          {
            <h1>{locale.Modals.RefundCustomerOverlay.header}</h1>
          }
        </div>
        <div className="overlay-confirmation-content">
          <h4> {locale.Appointment.refundAppointment.whichTypeOfRefund}</h4>
          <div className="patient-care-form-block">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="refund_type"
                    message={locale.Appointment.refundAppointment.refundLabel}
                    size="small"
                  />
                </FormItem>
              </Form>
              <Select
                id="refundType-select"
                name="refundType-select"
                options={refundTypeOptions}
                placeholder={locale.Appointment.refundAppointment.refundSelect}
                onChange={this.handleChangeRefundType}
                className="patient-care-select"
                classNamePrefix="patient-care"
              />
            </div>
          </div>

          {getRefundAmountPending ? (
            <Fragment>
              <Skeleton type="base" />
            </Fragment>
          ) :

            isreasondisabled ? (
              <Fragment>
                <h4>
                  {`${locale.Appointment.refundAppointment.refundReasonMessage[0]}
                 ${refundAmount}
                 ${locale.Appointment.refundAppointment.refundReasonMessage[1]}`
                  }
                </h4>
                <div className="patient-care-form-block">
                  <Form>
                    <FormItem type="item" error={(!reason && isEditedReason) ? 'true' : ''}>
                      <InputLabel
                        htmlFor="refund_reason"
                        message={locale.Appointment.refundAppointment.reasonForRefundLabel}
                        size="small"
                      />
                      <Textarea
                        id="refund_reason"
                        name="reason"
                        data-id="refund_reason"
                        value={reason || ""}
                        onChange={this.handleInputChange}
                        maxLength={300}
                        countValue={reason ? reason.length : 0}
                        countMax={300}
                        placeholder={locale.Appointment.refundAppointment.refundReasonPlaceHolder}
                      />
                      {
                        !reason && isEditedReason &&
                        <InputError message={locale.Appointment.refundAppointment.refundReasonError} />
                      }
                    </FormItem>
                  </Form>
                </div>
              </Fragment>
            ) : null}
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="cancel-btn"
            defaultMessage={locale.Modals.RefundCustomerOverlay.buttonCancel}
            onClick={handleCancel}
            isLoading={isLoading}
            data-id="cancel-btn"
            className="patient-care-btn-in-group"
          />
          <Button
            buttonType="secondary"
            messageKey="ok-btn"
            defaultMessage={locale.Modals.RefundCustomerOverlay.buttonConfirm}
            onClick={(e) => { (e && e.preventDefault()); this.handleAppointmentRefund(reason) }}
            isDisabled={isRefundDisabled}
            isLoading={isLoading}
            data-id="ok-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RefundCustomerOverlay);
