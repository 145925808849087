import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, NoResults, Skeleton } from '@patient-access/ui-kit';
import NavigationPrompt from "react-router-navigation-prompt";

import type { Action } from 'types/actions';
import * as RolesConstants from 'constants/RolesConstants';
import { getRoomsList, setRoomsListAction, createRoom, updateBranchRooms, closeBranchErrorDuplicateRoom, updateRoomNameById } from 'actions/rooms';

import FloatingFooterButtons from "components/Share/FloatingFooterButtons/FloatingFooterButtons";
import RoomItem from "./RoomItem/RoomItem";
import CustomTable from "components/Share/CustomTable/CustomTable";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";

import locale from 'service/locale';
import "./styles.scss";

type Props = {
  isActiveAgenda: boolean,
  currentRole: any,
  branchId: string,
  organisationId: string,
  createRoom: (room: Object) => any,
  getRoomsList: (organisationId: string, branchId: string) => any,
  updateBranchRooms: (branchId: string) => any,
  setRoomsList: (roomsList: any[]) => any,
  roomsList: any[],
  roomsListLoadedInitially: any[],
  isRoomsPending: boolean,
  isLoading: boolean,
  isBranchErrorDuplicatedRoom: boolean,
  closeBranchErrorDuplicateRoom: () => Action,
  updateRoomNameById: (id: string | null, text: string) => Action,
};

type State = {
  isEditMode: boolean,
  isDataChanged: boolean,
  isHavingRoomWithEmptyName: boolean
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  isActiveAgenda: state.panel.isActiveAgenda,
  roomsList: state.rooms.roomsList,
  roomsListLoadedInitially: state.rooms.roomsListLoadedInitially,
  isRoomsPending: state.rooms.isRoomsPending,
  isLoading: state.rooms.isUpdatingBranchRooms,
  isBranchErrorDuplicatedRoom: state.panel.isBranchErrorDuplicatedRoom,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getRoomsList: (organisationId: string, branchId: string) => dispatch(getRoomsList(organisationId, branchId)),
  createRoom: (room) => dispatch(createRoom(room)),
  updateBranchRooms: (branchId) => { dispatch(updateBranchRooms(branchId)) },
  setRoomsList: (roomsList) => { dispatch(setRoomsListAction(roomsList)) },
  closeBranchErrorDuplicateRoom: () => dispatch(closeBranchErrorDuplicateRoom()),
  updateRoomNameById: (id: string | null, text: string) => dispatch(updateRoomNameById(id, text))
});

class BranchRooms extends Component<Props, State> {

  state = {
    isEditMode: false,
    isDataChanged: false,
    isHavingRoomWithEmptyName: false
  };

  handleAddNewRoom = () => {
    const { createRoom } = this.props;

    createRoom({ name: "", id: (new Date()).getTime() });

    this.setState({
      isEditMode: true
    });
  };

  handleDataChanged = (id: string | null, text: string) => {
    const { isDataChanged } = this.state;
    const { updateRoomNameById } = this.props;

    updateRoomNameById(id, text);
    if (!isDataChanged) {
      this.setState({
        isDataChanged: true,
        isEditMode: true
      });
    }
  };

  updateBranchRooms = () => {
    const { branchId, updateBranchRooms } = this.props;
    updateBranchRooms(branchId);
    this.setState({
      isDataChanged: false,
      isEditMode: false
    });
  };

  handleChangeMode = () => {
    const { roomsList } = this.props;
    const { isEditMode } = this.state;
    const isHavingRoomWithEmptyName = roomsList.some(room => room.name.length === 0);

    if (isEditMode) {
      this.setState({
        isEditMode: isHavingRoomWithEmptyName,
        isHavingRoomWithEmptyName: isHavingRoomWithEmptyName
      });
    } else {
      this.setState({
        isEditMode: true,
        isHavingRoomWithEmptyName: isHavingRoomWithEmptyName
      });
    }
  };

  componentDidMount() {
    const { getRoomsList, branchId, organisationId } = this.props;
    getRoomsList(organisationId, branchId);
  }

  handleDiscardChanges = () => {
    const { roomsListLoadedInitially, setRoomsList } = this.props;
    setRoomsList(roomsListLoadedInitially);
    this.setState({
      isDataChanged: false,
      isEditMode: false
    });
  };

  handleCloseDuplicateNameInformationOverlay = () => {
    const { closeBranchErrorDuplicateRoom } = this.props;
    closeBranchErrorDuplicateRoom();
    this.setState({
      isDataChanged: true,
      isEditMode: true,
    });
  };

  handleCloseEmptyRoomInformationOverlay = () => {
    this.setState({ isHavingRoomWithEmptyName: false });
  };

  render() {
    const { roomsList, currentRole, isActiveAgenda, isRoomsPending, isLoading, isBranchErrorDuplicatedRoom } = this.props;
    const { isDataChanged, isEditMode, isHavingRoomWithEmptyName } = this.state;
    const isAbleToEdit = currentRole.role !== RolesConstants.BRANCH_MEMBER;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrganizationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;

    let tableData = [];
    if (roomsList && roomsList.length !== 0) {
      tableData =
        roomsList
          .map(({ id, name }) => {
            return [{ id, text: name }];
          });
    }

    // don't show empty rows
    if (!isEditMode) {
      tableData = tableData.filter(([d]) => {
        return d.text && d.text.length > 0;
      })
    }

    return (
      <Fragment>
        {
          roomsList && roomsList.length > 0
            ?
            <div className={`patient-care-user-filters-holder ${
              isSuperAdmin || isOrganizationAdmin ? " with-button" : ""
              }`}
            >
              <div className="patient-care-container">
                <div className="patient-care-align-right">
                  {isAbleToEdit && (
                    <Button
                      buttonType="secondary"
                      messageKey="add-service-btn"
                      defaultMessage={locale.BranchRooms.addRoomButton}
                      onClick={this.handleAddNewRoom}
                      data-id="add-service-btn"
                      className="patient-care-btn-in-group"
                    />
                  )}
                </div>
              </div>
            </div>
            : null
        }

        {
          roomsList && roomsList.length > 0
            ?
            <div className="patient-care-custom-table-container">
              <CustomTable
                header={locale.BranchRooms.colsHeaders}
                data={tableData}
                isEditMode={isEditMode}
                handleChangeMode={this.handleChangeMode}
                handleDataChanged={this.handleDataChanged}
                row={RoomItem}
                customSortList={isEditMode ? (() => { }) : undefined}
                isAbleToEdit={isAbleToEdit}
              />
            </div>
            : isRoomsPending
              ? <Skeleton type="basic" className="rooms-list-skeleton" />
              :
              <div className="patient-care-branches-empty-block">
                <NoResults type="info">
                  <h2>{locale.BranchRooms.emptyListTitle}</h2>
                  <p>{locale.BranchRooms.emptyListDescription}</p>
                  {
                    isAbleToEdit &&
                    <Button
                      buttonType="secondary"
                      messageKey="add-service-btn"
                      defaultMessage={locale.BranchRooms.addFirstRoomButton}
                      onClick={this.handleAddNewRoom}
                      data-id="add-service-btn"
                      className="patient-care-btn-in-group"
                    />
                  }
                </NoResults>
              </div>
        }

        {
          isDataChanged &&
          <FloatingFooterButtons
            handleDiscardChanges={this.handleDiscardChanges}
            proceedFunction={this.updateBranchRooms}
            isActiveAgenda={isActiveAgenda}
            isEditMode={roomsList.length !== 0 && isHavingRoomWithEmptyName}
            isLoading={isLoading}
          />
        }
        {
          isBranchErrorDuplicatedRoom
            ? (
              <InformationalOverlay
                header={locale.Modals.roomDuplicateModal.header}
                content={locale.Modals.roomDuplicateModal.content}
                buttonOk={locale.Modals.roomDuplicateModal.button}
                handleOk={this.handleCloseDuplicateNameInformationOverlay}
              />
            ) : null
        }
        {
          isHavingRoomWithEmptyName
            ? (
              <InformationalOverlay
                header={locale.Modals.roomEmptyNameModal.header}
                content={locale.Modals.roomEmptyNameModal.content}
                buttonOk={locale.Modals.roomEmptyNameModal.button}
                handleOk={this.handleCloseEmptyRoomInformationOverlay}
              />
            ) : null
        }
        <NavigationPrompt when={isDataChanged} >
          {({ onConfirm, onCancel }) => (
            <DiscardChangesOverlay
              handleStay={onCancel}
              handleDiscardChanges={onConfirm}
            />
          )}
        </NavigationPrompt>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchRooms);

