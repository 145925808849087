import React, { Component } from 'react';
import './styles/calendar-filters.scss';

type Props = {
  isHidden: boolean,
  children: any,
};

class CalendarFilters extends Component<Props> {
  render() {
    const { isHidden, children } = this.props;
    return (
      <aside className={`calendar-filters${isHidden ? ' calendar-filters-hidden' : ''}`}>
        {children}
      </aside>
    );
  }
};

export default CalendarFilters;
