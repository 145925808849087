import React, { Fragment } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import IconSort from "svg/IconSort";
import locale from "service/locale";
import "./styles.scss";

export const columns = [
  {
    className: "cell-main",
    Header: locale.OrganisationServices.table.branchesList.headers.branchName,
    accessor: "branchName",
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
];

const IndeterminateCheckbox = React.forwardRef(
  ({ checkServiceSelected, props, branchIds, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="input-checkbox input-checkbox-medium">
        <label>
          <input
            type="checkbox"
            value={branchIds}
            onClick={(e) =>
              onCheckBoxClick(e, props, branchIds, checkServiceSelected)
            }
            ref={resolvedRef}
            {...rest}
          />
          <span>&nbsp;</span>
        </label>
      </div>
    );
  }
);

const onCheckBoxClick = (e, props, branchId, checkServiceSelected) => {
  let userDataBranches = props.userData.serviceBranches;
  if (branchId && typeof branchId == "string") {
    if (e.target.checked) {
      if (
        userDataBranches.length === 0 ||
        userDataBranches.indexOf(branchId) < 0
      )
        userDataBranches.push(branchId);
    } else {
      userDataBranches = userDataBranches.filter((d) => d !== branchId);
    }
  } else if (branchId && typeof branchId == "object" && branchId.length > 0) {
    if (e.target.checked) {
      userDataBranches = userDataBranches.concat(branchId);
      userDataBranches = userDataBranches.filter(
        (item, pos) => userDataBranches.indexOf(item) === pos
      );
    } else {
      userDataBranches = userDataBranches.filter(
        (d) => branchId.indexOf(d) < 0
      );
    }
  }
  props.setUserData({ serviceBranches: userDataBranches });
  props.userData.serviceBranches = userDataBranches;
  checkServiceSelected(
    props &&
      props.userData &&
      props.userData.serviceBranches &&
      props.userData.serviceBranches.length > 0
  );
};

export function TableBranchesList({
  columns,
  data,
  props,
  checkServiceSelected,
}) {
  React.useEffect(() => {
    checkServiceSelected(
      props &&
        props.userData &&
        props.userData.serviceBranches &&
        props.userData.serviceBranches.length > 0
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: "branchName", desc: false }],
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          className: "cell-checkbox",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox
              checkServiceSelected={checkServiceSelected}
              branchIds={data.map((d) => d.branchId)}
              {...getToggleAllRowsSelectedProps()}
              props={props}
            />
          ),
          Cell: ({ row }) => {
            if (
              props.userData.serviceBranches != null &&
              props.userData.serviceBranches.indexOf(row.original.branchId) >=
                0 &&
              (!row.isNewSelection || (row.isNewSelection && !row.isSelected))
            ) {
              row.isSelected = true;
              selectedFlatRows.push(row);
              row.toggleRowSelected(row.id, true);
              selectedRowIds[row.id] = true;
              row.isNewSelection = true;
            }
            return (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                props={props}
                checkServiceSelected={checkServiceSelected}
                branchIds={row.original.branchId}
              />
            );
          },
          disableSortBy: true,
        },
        ...columns,
      ]);
    }
  );

  return (
    <Fragment>
      <table
        className="react-table react-table-branches-list"
        columns={visibleColumns.length}
        {...getTableProps()}
      >
        <thead className="white">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    className={column.className}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={
                      column.className !== "cell-checkbox"
                        ? locale.OrganisationServices.table.sortByLabel(
                            column.Header
                          )
                        : "Select all"
                    }
                  >
                    <div
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sorting-down"
                            : "sorting-up"
                          : "sorting-none"
                      }
                    >
                      {column.id === "selection" ? (
                        <Fragment>{column.render("Header")}</Fragment>
                      ) : (
                        <span>{column.render("Header")}</span>
                      )}
                      {column.disableSortBy ? null : (
                        <i>
                          <IconSort outline={false} />
                        </i>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={cell.column.className}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
}
