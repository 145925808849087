import * as AvailabilityRulesConstants from 'constants/AvailabilityRulesConstants';
import { setOrganisationHeader } from 'actions/router';
import { showSnackbarStatus } from 'actions/snackbar';
import { getToken } from 'helpers/common';
import agent from 'service/agent';

import locale from 'service/locale';

// GET
export const setGetAvailabilityRulesPending = (): Action => ({
  type: AvailabilityRulesConstants.GET_AVAILABILITY_RULES_PENDING,
});

export const setGetAvailabilityRulesSuccess = (): Action => ({
  type: AvailabilityRulesConstants.GET_AVAILABILITY_RULES_SUCCESS,
});

export const setGetAvailabilityRulesError = (): Action => ({
  type: AvailabilityRulesConstants.GET_AVAILABILITY_RULES_ERROR,
});

export const setGetAvailabilityRulesData = (data: any): Action => ({
  type: AvailabilityRulesConstants.SET_AVAILABILITY_RULES_DATA,
  payload: data
});

export const setGetAvailabilityRulesDataClone = (data: any): Action => ({
  type: AvailabilityRulesConstants.SET_AVAILABILITY_RULES_DATA_CLONE,
  payload: data
});

export const getAvailabilityRules = (organisationId: string) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setGetAvailabilityRulesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getAvailabilityRules(organisationId, accessToken)
        .then(data => {
          dispatch(setGetAvailabilityRulesData(data));
          dispatch(setGetAvailabilityRulesDataClone(data));
          dispatch(setGetAvailabilityRulesSuccess());
        })
        .catch(err => {
          console.log('getAvailabilityRules server error', err);
          dispatch(setGetAvailabilityRulesError());
        });
    });
}

// PUT
export const setPutAvailabilityRulesPending = (): Action => ({
  type: AvailabilityRulesConstants.PUT_AVAILABILITY_RULES_PENDING,
});

export const setPutAvailabilityRulesSuccess = (): Action => ({
  type: AvailabilityRulesConstants.PUT_AVAILABILITY_RULES_SUCCESS,
});

export const setPutAvailabilityRulesError = (): Action => ({
  type: AvailabilityRulesConstants.PUT_AVAILABILITY_RULES_ERROR,
});

export const putAvailabilityRules = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { timeOff } = currentState.availabilityRules.data;
  dispatch(setPutAvailabilityRulesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.putAvailabilityRules(organisationId, timeOff, accessToken)
        .then(timeOff => {
          dispatch(setPutAvailabilityRulesSuccess());
          dispatch(getAvailabilityRules(organisationId));
          dispatch(showSnackbarStatus(locale.Snackbar.availabilityRulesUpdated));
        })
        .catch(err => {
          console.log('setPutAvailabilityRules server error', err);
          dispatch(setPutAvailabilityRulesError());
          dispatch(showSnackbarStatus(locale.Snackbar.availabilityRulesError));
        });
    });
};

// UPDATE
export const setTimeOffDataUpdate = (data: any): Action => ({
  type: AvailabilityRulesConstants.SET_TIME_OFF_DATA_UPDATE,
  payload: data
});

// OVERLAY
export const setTimeOffOverlayVisibility = (isOverlayVisible: boolean): Action => ({
  type: AvailabilityRulesConstants.SET_TIME_OFF_OVERLAY_VISIBILITY,
  payload: isOverlayVisible,
});
