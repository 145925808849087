import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { LinkIcon, IconDelete, Button, Notification, Loader } from "@patient-access/ui-kit";

import CalendarDetailsBranchName from "./CalendarDetailsBranchName/CalendarDetailsBranchName";
import CalendarDetailsName from "./CalendarDetailsName/CalendarDetailsName";
import CalendarDetailsServices from "./CalendarDetailsServices/CalendarDetailsServices";
import CalendarDetailsAvailability from "./CalendarDetailsAvailability/CalendarDetailsAvailability";
import CalendarDetailsBlockAvailability from "./CalendarDetailsBlockAvailability/CalendarDetailsBlockAvailability";
import CalendarDetailsBranchClosedDays from "./CalendarDetailsBranchClosedDays/CalendarDetailsBranchClosedDays";
import RemoveCalendarWarningModal from "./RemoveCalendarWarningModal/RemoveCalendarWarningModal";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import ModalHeader from "components/Share/ModalHeader/ModalHeader";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";
import ConfirmationOverlayCommon from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import { formatAvailablePeriodsForEditCalendar } from "helpers/common";
import { cloneDeep } from "lodash";

import {
  getCalendar,
  getAppointmentsForCalendar,
  deleteCalendar,
  updateCalendar,
  closeUpdateAvailabilityConflictOverlay,
  openConfirmDeletionBlockedPeriod,
  closeConfirmDeletionBlockedPeriod,
  deleteBlockedPeriod,
  closeBlockedPeriodWarning,
  clearBlockedPeriods
} from "actions/calendars";
import { closeCalendarDetails } from "actions/panel";
import { setBranchConfirmationStatus } from "actions/branchDetails";
import * as RolesConstants from "constants/RolesConstants";
import * as BranchesConstants from "constants/BranchesConstants";
import { isDuplicateNameError, isEmptyNameError, checkBlockedPeriods } from "helpers/validateData";
import { drawerScrollHandler } from "helpers/common";
import type { Action } from "types/actions";
import type { BlockedPeriod } from "types/calendars";

import locale from "service/locale";
import { checkOpenHours ,checkBranchAvailibility } from "helpers/validateData";
import "./styles.scss";

type Props = {
  isActiveAgenda: boolean,
  form: Object,
  calendarId: string,
  calendar: Object,
  updateCalendar: () => Function,
  getCalendar: (calendarId: string) => Function,
  deleteCalendar: (calendarId: string, organisationId?: string) => Function,
  getAppointmentsForCalendar: (calendarId: string, startDate) => Function,
  handleCloseModal: () => void,
  handleDiscardChanges: () => void,
  currentRole: Object,
  confirmationStatus: string,
  existingCalendarNamesList: any[],
  isUpdateCalendarPending: boolean,
  appointmentsWithConflict: string[],
  isUpdateConflictOverlayOpened: boolean,
  closeUpdateAvailabilityConflictOverlay: () => void,
  openConfirmDeletionBlockedPeriod: () => Action,
  closeConfirmDeletionBlockedPeriod: () => Action,
  isConfirmDeletionBlockedOpen: boolean,
  deleteBlockedPeriod: (blockedPeriodId: number | null) => Action,
  calendarDetails: any,
  setBranchConfirmationStatus: (status: string) => Action,
  closeCalendarDetails: () => Action,
  isTryingBlockToday: boolean,
  closeBlockedPeriodWarning: () => Action,
  newBlockedPeriods: BlockedPeriod[],
  clearBlockedPeriods: () => Action,
  branchId: string,
  organisationId: string,
  isOrgLevelCalendar: boolean,
  isGetPending: boolean,
};

type State = {
  isConfirmDialogOpened: boolean,
  isBookingWarning: boolean,
  isEditingName: boolean,
  isEditingAvailability: boolean,
  isEditingBlock: boolean,
  isEditingServices: boolean,
  isDiscardChangesOpened: boolean,
  isScrolledStart: boolean,
  isScrolledEnd: boolean,
  blockedPeriodId: number | null,
  needRedirect: boolean
};

const mapStateToProps = state => ({
  isActiveAgenda: state.panel.isActiveAgenda,
  calendar: state.calendarDetails,
  closedDays: state.calendar.closedDays,
  form: state.form,
  currentRole: state.roles.profileCurrentRole,
  confirmationStatus: state.branchDetails.confirmationStatus,
  isUpdateCalendarPending: state.calendar.isUpdateCalendarPending,
  appointmentsWithConflict: state.calendar.appointmentsWithConflict,
  isUpdateConflictOverlayOpened: state.calendar.isUpdateConflictOverlayOpened,
  isConfirmDeletionBlockedOpen: state.calendar.isConfirmDeletionBlockedOpen,
  calendarDetails: state.calendarDetails,
  isTryingBlockToday: state.calendar.isTryingBlockToday,
  newBlockedPeriods: state.calendar.newBlockedPeriods,
  isGetPending: state.calendar.isGetPending,
});

const mapDispatchToProps = dispatch => ({
  getCalendar: calendarId => dispatch(getCalendar(calendarId)),
  getAppointmentsForCalendar: (calendarId, startDate) => dispatch(getAppointmentsForCalendar(calendarId, startDate)),
  deleteCalendar: (calendarId: string, organisationId?: string) => dispatch(deleteCalendar(calendarId, organisationId)),
  updateCalendar: () => dispatch(updateCalendar()),
  closeUpdateAvailabilityConflictOverlay: () => dispatch(closeUpdateAvailabilityConflictOverlay()),
  openConfirmDeletionBlockedPeriod: () => dispatch(openConfirmDeletionBlockedPeriod()),
  closeConfirmDeletionBlockedPeriod: () => dispatch(closeConfirmDeletionBlockedPeriod()),
  deleteBlockedPeriod: blockedPeriodId => dispatch(deleteBlockedPeriod(blockedPeriodId)),
  setBranchConfirmationStatus: (status: string) => dispatch(setBranchConfirmationStatus(status)),
  closeCalendarDetails: () => dispatch(closeCalendarDetails()),
  closeBlockedPeriodWarning: () => dispatch(closeBlockedPeriodWarning()),
  clearBlockedPeriods: () => dispatch(clearBlockedPeriods()),  
});

class CalendarDetailsModal extends Component<Props, State> {
  state = {
    isConfirmDialogOpened: false,
    isBookingWarning: false,
    isEditingName: false,
    isEditingAvailability: false,
    isEditingBlock: false,
    isEditingServices: false,
    isDiscardChangesOpened: false,
    isScrolledStart: false,
    isScrolledEnd: false,
    blockedPeriodId: null,
    needRedirect: false
  };

  componentDidMount = () => {
    drawerScrollHandler.bind(this)();
    const { calendarId, getCalendar } = this.props;
    getCalendar(calendarId);   
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { confirmationStatus, isUpdateCalendarPending } = nextProps;    
    if (confirmationStatus.includes(BranchesConstants.PASSWORD_WARNING_STATUSES.COMMON)) {
      this.setState({ isBookingWarning: true });
    }

    if (!isUpdateCalendarPending && this.props.isUpdateCalendarPending) {
      this.handleCloseBookingWarning();
    }
  };

  componentWillUnmount = () => {
    const { clearBlockedPeriods } = this.props;
    clearBlockedPeriods();
  };

  handleStay = () => {
    this.setState({ isDiscardChangesOpened: false });
  };

  handleOpenDiscardDialog = (e: any) => {
    e && e.preventDefault();
    this.setState({ isDiscardChangesOpened: true });
  };

  handleDiscardChanges = () => {
    const { needRedirect } = this.state;
    const { handleDiscardChanges } = this.props;
    this.setState({ isDiscardChangesOpened: false }, () => handleDiscardChanges(needRedirect));
  };

  handleUpdateCalendar = () => {
    const { updateCalendar } = this.props;
    updateCalendar();
  };

  handleCheckFutureAppointments = (e: any) => {
    e && e.preventDefault();
    const { calendarId, calendar, getAppointmentsForCalendar } = this.props;
    getAppointmentsForCalendar(calendarId, (calendar && calendar.start));
  };

  handleChangeMode = (type, status) => {
    this.setState({ [type]: status });
  };

  getAppointmentsWithConflictMessage = () => {
    let { appointmentsWithConflict } = this.props;
    const bodyMessageConstructor = locale.Modals.updateCalendarAvailabilityConflictOverlay.body;
    return (
      <div>
        {`
        ${bodyMessageConstructor[0]} ${appointmentsWithConflict.length}
        ${appointmentsWithConflict.length > 1 ? bodyMessageConstructor[2] : bodyMessageConstructor[1]}
        ${bodyMessageConstructor[3]}
        `}
      </div>
    );
  };

  handleUpdateAvailabilityConflictBack = () => {
    const { closeUpdateAvailabilityConflictOverlay } = this.props;
    closeUpdateAvailabilityConflictOverlay();
  };

  handleAddTodayAsBlockedPeriodBack = () => {
    const { closeBlockedPeriodWarning } = this.props;
    closeBlockedPeriodWarning();
  };

  handleOpenBlockedPeriodConfirmation = (blockedPeriodId: number) => {
    const { openConfirmDeletionBlockedPeriod } = this.props;

    this.setState({
      blockedPeriodId
    }, () => {
      openConfirmDeletionBlockedPeriod();
    });
  };

  handleDeleteBlockedPeriod = () => {
    const { deleteBlockedPeriod, closeConfirmDeletionBlockedPeriod } = this.props;
    const { blockedPeriodId } = this.state;
    deleteBlockedPeriod(blockedPeriodId);
    closeConfirmDeletionBlockedPeriod();
  };

  handleDeleteCalendar = (e: any) => {
    const { calendarId, deleteCalendar, isOrgLevelCalendar, organisationId } = this.props;
    e && e.preventDefault();
    isOrgLevelCalendar ? deleteCalendar(calendarId, organisationId) : deleteCalendar(calendarId);
  };

  handleCloseBookingWarning = (e: any) => {
    const { setBranchConfirmationStatus } = this.props;
    e && e.preventDefault();
    this.setState({
      isBookingWarning: false
    }, () => {
      setBranchConfirmationStatus("");
    });
  };

  handleCloseModal = (e: any) => {
    e && e.preventDefault();
    const { closeConfirmDeletionBlockedPeriod, handleCloseModal } = this.props;
    closeConfirmDeletionBlockedPeriod();
    handleCloseModal();
  };

  handleRedirect = () => {
    const { form, handleCloseModal } = this.props;
    const isFormEdited = Object.keys(form).length > 2;

    this.setState({
      needRedirect: true
    }, () => {
      isFormEdited ? this.handleOpenDiscardDialog() : handleCloseModal(true);
    });
  };

  render() {
    const {
      isActiveAgenda,
      handleCloseModal,
      form,
      calendar,
      currentRole,
      existingCalendarNamesList,
      isUpdateCalendarPending,
      isUpdateConflictOverlayOpened,
      isConfirmDeletionBlockedOpen,
      closeConfirmDeletionBlockedPeriod,      
      confirmationStatus,
      isTryingBlockToday,
      organisationId,
      branchId,
      closedDays,
      isOrgLevelCalendar,
      isGetPending
    } = this.props;
    const {
      isDiscardChangesOpened,
      isBookingWarning,
      isScrolledStart,
      isScrolledEnd
    } = this.state;
    const keys = Object.keys(form);
    const isCalendarEdited = keys.length > 2;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;
    const openHours = form && form.openHours ;
    const { isNotMultipleByFive, isEqualTime, isStartTimeLessThanEndTime, isIntervalInvalid, hasInvalidTime, hasEmptyPeriodWithoutZeros } = isCalendarEdited && checkOpenHours(openHours);    
    const isInvalidOpenHours = isNotMultipleByFive || isEqualTime || isStartTimeLessThanEndTime || isIntervalInvalid || hasInvalidTime || hasEmptyPeriodWithoutZeros;
    const { incorrectBlockedPeriods } = keys.includes("blockedPeriods") &&  checkBlockedPeriods(form.blockedPeriods);
    const branchAvailablePeriod = calendar && calendar.availablePeriods ? cloneDeep(formatAvailablePeriodsForEditCalendar(calendar.availablePeriods)) : [];
    const branchOpenHours = form && form.branchOpenHours;
    const { isCalendarIntervalInvalidWithBranchAvailability } = checkBranchAvailibility(branchOpenHours, openHours);

    const renderNotification = () => {
      if (calendar && calendar.isProcessing) {
        return (
          <div className="patient-care-block">
            <Notification type="info" messageKey="some-key" defaultMessage={locale.BranchCalendars.calendarDetailsNotification} />
          </div>
        );
      }
      return null;
    }

    const renderLoader = () => {
      if (isGetPending) {
        return (
          <div className="calendar-details-loading">
            <Loader type="inline" size="small" />
          </div>
        );
      }
      return null;
    }

    return (
      <Fragment>
        <div className={`patient-care-modal-full-height-${isActiveAgenda ? "with-agenda-holder" : "holder"}`} onScroll={drawerScrollHandler.bind(this)}>
          <div className="patient-care-modal-full-height">
            <ModalHeader
              ref="modalHeader"
              title={locale.BranchCalendars.calendarDetails}
              handleCloseModal={this.handleCloseModal}
              handleDiscardChanges={this.handleOpenDiscardDialog}
              customClassName={isScrolledStart ? "has-shadow" : ""}
            />
            <div className="patient-care-modal-content" ref="modalContent">
              <div id="patient-care-scrolling-content" ref="modalContentScroll">
                {renderNotification()}
                {isOrgLevelCalendar && (
                  <CalendarDetailsBranchName branchId={branchId} />
                )}
                <CalendarDetailsName
                  name={calendar.name || ""}
                  handleChangeMode={this.handleChangeMode}
                  isDuplicateNameError={isDuplicateNameError(
                    form,
                    existingCalendarNamesList.filter(
                      name => name !== calendar.name
                    )
                  )}
                  isEmptyNameError={isEmptyNameError(form)}
                  isBranchMember={isBranchMember}
                  isProcessing={calendar.isProcessing}
                />
                <CalendarDetailsServices
                  services={calendar.services || []}
                  handleChangeMode={this.handleChangeMode}
                  isBranchMember={isBranchMember}
                  isProcessing={calendar.isProcessing}
                />
                <CalendarDetailsAvailability
                  openHours ={ branchAvailablePeriod }
                  start={calendar.start ? calendar.start.substr(0, 10) : null}
                  end={calendar.end ? calendar.end.substr(0, 10) : null}
                  handleChangeMode={this.handleChangeMode}
                  isBranchMember={isBranchMember}
                  isProcessing={calendar.isProcessing}
                />
                { closedDays && closedDays.length
                  ? (
                    <CalendarDetailsBranchClosedDays
                      organisationId={organisationId}
                      branchId={branchId}
                      handleRedirect={this.handleRedirect}
                    />
                  )
                  : null
                }
                <CalendarDetailsBlockAvailability
                  blockedPeriods={calendar.blockedPeriods || []}
                  handleChangeMode={this.handleChangeMode}
                  isBranchMember={isBranchMember}
                  handleOpenBlockedPeriodConfirmation={this.handleOpenBlockedPeriodConfirmation}
                  isProcessing={calendar.isProcessing}
                />
              </div>
            </div>
            <div className={`patient-care-modal-footer ${isScrolledEnd ? "has-shadow" : ""}`} ref="modalAddOrDiscardButtons">
              <div className="patient-care-modal-buttons-row">
                {!isBranchMember && (
                  <div className="patient-care-btn-delete">
                    <LinkIcon
                      to="#delete"
                      icon={<IconDelete outline />}
                      size="medium"
                      accessibilitySpan={
                        locale.UserDetails.userDetailsButtonDelete
                      }
                      onClick={this.handleCheckFutureAppointments}
                    />
                  </div>
                )}
                <div className="patient-care-buttons-group">
                  {isCalendarEdited ? (
                    <Fragment>
                      <Button
                        buttonType="blueline"
                        messageKey="discard-btn"
                        defaultMessage={
                          locale.UserDetails.userDetailsButtonDiscard
                        }
                        onClick={this.handleOpenDiscardDialog}
                        data-id="discard-btn"
                        className="patient-care-btn-in-group"
                        isLoading={isUpdateCalendarPending}
                      />
                      <Button
                        buttonType="secondary"
                        messageKey="save-changes-btn"
                        defaultMessage={
                          locale.UserDetails.userDetailsButtonSave
                        }
                        onClick={this.handleUpdateCalendar}
                        data-id="save-changes-btn"
                        className="patient-care-btn-in-group"
                        isDisabled={isInvalidOpenHours || incorrectBlockedPeriods || isCalendarIntervalInvalidWithBranchAvailability}
                        isLoading={isUpdateCalendarPending}
                        tabIndex={isInvalidOpenHours || incorrectBlockedPeriods || isCalendarIntervalInvalidWithBranchAvailability ? -1 : 0}
                      />
                    </Fragment>
                  ) : (
                    <Button
                      buttonType="secondary"
                      messageKey="close-btn"
                      defaultMessage={locale.UserDetails.userDetailsButtonClose}
                      onClick={() => handleCloseModal()}
                      data-id="close-user-details-btn"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {renderLoader()}
          <span className="patient-care-modal-overlay" />
        </div>
        {isBookingWarning && (
          <RemoveCalendarWarningModal
            handleCloseModal={this.handleCloseBookingWarning}
            confirmationStatus={confirmationStatus}
            handleConfirmDeleteCalendar={this.handleDeleteCalendar}
            isLoading={isUpdateCalendarPending}
          />
        )}
        {isDiscardChangesOpened && (
          <DiscardChangesOverlay
            handleDiscardChanges={this.handleDiscardChanges}
            handleStay={this.handleStay}
          />
        )}
        {
          isUpdateConflictOverlayOpened &&
          <InformationalOverlay
            {...locale.Modals.updateAvailabilityConflictOverlay}
            content={this.getAppointmentsWithConflictMessage()}
            handleOk={this.handleUpdateAvailabilityConflictBack}
          />
        }
        {
          isTryingBlockToday &&
          <InformationalOverlay
            {...locale.Modals.addTodayAsBlockedPeriod}
            handleOk={this.handleAddTodayAsBlockedPeriodBack}
          />
        }
        {
          isConfirmDeletionBlockedOpen && (
            <ConfirmationOverlayCommon
              {...locale.Modals.confirmationDeleteBlockedOverlay}
              isShowConfirmButton={true}
              handleConfirm={this.handleDeleteBlockedPeriod}
              handleCancel={closeConfirmDeletionBlockedPeriod}
            />
          )
        }
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetailsModal);
