import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  InputLabel,
  Input,
  Checkbox,
  LinkIcon,
  IconClose
} from "@patient-access/ui-kit";
import isEqual from "lodash.isequal";
import moment from "moment";
import InputMask from 'react-input-mask';

import * as CalendarsConstants from 'constants/CalendarsConstants';
import locale from "service/locale";
import { updateForm } from "actions/form";
import { beforeMaskedValueChange } from "helpers/formatData";
import { checkTimeFormat } from "helpers/checkValues";
import type { Action } from "types/actions";

const today = new Date().toISOString().substr(0, 10);

const inputNames = {
  startDate: "startDate",
  endDate: "endDate",
  startTime: "startTime",
  endTime: "endTime",
};

type Props = {
  period: Object,
  updateForm: (data: any) => Action,
  handleDeletePeriod: (id: number) => any,
  form: any,
  isEditMode: boolean
};

type State = {
  period: Object
};

const mapStateToProps = state => ({
  form: state.form
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: data => dispatch(updateForm(data))
});

class BlockAvailabilityItem extends Component<Props, State> {
  state = {
    period: this.props.period || {},
    [inputNames.startDate]: false,
    [inputNames.endDate]: false,
    [inputNames.startTime]: false,
    [inputNames.endTime]: false,
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (!isEqual(this.props.period, nextProps.period))
      this.setState({
        period: {
          ...nextProps.period,
          [inputNames.startDate]: nextProps.period.startDate || today,
          [inputNames.endDate]: nextProps.period.endDate || today
        },
      });
  };

  componentDidMount = () => {
    const { period } = this.props;
    this.setState({
      period: {
        ...period,
        [inputNames.startDate]: period.startDate || today,
        [inputNames.endDate]: period.endDate || today
      },
    });
  };

  handleTimeChange = ({ target: { name, value } }) => {
    const { updateForm, form, period } = this.props;
    const blockedPeriods = form.blockedPeriods || {};
    const otherTime = name === "startTime" ? "endTime" : "startTime";
    blockedPeriods[period.id] = {
      ...blockedPeriods[period.id],
      [name]: value
    };
    const keys = Object.keys(blockedPeriods[period.id]);
    if (!keys.includes(otherTime)) {
      blockedPeriods[period.id] = {
        ...blockedPeriods[period.id],
        [otherTime]: period[otherTime].slice(0,5)
      };
    }
    updateForm({ blockedPeriods });
  };

  handleDateChange = (e: any) => {
    const { updateForm, form } = this.props;
    const { period } = this.state;
    const {
      target: { name, value }
    } = e;
    const blockedPeriods = form.blockedPeriods || {};

    blockedPeriods[period.id] = {
      ...blockedPeriods[period.id],
      [name]: value
    };

    if (name === inputNames.startDate && (!period.endDate || moment(value).isAfter(period.endDate))) {
      blockedPeriods[period.id].endDate = value;
      this.setState({
        period: {
          ...period,
          [inputNames.startDate]: value,
          [inputNames.endDate]: value
        }
      });
    } else if (name !== inputNames.startDate && !period.startDate) {
      blockedPeriods[period.id].startDate = today;
      this.setState({
        period: {
          ...period,
          [name]: value,
          [inputNames.startDate]: today,
        }
      });
    } else {
      this.setState({
        period: {
          ...period,
          [name]: value
        }
      });
    }

    updateForm({ blockedPeriods });
  };

  handleAllDayClick = () => {
    const { period } = this.state;
    const { updateForm, form } = this.props;
    const blockedPeriods = form.blockedPeriods || {};
    blockedPeriods[period.id] = {
      ...blockedPeriods[period.id],
      allDay: !period.isAllDay,
      [inputNames.startDate]: period.startDate,
      [inputNames.endDate]: period.startDate,
      [inputNames.startTime]: "",
      [inputNames.endTime]: "",
    };

    updateForm({ blockedPeriods });
    this.setState({
      period: {
        ...period,
        isAllDay: !period.isAllDay,
        [inputNames.startDate]: period.startDate,
        [inputNames.endDate]: period.startDate,
        [inputNames.startTime]: "",
        [inputNames.endTime]: "",
      }
    });
  };

  handleDeletePeriod = (e?: Event) => {
    e && e.preventDefault();
    const { handleDeletePeriod, period, updateForm } = this.props;
    handleDeletePeriod(period.id);
    updateForm({ edited: true });
  };

  checkDateInput = (e: Event) => {
    const {
      target: { name, value }
    } = e;

    this.setState({
      [name]: value.length === 0
    }, () => {

    });
  };

  render() {
    const { period } = this.state;

    const { isEditMode, form } = this.props;
    const { blockedPeriods } = form;
    const isPeriodEdited = blockedPeriods && blockedPeriods[period.id];
    const startTime = isPeriodEdited
      ? isPeriodEdited.startTime
      : ((period && period.startTime) || "").slice(0, -3);
    const endTime = isPeriodEdited
      ? isPeriodEdited.endTime
      : ((period && period.endTime) || "").slice(0, -3) || "";

    const inputStartDate = period.startDate || today;
    const inputEndDate = period.endDate || today;
    const isStartTimeInvalid = !checkTimeFormat(startTime || "");
    const isEndTimeInvalid = !checkTimeFormat(endTime || "");

    return (
      <div className="patient-care-blocked-times-wrap">
        <div className="patient-care-modal-edit-row">
          <div className="patient-care-col-11">
            <div className="patient-care-block-row">
              <div className="patient-care-col-5">
                <Form noValidate>
                  <FormItem type="item" error={inputStartDate ? "" : "true"}>
                    <InputLabel
                      htmlFor="startDate"
                      message={locale.AddNewCalendar.labelStartDate}
                      size="small"
                    />
                    <Input
                      id="startDate"
                      name={inputNames.startDate}
                      onChange={this.handleDateChange}
                      value={inputStartDate}
                      data-id="startDate"
                      type="date"
                      min={today}
                      disabled={!isEditMode}
                      onBlur={this.checkDateInput}
                    />
                  </FormItem>
                </Form>
              </div>
              {!period.isAllDay && (
                <div className="patient-care-col-2">
                  <Form>
                    <FormItem type="item" error={isStartTimeInvalid ? "true" : ""}>
                      <InputLabel
                        htmlFor="block_startTime"
                        message={locale.AddNewCalendar.labelTime}
                        size="small"
                      />
                      <InputMask
                        mask={`m${(startTime || "")[0] === "2" ? "M" : "9"}:sS`}
                        maskChar=" "
                        formatChars={CalendarsConstants.timeMaskOptions}
                        onChange={this.handleTimeChange}
                        value={startTime}
                        alwaysShowMask={false}
                        beforeMaskedValueChange={beforeMaskedValueChange}
                        disabled={!isEditMode}
                        onBlur={this.checkDateInput}
                      >
                        { () => <Input
                          id="block_startTime"
                          name={inputNames.startTime}
                          data-id="block_startTime"
                          placeholder={locale.AddNewCalendar.placeholderTime}
                          type="time"
                        />}
                      </InputMask>
                    </FormItem>
                  </Form>
                </div>
              )}
              <div className="patient-care-col-3">
                <Form noValidate className="patient-care-checkbox-in-row">
                  <FormItem type="item">
                    <Checkbox
                      id={`block_allDay_startDate_${period.id}`}
                      name={`block_allDay_startDate_${period.id}`}
                      value={period.id}
                      checked={period.isAllDay}
                      onChange={this.handleAllDayClick}
                      label={locale.AddNewCalendar.labelAllDay}
                      data-id={`block_allDay_startDate_${period.id}`}
                      disabled={!isEditMode}
                    />
                  </FormItem>
                </Form>
              </div>
            </div>
            <div className="patient-care-block-row">
              <div className="patient-care-col-5">
                <Form noValidate>
                  <FormItem type="item" error={inputEndDate ? "" : "true"}>
                    <InputLabel
                      htmlFor="endDate"
                      message={locale.AddNewCalendar.labelEndDate}
                      size="small"
                    />
                    <Input
                      id="endDate"
                      name={inputNames.endDate}
                      onChange={this.handleDateChange}
                      value={inputEndDate}
                      data-id="endDate"
                      type="date"
                      min={inputStartDate}
                      disabled={!isEditMode}
                      onBlur={this.checkDateInput}
                    />
                  </FormItem>
                </Form>
              </div>
              {!period.isAllDay && (
                <div className="patient-care-col-2">
                  <Form noValidate>
                    <FormItem type="item" error={isEndTimeInvalid ? "true" : ""}>
                      <InputLabel
                        htmlFor="block_endTime"
                        message={locale.AddNewCalendar.labelTime}
                        size="small"
                      />
                      <InputMask
                        mask={`m${(endTime || "")[0] === "2" ? "M" : "9"}:sS`}
                        maskChar=" "
                        formatChars={CalendarsConstants.timeMaskOptions}
                        onChange={this.handleTimeChange}
                        value={endTime}
                        alwaysShowMask={false}
                        beforeMaskedValueChange={beforeMaskedValueChange}
                        disabled={!isEditMode}
                        onBlur={this.checkDateInput}
                      >
                        { () => <Input
                          id="block_endTime"
                          name={inputNames.endTime}
                          data-id="block_endTime"
                          placeholder={locale.AddNewCalendar.placeholderTime}
                          type="time"
                        />}
                      </InputMask>
                    </FormItem>
                  </Form>
                </div>
              )}
            </div>
          </div>
          {isEditMode && (
            <div className="patient-care-btn-delete-row" onClick={this.handleDeletePeriod}>
              <LinkIcon
                to="#"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.UserDetails.deleteRoleIcon}
                onClick={this.handleDeletePeriod}
              />
            </div>
          )}
        </div>
        <hr className="patient-care-separator" />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockAvailabilityItem);
