import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';
import locale from "service/locale";
import { setOrganisationHeader } from 'actions/router';
import { showSnackbarStatus } from 'actions/snackbar';
import { getToken, splitArraybySize } from 'helpers/common';
import agent from 'service/agent';
import { Store } from 'store/index';
import { ai } from "service/telemetry";
import { TASK_ACTION_TYPE } from 'constants/TaskManagerConstants';

// UI
export const setUI = (value: string): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_UI,
  payload: value,
});

// USER
export const setUserSearch = (value: string): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_USER_SEARCH,
  payload: value,
});

export const setUserStep = (value: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_USER_STEP,
  payload: value,
});

export const setUserData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_USER_DATA,
  payload: data,
});

export const resetUserData = (): Action => ({
  type: OrganisationServicesConstants.RESET_ORG_SER_USER_DATA,
});

//SET USER EDIT DATA
export const setUserEditData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_USER_EDIT_DATA,
  payload: data,
});

export const resetUserEditData = (): Action => ({
  type: OrganisationServicesConstants.RESET_ORG_SER_USER_EDIT_DATA,
});

// SERVICES_SUMMARY
export const setServicesSummaryPending = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICES_SUMMARY_PENDING,
});

export const setServicesSummarySuccess = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICES_SUMMARY_SUCCESS,
});

export const setServicesSummaryError = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICES_SUMMARY_ERROR,
});

export const setServicesSummaryData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_SERVICES_SUMMARY_DATA,
  payload: data,
});

export const getServicesSummary = (organisationId: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setServicesSummaryPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.OrganisationServices.getServicesSummary(
        accessToken,
        organisationId,
      )
      .then(data => {
        dispatch(setServicesSummaryData(data));
        dispatch(setServicesSummarySuccess());
      })
      .catch(err => {
        dispatch(setServicesSummaryError());
        console.log("getServicesSummary server error or branch was not found", err);
      });
    });
};

// SERVICES_LIST
export const setServicesListPending = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICES_LIST_PENDING,
});

export const setServicesListSuccess = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICES_LIST_SUCCESS,
});

export const setServicesListError = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICES_LIST_ERROR,
});

export const setServicesListData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_SERVICES_LIST_DATA,
  payload: data,
});

export const getServicesList = (organisationId: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setServicesListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.OrganisationServices.getServicesList(
        accessToken,
        organisationId,
      )
      .then(data => {
        dispatch(setServicesListData(data));
        dispatch(setServicesListSuccess());
      })
      .catch(err => {
        dispatch(setServicesListError());
        console.log("getServicesList server error or branch was not found", err);
      });
    });
};

// BRANCHES_SUMMARY
export const setBranchesSummaryPending = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_BRANCHES_SUMMARY_PENDING,
});

export const setBranchesSummarySuccess = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_BRANCHES_SUMMARY_SUCCESS,
});

export const setBranchesSummaryError = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_BRANCHES_SUMMARY_ERROR,
});

export const setBranchesSummaryData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_BRANCHES_SUMMARY_DATA,
  payload: data,
});

export const getBranchesSummary = (organisationId: string, serviceId: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchesSummaryPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.OrganisationServices.getBranchesSummary(
        accessToken,
        organisationId,
        serviceId,
      )
      .then(data => {
        dispatch(setBranchesSummaryData(data));
        dispatch(setBranchesSummarySuccess());
      })
      .catch(err => {
        dispatch(setBranchesSummaryError());
        console.log("getBranchesSummary server error or branch was not found", err);
      });
    });
};

// BRANCHES_LIST
export const setBranchesListPending = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_BRANCHES_LIST_PENDING,
});

export const setBranchesListSuccess = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_BRANCHES_LIST_SUCCESS,
});

export const setBranchesListError = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_BRANCHES_LIST_ERROR,
});

export const setBranchesListData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_BRANCHES_LIST_DATA,
  payload: data,
});

export const getBranchesList = (organisationId: string, serviceId: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchesListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.OrganisationServices.getBranchesList(
        accessToken,
        organisationId,
        serviceId,
      )
      .then(data => {
        dispatch(setBranchesListData(data));
        dispatch(setBranchesListSuccess());
      })
      .catch(err => {
        dispatch(setBranchesListError());
        console.log("getBranchesList server error or branch was not found", err);
      });
    });
};

const setServiceDetailsPending = (): Action => ({
  type: OrganisationServicesConstants.SET_SERVICE_DETAILS_PENDING,
});

const setServiceDetailsSuccess = (): Action => ({
  type: OrganisationServicesConstants.SET_SERVICE_DETAILS_SUCCESS,
});

const setServiceDetailsError = (): Action => ({
  type: OrganisationServicesConstants.SET_SERVICE_DETAILS_ERROR,
});

export const setServiceDetails = (organisationId: string, isAddBranches: boolean) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const userProfile = currentState.profile;
  const userRole = currentState.roles.profileCurrentRole;
  const userData = currentState.organisationServices.user.data;

  const {
    serviceId,
    serviceName,
    serviceModes,
    serviceIsActive,
    servicePrice,
    serviceIsVatIncluded,
    serviceDuration,
    serviceCutOff,
    serviceBranches,
    serviceIsShowPrice,
    bookingUrl
  } = userData;

  const { id, displayName } = userProfile;
  const { role } = userRole;

  const serviceDetails = {
    userId: id,
    userName: displayName,
    roleName: role,
    organisationId: organisationId,
    actionName: TASK_ACTION_TYPE.ADDSERVICEBULK,
    request: {
      id: serviceId,
      name: serviceName,
      duration: serviceDuration,
      price: servicePrice,
      vatIncluded: serviceIsVatIncluded,
      lastMinute: serviceCutOff,
      paused: !serviceIsActive,
      modes: serviceModes,
      branchIds: serviceBranches,
      showPrice: serviceIsShowPrice,
      bookingPoint: {
        url: bookingUrl.value
      }
    },
  };

  dispatch(setServiceDetailsPending());
  getToken(dispatch)
    .then(accessToken => {
      SaveTelemetry(organisationId, serviceDetails, 'Add');
      agent.OrganisationServices.addTaskServicesDetails(accessToken, serviceDetails)
        .then((res) => {
          dispatch(resetUserData());
          dispatch(setServiceDetailsSuccess());
          dispatch(setUserStep(1));
          dispatch(setUI({ isOverlayConfirmationVisible: false, isSidebarAddVisible: false }));
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksSuccess));
          if (isAddBranches) {
            dispatch(getBranchesSummary(organisationId, serviceId));
          } else {
            dispatch(getServicesSummary(organisationId));
          }
        })
        .catch(err => {
          console.log('Add Service details to task manager server error ', err.response && err.response.body);
          dispatch(resetUserData());
          dispatch(setServiceDetailsError());
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksError));
        })
    });
};

export const setBatchServiceDetails = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const userData = currentState.organisationServices.user.data;
  const apibulkUpload = (window.__ENV && window.__ENV.bulkUpload) || {};
  let {
    apiBatchSize
  } = apibulkUpload;

  const {
    serviceBranches
  } = userData;

  const serviceDetails = {
    branchIds: serviceBranches
  };

  dispatch(setServiceDetailsPending());
  getToken(dispatch)
    .then(accessToken => {
      let batchData = splitArraybySize(serviceDetails.branchIds, parseInt(apiBatchSize));
      setBatchProcessForAdd(batchData, accessToken, organisationId);
    });
};

async function setBatchProcessForAdd(branchData, accessToken, organisationId) {
  const currentState = Store.getState();
  const userData = currentState.organisationServices.user.data;
  const orgDetails = currentState.organisationDetails.booking;
  const {
    serviceId,
    serviceBranches,
    serviceModes,
    serviceIsActive,
    servicePrice,
    serviceIsVatIncluded,
    serviceDuration,
    serviceCutOff,
    serviceIsShowPrice,
    bookingUrl
  } = userData;

  const promises = [];
  const serviceDetails = {
    id: serviceId,
    branchIds: serviceBranches,
    duration: serviceDuration,
    price: servicePrice,
    vatIncluded: serviceIsVatIncluded,
    lastMinute: serviceCutOff,
    paused: !serviceIsActive,
    modes: serviceModes,
    showPrice: serviceIsShowPrice,
    bookingPoint: {
      url: bookingUrl.isEdited ? bookingUrl.value : (orgDetails.point && orgDetails.point.url)
    }
  };
  SaveTelemetry(organisationId, serviceDetails, 'BatchAdd');

  for (let j = 0; j < branchData.length; j++) {
    let bulkServiceDetails = {
      id: serviceId,
      duration: serviceDuration,
      price: servicePrice,
      vatIncluded: serviceIsVatIncluded,
      lastMinute: serviceCutOff,
      paused: !serviceIsActive,
      modes: serviceModes,
      branchIds: branchData[j],
      showPrice: serviceIsShowPrice,
      bookingPoint: {
        url: bookingUrl.isEdited ? bookingUrl.value : (orgDetails.point && orgDetails.point.url)
      }
    };

    promises.push(await sendAddRequests(bulkServiceDetails, accessToken, organisationId));
    const refState = Store.getState();
    const isBulkUpdateCancel = refState.organisationServices.ui.isBulkUpdateCancel;
    if (isBulkUpdateCancel) {
      break;
    }
  }

  Promise.allSettled(promises)
    .then((responses) => {
      const { dispatch } = Store;
      const newState = Store.getState();
      const apiResponse = newState.organisationServices.ui.responseFromApi;
      const isBulkUpdateCancel = newState.organisationServices.ui.isBulkUpdateCancel;
      const failedApiResponses = responses.filter(resp => resp.value === "FAILED");
      if (failedApiResponses && failedApiResponses.length > 0) {
        dispatch(setServiceDetailsError());
        dispatch(setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.ALL_ERROR, requestMode: OrganisationServicesConstants.OVERLAY_MODE.ADD }));
      } else {
        let failedBranches = apiResponse.filter(branch => branch.isSuccess === false);
        if (isBulkUpdateCancel) {
          dispatch(setServiceDetailsSuccess());
          dispatch(setUI({ isOverlayConfirmationVisible: false, isBulkUpdateComplete: false, isBulkUpdateCancel: false, responseFromApi: [] }));
        } else if (failedBranches.length === 0) {
          dispatch(setServiceDetailsSuccess());
          dispatch(setUI({ isBulkUpdateComplete: true, isBulkUpdateCancel: false }));
        }
        else {
          dispatch(setServiceDetailsError());
          if (failedBranches.length === apiResponse.length) {
            dispatch(setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.ALL_ERROR, requestMode: OrganisationServicesConstants.OVERLAY_MODE.ADD }));
          }
          else {
            dispatch(setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.PARTIAL_ERROR, requestMode: OrganisationServicesConstants.OVERLAY_MODE.ADD }));
          }
        }
      }
    });
}

function sendAddRequests(bulkServiceDetails, accessToken, organisationId) {
  return new Promise((resolve) => {
    agent.OrganisationServices.addServicesDetails(accessToken, organisationId, bulkServiceDetails)
      .then((response) => {
        resolve(response);
        const refState = Store.getState();
        const responseFromApi = refState.organisationServices.ui.responseFromApi;
        let apiResponse = [...responseFromApi];
        response.forEach(resp => {
          apiResponse.push(resp);
        });
        Store.dispatch(setUI({ responseFromApi: apiResponse}));
      })
      .catch(err => {
        resolve("FAILED");
        console.log('Add Service details server error ', err.response && err.response.body);
      })
  });
}

// UPDATE SERVICES
export const updateServiceDetailsPending = (): Action => ({
  type: OrganisationServicesConstants.UPDATE_SERVICES_DETAILS_PENDING,
});

export const updateServiceDetailsSuccess = (): Action => ({
  type: OrganisationServicesConstants.UPDATE_SERVICES_DETAILS_SUCCESS,
});

export const updateServiceDetailsError = (): Action => ({
  type: OrganisationServicesConstants.UPDATE_SERVICES_DETAILS_ERROR,
});

export const updateServiceDetails = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const userProfile = currentState.profile;
  const userRole = currentState.roles.profileCurrentRole;
  var userData = currentState.organisationServices.user.data;
  var userEditData = currentState.organisationServices.user.editData;
  var userServiceDuration = userEditData.serviceDuration;
  var userEditDataPrice = userEditData.servicePrice;
  var userEditDataserviceIsVatIncluded = userEditData.serviceIsVatIncluded;
  var userEditDataCutoff = userEditData.serviceCutOff;
  var userEditDataStatus = userEditData.serviceIsActive;
  var userEditDataServiceModes = userEditData.serviceModes;
  var userEditDataserviceIsShowPrice = userEditData.serviceIsShowPrice;
  var userEditDataBookingUrl = userEditData.bookingUrl;

  const { id, displayName } = userProfile;
  const { role } = userRole;

  const {
    serviceId,
    serviceName,
    serviceBranches,
  } = userData;

  const serviceDetails = {
    id: serviceId,
    name: serviceName,
    branchIds: serviceBranches
    };

  if (userEditDataServiceModes.isEdited) {
    serviceDetails.modes = userEditDataServiceModes.value;
  }
  if (userServiceDuration.isEdited) {
    serviceDetails.duration = userServiceDuration.value;
  }
  if (userEditDataPrice.isEdited) {
    serviceDetails.price = userEditDataPrice.value;
  }
  if (userEditDataserviceIsVatIncluded.isEdited) {
    serviceDetails.vatIncluded = userEditDataserviceIsVatIncluded.value;
  }
  if (userEditDataCutoff.isEdited) {
    serviceDetails.lastMinute = userEditDataCutoff.value;
  }
  if (userEditDataStatus.isEdited) {
    serviceDetails.paused = !userEditDataStatus.value;
  }
  if (userEditDataserviceIsShowPrice.isEdited) {
    serviceDetails.showPrice = userEditDataserviceIsShowPrice.value;
  }
  if (userEditDataBookingUrl.isEdited) {
    serviceDetails.bookingPoint = {
      url: userEditDataBookingUrl.value
    }
  }

  const taskDetails = {
    userId: id,
    userName: displayName,
    roleName: role,
    organisationId: organisationId,
    actionName: TASK_ACTION_TYPE.EDITSERVICEBULK,
    request: serviceDetails
  };

  dispatch(updateServiceDetailsPending());
  getToken(dispatch)
    .then(accessToken => {
      SaveTelemetry(organisationId, taskDetails, 'Update');
      agent.OrganisationServices.editTaskServicesDetails(accessToken, taskDetails)
        .then((res) => {
          dispatch(updateServiceDetailsSuccess());
          dispatch(resetUserEditData());
          dispatch(setUserStep(1));
          dispatch(setUI({ isOverlayConfirmationVisible: false, isSidebarEditVisible: false, isEditByBranch: false }));
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksSuccess));
          dispatch(getBranchesSummary(organisationId, serviceId));
        })
        .catch(err => {
          console.log('Update Service details to task Manager server error ', err.response && err.response.body);
          dispatch(updateServiceDetailsError());
          dispatch(resetUserEditData());
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksError));
        })
    });
};

export const batchUpdateServiceDetails = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  var userData = currentState.organisationServices.user.data;

  const {
    serviceId,
    serviceBranches,
  } = userData;

  const branchesList = {
    id: serviceId,
    branchIds: serviceBranches
  };

  const apibulkUpload = (window.__ENV && window.__ENV.bulkUpload) || {};
  let {
    apiBatchSize
  } = apibulkUpload;

  dispatch(updateServiceDetailsPending());
  getToken(dispatch)
    .then(accessToken => {
      let batchData = splitArraybySize(branchesList.branchIds, parseInt(apiBatchSize));
      setBatchProcessForEdit(batchData, accessToken, organisationId);
    });
};

async function setBatchProcessForEdit(branchData, accessToken, organisationId) {
  const currentState = Store.getState();
  var userData = currentState.organisationServices.user.data;
  var userEditData = currentState.organisationServices.user.editData;
  var userServiceDuration = userEditData.serviceDuration;
  var userEditDataPrice = userEditData.servicePrice;
  var userEditDataserviceIsVatIncluded = userEditData.serviceIsVatIncluded;
  var userEditDataCutoff = userEditData.serviceCutOff;
  var userEditDataStatus = userEditData.serviceIsActive;
  var userEditDataServiceModes = userEditData.serviceModes;
  var userEditDataserviceIsShowPrice = userEditData.serviceIsShowPrice;
  var userEditDataBookingUrl = userEditData.bookingUrl;

  const {
    serviceId,
    serviceBranches,
  } = userData;

  const promises = [];
  const bulkServiceDetails = {
    id: serviceId,
    branchIds: serviceBranches,
  };

  if (userEditDataServiceModes.isEdited) {
    bulkServiceDetails.modes = userEditDataServiceModes.value;
  }
  if (userServiceDuration.isEdited) {
    bulkServiceDetails.duration = userServiceDuration.value;
  }
  if (userEditDataPrice.isEdited) {
    bulkServiceDetails.price = userEditDataPrice.value;
  }
  if (userEditDataserviceIsVatIncluded.isEdited) {
    bulkServiceDetails.vatIncluded = userEditDataserviceIsVatIncluded.value;
  }
  if (userEditDataCutoff.isEdited) {
    bulkServiceDetails.lastMinute = userEditDataCutoff.value;
  }
  if (userEditDataStatus.isEdited) {
    bulkServiceDetails.paused = !userEditDataStatus.value;
  }
  if (userEditDataserviceIsShowPrice.isEdited) {
    bulkServiceDetails.showPrice = userEditDataserviceIsShowPrice.value;
  }
  if (userEditDataBookingUrl.isEdited) {
    bulkServiceDetails.bookingPoint = {
      url: userEditDataBookingUrl.value
    }
  }
  
  SaveTelemetry(organisationId, bulkServiceDetails, 'BatchUpdate');

  for (let j = 0; j < branchData.length; j++) {
    let serviceDetails = {...bulkServiceDetails};
    serviceDetails.branchIds = branchData[j];

    promises.push(await sendEditRequests(serviceDetails, accessToken, organisationId));
    const refState = Store.getState();
    const isBulkUpdateCancel = refState.organisationServices.ui.isBulkUpdateCancel;
    if (isBulkUpdateCancel) {
      break;
    }
  }

  Promise.allSettled(promises)
    .then((responses) => {
      const { dispatch } = Store;
      const newState = Store.getState();
      const apiResponse = newState.organisationServices.ui.responseFromApi;
      const isBulkUpdateCancel = newState.organisationServices.ui.isBulkUpdateCancel;
      const failedApiResponses = responses.filter(resp => resp.value === "FAILED");
      if (failedApiResponses && failedApiResponses.length > 0) {
        dispatch(updateServiceDetailsError());
        dispatch(setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.ALL_ERROR, requestMode: OrganisationServicesConstants.OVERLAY_MODE.EDIT }));
        dispatch(resetUserEditData());
      } else {
        let failedBranches = apiResponse.filter(branch => branch.isSuccess === false);
        if (isBulkUpdateCancel) {
          dispatch(updateServiceDetailsSuccess());
          dispatch(setUI({ isOverlayConfirmationVisible: false, isBulkUpdateComplete: false, isBulkUpdateCancel: false, responseFromApi: [] }));
        } else if (failedBranches.length === 0) {
          dispatch(updateServiceDetailsSuccess());
          dispatch(setUI({ isBulkUpdateComplete: true, isBulkUpdateCancel: false }));
        }
        else {
          dispatch(updateServiceDetailsError());
          if (failedBranches.length === apiResponse.length) {
            dispatch(setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.ALL_ERROR, requestMode: OrganisationServicesConstants.OVERLAY_MODE.EDIT }));
          }
          else {
            dispatch(setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.PARTIAL_ERROR, requestMode: OrganisationServicesConstants.OVERLAY_MODE.EDIT }));
          }
        }
      }
    });
}

function sendEditRequests(bulkServiceDetails, accessToken, organisationId) {
  return new Promise((resolve) => {
    agent.OrganisationServices.updateServicesDetails(accessToken, organisationId, bulkServiceDetails)
      .then((response) => {
        resolve(response);
        const refState = Store.getState();
        const responseFromApi = refState.organisationServices.ui.responseFromApi;
        let apiResponse = [...responseFromApi];
        response.forEach(resp => {
          apiResponse.push(resp);
        });
        Store.dispatch(setUI({ responseFromApi: apiResponse}));
      })
      .catch(err => {
        resolve("FAILED");
        console.log('Bulk update service server error ', err.response && err.response.body);
      })
  });
}

// DELETE SERVICES
export const deleteOrgServicePending = (): Action => ({
  type: OrganisationServicesConstants.DELETE_ORG_SERVICES_PENDING,
});

export const deleteOrgServiceSuccess = (): Action => ({
  type: OrganisationServicesConstants.DELETE_ORG_SERVICES_SUCCESS,
});

export const deleteOrgServiceError = (): Action => ({
  type: OrganisationServicesConstants.DELETE_ORG_SERVICES_ERROR,
});

export const deleteOrgService = (organisationId: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const userProfile = currentState.profile;
  const userRole = currentState.roles.profileCurrentRole;
  const userData = currentState.organisationServices.user.data;

  const {
    serviceId,
    serviceName,
    serviceBranches,
  } = userData;

  const { id, displayName } = userProfile;
  const { role } = userRole;

  const serviceDetails = {
    userId: id,
    userName: displayName,
    roleName: role,
    organisationId: organisationId,
    actionName: TASK_ACTION_TYPE.REMOVESERVICEBULK,
    request: {
      id: serviceId,
      name: serviceName,
      branchIds: serviceBranches
    },
  };

  dispatch(deleteOrgServicePending());
  getToken(dispatch)
    .then(accessToken => {
      agent.OrganisationServices.removeTaskServicesDetails(accessToken, serviceDetails)
        .then((res) => {
          SaveTelemetry(organisationId, serviceDetails, 'Remove');
          dispatch(deleteOrgServiceSuccess());
          dispatch(resetUserData());
          dispatch(setUserStep(1));
          dispatch(setUI({ isOverlayConfirmationVisible: false, isSidebarEditVisible: false, isEditByBranch: false  }));
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksSuccess));
          dispatch(getBranchesSummary(organisationId, serviceId));
        })
        .catch(err => {
          console.log('Delete Service server error ', err.response && err.response.body);
          dispatch(deleteOrgServiceError());
          dispatch(showSnackbarStatus(locale.Snackbar.itemsAddedToTasksError));
          dispatch(resetUserData());
          dispatch(setUserStep(1));
          dispatch(setUI({ isOverlayConfirmationVisible: false, isSidebarEditVisible: false, isEditByBranch: false }));
        })
    });
};

// SERVICE_DESCRIPTION
export const setServiceDescriptionPending = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICE_DESCRIPTION_PENDING,
});

export const setServiceDescriptionSuccess = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICE_DESCRIPTION_SUCCESS,
});

export const setServiceDescriptionError = (): Action => ({
  type: OrganisationServicesConstants.GET_ORG_SER_SERVICE_DESCRIPTION_ERROR,
});

export const setServiceDescriptionServiceId = (serviceId: string): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_SERVICE_DESCRIPTION_SERVICE_ID,
  payload: serviceId,
});

export const setServiceDescriptionData = (data: any): Action => ({
  type: OrganisationServicesConstants.SET_ORG_SER_SERVICE_DESCRIPTION_DATA,
  payload: data,
});

export const getServiceDescription = (serviceId: string) => (dispatch: Function) => {
  dispatch(setServiceDescriptionServiceId(serviceId));
  dispatch(setServiceDescriptionPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.getPPLServiceDetails(
        serviceId,
        accessToken,
      )
      .then(data => {
        dispatch(setServiceDescriptionData(data));
        dispatch(setServiceDescriptionSuccess());
      })
      .catch(err => {
        dispatch(setServiceDescriptionError());
        console.log("getServiceDescription server error or branch was not found", err);
      });
    });
};

const SaveTelemetry = (organisationId: string, serviceDetails: any, createdUpdatedOrRemoved: string) => {
  const currentState = Store.getState();

  let eventDetails = {
    UserId: currentState.profile.id,
    SessionId: currentState.profile.sessionId,
    RoleName: currentState.roles.profileCurrentRole.role,
    OrganizationId: organisationId,
    ServiceDetails: serviceDetails,
    CreatedUpdatedOrRemoved: createdUpdatedOrRemoved
  }

  // Telemetry
  ai.appInsights.trackEvent({
    name: 'Bulk_Service' + createdUpdatedOrRemoved,
  }, eventDetails);
}
