import moment from "moment";
import values from "lodash.values";

import * as RolesConstants from "constants/RolesConstants";
import * as UsersConstants from "constants/UsersConstants";
import {
  checkEmail,
  checkPassword,
  checkPhoneNumber,
  checkPostcode,
  checkEmailRegexp,
  checkTimeFormat,
  passwordContainsEmail,
  checkURL
} from "helpers/checkValues";
import { formatOpeningHours } from "helpers/formatData";

import locale from "service/locale";
import * as CalendarsConstants from "constants/CalendarsConstants";

export const validateAddUserForm = (data: Object): boolean => {
  const {
    new_user_firstName,
    new_user_email,
    new_user_role,
    new_user_organization,
    new_user_branch
  } = data;
  let roleChecker = false;

  if (new_user_role && new_user_role.level === 0) roleChecker = true;
  if (new_user_role && new_user_role.level === 1 && new_user_organization) roleChecker = true;
  if (new_user_organization && new_user_organization.level !== 1 && new_user_branch) roleChecker = true;

  return (
    new_user_firstName &&
    new_user_firstName.trim().length > 1 &&
    new_user_email &&
    checkEmailRegexp.test(new_user_email) &&
    roleChecker
  );
};

export const validateAddBranchModal = (form: Object): boolean => {
  const {
    name,
    phone,
    email,
    address = {},
    organisationId,
    isVirtual
  } = form;
  const isPhoneCorrect = (phone || "").length === 0 || checkPhoneNumber(phone);
  const isEmailCorrect = (email || "").length === 0 || checkEmail(email);

  if (!isVirtual) {
    const {
      addressLine1,
      postcode,
      town
    } = address;
    const isPostcodeCorrect = checkPostcode(postcode);
    return (
      name &&
      !!name.length &&
      addressLine1 &&
      addressLine1.length &&
      town &&
      town.length &&
      organisationId &&
      isPhoneCorrect &&
      isEmailCorrect &&
      isPostcodeCorrect
    );
  } else {
    return (
      name &&
      !!name.length &&
      organisationId &&
      isPhoneCorrect &&
      isEmailCorrect 
    );
  }
};

export const validateEditBranchInfo = (info: Object): boolean => {
  const {
    name,
    email
  } = info;
  const isNameCorrect = name && !!(name || "").length;
  const isEmailCorrect = (email || "").length === 0 || checkEmail(email);
  return isNameCorrect && isEmailCorrect;
};

export const validateEditBranchAddress = (address: Object): boolean => {
  const {
    addressLine1,
    postcode,
    town
  } = address;
  const isPostcodeCorrect = checkPostcode(postcode);

  return addressLine1 && town && isPostcodeCorrect;
};

export const isNewCalendarDataCorrect = (formData: Object, existingCalendarNamesList: string[]): boolean => {
  const today = moment.utc().toISOString().substr(0, 10);
  let isValidBlockedPeriods = true;
  let {
    name,
    startDate,
    endDate,
    duration_type,
    blockedPeriods,
    openHours
  } = formData;
  if (!startDate) startDate = today;
  if (!endDate) endDate = today;
  if (!duration_type) duration_type = locale.AddNewCalendar.durationOptions[0].value;  
  let isAvailabilityCorrect= false;
  if(openHours)
  {
  const days = values(formatOpeningHours(openHours));
    isAvailabilityCorrect = days && (days.every(
      day => {
        const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
        if (start === "" && end === "") return false;
        return (day.isOpen === true&&
                  start &&
                  end &&
                  start < end &&
                  end.length === 5 &&
                  start.length === 5 &&
                  (start[4] === '0' || start[4] === '5') &&
                  (end[4] === '0' || end[4] === '5')) ;
      }
    ));
    }

    if (!isAvailabilityCorrect) return false;  

  const isNameEntered = !!(name &&
    name.length > 0 &&
    !existingCalendarNamesList.find(el => el === name));

  if (!isNameEntered) return false;

  const isDateRangeCorrect = !!(
    (startDate &&
      duration_type === locale.AddNewCalendar.durationOptions[1].value &&
      startDate <= endDate) ||
    (startDate &&
      duration_type === locale.AddNewCalendar.durationOptions[0].value)
  );

  if (!isDateRangeCorrect) return false;

  if (blockedPeriods) {
    const { incorrectBlockedPeriods } = checkBlockedPeriods(blockedPeriods);
    isValidBlockedPeriods = !incorrectBlockedPeriods;
  }
  return isValidBlockedPeriods;
};

export const validateCalendarDetails = (form, calendarDetails): boolean => {
  const { availablePeriods } = calendarDetails;
  const days = values(form);
  const keys = Object.keys(form);
  return days.some(
    (day, index) => {
      const editedDay = locale.AddNewCalendar.daysOfWeek.find(day => day.value.name === keys[index]);
      const availablePeriod = availablePeriods.find(period => (period && period.dayOfWeek) === (editedDay && editedDay.dayOfWeek));
      const status = (day && day.status) || availablePeriod;
      const start = day.start || (availablePeriod && availablePeriod.periods[0].start.slice(0,-3));
      const end = day.end || (availablePeriod && availablePeriod.periods[0].end.slice(0,-3));
      return status === "available"
        ? start && end
        : typeof status === "object"
          ? start && end
          : true;
    }
  );
};

export const validateChangePassword = (form: Object, email: string): boolean => {
  const {
    password = "", newPassword = "", confirmNewPassword = ""
  } = form;

  return (
    !!password &&
    !!password.length &&
    !!newPassword &&
    checkPassword(newPassword) &&
    !passwordContainsEmail(email, newPassword) &&
    !!confirmNewPassword &&
    checkPassword(confirmNewPassword) &&
    newPassword === confirmNewPassword
  );
};

export const checkDuplicateError = (err: any): boolean => {
  return (
    err.response &&
    err.response.body &&
    (
      err.response.body.hasOwnProperty(UsersConstants.DUPLICATE_ERROR_CODES.organisation) ||
      err.response.body.hasOwnProperty(UsersConstants.DUPLICATE_ERROR_CODES.branch)
    )
  );
};

export const checkDuplicateNameError = (err: any): boolean => {
  return (
    err.response &&
    err.response.body &&
    err.response.body.hasOwnProperty(UsersConstants.DUPLICATE_ERROR_CODES.branchName)
  );
};

export const checkOdsDuplicateError = (err: any): boolean => {
  return (
    err.response &&
    err.response.body &&
    err.response.body.hasOwnProperty(UsersConstants.DUPLICATE_ERROR_CODES.odsCode)
  );
};

export const isDuplicateNameError = (data: Object, existingCalendarNamesList: any[]): boolean => {
  return !!existingCalendarNamesList.find(el => el === data.name);
};

export const isEmptyNameError = (data: Object): boolean => {
  return data.name === "";
};

export const checkTodayDate = (date: Date): boolean => {
  const today = new Date();
  return moment(date).isSame(today, "day");
};

export const validateCalendarBlockPeriods = (formData: Object, blockedPeriods: any[]): boolean => {
  let isValidEnteredPeriods = true;
  let isNotValidEmptyPeriods = false;
  for (let period in formData) {
    const bp = blockedPeriods.find(bp => bp.id === Number(period));
    if (bp &&
      !formData[period].allDay &&
      (
        (formData[period].startTime || "").replace(/ /g, "").length !== 5 ||
        (formData[period].endTime || "").replace(/ /g, "").length !== 5 ||
        (
          formData[period].startTime &&
          formData[period].endTime &&
          (
            (formData[period].startDate || bp.startDate) === formData[period].endDate &&
            (formData[period].startTime || bp.startTime) >= formData[period].endTime
          )
        ) ||
        formData[period].startDate === "" ||
        !formData[period].endDate === ""
      )
    ) {
      isValidEnteredPeriods = false;
      break;
    }
  }

  if (!isValidEnteredPeriods) return false;

  const emptyBlockedPeriods = blockedPeriods.filter(period => period.start === "");

  if (emptyBlockedPeriods) {
    isNotValidEmptyPeriods = emptyBlockedPeriods.some(emptyPeriod => {
      const emptyPeriodInFormData = formData && formData[emptyPeriod.id];
      return (
        !emptyPeriodInFormData ||
        (((emptyPeriodInFormData.startTime && emptyPeriodInFormData.startTime.length !== 5) ||
            (emptyPeriodInFormData.endTime && emptyPeriodInFormData.endTime.length !== 5)) &&
          !emptyPeriodInFormData.allDay
        )) || 
        (emptyPeriodInFormData.startTime && emptyPeriodInFormData.endTime && emptyPeriodInFormData.startTime >= emptyPeriodInFormData.endTime)
    })
  }

  return !isNotValidEmptyPeriods;
};

export const checkOpenHours = (form: any): Object => {
  const days = values(formatOpeningHours(form));
  const isNotMultipleByFive = days.some(
    day => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);      
      if (start === "" || end === "" || start.replace(/ /g, "").length !== 5 || end.replace(/ /g, "").length !== 5 ) return false;
      return (day.status === locale.AddNewCalendar.dayAvailableOptions[0].value || day.isOpen) &&
      (!checkTimeFormat(start) || !checkTimeFormat(end)) ;
    }
  );
  const isEqualTime = days.some(
    day => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
      if(start === CalendarsConstants.EMPTY_VALUE && end === CalendarsConstants.EMPTY_VALUE) return false;
      return (day.status === locale.AddNewCalendar.dayAvailableOptions[0].value || day.isOpen) &&
        checkTimeFormat(start || "") &&
        checkTimeFormat(end || "") &&
        start === end &&
        start !== "";
    }
  );
  const isStartTimeLessThanEndTime = days.some(
    day => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
      return (day.status === locale.AddNewCalendar.dayAvailableOptions[0].value || day.isOpen) &&
        checkTimeFormat(start || "") &&
        checkTimeFormat(end || "") &&
        start.length === 5 &&
        end.length === 5 &&
        start > end;
    }
  );
  const hasEmptyPeriod = days.some(
    day => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
      var rejexPattern=/^[0\s]{0,2}:[0\s]{0,2}$/;
      return (start === "" || end === "") || (rejexPattern.test(start) || rejexPattern.test(end) );
    }
  );

  const hasEmptyPeriodWithoutZeros = days.some(
    day => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
      return start === "" && end === "";
    }
  );
    
  const isIntervalInvalid = days.some(
    (day, i, array) => {
       
      let intervals = array.filter(d => d.dayOfWeek === day.dayOfWeek);
      if (intervals.length <= 1) {
        return false;
      }      
      return intervals.some((interval, index, a) => {
  
        let nextPeriod;
        let startTime;
        let start;
        const endTime = interval.endTime || "";
        const end = endTime.indexOf(":", 4) ? endTime.slice(0,5) : endTime;

        if(a[index+1]){
          nextPeriod = a[index+1];
          startTime = nextPeriod.startTime || "";          
          start = startTime.indexOf(":", 4) ? startTime.slice(0,5) : startTime;
          if (start.replace(/ /g, "").length !== 5) return false;
        }

        return nextPeriod && start && start.length && end && end.length && start <= end;
    
        });
    });    
  
  const hasInvalidTime = days.some(
    day => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);      
      if (checkTimeFormat(start) && checkTimeFormat(end)) return false;      
      return true;      
    }
  );  

  return { isNotMultipleByFive, isEqualTime, isStartTimeLessThanEndTime, hasEmptyPeriod, isIntervalInvalid, hasInvalidTime, hasEmptyPeriodWithoutZeros };
};

 export const checkBranchAvailibility = (branchOpenHours: any, calendarOpenHours: any): object =>{
   const days = values(formatOpeningHours(branchOpenHours));
   const calendarOpenHoursdays = values(formatOpeningHours(calendarOpenHours));      
   const isCalendarIntervalInvalidWithBranchAvailability = calendarOpenHoursdays.some((cday,i,a) => {
      const cintervals = a.filter(d => d.dayOfWeek === cday.dayOfWeek); 
      const bintervals = days.filter(d => d.dayOfWeek === cday.dayOfWeek);
      let bintervalvalidate =[];
      let bstartTime;
      bstartTime = bintervals && bintervals.length ? bintervals[0].startTime : "";
      const bstart = bstartTime.indexOf(":", 4) ? bstartTime.slice(0,5) : bstartTime;

      bintervals.map((session,sindex) => {
        if(sindex < (bintervals.length - 1))        
          return bintervalvalidate.push({
              startTime: session.endTime,
              endTime: bintervals[sindex+1].startTime
        });    
        return bintervalvalidate.push({startTime:"",endTime:""});                      
      });

      if(bintervals.length === 1)
      { 
        let bendTime; 
        if(bintervals &&  bintervals.length && bintervals.length-1)
          {            
          bendTime = bintervals[bintervals.length-1].endTime || "";
          }
        else              
          bendTime = bintervals[0].endTime || "";

        const bend = bendTime.indexOf(":", 4) ? bendTime.slice(0,5) : bendTime;         
        let cendTime;
        if(cintervals &&  cintervals.length && cintervals.length-1)
          cendTime = cintervals[cintervals.length-1].endTime || "";
        else              
          cendTime = cintervals[0].endTime || "";

        const cend = cendTime.indexOf(":", 4) ? cendTime.slice(0,5) : cendTime;  
        const cstartTime = cintervals[0].startTime || "";
        const cstart = cstartTime.indexOf(":", 4) ? cstartTime.slice(0,5) : cstartTime;  
        if(cstart === CalendarsConstants.EMPTY_VALUE && cend === CalendarsConstants.EMPTY_VALUE) return false;      
        return cstart < bstart || cend > bend;        
      }     
      return cintervals.some((cinterval) => {
          const cendTime = cinterval.endTime || "";
          const cend = cendTime.indexOf(":", 4) ? cendTime.slice(0,5) : cendTime;  
          const cstartTime = cinterval.startTime || "";
          const cstart = cstartTime.indexOf(":", 4) ? cstartTime.slice(0,5) : cstartTime;          
          if(cstart === CalendarsConstants.EMPTY_VALUE && cend === CalendarsConstants.EMPTY_VALUE) return false;

      return bintervalvalidate.some((bintervalval) => {
        const vendTime = bintervalval.endTime || "";
        const vend = vendTime.indexOf(":", 4) ? vendTime.slice(0,5) : vendTime;  
        const vstartTime = bintervalval.startTime || "";
        const vstart = vstartTime.indexOf(":", 4) ? vstartTime.slice(0,5) : vstartTime;          
        const bendTime = bintervals && bintervals.length && bintervals.length-1 ? bintervals[bintervals.length-1].endTime : "";
        const bend = bendTime.indexOf(":", 4) ? bendTime.slice(0,5) : bendTime;          
        return (cend > vstart && cend < vend) || (cstart > vstart && cstart < vend) || (cstart <= vstart && cend >= vend) || (cstart < bstart) || (cend > bend);
        });
      });
    });    
  return { isCalendarIntervalInvalidWithBranchAvailability };
 };

const getTimeValues = (startTime: string = "", endTime: string = "") => {
  const start = startTime.indexOf(":", 4) ? startTime.slice(0,5) : startTime;
  const end = endTime.indexOf(":", 4) ? endTime.slice(0,5) : endTime;
  return { start, end };
};

export const checkBlockedPeriods = (blockedPeriods): boolean => { 
  const periods = values(blockedPeriods);
  if (!periods.length) return false;

  const isEmptyTimeField = periods.some(
    period => {
      if (period.allDay) return false;
      return !(period.startTime || "").length || !(period.endTime || "").length;
    }
  );

  const isNotMultipleByFive = periods.some(
    period => { 
      if (period.allDay) return false;
      let isStartTimeValid, isEndTimeValid;
      const startTime = period.startTime || "";
      const endTime = period.endTime || "";
      const start = startTime.indexOf(":", 4) ? startTime.slice(0,5) : startTime;
      const end = endTime.indexOf(":", 4) ? endTime.slice(0,5) : endTime;

      isStartTimeValid = period.startTime && period.startTime.length && period.startTime !== "  :  " ? !checkTimeFormat(start) : false;
      isEndTimeValid = period.endTime && period.endTime.length && period.endTime !== "  :  " ? !checkTimeFormat(end) : false;

      return isStartTimeValid || isEndTimeValid;
    }
  );

  const isEqualTime = periods.some(
    period => {
      if (period.allDay) return false;
      const startTime = period.startTime || "";
      const endTime = period.endTime || "";
      const start = startTime.indexOf(":", 4) ? startTime.slice(0,5) : startTime;
      const end = endTime.indexOf(":", 4) ? endTime.slice(0,5) : endTime;
      return period.startTime && period.startTime.length && period.endTime && period.endTime.length && start === end;
    }
  );

  const isEndTimeLessThanStartTime = periods.some(
    period => {
      if (period.allDay) return false;
      const startTime = period.startTime || "";
      const endTime = period.endTime || "";
      const start = startTime.indexOf(":", 4) ? startTime.slice(0,5) : startTime;
      const end = endTime.indexOf(":", 4) ? endTime.slice(0,5) : endTime;
      return period.startTime && period.startTime.length && period.endTime && period.endTime.length && start > end;
    }
  );

  const incorrectBlockedPeriods = isEmptyTimeField || isNotMultipleByFive || isEqualTime || isEndTimeLessThanStartTime;

  return { isNotMultipleByFive, isEqualTime, isEndTimeLessThanStartTime, incorrectBlockedPeriods };
};

export const validateUserRoles = (accounts: any[], newAccounts?: any): boolean => {
  return accounts
    .concat(
      values(newAccounts).map(account => {
        const branch = account.branch;
        const organisation = account.organisation;
        const role = account.role;
        return {
          branchId: branch && branch.id,
          branchName: branch && branch.name,
          organizationId: organisation && organisation.id,
          organizationName: organisation && organisation.companyName,
          role: role && role.name,
          roleLevel: role && role.level,
        }
      })
    )
    .every(account => {
      const { role, organizationId, branchId } = account;
      const isSuperAdmin = role === RolesConstants.ADMIN;
      const hasOrganisation = (role === RolesConstants.ORGANIZATION_ADMIN) && organizationId;
      const hasBranch = [RolesConstants.BRANCH_ADMIN, RolesConstants.BRANCH_MEMBER].includes(role) && branchId;

      return isSuperAdmin || hasOrganisation || hasBranch;
    });
};

export const validateAddOrganisationModel = (form: Object): boolean => {
  const {
    organisationType,
    companyNumber,
    booking,
  } = form;
  
  const url = booking && booking.point && booking.point.url;

  const isBookingURLCorrect = (url || "").length === 0 || checkURL(url);

  return (
    companyNumber &&
    !!companyNumber.length &&
    organisationType &&
    isBookingURLCorrect
  );
};

export const validateSoleTraderAddOrganisationModel = (form: Object): boolean => {
  const {
    organisationType,
    booking,
    address = {},
    name,
    twoFactorValue
  } = form;

  const {
    addressLine1,
    postcode,
    town,
    country
  } = address;

  const url = booking && booking.point && booking.point.url;
  const isBookingURLCorrect = (url || "").length === 0 || checkURL(url);
  const isPostcodeCorrect = checkPostcode(postcode);

  return (
    name &&
    !!name.length &&
    organisationType &&
    twoFactorValue &&
    isBookingURLCorrect &&
    address &&
    addressLine1 &&
    !!addressLine1.length &&
    town &&
    !!town.length &&
    isPostcodeCorrect &&
    country &&
    country.length
  );
}

//To check intervals are in multiple of five
export const checkNotMultipleByFive = (day: any): boolean => {
  const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
  let isNotMultipleByFive = false;

  if (start === "" || end === "" || start.replace(/ /g, "").length !== 5 || end.replace(/ /g, "").length !== 5 ) {
    isNotMultipleByFive = false;
  } else {
    isNotMultipleByFive = (day.status === locale.AddNewCalendar.dayAvailableOptions[0].value || day.isOpen) &&
    (!checkTimeFormat(start) || !checkTimeFormat(end));
  }

  return isNotMultipleByFive;
}

//To check start and end time are equal
export const checkEqualTime = (day: any): boolean => {
  const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
  let isEqualTime = false;

  if(start === CalendarsConstants.EMPTY_VALUE && end === CalendarsConstants.EMPTY_VALUE) {
    isEqualTime = false;
  } else {
    isEqualTime = (day.status === locale.AddNewCalendar.dayAvailableOptions[0].value || day.isOpen) &&
    checkTimeFormat(start || "") &&
    checkTimeFormat(end || "") &&
    start === end &&
    start !== "";
  }

  return isEqualTime;
}

//To check end time is less than start time
export const checkEndTimeLessThanStartTime = (day: any): boolean => {
  const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
  let isEndTimeLessThanStartTime = false;

  isEndTimeLessThanStartTime = (day.status === locale.AddNewCalendar.dayAvailableOptions[0].value || day.isOpen) &&
  checkTimeFormat(start || "") &&
  checkTimeFormat(end || "") &&
  start.length === 5 &&
  end.length === 5 &&
  start > end;
  
  return isEndTimeLessThanStartTime;
}

//To check intervals are empty
export const checkEmptyPeriod = (startTime: string, endTime: string): boolean => {
  var rejexPattern=/^[0\s]{0,2}:[0\s]{0,2}$/;
  const hasEmptyPeriod = (startTime === "" || endTime === "") || (rejexPattern.test(startTime) || rejexPattern.test(endTime));

  return hasEmptyPeriod;
}

//To check intervals are invalid
export const checkIntervalInvalid = (day:any, array:any): boolean => {
  let intervals = array.filter(d => d.dayOfWeek === day.dayOfWeek);
  let isIntervalInvalid = false;
  
  if (intervals.length <= 1) {
    isIntervalInvalid = false;
  } else {
    isIntervalInvalid = intervals.some((interval, index, a) => {

      let nextPeriod;
      let startTime;
      let start;
      const endTime = interval.endTime || "";
      const end = endTime.indexOf(":", 4) ? endTime.slice(0,5) : endTime;

      if(a[index+1]){
        nextPeriod = a[index+1];
        startTime = nextPeriod.startTime || "";          
        start = startTime.indexOf(":", 4) ? startTime.slice(0,5) : startTime;
        if (start.replace(/ /g, "").length !== 5) return false;
      }

      return nextPeriod && start && start.length && end && end.length && start <= end;
  
    });
  }

  return isIntervalInvalid;
}

//To check time is invalid
export const checkInvalidTime = (startTime: string, endTime: string):boolean => {
  let hasInvalidTime = false;

  if (checkTimeFormat(startTime) && checkTimeFormat(endTime)) {
    hasInvalidTime = false;
  } else {
    hasInvalidTime = true;
  }

  return hasInvalidTime;
}

export const checkIntervals = (form: any): Object => {
  const days = values(formatOpeningHours(form));
  let isValidHours = [];
  days.map(
    (day, i, array) => {
      const { start, end } = getTimeValues(day.start || day.startTime, day.end || day.endTime);
      const isNotMultipleByFive = checkNotMultipleByFive(day);
      let isEqualTime = checkEqualTime(day);
      let isEndTimeLessThanStartTime = checkEndTimeLessThanStartTime(day);
      const hasEmptyPeriod = checkEmptyPeriod(start, end);
      const isIntervalInvalid = checkIntervalInvalid(day, array);
      const hasInvalidTime = checkInvalidTime(start, end);

      if (hasInvalidTime) {
        isEndTimeLessThanStartTime =  (start !== "" && start !== "  :  ") && (end !== "" && end !== "  :  ") && start > end;
        isEqualTime = start !== "" && start === end;
      }

      const validDay = {
        dayOfWeek: day.dayOfWeek,
        startTime: start === "00:00" ? "" : start,
        endTime: end === "00:00" ? "" : end,
        isNotMultipleByFive,
        isEqualTime,
        isEndTimeLessThanStartTime,
        hasEmptyPeriod,
        isIntervalInvalid,
        hasInvalidTime
      };
      isValidHours.push(validDay);

      return { isValidHours };
    }
  );
  return { isValidHours };
};

export const checkIntervalsBranchAvailibility = (branchOpenHours: any, calendarOpenHours: any): object =>{
  const days = values(formatOpeningHours(branchOpenHours));
  const calendarOpenHoursdays = values(formatOpeningHours(calendarOpenHours));
  let isValidBranchAvailability = [];
  calendarOpenHoursdays.map((cday,i,a) => {
     const cintervals = a.filter(d => d.dayOfWeek === cday.dayOfWeek); 
     const bintervals = days.filter(d => d.dayOfWeek === cday.dayOfWeek);
     const { start, end } = getTimeValues(cday.startTime, cday.endTime);
     let bintervalvalidate =[];
     let bstartTime;
     let isValid;
     const validStartTime = start === "00:00" ? "" : start;
     const validEndTime = end === "00:00" ? "" : end;
     bstartTime = bintervals && bintervals.length ? bintervals[0].startTime : "";
     const bstart = bstartTime.indexOf(":", 4) ? bstartTime.slice(0,5) : bstartTime;

     bintervals.map((session,sindex) => {
       if(sindex < (bintervals.length - 1))        
         return bintervalvalidate.push({
             startTime: session.endTime,
             endTime: bintervals[sindex+1].startTime
       });    
       return bintervalvalidate.push({startTime:"",endTime:""});                      
     });

     if(bintervals.length === 1)
     { 
       let bendTime; 
       if(bintervals &&  bintervals.length && bintervals.length-1)
         {            
         bendTime = bintervals[bintervals.length-1].endTime || "";
         }
       else              
         bendTime = bintervals[0].endTime || "";

       const bend = bendTime.indexOf(":", 4) ? bendTime.slice(0,5) : bendTime;         
       let cendTime;
       if(cintervals &&  cintervals.length && cintervals.length-1)
         cendTime = cintervals[cintervals.length-1].endTime || "";
       else              
         cendTime = cintervals[0].endTime || "";

       const cend = cendTime.indexOf(":", 4) ? cendTime.slice(0,5) : cendTime;  
       const cstartTime = cintervals[0].startTime || "";
       const cstart = cstartTime.indexOf(":", 4) ? cstartTime.slice(0,5) : cstartTime;  
       if(cstart === CalendarsConstants.EMPTY_VALUE && cend === CalendarsConstants.EMPTY_VALUE) {
         isValid = false;
         isValidBranchAvailability.push({dayOfWeek: cday.dayOfWeek, startTime: validStartTime, endTime: validEndTime, isCalendarIntervalInvalidWithBranchAvailability: isValid});
         return { isValidBranchAvailability };
       } else {
        isValid = cstart < bstart || cend > bend;
        isValidBranchAvailability.push({dayOfWeek: cday.dayOfWeek, startTime: validStartTime, endTime: validEndTime, isCalendarIntervalInvalidWithBranchAvailability: isValid});
        return { isValidBranchAvailability };
       }              
     }     
     isValid = cintervals.some((cinterval) => {
         const cendTime = cinterval.endTime || "";
         const cend = cendTime.indexOf(":", 4) ? cendTime.slice(0,5) : cendTime;  
         const cstartTime = cinterval.startTime || "";
         const cstart = cstartTime.indexOf(":", 4) ? cstartTime.slice(0,5) : cstartTime;          
         if(cstart === CalendarsConstants.EMPTY_VALUE && cend === CalendarsConstants.EMPTY_VALUE) return false;

       return bintervalvalidate.some((bintervalval) => {
         const vendTime = bintervalval.endTime || "";
         const vend = vendTime.indexOf(":", 4) ? vendTime.slice(0,5) : vendTime;  
         const vstartTime = bintervalval.startTime || "";
         const vstart = vstartTime.indexOf(":", 4) ? vstartTime.slice(0,5) : vstartTime;          
         const bendTime = bintervals && bintervals.length && bintervals.length-1 ? bintervals[bintervals.length-1].endTime : "";
         const bend = bendTime.indexOf(":", 4) ? bendTime.slice(0,5) : bendTime;          
         return (cend > vstart && cend < vend) || (cstart > vstart && cstart < vend) || (cstart <= vstart && cend >= vend) || (cstart < bstart) || (cend > bend);
         });
     });

     isValidBranchAvailability.push({dayOfWeek: cday.dayOfWeek, startTime: validStartTime, endTime: validEndTime, isCalendarIntervalInvalidWithBranchAvailability: isValid});
     return { isValidBranchAvailability };
   });    
 return { isValidBranchAvailability };
};
