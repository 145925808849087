import React, { Component } from 'react';
import { ReportsLegend, ReportsLegendItem } from 'components/Pages/Reports/wrappers';
import ChartNominations from '../ChartNominations/ChartNominations';
import * as ColorConstants from 'constants/ColorConstants';
import './styles/card-reports-nominations-chart.scss';
import locale from 'service/locale';

type Props = {
  isResizing: boolean,
};

class CardNominationsChart extends Component<Props> {
  render() {
    const { isResizing } = this.props;

    return (
      <div className="card-reports-nominations-chart">
        <div className="card-reports-nominations-chart-header">
          <h3>{locale.Reports.smartPharmacyNominationGraphTitle}</h3>
        </div>
        <div className="card-reports-nominations-chart-content">
          <ChartNominations isResizing={isResizing}/>
        </div>
        <div className="card-reports-nominations-chart-footer">
          <ReportsLegend>
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_TWO} label={locale.Reports.smartPharmacyNominationGraphLegend2} />
          </ReportsLegend>
        </div>
      </div>
    );
  }
};

export default CardNominationsChart;
