import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { formatDOBToString } from "helpers/formatData";
import {
  checkEmail,
  checkPhoneNumber,
  checkFirstName,
  checkLastName,
  checkPostcode,
  checkDOB
} from "helpers/checkValues";
import {
  Form,
  FormItem,
  InputLabel,
  Input,
  Icon,
  IconChevronRight,
  IconChevronDown,
  InputError
} from "@patient-access/ui-kit";

import locale from "service/locale";
import "./styles.scss";

export const formFields = {
  firstName: "details_firstName",
  lastName: "details_lastName",
  email: "details_email",
  phoneNumber: "details_phoneNumber",
  DOB: "details_DOB",
  postcode: "details_postcode"
};

type Props = {
  patient: {
    isDetailsOpened: boolean,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    postcode: string,
    email: string,
    phone: string
  },
  form: any,
  handleFormChange: (data: any) => any,
  status: number,
  isBookedOnline: boolean,
  isPastAndEndTimeAppointment: boolean,
  startStatus: number,
  isLowResolution: boolean,
  appointmentType: number
};

type State = {
  isDetailsOpened: boolean,
  isEditMode: boolean,
  details_firstName: boolean,
  details_lastName: boolean,
  details_email: boolean,
  details_phoneNumber: boolean,
  details_DOB: boolean,
  details_postcode: boolean
};

const mapStateToProps = (state: any) => ({
  form: state.form,
  isLowResolution: state.panel.isLowResolution
});

class CustomerDetails extends Component<Props, State> {
  state = {
    isDetailsOpened:
      !this.props.isLowResolution ||
      this.props.startStatus !== locale.Appointment.status.booked.status ||
      !this.props.isPastAndEndTimeAppointment,
    isEditMode: false,
    details_firstName: false,
    details_lastName: false,
    details_email: false,
    details_phoneNumber: false,
    details_DOB: false,
    details_postcode: false
  };

  handleToggleDetails = (e: Event) => {
    e && e.preventDefault();
    const { isDetailsOpened } = this.state;
    this.setState({ isDetailsOpened: !isDetailsOpened });
  };

  handleSelectViewMode = (e: Event) => {
    e && e.preventDefault();
    const { form } = this.props;

    const { isFormValid } = this.getFormValidation(form);

    if (isFormValid )
      this.setState({ isEditMode: false });
  };

  handleSelectEditMode = (e: Event) => {
    e && e.preventDefault();
    this.setState({ isEditMode: true });
  };

  handleFocus = ({ target: { name }}) => {
    this.setState({ [name]: true });
  };

  handleBlur = ({ target: { name }}) => {
    this.setState({ [name]: false });
  };

  getFormValidation = (form: Object) => {
    const _keys = Object.keys(form);
    const { appointmentType } = this.props;
    const isTelephoneAppointment = appointmentType === 2;
    const isVideoAppointment = appointmentType === 1;
    const isEditedFirstName = _keys.includes(formFields.firstName);
    const isEditedLastName = _keys.includes(formFields.lastName);
    const isEditedEmail = _keys.includes(formFields.email);
    const isEditedPhone = _keys.includes(formFields.phoneNumber);
    const isEditedPostcode = _keys.includes(formFields.postcode);
    const isEditedDOB = _keys.includes(formFields.DOB);

    const isFirstNameCorrect = isEditedFirstName ? checkFirstName(form[formFields.firstName]) : true;
    const isLastNameCorrect = isEditedLastName ? checkLastName(form[formFields.lastName]) : true;
    const isEmailCorrect =  isEditedEmail 
      ? (isTelephoneAppointment || isVideoAppointment) 
        ? checkEmail(form[formFields.email]) :
        (!form[formFields.email] || checkEmail(form[formFields.email])) 
      : true;
    const isPhoneCorrect = isEditedPhone
      ? (isTelephoneAppointment || isVideoAppointment)
        ? checkPhoneNumber(form[formFields.phoneNumber])
        : !form[formFields.phoneNumber] ||
          checkPhoneNumber(form[formFields.phoneNumber])
      : true;
    const isPostcodeCorrect = isEditedPostcode ? (!form[formFields.postcode] || checkPostcode(form[formFields.postcode])) : true;
    const isDOBCorrect = isEditedDOB ? (!form[formFields.DOB] || checkDOB(form[formFields.DOB])) : true;

    const isFormValid =
      isFirstNameCorrect &&
      isLastNameCorrect &&
      isEmailCorrect &&
      isPhoneCorrect &&
      isPostcodeCorrect &&
      isDOBCorrect;

    return {
      isFirstNameCorrect,
      isLastNameCorrect,
      isEmailCorrect,
      isPhoneCorrect,
      isPostcodeCorrect,
      isDOBCorrect,
      isFormValid
    };
  };

  render() {
    const { patient, handleFormChange, form, isBookedOnline, status, appointmentType } = this.props;
    const { isDetailsOpened, isEditMode } = this.state;
    const {
      details_firstName,
      details_lastName,
      details_email,
      details_phoneNumber,
      details_DOB,
      details_postcode
    } = this.state;

    const {
      isFirstNameCorrect,
      isLastNameCorrect,
      isEmailCorrect,
      isPhoneCorrect,
      isPostcodeCorrect,
      isDOBCorrect
    } = this.getFormValidation(form);

    const _keys = Object.keys(form);
    const isEditedFirstName = _keys.includes(formFields.firstName);
    const isEditedLastName = _keys.includes(formFields.lastName);
    const isEditedEmail = _keys.includes(formFields.email);
    const isEditedPhone = _keys.includes(formFields.phoneNumber);
    const isEditedPostcode = _keys.includes(formFields.postcode);
    const isEditedDOB = _keys.includes(formFields.DOB);
    const isTelephoneAppointment = appointmentType === 2;
    const isVideoAppointment = appointmentType === 1;

    const isEmailEmpty = isEditedEmail && (isTelephoneAppointment || isVideoAppointment) &&  form[formFields.email].length === 0;
    const isPhoneEmpty = isEditedPhone && (isTelephoneAppointment || isVideoAppointment) && form[formFields.phoneNumber].length === 0;

    const { firstName, lastName, dateOfBirth, postcode, email, phone } = patient;

    return (
      <div className="patient-care-details-wrap">
        <div className="patient-care-heading-row">
          <div
            onClick={this.handleToggleDetails}
            className="patient-care-appointment-opener"
          >
            <h3 className="patient-care-title-in-row">
              {locale.Appointment.customerDetails.title}
            </h3>
            <Icon
              type="inline"
              size="small"
              icon={
                isDetailsOpened ? (
                  <IconChevronDown outline={true} />
                ) : (
                  <IconChevronRight outline={true} />
                )
              }
            />
          </div>

          {status === locale.Appointment.status.booked.status &&
            isDetailsOpened &&
            !isBookedOnline && (
              <button
                className="patient-care-btn-link"
                onClick={
                  isEditMode
                    ? this.handleSelectViewMode
                    : this.handleSelectEditMode
                }
              >
                {isEditMode
                  ? locale.Appointment.customerDetails.buttonDone
                  : locale.Appointment.customerDetails.buttonEdit}
              </button>
            )}
        </div>

        {isDetailsOpened && !isEditMode && (
          <Fragment>
            <div className="patient-care-block-row">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="details_filled_firstName"
                      message={locale.Appointment.customerDetails.inputName}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {isEditedFirstName ? form.details_firstName : firstName}
                </span>
              </div>
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="details_filled_lastName"
                      message={
                        locale.Appointment.customerDetails.inputFamilyName
                      }
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {isEditedLastName ? form.details_lastName : lastName}
                </span>
              </div>
            </div>
            <div className="patient-care-block-row">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="details_filled_dob"
                      message={locale.Appointment.customerDetails.inputDOB}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {isEditedDOB
                    ? formatDOBToString(form.details_DOB)
                    : dateOfBirth
                    ? formatDOBToString(dateOfBirth)
                    : locale.Appointment.customerDetails.notProvided}
                </span>
              </div>
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="details_filled_postcode"
                      message={locale.Appointment.customerDetails.postcode}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {isEditedPostcode
                    ? form.details_postcode
                    : postcode
                    ? postcode
                    : locale.Appointment.customerDetails.notProvided}
                </span>
              </div>
            </div>
            <div className="patient-care-block-row">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="details_filled_phoneNumber"
                      message={
                        (isTelephoneAppointment || isVideoAppointment)
                          ? locale.Appointment.customerDetails.phoneNumberMandatory
                          : locale.Appointment.customerDetails.phoneNumber
                      }
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {isEditedPhone
                    ? form.details_phoneNumber
                    : phone
                    ? phone
                    : locale.Appointment.customerDetails.notProvided}
                </span>
              </div>
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="details_filled_email"
                      message={(isTelephoneAppointment || isVideoAppointment) ? locale.Appointment.customerDetails.emailMandatory :locale.Appointment.customerDetails.email}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {isEditedEmail
                    ? form.details_email
                    : email
                    ? email
                    : locale.Appointment.customerDetails.notProvided}
                </span>
              </div>
            </div>
          </Fragment>
        )}

        {isDetailsOpened && isEditMode && (
          <Form noValidate>
            <div className="patient-care-block-row">
              <div className="patient-care-col-6">
                <FormItem
                  type="item"
                  error={
                    !isFirstNameCorrect &&
                    !details_firstName &&
                    isEditedFirstName
                      ? "error"
                      : null
                  }
                >
                  <InputLabel
                    htmlFor={formFields.firstName}
                    message={locale.Appointment.customerDetails.inputName}
                    size="small"
                  />
                  <Input
                    type="text"
                    id={formFields.firstName}
                    name={formFields.firstName}
                    data-id={formFields.firstName}
                    defaultValue={
                      isEditedFirstName ? form.details_firstName : firstName
                    }
                    onChange={handleFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  {!isFirstNameCorrect &&
                    !details_firstName &&
                    isEditedFirstName && (
                      <InputError
                        message={
                          locale.Appointment.customerDetails.inputNameError
                        }
                      />
                    )}
                </FormItem>
              </div>
              <div className="patient-care-col-6">
                <FormItem
                  type="item"
                  error={
                    !isLastNameCorrect && !details_lastName && isEditedLastName
                      ? "error"
                      : null
                  }
                >
                  <InputLabel
                    htmlFor={formFields.lastName}
                    message={locale.Appointment.customerDetails.inputFamilyName}
                    size="small"
                  />
                  <Input
                    type="text"
                    id={formFields.lastName}
                    name={formFields.lastName}
                    data-id={formFields.lastName}
                    defaultValue={
                      isEditedLastName ? form.details_lastName : lastName
                    }
                    onChange={handleFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  {!isLastNameCorrect &&
                    !details_lastName &&
                    isEditedLastName && (
                      <InputError
                        message={
                          locale.Appointment.customerDetails
                            .inputFamilyNameError
                        }
                      />
                    )}
                </FormItem>
              </div>
            </div>
            <div className="patient-care-block-row">
              <div className="patient-care-col-6">
                <FormItem
                  type="item"
                  error={
                    !isDOBCorrect && !details_DOB && isEditedDOB
                      ? "error"
                      : null
                  }
                >
                  <InputLabel
                    htmlFor={formFields.DOB}
                    message={locale.Appointment.customerDetails.inputDOB}
                    size="small"
                  />
                  <Input
                    type="date"
                    id={formFields.DOB}
                    name={formFields.DOB}
                    data-id={formFields.DOB}
                    defaultValue={
                      isEditedDOB
                        ? form.details_DOB
                        : dateOfBirth
                        ? dateOfBirth.split("T")[0]
                        : ""
                    }
                    onChange={handleFormChange}
                  />
                  {!isDOBCorrect && !details_DOB && isEditedDOB && (
                    <InputError
                      message={locale.Appointment.customerDetails.inputDOBError}
                    />
                  )}
                </FormItem>
              </div>

              <div className="patient-care-col-6">
                <FormItem
                  type="item"
                  error={
                    !isPostcodeCorrect && !details_postcode && isEditedPostcode
                      ? "error"
                      : null
                  }
                >
                  <InputLabel
                    htmlFor={formFields.postcode}
                    message={locale.Appointment.customerDetails.postcode}
                    size="small"
                  />
                  <Input
                    type="text"
                    id={formFields.postcode}
                    name={formFields.postcode}
                    data-id={formFields.postcode}
                    defaultValue={
                      isEditedPostcode ? form.details_postcode : postcode
                    }
                    onChange={handleFormChange}
                  />
                  {!isPostcodeCorrect &&
                    !details_postcode &&
                    isEditedPostcode && (
                      <InputError
                        message={
                          locale.Appointment.customerDetails.inputPostcodeError
                        }
                      />
                    )}
                </FormItem>
              </div>
            </div>
            <div className="patient-care-block-row">
              <div className="patient-care-col-6">
                <FormItem
                  type="item"
                  error={
                    !isPhoneCorrect && !details_phoneNumber && isEditedPhone
                      ? "error"
                      : null
                  }
                >
                  <InputLabel
                    htmlFor={formFields.phoneNumber}
                    message={
                        (isTelephoneAppointment || isVideoAppointment)
                          ? locale.Appointment.customerDetails.phoneNumberMandatory
                          : locale.Appointment.customerDetails.phoneNumber
                      }
                    size="small"
                  />
                  <Input
                    type="text"
                    id={formFields.phoneNumber}
                    name={formFields.phoneNumber}
                    data-id={formFields.phoneNumber}
                    defaultValue={
                      isEditedPhone
                        ? form.details_phoneNumber
                        : phone
                        ? phone
                        : ""
                    }
                    onChange={handleFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  {!isPhoneCorrect && !details_phoneNumber && isEditedPhone && (
                    <InputError
                      message={
                        isPhoneEmpty 
                        ? locale.Appointment.customerDetails.phoneNumberEmptyError
                        : locale.Appointment.customerDetails.phoneNumberError
                      }
                    />
                  )}
                </FormItem>
              </div>
              <div className="patient-care-col-6">
                <FormItem
                  type="item"
                  error={
                    !isEmailCorrect && !details_email && isEditedEmail
                      ? "error"
                      : null
                  }
                >
                  <InputLabel
                    htmlFor={formFields.email}
                    message={(isTelephoneAppointment || isVideoAppointment) ? locale.Appointment.customerDetails.emailMandatory :locale.Appointment.customerDetails.email}
                    size="small"
                  />
                  <Input
                    type="text"
                    id={formFields.email}
                    name={formFields.email}
                    data-id={formFields.email}
                    defaultValue={isEditedEmail ? form.details_email : email}
                    onChange={handleFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  {!isEmailCorrect && !details_email && isEditedEmail && (
                    <InputError
                      message={ 
                        isEmailEmpty
                        ? locale.Appointment.customerDetails.emailEmptyError
                        : locale.Appointment.customerDetails.emailError}
                    />
                  )}
                </FormItem>
              </div>
            </div>
          </Form>
        )}
        <hr className="patient-care-separator" />
      </div>
    );
  }
}

export default connect(mapStateToProps)(CustomerDetails);
