import React, { Component } from "react";
import { connect } from "react-redux";
import { LinkIcon, IconClose } from "@patient-access/ui-kit";
import locale from "service/locale";

type Props = {
  form: Object;
  handleCloseModal: () => any;
  handleDiscardChanges: () => any;
  existingCalendarNamesList: any[];
  title: string;
  customClassName: string;
};

const mapStateToProps = state => ({
  form: state.form
});

class ModalHeader extends Component<Props> {

  render() {
    const {handleCloseModal, form, handleDiscardChanges, title, customClassName } = this.props;
    const isFormEdited = Object.keys(form).length > 2;
    return (
      <div className={`patient-care-modal-header ${customClassName}`}>
        <h2>{title}</h2>
        <div className="patient-care-btn-close">
          <LinkIcon
            to="#"
            size="small"
            icon={<IconClose outline />}
            accessibilitySpan={locale.AddNewCalendar.closeIcon}
            onClick={isFormEdited ? handleDiscardChanges : handleCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ModalHeader);
