import * as UsersConstants from 'constants/UsersConstants';
import type { Action } from 'types/actions';

type UsersListState = {
    data: any[],
    pages: number,
    pageNumber: number,
    searchValue: string,
    isLoading: boolean,
    isError: boolean,
}

const initialState: UsersListState = {
    data: [],
    pages: 0,
    pageNumber: 0,
    searchValue: "",
    isLoading: false,
    isError: false,
};

export default (localState: UsersListState = initialState, action: Action): UsersListState => {
    switch (action.type) {
        case UsersConstants.SET_USERS_LIST_DATA: {
            const { usersList, searchValue } = action.payload;
            const { items, pages, pageNumber } = usersList;
            return {
                ...localState,
                data: items,
                pages: pages === 0 ? localState.pages : pages,
                pageNumber: pageNumber,
                searchValue,
            };
        }
        case UsersConstants.GET_USERS_LIST_PENDING:
            return {
                ...localState,
                isLoading: true,
                isError: false,
            };
        case UsersConstants.GET_USERS_LIST_SUCCESS:
            return {
                ...localState,
                isLoading: false,
                isError: false,
            };
        case UsersConstants.GET_USERS_LIST_ERROR:
            return {
                ...localState,
                isLoading: false,
                isError: true,
            };
        default: {
            return localState;
        }
    }
}
