import React, { Component, Fragment } from 'react';

type Props = {
  outline: boolean,
};

class IconDragVerical extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <Fragment>
        <circle cx="21" cy="16.5" r="3" />
        <circle cx="12" cy="16.5" r="3" />
        <circle cx="3" cy="16.5" r="3" />
        <circle cx="3" cy="7.5" r="3" />
        <circle cx="12" cy="7.5" r="3" />
        <circle cx="21" cy="7.5" r="3" />
      </Fragment>
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-drag-vertical ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconDragVerical;
