import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { SkeletonDescription } from 'components/Share/skeleton';
import { setTaskManagerOverlayIsVisible, setBranchesOverlayIsVisible, retryTask, getTaskDetail } from 'actions/taskManager';
import locale from 'service/locale';

type Props = {
    setTaskManagerOverlayIsVisible: (isVisible: boolean) => any,
    setBranchesOverlayIsVisible: (isOverlayBranchesVisible: any) => any,
    isLoading: boolean,
    isError: boolean,
    retryTask: (taskId: any) => any,
    getTaskDetail: (taskId: any) => any,
    taskDetail: any,
    taskId: any,
};

const mapStateToProps = state => ({
    isLoading: state.taskManager.taskDetail.isLoading,
    isError: state.taskManager.taskDetail.isError,
    taskDetail: state.taskManager.taskDetail.data,
});

const mapDispatchToProps = (dispatch: any) => ({
    setTaskManagerOverlayIsVisible: (isVisible: boolean) => dispatch(setTaskManagerOverlayIsVisible(isVisible)),
    setBranchesOverlayIsVisible: (isOverlayBranchesVisible: boolean) => dispatch(setBranchesOverlayIsVisible(isOverlayBranchesVisible)),
    retryTask: (taskId: any) => dispatch(retryTask(taskId)),
    getTaskDetail: (taskId: any) => dispatch(getTaskDetail(taskId)),
});

class OverlayCancel extends Component<Props> {
    render() {
        const { setTaskManagerOverlayIsVisible, setBranchesOverlayIsVisible, isLoading, isError, taskDetail, retryTask, taskId, getTaskDetail } = this.props;
        if (isLoading) {
            return (
                <Fragment>
                    <SkeletonDescription />
                </Fragment>
            );
        }
        if (isError) {
            return (
                <Fragment>
                    <div className="overlay-confirmation-header">
                        <h1>{locale.TaskManager.header.errorTaskDetail}</h1>
                    </div>
                    <div className="overlay-confirmation-content overlay-content-task-manager">
                        <h4>{locale.TaskManager.error.generic}</h4>
                    </div>
                    <div className="overlay-confirmation-buttons">
                        <Button buttonType="blueline" messageKey={locale.TaskManager.button.close} defaultMessage={locale.TaskManager.button.close} onClick={() => { setTaskManagerOverlayIsVisible(false) }} />
                        <Button buttonType="secondary" messageKey={locale.TaskManager.button.tryAgain} defaultMessage={locale.TaskManager.button.tryAgain} onClick={() => { getTaskDetail(taskId); }} />
                    </div>
                </Fragment>
            );
        }
        var failedBranches = (taskDetail && taskDetail.output) ? taskDetail.output.filter(branch => branch.isSuccess === false) : [];
        return (
            <Fragment>
                <div className="overlay-confirmation-header">
                    <h1>{taskDetail.input.TaskName}</h1>
                </div>
                <div className="overlay-confirmation-content overlay-content-task-manager">
                    <h4>{locale.TaskManager.overlay.branchCancelledContentText(failedBranches.length)} </h4>
                    <h4><a href="#taks" onClick={(e) => { e.preventDefault(); setBranchesOverlayIsVisible(true) }}><strong>{locale.OrganisationServices.overlay.confirmation.viewAffectedBranches(failedBranches.length)}</strong></a></h4>
                </div>
                <div className="overlay-confirmation-buttons">
                    <Button buttonType="blueline" messageKey={locale.TaskManager.button.goBack} defaultMessage={locale.TaskManager.button.goBack} onClick={() => { setTaskManagerOverlayIsVisible(false) }} />
                    <Button buttonType="secondary" messageKey={locale.TaskManager.button.tryAgainPartial(failedBranches.length)} defaultMessage={locale.TaskManager.button.tryAgainPartial(failedBranches.length)} onClick={() => { retryTask(taskDetail.input.TaskId); setTaskManagerOverlayIsVisible(false); }} />
                </div>
            </Fragment>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayCancel);
