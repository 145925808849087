import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Pagination, NoResults } from "@patient-access/ui-kit";
import { Redirect, matchPath, withRouter} from 'react-router-dom';

import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';
import ConfirmationOverlayCommon from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import ConflictModal from "components/Share/ConflictModal/ConflictModal";
import AddUserModal from './AddUserModal/AddUserModal';
import UsersFilters from './UsersFilters/UsersFilters';
import UsersTab from "./UsersTab/UsersTab";
import { columns, UsersList } from './UsersList/UsersList';

import { clearForm } from "actions/form";
import { getFilteredUsersList, getCurrentPage } from 'actions/users';
import { getUserDetails } from 'actions/userDetails';
import { clearFilter, updateFilter } from 'actions/filter';
import { closeModalOverlay, closeDuplicateConflict, closeRoleConflict } from 'actions/panel';
import { getOrganisations } from 'actions/organisations';
import { getOrganisationDetails } from "actions/organisationDetails";
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentScroll, FeatureContentHeader, FeaturePagination } from 'components/Share/feature';
import { SkeletonTable } from 'components/Share/skeleton';
import UserDetailsModal from './UserDetailsModal/UserDetailsModal';
import AdminSidebar from "../AdminContent/AdminSidebar/AdminSidebar";
import OrganisationSidebar from "../OrganisationContent/OrganisationSidebar/OrganisationSidebar";
import BranchSidebar from "../BranchContent/BranchSidebar/BranchSidebar";

import Modal from "components/Share/Modal/Modal";
import AppointmentDetails from "components/Share/AppointmentDetails/AppointmentDetails";
import UsersBreadcrumbs from "./Breadcrumbs/UsersBreadcrumbs";

import type { Action } from 'types/actions';
import type { Filter } from 'types/filter';
import type { Organisation } from 'types/organisation';
import * as RolesConstants from 'constants/RolesConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as SearchConstants from 'constants/SearchConstants';
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import find from 'lodash.find';

import locale from 'service/locale';

type Props = {
  closeModalOverlay: () => Action,
  isModalOverlayOpened: boolean,
  clearForm: () => Action,
  currentRole: Object,
  getOrganisations: () => Action,
  organisations: Organisation[],
  getFilteredUsersList: (filter?: Filter) => Action,
  organisationId?: string,
  branchId?: string,
  filter: Filter,
  updateFilter: (data: any) => Action,
  getOrganisationDetails: (organisationId: string) => Organisation,
  isConfirmationOpened: boolean,
  getUserDetails: (userId: string, isCurrentUser?: boolean) => any,
  closeRoleConflict: () => Action,
  closeDuplicateConflictOverlay: () => Action,
  conflictUserId: string,
  isConfirmationDuplicateConflictOpened: boolean,
  usersList: any[],
  clearFilter: () => Action,
  pages: number,
  getCurrentPage: (pageNumber: number, filter?: Filter) => any,
  isLoading: boolean,
  isError: boolean,
  isActiveUserDetails: boolean,
  adminUrl: any,
  isActiveAgendaDetails: boolean,
}

type State = {
  isDiscardChangesOverlayOpen: boolean,
  organisations: Organisation[],
  tableData: any[];
};

const mapStateToProps = (state) => ({
  usersList: state.usersList.data,
  pages: state.usersList.pages,
  organisations: state.organisations.organisationsList,
  isModalOverlayOpened: state.panel.isModalOverlayOpened,
  isConfirmationDuplicateConflictOpened: state.panel.isConfirmationDuplicateConflictOpened,
  conflictUserId: state.panel.conflictUserId,
  currentRole: state.roles.profileCurrentRole,
  adminUrl: state.profile.adminUrl,
  filter: state.filter,
  isConfirmationOpened: state.panel.isConfirmationOpened,
  isError: state.usersList.isError,
  isLoading: state.usersList.isLoading,
  isActiveUserDetails: state.panel.isActiveUserDetails,
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails,
});

const mapDispatchToProps = (dispatch) => ({
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  clearForm: () => dispatch(clearForm()),
  getOrganisations: () => dispatch(getOrganisations()),
  getFilteredUsersList: (filter) => dispatch(getFilteredUsersList(filter)),
  updateFilter: (data) => dispatch(updateFilter(data)),
  closeDuplicateConflictOverlay: () => dispatch(closeDuplicateConflict()),
  getUserDetails: (userId, isCurrentUser) => dispatch(getUserDetails(userId, isCurrentUser)),
  getOrganisationDetails: (organisationId) => dispatch(getOrganisationDetails(organisationId)),
  closeRoleConflict: () => dispatch(closeRoleConflict()),
  clearFilter: () => dispatch(clearFilter()),
  getCurrentPage: (pageNumber, filter) => dispatch(getCurrentPage(pageNumber, filter)),
});

class AdminContent extends Component<Props, State> {

  state = {
    isDiscardChangesOverlayOpen: false,
    organisations: [],
    tableData: [],
  };

  componentDidMount = () => {
    const { getOrganisations, getOrganisationDetails, getFilteredUsersList, updateFilter, usersList, filter, organisationId, branchId } = this.props;
    (organisationId || branchId) && getOrganisationDetails(organisationId);
    !organisationId && getOrganisations();

    if (organisationId || branchId) {
      updateFilter({
        organizationId: organisationId,
        branchId,
      });
    } else {
      getFilteredUsersList(filter);
    }

    if (usersList.length > 0) {
      this.formatData(usersList);
    }
  };

  componentWillUnmount = () => {
    const { clearFilter } = this.props;
    clearFilter();
  }

  componentWillReceiveProps = (nextProps: Props): void => {
    if (nextProps.organisations.length) {
      this.setState({ organisations: nextProps.organisations });
    }

    const { usersList, filter } = this.props;
    const searchValue = nextProps.filter.pattern;
    if (filter.pattern !== searchValue) {
      if (searchValue && searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
        const { getFilteredUsersList } = nextProps;
        getFilteredUsersList(nextProps.filter);
      } else if (!searchValue || (searchValue && searchValue.length <= SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH && filter.pattern && 
          filter.pattern.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH)) {
        const { getFilteredUsersList } = nextProps;
        getFilteredUsersList(nextProps.filter);
      }
    }

    if (nextProps.filter && 
        (filter.role !== nextProps.filter.role || filter.organizationId !== nextProps.filter.organizationId || 
          filter.branchId !== nextProps.filter.branchId || filter.isMFAPhoneRegistered !== nextProps.filter.isMFAPhoneRegistered)
        ) {
      const { getFilteredUsersList } = nextProps;
      getFilteredUsersList(nextProps.filter);
    }

    if (nextProps.usersList !== usersList) {
      const { usersList } = nextProps;
      this.formatData(usersList);
    }
  };

  formatData = (usersList) => {
    let tableData = usersList.length ?
      usersList.map(user => {
        const { email, displayName, uniqueRoleCount, uniqueOrganizationCount, uniqueBranchCount, deleted: isDeleted, isMFAPhoneRegistered } = user;
        const accounts = user.accounts.length > 0 ? user.accounts : [{}];
        const isSingleRole = uniqueRoleCount === 1;
        const isSingleOrganization = uniqueOrganizationCount === 1;
        const isSingleBranch = uniqueBranchCount === 1;
        let role = isSingleRole
          ? find(RolesConstants.PROFILE_ROLES_LIST, { id: accounts[0].role }).label
          : uniqueRoleCount + locale.UserDetails.multipleRolesText;
        let organization = locale.UserDetails.allUserDescription;
        let branch = locale.UserDetails.allUserDescription;
        if (role === RolesConstants.PROFILE_ROLES_LIST[0].label) {
          return {
            displayName,
            userId: user.id,
            role,
            organization,
            branch,
            email,
            isMFAPhoneRegistered
          }
        }
        const organisationQuantity = isSingleOrganization
          ? accounts[0].organizationName
          : locale.UserDetails.multipleDescription;
        const branchQuantity = isSingleBranch && isSingleOrganization
          ? accounts[0].branchName
          : role === RolesConstants.PROFILE_ROLES_LIST[1].label
            ? locale.UserDetails.notAvailableDescription
            : locale.UserDetails.multipleDescription;
        role = isDeleted ? locale.UserDetails.deletedDescription : role;
        organization = isDeleted ? locale.UserDetails.deletedDescription : organisationQuantity || locale.UserDetails.notAvailableDescription;
        branch = isDeleted ? locale.UserDetails.deletedDescription : branchQuantity || locale.UserDetails.notAvailableDescription;
        return {
          displayName,
          userId: user.id,
          role,
          organization,
          branch,
          email,
          isMFAPhoneRegistered
        }
      }) : [];
    this.setState({ tableData });
  }

  handleDiscardChanges = () => {
    const { closeModalOverlay, clearForm } = this.props;

    this.setState({
      isDiscardChangesOverlayOpen: false
    }, () => {
      clearForm();
      closeModalOverlay();
    });
  };

  handleStay = () => {
    this.setState({ isDiscardChangesOverlayOpen: false });
  };

  openDiscardChangesOverlay = () => {
    this.setState({ isDiscardChangesOverlayOpen: true });
  };

  handleDuplicateConflictCancel = () => {
    const { closeDuplicateConflictOverlay } = this.props;
    closeDuplicateConflictOverlay();
  };

  handleDuplicateConfirmConfirm = () => {
    let { closeDuplicateConflictOverlay, conflictUserId, getUserDetails, closeModalOverlay } = this.props;

    getUserDetails(conflictUserId, false);
    closeDuplicateConflictOverlay();
    closeModalOverlay();
  };

  handleCloseRoleConflictModal = () => {
    const { closeRoleConflict } = this.props;
    closeRoleConflict();
  };

  render() {
    const { isDiscardChangesOverlayOpen, tableData } = this.state;
    const { isConfirmationDuplicateConflictOpened, isModalOverlayOpened, isActiveUserDetails, organisations, organisationId, branchId, isConfirmationOpened, pages, usersList, isLoading, isError, filter, currentRole, adminUrl, isActiveAgendaDetails } = this.props;

    const isEmptyData = usersList.length === 0;
    const isEmptySearch = !filter.pattern || filter.pattern.length === 0;
    const isEmptyUsers = isEmptyData && isEmptySearch && !isLoading;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrgAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    const isBranchAdmin = currentRole.role === RolesConstants.BRANCH_ADMIN;

    const currentLocation = this.props.location.pathname;
    const isSAUsersList = matchPath(currentLocation, {
      path: "/admin/users",
      exact: true,
      strict: false
    });
    const isOrgUsersList = matchPath(currentLocation, {
      path: "/admin/organisations/:orgId/users",
      exact: true,
      strict: false
    });
    const isBranchUsersList = matchPath(currentLocation, {
      path: "/admin/organisations/:orgId/:branchId/users",
      exact: true,
      strict: false
    });

    let hiddenColumns = [];
    if (isOrgUsersList) {
      hiddenColumns = ["organization"];
    } else if (isBranchUsersList) {
      hiddenColumns = ["organization", "branch"];
    }

    const renderSideBar = () => {
      if (isSAUsersList) {
        return (
          <FeatureMenu>
            <AdminSidebar />
          </FeatureMenu>
        );
      } else if (isOrgUsersList) {
        return (
          <FeatureMenu>
            <OrganisationSidebar organisationId={organisationId} />
          </FeatureMenu>
        );
      } else if (isBranchUsersList) {
        return (
          <FeatureMenu>
            <BranchSidebar organisationId={organisationId} branchId={branchId} />
          </FeatureMenu>
        );
      }
      return null;
    }

    const handlePagination = (e: any) => {
      const { getCurrentPage, filter } = this.props;
      getCurrentPage(e.selected + 1, filter);
    }

    const renderPagination = () => {
      if (pages > 1) {
        return (
          <FeaturePagination hidden={isLoading || isError || isEmptyData}>
            <Pagination pageCount={pages} onPageChange={handlePagination} />
          </FeaturePagination>
        );
      }
      return null;
    }

    const renderTableUsers = () => {
      if (isLoading) {
        return (
          <SkeletonTable className="spacing-medium" columns={6} isSticky={true} isGrey={true} />
        );
      }
      if (isEmptyData) {
        return (
          <NoResults type="info">
            <h2>{locale.UsersListRedesign.noResults.noUsersFilter}</h2>
          </NoResults>
        );
      }
      return <UsersList data={tableData} columns={columns} props={this.props} hiddenColumns={hiddenColumns} />;
    }

    const renderContent = () => {
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.UsersListRedesign.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_BRANCHES}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.UsersListRedesign.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmptyUsers) {
        return (
          <Fragment>
            <FeatureContentSticky className="smallest">
              <FeatureContentHeader className="no-spacing">
                <UsersFilters organisations={organisations} organisationId={organisationId} branchId={branchId} isOrgUsersList={isOrgUsersList} isBranchUsersList={isBranchUsersList}/>
              </FeatureContentHeader>
            </FeatureContentSticky>
            <FeatureContentScroll className="smallest" isGreyBackground={false}>
              <NoResults type="info">
                <h2>{locale.UsersListRedesign.noResults.noUsers}</h2>
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="no-spacing">
              <UsersFilters organisations={organisations} organisationId={organisationId} branchId={branchId} isOrgUsersList={isOrgUsersList} isBranchUsersList={isBranchUsersList} />
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableUsers()}
            {renderPagination()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    const redirectPage = () => {
      if (isOrgAdmin && isSAUsersList) {
        return (
          <Redirect to={adminUrl} />
        );
      } else if (isBranchAdmin && (isSAUsersList || isOrgUsersList)) {
        return (
          <Redirect to={adminUrl} />
        );
      }
    }

    if (isOrgAdmin || isBranchAdmin) {
      redirectPage();
    }

    return (
      <Feature>
        {redirectPage()}
        {renderSideBar()}
        {isActiveAgendaDetails && (
          <Modal>
            <AppointmentDetails
              type={
                AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
              }
            />
          </Modal>
        )}
        <FeatureContent>
          <UsersBreadcrumbs isSuperAdmin={isSuperAdmin} isOrgAdmin={isOrgAdmin} isOrgUsersList={isOrgUsersList} isBranchUsersList={isBranchUsersList} organisationId={organisationId} branchId={branchId}></UsersBreadcrumbs>
          <UsersTab></UsersTab>
          {renderContent()}
          {isModalOverlayOpened &&
            <AddUserModal
              handleDiscardChanges={this.openDiscardChangesOverlay}
              organisationId={organisationId}
              branchId={branchId}
            />
          }
          {isConfirmationDuplicateConflictOpened &&
            <ConfirmationOverlayCommon
              {...locale.Modals.duplicateConflictOverlay}
              handleCancel={this.handleDuplicateConflictCancel}
              handleConfirm={this.handleDuplicateConfirmConfirm} />
          }
          {isDiscardChangesOverlayOpen &&
            <DiscardChangesOverlay
              handleDiscardChanges={this.handleDiscardChanges}
              handleStay={this.handleStay}
            />
          }
          {isConfirmationOpened &&
            <ConflictModal
              title={locale.UserDetails.roleConflictTitle}
              body={locale.UserDetails.roleConflictBody}
              buttonText={locale.UserDetails.roleConflictButton}
              handleStay={this.handleCloseRoleConflictModal}
            />
          }
          {isActiveUserDetails && 
            <UserDetailsModal organisationId={organisationId} branchId={branchId} />
          }
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminContent));
