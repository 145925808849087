import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CalendarFilters, CalendarFiltersContent, CalendarFiltersFooter } from './wrappers'
import { ButtonScrollTop } from './button';
import SectionCalendars from './SectionCalendars/SectionCalendars';
import SectionStatuses from './SectionStatuses/SectionStatuses';
import SectionServices from './SectionServices/SectionServices';
import SectionSkeleton from './SectionSkeleton/SectionSkeleton';

type Props = {
  isActiveSearchResults: boolean,
  isActiveAppointmentSlots: boolean,
  isActiveBooking: boolean,
  isCalendarsPending: boolean,
}

const mapStateToProps = (state: any) => ({
  isActiveSearchResults: state.panel.isActiveSearchResults,
  isActiveAppointmentSlots: state.panel.isActiveAppointmentSlots,
  isActiveBooking: state.panel.isActiveBooking,
  isCalendarsPending: state.branchDetails.isCalendarsPending,
});

export class SidebarLeft extends Component<Props> {
  render() {
    const { isActiveSearchResults, isActiveAppointmentSlots, isActiveBooking, isCalendarsPending } = this.props;

    const renderContentNew = () => {
      if (isCalendarsPending) {
        return (
          <Fragment>
            <SectionSkeleton />
          </Fragment>
        );
      }
      return (
        <Fragment>
          <SectionCalendars />
          <SectionStatuses />
          <SectionServices />
        </Fragment>
      );
    }

    return (
      <CalendarFilters isHidden={(isActiveSearchResults || isActiveAppointmentSlots || isActiveBooking)}>
        <CalendarFiltersContent>
        {renderContentNew()}
        </CalendarFiltersContent>
        <CalendarFiltersFooter>
          <ButtonScrollTop />
        </CalendarFiltersFooter>
      </CalendarFilters>
    )
  }
}

export default connect(mapStateToProps)(SidebarLeft);
