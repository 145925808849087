import type { Action } from "types/actions";
import { getToken, modifyServicesList } from 'helpers/common';
import agent from 'service/agent';
import { showSnackbarStatus } from "actions/snackbar";
import * as DefaultServicesConstants from "constants/DefaultServicesConstants";
import locale from "service/locale"; 

export const setDefaultServiceDetails = (serviceId: string, data: any): Action => ({
  type: DefaultServicesConstants.SET_DEFAULT_SERVICE_DATA,
  payload: { serviceId, data }
});

export const setServicesList = (
  servicesList: any,
  searchValue ? : string
): Action => ({
  type: DefaultServicesConstants.SET_DEFAULT_SERVICE_LIST,
  payload: {
    servicesList,
    searchValue
  }
});

export const setServicesPending = (): Action => ({
  type: DefaultServicesConstants.SET_SERVICES_PENDING,
});

export const updateServicesPending = (): Action => ({
  type: DefaultServicesConstants.UPDATE_SERVICES_PENDING,
});

export const updateServicesSuccess = (): Action => ({
  type: DefaultServicesConstants.UPDATE_SERVICES_SUCCESS,
});

export const updateServicesError = (): Action => ({
  type: DefaultServicesConstants.UPDATE_SERVICES_ERROR,
});

export const getFilteredDefaultServices = (
  pageNumber: number,
  searchValue: string,
  organisationId ? : string,
  callback ? : Function,
) => (dispatch: Function) => {
  dispatch(setServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      const itemsPerPage = 500;
      agent.Organisations.getFilteredDefaultServices(
          pageNumber,
          searchValue,
          itemsPerPage,
          accessToken
        )
        .then(servicesList => {
          servicesList.items = modifyServicesList(servicesList);
          !callback && dispatch(setServicesList(servicesList, searchValue));
          callback && callback(servicesList.items);
        })
        .catch(err => {
          console.log("getFilteredDefaultServices server error", err);
        });
    });
};

export const updateDefaultServiceList = () => (dispatch: Function, getState: Function) => {
   const { defaultServices } = getState();
   const servicesList = defaultServices.services;
   const pageNumber = 1;
   const searchValue = "";
  dispatch(updateServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      return agent.Organisations.updateDefaultServicesList(servicesList, accessToken);
    })
    .then(() => {
      dispatch(updateServicesSuccess());
      dispatch(getFilteredDefaultServices(pageNumber, searchValue));
      dispatch(showSnackbarStatus(locale.Snackbar.defaultServiceListUpdated));
    })
    .catch(err => {
      dispatch(updateServicesError());
      console.log("updateDefaultServiceList server error", err);
      dispatch(showSnackbarStatus(locale.Snackbar.defaultServiceListNotUpdated));
    });
};