import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AADLoginContainer from 'components/Pages/Login/AAD/AADLogin/AADLoginContainer';
import AADLoginCallbackContainer from 'components/Pages/Login/AAD/AADLoginCallback/AADLoginCallbackContainer';
import AADForgotPasswrodCallbackContainer from 'components/Pages/Login/AAD/AADForgotPasswordCallback/AADForgotPasswrodCallbackContainer';
import AADChangePasswordCallbackContainer from 'components/Pages/Login/AAD/AADChangePasswordCallback/AADChangePasswordCallbackContainer';
import AADLogoutContainer from 'components/Pages/Login/AAD/AADLogout/AADLogoutContainer';
import NotFound from 'components/Pages/NotFound/NotFound';
import Password from 'components/Pages/Password/Password';
import Register from 'components/Pages/Register/Register';
import App from 'components/App/App';

import * as RoutesConstants from 'constants/RoutesConstants';

export default function Routes() {
  return (
    <Switch>
      <Route exact path={RoutesConstants.LOGIN} component={AADLoginContainer} />
      <Route exact path={RoutesConstants.LOGINCAllBACK} component={AADLoginCallbackContainer} />
      <Route exact path={RoutesConstants.FORGOTPASSWORDCAllBACK} component={AADForgotPasswrodCallbackContainer} />
      <Route exact path={RoutesConstants.CHANGEPASSWORDCAllBACK} component={AADChangePasswordCallbackContainer} />
      <Route exact path={RoutesConstants.LOGOUT} component={AADLogoutContainer} />
      <Route exact path={RoutesConstants.HOME} component={App} />
      <Route exact path={RoutesConstants.REGISTER} component={Register} />
      <Route path={RoutesConstants.REPORTS} component={App} />
      <Route path={RoutesConstants.ADMIN} component={App} />
      <Route path={RoutesConstants.CALENDAR} component={App} />
      <Route path={RoutesConstants.COMPLETE_REGISTRATION} component={(props) => <Password type="complete" {...props} />} />
      <Route path={RoutesConstants.RESET_PASSWORD} component={(props) => <Password type="reset" {...props} />} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
}
