import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import Modal from "components/Share/Modal/Modal";
import AppointmentDetails from "components/Share/AppointmentDetails/AppointmentDetails";
import BranchSidebar from './BranchSidebar/BranchSidebar';
import Breadcrumbs from 'components/Share/Breadcrumbs/Breadcrumbs';
import * as RolesConstants from 'constants/RolesConstants';

type Props = {
  children: any,
  isActiveAgenda: boolean,
  organisationId: string,
  branchId: string,
  currentRole: any,
  customClassName: string,
  isActiveAgendaDetails: boolean,
}

const mapStateToProps = (state) => ({
  isActiveAgenda: state.panel.isActiveAgenda,
  currentRole: state.roles.profileCurrentRole,
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails,
});

class BranchContent extends Component<Props> {
  render() {
    const { children, isActiveAgenda, organisationId, branchId, currentRole, isActiveAgendaDetails } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrgAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    const currentPage = children.type.WrappedComponent.name;

    return (
      <Fragment>
        <div className={`patient-care-main-holder ${isActiveAgenda ? "active" : ""}`}>
          {
            (isSuperAdmin || isOrgAdmin) &&
              <Breadcrumbs
                organisationId={organisationId}
                branchId={branchId}
                currentPage={currentPage}
              />
          }
          { children }
        </div>
        <BranchSidebar organisationId={organisationId} branchId={branchId} />
        {isActiveAgendaDetails && (
          <Modal>
            <AppointmentDetails
              type={
                AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
              }
            />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(BranchContent);
