import * as BranchServicesConstants from 'constants/BranchServicesConstants';

type BranchServices = {
  // TODO
};

const initialState: BranchServices = {
  servicesSummary: {
    data: [],
    isLoading: false,
    isError: false,
  },
  servicesList: {
    data: [],
    isLoading: false,
    isError: false,
  },
  servicesUpdate: {
    serviceFutureAppointments: {
      serviceId: null,
      listOfAppointments: []
    },
    isLoading: false,
    isError: false,
  },
  serviceDescription: {
    serviceId: '',
    data: [],
    isLoading: false,
    isError: false,
  },
  ui: {
    isSidebarAddVisible: false,
    isSidebarEditVisible: false,
    isOverlayDescriptionVisible: false,
    isOverlayRemoveServiceVisible: false,
  },
  user: {
    step: 1,
    search: {
      servicesSummary: '',
      servicesList: '',
    },
    data: {
      serviceId: '',
      serviceName: '',
      serviceAvailableModes: [],
      serviceIsNhsCovered: false,
      serviceModes: [],
      serviceIsActive: null,
      serviceIsShowPrice: null,
      servicePrice: null,
      serviceIsVatIncluded: null,
      serviceDuration: null,
      serviceCutOff: null,
      serviceBookingUrl: '',
    },
  },
};

export default (
  localState: BranchServices = initialState,
  action: Action
): BranchServices => {
  switch (action.type) {
    // UI
    case BranchServicesConstants.SET_BRANCH_SERVICES_UI:
      return {
        ...localState,
        ui: {
          ...localState.ui,
          ...action.payload,
        },
      };

    // USER
    case BranchServicesConstants.SET_BRANCH_SERVICES_USER_SEARCH:
      return {
        ...localState,
        user: {
          ...localState.user,
          search: {
            ...localState.user.search,
            ...action.payload,
          },
        },
      };
    case BranchServicesConstants.SET_BRANCH_SERVICES_USER_STEP:
      return {
        ...localState,
        user: {
          ...localState.user,
          step: action.payload,
        },
      };
    case BranchServicesConstants.SET_BRANCH_SERVICES_USER_DATA:
      return {
        ...localState,
        user: {
          ...localState.user,
          data: {
            ...localState.user.data,
            ...action.payload,
          },
        },
      };
    case BranchServicesConstants.RESET_BRANCH_SERVICES_USER_DATA:
      return {
        ...localState,
        user: {
          ...localState.user,
          data: {
            ...initialState.user.data,
          },
        },
      };

    // SERVICES_SUMMARY
    case BranchServicesConstants.GET_BRANCH_SERVICES_SUMMARY_PENDING:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          isLoading: true,
          isError: false,
        },
      };
    case BranchServicesConstants.GET_BRANCH_SERVICES_SUMMARY_SUCCESS:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          isLoading: false,
          isError: false,
        },
      };
    case BranchServicesConstants.GET_BRANCH_SERVICES_SUMMARY_ERROR:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          isLoading: false,
          isError: true,
        },
      };
    case BranchServicesConstants.SET_BRANCH_SERVICES_SUMMARY_DATA:
      return {
        ...localState,
        servicesSummary: {
          ...localState.servicesSummary,
          data: action.payload,
        },
      };

    // SERVICES_LIST
    case BranchServicesConstants.GET_BRANCH_SERVICES_LIST_PENDING:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          isLoading: true,
          isError: false,
        },
      };
    case BranchServicesConstants.GET_BRANCH_SERVICES_LIST_SUCCESS:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          isLoading: false,
          isError: false,
        },
      };
    case BranchServicesConstants.GET_BRANCH_SERVICES_LIST_ERROR:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          isLoading: false,
          isError: true,
        },
      };
    case BranchServicesConstants.SET_BRANCH_SERVICES_LIST_DATA:
      return {
        ...localState,
        servicesList: {
          ...localState.servicesList,
          data: action.payload,
        },
      };

    // SERVICES_UPDATE
    case BranchServicesConstants.PUT_BRANCH_SERVICES_UPDATE_PENDING:
      return {
        ...localState,
        servicesUpdate: {
          ...localState.servicesUpdate,
          isLoading: true,
          isError: false,
        },
      };
    case BranchServicesConstants.PUT_BRANCH_SERVICES_UPDATE_SUCCESS:
      return {
        ...localState,
        servicesUpdate: {
          ...localState.servicesUpdate,
          isLoading: false,
          isError: false,
        },
      };
    case BranchServicesConstants.PUT_BRANCH_SERVICES_UPDATE_ERROR:
      return {
        ...localState,
        servicesUpdate: {
          ...localState.servicesUpdate,
          isLoading: false,
          isError: true,
        },
      };

    // SERVICES_REMOVE
    case BranchServicesConstants.SET_SERVICE_FUTURE_APPOINTMENTS:
      return {
        ...localState,
        servicesUpdate: {
          ...localState.servicesUpdate,
          serviceFutureAppointments: {
            serviceId: action.payload.serviceId,
            listOfAppointments: action.payload.listOfAppointments
          },
        }
      };

    // SERVICE_DESCRIPTION
    case BranchServicesConstants.GET_BRANCH_SERVICES_DESCRIPTION_PENDING:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          isLoading: true,
          isError: false,
        },
      };
    case BranchServicesConstants.GET_BRANCH_SERVICES_DESCRIPTION_SUCCESS:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          isLoading: false,
          isError: false,
        },
      };
    case BranchServicesConstants.GET_BRANCH_SERVICES_DESCRIPTION_ERROR:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          isLoading: false,
          isError: true,
        },
      };
    case BranchServicesConstants.SET_BRANCH_SERVICES_DESCRIPTION_SERVICE_ID:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          serviceId: action.payload,
        },
      };
    case BranchServicesConstants.SET_BRANCH_SERVICES_DESCRIPTION_DATA:
      return {
        ...localState,
        serviceDescription: {
          ...localState.serviceDescription,
          data: action.payload,
        },
      };

    // DEFAULT
    default:
      return localState;
  }
};
