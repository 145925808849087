import React, { Component } from "react";
import { connect } from 'react-redux';

import SearchUsersForm from './SearchUsersForm/SearchUsersForm';
import OrganisationsFilter from './OrganizationsFilter/OrganizationsFilter';
import BranchFilter from './BranchFilter/BranchFilter';
import MFAFilter from './MFAFilter/MFAFilter';
import * as RolesConstants from 'constants/RolesConstants';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as BranchesConstants from 'constants/BranchesConstants';
import * as UsersConstants from 'constants/UsersConstants';
import { getFilteredUsersList } from 'actions/users';
import { updateFilter } from 'actions/filter';
import type { Action } from 'types/actions';
import type { Filter } from 'types/filter';
import type { Organisation, Branch } from 'types/organisation';

import './styles.scss';

type State = {
  selectedOrganisation: Organisation,
  selectedBranch: Branch,
  selectedMFAStatus: any
}

type Props = {
  organisations: Organisation[],
  currentRole: Object,
  getFilteredUsersList: (filter?: Filter) => Action,
  organisationId: string,
  branchId: string,
  filter: Filter,
  updateFilter: () => Action,
  organisationDetails: Organisation,
  isLowResolution: boolean,
  isOrgUsersList: string, 
  isBranchUsersList: string,
}

const mapStateToProps = (state: any) => ({
  currentRole: state.roles.profileCurrentRole,
  filter: state.filter,
  organisationDetails: state.organisationDetails,
  isLowResolution: state.panel.isLowResolution,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getFilteredUsersList: (filter?: Filter) => dispatch(getFilteredUsersList(filter)),
  updateFilter: (data: any) => dispatch(updateFilter(data)),
});

class UsersFilters extends Component<Props,State> {

  state = {
    selectedOrganisation: OrganisationsConstants.DROPDOWN_OPTION_DEFAULT,
    selectedBranch: BranchesConstants.DROPDOWN_OPTION_DEFAULT,
    selectedMFAStatus: UsersConstants.MFA_STATUS_FILTER_OPTIONS[0]
  };

  componentDidMount = () => {
    const { organisationId, branchId, organisationDetails } = this.props;
    if (organisationId || branchId) {
      this.setState({ selectedOrganisation: organisationDetails });
    }
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { organisationDetails } = nextProps;
    if (organisationDetails.organisationId) {
      this.setState({ selectedOrganisation: organisationDetails });
    }
  };

  handleSelectOrganisation = (organisation: Organisation) => {
    const { updateFilter } = this.props;

    this.setState({
      selectedOrganisation: organisation,
    }, () => {
      updateFilter({
        organizationId: organisation ? organisation.id : "",
        branchId: "",
      });
    });
  };

  handleSelectBranch = (branch: Branch) => {
    const { updateFilter } = this.props;
    const { selectedOrganisation } = this.state;

    this.setState({
      selectedBranch: branch,
    }, () => {
      updateFilter({
        organizationId: selectedOrganisation.id || selectedOrganisation.organisationId,
        branchId: branch ? branch.id : "",
      });
    });
  };

  handleSelectMFA = (twoFactorStatus: String) => {
    const { updateFilter, organisationId, branchId } = this.props;
    const { selectedOrganisation, selectedBranch } = this.state;

    this.setState({
      selectedMFAStatus: twoFactorStatus
    }, () => {
      updateFilter({
        organizationId: selectedOrganisation.id || organisationId,
        branchId: selectedBranch.id || branchId,
        isMFAPhoneRegistered: twoFactorStatus.value===1 ? false 
                              : twoFactorStatus.value===2 ? true
                              : null
      })
    });
  }

  render() {
    const { selectedOrganisation } = this.state;
    const { currentRole, organisationId, branchId, isLowResolution, isOrgUsersList, isBranchUsersList } = this.props;
    const isAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrganizationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;

    return (
        <div className="patient-care-user-filters-holder no-padding">
          <div className="patient-care-container-flex">
            <div className={
              !isLowResolution ? (
                organisationId || branchId ?
                  isOrganizationAdmin ?
                  "patient-care-col-9 small" :
                  "patient-care-col-9" :
                "patient-care-col-6"
              ) : (
                organisationId && branchId ?
                "patient-care-col-12" :
                organisationId || branchId ?
                "patient-care-col-8" :
                "patient-care-col-4"
              )
            }>
              <SearchUsersForm role={currentRole} isOrgUsersList={isOrgUsersList} isBranchUsersList={isBranchUsersList} />
            </div>
            {
              isAdmin && !organisationId && (
                <div className={
                  !isLowResolution ?
                  "patient-care-col-2" :
                  "patient-care-col-4"
                }>
                  <OrganisationsFilter handleSelectOrganisation={this.handleSelectOrganisation} />
                </div>
              )
            }
            {
              (isAdmin || isOrganizationAdmin) && !branchId &&
              <div className={
              isOrganizationAdmin ?
                "patient-care-col-4" :
                  !isLowResolution ?
                    "patient-care-col-2" :
                    "patient-care-col-4"
              }>
                <BranchFilter
                  selectedOrganisation={selectedOrganisation}
                  branches={selectedOrganisation.branches || []}
                  handleSelectBranch={this.handleSelectBranch}
                />
              </div>
            }
              <div className="patient-care-col-2">
                <MFAFilter 
                  handleSelectMFA={this.handleSelectMFA}
                />
              </div>
          </div>
        </div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilters);
