import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { Overlay, Button } from '@patient-access/ui-kit';
import { getReportsCustomOrganisations, getReportsCustomServices, setReportsCustomSelectedService } from 'actions/reportsCustom';
import { getServicesForAsyncSelect } from 'actions/services';
import locale from 'service/locale';
import './styles.scss';

type Props = {
  dataOrganisations: any,
  dataServices: any,
  isLoadingOrganisations: boolean,
  isErrorOrganisations: boolean,
  isLoadingServices: boolean,
  isErrorServices: boolean,
  selectedService: any,
  services: any,
  getReportsCustomOrganisations: () => Action,
  getReportsCustomServices: () => Action,
  setReportsCustomSelectedService: (selectedService: any) => Action,
  getServicesForAsyncSelect: (searchValue: string, organizationId?: string, branchId?: string, callback?: Function) => any,
};

type State = {
  isOverlayOrganisationsVisible: boolean,
  isOverlayServicesVisible: boolean,
};

const mapStateToProps = (state) => ({
  dataOrganisations: state.reportsCustom.organisations.data,
  dataServices: state.reportsCustom.services.data,
  isLoadingOrganisations: state.reportsCustom.organisations.isLoading,
  isErrorOrganisations: state.reportsCustom.organisations.isError,
  isLoadingServices: state.reportsCustom.services.isLoading,
  isErrorServices: state.reportsCustom.services.isError,
  selectedService: state.reportsCustom.services.selectedService,
  services: state.services.servicesListAsync,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getReportsCustomOrganisations: () => dispatch(getReportsCustomOrganisations()),
  getReportsCustomServices: () => dispatch(getReportsCustomServices()),
  setReportsCustomSelectedService: (selectedService) => dispatch(setReportsCustomSelectedService(selectedService)),
  getServicesForAsyncSelect: (searchValue, organizationId, branchId, callback) => dispatch(getServicesForAsyncSelect(searchValue, organizationId, branchId, callback)),
});

class Custom extends Component<Props, State> {
  state = {
    isOverlayOrganisationsVisible: false,
    isOverlayServicesVisible: false,
  }

  componentDidMount = () => {
    const {getServicesForAsyncSelect, setReportsCustomSelectedService} = this.props;
    getServicesForAsyncSelect();
    setReportsCustomSelectedService(null);
  };

  render() {
    const { isOverlayOrganisationsVisible, isOverlayServicesVisible } = this.state;
    const { dataOrganisations, dataServices, isLoadingOrganisations, isErrorOrganisations, isLoadingServices, isErrorServices, selectedService, services, getReportsCustomOrganisations, getReportsCustomServices } = this.props;

    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const isWin = navigator.platform.toUpperCase().indexOf('WIN') >= 0;
    const isFirefox = navigator.userAgent.toUpperCase().indexOf('FIREFOX') >= 0;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const orgHeaders = [
      { label: "organisationId", key: "organisationId" },
      { label: "organisationName", key: "organisationName" },
      { label: "branchId", key: "branchId" },
      { label: "branchName", key: "branchName" },
      { label: "odsCode", key: "odsCode" },
      { label: "addressLine1", key: "addressLine1" },
      { label: "addressLine2", key: "addressLine2" },
      { label: "postcode", key: "postcode" },
      { label: "added", key: "added" },
      { label: "isDraft", key: "isDraft" },
      { label: "visibility", key: "visibility" },
      { label: "stripeAccountId", key: "stripeAccountId" },
      { label: "isActive", key: "isActive" },
      { label: "isSmartPharmacy", key: "isSmartPharmacy" },
      { label: "usersCount", key: "usersCount" },
      { label: "calendarsCount", key: "calendarsCount" },
      { label: "servicesCount", key: "servicesCount" }
    ];
    const serviceHeaders = [
      { label: "organisationId", key: "organisationId" },
      { label: "organisationName", key: "organisationName" },
      { label: "branchId", key: "branchId" },
      { label: "branchName", key: "branchName" },
      { label: "careProviderId", key: "careProviderId" },
      { label: "odsCode", key: "odsCode" },
      { label: "serviceId", key: "serviceId" },
      { label: "serviceName", key: "serviceName" },
      { label: "price", key: "price" },
      { label: "vatIncluded", key: "vatIncluded" },
      { label: "status", key: "status" },
      { label: "duration", key: "duration" },
      { label: "branchCalenderCount", key: "branchCalenderCount" },
      { label: "isAddedToCalendar", key: "isAddedToCalendar" },
      { label: "serviceMode", key: "serviceMode" },
      { label: "lastMinute", key: "lastMinute" }
    ];

    const scvOrganisationsFilename = () => {
      var fileName = `Organisations_Reports`;
      if (isMac) {
        if (isFirefox || isSafari) return `${fileName}.csv`;
      }
      if (isWin) return `${fileName}.csv`;
      return `${fileName}`;
    }

    const scvServicesFilename = () => {
      var fileName = `Services_Reports`;
      if (isMac) {
        if (isFirefox || isSafari) return `${fileName}.csv`;
      }
      if (isWin) return `${fileName}.csv`;
      return `${fileName}`;
    }

    const renderServiceName = () => {
      if (selectedService && selectedService.length > 0) {
        return (
          <ul className="patient-care-unstyled-list">
            {selectedService.map(item => { return (<li key={item.id}>{item.name}</li>); })}
          </ul>
        );
      }
      return null;
    }

    const renderOverlayOrganisations = () => {
      return (
        <Overlay background="dark" type="confirmation" isOpen={isOverlayOrganisationsVisible} showCloseButton doClose={() => { this.setState({ isOverlayOrganisationsVisible: false }); }} >
          <div className="overlay-confirmation-header">
            <h1>Download Organisations Reports as CSV</h1>
          </div>
          <div className="overlay-confirmation-content reports-basic-download">
            <h4>You will download the current organisations reports by clicking on the Download button below:</h4>
          </div>
          <div className="overlay-confirmation-buttons reports-basic-download">
            <Button to="#back" buttonType="blueline" messageKey="some-key" defaultMessage="Back" onClick={(e) => { e.preventDefault(); this.setState({ isOverlayOrganisationsVisible: false }); }} isLoading={isLoadingOrganisations} isDisabled={isErrorOrganisations} />
            <CSVLink className={`ui-kit-button ui-kit-button-secondary ui-kit-button-medium ${(isLoadingOrganisations || isErrorOrganisations) ? 'ui-kit-button-loading' : ''}`} data={dataOrganisations} headers={orgHeaders} filename={scvOrganisationsFilename()} >
              {(isLoadingOrganisations || isErrorOrganisations) ? <em><span className="ui-kit-accessibility-span">{locale.Reports.loading}</span></em> : null}
              <span>{locale.Reports.buttonDownload}</span>
            </CSVLink>
          </div>
        </Overlay>
      );
    }

    const renderOverlayServices = () => {
      return (
        <Overlay background="dark" type="confirmation" isOpen={isOverlayServicesVisible} showCloseButton doClose={() => { this.setState({ isOverlayServicesVisible: false }); }} >
          <div className="overlay-confirmation-header">
            <h1>Download Services Reports as CSV</h1>
          </div>
          <div className="overlay-confirmation-content reports-basic-download">
            <h4>You will download the filtered service reports by clicking on the Download button below:</h4>
            <p><strong>{locale.Reports.downloadOverlay.serviceLabel}</strong></p>
            {renderServiceName()}
          </div>
          <div className="overlay-confirmation-buttons reports-basic-download">
            <Button to="#back" buttonType="blueline" messageKey="some-key" defaultMessage="Back" onClick={(e) => { e.preventDefault(); this.setState({ isOverlayServicesVisible: false }); }} isLoading={isLoadingServices} isDisabled={isErrorServices} />
            <CSVLink className={`ui-kit-button ui-kit-button-secondary ui-kit-button-medium ${(isLoadingServices || isErrorServices) ? 'ui-kit-button-loading' : ''}`} data={dataServices} headers={serviceHeaders} filename={scvServicesFilename()}>
              {(isLoadingServices || isErrorServices) ? <em><span className="ui-kit-accessibility-span">{locale.Reports.loading}</span></em> : null}
              <span>{locale.Reports.buttonDownload}</span>
            </CSVLink>
          </div>
        </Overlay>
      );
    }

    const handleServiceSelection = (service) => {
      const { setReportsCustomSelectedService } = this.props;
      setReportsCustomSelectedService(service);
    }

    const renderOrganisations = () => {
      return (
        <div className="reports-custom-item">
          <h3>Organisations Reports</h3>
          <p>You will download the current organisations reports by clicking on the button below:</p>
          <Button buttonType="secondary" messageKey="some-key" defaultMessage="Download as CSV" onClick={() => { this.setState({ isOverlayOrganisationsVisible: true }); getReportsCustomOrganisations(); }} />
        </div>
      );
    }

    const renderServices = () => {
      return (
        <div className="reports-custom-item">
          <h3>Services Reports</h3>
          <p>You will download the current services reports by selecting a service and clicking on the button below:</p>
          <h4>{locale.Reports.selectServiceHeader}</h4>
          <div className="reports-custom-filter-services">
            <Select
              id="reports_service_select"
              name="reports_service_select"
              placeholder="Select a service"
              options={services}
              classNamePrefix="patient-care"
              onChange={(service) => {handleServiceSelection(service);}}
              className="patient-care-select"
              isLoading={services.length <= 0}
              isMulti
            />
          </div>
          <Button buttonType="secondary" messageKey="some-key" defaultMessage="Download as CSV" isDisabled={!(selectedService && selectedService.length > 0)} onClick={() => { this.setState({ isOverlayServicesVisible: true }); getReportsCustomServices(); }} />
        </div>
      );
    }

    return (
      <div className="reports-content">
        <div className="reports-custom">
          {renderServices()}
          {renderOrganisations()}
        </div>
        {renderOverlayOrganisations()}
        {renderOverlayServices()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Custom);
