import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, FormItem, Checkbox } from "@patient-access/ui-kit";

import * as OrganisationsConstants from "constants/OrganisationsConstants";
import { updateForm } from "actions/form";
import locale from "service/locale";

type Props = {
  name: string,
  paymentMethod: number,
  form: any,
  updateForm: (data: any) => any,
  handlePayoutMethodEdit: (status: boolean) => any,
};

type State = {
  paymentMethod: number,
  changeMode: boolean,
};

const mapStateToProps = (state) => ({
  form: state.form,
  paymentMethod: state.organisationDetails && state.organisationDetails.payment && state.organisationDetails.payment.paymentMethod
});

const mapDispatchToProps = (dispatch) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

const checkPaymentMethod = (prop: number | undefined): boolean => {
  return prop ? OrganisationsConstants.PAYMENT_METHOD[prop].value : OrganisationsConstants.PAYMENT_METHOD[1].value;
};

class PayoutMethod extends Component<Props, State> {

  state = {
    paymentMethod: checkPaymentMethod(this.props.paymentMethod),
    changeMode: false,
  };

  handleChangeEditMode = () => {
    const { handlePayoutMethodEdit } = this.props;
    const { changeMode } = this.state;
    this.setState(
      {
        changeMode: !changeMode,
      },
      handlePayoutMethodEdit(!changeMode)
    );
  };

  handleChangePayoutMethod = (e: Event) => {
    const {
      target: { checked }
    } = e;
    const { updateForm, form } = this.props;

    this.setState({
      paymentMethod: checked
    }, () => {
      updateForm({
        ...form,
        paymentMethod: checked
      });
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const { paymentMethod } = this.props;

    if (paymentMethod !== nextProps.paymentMethod) {
      this.setState({
        paymentMethod: checkPaymentMethod(nextProps.paymentMethod)
      });
    }
  };

  render() {
    const { name } = this.props;
    const { changeMode, paymentMethod } = this.state;
    const label = (
      <React.Fragment>
        {locale.PayoutDetails.payoutMethodLabel}
        <b>{name}</b>
      </React.Fragment>
    );

    return (
      <Fragment>
        <div className="patient-care-block-margin-medium">
          <h3>
            {locale.PayoutDetails.payoutMethod}
          </h3>
        </div>
        <div className="patient-care-description-block">
          <p>
            {locale.PayoutDetails.payoutMethodDescription4}
          </p>
        </div>
        <div className="patient-care-block-margin-medium">
          <div className="patient-care-row">
            <div className="patient-care-col-11">
              <Form noValidate>
                <FormItem type="item">
                  <Checkbox
                    id={locale.PayoutDetails.onlinePayment}
                    name={locale.PayoutDetails.onlinePayment}
                    value={name || ""}
                    checked={paymentMethod}
                    onChange={this.handleChangePayoutMethod}
                    label={label}
                    disabled={!changeMode}
                    data-id={locale.PayoutDetails.onlinePayment}
                    className="input-checkbox-large horisontal-centered"
                  />
                </FormItem>
              </Form>
            </div>
            <div className="patient-care-col-1">
              <button
                className="patient-care-btn-link"
                onClick={this.handleChangeEditMode}
              >
                { changeMode
                  ? locale.Buttons.buttonDone
                  : locale.Buttons.buttonEdit
                }
              </button>
            </div>
          </div>
        </div>
        <div className="patient-care-description-block">
          <p>
            {locale.PayoutDetails.payoutMethodDescription5}
          </p>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutMethod);
