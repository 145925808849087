import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FeatureSidebar } from 'components/Share/feature';
import { setUserStep, setUI, resetUserData } from 'actions/branchServices';
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import DiscardChangesOverlay from 'components/Share/DiscardChangesOverlay/DiscardChangesOverlay';

type State = {
  organisationId: string,
  branchId: string,
  isOverlayVisible: boolean,
};

type Props = {
  userStep: any,
  userData: any,
  isSidebarAddVisible: boolean,
  setUserStep: (value: any) => any,
  setUI: (value: any) => any,
  resetUserData: () => any,
};

const mapStateToProps = (state) => ({
  userStep: state.branchServices.user.step,
  userData: state.branchServices.user.data,
  isSidebarAddVisible: state.branchServices.ui.isSidebarAddVisible,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserData: () => dispatch(resetUserData()),
});

class SidebarAddService extends Component<Props, State> {
  state = {
    isOverlayVisible: false,
  };

  render() {
    const { organisationId, branchId, userStep, userData, isSidebarAddVisible, setUserStep, setUI, resetUserData } = this.props;
    const { isOverlayVisible } = this.state;

    const handleStay = () => {
      this.setState({ isOverlayVisible: false });
    }

    const handleDiscard = () => {
      this.setState({ isOverlayVisible: false }, () => {
        setUI({ isSidebarAddVisible: false });
        setUserStep(1);
        resetUserData();
      });
    }

    const handleClose = () => {
      if (userData.serviceId) {
        this.setState({ isOverlayVisible: true });
      } else {
        setUI({ isSidebarAddVisible: false });
      }
    }

    const renderContent = () => {
      switch (userStep) {
        case 1:
          return <StepOne handleClose={handleClose} />;
        case 2:
          return <StepTwo organisationId={organisationId} branchId={branchId} />;

        default:
          return null;
      }
    }

    const renderOverlay = () => {
      if (isOverlayVisible) {
        return <DiscardChangesOverlay handleDiscardChanges={handleDiscard} handleStay={handleStay} />;
      }
      return null;
    }

    if (isSidebarAddVisible) {
      return (
        <Fragment>
          <FeatureSidebar isOpen={true} doClose={handleClose}>
            {renderContent()}
          </FeatureSidebar>
          {renderOverlay()}
        </Fragment>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAddService);
