export const OPEN_AGENDA = "OPEN_AGENDA";
export const CLOSE_AGENDA = "CLOSE_AGENDA";
export const OPEN_SEARCH = "OPEN_SEARCH";
export const CLOSE_SEARCH = "CLOSE_SEARCH";
export const COMPLETE_SEARCH = "COMPLETE_SEARCH";
export const TOGGLE_AGENDA = "TOGGLE_AGENDA";
export const OPEN_SLOTS = "OPEN_SLOTS";
export const CLOSE_SLOTS = "CLOSE_SLOTS";
export const OPEN_BOOKING = "OPEN_BOOKING";
export const CLOSE_BOOKING = "CLOSE_BOOKING";
export const OPEN_ADD_USER = "OPEN_ADD_USER";
export const CLOSE_ADD_USER = "CLOSE_ADD_USER";
export const OPEN_USER_DETAILS = "OPEN_USER_DETAILS";
export const CLOSE_USER_DETAILS = "CLOSE_USER_DETAILS";
export const OPEN_AGENDA_DETAILS = "OPEN_AGENDA_DETAILS";
export const CLOSE_AGENDA_DETAILS = "CLOSE_AGENDA_DETAILS";
export const OPEN_SEARCH_DETAILS = "OPEN_SEARCH_DETAILS";
export const CLOSE_SEARCH_DETAILS = "CLOSE_SEARCH_DETAILS";
export const OPEN_ADD_ORGANISATION = "OPEN_ADD_ORGANISATION";
export const CLOSE_ADD_ORGANISATION = "CLOSE_ADD_ORGANISATION";
export const OPEN_ADD_ORGANISATION_ERROR = "OPEN_ADD_ORGANISATION_ERROR";
export const CLOSE_ADD_ORGANISATION_ERROR = "CLOSE_ADD_ORGANISATION_ERROR";
export const OPEN_ADD_BRANCH_ERROR = "OPEN_ADD_BRANCH_ERROR";
export const CLOSE_ADD_BRANCH_ERROR = "CLOSE_ADD_BRANCH_ERROR";
export const OPEN_ADD_BRANCH_NAME_ERROR = "OPEN_ADD_BRANCH_NAME_ERROR";
export const CLOSE_ADD_BRANCH_NAME_ERROR = "CLOSE_ADD_BRANCH_NAME_ERROR";
export const OPEN_PROFILE = "OPEN_PROFILE";
export const CLOSE_PROFILE = "CLOSE_PROFILE";
export const OPEN_MODAL_OVERLAY = "OPEN_MODAL_OVERLAY";
export const CLOSE_MODAL_OVERLAY = "CLOSE_MODAL_OVERLAY";
export const OPEN_CHANGE_PASSWORD = "OPEN_CHANGE_PASSWORD";
export const CLOSE_CHANGE_PASSWORD = "CLOSE_CHANGE_PASSWORD";
export const OPEN_PROFILE_DROPDOWN = "OPEN_PROFILE_DROPDOWN";
export const CLOSE_PROFILE_DROPDOWN = "CLOSE_PROFILE_DROPDOWN";
export const OPEN_CONFIRMATION_DELETE_USER = "OPEN_CONFIRMATION_DELETE_USER";
export const CLOSE_CONFIRMATION_DELETE_USER = "CLOSE_CONFIRMATION_DELETE_USER";
export const OPEN_ROLE_CONFLICT = "OPEN_ROLE_CONFLICT";
export const CLOSE_ROLE_CONFLICT = "CLOSE_ROLE_CONFLICT";
export const OPEN_DUPLICATE_CONFLICT = "OPEN_DUPLICATE_CONFLICT";
export const CLOSE_DUPLICATE_CONFLICT = "CLOSE_DUPLICATE_CONFLICT";
export const OPEN_DELETE_ORGANIZATION_CONFLICT = "OPEN_DELETE_ORGANIZATION_CONFLICT";
export const CLOSE_DELETE_ORGANIZATION_CONFLICT = "CLOSE_DELETE_ORGANIZATION_CONFLICT";
export const OPEN_CALENDAR_NAME_CONFLICT = "OPEN_CALENDAR_NAME_CONFLICT";
export const CLOSE_CALENDAR_NAME_CONFLICT = "CLOSE_CALENDAR_NAME_CONFLICT";
export const OPEN_CALENDAR_AVAILABILITY_CONFLICT = "OPEN_CALENDAR_AVAILABILITY_CONFLICT";
export const CLOSE_CALENDAR_AVAILABILITY_CONFLICT = "CLOSE_CALENDAR_AVAILABILITY_CONFLICT";
export const OPEN_CALENDAR_MODAL_OVERLAY = "OPEN_CALENDAR_MODAL_OVERLAY";
export const CLOSE_CALENDAR_MODAL_OVERLAY = "CLOSE_CALENDAR_MODAL_OVERLAY";
export const OPEN_BRANCH_ERROR_DUPLICATED_ODS = "OPEN_BRANCH_ERROR_DUPLICATED_ODS";
export const CLOSE_BRANCH_ERROR_DUPLICATED_ODS = "CLOSE_BRANCH_ERROR_DUPLICATED_ODS";
export const OPEN_BRANCH_ERROR_DUPLICATED_ROOM = "OPEN_BRANCH_ERROR_DUPLICATED_ROOM";
export const CLOSE_BRANCH_ERROR_DUPLICATED_ROOM = "CLOSE_BRANCH_ERROR_DUPLICATED_ROOM";
export const THROW_GENERAL_ERROR = "THROW_GENERAL_ERROR";
export const RESET_GENERAL_ERROR = "RESET_GENERAL_ERROR";
export const OPEN_APPOINTMENT_DETAILS_FROM_AGENDA = "OPEN_APPOINTMENT_DETAILS_FROM_AGENDA";
export const CLOSE_APPOINTMENT_DETAILS_FROM_AGENDA = "CLOSE_APPOINTMENT_DETAILS_FROM_AGENDA";
export const OPEN_DISCARD_CHANGES  = "OPEN_DISCARD_CHANGES";
export const CLOSE_DISCARD_CHANGES = "CLOSE_DISCARD_CHANGES";
export const OPEN_CALENDAR_DETAILS = "OPEN_CALENDAR_DETAILS";
export const CLOSE_CALENDAR_DETAILS = "CLOSE_CALENDAR_DETAILS";
export const SET_SCREEN_WIDTH = "SET_SCREEN_WIDTH";
export const OPEN_ADD_BRANCH_ODS_ERROR = "OPEN_ADD_BRANCH_ODS_ERROR";
export const CLOSE_ADD_BRANCH_ODS_ERROR = "CLOSE_ADD_BRANCH_ODS_ERROR";
export const OPEN_INTERNAL_EVENT = "OPEN_INTERNAL_EVENT";
export const CLOSE_INTERNAL_EVENT = "CLOSE_INTERNAL_EVENT";
export const OPEN_INTERNAL_EVENT_DETAILS = "OPEN_INTERNAL_EVENT_DETAILS";
export const CLOSE_INTERNAL_EVENT_DETAILS = "CLOSE_INTERNAL_EVENT_DETAILS";
export const OPEN_REMOVE_TWO_FACTOR_OVERLAY = "OPEN_REMOVE_TWO_FACTOR_OVERLAY"
export const CLOSE_REMOVE_TWO_FACTOR_OVERLAY = "CLOSE_REMOVE_TWO_FACTOR_OVERLAY"

export const LOW_RESOLUTION_POINT = 1100;
