import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { LogoPatientAccessPro, IconStar, IconHelp, Loader } from '@patient-access/ui-kit';
import * as RolesConstants from "constants/RolesConstants";
import locale from 'service/locale';
import './styles.scss';

type Props = {
  isAuth: boolean,
  errorCode: any,
}

const mapStateToProps = (state): any => ({
  isAuth: state.login.isAuth,
  errorCode: state.login.errorCode,
});

class NoRolesError extends Component<Props> {

  renderDescription = () => {
    return (
      <div className="patient-care-norole-content-wrapper">
        <div className="patient-care-norole-content-card">
          <h3>{locale.Login.noRolesErrorHeader}</h3>
          <div className="patient-care-norole-content-description">
            <p>{locale.Login.noRolesErrorDescription[0]}</p>
            <p>{locale.Login.noRolesErrorDescription[1]}</p>
          </div>
        </div>
        <div className="patient-care-norole-content-navigation">
          <p><a href="https://pro.patientaccess.com/login">Go back to sign in</a></p>
        </div>
      </div>
    );
  }

  renderHeader = () => {
    return (
      <div className="patient-care-header-container">
        <div className="patient-care-norole-header-logo">
          <a href="https://pro.patientaccess.com/"><LogoPatientAccessPro /></a>
        </div>
        <div className="patient-care-norole-header-navigation">
          <ul>
            <li><a href="https://pro.patientaccess.com/register/"><IconStar outline={false} /><span>Contact sales</span></a></li>
            <li><a href="https://supportpro.patientaccess.com/" ><IconHelp outline={true} /><span>Support centre</span></a></li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { isAuth, errorCode } = this.props;

    return (
      <Fragment>
        <header className="patient-care-header">
          {this.renderHeader()}
        </header>
        <div className="patient-care-norole-content">
          <div className="norole-content-container">
            {(!isAuth && errorCode && errorCode === RolesConstants.NO_ROLE_ERROR.errorCode) ? this.renderDescription() : <Loader size="small" type="inline" />}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(NoRolesError);
