import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Icon, IconSortUp, IconSortDown, NoResults, Form, FormItem, Checkbox } from "@patient-access/ui-kit";
import type { Action } from 'types/actions';
import { clearNewBranchServiceList, addNewBranchService } from 'actions/services';
import NewServiceItem from 'components/Share/NewServiceItem/NewServiceItem';
import ViewServiceDescriptionOverlay from "components/Share/ViewServiceDescriptionOverlay/ViewServiceDescriptionOverlay";
import * as ServiceConstants from "constants/ServicesConstants";

import locale from 'service/locale';

type Props = {
  branchServices: any[];
  currentRole: any;
  branchId: string;
  addNewBranchService: (service: Object) => Action;
  clearNewBranchServiceList: () => Action;
  defaultChecked: boolean;
};

type State = {
  isViewServiceDescriptionOpened: Boolean,
  sorting: boolean;
  defaultChecked: boolean;
  branchServices: any[];
  serviceId: String;
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  branchServices: state.branchDetails.services,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  addNewBranchService: (service) => dispatch(addNewBranchService(service)),
  clearNewBranchServiceList: () => dispatch(clearNewBranchServiceList()),
});

class AddNewCalendarServices extends Component<Props, State> {

  state = {
    sorting: false,
    defaultChecked: this.props.defaultChecked,
    branchServices: this.props.branchServices,
    isViewServiceDescriptionOpened: false,
    serviceId: ""
  };

  componentDidMount = () => {
    const { branchServices, addNewBranchService, clearNewBranchServiceList } = this.props;
    clearNewBranchServiceList();
    branchServices.forEach(service => { addNewBranchService(service) });
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (this.props.branchServices !== nextProps.branchServices) {
      const { addNewBranchService, branchServices, clearNewBranchServiceList } = this.props;

      if(nextProps.branchServices.length && branchServices.length){
        clearNewBranchServiceList();
        nextProps.branchServices.forEach((service) => {
          addNewBranchService(service);
        });
      }
      else if (!branchServices.length && nextProps.branchServices.length && branchServices.length !== nextProps.branchServices.length) {
        nextProps.branchServices.forEach((service) => {
          addNewBranchService(service);
        });
      } else if (!nextProps.branchServices.length) {
        clearNewBranchServiceList();
      }
      this.setState({
        branchServices: nextProps.branchServices
      });
    }
  };

  sortServicesList = (el1, el2) => {
    const { sorting } = this.state;
    if (el1.name > el2.name) {
      return sorting ? -1 : 1;
    }
    if (el1.name < el2.name) {
      return sorting ? 1 : -1;
    }
    return 0;
  };

  handleChangeSortingOrder = () => {
    this.setState(prevState => {
      return {
        sorting: !prevState.sorting
      }
    })
  };

  handleSelectionClick = (e: any) => {
    const { defaultChecked, branchServices } = this.state;
    const { addNewBranchService, clearNewBranchServiceList } = this.props;
    if (defaultChecked === false) {
      branchServices.forEach(service => service["selected"] = true);
    } else {
      branchServices.forEach(service => service["selected"] = false);
    }
    this.setState({
      defaultChecked: !defaultChecked,
      branchServices: branchServices
    }, () => {
      !defaultChecked
        ? branchServices.forEach(service => { addNewBranchService(service); })
        : clearNewBranchServiceList();
    });
  };


  onCheckHandler = (service: Object, checkedStatus: boolean, serviceIndex: number) => {
    const { branchServices } = this.state;
    branchServices[serviceIndex]['selected'] = checkedStatus;
    this.setState({
      // @info: check all of the branchServices have `selected` property with value `true`
      defaultChecked: branchServices.every(service => service['selected'] === true),
      branchServices: branchServices
    });
  };

  handleViewDescription = (serviceId: string) => {
    this.setState(
      {
        isViewServiceDescriptionOpened: true,
        serviceId: serviceId
      });
  }

  handleCloseOverlay = () => {
    this.setState({ isViewServiceDescriptionOpened: false });
  }

  render() {
    const { branchServices, isViewServiceDescriptionOpened, serviceId } = this.state;
    const { sorting, defaultChecked } = this.state;
    const branchServiceList = branchServices.sort(this.sortServicesList).filter(service => (!(service.id === ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID) && service.isServiceQueuedForRemove===false));
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddNewCalendar.servicesTitle}</h3>
          <p>{locale.AddNewCalendar.servicesDescription}</p>
        </div>

        {branchServiceList.length
          ? (
            <div className="patient-care-modal-table-block">
              <div className="patient-care-custom-table">
                <div className="patient-care-table-header-with-checkbox">
                  <Form noValidate>
                    <FormItem type="item">
                      <Checkbox
                        id="select_all"
                        name="select_all"
                        label=""
                        value={locale.AddNewCalendar.checkboxValue}
                        checked={defaultChecked}
                        onChange={this.handleSelectionClick}
                        data-id="select_all"
                        className="patient-care-checkbox-col-header"
                      />
                    </FormItem>
                  </Form>
                  <span className="patient-care-link" onClick={this.handleChangeSortingOrder}>
                    {locale.AddNewCalendar.colHeader} {sorting ? (
                      <Icon
                        size="smaller"
                        icon={<IconSortUp outline />}
                        type="inline"
                      />
                    ) : (
                        <Icon
                          size="smaller"
                          icon={<IconSortDown outline />}
                          type="inline"
                        />
                      )}
                  </span>
                </div>
              </div>
              <ul className="patient-care-unstyled-list">
                {
                  branchServiceList
                    .sort(this.sortServicesList)
                    .map((service, index) =>
                      <NewServiceItem
                        key={service.id}
                        service={service}
                        defaultChecked={service.selected}
                        onCheckHandler={this.onCheckHandler}
                        serviceIndex={index}
                        handleViewDescription={this.handleViewDescription}
                      />
                    )
                }
              </ul>
            </div>
          )
          : <div className="patient-care-empty-block">
            <NoResults type="info">
              <h3>{locale.AddNewCalendar.emptyListTitle}</h3>
              <p>{locale.AddNewCalendar.emptyListDescription}</p>
            </NoResults>
          </div>
        }
        <hr className="patient-care-separator" />

        {
          isViewServiceDescriptionOpened && (
            <ViewServiceDescriptionOverlay
              serviceId={serviceId}
              handleCloseOverlay={() => this.handleCloseOverlay()}
            />
          )
        }
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCalendarServices);
