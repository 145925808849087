import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconTask extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="M13.17,2 C13.6411111,2 14.0964198,2.16592593 14.4516461,2.46266118 L14.58,2.58 L19.41,7.41 C19.7477778,7.74777778 19.9512346,8.1882716 19.9922771,8.65422497 L20,8.83 L20,20 C20,21.05 19.1799587,21.9177686 18.1486946,21.9944872 L18,22 L5.99,22 C4.94,22 4.08134298,21.1799587 4.00545267,20.1486946 L4,20 L4.01,4 C4.01,2.95 4.82092975,2.0822314 5.85136551,2.00551277 L6,2 L13.17,2 Z M13,3.999 L6.00999961,4.00125 L6,20 L18,20 L17.999,9 L14,9 C13.45,9 13,8.55 13,8 L13,3.999 Z M15.7859581,11.5469231 L15.88,11.63 C16.27,12.02 16.27,12.65 15.88,13.04 L11.64,17.28 C11.26,17.68 10.62,17.68 10.23,17.29 L8.11,15.17 C7.75,14.81 7.72230769,14.245503 8.02692308,13.8540419 L8.11,13.76 C8.5,13.37 9.13,13.37 9.52,13.76 L10.93,15.17 L14.47,11.63 C14.83,11.27 15.394497,11.2423077 15.7859581,11.5469231 Z" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-task ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconTask;
