import { combineReducers } from "redux";

import ProfileReducer from './ProfileReducer';
import PanelReducer from './PanelReducer';
import AppointmentsReducer from './AppointmentsReducer';
import CalendarsReducer from './CalendarsReducer';
import OrganisationsReducer from './OrganisationsReducer';
import OrganisationDetailsReducer from './OrganisationDetailsReducer';
import BranchesReducer from './BranchesReducer';
import BranchDetailsReducer from './BranchDetailsReducer';
import AppointmentDetailsReducer from './AppointmentDetailsReducer';
import SearchAppointmentsReducer from './SearchAppointmentsReducer';
import BookAppointmentReducer from './BookAppointmentReducer';
import FilterReducer from './FilterReducer';
import FormReducer from './FormReducer';
import SnackbarReducer from './SnackbarReducer';
import UsersReducer from './UsersReducer';
import UsersListReducer from './UsersListReducer';
import UserDetailsReducer from './UserDetailsReducer';
import RoomsReducer from './RoomsReducer';
import LoginReducer from './LoginReducer';
import RolesReducer from './RolesReducer';
import ServicesReducer from './ServicesReducer';
import CalendarDetailsReducer from './CalendarDetailsReducer';
import CalendarViewReducer from './CalendarViewReducer';
import RouterReducer from './RouterReducer';
import AgendaReducer from './AgendaReducer';
import CalendarDropdownReducer from './CalendarDropdownReducer';
import CalendarSearchReducer from './CalendarSearchReducer';
import ReportsReducer from './ReportsReducer';
import DefaultServicesReducer from './DefaultServicesReducer';
import EnabledServicesReducer from './EnabledServicesReducer';
import AvailabilityRulesReducer from './AvailabilityRulesReducer';
import OrganisationSupportedFeaturesReducer from './OrganisationSupportedFeaturesReducer';
import OrganisationServicesReducer from './OrganisationServicesReducer';
import TaskManagerReducer from './TaskManagerReducer';
import ReportsSmartPharmacyReducer from "./ReportsSmartPharmacyReducer";
import BranchServicesReducer from './BranchServicesReducer';
import BranchesListReducer from './BranchesListReducer';
import OrganisationsListReducer from './OrganisationsListReducer';
import CalendarsListReducer from './CalendarsListReducer';
import ReportsCustomReducer from './ReportsCustomReducer';

export default combineReducers({
  login: LoginReducer,
  profile: ProfileReducer,
  roles: RolesReducer,
  panel: PanelReducer,
  taskManager: TaskManagerReducer,
  calendar: CalendarsReducer,
  calendarDetails: CalendarDetailsReducer,
  calendarDropdown: CalendarDropdownReducer,
  calendarView: CalendarViewReducer,
  calendarSearch: CalendarSearchReducer,
  calendarsList: CalendarsListReducer,
  appointments: AppointmentsReducer,
  appointmentDetails: AppointmentDetailsReducer,
  organisations: OrganisationsReducer,
  organisationsList: OrganisationsListReducer,
  organisationDetails: OrganisationDetailsReducer,
  organisationSupportedFeatures: OrganisationSupportedFeaturesReducer,
  organisationServices: OrganisationServicesReducer,
  branches: BranchesReducer,
  branchesList: BranchesListReducer,
  branchDetails: BranchDetailsReducer,
  branchServices: BranchServicesReducer,
  services: ServicesReducer,
  rooms: RoomsReducer,
  users: UsersReducer,
  usersList: UsersListReducer,
  userDetails: UserDetailsReducer,
  search: SearchAppointmentsReducer,
  book: BookAppointmentReducer,
  filter: FilterReducer,
  snackbar: SnackbarReducer,
  form: FormReducer,
  router: RouterReducer,
  agenda: AgendaReducer,
  reportsBasic: ReportsReducer,
  reportsSmartPharmacy: ReportsSmartPharmacyReducer,
  reportsCustom: ReportsCustomReducer,
  defaultServices: DefaultServicesReducer,
  enabledServices: EnabledServicesReducer,
  availabilityRules: AvailabilityRulesReducer,
});
