import type {
  Action
} from "types/actions";
import agent from "service/agent";
import * as BranchesConstants from "constants/BranchesConstants";
import * as CalendarsConstants from "constants/CalendarsConstants";
import {
  CALENDAR_VIEWS
} from "constants/AppointmentConstants";
import {
  clearForm
} from "actions/form";
import {
  openAddBranchError,
  openAddBranchOdsError,
  openAddBranchNameError,
  closeModalOverlay
} from "actions/panel";
import {
  setOpenHours,
  setBookingCutoff,
  setClosedDays,
  setBlockedDays
} from "actions/calendars";
import {
  getAppointmentsError,
  getAppointmentsPending,
  getAppointmentsSuccess
} from "actions/appointments";
import {
  setEvents,
  setResources,
  setTimeInterval,
  setBranchResources,
  setCalendarList
} from "actions/calendarView";
import {
  showSnackbarStatus
} from "actions/snackbar";
import {
  setBranchCalendars,
  setBranchConfirmationStatus,
  setBranchServices,
  setCalendarsPending,
  setCalendarsPendingSuccess,
  setCalendarsPendingError,
  setServicesPending,
  setServicesPendingSuccess,
  setServicesPendingError
} from 'actions/branchDetails';
import type {
  Branch
} from "types/organisation";
import {
  // getAppointmentsListFromAllCalendars,
  getAllItemsListFromAllCalendars,
  getToken,
  // getAppointmentsListForCalendarView,
  getAllItemsListForCalendarView,
  getMinMaxBranchOpenHours,
  modifyBranchesList,
  getCalendarResources,
  getBlockedDays,
  getCalendarListView,
} from "helpers/common";
import {
  formatDate,
  formatOpeningHours,
  formatOpeningHoursRevert
} from "helpers/formatData";
import {
  checkDuplicateError,
  checkOdsDuplicateError,
  checkDuplicateNameError
} from "helpers/validateData";
import {
  setOrganisationHeader,
  setBranchHeader
} from "actions/router";
import locale from "service/locale";
import moment from 'moment';

export const setBranchesPending = (): Action => ({
  type: BranchesConstants.SET_BRANCHES_PENDING,
});

export const setBranchesPPLPending = (): Action => ({
  type: BranchesConstants.SET_BRANCHESPPL_PENDING,
});

export const createBranchPending = (): Action => ({
  type: BranchesConstants.CREATE_BRANCH_PENDING,
});

export const createBranchSuccess = (): Action => ({
  type: BranchesConstants.CREATE_BRANCH_SUCCESS,
});

export const createBranchError = (): Action => ({
  type: BranchesConstants.CREATE_BRANCH_ERROR,
});

export const deleteBranchPending = (): Action => ({
  type: BranchesConstants.DELETE_BRANCH_PENDING,
});

export const deleteBranchSuccess = (): Action => ({
  type: BranchesConstants.DELETE_BRANCH_SUCCESS,
});

export const deleteBranchError = (): Action => ({
  type: BranchesConstants.DELETE_BRANCH_ERROR,
});

export const setPendingAppointmentsCount = (pendingAppointmentsCount: number): Action => ({
  type: BranchesConstants.SET_PENDING_APPOINTMENTS_COUNT,
  payload: pendingAppointmentsCount
});

export const setDisplayBranchPendingAlert = (pendingAlertPage: string, branchId: string): Action => ({
  type: BranchesConstants.SET_DISPLAY_PENDING_ALERT, 
  payload: {
    disableAlertBranchId: branchId,
    disableAlertPage: pendingAlertPage
  }
});

export const setDisplayPendingAppointmentList = (isDisplayPendingAppointmentList: boolean): Action => ({
  type: BranchesConstants.SET_DISPLAY_PENDING_APPOINTMENT_LIST, 
  payload: isDisplayPendingAppointmentList
});

const setBranchesListForAsyncSelect = (branchesList: Branch[], searchValue ? : string): Action => ({
  type: BranchesConstants.SET_BRANCHES_LIST_ASYNC,
  payload: {
    branchesListAsync: branchesList,
    searchValueAsync: searchValue
  }
});

export const setBranchesList = (
  branchesList: Branch[],
  searchValue ? : string
): Action => ({
  type: BranchesConstants.SET_BRANCHES_LIST,
  payload: {
    branchesList,
    searchValue
  }
});

export const setPPLBranchesList = (
  PPLBranchesList: Branch[],
  pplSearchValue ? : string
): Action => ({
  type: BranchesConstants.SET_BRANCHES_PPL_LIST,
  payload: {
    PPLBranchesList,
    pplSearchValue
  }
});

export const redirectToBranchSettings = (newOrganisationId: string, newBranchId: string, isRedirect: boolean): Action => ({
  type: BranchesConstants.REDIRECT_TO_BRANCH_SETTINGS,
  payload: {
    newOrganisationId,
    newBranchId,
    isRedirect
  }
});

export const removeRedirect = (): Action => ({
  type: BranchesConstants.REMOVE_REDIRECT,
});

export const getBranches = (organisationId ? : string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.getBranches(
          accessToken,
          organisationId
        )
        .then(branchesList => {
          branchesList.items = modifyBranchesList(branchesList);
          dispatch(setBranchesList(branchesList));
        })
        .catch(err => {
          console.log("getBranches server error or branch was not found", err);
        });
    });

};

export const getFilteredBranches = (
  pageNumber: number,
  searchValue: string,
  organisationId ? : string,
  callback ? : Function,
) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchesPending());
  getToken(dispatch)
    .then(accessToken => {
      const itemsPerPage = 30;
      agent.Branches.getFilteredBranches(
          pageNumber,
          searchValue,
          itemsPerPage,
          accessToken,
          organisationId
        )
        .then(branchesList => {
          branchesList.items = modifyBranchesList(branchesList);
          !callback && dispatch(setBranchesList(branchesList, searchValue));
          callback && callback(branchesList.items);
        })
        .catch(err => {
          console.log("getFilteredBranches server error or branch was not found", err);
        });
    });

};

export const getFilteredBranchesPPL = (searchValue: string, callback: Function) => (dispatch: Function) => {
  dispatch(setBranchesPPLPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.getFilteredBranchesPPL(searchValue, accessToken)
        .then(searchResults => {
          const {
            items
          } = searchResults;

          items.forEach(branch => {
            const {
              name,
              address
            } = branch;
            branch.label = `${name} ${address.postcode ? `(${address.postcode})` : ""}`;
            branch.value = name;
          });
          dispatch(setPPLBranchesList(items, searchValue));
          callback(items);
        })
        .catch(err => {
          console.log("getFilteredBranchesPPL ", err);
          callback();
        });
    });
};

export const getBranchCalendarsAppointments = () => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const branchId = currentState.router.branchId;
  const currentViewPeriod = currentState.calendarView.currentView;
  const currentDate = currentState.calendarView.currentDate;
  const isShowCancellation = currentState.calendarView.isShowCancellation;
  const calendarFilters = currentState.calendarView.calendarFilters;
  const serviceFilters = currentState.calendarView.serviceFilters;
  const statusFilters = currentState.calendarView.statusFilters;
  const includeCustom = true;
  dispatch(getAppointmentsPending());
  dispatch(setCalendarsPending());
  dispatch(setServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      if (branchId !== BranchesConstants.DEFAULT_BRANCHID) {
        const currentDateDate = moment.utc(currentDate).toISOString();
        const startTime = moment.utc(currentDateDate).startOf(currentViewPeriod + "s").toISOString();
        const isMonthView = currentViewPeriod === CALENDAR_VIEWS.MONTH;
        const endTime = isMonthView ?
          moment.utc(currentDateDate).endOf(currentViewPeriod + "s").toISOString() :
          moment.utc(currentDateDate).add(1, currentViewPeriod + "s").toISOString();
        Promise.all(
            [
              agent.Branches.getBranchDetails(branchId, accessToken, includeCustom),
              agent.Calendars.getBranchCalendars(branchId, accessToken),
              agent.Appointments.getAllItemsForCalendar(branchId, startTime, endTime, accessToken),
              agent.Calendars.getBranchAvailability(branchId, accessToken)
            ]
          )
          .then((
            [
              branchDetails,
              {
                items: branchCalendarsList
              },
              {
                allItemsByDate
              },
              {
                openHoursModel,
                closedDaysModel,
                cutOffModel
              }
            ]
          ) => {
            Promise.all(branchCalendarsList.map(calendar => agent.Calendars.getCalendarWithParameter(calendar.id, accessToken, '?includeInternalEvents=false')))
              .then(calendarsList => {
                const openingHours = formatOpeningHoursRevert(openHoursModel.days);
                return ({
                  branchDetails,
                  branchCalendarsList,
                  formattedAppointmentsList: getAllItemsListFromAllCalendars(allItemsByDate),
                  openHours: openingHours,
                  branchTimeInterval: getMinMaxBranchOpenHours(openHoursModel.days),
                  closedDays: closedDaysModel.days,
                  cutOff: cutOffModel,
                  calendarResourcesAll: getCalendarResources(branchCalendarsList, calendarsList),
                  calendarResources: getCalendarResources(branchCalendarsList, calendarsList)
                    .filter(resource => calendarFilters.indexOf(resource.resourceId) === -1),
                  blockedDays: getBlockedDays(startTime, endTime, calendarsList, currentViewPeriod, openingHours, closedDaysModel.days)
                })
              })
              .then(({
                branchDetails,
                branchCalendarsList,
                formattedAppointmentsList,
                branchTimeInterval,
                closedDays,
                openHours,
                cutOff,
                calendarResourcesAll,
                calendarResources,
                blockedDays
              }) => {

                if (cutOff) {
                  cutOff.advance = CalendarsConstants.AdvancedOptions.find(
                    option => option.value === cutOff.advance
                  ) || CalendarsConstants.AdvancedOptions[3];
                }
                dispatch(setBlockedDays(blockedDays));
                dispatch(setBookingCutoff(cutOff));
                dispatch(setOpenHours(openHours));
                dispatch(setClosedDays(closedDays));
                dispatch(setTimeInterval(branchTimeInterval.minStartTime, branchTimeInterval.maxEndTime));
                dispatch(setBranchServices(branchDetails.services));
                dispatch(setBranchCalendars(branchCalendarsList));
                dispatch(setBranchResources(calendarResourcesAll));
                dispatch(setResources(calendarResources));
                dispatch(setEvents(getAllItemsListForCalendarView(formattedAppointmentsList, isShowCancellation, calendarFilters, serviceFilters, statusFilters, branchCalendarsList)));
                dispatch(getAppointmentsSuccess());
                dispatch(setCalendarsPendingSuccess());
                dispatch(setServicesPendingSuccess());

              });
          })
          .catch(err => {
            dispatch(getAppointmentsError());
            dispatch(setCalendarsPendingError());
            dispatch(setServicesPendingError());
            console.log("getAppointmentsForCalendar server error ", err);
          })
      }
    })

};

export const getPendingAppointments = (pendingAlertPage: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  let branchId = currentState.router.branchId;
  let pendingAppointmentsCount = 0;
  let isDisableAlert = currentState.branches.disabledCalendarAlertBranches && currentState.branches.disabledCalendarAlertBranches.some(str => str.includes(branchId));
  let appointmentsListForCalendar = [];
  if (pendingAlertPage === BranchesConstants.PENDING_ALERT_PAGE.REPORT) {
      branchId = currentState.reportsBasic.filters.branchIds.length === 1 ? currentState.reportsBasic.filters.branchIds[0] : null;
      isDisableAlert = currentState.branches.disabledReportAlertBranches && currentState.branches.disabledReportAlertBranches.some(str => str.includes(branchId));
      var organisationId = currentState.branches.branchesListAsync.find(branch => branch.id === branchId)?.organizationId;
      organisationId && dispatch(setOrganisationHeader(organisationId));
      branchId && dispatch(setBranchHeader(branchId));
  }
  if (!isDisableAlert && branchId) {
      const endDate = moment.utc().toISOString();
      const startDate = moment.utc(endDate).subtract(12, 'months').toISOString();
      let formattedStartDate = formatDate(startDate);
      getToken(dispatch)
          .then(accessToken => {
              agent.Appointments.getPendingAppointments(branchId, startDate, endDate, accessToken, BranchesConstants.PENDING_APPOINTMENT, true)
                  .then(appointment => {
                      let appointments = appointment.allItemsByDate.filter(n => n.date >= formattedStartDate);
                      appointmentsListForCalendar = getCalendarListView(appointments);
                      appointmentsListForCalendar.forEach(item => {
                          pendingAppointmentsCount += item.appointments.length;
                      });
                      if (pendingAlertPage === BranchesConstants.PENDING_ALERT_PAGE.REPORT) {
                          pendingAppointmentsCount = currentState.reportsBasic.filters.branchIds.length === 1 ? pendingAppointmentsCount : 0;
                      }
                      dispatch(setPendingAppointmentsCount(pendingAppointmentsCount));
                      dispatch(setCalendarList(appointmentsListForCalendar));
                  })
                  .catch(err => {
                      console.log('getting pending appointments details error', err);
                  });
          });
  } else {
      dispatch(setPendingAppointmentsCount(pendingAppointmentsCount));
  }
};

export const disableBranchPendingAppointmentAlert = (pendingAlertPage: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const pendingAppointmentsCount = 0;
  let branchId = currentState.router.branchId;
  dispatch(setDisplayBranchPendingAlert(pendingAlertPage, branchId));
  dispatch(setPendingAppointmentsCount(pendingAppointmentsCount));
};

export const createBranch = (form: any, redirect: boolean, organisationId?: string, param?: any) => (dispatch: Function) => {
  dispatch(createBranchPending());
  getToken(dispatch)
    .then(accessToken => {
      if (Object.keys(form).includes("email") && form.email.length === 0) form.email = null;
      agent.Branches.createBranch(form, accessToken)
        .then(branchId => {
          let {
            openTimes
          } = form;

          if (!openTimes || !openTimes.length) {
            openTimes = [];
          }

          dispatch(setBranchHeader(branchId));
          agent.Calendars.updateBranchAvailability({
              openHours: formatOpeningHours(openTimes),
              closedDays: [],
            }, branchId, accessToken)
            .then(() => {
              dispatch(getFilteredBranches(1, "", organisationId));
              dispatch(closeModalOverlay());
              dispatch(clearForm());
              dispatch(showSnackbarStatus(locale.Snackbar.createBranch));
              dispatch(redirectToBranchSettings(form.organisationId, branchId, redirect));
              dispatch(createBranchSuccess());
              if (param) {
                if(organisationId){
                    param(1,'', organisationId);
                }
                else
                param(1, ''); // getBranchesList call
              }
            })
            .catch(err => {
              dispatch(createBranchError());
              console.log('Updating availability settings for new branch error: ', err);
            })
        })
        .catch(err => {
          dispatch(createBranchError());

          if (checkDuplicateError(err)) {
            dispatch(openAddBranchError());
            return;
          }

          if (checkDuplicateNameError(err)) {
            dispatch(openAddBranchError());
            dispatch(openAddBranchNameError());
            return;
          }

          if (checkOdsDuplicateError(err)) {
            dispatch(openAddBranchOdsError());
            return;
          }

          console.log("createBranch error ", err.response && err.response.body);
          dispatch(closeModalOverlay());
          dispatch(clearForm());
        });
    });

};

export const proceedPasswordConfirmationError = (err: Object, message: string, dispatch: Function) => {
  if (err && err.response) {
    const isLastAttempt = err.response.body.NextUnsuccessfulAttemptWillLockUser;
    if (err.response.body.ErrorCode === 2)
      dispatch(
        setBranchConfirmationStatus(
          BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.BLOCKED
        )
      );
    else
      dispatch(
        setBranchConfirmationStatus(
          isLastAttempt ?
          BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.LAST_ATTEMPT :
          BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.WRONG
        )
      );
  } else {
    console.log(message, err);
  }
};

export const getFutureAppointmentsForBranch = (branchId: string) => (
  dispatch: Function
) => {
  getToken(dispatch).then(accessToken => {
    agent.Appointments.getFutureAppointments(
        branchId,
        moment()
        .add(1, "minutes")
        .format(),
        accessToken
      )
      .then(futureAppointmentsList => {
        const isNotAllAppointmentsCancelled =
          futureAppointmentsList.appointmentsByDate
          .some(date => date.appointments.find(appointment => appointment.status !== locale.Appointment.status.cancelled.status));
        dispatch(
          setBranchConfirmationStatus(
            isNotAllAppointmentsCancelled ?
            BranchesConstants.PASSWORD_WARNING_STATUSES.HAS_BOOKINGS :
            BranchesConstants.PASSWORD_WARNING_STATUSES.NO_BOOKINGS
          )
        );
      })
      .catch(err => {
        console.log("appointment search server error", err);
      });
  });
};

export const deleteBranch = (branchId: string) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING));
  const currentState = getState();

  dispatch(deleteBranchPending());
  getToken(dispatch)
    .then(accessToken => {
          agent.Branches.deleteBranch(branchId, accessToken)
            .then(() => {
              const removedBranchIndex = currentState.branches.branchesList.findIndex(branch => branch.id === branchId);
              const newBranchesList = [
                ...currentState.branches.branchesList.slice(0, removedBranchIndex),
                ...currentState.branches.branchesList.slice(removedBranchIndex + 1)
              ];
              dispatch(deleteBranchSuccess());
              dispatch(setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED));
              dispatch(setBranchesList(newBranchesList));
              dispatch(showSnackbarStatus(locale.Snackbar.branchDeleted));
            })
            .catch(err => {
              dispatch(deleteBranchError());
              if (err && err.response && err.response.body && err.response.body.result && err.response.body.result.length > 0) {
                dispatch(setBranchConfirmationStatus(BranchesConstants.PASSWORD_WARNING_STATUSES.HAS_UNCOMPLETED));
              } else {
                dispatch(setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED));
                dispatch(showSnackbarStatus(locale.Snackbar.branchNotDeleted));
              }
            });
        });
};

export const getBranchesForAsyncSelect = (
  searchValue: string = "",
  organisationId ? : string,
  callback ? : Function,
) => (dispatch: Function) => {
  dispatch(setBranchesPending());
  getToken(dispatch)
    .then(accessToken => {
      const pageNumber = 1;
      const itemsPerPage = 999;
      agent.Branches.getFilteredBranches(
          pageNumber,
          searchValue,
          itemsPerPage,
          accessToken,
          organisationId
        )
        .then(branchesList => {
          branchesList.items = modifyBranchesList(branchesList);
          !callback && dispatch(setBranchesListForAsyncSelect(branchesList, (searchValue || "")));
          callback && callback(branchesList.items);
        })
        .catch(err => {
          console.log("getBranchesForAsyncSelect server error or branch was not found", err);
        });
    });
};

export const setOnlineBranchOverlayVisibility = (isOverlayVisible: boolean): Action => ({
  type: BranchesConstants.SET_ONLINE_BRANCH_OVERLAY_VISIBILITY,
  payload: isOverlayVisible,
});

export const setOnlineBranchOverlayCreateLater = (isCreateBranchLater: boolean): Action => ({
  type: BranchesConstants.SET_ONLINE_BRANCH_OVERLAY_CREATE_LATER,
  payload: isCreateBranchLater,
});
