import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Skeleton, NoResults } from "@patient-access/ui-kit";
import * as RolesConstants from "constants/RolesConstants";
import DefaultServicesFilter from "./DefaultServicesFilter/DefaultServicesFilter";
import {
  getFilteredDefaultServices,
  updateDefaultServiceList,
} from "actions/defaultServices";
import FloatingFooterButtons from "components/Share/FloatingFooterButtons/FloatingFooterButtons";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import { clearForm } from "actions/form";
import isEqual from "lodash.isequal";

import DefaultServicesTableBody from "./DefaultServicesTable/DefaultServicesTableBody";
import DefaultServicesTableHeader from "./DefaultServicesTable/DefaultServicesTableHeader";
import AdminSidebar from "../AdminSidebar/AdminSidebar";

import {
  Feature,
  FeatureMenu,
  FeatureContent,
  FeatureContentSticky,
  FeatureContentScroll,
  FeatureAgenda,
} from "components/Share/feature";

import "./styles.scss";
import locale from 'service/locale';

type Props = {
  currentRole: any,
  services: any[],
  servicesLoadedInitially: any[],
  isServicesPending: boolean,
  isLoading: boolean,
  searchValue: string,
  clearForm: () => Action,
  getFilteredDefaultServices: (page: number, searchValue: string) => any,
  updateDefaultServiceList: () => Action,
};

type State = {
  redirectUrl: string,
  isEditMode: boolean,
  isDataChanged: boolean,
  isSorting: boolean,
  defaultChecked: boolean
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  services: state.defaultServices.services,
  servicesLoadedInitially: state.defaultServices.servicesLoadedInitially,
  isLoading: state.defaultServices.isUpdateServicesPending,
  isServicesPending: state.defaultServices.isServicesPending,
  searchValue: state.defaultServices.searchValue,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  clearForm: () => dispatch(clearForm()),
  getFilteredDefaultServices: (page: number, searchValue: string) =>
    dispatch(getFilteredDefaultServices(page, searchValue)),
  updateDefaultServiceList: () => dispatch(updateDefaultServiceList()),
});

class DefaultServices extends Component<Props, State> {
  state = {
    redirectUrl: "#",
    isEditMode: false,
    isDataChanged: false,
    isSorting: false,
    defaultChecked: false
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { services, organisationType } = this.props;
    if (
      services !== nextProps.services ||
      organisationType !== nextProps.organisationType
    ) {
      const services = nextProps.services;
      const servicesLoadedInitially = nextProps.servicesLoadedInitially;
      this._dataChangeUpdate(services, servicesLoadedInitially);
    }
  };

  componentDidMount = () => {
    const { getFilteredDefaultServices } = this.props;
    const pageNumber = 1;
    const searchValue = "";
    getFilteredDefaultServices(pageNumber, searchValue);
  };

  handleChangeMode = (e: any) => {
    const { isEditMode } = this.state;
    if (isEditMode) {
      let isDataChecked = true;
      this.setState({
        isEditMode: !isDataChecked,
      });
    } else {
      this.setState({ isEditMode: true });
    }
  };

  handleDiscardChanges = () => {
    const {
      clearForm,
      getFilteredDefaultServices
    } = this.props;
    this.setState({
      isDiscardChangesOverlayOpen: false,
      isDataChanged: false,
      isEditMode: false,
    }, () => {
      const pageNumber = 1;
      const searchValue = "";
      clearForm();
      getFilteredDefaultServices(pageNumber, searchValue);
    }
    );
  };

  handleStay = () => {
    this.setState({ isDiscardChangesOverlayOpen: false });
  };

  openDiscardChangesOverlay = () => {
    this.setState({ isDiscardChangesOverlayOpen: true });
  };

  _dataChangeUpdate = (services, servicesLoadedInitially) => {
    this.setState({
      isDataChanged: !isEqual(
        services.sort(this.alphabetizeList),
        servicesLoadedInitially.sort(this.alphabetizeList)
      ),
    });
  };

  updateDefaultServices = () => {
    const { updateDefaultServiceList } = this.props;
    this.setState(
      {
        isDataChanged: true,
        isEditMode: false,
      },
      updateDefaultServiceList
    );
  };

  handleChangeSortingOrder = () => {
    this.setState(prevState => {
      return { isSorting: !prevState.isSorting };
    });
  };

  handleDataChanged = () => {
    const { services, servicesLoadedInitially } = this.props;
    this._dataChangeUpdate(services, servicesLoadedInitially);
  };

  handleSelectionClick = (e: any) => {
    const { services } = this.props;
    const isChecked = e && e.target && e.target.checked;
    if (isChecked) {
      services.forEach(service => service["isStandard"] = true);
    } else {
      services.forEach(service => service["isStandard"] = false);
    }
    this.setState(
      {
        defaultChecked: isChecked,
      }
    );
    this.handleDataChanged();
  }

  render() {
    const {
      isEditMode,
      isDataChanged,
      isDiscardChangesOverlayOpen,
      isSorting,
      defaultChecked
    } = this.state;
    const {
      currentRole,
      services,
      isLoading,
      isServicesPending,
      searchValue,
    } = this.props;
    const isAbleToEdit = currentRole.role === RolesConstants.ADMIN;

    let isDataValid = true;
    services.forEach((service) => {
      if (service.modes && service.modes.length === 0) isDataValid = false;
    });

    const renderTableHeader = () => {
      if (services.length || (searchValue && searchValue.length)) {
        return <Fragment>
          {services.length || (searchValue && searchValue.length) ? <div className="patient-care-user-filters-holder">
            <div className="patient-care-container-flex  patient-care-col-6">
              <DefaultServicesFilter />
            </div>
          </div> : null}
          {services.length ?
            <DefaultServicesTableHeader isAbleToEdit={isAbleToEdit}
              isSorting={isSorting}
              isEditMode={isEditMode}
              handleChangeSortingOrder={this.handleChangeSortingOrder}
              handleChangeMode={this.handleChangeMode}
              handleSelectionClick={this.handleSelectionClick}
              defaultChecked={defaultChecked}
              data={services} /> : null}
        </Fragment>;
      }
    };
    const renderTableContent = () => {
      if (services.length) {
        return <DefaultServicesTableBody
          data={services}
          isSorting={isSorting}
          isEditMode={isEditMode}
          handleDataChanged={this.handleDataChanged} />;
      }
      if (isServicesPending) {
        return <Skeleton type="basic" />;
      }
      if (searchValue && searchValue.length){
        return (
          <NoResults>
            <h2>{locale.DefaultServices.emptySearchList}</h2>
          </NoResults>
        );
      }
    };

    return <Fragment>
      <Feature>
        <FeatureMenu>
          <AdminSidebar />
        </FeatureMenu>
        <FeatureContent>
          <FeatureContentSticky>
            {renderTableHeader()}
          </FeatureContentSticky>
          <FeatureContentScroll isDataChanged={isDataChanged}>
            {renderTableContent()}
          </FeatureContentScroll>
        </FeatureContent>
      </Feature>
      <FeatureAgenda />

      {isDataChanged &&
        <FloatingFooterButtons
          handleDiscardChanges={this.openDiscardChangesOverlay}
          proceedFunction={this.updateDefaultServices}
          isEditMode={!isDataValid}
          isLoading={isLoading} />}
      {isDiscardChangesOverlayOpen &&
        <DiscardChangesOverlay
          handleDiscardChanges={this.handleDiscardChanges}
          handleStay={this.handleStay} />}
    </Fragment>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultServices);
