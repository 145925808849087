import React, { Component } from "react";
import moment from 'moment';
import { IconClose, LinkIcon } from "@patient-access/ui-kit";

import type { ClosedDay } from 'types/calendars';
import * as CalendarsConstants from 'constants/CalendarsConstants';

import locale from 'service/locale';

type Props = {
  key: string,
  day: ClosedDay,
  handleDeleteRole?: () => any,
  isEditMode?: boolean,
};

export default class ClosedDaysItem extends Component<Props> {

  handleDeleteRole = (e: Event) => {
    e && e.preventDefault();
    const { day, handleDeleteRole } = this.props;
    handleDeleteRole(day.name);
  };

  render() {
    const { isEditMode, day, key } = this.props;
    return (
      <React.Fragment key={key}>
        <div className="patient-care-modal-edit-row">
          <div className="patient-care-col-6">
            <span className="patient-care-filled-input">
              {moment(new Date(day.date)).format(CalendarsConstants.CLOSED_DAY_FORMAT)}
            </span>
          </div>
        { isEditMode ? (
          <div className="patient-care-btn-delete-row">
            <LinkIcon
              to="#delete"
              size="small"
              icon={<IconClose outline />}
              accessibilitySpan={locale.Buttons.buttonDelete}
              onClick={this.handleDeleteRole}
            />
          </div>
        ) : null }
        </div>
        <hr className="patient-care-separator-small" />
      </React.Fragment>
    )
  }
}
