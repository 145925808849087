import React, { Component } from 'react';
import Wrapper from '../Wrapper/Wrapper';
import SectionMoreForm from './SectionMoreForm';
import './styles.scss';

class SectionMore extends Component {
  render() {
    return (
      <div className="section-block section-more">
        <Wrapper className="section-more-wrapper">
          <div className="section-more-content">
            <SectionMoreForm />
          </div>
          <div className="section-more-image">
            <img src="/assets/images/landing/section-more.png" alt="" />
          </div>
        </Wrapper>
      </div>
    );
  }
}

export default SectionMore;
