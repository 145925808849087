import * as ProfileConstants from "constants/ProfileConstants";
import type { UserAction, UserState } from "types/user";

const initialState: UserState = {
  id: "",
  name: "",
  email: "",
  displayName: "",
  adminUrl: "",
  passwordMismatchCode: "",
  isActiveAdmin: false,
  isActiveReports: false,
  organisation: {},
  needRedirect: false,
  resetPasswordError: [],
  sessionId: ""
};

export default (
  localState: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case ProfileConstants.SET_CURRENT_PROFILE: {
      const { id, name, email, displayName, adminUrl, isActiveAdmin, isActiveReports, sessionId } = action.payload;
      return {
        ...localState,
        id,
        name,
        email,
        displayName,
        adminUrl,
        isActiveAdmin,
        isActiveReports,
        sessionId
      };
    }
    case ProfileConstants.SET_CONFIRMATION_STATUS: {
      return {
        ...localState,
        email: action.payload
      };
    }
    case ProfileConstants.SET_CURRENT_PROFILE_URL: {
      return {
        ...localState,
        adminUrl: action.payload,
      };
    }
    case ProfileConstants.SET_PASSWORD_MISMATCH: {
      return {
        ...localState,
        passwordMismatchCode: action.payload
      };
    }
    case ProfileConstants.RESET_PASSWORD_MISMATCH: {
      return {
        ...localState,
        passwordMismatchCode: ""
      };
    }
    case ProfileConstants.SET_ACTIVE_ADMIN_MENU: {
      return {
        ...localState,
        isActiveAdmin: action.payload
      };
    }
    case ProfileConstants.SET_ACTIVE_REPORTS_MENU: {
      return {
        ...localState,
        isActiveReports: action.payload
      };
    }
    case ProfileConstants.SET_CURRENT_PROFILE_ORGANISATION: {
      return {
        ...localState,
        organisation: action.payload
      };
    }
    case ProfileConstants.SET_PASSWORD_LINK_EXPIRED: {
      return {
        ...localState,
        needRedirect: true,
        resetPasswordError: action.payload
      }
    }
    default: {
      return localState;
    }
  }
};
