import React, { Component } from 'react';
import { Skeleton } from '@patient-access/ui-kit';
import locale from 'service/locale';

type Props = {
  isLoading: boolean,
};

class LegendAppointments extends Component<Props> {
  render() {
    const { isLoading } = this.props;
    if (isLoading) return <Skeleton type="basic" className="reports-basic-legend reports-basic-skeleton small" />;
    return (
      <div className="reports-basic-legend">
        <ul>
          <li><i className="color-accent" /> <span>{locale.Reports.appointmentsStatus.serviceProvided}</span></li>
          <li><i className="color-status-error" /> <span>{locale.Reports.appointmentsStatus.statusRequired}</span></li>
          <li><i className="color-grey-light" /> <span>{locale.Reports.appointmentsStatus.cancelled}</span></li>
          <li><i className="color-support-two" /> <span>{locale.Reports.appointmentsStatus.missed}</span></li>
          <li><i className="color-notifications-warning" /> <span>{locale.Reports.appointmentsStatus.serviceNotProvided}</span></li>
          <li><i className="color-status-success" /> <span>{locale.Reports.appointmentsStatus.upcoming}</span></li>
        </ul>
      </div>
    );
  }
}

export default LegendAppointments;
