import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

class SectionFeatureOtherManagement extends Component {
  render() {
    return (
      <div className="section-block section-feature">
        <div className="wrapper section-feature-wrapper">
          <div className="section-feature-blob" style={{ top: '-100px', left: '-500px' }}>
            <img src="/assets/images/blobs/blob-two.svg" alt="" width="1240" />
          </div>
          <div className="section-feature-header">
            <h2><FormattedMessage id="landing.feature.other.management.header" /></h2>
          </div>
          <div className="section-feature-body lside">
            <div className="section-feature-image">
              <img src="/assets/images/landing/section-management-other.png" alt="" />
            </div>
            <div className="section-feature-content">
              <ul className="section-block-list">
                <li>
                  <h3><FormattedMessage id="landing.feature.other.management.feature_one.header" /></h3>
                  <p><FormattedMessage id="landing.feature.other.management.feature_one.copy" /></p>
                  <span className="line" />
                </li>
                <li>
                  <h3><FormattedMessage id="landing.feature.other.management.feature_two.header" /></h3>
                  <p><FormattedMessage id="landing.feature.other.management.feature_two.copy" /></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionFeatureOtherManagement;
