import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormItem } from "@patient-access/ui-kit";

import { updateForm } from 'actions/form';
import { validateAddBranchModal } from "helpers/validateData";
import { setOnlineBranchOverlayVisibility, setOnlineBranchOverlayCreateLater } from 'actions/branches';

import locale from "service/locale";

type Props = {
  form: any,
  handleCloseAddBranch: () => any,
  handleOpenDiscardDialog: () => any,
  isCreationPending: boolean,
  customClassName: string,
  organisationId: string,
  updateForm: (date: any) => any,
  handleCreateBranch: any,
  setOnlineBranchOverlayVisibility: (isOverlayVisible: boolean) => Action,
  setOnlineBranchOverlayCreateLater: (isCreateBranchLater: boolean) => Action,
};

const mapStateToProps = (state: any) => ({
  form: state.form,
  isCreationPending: state.branchDetails.isCreationPending
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  setOnlineBranchOverlayVisibility: (isOverlayVisible) => dispatch(setOnlineBranchOverlayVisibility(isOverlayVisible)),
  setOnlineBranchOverlayCreateLater: (isCreateBranchLater) => dispatch(setOnlineBranchOverlayCreateLater(isCreateBranchLater)),
});

class AddBranchButtons extends Component<Props> {
  handleValidateForm = () => {
    const { form, updateForm } = this.props;

    updateForm({
      ...form,
      name: form.name || "",
      address: {
        ...form.address,
        addressLine1: form.address && form.address.addressLine1,
        town: form.address && form.address.town,
        postcode: form.address && form.address.postcode
      },
      organisationId: form.organisationId || "",
      isVirtual: form.isVirtual || false
    });
  };

  createBranchHandler = () => {
    const { form, handleCreateBranch, setOnlineBranchOverlayVisibility, setOnlineBranchOverlayCreateLater } = this.props;
    if (!validateAddBranchModal(form)) {
      return this.handleValidateForm;
    } else {
      if (form.isVirtual) {
        setOnlineBranchOverlayVisibility(true);
        setOnlineBranchOverlayCreateLater(false);
      } else {
        handleCreateBranch(true);
      }
    }
  };

  createBranchLaterHandler = () => {
    const { form, handleCreateBranch, setOnlineBranchOverlayVisibility, setOnlineBranchOverlayCreateLater } = this.props;
    if (!validateAddBranchModal(form)) {
      return this.handleValidateForm;
    } else {
      if (form.isVirtual) {
        setOnlineBranchOverlayVisibility(true);
        setOnlineBranchOverlayCreateLater(true);
      } else {
        handleCreateBranch(false);
      }
    }
  };

  render() {
    const {
      handleOpenDiscardDialog,
      form,
      handleCloseAddBranch,
      isCreationPending,
      customClassName
    } = this.props;

    return (
      <div className={`patient-care-modal-footer ${customClassName}`}>
        <FormItem type="buttons">
          <div className="patient-care-row-align-right">
            <div className="patient-care-buttons-group">
              <Button
                buttonType="blueline"
                messageKey="cancel-btn"
                defaultMessage={locale.AddBranch.buttonCancel}
                onClick={form.name ? handleOpenDiscardDialog : handleCloseAddBranch}
                isLoading={isCreationPending}
                data-id="cancel-btn"
                className="patient-care-btn-in-group"
              />
              <Button
                buttonType="secondary"
                messageKey="create-btn"
                defaultMessage={locale.AddBranch.buttonCreateLater}
                onClick={this.createBranchLaterHandler}
                isLoading={isCreationPending}
                data-id="create-btn"
                className={`patient-care-btn-in-group ${!validateAddBranchModal(form) && "pretend-disabled"}`}
                tabIndex={!validateAddBranchModal(form) ? -1 : 0}
              />
              <Button
                buttonType="secondary"
                messageKey="create-btn"
                defaultMessage={locale.AddBranch.buttonCreate}
                onClick={this.createBranchHandler}
                isLoading={isCreationPending}
                data-id="create-btn"
                className={`patient-care-btn-in-group ${!validateAddBranchModal(form) && "pretend-disabled"}`}
                tabIndex={!validateAddBranchModal(form) ? -1 : 0}
              />
            </div>
          </div>
        </FormItem>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBranchButtons);
