import React, { Component } from "react";
import { Button, Overlay, Form, FormItem } from "@patient-access/ui-kit";

import locale from "service/locale";
import * as BranchesConstants from "constants/BranchesConstants";

type Props = {
  handleCloseModal: () => any;
  handleConfirmDeleteCalendar: () => any;
  confirmationStatus: string;
  isLoading: boolean;
};

class RemoveCalendarWarningModal extends Component<Props> {

  render() {
    const { handleCloseModal, confirmationStatus, handleConfirmDeleteCalendar, isLoading } = this.props;
    const hasBookings = confirmationStatus.includes(BranchesConstants.PASSWORD_WARNING_STATUSES.HAS_BOOKINGS);
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="confirm-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{locale.BranchCalendars.warningModalHeader}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>
          {
            hasBookings
              ? locale.BranchCalendars.warningBookedAppointments
              : locale.BranchCalendars.warningNoBookingAppointments
          }
          </h4>
        </div>
        <div className="patient-care-block">
          <Form noValidate>
            <FormItem type="buttons">
              <div className="overlay-confirmation-buttons">
                <Button
                  buttonType="blueline"
                  messageKey="discard-btn"
                  defaultMessage={
                    !hasBookings
                      ? locale.BranchCalendars.warningModalDismiss
                      : locale.BranchCalendars.warningModalBack
                  }
                  onClick={handleCloseModal}
                  data-id="discard-btn"
                  className="patient-care-btn-in-group"
                  isLoading={isLoading}
                />
                {
                  !hasBookings ? (
                    <Button
                      buttonType="secondary"
                      messageKey="stay-btn"
                      defaultMessage={locale.BranchCalendars.warningModalContinue}
                      data-id="stay-btn"
                      className="patient-care-btn-in-group"
                      onClick={handleConfirmDeleteCalendar}
                      isLoading={isLoading}
                    />
                  ) : null
                }
              </div>
            </FormItem>
          </Form>
        </div>
      </Overlay>
    );
  }
}

export default RemoveCalendarWarningModal;
