import React, { Component } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";

import locale from 'service/locale';
import './styles.scss';

type Props = {
  form: any;
};

const mapStateToProps = (state: any) => ({
  form: state.form || {},
});

class AddOrganisationCompanyInfo extends Component<Props> {
  render() {
    const { form } = this.props;
    return (
      <div className="patient-care-organisation-info-wrap">
        <div className="patient-care-block">
          <Form noValidate>
            <FormItem type="item">
              <InputLabel
                htmlFor="details_filled_firstName"
                message={locale.AddOrganisation.companyInfo.nameTitle}
                size="small"
              />
            </FormItem>
          </Form>
          <span className="patient-care-filled-input">
            {form.companyName}
          </span>
        </div>

        <div className="patient-care-block">
          <Form noValidate>
            <FormItem type="item">
              <InputLabel
                htmlFor="details_filled_firstName"
                message={locale.AddOrganisation.companyInfo.numberTitle}
                size="small"
              />
            </FormItem>
          </Form>
          <span className="patient-care-filled-input">
            {form.companyNumber}
          </span>
        </div>
        <hr className="patient-care-separator" />
      </div>
    );
  }
}

export default connect(mapStateToProps)(AddOrganisationCompanyInfo);
