import React, { Component, Fragment } from "react";
import SlotItem from "./SlotItem/SlotItem";
import { connect } from "react-redux";

type Props = {
  slots: any[],
  selectedSlot: number
};

const mapStateToProps = state => ({
  selectedSlot: state.calendarView.selectedSlot
});

class AppointmentSlot extends Component<Props> {

  //TODO: double check if this method can be removed in future in case if not all slots are rendered correctly
  shouldComponentUpdate = (nextProps, nextState) => {
    return this.props.selectedSlot !== nextProps.selectedSlot
  };

  render() {
    const { slots, selectedSlot } = this.props;
    let currentTimePeriod = null;
    return (
      <ul className="patient-care-slots-list">
      {slots.map(slot => {
          if (!slot.isHiddenCalendar) {
            if (currentTimePeriod !== slot.timePeriod) {
              currentTimePeriod = slot.timePeriod;
              return (
                <Fragment key={slot.start}>
                  <li className="patient-care-slot-title">
                    {slot.timePeriod}
                  </li>
                  <SlotItem key={slot.start} slot={slot} isHovered={selectedSlot === slot.zIndex}/>
                </Fragment>
              );
            }
            return <SlotItem key={slot.start} slot={slot} isHovered={selectedSlot === slot.zIndex}/>;
          } else return null;
        })}
  </ul>
    );
  }
}

export default connect(mapStateToProps)(AppointmentSlot);
