import React, { Component } from 'react';
import './styles/patient-care-feature-content.scss';

type Props = {
  children: any,
};

class FeatureContent extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="patient-care-feature-content">
        {children}
      </div>
    );
  }
};

export default FeatureContent;
