import * as OrganisationsConstants from "constants/OrganisationsConstants";
import * as BranchesConstants from "constants/BranchesConstants";
import * as PanelConstants from "constants/PanelConstants";
import agent from "service/agent";
import type { Action } from "types/actions";
import type { Branch } from "types/organisation";
import { setBranchesList } from 'actions/branches';
import { showSnackbarStatus } from "actions/snackbar";
import { clearForm } from "actions/form";
import { getToken } from "../helpers/common";
import { setOrganisationHeader, setBranchHeader } from "actions/router";

import locale from "service/locale";
import moment from "moment";

export const setBranchDetails = (branchDetails: Branch): Action => ({
  type: BranchesConstants.SET_BRANCH_DETAILS,
  payload: branchDetails
});

export const setBranchCalendar = (calendar: Object): Action => ({
  type: BranchesConstants.SET_BRANCH_CALENDAR,
  payload: calendar
});

export const setBranchCalendars = (branchCalendars: Branch): Action => ({
  type: BranchesConstants.SET_BRANCH_CALENDARS,
  payload: branchCalendars
});

export const setCalendarsPending = (): Action => ({
  type: BranchesConstants.SET_CALENDAR_PENDING,
});

export const setCalendarsPendingSuccess = (): Action => ({
  type: BranchesConstants.SET_CALENDAR_PENDING_SUCCESS,
});

export const setCalendarsPendingError = (): Action => ({
  type: BranchesConstants.SET_CALENDAR_PENDING_ERROR,
});

export const setBranchConfirmationStatus = (status: string): Action => ({
  type: BranchesConstants.SET_BRANCH_CONFIRMATION_STATUS,
  payload: status
});

export const setBranchServices = (branchServicesList: any[]): Action => ({
  type: BranchesConstants.SET_BRANCH_SERVICES_LIST,
  payload: branchServicesList
});

export const setServicesPending = (): Action => ({
  type: BranchesConstants.SET_SERVICES_PENDING,
});

export const setServicesPendingSuccess = (): Action => ({
  type: BranchesConstants.SET_SERVICES_PENDING_SUCCESS,
});

export const setServicesPendingError = (): Action => ({
  type: BranchesConstants.SET_SERVICES_PENDING_ERROR,
});

export const updateBranchSettingsPending = (): Action => ({
  type: BranchesConstants.UPDATE_BRANCH_SETTINGS_PENDING,
});

export const updateBranchSettingsSuccess = (): Action => ({
  type: BranchesConstants.UPDATE_BRANCH_SETTINGS_SUCCESS,
});

export const updateBranchSettingsError = (): Action => ({
  type: BranchesConstants.UPDATE_BRANCH_SETTINGS_ERROR,
});

export const openBranchErrorDuplicateOds = (errorMessage: string) : Action => ({
  type: PanelConstants.OPEN_BRANCH_ERROR_DUPLICATED_ODS,
  payload: errorMessage
});

export const closeBranchErrorDuplicateOds = () : Action => ({
  type: PanelConstants.CLOSE_BRANCH_ERROR_DUPLICATED_ODS
});

export const getBranchDetails = (organisationId: string, branchId: string) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchHeader(branchId));
  dispatch(setServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      branchId !== BranchesConstants.DEFAULT_BRANCHID &&
      agent.Branches.getBranchDetails(branchId, accessToken)
        .then(branchDetails => {
          dispatch(setBranchDetails(branchDetails));
        })
        .catch(err => {
          console.log("getBranchDetails server error or branch is not found", err);
        });
    });

};

export const getBranchCalendarsList = (organisationId: string, branchId: string, includeCustom: boolean = false) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchHeader(branchId));
  dispatch(setCalendarsPending());
  getToken(dispatch)
    .then(accessToken => {
      branchId !== BranchesConstants.DEFAULT_BRANCHID &&
      agent.Branches.getBranchDetails(branchId, accessToken, includeCustom)
        .then(branchDetails => {
          agent.Calendars.getBranchCalendars(branchId, accessToken)
            .then(({ items: branchCalendarsList }) => {
              dispatch(setBranchDetails(branchDetails));
              dispatch(setCalendarsPendingSuccess());
              dispatch(setBranchCalendars(branchCalendarsList));
            })
            .catch(err => {
              dispatch(setCalendarsPendingError());
              console.log(
                "getBranchCalendars server error or branch is not found",
                err
              );
            });
        })
        .catch(err => {
          dispatch(setCalendarsPendingError());
          console.log("getBranchDetails server error or branch is not found", err);
        });
    });

};

export const updateBranch = (branchId: string, newBranchData: Object) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const formData = currentState.form;
  const {
      branch_name,
      branch_description,
      branch_code,
      branch_phone,
      branch_address_1,
      branch_address_2,
      branch_city,
      branch_postcode,
      branch_email,
      branch_enhanced_profile,
      branch_featuredServices,
    } = formData;

    const sortedBranch_featuredServices = (branch_featuredServices &&
      branch_featuredServices.map((service, index) => {
        return {
          index: index + 1,
          serviceId: service.serviceId,
          name: service.name,
        };
      })
    );

    const { email, name, description, odsCode, phone, address, branchServices, isDraft, supportedFeatures, featuredService } = newBranchData;
    const { services } = featuredService || {};
    const { addressLine1, addressLine2, postcode, town } = address;
    const keys = Object.keys(formData);
    const supportedFeaturesClone = [...supportedFeatures];
    if (keys.includes("branch_enhanced_profile")) {
      const index = supportedFeaturesClone.findIndex(x => x.name === OrganisationsConstants.SUPPORTEDFEATURE_ENHANCEDPROFILE);
      supportedFeaturesClone[index].isEnabled = branch_enhanced_profile
    }
    const branchData = {
      email: keys.includes("branch_email") ? (branch_email || null) : email,
      name: branch_name || name,
      description: keys.includes("branch_description") ? branch_description : description,
      // @info: if the field in form was not changed, we take value from `state`
      // else we take value from form
      odsCode: keys.includes("branch_code") ? branch_code : odsCode,
      // @info: if nothing in form was changed (phone field from form), we take value from `state`
      // else we take value from form (phone field from form)
      phone: keys.includes("branch_phone") ? branch_phone : phone,
      address: {
        addressLine1: keys.includes("branch_address_1") ? branch_address_1 : addressLine1,
        addressLine2: keys.includes("branch_address_2") ? branch_address_2 : addressLine2,
        town: keys.includes("branch_city") ? branch_city : town,
        postcode: keys.includes("branch_postcode") ? branch_postcode : postcode
      },
      isDraft: isDraft,
      supportedFeatures: supportedFeaturesClone,
      branchServices,
      featuredServices: keys.includes("branch_featuredServices") ? sortedBranch_featuredServices : services,
    };
  dispatch(updateBranchSettingsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.updateBranch(branchId, branchData, accessToken)
        .then(() => {
          const updatedBranchIndex = currentState.branches.branchesList.findIndex(
            branch => branch.id === branchId
          );
          const newBranchesList = {
            items: currentState.branches.branchesList,
          };

          newBranchesList.items[updatedBranchIndex] = {
            ...newBranchesList.items[updatedBranchIndex],
            email: branchData.email,
            id: branchId,
            name: branchData.name,
            description: branchData.description,
            odsCode: branchData.odsCode,
            phone: branchData.phone,
            address: {
              addressLine1: branchData.address && branchData.address.addressLine1,
              addressLine2: branchData.address && branchData.address.addressLine2,
              town: branchData.address && branchData.address.town,
              postcode: branchData.address && branchData.address.postcode,
              country: BranchesConstants.COUNTRY,
            },
            isDraft: branchData.isDraft
          };
          dispatch(setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.UPDATED));
          dispatch(setBranchesList(newBranchesList));
          dispatch(showSnackbarStatus(locale.Snackbar.branchUpdated));
          dispatch(clearForm());
          dispatch(updateBranchSettingsSuccess());
        })
        .catch(err => {
          dispatch(updateBranchSettingsError());
          const errorMessage = err.response && err.response.body[BranchesConstants.odsDuplicateErrorKey];
          dispatch(openBranchErrorDuplicateOds(errorMessage));

          console.log("updateBranch server error or branch is not found", err);
          dispatch(showSnackbarStatus(locale.Snackbar.branchNotUpdated));
        });
    });

};

export const setServiceFutureAppointmentsAction = (serviceId: string | null, listOfAppointments: any[]): Action => ({
  type: BranchesConstants.SET_SERVICE_FUTURE_APPOINTMENTS,
  payload: { serviceId, listOfAppointments }
});


export const setServiceFutureAppointments = (serviceId: string) => (dispatch: Function) => {
  dispatch(setServiceFutureAppointmentsAction(null, []));
  getToken(dispatch)
    .then(accessToken => {
      const today = moment.utc().add(1, "minutes").toISOString();
      agent.Branches.getFutureAppointmentsForService(serviceId, today, accessToken).then((result) => {
        dispatch(setServiceFutureAppointmentsAction(serviceId, result.appointmentsByDate));
      })
    })
};

export const setBranchPublishStatus = (status: Boolean): Action => ({
  type: BranchesConstants.CHANGE_BRANCH_PUBLISH_STATUS,
  payload: status
});

export const setBranchSmartPharmacyEnabled = (feature: any): Action => ({
  type: BranchesConstants.CHANGE_BRANCH_SMART_PHARMACY_ENABLED,
  payload: feature,
});
