import React, { Component } from 'react';
import { IconChevronDown } from '@patient-access/ui-kit';

import './styles/skeleton-select.scss';

class SkeletonSelect extends Component {
  render() {
    return (
      <div className="skeleton-select">
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-60" />
        <i><IconChevronDown outline /></i>
      </div>
    );
  }
};

export default SkeletonSelect;
