import React, { Component, Fragment } from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { Button, FormItem, LinkIcon, IconDelete, Tooltip } from "@patient-access/ui-kit";
import values from "lodash.values";
import find from "lodash.find";

import locale from "service/locale";
import * as RolesConstants from "constants/RolesConstants";
import * as AppointmentDetailsConstants from 'constants/AppointmentDetailsConstants';
import * as RoutesConstants from 'constants/RoutesConstants';

import { getAppointmentDetails } from "actions/appointmentDetails";

const allStatusesOptions = values(locale.Appointment.status);

type Props = {
  isDataChanged: boolean,
  handleDiscardChanges: () => any,
  handleSaveAppointmentDetails?: () => any,
  handleCloseAppointmentDetails: () => any,
  handleQuickCancelAppointment: () => any,
  handleQuickAppointmentRefund: () => any,
  getAppointmentDetails: (
    appointmentId: string,
    type: AppointmentDetailsType
  ) => Function,
  customClassName: string,
  isAppointmentPending: boolean,
  isCompletedAppointment: boolean,
  isAppointmentStarted: boolean,
  isClosingAgendaWithNoSavedChanges: boolean,
  role: string,
  status: number,
  outcomeStatus: number
};

const mapStateToProps = state => ({
  isAppointmentPending: state.book.isAppointmentPending,
  isClosingAgendaWithNoSavedChanges: state.panel.isClosingAgendaWithNoSavedChanges,
  role: state.roles.profileCurrentRole.role,
  userId: state.profile.id
});

const mapDispatchToProps = (dispatch: (action: any) => Function): Object => ({
  getAppointmentDetails: (appointmentId, type) =>
    dispatch(getAppointmentDetails(appointmentId, type))
});

class AppointmentButtons extends Component<Props> {

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.isClosingAgendaWithNoSavedChanges && !this.props.isClosingAgendaWithNoSavedChanges) {
      nextProps.handleDiscardChanges();
    }
  };

  handleCancelAppointment = (e: any) => {
    const { handleQuickCancelAppointment } = this.props;
    e && e.preventDefault();
    handleQuickCancelAppointment();
  };

  render() {
    const {
      isDataChanged,
      handleDiscardChanges,
      handleSaveAppointmentDetails,
      handleCloseAppointmentDetails,
      handleQuickAppointmentRefund,
      customClassName,
      isAppointmentPending,
      isCompletedAppointment,
      isAppointmentStarted,
      appointmentDetails,
      role,
      status,
      outcomeStatus,
      isRefundedbySupportTeam,
      price,
      bookingMethod,
      paymentMethod,
      userId
    } = this.props;
    const hasAccessforRefund = role === RolesConstants.ADMIN
    const selectedStatus = find(allStatusesOptions, {
      status: status,
      outcomeStatus: outcomeStatus
    }) || {
      label: "Select status",
      value: 0,
      color: "grey-light",
      status: 0,
      outcomeStatus: 0
    };
    const isFreeAppointment = price === 0;
    const isOnlineAppointment = bookingMethod === locale.Appointment.type.online.value;
    const isEligibleforRefund =
      (selectedStatus.label === locale.Appointment.status.provided.label ||
        selectedStatus.label === locale.Appointment.status.missed.label) &&
      hasAccessforRefund &&
      isCompletedAppointment &&
      !isRefundedbySupportTeam &&
      !isFreeAppointment &&
      isOnlineAppointment &&
      paymentMethod === AppointmentDetailsConstants.PAYMENT_METHOD[1].id;
    const patientName = appointmentDetails.patient.firstName +" "+ appointmentDetails.patient.lastName;
    // JOIN BUTTON logic:
    // Inactive button: from moment appointment is booked until 30 mins before appointment starts
    // Active button: from 30 mins before appointment until max of 60 Mins after appointment has ended
    // Hidden button: from 60 mins after end of appointment or sooner if Appointment status has been updated

    const nowTime = moment();
    const videoMinutesBefore = window.__ENV.video.videoMinutesBefore || 30;
    const videoMinutesAfter = window.__ENV.video.videoMinutesAfter || 60;
    const isPastAppointment = moment(nowTime) > moment(appointmentDetails.endTime).add(videoMinutesAfter, 'minutes');
    const isFutureAppointment = moment(nowTime) < moment(appointmentDetails.startTime).subtract(videoMinutesBefore, 'minutes');

    const renderVideoButton = () => {
      if (appointmentDetails.status !== 1 || isPastAppointment) return null;
      if (isFutureAppointment) {
        return (
          <div className="patient-care-btn-join">
            {appointmentDetails.hideVideoJoinButton ? (
              <div className="patient-care-video-join-msg">
                <span>{locale.Appointment.videoAppointmentMessage}</span>
              </div>
            ) : (
              <Tooltip
                vertical="top"
                horizontal="center"
                message={`${locale.Appointment.joinTooltip.before} ${videoMinutesBefore} ${locale.Appointment.joinTooltip.after}`}
              >
                <Button
                  buttonType="secondary"
                  messageKey="some-key"
                  defaultMessage={locale.Appointment.buttons.join}
                  onClick={() => {}}
                  isDisabled
                />
              </Tooltip>
            )}
          </div>
        );
      }
      return (
        <div className="patient-care-btn-join">
          <form
            action={`${window.__ENV.video.videoUrl}`}
            method="post"
            id="webapiForm"
            target="_blank"
          >
            <input
              id="authToken"
              type="hidden"
              name="authToken"
              value={appointmentDetails.videoToken}
            />
            <input
              id="appointmentId "
              type="hidden"
              name="appointmentId"
              value={`0_${appointmentDetails.appointmentId}`}
            />
            <input
              id="roleName"
              type="hidden"
              name="roleName"
              value={role}
            />
            <input
              id="userId"
              type="hidden"
              name="userId"
              value={userId}
            />
            <input
              id="sessionId"
              type="hidden"
              name="sessionId"
              value={appointmentDetails.sessionId}
            />
            <input
              id="iamGuid"
              type="hidden"
              name="iamGuid"
              value={appointmentDetails.patient.patientUserId}
            />
            <input
              id="startDate"
              type="hidden"
              name="startDate"
              value={appointmentDetails.startTime}
            />
            <input
              id="endDate"
              type="hidden"
              name="endDate"
              value={appointmentDetails.endTime}
            />
            <input
              id="clientId"
              type="hidden"
              name="clientId"
              value={appointmentDetails.careproviderId}
            />
            <input
              id="name"
              type="hidden"
              name="name"
              value={appointmentDetails.branch.name}
            />
            <input
              id="serviceId"
              type="hidden"
              name="serviceId"
              value={appointmentDetails.service.id}
            />
            <input
              id="webTemplateFileName"
              type="hidden"
              name="webTemplateFileName"
              value={AppointmentDetailsConstants.VIDEO_WEB_TEMPLATE_FILENAME}
            />
            <input
              id="interlocutor"
              type="hidden"
              name="interlocutor"
              value={patientName}
            />
            <input
              id="organisationId"
              type="hidden"
              name="organisationId"
              value={appointmentDetails.organisationId}
            />
            <input
              id="careProviderId"
              type="hidden"
              name="careProviderId"
              value={appointmentDetails.careproviderId}
            />
            {appointmentDetails.hideVideoJoinButton ? (
                <div className="patient-care-video-join-msg">
                  <span>{locale.Appointment.videoAppointmentMessage}</span>{" "}
                </div>
              ) : (
                <button
                  value="Join"
                  name="Join"
                  id="submit"
                  className="ui-kit-button ui-kit-button-secondary ui-kit-button-medium"
                  buttontype="secondary"
                  onClick={() =>
                    this.props.getAppointmentDetails(
                      appointmentDetails.appointmentId,
                      AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
                    )
                  }
                >
                  <span>{locale.Appointment.buttons.join}</span>
                </button>
              )}
          </form>
        </div>
      );
    }

    return (
      <div className={`patient-care-modal-footer ${customClassName}`}>
        <FormItem type="buttons">
          <div className="patient-care-modal-buttons-row">
            <div className="patient-care-modal-buttons-flex">
              {isEligibleforRefund ? (
                <Button
                  buttonType="blueline"
                  messageKey="discard-btn"
                  defaultMessage={locale.Appointment.buttons.refund}
                  onClick={handleQuickAppointmentRefund}
                  data-id="discard-btn"
                  className="patient-care-btn-in-group"
                />
              ): ''}
              {!isCompletedAppointment && !isAppointmentStarted && (
                <div className="patient-care-btn-delete">
                  <LinkIcon
                    to={RoutesConstants.HOME}
                    icon={<IconDelete outline />}
                    size="medium"
                    accessibilitySpan={locale.Appointment.buttons.delete}
                    onClick={this.handleCancelAppointment}
                  />
                </div>
              )}
              {appointmentDetails.type === 1 ? renderVideoButton() : null}
            </div>
            <div className="patient-care-buttons-group">
              {isDataChanged ? (
                <Fragment>
                  <Button
                    buttonType="blueline"
                    messageKey="discard-btn"
                    defaultMessage={locale.Appointment.buttons.discardChanges}
                    onClick={handleDiscardChanges}
                    isLoading={isAppointmentPending}
                    data-id="discard-btn"
                    className="patient-care-btn-in-group"
                  />
                  <Button
                    buttonType="secondary"
                    messageKey="save-changes-btn"
                    defaultMessage={locale.Appointment.buttons.saveChanges}
                    onClick={handleSaveAppointmentDetails}
                    isLoading={isAppointmentPending}
                    data-id="save-changes-btn"
                    className="patient-care-btn-in-group"
                  />
                </Fragment>
              ) : (
                <Fragment>
                <Button
                  buttonType="blueline"
                  messageKey="cancel-btn"
                  defaultMessage={locale.Appointment.buttons.close}
                  onClick={handleCloseAppointmentDetails}
                  data-id="cancel-changes-btn"
                />
                </Fragment>
              )}
            </div>
          </div>
        </FormItem>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentButtons);
