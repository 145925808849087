import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import { Button } from '@patient-access/ui-kit';
import AsyncSelect from "react-select/lib/Async";
import DatePickerWrapper from 'components/Share/DatePickerWrapper/DatePickerWrapper';
import moment from 'moment';
import locale from 'service/locale';
import './styles/reports-content-filters.scss';
import { getOrganisationsForAsyncSelect } from "actions/organisations";
import { formatListAsOptions, formatOrganisationListAsOptions } from "helpers/formatData";
import { setDropdownOrganisation, setDropdownBranch, setReportFilter, resetReportFiltersOrganisation,
   resetReportFiltersBranch, resetReportFiltersRest, setReportsDownloadOverlay, getSmartPharmcyReportsDownload } from 'actions/reportsSmartPharmacy';
import * as RolesConstants from "constants/RolesConstants";
import { getBranchesForAsyncSelect } from 'actions/branches';
import type { Organisation, Branch } from "types/organisation";
import type { ReportSmartPharmacyFilter } from "types/reports";
import * as ReportsConstants from 'constants/ReportsConstants';
import * as BranchesConstants from 'constants/BranchesConstants';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import { ai } from "service/telemetry";

type Props = {
  currentRole: any,
  organisations: Organisation[],
  branches: Branch[],
  userOrganisationId: string,
  userBranchId: string,
  profileOrganisation: Organisation,
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
  getBranchesForAsyncSelect: (searchValue: string, organizationId?: string, callback?: Function) => any,
  setDropdownOrganisation: (organisation: Organisation) => Action,
  setDropdownBranch: (branch: Branch) => Action,
  setReportFilter: (filters: ReportSmartPharmacyFilter) => Action,
  handleReportFilterChange: () => Action,
  resetReportFiltersOrganisation: () => Action,
  resetReportFiltersBranch: () => Action,
  resetReportFiltersRest: () => Action,
  setReportsDownloadOverlay: (isOverlayVisible: boolean) => Action,
  getSmartPharmcyReportsDownload: () => Action,

    // Telemetry
    aiUserId : string,
    aiSessionId: string,
    aiRoleName: string,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  profileOrganisation: state.profile.organisation,
  organisations: state.organisations.organisationsListAsync,
  branches: state.branches.branchesListAsync,
  selectedOrganisation: state.reportsSmartPharmacy.search.selectedOrganisation,
  selectedBranch: state.reportsSmartPharmacy.search.selectedBranch,

    // Telemetry
    aiUserId: state.profile.id,
    aiSessionId: state.profile.sessionId,
    aiRoleName: state.roles.profileCurrentRole.role,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
  getBranchesForAsyncSelect: (searchValue, organizationId, callback) => dispatch(getBranchesForAsyncSelect(searchValue, organizationId, callback)),
  setDropdownOrganisation: (organisation) => dispatch(setDropdownOrganisation(organisation)),
  setDropdownBranch: (branch) => dispatch(setDropdownBranch(branch)),
  setReportFilter: (filters) => dispatch(setReportFilter(filters)),
  resetReportFiltersOrganisation: () => dispatch(resetReportFiltersOrganisation()),
  resetReportFiltersBranch: () => dispatch(resetReportFiltersBranch()),
  resetReportFiltersRest: () => dispatch(resetReportFiltersRest()),
  setReportsDownloadOverlay: (isOverlayVisible) => dispatch(setReportsDownloadOverlay(isOverlayVisible)),
  getSmartPharmcyReportsDownload: () => dispatch(getSmartPharmcyReportsDownload()),
});

class ReportsFilter extends Component<Props, State> {
  state = {
    searchOrgValue: "",
    searchBranchValue: "",
    selectedStartDate: ReportsConstants.DEFAULT_REPORT_STARTDATE,
    selectedEndDate: ReportsConstants.DEFAULT_REPORT_ENDDATE,
    maxDate: ReportsConstants.DEFAULT_REPORT_MAXDATE
  };

  componentDidMount = () => {
    this.handleFilterDefaultValues();
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const {selectedBranch} = this.props;
    if (selectedBranch.id && !selectedBranch.label) {
      selectedBranch.label = selectedBranch.name;
    }
    if (nextProps.profileOrganisation !== this.props.profileOrganisation) {
      Object.assign(
        this.props.profileOrganisation,
        nextProps.profileOrganisation
      );

      this.handleFilterDefaultValues();
    }
  };

  handlerTelemetry = (orgId: string, branchId: string) => {
    const { aiUserId, aiRoleName, aiSessionId } = this.props;

    // Telemetry
    ai.appInsights.trackEvent({
      name: 'PAProSpReport',
    }, {
      UserId: aiUserId,
      SessionId: aiSessionId,
      RoleName: aiRoleName,
      OrganizationId: orgId || locale.Telemetry.naOrganisationId,
      BranchId: branchId || locale.Telemetry.naBranchId,
    });
  }

  handleFilterDefaultValues = () => {
    const {
      currentRole,
      userOrganisationId,
      userBranchId,
      profileOrganisation,
      getOrganisationsForAsyncSelect,
      getBranchesForAsyncSelect,
      handleReportFilterChange
    } = this.props;

    if (currentRole.role === RolesConstants.ADMIN) {
      getOrganisationsForAsyncSelect();
      getBranchesForAsyncSelect();
      handleReportFilterChange();
      this.handlerTelemetry(userOrganisationId, userBranchId);
    }

    if (userOrganisationId) {
      profileOrganisation.id = profileOrganisation.organisationId;
      this.handleOrganisationChange(profileOrganisation);
    }

    if (userBranchId) {
      const branch =
        (profileOrganisation.branches &&
          profileOrganisation.branches.find(
            branch => branch.id === userBranchId
          )) ||
        {};
      this.handleBranchChange(branch);
    }
  }

  handleLoadOrganisationOptions = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  handleLoadBranchOptions = (inputValue: string, callback: Function) => {
    const { getBranchesForAsyncSelect, selectedOrganisation } = this.props;
    return getBranchesForAsyncSelect(inputValue.length > 2 ? inputValue : "", selectedOrganisation.id, callback);
  };

  handleOrganisationChange = organisation => {
    const { searchBranchValue } = this.state;
    const { currentRole, getBranchesForAsyncSelect, setDropdownOrganisation, setDropdownBranch, setReportFilter, handleReportFilterChange } = this.props;

    if (organisation.id) {
      setDropdownOrganisation(organisation);
      setReportFilter({ organisationId: organisation.id, branchId: "", careProviderId: "" });
    } else {
      setDropdownOrganisation({});
      setReportFilter({ organisationId: "", branchId: "", careProviderId: "" });
      getBranchesForAsyncSelect();
    }

    setDropdownBranch({});

    if (
      organisation.id &&
      (currentRole.role === RolesConstants.ORGANIZATION_ADMIN ||
        currentRole.role === RolesConstants.ADMIN)
    ) {
      getBranchesForAsyncSelect(searchBranchValue, organisation.id);
      handleReportFilterChange();
      this.handlerTelemetry(organisation.id, "");
    }

    if (currentRole.role === RolesConstants.ADMIN) {
      handleReportFilterChange();
      this.handlerTelemetry(organisation.id, "");
    }
  };

  handleBranchChange = branch => {
    const { currentRole, userOrganisationId, selectedOrganisation, setDropdownBranch, setReportFilter, handleReportFilterChange } = this.props;

    if (branch.id) {
      setDropdownBranch(branch);
      setReportFilter({ branchId: branch.id, careProviderId: (branch.careProviderId ?? branch.id) });
    } else {
      setDropdownBranch({});
      setReportFilter({ branchId: "", careProviderId: ""});
    }

    if (currentRole.role === RolesConstants.ADMIN || 
          (currentRole.role === RolesConstants.ORGANIZATION_ADMIN && userOrganisationId) ||
          (currentRole.role === RolesConstants.BRANCH_ADMIN && userOrganisationId && branch.id)
      ) {
      handleReportFilterChange();
      this.handlerTelemetry(selectedOrganisation.id, branch.id);
    }
  };

  handleStartDateChange = date => {
    const { selectedOrganisation, selectedBranch, handleReportFilterChange, setReportFilter } = this.props;
    const { selectedEndDate} = this.state;

    if(date > selectedEndDate) {
      this.setState({
        selectedStartDate: date,
        selectedEndDate: date
      });
      setReportFilter({ startDate: date, endDate: date });
    } else {
      this.setState({
        selectedStartDate: date
      });
      setReportFilter({ startDate: date });
    }
    handleReportFilterChange();
    this.handlerTelemetry(selectedOrganisation.id, selectedBranch.id);
  };

  handleEndDateChange = date => {
    const { selectedOrganisation, selectedBranch, handleReportFilterChange, setReportFilter } = this.props;
    const { selectedStartDate} = this.state;

    if(date < selectedStartDate) {
      this.setState({
        selectedStartDate: date,
        selectedEndDate: date
      });
      setReportFilter({ startDate: date, endDate: date });
    } else {
      this.setState({
        selectedEndDate: date
      });
      setReportFilter({ endDate: date });
    }
    handleReportFilterChange();
    this.handlerTelemetry(selectedOrganisation.id, selectedBranch.id);
  };

  handleOrganisationSearchChange = (searchOrgValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchOrgValue
      }, () => {
        if (searchOrgValue && searchOrgValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  handleBranchSearchChange = (searchBranchValue: string, action: any) => {
    const { getBranchesForAsyncSelect, selectedOrganisation } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchBranchValue
      }, () => {
        if (searchBranchValue && searchBranchValue.length < 2) {
          getBranchesForAsyncSelect("", selectedOrganisation.id);
        }
      });
    }
  };

  handleResetFilter = () => {
    const { currentRole, userOrganisationId, userBranchId, resetReportFiltersOrganisation, resetReportFiltersBranch, resetReportFiltersRest, handleReportFilterChange } = this.props;

    if (currentRole.role === RolesConstants.ADMIN) {
      resetReportFiltersOrganisation();
      resetReportFiltersBranch();
    }
    if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) {
      resetReportFiltersBranch();
    }
    resetReportFiltersRest();

    this.setState({
      selectedStartDate: ReportsConstants.DEFAULT_REPORT_STARTDATE,
      selectedEndDate: ReportsConstants.DEFAULT_REPORT_ENDDATE,
      maxDate: ReportsConstants.DEFAULT_REPORT_MAXDATE
    });

    handleReportFilterChange();
    this.handlerTelemetry(userOrganisationId, userBranchId);
  };

  render() {
    const { searchOrgValue, searchBranchValue, selectedStartDate, selectedEndDate, maxDate } = this.state;
    const { currentRole, organisations, branches, selectedOrganisation, selectedBranch, setReportsDownloadOverlay, getSmartPharmcyReportsDownload } = this.props;

    const showOrganisationSelect = currentRole.role === RolesConstants.ADMIN;
    const showBranchSelect = currentRole.role === RolesConstants.ADMIN || currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    
    let organisationsListOpts = formatOrganisationListAsOptions(organisations.slice(0).filter(organisation => organisation.value));
    organisationsListOpts.unshift(OrganisationsConstants.DROPDOWN_OPTION_DEFAULT);

    let branchesListOpts = formatListAsOptions(branches.slice(0).filter(branch => branch.value));
    branchesListOpts.unshift(BranchesConstants.DROPDOWN_OPTION_DEFAULT);

    const renderFilterDropdowns = () => {
      return (
        <div className="reports-content-filters-select">
          {
            showOrganisationSelect ?
              <div className="reports-content-filters-organisation">
                <AsyncSelect
                  defaultOptions={organisationsListOpts}
                  defaultValue={selectedOrganisation.id ? selectedOrganisation : organisationsListOpts[0]}
                  value={selectedOrganisation.id ? selectedOrganisation : organisationsListOpts[0]}
                  id="reportsSmartPharmacy_organisation_select"
                  inputId="reportsSmartPharmacy_organisation_input"
                  name="reportsSmartPharmacy_organisation_select"
                  placeholder="Select Organisation"
                  loadOptions={this.handleLoadOrganisationOptions}
                  classNamePrefix="patient-care"
                  onInputChange={this.handleOrganisationSearchChange}
                  onChange={this.handleOrganisationChange}
                  className="patient-care-select"
                  inputValue={searchOrgValue}
                  openMenuOnFocus={true}
                />
              </div> : null
          }
          {
            showBranchSelect ?
              <div className="reports-content-filters-branch">
                <AsyncSelect
                  defaultOptions={branchesListOpts}
                  defaultValue={selectedBranch.id ? selectedBranch : branchesListOpts[0]}
                  value={selectedBranch.id ? selectedBranch : branchesListOpts[0]}
                  id="reportsSmartPharmacy_branch_select"
                  inputId="reportsSmartPharmacy_branch_input"
                  name="reportsSmartPharmacy_branch_select"
                  placeholder="Select branch"
                  loadOptions={this.handleLoadBranchOptions}
                  classNamePrefix="patient-care"
                  onInputChange={this.handleBranchSearchChange}
                  onChange={this.handleBranchChange}
                  inputValue={searchBranchValue}
                  className="patient-care-select"
                  openMenuOnFocus={true}
                />
              </div> : null
          }
        </div>
      )
    }

    const renderResetFiltersButton =() => {
      if (
        currentRole.role === RolesConstants.ADMIN &&
        Object.keys(selectedOrganisation).length === 0 &&
        Object.keys(selectedBranch).length === 0 &&
        moment(selectedStartDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_STARTDATE).format('DD/MM/YYYY') &&
        moment(selectedEndDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_ENDDATE).format('DD/MM/YYYY')
        ) return null;
      if (
        currentRole.role === RolesConstants.ORGANIZATION_ADMIN &&
        Object.keys(selectedBranch).length === 0 &&
        moment(selectedStartDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_STARTDATE).format('DD/MM/YYYY') &&
        moment(selectedEndDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_ENDDATE).format('DD/MM/YYYY')
        ) return null;
      if (
        currentRole.role === RolesConstants.BRANCH_ADMIN &&
        moment(selectedStartDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_STARTDATE).format('DD/MM/YYYY') &&
        moment(selectedEndDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_ENDDATE).format('DD/MM/YYYY')
        ) return null;
      return (
        <Button
          buttonType="blueline"
          messageKey="buttonResetFilter"
          defaultMessage={locale.Reports.buttonResetFilter}
          onClick={(e) => { e.preventDefault(); this.handleResetFilter(); }}
        />
      );
    }

    return (
      <Fragment>
        <div className="reports-content-filters">
          {
            showOrganisationSelect || showBranchSelect ?
              renderFilterDropdowns() : null
          }
          <div className="reports-content-filters-date">
            <DatePickerWrapper>
              <DatePicker
                selected={selectedStartDate}
                maxDate={maxDate}
                onChange={date => this.handleStartDateChange(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
              />
            </DatePickerWrapper>
            <p>to</p>
            <DatePickerWrapper>
              <DatePicker
                selected={selectedEndDate}
                maxDate={maxDate}
                onChange={date => this.handleEndDateChange(date)}
                selectsEnd
                dateFormat="dd/MM/yyyy"
              />
            </DatePickerWrapper>
          </div>
          <div className="reports-content-filters-buttons">
            {renderResetFiltersButton()}
            <Button buttonType="secondary" messageKey="buttonDownloadSCV" defaultMessage={locale.Reports.buttonDownloadSCV} onClick={(e) => { e.preventDefault(); setReportsDownloadOverlay(true); getSmartPharmcyReportsDownload(); }} />
          </div>
        </div>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilter);
