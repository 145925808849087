import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Form, SearchFilter, IconSearch, Skeleton } from '@patient-access/ui-kit';
import { FeatureContentBreadcrumbs } from 'components/Share/feature';
import { setFiltersSearchValue, setFiltersBranchesValue, setFiltersServicesValue } from 'actions/calendarsList';
import * as BranchesConstants from 'constants/BranchesConstants';
import * as ServicesConstants from 'constants/ServicesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import { modifyBranchesList, modifyServicesList } from 'helpers/common';
import { formatListAsOptions } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

// CALENDAR_ADD
import { openModalOverlay } from 'actions/panel';

type Props = {
  organisationId: string,
  organisationDetails: any,
  services: any,
  searchValue: string,
  branchIds: any,
  serviceIds: any,
  isLoading: boolean,
  setFiltersSearchValue: (searchValue: string) => any,
  setFiltersBranchesValue: (branchIds: any) => any,
  setFiltersServicesValue: (serviceIds: any) => any,

  // CALENDAR_ADD
  openModalOverlay: () => Action,
};

const mapStateToProps = (state) => ({
  organisationDetails: state.organisationDetails,
  searchValue: state.calendarsList.filters.searchValue,
  branchIds: state.calendarsList.filters.branchIds,
  serviceIds: state.calendarsList.filters.serviceIds,
  services: state.calendarsList.services.data,
  isLoadingServices: state.calendarsList.services.isLoading,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setFiltersSearchValue: (searchValue: string) => dispatch(setFiltersSearchValue(searchValue)),
  setFiltersBranchesValue: (branchIds: string) => dispatch(setFiltersBranchesValue(branchIds)),
  setFiltersServicesValue: (serviceIds: string) => dispatch(setFiltersServicesValue(serviceIds)),

  // CALENDAR_ADD
  openModalOverlay: () => dispatch(openModalOverlay()),
});

class FilterCalendars extends Component<Props> {
  componentWillUnmount = () => {
    const { setFiltersSearchValue, setFiltersBranchesValue, setFiltersServicesValue } = this.props;
    setFiltersSearchValue('');
    setFiltersBranchesValue([]);
    setFiltersServicesValue([]);
  }

  render() {
    const { organisationId, organisationDetails, services, isLoadingServices, searchValue, serviceIds, branchIds, isSuperAdmin, isLoading, setFiltersSearchValue, setFiltersBranchesValue, setFiltersServicesValue, openModalOverlay } = this.props;
    const isLoadingBranches = organisationDetails.getOrganisatonDetailsPending;

    const branchesList = organisationDetails ? modifyBranchesList({ items: organisationDetails.branches || [] }) : [];
    let branchesListOpts = formatListAsOptions(branchesList.slice(0).filter(branch => branch.value));
    branchesListOpts.unshift(BranchesConstants.DROPDOWN_OPTION_DEFAULT);

    const servicesList = services ? modifyServicesList({ items: services || [] }) : [];
    let servicesListOpts = formatListAsOptions(servicesList.slice(0).filter(service => service.value));
    servicesListOpts.unshift(ServicesConstants.DROPDOWN_OPTION_DEFAULT);

    const handleBranchIds = (branch: Branch) => {
      if (branch.value) {
        setFiltersBranchesValue([branch.value]);
      } else {
        setFiltersBranchesValue([]);
      }
    };

    const handleServiceIds = (service: Service) => {
      if (service.value) {
        setFiltersServicesValue([service.value]);
      } else {
        setFiltersServicesValue([]);
      }
    };

    const renderBreadcrumbs = () => {
      if (isSuperAdmin) {
        return (
          <div className="row">
            <FeatureContentBreadcrumbs organisationId={organisationId} />
          </div>
        );
      }
      return null;
    }

    const renderSelectBranches = () => {
      if (isLoading || isLoadingBranches) return <Skeleton className="patient-care-select" type="select" />;
      return (
        <Select
          id="selectCalendarBranch"
          name="selectCalendarBranch"
          // defaultValue={BranchesConstants.DROPDOWN_OPTION_DEFAULT}
          defaultValue={branchesListOpts.find(option => option.id === branchIds[0])}
          options={branchesListOpts}
          classNamePrefix="patient-care"
          className="patient-care-select"
          onChange={handleBranchIds}
        />
      );
    }

    const renderSelectServices = () => {
      if (isLoading || isLoadingServices) return <Skeleton className="patient-care-select" type="select" />;
      return (
        <Select
          id="selectCalendarServices"
          name="selectCalendarServices"
          // defaultValue={ServicesConstants.DROPDOWN_OPTION_DEFAULT}
          defaultValue={servicesListOpts.find(option => option.id === serviceIds[0])}
          options={servicesListOpts}
          classNamePrefix="patient-care"
          className="patient-care-select"
          onChange={handleServiceIds}
        />
      );
    }

    return (
      <Fragment>
        {renderBreadcrumbs()}
        <div className="row">
          <div className="column column-full">
            <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
              <div className="filter-organisation-calendars">
                <SearchFilter
                  autoComplete="off"
                  id="searchCalendarList"
                  name="searchCalendarList"
                  placeholder={locale.OrganisationCalendars.filter.placeholder}
                  clearHandler={() => { setFiltersSearchValue(''); }}
                  onChange={(e) => { setFiltersSearchValue(e.target.value); }}
                  icon={<IconSearch outline />}
                  value={searchValue}
                />
                {renderSelectBranches()}
                {renderSelectServices()}
              </div>
            </Form>
          </div>
          <div className="column">
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.OrganisationCalendars.button.addCalendar}
              onClick={() => { openModalOverlay(); }}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCalendars);
