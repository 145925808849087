import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { Form, IconCog, IconPerson, IconPhone, IconVideo, IconHome, IconCheckbox, LogoNHS } from '@patient-access/ui-kit';
import { formatNumber } from 'helpers/formatData';
import * as RoutesConstants from 'constants/RoutesConstants';
import IconSort from 'svg/IconSort';
import IconCheckboxIntermediate from 'svg/IconCheckboxIntermediate';
import locale from 'service/locale';
import './styles.scss';

export const columnsAffiliates = [
  {
    className: 'cell-main',
    Header: locale.OrganisationServices.table.servicesSummary.headers.serviceName,
    accessor: 'serviceName',
    Cell: ({ cell: { value } }) => <span>{value}</span>
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.branches,
    accessor: 'branches',
    Cell: ({ cell: { value } }) => <span>{formatNumber(value)}</span>
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.showPrice,
    accessor: 'priceShownBranches',
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.servicePrice,
    accessor: 'servicePriceFormattedRange',
    Cell: ({ cell: { value } }) => <span>{value !== '' ? `${locale.OrganisationServices.table.servicesSummary.labels.servicePrice}${value}` : `${locale.OrganisationServices.table.servicesSummary.labels.servicePriceNull}`}</span>
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.status,
    accessor: 'status',
    Cell: ({ cell: { value } }) => <span>{formatNumber(value)} {locale.OrganisationServices.table.servicesSummary.labels.status}</span>
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.duration,
    accessor: 'durationFormattedRange',
    Cell: ({ cell: { value } }) => <span>{value} {locale.OrganisationServices.table.servicesSummary.labels.duration}</span>
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.modes,
    accessor: 'modes',
    Cell: ({ cell: { value } }) =>
      <i className="group">
        {value.sort().map(item => (
          <Fragment key={item}>
            {item === 0 ? <span title={locale.OrganisationServices.section.appointmentTypes.labelF2F}><IconPerson outline={false} /></span> : null}
            {item === 1 ? <span title={locale.OrganisationServices.section.appointmentTypes.labelVideo}><IconVideo outline={false} /></span> : null}
            {item === 2 ? <span title={locale.OrganisationServices.section.appointmentTypes.labelPhone}><IconPhone outline={false} /></span> : null}
            {item === 5 ? <span title={locale.OrganisationServices.section.appointmentTypes.labelHomeDelivery}><IconHome outline={false} /></span> : null}
          </Fragment>
        ))}
      </i>,
    sortType: 'basic'
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationServices.table.servicesSummary.headers.bookingUrl,
    accessor: 'bookingUrl',
    Cell: ({ cell: { value } }) =>
      <span>
        {value.length === 0 ? <em>{locale.OrganisationServices.table.servicesSummary.labels.bookingUrlEmpy}</em> : null}
        {value.length > 1 ? <em>{locale.OrganisationServices.table.servicesSummary.labels.bookingUrlMultiple}</em> : null}
        {value.length === 1 ? (value[0] !== '' && value[0] !== null ? value[0] : locale.OrganisationServices.table.servicesSummary.labels.bookingUrlEmpy) : null}
      </span>
  },
];

export function TableServicesSummaryAffiliates({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: 'serviceName', desc: false }],
      },
    },
    useSortBy,
  );
  const [ isMenuOn, setMenuOn ] = useState(false);

  const renderCell = (row, cell) => {
    if (cell.column.Header === locale.OrganisationServices.table.servicesSummary.headers.serviceName) {
      return (
        <Link className={row.original.nhsCovered ? 'nhs-service' : null } to={`${RoutesConstants.SERVICE}/${row.original.serviceId}/${RoutesConstants.DETAILS}`} title={cell.value}>
          {cell.render('Cell')}
          {row.original.nhsCovered ? <i><LogoNHS /></i> : null}
        </Link>
      );
    }
    if (cell.column.Header === locale.OrganisationServices.table.servicesSummary.headers.showPrice) {
      if (row.original.priceShownBranches === 0) {
        return <i className="group"><IconCheckbox outline={true} /></i>;
      }
      if (row.original.priceShownBranches === row.original.branches) {
        return <i className="group"><IconCheckbox outline={false} /></i>;
      }
      return (
        <i className="group"><IconCheckboxIntermediate outline={false} /></i>
      );
    }
    return <Fragment>{cell.render('Cell')}</Fragment>;
  }

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div className="react-table-settings-background" onClick={() => { setMenuOn(!isMenuOn); }} />
          <div className="react-table-settings-menu">
            <h2>{locale.OrganisationServices.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map(column => {
                if (column.id === 'serviceName') return null;
                return (
                  <div className="input-checkbox input-checkbox-small" key={column.id}>
                    <label htmlFor={column.id}>
                      <input id={column.id} name={column.id} type="checkbox" {...column.getToggleHiddenProps()} />
                      <span>{column.Header}</span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  }

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div className="react-table-settings-button" onClick={() => { setMenuOn(!isMenuOn); }}>
          <i><IconCog outline={false} /></i>
        </div>
      </div>
      <table className="react-table react-table-services-summary spacing-medium" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="sticky grey">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}  title={locale.OrganisationServices.table.sortByLabel(column.Header)}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr className={row.original.status === 0 ? 'highlighted' : ''} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {renderCell(row, cell)}
                      </td>
                    );
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
