import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IconPlusCircle } from 'svg';
import './styles/button-add.scss';

type Props = {
  onClick: any,
};

class ButtonAdd extends Component<Props> {
  render() {
    const { onClick } = this.props;
    return (
      <Link className="button-add" to="#add" onClick={onClick}>
        <IconPlusCircle outline={false} />
      </Link>
    );
  }
};

export default ButtonAdd;
