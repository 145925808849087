import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import * as LoginConstants from "constants/LoginConstants";
import rootReducer from 'reducers';
import { removeRefreshTokenCookie, removeUsersCookie } from "service/cookie";

let middleWares;

const appReducer = (state, action) => {
  if (action.type === LoginConstants.USER_LOGOUT) {
    sessionStorage.clear();
    removeUsersCookie();
    removeRefreshTokenCookie();
    state = undefined;
  }

  return rootReducer(state, action);
};

switch (process.env.NODE_ENV) {
  case 'development':
    middleWares = composeWithDevTools(applyMiddleware(thunk));
    break;
  case 'production':
  default:
    middleWares = applyMiddleware(thunk);
}

const configureStore = function() {
  return createStore(
      appReducer,
      middleWares
  );
};

const Store = configureStore();

export { configureStore, Store } ;
