import type {
  Action
} from "types/actions";
import type {
  DefaultServicesState
} from 'types/defaultServices';
import * as DefaultServicesConstants from "constants/DefaultServicesConstants";
import {
  cloneDeep
} from "lodash";

const initialState: DefaultServicesState = {
  services: [],
  servicesList: [],
  servicesLoadedInitially:[],
  isServicesPending:false,
  isUpdateServicesPending: false,
  pages: 0,
  pageNumber: 0,
  searchValue: "",
}

export default (localState: DefaultServicesState = initialState, action: Action): DefaultServicesState => {        
 switch (action.type) {

    case DefaultServicesConstants.SET_DEFAULT_SERVICE_LIST:
      const {
        servicesList, searchValue
      } = action.payload;
      const {
        items, pages, pageNumber
      } = servicesList;
      if (items) {
        action.payload.servicesLoadedInitially = cloneDeep(items);
      }
      return {
        ...localState,
        ...action.payload,
        services: items,
          pages: pages === 0 ? localState.pages : pages,
          pageNumber,
          searchValue,
          isServicesPending: false,
      };

     case DefaultServicesConstants.SET_DEFAULT_SERVICE_DATA:
      const newDefaultServiceDetails = {
        ...localState
      };
      const editedServiceIndex = newDefaultServiceDetails.services.findIndex(
        service => service.id === action.payload.serviceId
      );
      newDefaultServiceDetails.services[editedServiceIndex] = {
        ...newDefaultServiceDetails.services[editedServiceIndex],
        ...action.payload.data
      };
      return newDefaultServiceDetails;

      case DefaultServicesConstants.SET_SERVICES_PENDING:
      return {
        ...localState,
        isServicesPending: true,
      };
      case DefaultServicesConstants.UPDATE_SERVICES_PENDING:
      return {
        ...localState,
        isUpdateServicesPending: true,
      };
      case DefaultServicesConstants.UPDATE_SERVICES_SUCCESS:
        return {
          ...localState,
          isUpdateServicesPending: false,
        };
      case DefaultServicesConstants.UPDATE_SERVICES_ERROR:
        return {
          ...localState,
          isUpdateServicesPending: false,
        }; 

      default:
      return localState;
 }
}