import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { Form, IconCog } from '@patient-access/ui-kit';
import { IconSort } from 'svg';
import locale from 'service/locale';
import './styles.scss';

export const columns = [
  {
    className: 'cell-main',
    Header: locale.UsersListRedesign.table.headers.name,
    accessor: 'displayName',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
    sortType: 'basic',
  },
  {
    className: 'cell-default',
    Header: locale.UsersListRedesign.table.headers.role,
    accessor: 'role',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: 'cell-default',
    Header: locale.UsersListRedesign.table.headers.organisation,
    accessor: 'organization',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: 'cell-default',
    Header: locale.UsersListRedesign.table.headers.branch,
    accessor: 'branch',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: 'cell-default',
    Header: locale.UsersListRedesign.table.headers.email,
    accessor: 'email',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: 'cell-default',
    Header: locale.UsersListRedesign.table.headers.mfaStatus,
    accessor: 'isMFAPhoneRegistered',
    Cell: ({ cell: { value } }) => <span>{value? locale.UsersListRedesign.labels.mfaStatus[1] : locale.UsersListRedesign.labels.mfaStatus[0]}</span>,
  },
];

export function UsersList({ columns, data, props, hiddenColumns }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: 'displayName', desc: false }],
        hiddenColumns: hiddenColumns,
      },
    },
    useSortBy,
  );
  const [ isMenuOn, setMenuOn ] = useState(false);
  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div className="react-table-settings-background" onClick={() => { setMenuOn(!isMenuOn); }} />
          <div className="react-table-settings-menu">
            <h2>{locale.BranchesListRedesign.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map(column => {
                if (column.id === 'displayName') return null;
                return (
                  <div className="input-checkbox input-checkbox-small" key={column.id}>
                    <label htmlFor={column.id}>
                      <input id={column.id} name={column.id} type="checkbox" {...column.getToggleHiddenProps()} />
                      <span>{column.Header}</span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  }

  const renderCell = (row, cell) => {
    if (cell.column.id === 'displayName' && props.getUserDetails) {
      return (
        <Link to="#user-details" title={cell.value} onClick={(e) => handleSelectedRow(e, row.original)}>
          {cell.render('Cell')}
        </Link>
      );
    }
    return <Fragment>{cell.render('Cell')}</Fragment>;
  }

  const handleSelectedRow = (e, row) => {
    e.preventDefault();
    props.getUserDetails(row.userId);
  }

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div className="react-table-settings-button" onClick={() => { setMenuOn(!isMenuOn); }}>
          <i><IconCog outline={false} /></i>
        </div>
      </div>
      <table className="react-table branches-list spacing-medium" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="sticky grey">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())} title={locale.BranchesListRedesign.table.sortByLabel(column.Header)}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {renderCell(row, cell)}
                      </td>
                    );
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
