import { getPreviousMonthDate } from "helpers/common"

export const SET_REPORTSMARTPHARMACY_DROPDOWN_ORGANISATION = "SET_REPORTSMARTPHARMACY_DROPDOWN_ORGANISATION";
export const SET_REPORTSMARTPHARMACY_DROPDOWN_BRANCH = "SET_REPORTSMARTPHARMACY_DROPDOWN_BRANCH";
export const CLEAR_REPORTSMARTPHARMACY_DROPDOWN_BRANCH = "CLEAR_REPORTSMARTPHARMACY_DROPDOWN_BRANCH";
export const SET_REPORTSMARTPHARMACY_FILTER = "SET_REPORTSMARTPHARMACY_FILTER";
export const RESET_REPORTSMARTPHARMACY_FILTER_ORGANISATION = "RESET_REPORTSMARTPHARMACY_FILTER_ORGANISATION";
export const RESET_REPORTSMARTPHARMACY_FILTER_BRANCH = "RESET_REPORTSMARTPHARMACY_FILTER_BRANCH";
export const RESET_REPORTSMARTPHARMACY_FILTER_REST = "RESET_REPORTSMARTPHARMACY_FILTER_REST";
export const SET_SMARTPHARMACY_SUMMARYREPORT = "SET_SMARTPHARMACY_SUMMARYREPORT";
export const SET_SMARTPHARMACY_NOMINATIONREPORT = "SET_SMARTPHARMACY_NOMINATIONREPORT";
export const SET_SMARTPHARMACY_MEDICATIONREPORT = "SET_SMARTPHARMACY_MEDICATIONREPORT";
export const GET_SUMMARY_REPORT_PENDING = "GET_SUMMARY_REPORT_PENDING";
export const GET_SUMMARY_REPORT_SUCCESS = "GET_SUMMARY_REPORT_SUCCESS";
export const GET_SUMMARY_REPORT_ERROR = "GET_SUMMARY_REPORT_ERROR";
export const GET_NOMINATION_REQUESTS_PENDING = "GET_NOMINATION_REQUESTS_PENDING";
export const GET_NOMINATION_REQUESTS_SUCCESS = "GET_NOMINATION_REQUESTS_SUCCESS";
export const GET_NOMINATION_REQUESTS_ERROR = "GET_NOMINATION_REQUESTS_ERROR";
export const GET_MEDICATION_REQUESTS_PENDING = "GET_MEDICATION_REQUESTS_PENDING";
export const GET_MEDICATION_REQUESTS_SUCCESS = "GET_MEDICATION_REQUESTS_SUCCESS";
export const GET_MEDICATION_REQUESTS_ERROR = "GET_MEDICATION_REQUESTS_ERROR";
export const SET_SMARTPHARMACY_PRESCRIPTIONREPORT = "SET_SMARTPHARMACY_PRESCRIPTIONREPORT";
export const GET_PRESCRIPTION_REQUESTS_PENDING = "GET_PRESCRIPTION_REQUESTS_PENDING";
export const GET_PRESCRIPTION_REQUESTS_SUCCESS = "GET_PRESCRIPTION_REQUESTS_SUCCESS";
export const GET_PRESCRIPTION_REQUESTS_ERROR = "GET_PRESCRIPTION_REQUESTS_ERROR";

export const DEFAULT_REPORTSMARTPHARMACY_STARTDATE = getPreviousMonthDate();
export const DEFAULT_REPORTSMARTPHARMACY_ENDDATE = new Date();
export const DEFAULT_REPORTSMARTPHARMACY_MAXDATE = new Date();

export const SET_REPORTSMARTPHARMACY_DOWNLOAD_OVERLAY = "SET_REPORTSMARTPHARMACY_DOWNLOAD_OVERLAY";
export const GET_REPORTSMARTPHARMACY_DOWNLOAD_PENDING = "GET_REPORTSMARTPHARMACY_DOWNLOAD_PENDING";
export const GET_REPORTSMARTPHARMACY_DOWNLOAD_SUCCESS = "GET_REPORTSMARTPHARMACY_DOWNLOAD_SUCCESS";
export const GET_REPORTSMARTPHARMACY_DOWNLOAD_ERROR = "GET_REPORTSMARTPHARMACY_DOWNLOAD_ERROR";
export const SET_REPORTSMARTPHARMACY_DOWNLOAD_ITEMS = "SET_REPORTSMARTPHARMACY_DOWNLOAD_ITEMS";
