import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Form, FormItem, Button, Input, InputLabel, InputError, Checkbox } from '@patient-access/ui-kit';
import * as RegEx from '../../../../constants/RegExConstants';
import agent from '../../../../service/agent';
import locale from "service/locale";
import Recaptcha from "components/Share/Recaptcha/Recaptcha";

type Props = {
  intl: any,
};

type State = {
  name: string,
  nameError: string,
  email: string,
  emailError: string,
  phone: number,
  phoneError: string,
  pharmacy: string,
  pharmacyError: string,
  postcode: string,
  postcodeError: string,
  contact: boolean,
  contactError: string,
  formSubmitted: boolean,
  formError: boolean
};

const recaptcha = React.createRef();

class SectionMoreForm extends Component<Props, State> {

  state = {
    name: '',
    nameError: '',
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    pharmacy: '',
    pharmacyError: '',
    postcode: '',
    postcodeError: '',
    contact: false,
    contactError: '',
    formSubmitted: false,
    formError: false,
  };

  onChange = async (response) => {
    try {
        await agent.Register.sendRegister({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        pharmacy: this.state.pharmacy,
        postCode: this.state.postcode
      },
      response);
      this.setState({ formSubmitted: true, formError: false });
    } catch (error) {
      recaptcha.current.reset();
      this.setState({ formError: true });
    }
  }

  submitHandler = (e: any) => {
    e.preventDefault();
    if (recaptcha) {
      recaptcha.current.execute();
    } else {
      this.setState({ formError: true });
      recaptcha.current.reset();
    }
  }

  blurHandlerName = () => {
    if (this.state.name) return this.setState({ nameError: '' });
    return this.setState({ nameError: 'empty' });
  }

  blurHandlerEmail = () => {
    if (this.state.email) {
      if (this.state.email.match(RegEx.EMAIL)) return this.setState({ emailError: '' });
      return this.setState({ emailError: 'invalid_email' });
    }
    return this.setState({ emailError: 'empty' });
  }

  blurHandlerPhone = () => {
    if (this.state.phone) {
      if (this.state.phone.match(RegEx.PHONE)) return this.setState({ phoneError: '' });
      return this.setState({ phoneError: 'invalid_phone' });
    }
    return this.setState({ phoneError: 'empty' });
  }

  blurHandlerPharmacy = () => {
    if (this.state.pharmacy) return this.setState({ pharmacyError: '' });
    return this.setState({ pharmacyError: 'empty' });
  }

  blurHandlerPostcode = () => {
    if (this.state.postcode) {
      if (this.state.postcode.match(RegEx.POSTCODE)) return this.setState({ postcodeError: '' });
      return this.setState({ postcodeError: 'invalid_postcode' });
    }
    return this.setState({ postcodeError: 'empty' });
  }

  blurHandlerContact = () => {
    if (this.state.contact) return this.setState({ contactError: '' });
    return this.setState({ contactError: 'empty' });
  }

  renderIsDisabled() {
    const { name, nameError, email, emailError, phone, phoneError, pharmacy, pharmacyError, postcode, postcodeError, contact, contactError } = this.state;
    if (name && !nameError && email && !emailError && phone && !phoneError && pharmacy && !pharmacyError && postcode && !postcodeError && contact && !contactError) return false;
    return true;
  }

  renderErrorMessage(error) {
    if (error) return <InputError message={this.props.intl.messages[`landing.more.form.error_${error}`]} />;
    return null;
  }

  render() {
    const { name, nameError, email, emailError, phone, phoneError, pharmacy, pharmacyError, postcode, postcodeError, contact, contactError, formSubmitted, formError } = this.state;
    if (formSubmitted) {
      return (
        <div className="section-more-contact">
          <p><strong><FormattedMessage id="landing.more.form.success" /></strong></p>
        </div>
      );
    }
    if (formError) {
      return (
        <div className="section-more-contact">
          <p><strong><FormattedMessage id="landing.more.form.error" /></strong></p>
        </div>
      );
    }
    return (
      <Fragment>
        <div className="section-more-body">
          <h2><FormattedMessage id="landing.more.heading" /></h2>
          <p><FormattedMessage id="landing.more.content" /></p>
        </div>
        <Recaptcha recaptcha={recaptcha} recaptchaError={locale.Recaptcha.error.signIn} onChange={this.onChange} />
        <div className="section-more-contact">
          <Form noValidate>
            <fieldset>
              <FormItem type="item" error={nameError}>
                <InputLabel
                  htmlFor="name"
                  message={this.props.intl.messages['landing.more.form.name_label']}
                  hidden
                />
                <Input
                  type="text"
                  id="name"
                  name="name"
                  maxLength={100}
                  placeholder={this.props.intl.messages['landing.more.form.name_placeholder']}
                  spellCheck={false}
                  onChange={(e) => { this.setState({ name: e.target.value, nameError: '' }); }}
                  onBlur={this.blurHandlerName}
                  value={name}
                />
                {this.renderErrorMessage(nameError)}
              </FormItem>
              <FormItem type="item" error={emailError}>
                <InputLabel
                  htmlFor="email"
                  message={this.props.intl.messages['landing.more.form.email_label']}
                  hidden
                />
                <Input
                  type="text"
                  id="email"
                  name="email"
                  placeholder={this.props.intl.messages['landing.more.form.email_placeholder']}
                  spellCheck={false}
                  onChange={(e) => { this.setState({ email: e.target.value, emailError: '' }); }}
                  onBlur={this.blurHandlerEmail}
                  value={email}
                />
                {this.renderErrorMessage(emailError)}
              </FormItem>
              <FormItem type="item" error={phoneError}>
                <InputLabel
                  htmlFor="phone"
                  message={this.props.intl.messages['landing.more.form.phone_label']}
                  hidden
                />
                <Input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder={this.props.intl.messages['landing.more.form.phone_placeholder']}
                  spellCheck={false}
                  onChange={(e) => { this.setState({ phone: e.target.value, phoneError: '' }); }}
                  onBlur={this.blurHandlerPhone}
                  value={phone}
                />
                {this.renderErrorMessage(phoneError)}
              </FormItem>
              <div className="form-item form-row">
                <FormItem type="item" error={pharmacyError}>
                  <InputLabel
                    htmlFor="pharmacy"
                    message={this.props.intl.messages['landing.more.form.pharmacy_label']}
                    hidden
                  />
                  <Input
                    type="text"
                    id="pharmacy"
                    name="pharmacy"
                    maxLength={100}
                    placeholder={this.props.intl.messages['landing.more.form.pharmacy_placeholder']}
                    spellCheck={false}
                    onChange={(e) => { this.setState({ pharmacy: e.target.value, pharmacyError: '' }); }}
                    onBlur={this.blurHandlerPharmacy}
                    value={pharmacy}
                  />
                  {this.renderErrorMessage(pharmacyError)}
                </FormItem>
                <FormItem type="item" error={postcodeError}>
                  <InputLabel
                    htmlFor="postcode"
                    message={this.props.intl.messages['landing.more.form.postcode_label']}
                    hidden
                  />
                  <Input
                    type="text"
                    id="postcode"
                    name="postcode"
                    placeholder={this.props.intl.messages['landing.more.form.postcode_placeholder']}
                    spellCheck={false}
                    onChange={(e) => { this.setState({ postcode: e.target.value, postcodeError: '' }); }}
                    onBlur={this.blurHandlerPostcode}
                    value={postcode}
                  />
                  {this.renderErrorMessage(postcodeError)}
                </FormItem>
              </div>
              <FormItem type="item">
                <div className="form-contact">
                  <Checkbox
                    id="contact"
                    name="contact"
                    value="contact"
                    label={this.props.intl.messages['landing.more.form.contact_label']}
                    onChange={(e) => { this.setState({ contact: e.target.checked, contactError: '' }); }}
                    onBlur={this.blurHandlerContact}
                    checked={contact}
                  />
                  {this.renderErrorMessage(contactError)}
                </div>
              </FormItem>
            </fieldset>
            <FormItem type="buttons">
              <Button
                buttonType="secondary"
                buttonLength="full"
                messageKey="landing.more.form.button"
                onClick={this.submitHandler}
                isDisabled={this.renderIsDisabled()}
              />
            </FormItem>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(SectionMoreForm);
