import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { periodsList } from "constants/BranchesConstants";
import { formatListAsOptions, formatItemAsOption } from "helpers/formatData";
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";
import { setService, filterSlotsByCalendar, getSlots } from 'actions/bookAppointment';
import { releaseSlot } from "actions/bookAppointment";
import { closeBooking, closeInternalEvent } from "actions/panel";
import { clearForm, updateForm } from "actions/form";

import locale from 'service/locale';
import * as ServiceConstants from "constants/ServicesConstants";

type Props = {
  calendarsList: any[],
  selectedService: Object,
  bookingCalendar: Object,
  setService: (service: Object) => any,
  filterSlotsByCalendar: (calendar: Object) => any,
  getSlots: (branchId: string) => Function,
  releaseSlot: () => Function,
  clearForm: () => any,
  closeBooking: () => any,
  appointmentId: string
};

const mapStateToProps = state => ({
  calendarsList: state.branchDetails.calendarsList,
  selectedService: state.book.service,
  bookingCalendar: state.book.calendar,
  appointmentId: state.book.appointmentId
});

const mapDispatchToProps = (dispatch: any) => ({
  setService: (service) => dispatch(setService(service)),
  getSlots: (branchId) => dispatch(getSlots(branchId)),
  filterSlotsByCalendar: (calendar) => dispatch(filterSlotsByCalendar(calendar)),
  clearForm: () => dispatch(clearForm()),
  closeBooking: () => dispatch(closeBooking()),
  releaseSlot: () => dispatch(releaseSlot()),
  updateForm: (data: any) => dispatch(updateForm(data)),
  closeInternalEvent: () => dispatch(closeInternalEvent())
});

class CustomAppointmentFilter extends Component<Props, State> {
  handleDurationChange = data => {
    const { setService, selectedService, getSlots, branchId, closeBooking, clearForm, releaseSlot, updateForm, closeInternalEvent, appointmentId } = this.props;
    setService({
      name: selectedService.name,
      id: selectedService.id,
      duration: data.value,
      price: selectedService.price,
      lastMinute: selectedService.lastMinute,
      type: selectedService.appointmentType,
      modes: selectedService.modes
    });
    clearForm();
    closeBooking();
    closeInternalEvent();
    updateForm({ duration: data.value });
    if (!!appointmentId) {
      releaseSlot()
        .then(() =>
          getSlots(branchId)
        );
    }
    else {
      getSlots(branchId);
    }
  };

  handleChangeCalendar = (calendar) => {
    const { filterSlotsByCalendar, getSlots, branchId, closeBooking, clearForm, releaseSlot, closeInternalEvent, appointmentId } = this.props;
    clearForm();
    closeBooking();
    closeInternalEvent();
    if (!!appointmentId) {
      releaseSlot()
        .then(() =>
        filterSlotsByCalendar(calendar)
            .then(() =>
              getSlots(branchId)));
    }
    else {
      filterSlotsByCalendar(calendar)
        .then(() =>
          getSlots(branchId))
    }
  };

  render() {

    const { calendarsList, bookingCalendar, selectedService } = this.props;
    const calendarFilterOptions = formatListAsOptions(calendarsList);
    const isCustomAppointment = selectedService.id === ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID
    return (
      <div className="patient-care-block">
        <div className="patient-care-form-group">
          <Form noValidate>
            <FormItem type="item">
              <InputLabel
                htmlFor="calendar_select"
                message={locale.AppointmentSlots.customAppointmentFilter.calendar}
                size="small"
              />
            </FormItem>
          </Form>
          <Select
            id="calendar_select"
            name="calendar_select"
            defaultValue={formatItemAsOption(bookingCalendar)}
            options={calendarFilterOptions}
            placeholder={locale.AppointmentSlots.customAppointmentFilter.placeholderCalendar}
            onChange={this.handleChangeCalendar}
            classNamePrefix="patient-care"
          />
        </div>
        <Form noValidate>
          <FormItem type="item">
            <InputLabel
              htmlFor="duration_select"
              message={isCustomAppointment ?
                locale.AppointmentSlots.customAppointmentFilter.duration :
                locale.AppointmentSlots.internalEventFilter.duration}
              size="small"
            />
          </FormItem>
        </Form>
        <Select
          id="duration_select"
          name="duration_select"
          defaultValue={periodsList[0]}
          options={periodsList}
          placeholder={locale.AppointmentSlots.customAppointmentFilter.placeholderDuration}
          onChange={this.handleDurationChange}
          classNamePrefix="patient-care"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAppointmentFilter);
