import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { LogoNHS } from '@patient-access/ui-kit';
import Wrapper from '../Wrapper/Wrapper';
import './styles.scss';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Wrapper>
          <div className="footer-info">
            <div className="footer-info-links">
              <a rel="noopener noreferrer" href="https://supportpro.patientaccess.com/terms-of-use"><FormattedMessage id="landing.footer.link.terms" /></a>
              <a rel="noopener noreferrer" href="https://supportpro.patientaccess.com/privacy-policy"><FormattedMessage id="landing.footer.link.privacy_policy" /></a>
              <a rel="noopener noreferrer" href="https://supportpro.patientaccess.com"><FormattedMessage id="landing.footer.link.support" /></a>
            </div>
            <div className="footer-info-logos">
              <LogoNHS />
            </div>
          </div>
          <div className="footer-copyright">
            <p><strong><FormattedMessage id="landing.footer.copyright" /></strong></p>
          </div>
        </Wrapper>
      </footer>
    );
  }
}

export default Footer;
