import * as PanelConstants from 'constants/PanelConstants';
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import type { PanelActions } from 'types/panel';
import { setAppointmentDetails } from 'actions/appointmentDetails';
import {setBranchHeader} from "./router";

export const openAgenda = (): PanelActions => ({
  type: PanelConstants.OPEN_AGENDA,
});

export const closeAgenda = (): PanelActions => ({
  type: PanelConstants.CLOSE_AGENDA,
});

export const openSearch = (): PanelActions => ({
  type: PanelConstants.OPEN_SEARCH
});

export const closeSearch = (): PanelActions => ({
  type: PanelConstants.CLOSE_SEARCH
});

export const completeSearch = (): PanelActions => ({
  type: PanelConstants.COMPLETE_SEARCH
});

export const openSlots = (): PanelActions => ({
  type: PanelConstants.OPEN_SLOTS
});

export const closeSlots = (): PanelActions => ({
  type: PanelConstants.CLOSE_SLOTS
});

export const openBooking = (): PanelActions => ({
  type: PanelConstants.OPEN_BOOKING
});

export const closeBooking = (): PanelActions => ({
  type: PanelConstants.CLOSE_BOOKING
});

export const openUserDetails = (): PanelActions => ({
  type: PanelConstants.OPEN_USER_DETAILS
});

export const closeUserDetails = (): PanelActions => ({
  type: PanelConstants.CLOSE_USER_DETAILS
});

export const openInternalEvent = (): PanelActions => ({
 type: PanelConstants.OPEN_INTERNAL_EVENT
});

export const closeInternalEvent = (): PanelActions => ({
  type: PanelConstants.CLOSE_INTERNAL_EVENT
 });

export const openInternalEventDetails = (): PanelActions => ({
  type: PanelConstants.OPEN_INTERNAL_EVENT_DETAILS
});

export const closeInternalEventDetails = (): PanelActions => ({
  type: PanelConstants.CLOSE_INTERNAL_EVENT_DETAILS
}); 

export const openAgendaDetails = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const agendaBranchId = currentState.calendarView.branchId;
  const isActiveAdmin = currentState.profile.isActiveAdmin;
  isActiveAdmin && dispatch(setBranchHeader(agendaBranchId));
  dispatch({
    type: PanelConstants.OPEN_AGENDA_DETAILS
  });
};

export const closeAgendaDetails = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const defaultBranchId = currentState.router.branchId;
  const isActiveAdmin = currentState.profile.isActiveAdmin;
  isActiveAdmin && dispatch(setBranchHeader(defaultBranchId));
  dispatch({
    type: PanelConstants.CLOSE_AGENDA_DETAILS
  });
};

export const openSearchDetails = (): PanelActions => ({
  type: PanelConstants.OPEN_SEARCH_DETAILS
});

export const closeSearchDetails = (): PanelActions => ({
  type: PanelConstants.CLOSE_SEARCH_DETAILS
});

export const openAddOrganisation = (): PanelActions => ({
  type: PanelConstants.OPEN_ADD_ORGANISATION
});

export const closeAddOrganisation = (): PanelActions => ({
  type: PanelConstants.CLOSE_ADD_ORGANISATION
});

export const openAddOrganisationError = (): PanelActions => ({
  type: PanelConstants.OPEN_ADD_ORGANISATION_ERROR
});

export const closeAddOrganisationError = (): PanelActions => ({
  type: PanelConstants.CLOSE_ADD_ORGANISATION_ERROR
});

export const openAddBranchError = (): PanelActions => ({
  type: PanelConstants.OPEN_ADD_BRANCH_ERROR
});

export const closeAddBranchError = (): PanelActions => ({
  type: PanelConstants.CLOSE_ADD_BRANCH_ERROR
});

export const openAddBranchNameError = (): PanelActions => ({
  type: PanelConstants.OPEN_ADD_BRANCH_NAME_ERROR
});

export const closeAddBranchNameError = (): PanelActions => ({
  type: PanelConstants.CLOSE_ADD_BRANCH_NAME_ERROR
});

export const openAddBranchOdsError = (): PanelActions => ({
  type: PanelConstants.OPEN_ADD_BRANCH_ODS_ERROR
});

export const closeAddBranchOdsError = (): PanelActions => ({
  type: PanelConstants.CLOSE_ADD_BRANCH_ODS_ERROR
});

export const openProfile = (): PanelActions => ({
  type: PanelConstants.OPEN_PROFILE
});

export const closeProfile = (): PanelActions => ({
  type: PanelConstants.CLOSE_PROFILE
});

export const openModalOverlay = (): PanelActions => ({
  type: PanelConstants.OPEN_MODAL_OVERLAY
});

export const closeModalOverlay = (): PanelActions => ({
  type: PanelConstants.CLOSE_MODAL_OVERLAY
});

export const openCalendarModalOverlay = (): PanelActions => ({
  type: PanelConstants.OPEN_CALENDAR_MODAL_OVERLAY
});

export const closeCalendarModalOverlay = (): PanelActions => ({
  type: PanelConstants.CLOSE_CALENDAR_MODAL_OVERLAY
});

export const openChangePassword = (): PanelActions => ({
  type: PanelConstants.OPEN_CHANGE_PASSWORD
});

export const closeChangePassword = (): PanelActions => ({
  type: PanelConstants.CLOSE_CHANGE_PASSWORD
});

export const openProfileDropdown = (): PanelActions => ({
  type: PanelConstants.OPEN_PROFILE_DROPDOWN
});

export const closeProfileDropdown = (): PanelActions => ({
  type: PanelConstants.CLOSE_PROFILE_DROPDOWN
});

export const openConfirmationDeleteUser = (): PanelActions => ({
  type: PanelConstants.OPEN_CONFIRMATION_DELETE_USER
});

export const closeConfirmationDeleteUser = (): PanelActions => ({
  type: PanelConstants.CLOSE_CONFIRMATION_DELETE_USER
});

export const openRoleConflict = (): PanelActions => ({
  type: PanelConstants.OPEN_ROLE_CONFLICT
});

export const closeRoleConflict = (): PanelActions => ({
  type: PanelConstants.CLOSE_ROLE_CONFLICT
});

export const openDuplicateConflict = (userId: string): PanelActions => ({
  type: PanelConstants.OPEN_DUPLICATE_CONFLICT,
  userId
});

export const closeDuplicateConflict = (): PanelActions => ({
  type: PanelConstants.CLOSE_DUPLICATE_CONFLICT
});

export const openDeleteOrganisationConflictOverlay = (appointmentIds: Array<string>): PanelActions => ({
  type: PanelConstants.OPEN_DELETE_ORGANIZATION_CONFLICT,
  appointmentIds
});

export const closeDeleteOrganisationConflictOverlay = (): PanelActions => ({
  type: PanelConstants.CLOSE_DELETE_ORGANIZATION_CONFLICT
});

export const openCalendarNameConflict = (): PanelActions => ({
  type: PanelConstants.OPEN_CALENDAR_NAME_CONFLICT
});

export const openDiscardAppointmentChanges = (): PanelActions => ({
  type: PanelConstants.OPEN_DISCARD_CHANGES
});

export const closeDiscardAppointmentChanges = (): PanelActions => ({
  type: PanelConstants.CLOSE_DISCARD_CHANGES
});

export const closeCalendarNameConflict = (): PanelActions => ({
  type: PanelConstants.CLOSE_CALENDAR_NAME_CONFLICT
});

export const openCalendarAvailabilityConflict = (): PanelActions => ({
  type: PanelConstants.OPEN_CALENDAR_AVAILABILITY_CONFLICT
});

export const closeCalendarAvailabilityConflict = (): PanelActions => ({
  type: PanelConstants.CLOSE_CALENDAR_AVAILABILITY_CONFLICT
});

export const throwGeneralError = (): PanelActions => ({
  type: PanelConstants.THROW_GENERAL_ERROR
});

export const resetGeneralError = (): PanelActions => ({
  type: PanelConstants.RESET_GENERAL_ERROR
});

export const openCalendarDetails = (): PanelActions => ({
  type: PanelConstants.OPEN_CALENDAR_DETAILS
});

export const closeCalendarDetails = (): PanelActions => ({
  type: PanelConstants.CLOSE_CALENDAR_DETAILS
});

export const openTwoFactorOverlay = (): PanelActions => ({
  type: PanelConstants.OPEN_REMOVE_TWO_FACTOR_OVERLAY
});

export const closeTwoFactorOverlay = (): PanelActions => ({
  type: PanelConstants.CLOSE_REMOVE_TWO_FACTOR_OVERLAY
});


//TODO: uncomment if old results of search will be needed
// export const focusSearch = () => (dispatch: (action: any) => PanelActions, getState: () => any) => {
//   const currentState = getState();
//   const { isActiveSearchResults, isSearchCompleted, isActiveAppointmentSlots } = currentState.panel;
//   //TODO: clarify if uncomment next line
//   //dispatch(setAppointmentDetails());
//   isActiveAppointmentSlots && dispatch(closeSlots());
//   !isActiveSearchResults && isSearchCompleted && dispatch(openSearch());
// };

export const toggleAgenda = () => (dispatch: (action: any) => PanelActions, getState: Function) => {
  const currentState = getState();
  const isActiveAgenda = currentState.panel.isActiveAgenda;
  const isAppointmentDataChanged = currentState.appointmentDetails.isAppointmentDataChanged;
  if (!isAppointmentDataChanged) {
    dispatch(setAppointmentDetails(null, AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA));
    localStorage.setItem('isActiveAgenda', JSON.stringify(!isActiveAgenda));
    isActiveAgenda
      ? dispatch(closeAgenda())
      : dispatch(openAgenda());
  }
  else {
    dispatch(openDiscardAppointmentChanges());
  }
};

export const setScreenWidth = (isLowResolution: boolean) => (dispatch: (action: any) => PanelActions) => {
  const needUpdate = isLowResolution !== (window.innerWidth <= PanelConstants.LOW_RESOLUTION_POINT);
  needUpdate && dispatch({
    type: PanelConstants.SET_SCREEN_WIDTH,
    payload: window.innerWidth <= PanelConstants.LOW_RESOLUTION_POINT
  });
};
