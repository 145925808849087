import React, { Component } from 'react';
import { IconSearch } from '@patient-access/ui-kit';

import './styles/skeleton-search-filter.scss';

class SkeletonSearchFilter extends Component {
  render() {
    return (
      <div className="skeleton-search-filter">
        <i><IconSearch outline /></i>
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-70" />
      </div>
    );
  }
};

export default SkeletonSearchFilter;
