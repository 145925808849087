import { BlobServiceClient } from '@azure/storage-blob';

const getHostUrl = (accountName) => {
  return `https://${accountName}.blob.core.windows.net/`;
};

const getCDNHostUrl = (cdnName) => {
  return `https://${cdnName}.azureedge.net/`;
};

const getCdnBaseUrl = (options) => {
  const { cdnName, containerName } = options;
  return `${getCDNHostUrl(cdnName)}${containerName}/`;
};

const getConnectionString = (options) => {
  const { accountName, sasToken } = options;
  return `BlobEndpoint=${getHostUrl(accountName)};SharedAccessSignature=${sasToken}`;
};

const getFormattedFileName = (fileName) => {
  const fileNameTrimmed = fileName && fileName.replaceAll(" ", "").trim();
  return `${fileNameTrimmed}_logo_${new Date().getTime()}`;
};

const getBlockBlobNameFromUrl = (url) => {
  const urlSegments = url && url.split('/');
  const folderName = urlSegments[urlSegments.length - 2];
  const fileName = urlSegments[urlSegments.length - 1];
  return `${folderName}/${fileName}`;
}

const buildContainerClient = (options) => {
  const blobServiceClient = BlobServiceClient.fromConnectionString(getConnectionString(options));
  return blobServiceClient.getContainerClient(options.containerName);
}

const buildBlockBlobClient = (options, filePath) => {
  const containerClient = buildContainerClient(options);
  return containerClient.getBlockBlobClient(filePath);
};

export const uploadBlobBlock = async (file, options, fileName) => {
  const uploadOptions = {
    blobHTTPHeaders: {
      blobContentType: file.type
    },
  };

  const formattedFileName = getFormattedFileName(fileName);
  const filePath = `OrganisationLogos/${formattedFileName}`;

  const blockBlobClient = buildBlockBlobClient(options, filePath);
  await blockBlobClient.uploadData(file, uploadOptions);

  return `${getCdnBaseUrl(options)}${filePath}`;
};

export const deleteBlobBlock = async (logoUrl, options) =>  {
  const containerClient = buildContainerClient(options);
  await containerClient.deleteBlob(getBlockBlobNameFromUrl(logoUrl));
};
