import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, FormItem, InputLabel, Input } from "@patient-access/ui-kit";
import { updateForm } from "actions/form";
import type { Action } from 'types/actions';
import locale from "service/locale";

type Props = {
  updateForm: (data: any) => Action;
  isDuplicateNameError: boolean;
  isEmptyNameError: boolean;
  name: string
};

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: (data) => dispatch(updateForm(data)),
});

class AddNewCalendarName extends Component<Props> {

  handleNameChange = ({ target: { name, value } }) => {
    const { updateForm } = this.props;
    value.length <= locale.AddNewCalendar.maxCalendarNameLength && updateForm({ [name]: value });
  };

  render() {
    const { isDuplicateNameError, isEmptyNameError, name } = this.props;
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-block">
            <p>{locale.AddNewCalendar.description}</p>
          </div>
          <div className="patient-care-block-row">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="name"
                    message={locale.AddNewCalendar.name}
                    size="small"
                  />
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={this.handleNameChange}
                    data-id="name"
                    placeholder={locale.AddNewCalendar.title}
                    value={name}
                  />
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        {
          isDuplicateNameError &&
          <div className="patient-care-input-error">
            <p>{locale.AddNewCalendar.duplicateNameText}</p>
          </div>
        }
        {
          isEmptyNameError &&
          <div className="patient-care-input-error">
            <p>{locale.AddNewCalendar.emptyNameText}</p>
          </div>
        }
        {name.length >= locale.AddNewCalendar.maxCalendarNameLength && (
              <div className="patient-care-input-error">
                <p>{locale.AddNewCalendar.longNameText}</p>
              </div>
            )}
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(AddNewCalendarName);
