import React, { Component } from 'react'
import {
  Overlay,
  Button,
} from "@patient-access/ui-kit";
import AppointmentStatus from "./../../AppointmentDetailsContent/AppointmentStatus/AppointmentStatus";
import locale from 'service/locale';

type Props = {
  handleSetAppointmentStatus: (status: number, outcomeStatus: number, behalfOfPatient: any, isForced: boolean) => any;
  handleCloseOverlay: () => any;
}

type State = {
  isNewStatusSelected: boolean,
  status: number,
  outcomeStatus: number,
  behalfOfPatient: any
}

export default class NotUpdatedPastOverlay extends Component<Props, State> {
  state = {
    isNewStatusSelected: false,
    status: 0,
    outcomeStatus: 0,
    behalfOfPatient: null
  };

  handleSaveStatus = () => {
    const { status, outcomeStatus, behalfOfPatient } = this.state;
    const { handleSetAppointmentStatus } = this.props;
    handleSetAppointmentStatus(status, outcomeStatus, behalfOfPatient, true);
  };

  handleCloseOverlay = () => {
    const { handleCloseOverlay } = this.props;
    handleCloseOverlay();
  };

  handleAppointmentStatusChange = (newAppointmentStatus: any) => {
    const { status, outcomeStatus, behalfOfPatient } = newAppointmentStatus;
    this.setState({ isNewStatusSelected: true, status, outcomeStatus, behalfOfPatient });
  };

  render() {
    const { isNewStatusSelected, status, outcomeStatus } = this.state;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.Modals.notUpdatedOverlay.header}</h1>
        </div>

        <div className="overlay-confirmation-content">
          <AppointmentStatus
            status={status}
            isPastAppointment={true}
            isTodayAppointment={false}
            outcomeStatus={outcomeStatus}
            isDisabled={false}
            handleAppointmentStatusChange={this.handleAppointmentStatusChange}
          />
        </div>

        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="ok-btn"
            defaultMessage={locale.Modals.notUpdatedOverlay.buttonOk}
            onClick={this.handleSaveStatus}
            data-id="ok-btn"
            className="patient-care-btn-in-group"
            isDisabled={!isNewStatusSelected}
          />
          <Button
            buttonType="secondary"
            messageKey="cancel-btn"
            defaultMessage={locale.Modals.notUpdatedOverlay.buttonCancel}
            onClick={this.handleCloseOverlay}
            data-id="cancel-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    )
  }
}
