import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button } from '@patient-access/ui-kit';
import { ReportsContent, ReportsContentSticky, ReportsContentScroll } from 'components/Pages/Reports/wrappers';
import { setActiveAdminMenu, setActiveReportsMenu } from 'actions/profile';
import { getSmartPharmacySummaryReport, getSmartPharmacyNominationReport, getSmartPharmacyMedicationReport, getSmartPharmacyPrescriptionReport } from 'actions/reportsSmartPharmacy';
import { ReportsFilter, ReportsFilterSkeleton } from './ReportsFilter';
import { CardNominations, CardNominationsSkeleton } from './CardNominations';
import { CardFees, CardFeesSkeleton } from './CardFees';
import { CardPharmacy, CardPharmacySkeleton } from './CardPharmacy';
import { CardNominationsChart, CardNominationsChartSkeleton } from './CardNominationsChart';
import { CardMedicationChart, CardMedicationChartSkeleton } from './CardMedicationChart';
import { CardDeliveryTrendChart, CardDeliveryTrendChartSkeleton } from './CardDeliveryTrendChart';
import { CardPrescriptionRequestsChart, CardPrescriptionRequestsChartSkeleton } from './CardPrescriptionRequestsChart';
import * as RolesConstants from 'constants/RolesConstants';
import './styles.scss';
import DownloadOverlay from './ReportsOverlay/DownloadOverlay';
import locale from 'service/locale';

// isEmpty to be handled inside CardComponents

type Props = {
  currentRole: any,
  profileOrganisation: Organisation,
  getSummaryReport: () => Action,
  getNominationReport: () => Action,
  getMedicationReport: () => Action,
  getPrescriptionReport: () => Action,
  setActiveAdminMenu: (status: boolean) => Action,
  setActiveReportsMenu: (status: boolean) => Action,
};

type State = {
  isResizing: boolean,
};

const mapStateToProps = (state) => ({
  filters: state.reportsSmartPharmacy.filters,
  summary: state.reportsSmartPharmacy.metrics.summary,
  nominationRequests: state.reportsSmartPharmacy.metrics.nomination,
  medicationRequests: state.reportsSmartPharmacy.metrics.medication,
  prescriptionRequests: state.reportsSmartPharmacy.metrics.prescription,
  currentRole: state.roles.profileCurrentRole,
  profileOrganisation: state.profile.organisation,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getSummaryReport: () => dispatch(getSmartPharmacySummaryReport()),
  getNominationReport: () => dispatch(getSmartPharmacyNominationReport()),
  getMedicationReport: () => dispatch(getSmartPharmacyMedicationReport()),
  getPrescriptionReport: () => dispatch(getSmartPharmacyPrescriptionReport()),
  setActiveAdminMenu: (status) => dispatch(setActiveAdminMenu(status)),
  setActiveReportsMenu: (status) => dispatch(setActiveReportsMenu(status)),
});

class SmartPharmacy extends Component<Props, State> {
  state = {
    isResizing: false,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.setIsResizing);
    // SET HEADER MENU ACTIVE CLASS
    const { setActiveAdminMenu, setActiveReportsMenu } = this.props;
    setActiveAdminMenu(false);
    setActiveReportsMenu(true);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setIsResizing);
    if (this.resizeTimer) clearTimeout(this.resizeTimer);
  }

  setIsResizing = () => {
    this.setState({ isResizing: true });
    if (this.resizeTimer) clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.setState({ isResizing: false });
    }, 1000);
  }

  handleReportFilterChange = () => {
    const { getSummaryReport, getNominationReport, getMedicationReport, getPrescriptionReport } = this.props;
    getSummaryReport();
    getNominationReport();
    getMedicationReport();
    getPrescriptionReport();
  }

  handlerTryAgainButton = (e: any) => {
    e.preventDefault();
    const { getSummaryReport, getNominationReport, getMedicationReport, getPrescriptionReport } = this.props;
    getSummaryReport();
    getNominationReport();
    getMedicationReport();
    getPrescriptionReport();
  }

  render() {
    const { isResizing } = this.state;
    const { currentRole, profileOrganisation, summary, nominationRequests, medicationRequests } = this.props;
    const { isSubscriptionModel } = summary.data;
    //This is to set report filter based on user role
    const userOrganisationId = currentRole.role !== RolesConstants.ADMIN ? currentRole.organizationId : null;

    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrgAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;

    const hasSingleBranch = (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) && (profileOrganisation.branches && profileOrganisation.branches.length === 1);
    const userBranchId = hasSingleBranch
      ? profileOrganisation.branches[0].id
      : [RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(currentRole.role)
        ? currentRole.branchId
        : null;

    const renderReportFilter = () => {
      const isLoading = false;
      if (isLoading) {
        return (
          <ReportsFilterSkeleton />
        )
      } else {
        return (
          <ReportsFilter handleReportFilterChange={this.handleReportFilterChange} userOrganisationId={userOrganisationId} userBranchId={userBranchId} />
        )
      }
    }

    const renderSummary = () => {
      const { filters } = this.props;
      const isOrgVisible = filters.organisationId === "" ? true : false;
      if (summary.isLoading) {
        return (
          <Fragment>
            <CardNominationsSkeleton />
            <CardFeesSkeleton />
            <CardPharmacySkeleton />
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <CardNominations />
            <CardFees />
            <CardPharmacy isSuperAdmin={isSuperAdmin} isOrgAdmin={isOrgAdmin} isOrgVisible={isOrgVisible} />
          </Fragment>
        )
      }
    }

    const renderNominationGraph = () => {
      if (nominationRequests.isLoading) {
        return (
          <CardNominationsChartSkeleton />
        )
      } else {
        return (
          <CardNominationsChart isResizing={isResizing} />
        )
      }
    }

    const renderMedicationGraph = () => {
      if (medicationRequests.isLoading) {
        return (
          <CardMedicationChartSkeleton />
        )
      } else {
        return (
          <CardMedicationChart isResizing={isResizing} isSubscriptionModel={isSubscriptionModel} />
        )
      }
    }

    const renderPrescriptionRequestsGraph = () => {
      if (nominationRequests.isLoading) {
        return (
          <CardPrescriptionRequestsChartSkeleton />
        )
      } else {
        return (
          <CardPrescriptionRequestsChart isResizing={isResizing} />
        )
      }
    }

    const renderDeliveryTrendGraph = () => {
      if (nominationRequests.isLoading) {
        return (
          <CardDeliveryTrendChartSkeleton />
        )
      } else {
        return (
          <CardDeliveryTrendChart isResizing={isResizing} />
        )
      }
    }

    const renderContent = () => {

      if (summary.isError || nominationRequests.isError || medicationRequests.isError) {
        return (
          <NoResults type="info">
            <h2>{locale.Reports.errorHeader}</h2>
            <Button buttonType="secondary" messageKey="errorButton" defaultMessage={locale.Reports.errorButton} onClick={this.handlerTryAgainButton} />
          </NoResults>
        );
      }

      return (
        <Fragment>
          <ReportsContentScroll>
            <div className="reports-content-nominations">
              <div className="reports-content-nominations-lside">
                {renderSummary()}
              </div>
              <div className="reports-content-nominations-rside">
                {renderNominationGraph()}
              </div>
            </div>
            <div className="reports-content-prescriptions">
              {renderMedicationGraph()}
            </div>
            <div className="reports-content-conversion-trend">
              <div className="reports-content-conversion-trend-lside">
                {renderDeliveryTrendGraph()}
              </div>
              <div className="reports-content-conversion-trend-rside">
                {renderPrescriptionRequestsGraph()}
              </div>
            </div>
          </ReportsContentScroll>
          <DownloadOverlay isSubscriptionModel={isSubscriptionModel} />
        </Fragment>
      );
    }

    return (
      <ReportsContent>
        <ReportsContentSticky>
          {renderReportFilter()}
        </ReportsContentSticky>
        {renderContent()}
      </ReportsContent>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartPharmacy);
