import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { setTaskManagerOverlayIsVisible, setTaskManagerOverlayType, cancelTask } from 'actions/taskManager';
import locale from 'service/locale';

type Props = {
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => any,
  setTaskManagerOverlayType: (type: string) => any,
  cancelTask : (taskId: string) => any,
  taskDetail: any,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch: any) => ({
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => dispatch(setTaskManagerOverlayIsVisible(isVisible)),
  setTaskManagerOverlayType: (type: string) => dispatch(setTaskManagerOverlayType(type)),
  cancelTask: (taskId: string) => dispatch(cancelTask(taskId)),
});

const setHeader = (taskName) => {
  var header = "Cancel ";
  if (taskName.includes("Add")) {
    header = header + taskName.replace("Add", "adding");
  } else if (taskName.includes("Edit")) {
    header = header + taskName.replace("Edit", "editing");
  } else if (taskName.includes("Remove")) {
    header = header + taskName.replace("Remove", "removing");
  }
  return header;
};

class OverlayCancelConfirmation extends Component<Props> {
  render() {
    const { setTaskManagerOverlayIsVisible, setTaskManagerOverlayType, cancelTask, taskDetail } = this.props;
    const header = setHeader(taskDetail.input.TaskName);
    return (
      (taskDetail && taskDetail.input) &&
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{header}</h1>
        </div>
        <div className="overlay-confirmation-content overlay-content-task-manager">
          <h4>This task will be removed from the queue.</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button buttonType="blueline" messageKey={locale.TaskManager.button.goBack} defaultMessage={locale.TaskManager.button.goBack} onClick={() => { setTaskManagerOverlayIsVisible(false); setTaskManagerOverlayType(''); }} />
          <Button buttonType="secondary" messageKey={locale.TaskManager.button.cancelTask} defaultMessage={locale.TaskManager.button.cancelTask} onClick={() => { cancelTask(taskDetail.input.TaskId); setTaskManagerOverlayIsVisible(false); }} />
        </div>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayCancelConfirmation);
