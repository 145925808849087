import * as CalendarsListConstants from 'constants/CalendarsListConstants';
import * as BranchesConstants from 'constants/BranchesConstants';
import { getToken } from 'helpers/common';
import { setOrganisationHeader, setBranchHeader } from 'actions/router';
import { setBranchDetails } from 'actions/branchDetails';
import agent from 'service/agent';

// FILTERS
export const setFiltersSearchValue = (value: string): Action => ({
  type: CalendarsListConstants.SET_CALENDARS_LIST_SEARCH_VALUE,
  payload: value,
});

export const setFiltersBranchesValue = (value: any): Action => ({
  type: CalendarsListConstants.SET_CALENDARS_LIST_BRANCHES_IDS,
  payload: value,
});

export const setFiltersServicesValue = (value: any): Action => ({
  type: CalendarsListConstants.SET_CALENDARS_LIST_SERVICES_IDS,
  payload: value,
});

// CALENDARS_LIST
export const setCalendarsListPending = (): Action => ({
  type: CalendarsListConstants.GET_CALENDARS_LIST_PENDING,
});

export const setCalendarsListSuccess = (): Action => ({
  type: CalendarsListConstants.GET_CALENDARS_LIST_SUCCESS,
});

export const setCalendarsListError = (): Action => ({
  type: CalendarsListConstants.GET_CALENDARS_LIST_ERROR,
});

export const setCalendarsListData = (data: any): Action => ({
  type: CalendarsListConstants.SET_CALENDARS_LIST_DATA,
  payload: data,
});

export const getCalendarsList = (searchValue: string, branchIds: Object, serviceIds: Object, pageNumber: number, organisationId?: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setCalendarsListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Calendars.getFilteredCalendarSearch(accessToken, searchValue, branchIds, serviceIds, pageNumber, organisationId)
      .then(calendarsList => {
        dispatch(setCalendarsListData(calendarsList));
        dispatch(setCalendarsListSuccess());
      })
      .catch(err => {
        console.log("getCalendarsList server error", err);
        dispatch(setCalendarsListError());
      });
    });
};

// CALENDARS_SERVICES
export const setCalendarsServicesPending = (): Action => ({
  type: CalendarsListConstants.GET_CALENDARS_SERVICES_PENDING,
});

export const setCalendarsServicesSuccess = (): Action => ({
  type: CalendarsListConstants.GET_CALENDARS_SERVICES_SUCCESS,
});

export const setCalendarsServicesError = (): Action => ({
  type: CalendarsListConstants.GET_CALENDARS_SERVICES_ERROR,
});

export const setCalendarsServicesData = (data: any): Action => ({
  type: CalendarsListConstants.SET_CALENDARS_SERVICES_DATA,
  payload: data,
});

export const getCalendarsServices = (organisationId?: string) => (dispatch: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setCalendarsServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getOrganisationServices(accessToken, organisationId)
      .then(calendarsServices => {
        dispatch(setCalendarsServicesData(calendarsServices));
        dispatch(setCalendarsServicesSuccess());
      })
      .catch(err => {
        console.log("getCalendarsServices server error", err);
        dispatch(setCalendarsServicesError());
      });
    });
};

export const getBranchCalendarsServices = (organisationId: string, branchId: string) => (dispatch: Function) => {
  dispatch(setCalendarsServicesPending());
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchHeader(branchId));
  getToken(dispatch)
    .then(accessToken => {
      branchId !== BranchesConstants.DEFAULT_BRANCHID &&
      agent.Branches.getBranchDetails(branchId, accessToken)
        .then(branchDetails => {
          dispatch(setBranchDetails(branchDetails));
          dispatch(setCalendarsServicesData(branchDetails.services));
          dispatch(setCalendarsServicesSuccess());
        })
        .catch(err => {
          dispatch(setCalendarsServicesError());
          console.log("getBranchCalendarsServices server error or branch is not found", err);
        });
    });
};

// CALENDAR_DETAILS
export const setCalendarDetailsOverlayData = (value: string): Action => ({
  type: CalendarsListConstants.SET_CALENDAR_DETAILS_OVERLAY_DATA,
  payload: value,
});
