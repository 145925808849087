import * as CalendarDropdownConstants from "constants/CalendarDropdownConstants";
import type { Action } from "types/actions";
import type { Organisation, Branch } from "types/organisation";

type CalendarDropdownState = {
  isOpened: boolean,
  selectedOrganisation: Organisation,
  selectedBranch: Branch,
};

const initialState: CalendarDropdownState = {
  isOpened: false,
  selectedOrganisation: {},
  selectedBranch: {},
};

export default (localState: CalendarDropdownState = initialState, action: Action): CalendarDropdownState => {
  switch (action.type) {
    case CalendarDropdownConstants.OPEN_DROPDOWN:
      return {
        ...localState,
        isOpened: true
      };
    case CalendarDropdownConstants.CLOSE_DROPDOWN:
      return {
        ...localState,
        isOpened: false
      };
    case CalendarDropdownConstants.SET_DROPDOWN_ORGANISATION:
      return {
        ...localState,
        selectedOrganisation: action.payload
      };
    case CalendarDropdownConstants.SET_DROPDOWN_BRANCH:
      return {
        ...localState,
        selectedBranch: action.payload
      };
    case CalendarDropdownConstants.RESET_DROPDOWN:
      return {
        isOpened: false,
        selectedOrganisation: {},
        selectedBranch: {}
      };
    default:
      return localState;
  }
};
