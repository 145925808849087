import React from "react";
import locale from 'service/locale';

const LoginFooterLinks = () => {
  return (
    <ul className="patient-care-unstyled-list patient-care-align-center">
      <li className="patient-care-footer-list-item">
        <a href={locale.Login.policyLink}>{locale.Login.policy}</a>
      </li>
      <li className="patient-care-footer-list-item">
        <a href={locale.Login.termsLink}>{locale.Login.terms}</a>
      </li>
      <li className="patient-care-footer-list-item">
        <a href={locale.Login.linkSupportCentre}>{locale.Login.support}</a>
      </li>
      <li className="patient-care-footer-list-item">
        <a href={locale.Login.cancellationLink}>{locale.Login.cancellation}</a>
      </li>
    </ul>
  )
};

export default LoginFooterLinks;
