import React, { Component, Fragment } from "react";

import locale from "service/locale";
import { Action } from "rxjs/dist/cjs/internal/scheduler/Action";


type Props = {
  isEditPublishBranchMode: Boolean,
  isAbleToEdit: Boolean,
  isPublished: Boolean,
  branchName: string,
  handlePublishStatusChange: (newPublishStatus: Boolean) => any,
  handleSelectPublishBranchEditMode: () => Action
};

class BranchVisibility extends Component<Props, State> {

  ChangePublishStatus = (status: Boolean) => {
    const { handlePublishStatusChange } = this.props;
    handlePublishStatusChange(status);
  };

  render() {
    const {
      isEditPublishBranchMode,
      isAbleToEdit,
      handleSelectPublishBranchEditMode,
      branchName,
      isPublished
    } = this.props;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.BranchSettings.branchVisibility}</h3>
            {isAbleToEdit && (
              <button
                className="patient-care-btn-link"
                onClick={handleSelectPublishBranchEditMode}
              >
                {isEditPublishBranchMode
                  ? locale.Appointment.customerDetails.buttonDone
                  : locale.Appointment.customerDetails.buttonEdit}
              </button>
            )}
          </div>
          <p>{locale.BranchSettings.visibilityDescription}</p>
        </div>
        {isEditPublishBranchMode ? (
          <Fragment>
            <div className="patient-care-description-block">
              <ul className="patient-care-unstyled-list patient-care-status-list">
                <li
                  onClick={() => this.ChangePublishStatus(false)}
                  className={`patient-care-status-item ${!isPublished ? "active" : ""}`}>
                  {locale.BranchSettings.unpublishButton}
                </li>
                <li
                  onClick={() => this.ChangePublishStatus(true)}
                  className={`patient-care-status-item ${isPublished ? "active" : ""}`}>
                  {locale.BranchSettings.publishButton}
                </li>
              </ul>
            </div>
          </Fragment>
        ) :
          (
            <Fragment>
              <div className="patient-care-description-block">
                <ul className="patient-care-unstyled-list patient-care-status-list patient-care-status-list-disabled">
                  <li
                    className={`patient-care-status-item ${!isPublished ? "active" : ""}`}>
                    {locale.BranchSettings.unpublishButton}
                  </li>
                  <li
                    className={`patient-care-status-item ${isPublished ? "active" : ""}`}>
                    {locale.BranchSettings.publishButton}
                  </li>
                </ul>
              </div>
            </Fragment>
          )}
        <div className="patient-care-description-block">
          <p>
            <b>
              {branchName} {" "}
              {isPublished
                ? locale.BranchSettings.publishDescription
                : locale.BranchSettings.unPublishDescription
              }
            </b>
          </p>
        </div>

        {isAbleToEdit && <hr className="patient-care-separator" />}
      </Fragment>
    )
  }
}

export default BranchVisibility;
