import React, { Component, Fragment } from "react";
import moment from "moment";
import Select from "react-select";
import { connect } from 'react-redux';
import { Form, FormItem, Input, InputLabel } from "@patient-access/ui-kit";
import * as CalendarsConstants from "constants/CalendarsConstants";

import * as DateTimeConstants from 'constants/DateTimeConstants';
import * as RolesConstants from "constants/RolesConstants";
import { updateForm } from "actions/form";
import type { Action } from 'types/actions';
import AvailabilityItem from "./AvailabilityItem/AvailabilityItem";
import { checkOpenHours } from "helpers/validateData";
import { cloneDeep } from "lodash";
import { getAvailabilitySettings } from 'actions/calendars';
import locale from "service/locale";

const today = new Date().toISOString().substr(0, 10);

const inputNames = {
  openHours: "openHours",
  branchOpenHours: "branchOpenHours"
};

type Props = {
  start: string | null,
  end: string | null,
  availablePeriods: any[],
  branchCalendarAvailablity: any[],
  updateForm : (data: any) => Action,
  handleChangeMode: (type: string, status: boolean) => any,  
  form: any,
  currentRole: any,
  calendarDetails: any,
  getAvailabilitySettings: (organisationId: string, branchId: string) => Action,
  calendar: any,
  isAvailabilityPending: boolean,
  isProcessing:boolean
};

type State = {
  durationType: Object,
  isEditMode: boolean,
  isNotMultipleByFive: boolean,
  isEqualTime: boolean,
  isStartTimeLessThanEndTime: boolean
};

const mapStateToProps = (state) => ({
  form: state.form,
  currentRole: state.roles.profileCurrentRole,
  calendarDetails: state.calendarDetails,
  calendar: state.calendar,
  organisationId: state.router.organisationId,
  branchId: state.router.branchId,
  isAvailabilityPending: state.calendar.isGetPending,  
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data) => dispatch(updateForm(data)),
  getAvailabilitySettings: (organisationId: string, branchId: string) => dispatch(getAvailabilitySettings(organisationId, branchId)),
});

class CalendarDetailsAvailability extends Component<Props, State> {

  state = {
    durationType: locale.AddNewCalendar.durationOptions[0],
    isEditMode: false,
    isNotMultipleByFive: false,
    isEqualTime: false,
    isStartTimeLessThanEndTime: false,
    hasValues: false
  };

  componentDidMount = () => {
    const { organisationId, branchId, getAvailabilitySettings } = this.props;    
    getAvailabilitySettings(organisationId, branchId);   
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { updateForm, isAvailabilityPending, openHours, form } = this.props;      
     if(!isAvailabilityPending && !nextProps.isAvailabilityPending && openHours !== nextProps.openHours && (!openHours.length || !form.openHours) ) {        
       let openHours=[];    
        if (nextProps.openHours.length) {
            openHours = nextProps.openHours;
          }
          updateForm({
            [inputNames.openHours]: openHours,
            [inputNames.branchOpenHours]: cloneDeep(nextProps.calendar.openHours)
          });
      }
  }

  handleChangeMode = (e: any) => {
    e && e.preventDefault();
    const { isEditMode } = this.state;
    const { handleChangeMode } = this.props;
    this.setState({ isEditMode: !isEditMode}, () => { handleChangeMode("isEditingAvailability", !isEditMode) });    
  };

  handleSelectDurationType = (e: any) => {
    const { updateForm } = this.props;
    const { value } = e;
    updateForm({ duration_type: value });
    this.setState({ durationType: locale.AddNewCalendar.durationOptions.find(option => option.value === e.value) });
  };


  handleDateChange = (e: any) => {
    const { updateForm } = this.props;
    const {
      target: { name, value }
    } = e;    
    updateForm({
      [name]: value
    });
  };

  render() {
    const { start, end, form, currentRole, isProcessing } = this.props;    
    const { isEditMode, hasValues } = this.state;
    const { startDate, endDate } = form;
    const calendarStart = startDate || start || today;
    const calendarEnd = endDate || end || calendarStart;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;
    const openHours = form && form.openHours ;
    const branchOpenHours = form && form.branchOpenHours ;
    const {
      isNotMultipleByFive,
      isEqualTime,
      isStartTimeLessThanEndTime,
    } = checkOpenHours(openHours);     
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.AddNewCalendar.addNewCalendarAvailabilityTitle}</h3>
            {!isBranchMember && !isProcessing ? (
              <button
                className="patient-care-btn-link"
                onClick={(hasValues && (isNotMultipleByFive || isEqualTime || isStartTimeLessThanEndTime)) ? () => {} : this.handleChangeMode}
              >
                {isEditMode
                  ? locale.Buttons.buttonDone
                  : locale.Buttons.buttonEdit}
              </button>
            ) : null}
          </div>
          <p>
            {locale.AddNewCalendar.addNewCalendarAvailabilityDescription}
          </p>
        </div>

        <div className="patient-care-сalendar-dates with-duration-range">
          <div className="patient-care-block-row">
            <div className="patient-care-col-4">
              {isEditMode ? (
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="startDate"
                      message={locale.AddNewCalendar.labelStartDate}
                      size="small"
                    />
                    <Input
                      id="startDate"
                      name="startDate"
                      onChange={this.handleDateChange}
                      value={calendarStart}
                      data-id="startDate"
                      type="date"
                      min={today}
                      max={endDate || end || null}
                    />
                  </FormItem>
                </Form>
              ) : (
                <Fragment>
                  <Form noValidate>
                    <FormItem type="item">
                      <InputLabel
                        htmlFor="name"
                        message={locale.AddNewCalendar.labelStartDate}
                        size="small"
                      />
                    </FormItem>
                  </Form>
                  <span className="patient-care-filled-input">{moment(calendarStart).format(DateTimeConstants.dateFormatForInputs)}</span>
                </Fragment>
              )}
            </div>
            <div className="patient-care-col-4">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="duration_type"
                    message={
                      locale.AddNewCalendar.addNewCalendarDurationLabel
                    }
                    size="small"
                  />
                </FormItem>
              </Form>
              { isEditMode ? (
                <Select
                  id="duration_type"
                  name="duration_type"
                  options={locale.AddNewCalendar.durationOptions}
                  value={
                    form.duration_type
                      ? locale.AddNewCalendar.durationOptions.find(
                      option => option.value === form.duration_type
                      )
                      : end
                      ? locale.AddNewCalendar.durationOptions[1]
                      : locale.AddNewCalendar.durationOptions[0]
                  }
                  onChange={this.handleSelectDurationType}
                  isDisabled={!isEditMode}
                  classNamePrefix="patient-care"
                  className="patient-care-select"
                />
              ) : (
                <span className="patient-care-filled-select">
                  {
                    form.duration_type
                      ? locale.AddNewCalendar.durationOptions.find(option => option.value === form.duration_type).label
                      : end
                        ? locale.AddNewCalendar.durationOptions[1].label
                        : locale.AddNewCalendar.durationOptions[0].label
                  }
                </span>
              )}
            </div>
            {((form.duration_type && form.duration_type === locale.AddNewCalendar.durationOptions[1].value) || (end && !form.duration_type)) && (
              <div className="patient-care-col-4">
                { isEditMode ? (
                  <Form noValidate>
                    <FormItem type="item">
                      <InputLabel
                        htmlFor="endDate"
                        message={locale.AddNewCalendar.labelEndDate}
                        size="small"
                      />
                      <Input
                        id="endDate"
                        name="endDate"
                        onChange={this.handleDateChange}
                        data-id="endDate"
                        type="date"
                        disabled={!isEditMode}
                        value={calendarEnd}
                        min={calendarStart}
                      />
                    </FormItem>
                  </Form>
                ) : (
                  <Fragment>
                    <Form noValidate>
                      <FormItem type="item">
                        <InputLabel
                          htmlFor="name"
                          message={locale.AddNewCalendar.labelEndDate}
                          size="small"
                        />
                      </FormItem>
                    </Form>
                    <span className="patient-care-filled-input">{moment(calendarEnd).format(DateTimeConstants.dateFormatForInputs)}</span>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="patient-care-сalendar-availability">
          {locale.AddNewCalendar.daysOfWeek.map(day => {                                   
            const timeSessionForDay = form.openHours && form.openHours.find(item => item.day === day.dayOfWeek);
            const isBranchClosed = branchOpenHours && branchOpenHours.find(item => item.day === day.dayOfWeek).timesSessions.length < 1;
            const dayName = day.value.name;
            const formDayData = form[dayName];
            const isDisabled = (formDayData &&
              formDayData.status ===
              locale
                .AddNewCalendar
                .dayAvailableOptions[1]
                .value) ||
              !isEditMode;
            const isAvailablePeriodEntered =
              formDayData && formDayData.status;
            const editableDay = timeSessionForDay && (timeSessionForDay.timesSessions.length >= 1 && 
                                timeSessionForDay.timesSessions[0].from !== CalendarsConstants.EMPTY_VALUE && 
                                timeSessionForDay.timesSessions[0].to !== CalendarsConstants.EMPTY_VALUE);
            let status;
            if (isAvailablePeriodEntered) {
              status =
                isAvailablePeriodEntered ===
                locale.AddNewCalendar.dayAvailableOptions[0].value
                  ? locale.AddNewCalendar.dayAvailableOptions[0]
                  : locale.AddNewCalendar.dayAvailableOptions[1];
            }
            else {
              status = editableDay
                ? locale.AddNewCalendar.dayAvailableOptions[0]
                : locale.AddNewCalendar.dayAvailableOptions[1];
            }
            return (              
              <AvailabilityItem
                key={day.label}
                dayLabel={day.label}                
                dayName={dayName}
                status={status}
                isEditMode={isEditMode}
                isDisabled={isDisabled}
                isBranchClosed={isBranchClosed}
                editableDay={editableDay}
                timeSessionForDay = {timeSessionForDay}
                handleEditTime={this.handleEditTime}
              />              
            );            
          })}
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetailsAvailability);
