import React, { Component } from 'react';

import './styles/patient-care-feature-pagination.scss';

type Props = {
  children: any,
  hidden: boolean,
};

class FeaturePagination extends Component<Props> {
  render() {
    const { children, hidden } = this.props;
    return (
      <div className={`patient-care-feature-pagination${hidden ? ' hidden' : ''}`}>
        {children}
      </div>
    );
  }
};

export default FeaturePagination;
