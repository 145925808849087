import React, { Component } from 'react';

import locale from 'service/locale';

type Props = {}

class NotFound extends Component<Props> {

  render() {
    return (
      <div>
        {locale.Share.pageNotFound}
      </div>
    );
  }

}

export default NotFound;
