import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import * as RoutesConstants from 'constants/RoutesConstants';
import {
  signIn,
  signInCallback,
  getADDToken,
  handleCallbackErrors,
} from "../aadAuthHelper";
import NoRolesError from "components/Pages/NoRolesError/NoRolesError";
import '../../styles.scss';

class AADLoginCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.checkIfUserIsAuthenticated = this.checkIfUserIsAuthenticated.bind(this);
  }

  checkIfUserIsAuthenticated = () => {
    getADDToken().then((token) => {
      if (token) {
        this.setState({ error: null });
        this.props.loadUser(token);
      }
    }).catch(() => {
      signIn();
    });
  }

  async componentDidMount() {
    try {
      const response = await signInCallback();
      if (response && response.accessToken) {
        const { accessToken } = response;
        this.setState({ error: null });
        this.props.loadUser(accessToken);
      } else {
        this.checkIfUserIsAuthenticated();
      }
    }
    catch (error) {
      const handled = handleCallbackErrors(error);
      if (!handled) {
        this.checkIfUserIsAuthenticated();
      }
    }
  }

  render() {
    const { location, isAuth } = this.props;

    if (this.state.error) {
      return (
        <div>
          {this.state.error}
        </div>
      );
    }

    if (isAuth) {
      if (location.search.length) {
        return <Redirect to={location.search.replace('?referrer=', '')} />;
      }
      return <Redirect to={RoutesConstants.HOME} />;
    }
    return (<NoRolesError />);
  }
}

export default AADLoginCallback;
