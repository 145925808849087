import * as LoginConstants from "constants/LoginConstants";
import type { Action } from "types/actions";
import agent from "service/agent";
import { throwGeneralError } from "actions/panel";
import { history } from "store/history";
import { getRefreshTokenCookie } from "service/cookie";

import * as RoutesConstants from 'constants/RoutesConstants';
import { logoutUserAAD, isAzureA2bAuthencationEnabled } from 'components/Pages/Login/AAD/aadAuthHelper';

export const loginUserSuccess = (email: string, token: string, expires: number, refresh_token: string): Action => ({
  type: LoginConstants.LOGIN_SUCCESS,
  payload: { email, token, expires, refresh_token }
});

export const loginUserError = (errorCode: number, isLastAttempt: boolean): Action => ({
  type: LoginConstants.LOGIN_ERROR,
  payload: { errorCode, isLastAttempt }
});

export const loginUserPending = (): Action => ({
  type: LoginConstants.LOGIN_PENDING
});

export const loginUserPendingSuccess = (): Action => ({
  type: LoginConstants.LOGIN_PENDING_SUCCESS
});

export const loginUserPendingError = (): Action => ({
  type: LoginConstants.LOGIN_PENDING_ERROR
});

export const clearLoginUserError = (): Action => ({
  type: LoginConstants.CLEAR_LOGIN_ERROR
});

export const hideLoginUserWarning = (): Action => ({
  type: LoginConstants.HIDE_LOGIN_WARNING
});

export const logoutUser = (hasError: boolean) => (dispatch: Function) => {
  if (isAzureA2bAuthencationEnabled()) {
    logoutUserAAD();
  } else {
    logoutUser(hasError)(dispatch);
  }
}

export const logoutUserOld = (hasError: boolean) => (dispatch: Function) => {
  const access_token = sessionStorage.getItem("token");
  const refresh_token = getRefreshTokenCookie();
  
  Promise.all([agent.Auth.logoutUser("access_token", access_token), agent.Auth.logoutUser("refresh_token", refresh_token)])
    .then(() => {
      dispatch({ type: LoginConstants.USER_LOGOUT });
      hasError && dispatch(throwGeneralError());
      history.push(RoutesConstants.LOGIN);
    })
    .catch(err => {
      dispatch({ type: LoginConstants.USER_LOGOUT });
      hasError && dispatch(throwGeneralError());
      console.log("Error occurred during revocation of token. Error: ", err);
    });
};
