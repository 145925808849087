import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { getPPLServiceDetails } from "actions/services";
import { FormattedMessage } from 'react-intl';
import {
  Overlay,
  Button,
  LinkExternal
} from "@patient-access/ui-kit";
import Markup from "components/Share/Markup/Markup";
import locale from 'service/locale';


type Props = {
  serviceDetails: any,
  getPPLServiceDetails: (serviceId: string) => Function,
}

const mapStateToProps = state => ({
  service: state.branchDetails.serviceDetails,
});

const mapDispatchToProps = dispatch => ({
  getPPLServiceDetails: serviceId => dispatch(getPPLServiceDetails(serviceId)),
});

class ViewServiceDescriptionOverlay extends Component<Props, State> {

  componentDidMount = () => {
    const { serviceId, getPPLServiceDetails } = this.props;
    getPPLServiceDetails(serviceId);
  };

  handleCloseOverlay = () => {
    const { handleCloseOverlay } = this.props;
    handleCloseOverlay();
  };

  render() {
    const { name, description, piLink } = this.props.service;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={true}
        closeOnEsc={true}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={this.handleCloseOverlay}
      >
        <Fragment>
          <div className="overlay-confirmation-header">
            <h1>{name}</h1>
          </div>
          <div className="overlay-confirmation-content">
            {description &&
              <Markup text={description} />
            }
            {piLink &&
              (
                <div className="patient-care-external-link">
                  <LinkExternal
                    to={piLink}
                    target="_blank"
                    message={<FormattedMessage id="service_btn" values={{ name }} defaultMessage="Read more about {name}" />}
                  />
                </div>
              )
            }
          </div>
        </Fragment>

        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="secondary"
            messageKey="cancel-btn"
            defaultMessage={locale.Modals.ViewServiceDescriptionOverlay.buttonCancel}
            onClick={this.handleCloseOverlay}
            data-id="cancel-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewServiceDescriptionOverlay);
