import React, { Component, Fragment } from 'react';

type Props = {
  outline: boolean,
};

class IconSort extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <Fragment>
        <polygon id="bottom" points="2.74 15 11.74 24 20.74 15 2.74 15" />
        <polygon id="top" points="20.74 9 11.74 0 2.74 9 20.74 9" />
      </Fragment>
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-sort ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconSort;
