import * as OrganisationsConstants from 'constants/OrganisationsConstants';

type OrganisationSupportedFeatures = {
  data: any,
  dataClone: any,
  isOverlayVisible: boolean,
  isPutLoading: boolean,
  isPutError: boolean
};

const initialState: OrganisationSupportedFeatures = {
  data: [],
  dataClone: [],
  isOverlayVisible: false,
  isPutLoading: false,
  isPutError: false
};

export default (localState: OrganisationSupportedFeatures = initialState, action: Action): OrganisationSupportedFeatures => {
  switch (action.type) {
    // SET
    case OrganisationsConstants.SET_ORGANISATION_SUPPORTED_FEATURES_DATA:
      const { branches, supportedFeatures, smartPharmacyBranchCount } = action.payload;
      const branchCount = (branches && branches.length) || 0;   
      const areAllBranchesDisabled = (branchCount > 0) && (smartPharmacyBranchCount === 0);
      const areAllBranchesEnabled = (branchCount > 0) && (smartPharmacyBranchCount === branchCount);
      const initialIsEnabledForAllBranches = areAllBranchesEnabled ? true : areAllBranchesDisabled ? false : undefined;
      return {
        ...localState,
        data: [
          ...supportedFeatures
        ],
        IsEnabledForAllBranches: initialIsEnabledForAllBranches
      };
    case OrganisationsConstants.SET_ORGANISATION_SUPPORTED_FEATURES_DATA_CLONE:
      return {
        ...localState,
        dataClone: [
          ...action.payload
        ]
      };

    // UPDATE
    case OrganisationsConstants.UPDATE_ORGANISATION_SUPPORTED_FEATURES:
      const newData = [
        ...localState.data
      ];
      const editedDataIndex = newData.findIndex(
        feature => feature.name === action.payload.featureName
      );
      if (editedDataIndex !== -1) {
          newData[editedDataIndex] = {
            ...newData[editedDataIndex],
            ...action.payload.data
          };
      } else {
        newData.push(action.payload.data);
      }
      return {
        ...localState,
        data: newData
      };

    // UPDATE
    case OrganisationsConstants.UPDATE_ORGANISATION_SUPPORTED_FEATURES_DISABLE_ALL_BRANCHES:
      return {
        ...localState,
        IsEnabledForAllBranches: action.payload,
      };

    // RESET
    case OrganisationsConstants.RESET_ORGANISATION_SUPPORTED_FEATURES:
      return {
        ...localState,
        data: [
          ...localState.dataClone,
        ],
        IsEnabledForAllBranches: undefined,
      };

    // PUT
    case OrganisationsConstants.PUT_ORGANISATION_SUPPORTED_FEATURES_PENDING:
      return {
        ...localState,
        isPutLoading: true,
        isPutError: false
      };
    case OrganisationsConstants.PUT_ORGANISATION_SUPPORTED_FEATURES_SUCCESS:
      return {
        ...localState,
        isPutLoading: false,
        isPutError: false,
        IsEnabledForAllBranches: undefined,
      };
    case OrganisationsConstants.PUT_ORGANISATION_SUPPORTED_FEATURES_ERROR:
      return {
        ...localState,
        isPutLoading: false,
        isPutError: true,
        IsEnabledForAllBranches: undefined,
      };

    // OVERLAY
    case OrganisationsConstants.SET_SMART_PHARMACY_OVERLAY_VISIBILITY:
      return {
        ...localState,
        isOverlayVisible: action.payload,
      };

    default:
      return localState;
  }
};
