import React from 'react';

const CustomHeader = ({ label, resource }) => {
  return (
    <i className={`ui-kit-avatar ui-kit-avatar-small ui-kit-avatar-${resource.resourceColor}`}>
      <span>{label}</span>
    </i>
  );
};

export default CustomHeader;
