export const SET_PROFILE_ROLES = "SET_PROFILE_ROLES";
export const SET_CURRENT_PROFILE_ROLE = "SET_CURRENT_PROFILE_ROLE";

export const PROFILE_ROLES_LIST = [
  { value: 1, label: "Super Admin", id: "SuperAdmin", name: "role", roleLevel: 0 },
  { value: 2, label: "Organisation Admin", id: "OrganizationAdmin", name: "role", roleLevel: 1 },
  { value: 3, label: "Branch Admin", id: "BranchAdmin", name: "role", roleLevel: 2 },
  { value: 4, label: "Branch Member", id: "BranchMember", name: "role", roleLevel: 3 },
];

export const PROFILE_ROLES_LEVEL = {
  SuperAdmin: 0,
  OrganizationAdmin: 1,
  BranchAdmin: 2,
  BranchMember: 3,
};

export const ADMIN = "SuperAdmin";
export const ORGANIZATION_ADMIN = "OrganizationAdmin";
export const BRANCH_ADMIN = "BranchAdmin";
export const BRANCH_MEMBER = "BranchMember";

export const LABEL_TO_SHORT_LABEL_MAP = {
  "Organisation Admin": "Org. Admin"
};

export const ROLES_TWO_FACTOR_LIST = [
  { value: 0, label: "Not required"},
  { value: 1, label: "Required"},
];

export const NO_ROLE_ERROR = {
  errorCode: 1,
  label: "This user has no roles assigned to it",
};
