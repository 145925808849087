import React, { Component, Fragment } from 'react';
import { getAppointmentDateAndTime } from "helpers/formatData";
import { connect } from 'react-redux';
import { LinkIcon, IconClose } from "@patient-access/ui-kit";
import {
  FormItem,
  Input,
  Form,
  InputError,
  Button
} from "@patient-access/ui-kit";
import { updateForm } from "actions/form";
import locale from 'service/locale';
import { createInternalEvent } from "actions/bookAppointment";

import * as RoutesConstants from 'constants/RoutesConstants';

type State = {
  isDataChanged: boolean,
  isDiscardChangesBookingOverlayOpen: boolean
}

type Props = {
  isActiveAgenda: boolean,
  handleOpenDiscardChangesOverlay: () => any,
  handleCloseExpiredSlotDialog: () => any,
  handleDiscardChanges: () => any,
  booking: any,
  isEventPending: boolean,
  form: any,
  updateForm: (data: any) => any,
  createInternalEvent: (title: string) => any,
}

const mapStateToProps = (state: any) => ({
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda,
  booking: state.book,
  isEventPending: state.book.isInternalEventPending
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  createInternalEvent: (title: string) => dispatch(createInternalEvent(title)),
})

class AddInternalEvent extends Component<Props, State> {
  state = {
    isDataChanged: false,
    isDiscardChangesBookingOverlayOpen: false
  };

  handleDataChange = ({ target: { name, value } }) => {
    const { isDataChanged } = this.state;
    const { updateForm } = this.props;
    !isDataChanged && this.setState({ isDataChanged: true });
    updateForm({ [name]: value });
  };

  handleCreateInternalEvent = () => {
    const { createInternalEvent, form } = this.props;
    const title = form.internal_event_title;
    createInternalEvent(title);
  }

  render() {
    const { booking, isActiveAgenda, handleOpenDiscardChangesOverlay, handleDiscardChanges, isEventPending, form } = this.props;
    const { isDataChanged } = this.state;
    const keys = Object.keys(form);
    const isEmptyTitle = keys.includes("internal_event_title") && (form.internal_event_title && !form.internal_event_title.length) === "";
    const isValidForm = isDataChanged && !isEmptyTitle;

    return (
      <div className={`patient-care-appointment-booking ${isActiveAgenda ? "patient-care-modal-full-height-with-agenda-holder" : "patient-care-modal-full-height-holder"}`}>
        <div className="patient-care-modal-full-height">
          <div className="patient-care-modal-header">
            <h1>{locale.AddInternalEvent.header}</h1>
            <div className="patient-care-btn-close">
              <LinkIcon
                to={RoutesConstants.HOME}
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.AddInternalEvent.closeIcon}
                onClick={isDataChanged ? handleOpenDiscardChangesOverlay : handleDiscardChanges}
              />
            </div>
          </div>
          <div className="patient-care-modal-content">
            <div className="patient-care-block">
              <div className="patient-care-heading-row">
                <h3>{locale.AddInternalEvent.dateTimeLabel}</h3>
              </div>
              <b>
                {
                  getAppointmentDateAndTime(booking.startTime, booking.endTime)
                }
              </b>
            </div>
            <hr className="patient-care-separator" />
            <div className="patient-care-block">
              <div className="patient-care-heading-row">
                <h3>{locale.AddInternalEvent.title}</h3>
              </div>
              <Fragment>
                <Form noValidate>
                  <FormItem type="item" error={isEmptyTitle ? "error" : null}>
                    <Input
                      type="text"
                      id="internal_event_title"
                      name="internal_event_title"
                      data-id="internal_event_title"
                      onChange={this.handleDataChange}
                    />
                    {isEmptyTitle && (
                      <InputError message={locale.AddInternalEvent.titleRequired} />
                    )}
                  </FormItem>
                </Form>
              </Fragment>
            </div>
          </div>
          <div className="patient-care-modal-footer">
            <div className="patient-care-row-align-right">
              <Button
                buttonType="blueline"
                messageKey="add-event-cancel-btn"
                defaultMessage={locale.AddInternalEvent.cancelButtonText}
                onClick={isDataChanged ? handleOpenDiscardChangesOverlay : handleDiscardChanges}
                isLoading={isEventPending}
                data-id="cancel-btn"
              />
              <Button
                buttonType="secondary"
                messageKey="add-event-btn"
                defaultMessage={locale.AddInternalEvent.createButtonText}
                onClick={this.handleCreateInternalEvent}
                isLoading={isEventPending}
                data-id="add-event-btn"
                isDisabled={!isValidForm}
              />
            </div>
          </div>
        </div>
        <span className="patient-care-modal-overlay" />
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddInternalEvent);
