import * as UsersConstants from 'constants/UsersConstants';
import type { Action } from 'types/actions';
import type { Filter } from 'types/filter';
import agent from "service/agent";
import { getToken } from "helpers/common";

// USERS_LIST
export const setUsersListPending = (): Action => ({
  type: UsersConstants.GET_USERS_LIST_PENDING,
});

export const setUsersListSuccess = (): Action => ({
  type: UsersConstants.GET_USERS_LIST_SUCCESS,
});

export const setUsersListError = (): Action => ({
  type: UsersConstants.GET_USERS_LIST_ERROR,
});

export const setUsersListData = (usersList: any[]): Action => ({
  type: UsersConstants.SET_USERS_LIST_DATA,
  payload: { usersList },
});

export const getUsersList = () => (dispatch: Function) => {
  dispatch(setUsersListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Users.getAllUsers(accessToken)
        .then(usersList => {
          dispatch(setUsersListData(usersList));
          dispatch(setUsersListSuccess());
        })
        .catch(err => {
          dispatch(setUsersListError());
          console.log(err);
        });
    });

};

export const getFilteredUsersList = (filter?: Filter = {}) => (dispatch: Function) => {
  dispatch(setUsersListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Users.getFilteredUsers(filter, accessToken)
        .then(usersList => {
          dispatch(setUsersListData(usersList));
          dispatch(setUsersListSuccess());
        })
        .catch(err => {
          dispatch(setUsersListError());
          console.log('getFilteredUsersList ', err);
        });
    });

};

export const getCurrentPage = (pageNumber: number, filter?: Filter = {}) => (dispatch: Function) => {
  dispatch(setUsersListPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Users.getCurrentPage(pageNumber, filter, accessToken)
        .then(searchResults => {
          dispatch(setUsersListData(searchResults));
          dispatch(setUsersListSuccess());
        })
        .catch(err => {
          dispatch(setUsersListError());
          console.log(err);
        });
    });

};
