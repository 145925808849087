import { ai } from "service/telemetry";
import {
  msalApp,
  forgotPwdMsalApp,
  changePasswordSelfMsalApp,
  LOGIN_REQUEST,
  postLogoutRedirectUri,
} from "./authConfig";

const forgortPasswordFlowErrorCode = "AADB2C90118";

// Check for forgot password error
// Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
export const handleCallbackErrors = (error) => {
  try {
    if (error && error.errorMessage && error.errorMessage.indexOf(forgortPasswordFlowErrorCode) > -1) {
      // telemetry
      ai.appInsights.trackEvent({
        name: 'PAProAADForgotPasswordInitiated',
      }, {
        errorMessage: error,
      });

      forgotPwdMsalApp.loginRedirect(LOGIN_REQUEST.LOGIN);

      return true;
    }
    
    // telemetry
    ai.appInsights.trackEvent({
      name: 'PAProAADCallbackError',
    }, {
      errorMessage: error,
    });
    
    return false;
  } catch (err) {
    return false;
  }
};

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf("consent_required") > -1 ||
    errorMessage.indexOf("interaction_required") > -1 ||
    errorMessage.indexOf("login_required") > -1
  );
};

export const getADDToken = () => {
  const request = LOGIN_REQUEST.REFRESH;
  const account = getCurrentAccount();
  if (!account) {
    return msalApp.acquireTokenRedirect(LOGIN_REQUEST.REFRESH);
  } else {
    request.account = account;
  }

  return msalApp.acquireTokenSilent(request)
    .then((response) => { return response.accessToken; })
    .catch(error => {
      // Call acquireTokenRedirect in case of acquireTokenSilent failure
      // due to consent or interaction required ONLY
      if (requiresInteraction(error.errorCode)) {
        return msalApp.acquireTokenRedirect(LOGIN_REQUEST.REFRESH);
      } else {
        // telemetry
        ai.appInsights.trackEvent({
          name: 'PAProAADNonInteractiveError',
        }, {
          errorMessage: error,
        });
      }
    });
};

export const signIn = async () => {
  try {
    sessionStorage.clear();
    await msalApp.loginRedirect(LOGIN_REQUEST.LOGIN);
  }
  catch (error) {
     // telemetry
     ai.appInsights.trackEvent({
      name: 'PAProAADLoginRedirectError',
    }, {
      errorMessage: error,
    });
  }
};

export const signInCallback = async () => {
  return await msalApp.handleRedirectPromise();
};

export const forgotPasswordCallback = async () => {
  const response = await forgotPwdMsalApp.handleRedirectPromise();

  if (response && response.accessToken)
    // telemetry
    ai.appInsights.trackEvent({
      name: 'PAProAADForgotPasswordCompleted',
    }, {
      accountId: response.account && response.account.homeAccountId,
    });

  return response;
};

export const changePasswordCallback = async () => {
  const response = await changePasswordSelfMsalApp.handleRedirectPromise();

  if (response && response.accessToken)
    // telemetry
    ai.appInsights.trackEvent({
      name: 'PAProAADChangePasswordCompleted',
    }, {
      accountId: response.account && response.account.homeAccountId,
    });

  return response;
};

export const logoutUserAAD = async () => {
  const logoutRequest = { postLogoutRedirectUri: postLogoutRedirectUri };
  const account = getCurrentAccount();

  logoutRequest.account = account;
  await msalApp.logout(logoutRequest);
};

export const isAuthorized = () => {
  const account = getCurrentAccount();

  return !!account;
};

export const getCurrentAccount = () => {
  const currentAccounts = msalApp.getAllAccounts();
  if(currentAccounts && currentAccounts.length) {
    return currentAccounts[0]; 
  }

  return null;
};

export const isAzureA2bAuthencationEnabled = () => {
  // if (window.__ENV.azureadb2c) {
  //   return window.__ENV.azureadb2c.enabled === 'true';
  // }
  // return false;

  return true;
};

export const changePasswordRedirect = (loginHint) => {
  changePasswordSelfMsalApp.loginRedirect(Object.assign({}, LOGIN_REQUEST.LOGIN, { loginHint }));
};