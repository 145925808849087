import {
  clearForm,
  updateForm
} from "actions/form";
import type {
  Action
} from "types/actions";
import agent from "service/agent";
import {
  closeModalOverlay,
  openCalendarNameConflict,
  openCalendarAvailabilityConflict,
  closeCalendarDetails
} from "actions/panel";
import * as BranchesConstants from "constants/BranchesConstants";
import * as CalendarsConstants from "constants/CalendarsConstants";
import type {
  Calendar,
  OpenHours,
  ClosedDay,
  BookingCutoff,
  BlockedPeriod
} from "types/calendars";
import {
  getToken,
  formatAvailablePeriodsForNewCalendar,
  formatBlockedPeriodsForNewCalendar
} from "helpers/common";
import {
  formatOpeningHours,
  formatOpeningHoursRevert
} from "helpers/formatData";
import {
  clearNewBranchServiceList
} from "actions/services";
import {
  setBranchCalendars,
  setBranchCalendar,
  setBranchDetails,
  setBranchConfirmationStatus,
  setCalendarsPending,
  setCalendarsPendingSuccess,
  setCalendarsPendingError
} from "actions/branchDetails";
import {
  showSnackbarStatus
} from "actions/snackbar";
import {
  setResources
} from "actions/calendarView";
import {
  getCalendarSearch
} from 'actions/calendarSearch';
import locale from "service/locale";
import moment from "moment";
import {
  setOrganisationHeader,
  setBranchHeader
} from "actions/router";
import {
  setPasswordConfirmationStatus
} from "./organisationDetails";
import * as ServiceConstants from "constants/ServicesConstants";
import { getCalendarsList } from 'actions/calendarsList';

export const setCalendars = (calendarsList: Calendar[]): Action => ({
  type: CalendarsConstants.SET_CALENDARS,
  payload: calendarsList
});

export const setOpenHours = (openHours: OpenHours[]): Action => ({
  type: CalendarsConstants.SET_OPEN_HOURS,
  payload: openHours
});

export const setClosedDays = (closedDays: ClosedDay[]): Action => ({
  type: CalendarsConstants.SET_CLOSED_DAYS,
  payload: closedDays
});


export const setTimeOffMinutes = (timeOff: TimeOff): Action => ({
  type: CalendarsConstants.SET_TIME_OFF_MINUTES,
  payload: timeOff
});

export const setBookingCutoff = (bookingCutoff: BookingCutoff): Action => ({
  type: CalendarsConstants.SET_BOOKING_CUTOFF,
  payload: bookingCutoff
});

export const setBlockedDays = (blockedDays: string[]): Action => ({
  type: CalendarsConstants.SET_BLOCKED_DAYS,
  payload: blockedDays
});

export const addBlockedPeriod = (blockedPeriod: BlockedPeriod): Action => ({
  type: CalendarsConstants.ADD_BLOCKED_PEROID,
  payload: blockedPeriod
});

export const deleteBlockedPeriod = (blockedPeriodId: number): Action => ({
  type: CalendarsConstants.DELETE_BLOCKED_PEROID,
  payload: blockedPeriodId
});

export const clearBlockedPeriods = (): Action => ({
  type: CalendarsConstants.CLEAR_BLOCKED_PEROIDS
});

const getAvailabilityPending = (): Action => ({
  type: CalendarsConstants.GET_AVAILABILITY_PENDING
});

const getAvailabilitySuccess = (): Action => ({
  type: CalendarsConstants.GET_AVAILABILITY_SUCCESS
});

const getAvailabilityError = (): Action => ({
  type: CalendarsConstants.GET_AVAILABILITY_ERROR
});

const updateAvailabilityPending = (): Action => ({
  type: CalendarsConstants.UPDATE_AVAILABILITY_PENDING
});

const updateAvailabilitySuccess = (): Action => ({
  type: CalendarsConstants.UPDATE_AVAILABILITY_SUCCESS
});

const updateAvailabilityError = (): Action => ({
  type: CalendarsConstants.UPDATE_AVAILABILITY_ERROR
});

export const createCalendarPending = (): Action => ({
  type: BranchesConstants.CREATE_CALENDAR_PENDING
});

export const createCalendarSuccess = (): Action => ({
  type: BranchesConstants.CREATE_CALENDAR_SUCCESS
});

export const createCalendarError = (): Action => ({
  type: BranchesConstants.CREATE_CALENDAR_ERROR
});

const updateCalendarPending = (): Action => ({
  type: CalendarsConstants.UPDATE_CALENDAR_PENDING
});

const updateCalendarSuccess = (): Action => ({
  type: CalendarsConstants.UPDATE_CALENDAR_SUCCESS
});

const updateCalendarError = (): Action => ({
  type: CalendarsConstants.UPDATE_CALENDAR_ERROR
});

export const openConfirmDeletionBlockedPeriod = (): Action => ({
  type: CalendarsConstants.OPEN_CONFIRM_DELETION_BLOCKED
});

export const closeConfirmDeletionBlockedPeriod = (): Action => ({
  type: CalendarsConstants.CLOSE_CONFIRM_DELETION_BLOCKED
});

export const openBlockedPeriodWarning = (): Action => ({
  type: CalendarsConstants.OPEN_ADD_BLOCKED_TODAY
});

export const closeBlockedPeriodWarning = (): Action => ({
  type: CalendarsConstants.CLOSE_ADD_BLOCKED_TODAY
});

export const openUpdateAvailabilityConflictOverlay = (
  appointments: string[]
): Action => ({
  type: CalendarsConstants.OPEN_UPDATE_AVAILABILITY_CONFLICT,
  payload: appointments
});

export const closeUpdateAvailabilityConflictOverlay = (): Action => ({
  type: CalendarsConstants.CLOSE_UPDATE_AVAILABILITY_CONFLICT
});

export const updateAvailabilitySettings = (branchId: string, data: any) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const form = currentState.form;
  return new Promise((resolve, reject) => {
    const {
      openHours,
      closedDays,
      bookingCutoff,
      timeOffModel
    } = form;
    const dataToSend = Object.assign({}, form, {
      openHours: formatOpeningHours(openHours),
      bookingCutoff: {
        advance: bookingCutoff.advance.value
      }
    });

    dispatch(updateAvailabilityPending());
    getToken(dispatch).then(accessToken => {
      agent.Calendars.updateBranchAvailability(
          dataToSend,
          branchId,
          accessToken
        )
        .then(() => {
          openHours && openHours.length && dispatch(setOpenHours(openHours));
          closedDays && dispatch(setClosedDays(closedDays));
          bookingCutoff && dispatch(setBookingCutoff(bookingCutoff));
          timeOffModel && dispatch(setTimeOffMinutes(timeOffModel));
          dispatch(updateAvailabilitySuccess());
          dispatch(showSnackbarStatus(locale.Snackbar.availabilitySettingsUpdated));
          dispatch(clearForm());
          resolve(false);
        })
        .catch(err => {
          dispatch(updateAvailabilityError());
          dispatch(showSnackbarStatus(locale.Snackbar.availabilitySettingsError));

          if (err.status === 400) {
            let {
              result: appointments
            } = err.response.body;
            dispatch(openUpdateAvailabilityConflictOverlay(appointments));
            dispatch(setPasswordConfirmationStatus(null));
            dispatch(
              updateForm({
                openHours: currentState.calendar.openHours,
                bookingCutoff: currentState.calendar.bookingCutoff,
                closedDays: currentState.calendar.closedDays
              })
            );
            resolve(true);
            return;
          }

          console.log(
            `Error while updating availability settings for the branch ${branchId}: `,
            err
          );
          reject();
        });
    });
  });
};

export const getAvailabilitySettings = (
  organisationId: string,
  branchId: string
) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setBranchHeader(branchId));
  dispatch(getAvailabilityPending());
  getToken(dispatch).then(accessToken => {
    agent.Calendars.getBranchAvailability(branchId, accessToken)
      .then(data => {
        const {
          openHoursModel,
          closedDaysModel,
          cutOffModel,
          timeOffModel
        } = data;
        const openingHours = formatOpeningHoursRevert(openHoursModel.days);

        dispatch(setOpenHours(openingHours));
        const openHoursFormData = {};
        openHoursModel.days.forEach(openHoursDay => {
          const matchedDayOfWeek = locale.AddNewCalendar.daysOfWeek.find(
            day => day.dayOfWeek === openHoursDay.dayOfWeek
          );
          openHoursFormData[matchedDayOfWeek.value.name] = {
            status: openHoursDay.isOpen ?
              locale.AddNewCalendar.dayAvailableOptions[0].value : locale.AddNewCalendar.dayAvailableOptions[1].value,
            start: openHoursDay.isOpen ?
              openHoursDay.startTime.slice(0, -3) : "",
            end: openHoursDay.isOpen ?
              openHoursDay.endTime.slice(0, -3) : ""
          };
        });
        dispatch(setClosedDays(closedDaysModel.days));
        dispatch(setTimeOffMinutes(timeOffModel));
        if (cutOffModel) {
          cutOffModel.advance =
            CalendarsConstants.AdvancedOptions.find(option => option.value === cutOffModel.advance) ||
            CalendarsConstants.AdvancedOptions[3];
        }
        dispatch(setBookingCutoff(cutOffModel));
        dispatch(getAvailabilitySuccess());
      })
      .catch(err => {
        dispatch(getAvailabilityError());
        console.log(`Error while getting availability setting for the branch ${branchId}: `, err);
      });
  });
};

export const getCalendar = (calendarId: string) => (dispatch: Function) => {
  getToken(dispatch).then(accessToken => {
    agent.Calendars.getCalendar(calendarId, accessToken)
      .then(calendar => {
        if (calendar.blockedPeriods.length) {
          calendar.blockedPeriods.forEach((period, index) => {
            period.isAllDay = period.startTime === null && period.endTime === null;
            period.calendarId = period.id;
            period.id = index;
          });
        }
        dispatch(setBranchCalendar(calendar));
      })
      .catch(err => {
        console.log("get branch calendar server error or calendar is not found", err);
      });
  });
};

export const createCalendar = (branchId: string, organisationId ? : string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const form = currentState.form;
  const services = currentState.services.newServicesList;

  const searchValue = currentState.calendarsList.filters.searchValue;
  const branchIds = currentState.calendarsList.filters.branchIds;
  const serviceIds = currentState.calendarsList.filters.serviceIds;

  const calendarData = {
    blockedPeriods: formatBlockedPeriodsForNewCalendar(form.blockedPeriods),
    availablePeriods: formatAvailablePeriodsForNewCalendar(form),
    services: services.filter(service => !(service.id === ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID)).map(service => service.id),
    branchId,
    name: form.name,
    start: form.startDate || moment.utc().toISOString(),
    end: form.endDate ? form.endDate : null
  };
  dispatch(createCalendarPending());
  getToken(dispatch).then(accessToken => {
    agent.Calendars.createCalendar(calendarData, accessToken)
      .then(() => {
        dispatch(createCalendarSuccess());
        branchId
          ?
          agent.Branches.getBranchDetails(branchId, accessToken)
          .then(branchDetails => {
            dispatch(setCalendarsPending());
            dispatch(setBranchDetails(branchDetails));
            agent.Calendars.getBranchCalendars(branchId, accessToken)
              .then(({
                items: branchCalendarsList
              }) => {
                dispatch(setCalendarsPendingSuccess());
                dispatch(setBranchCalendars(branchCalendarsList));
                dispatch(
                  setResources(
                    branchCalendarsList.map(calendar => ({
                      resourceId: calendar.id,
                      resourceTitle: calendar.name[0].toUpperCase()
                    }))
                  )
                );
              })
          }) : dispatch(getCalendarSearch(organisationId));
        dispatch(closeModalOverlay());
        dispatch(clearForm());
        dispatch(clearNewBranchServiceList());
        dispatch(showSnackbarStatus(locale.Snackbar.calendarCreated));
        dispatch(getCalendarsList(searchValue, branchIds, serviceIds, 1, organisationId));
      })
      .catch(err => {
        dispatch(setCalendarsPendingError());
        dispatch(createCalendarError());
        if (err.response && err.response.body) {
          Object.keys(err.response.body).includes(
            BranchesConstants.calendarNameConflictError
          ) && dispatch(openCalendarNameConflict());

          Object.keys(err.response.body).includes(
            BranchesConstants.wrongAvailablePeriods
          ) && dispatch(openCalendarAvailabilityConflict());
        }
        dispatch(showSnackbarStatus(locale.Snackbar.calendarNotCreated));
        console.log("creating new calendar server error", err);
      });
  });
};

export const updateCalendar = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const formData = currentState.form;
  let isTryingBlockToday = false;
  const newCalendarData = {
    ...currentState.calendarDetails
  };
  const newBlockedPeriods = currentState.calendar.newBlockedPeriods.map(
    period => {
      const editedPeriodData = formData.blockedPeriods && formData.blockedPeriods[period.id];
      if (editedPeriodData) {
        const oldPeriodData = {
          startDate: period.startDate || new Date().toISOString().slice(0, 10),
          endDate: period.endDate || new Date().toISOString().slice(0, 10),
          isAllDay: period.isAllDay,
          startTime: period.startTime || "00:00",
          endTime: period.endTime || "00:00",
        };
        if (moment().isSame(editedPeriodData.startDate, "day")) {
          isTryingBlockToday = true;
        }
        const isAllDay = editedPeriodData.allDay || (!editedPeriodData.endTime && !editedPeriodData.startTime && oldPeriodData.isAllDay);
        const startDate = editedPeriodData.startDate || oldPeriodData.startDate;
        let endDate = editedPeriodData.endDate || oldPeriodData.endDate;
        let startTime = editedPeriodData.startTime || oldPeriodData.startTime;
        let endTime = editedPeriodData.endTime || oldPeriodData.endTime;
        if (isAllDay) {
          startTime = endTime = null;
        }
        return {
          startDate,
          endDate,
          startTime,
          endTime,
          id: period.calendarId
        };
      } else {
        return {
          ...period,
          id: period.calendarId
        };
      }
    }
  );
  const {
    newServicesList
  } = currentState.services;
  const newServices =
    newServicesList && newServicesList[0] ?
    newServicesList.map(service => service.id) : [];
  const newAvailablePeriods = [];
  locale.AddNewCalendar.daysOfWeek.forEach(day => {
    const dayName = day.value.name;
    const isOldPeriodExist = newCalendarData.availablePeriods.find(
      oldPeriodDay => oldPeriodDay.dayOfWeek === day.dayOfWeek
    );
    const timeSessionForDay = formData.openHours.find(x => x.day === day.dayOfWeek);
    const timePeriods = []
    if (timeSessionForDay && timeSessionForDay.timesSessions.length) {

      timeSessionForDay.timesSessions.map(session => {
        return timePeriods.push({
          start: session.from,
          end: session.to
        });
      });
    }

    if (formData[dayName]) {
      formData[dayName].status !==
        locale.AddNewCalendar.dayAvailableOptions[1].value &&
        newAvailablePeriods.push({
          dayOfWeek: dayName,
          periods: timePeriods
        });
    } else {
      if (isOldPeriodExist && timeSessionForDay) {
        newAvailablePeriods.push({
          dayOfWeek: dayName,
          periods: timePeriods
        });
      }
    }
  });
  newCalendarData.availablePeriods = newAvailablePeriods;
  newCalendarData.services = newServices.filter(
    (s, i) => s && newServices.indexOf(s) === i
  );
  newCalendarData.blockedPeriods = newBlockedPeriods;
  if (formData.name) newCalendarData.name = formData.name;
  if (formData.startDate) newCalendarData.start = formData.startDate;
  if (formData.duration_type) {
    if (
      formData.duration_type === locale.AddNewCalendar.durationOptions[1].value
    ) {
      if (formData.endDate) {
        newCalendarData.end = formData.endDate;
      }
    } else {
      newCalendarData.end = null;
    }
  } else {
    if (formData.endDate || formData.endDate === null)
      newCalendarData.end = formData.endDate;
  }

  if (isTryingBlockToday) {
    dispatch(openBlockedPeriodWarning());
    return;
  }

  dispatch(updateCalendarPending());
  getToken(dispatch).then(accessToken => {
    agent.Calendars.updateCalendar(newCalendarData, accessToken)
      .then(() => {
        dispatch(updateCalendarSuccess());
        dispatch(setBranchConfirmationStatus(CalendarsConstants.CALENDAR_UPDATED));
        dispatch(showSnackbarStatus(locale.Snackbar.calendarUpdated));
        dispatch(clearForm());
        dispatch(closeCalendarDetails());
      })
      .catch(err => {
        dispatch(updateCalendarError());

        const res = err.response.body;
        let message = locale.Snackbar.calendarNotUpdated;

        if (err.status === 400) {
          if (res.result) {
            let {
              result: appointments
            } = res;
            dispatch(openUpdateAvailabilityConflictOverlay(appointments));
            dispatch(setPasswordConfirmationStatus(null));
            return;
          } else {
            // parse response like { key: ["message"] }
            const errors = res.errors || res;
            const keys = Object.keys(errors);
            message = keys
              .map(key => {
                return errors[key].join(" ");
              })
              .join("\r\n");
          }
        }
        console.log("updateCalendar server error", err);
        dispatch(setBranchConfirmationStatus(CalendarsConstants.CALENDAR_UPDATE_ERROR));
        dispatch(showSnackbarStatus(message));
        dispatch(clearForm());
        dispatch(closeCalendarDetails());
      });
  });
};

export const deleteCalendar = (calendarId: string, organisationId ? : string) => (
  dispatch: Function,
  getState: Function,
) => {
  const branchId = getState().branchDetails.branchId;
  dispatch(updateCalendarPending());
  getToken(dispatch).then(accessToken => {
    agent.Calendars.deleteCalendar(calendarId, accessToken)
      .then(() => {
        dispatch(updateCalendarSuccess());
        dispatch(closeCalendarDetails());
        organisationId
          ?
          dispatch(getCalendarSearch(organisationId)) :
          agent.Calendars.getBranchCalendars(branchId, accessToken)
          .then(({
            items: branchCalendarsList
          }) => {
            dispatch(setBranchCalendars(branchCalendarsList));
          })
          .catch(err => {
            console.log("getBranchCalendars server error or branch is not found", err);
          });
        dispatch(clearForm());
        dispatch(showSnackbarStatus(locale.Snackbar.calendarDeleted));
      })
      .catch(err => {
        dispatch(showSnackbarStatus(locale.Snackbar.calendarNotDeleted));
        console.log("deleteCalendar server error or calendar is not found", err);
      });
  });
};

export const getAppointmentsForCalendar = (calendarId: string, startDate: string) => (
  dispatch: Function
) => {
  getToken(dispatch).then(accessToken => {
    agent.Calendars.getAppointmentsForCalendar(
        calendarId,
        startDate,
        accessToken
      )
      .then(appointmentsList => {
        const bookedAppointmentsList = [];
        appointmentsList.appointmentsByDate.forEach(date => {
          const bookedAppointment = date.appointments;
          bookedAppointment && bookedAppointmentsList.push(bookedAppointment);
        });
        dispatch(
          setBranchConfirmationStatus(
            bookedAppointmentsList.length ?
            `${BranchesConstants.PASSWORD_WARNING_STATUSES.HAS_BOOKINGS},${
                  bookedAppointmentsList.length
                }` :
            BranchesConstants.PASSWORD_WARNING_STATUSES.NO_BOOKINGS
          )
        );
      })
      .catch(err => {
        console.log("appointment search server error", err);
      });
  });
};

