import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, LinkExternal } from '@patient-access/ui-kit';
import { setTaskManagerOverlayIsVisible, retryTask } from 'actions/taskManager';
import locale from 'service/locale';

type Props = {
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => any,
  retryTask : (taskId: any) => any,
  taskDetail: any,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch: any) => ({
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => dispatch(setTaskManagerOverlayIsVisible(isVisible)),
  retryTask: (taskId: any) => dispatch(retryTask(taskId)),
});

const setHeader = (taskName) => {
  var header = "";
  if (taskName.includes("Add")) {
    header = taskName.replace("Add", "adding");
  } else if (taskName.includes("Edit")) {
    header = taskName.replace("Edit", "editing");
  } else if (taskName.includes("Remove")) {
    header = taskName.replace("Remove", "removing");
  }
  return header;
};

class OverlayError extends Component<Props> {
  render() {
    const { setTaskManagerOverlayIsVisible, taskDetail, retryTask } = this.props;
    return (
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{locale.TaskManager.overlay.branchErrorHeaderText(setHeader(taskDetail.input.TaskName))}</h1>
        </div>
        <div className="overlay-confirmation-content overlay-content-task-manager">
          <h4>{locale.TaskManager.overlay.errorContent}</h4>
          <h4>{locale.TaskManager.overlay.errorSupportContent1}
            <LinkExternal
              to="https://supportpro.patientaccess.com/contact-support-form"
              message={locale.Header.supportLinkOne}
              baseClassName="patient-care-link"
              target={locale.Share.targetBlank}
            />
            {locale.TaskManager.overlay.errorSupportContent2}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button buttonType="blueline" messageKey={locale.TaskManager.button.goBack} defaultMessage={locale.TaskManager.button.goBack} onClick={() => { setTaskManagerOverlayIsVisible(false) }} />
          <Button buttonType="secondary" messageKey={locale.TaskManager.button.tryAgain} defaultMessage={locale.TaskManager.button.tryAgain} onClick={() => {retryTask(taskDetail.input.TaskId); setTaskManagerOverlayIsVisible(false); }} />
        </div>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayError);
