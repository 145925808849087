import React, { Component } from 'react';
import { Link as LinkScroll } from 'react-scroll';
import locale from 'service/locale';

class ButtonScrollTop extends Component<Props> {
  render() {
    return (
      <LinkScroll to="calendar-filters-content" className="ui-kit-button ui-kit-button-secondary ui-kit-button-medium ui-kit-button-full" containerId="calendar-filters-content" offset={0} duration={300} smooth="easeInOutCubic">
        <span>{locale.Sidebars.scrollTop}</span>
      </LinkScroll>
    );
  }
};

export default ButtonScrollTop;
