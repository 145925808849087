import React, { Fragment, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Form, Tooltip, Icon, IconCog, IconPerson, IconPhone, IconVideo, IconAlert } from '@patient-access/ui-kit';
import { formatCurrency } from 'helpers/formatData';
import IconSort from 'svg/IconSort';
import locale from 'service/locale';
import './styles.scss';

export const dataServicesList = [
  { serviceName: 'Asthma - emergency inhaler supply', servicePrice: 14, branches: 100, status: 50, duration: 15, cutOff: 24, type: [0,1,2], isAlert: true },
  { serviceName: 'Back pain - tablets', servicePrice: 21.5, branches: 24, status: 10, duration: 25, cutOff: 24, type: [0,2] },
  { serviceName: 'Chlamydia treatment', servicePrice: 9, branches: 31, status: 5, duration: 5, cutOff: 48, type: [1] },
  { serviceName: 'Service name', servicePrice: 14, branches: 12, status: 50, duration: 15, cutOff: 24, type: [0] },
  { serviceName: 'Cholera - vaccination', servicePrice: 22, branches: 211, status: 20, duration: 30, cutOff: 24, type: [0] },
  { serviceName: 'COVID 19 PCR test', servicePrice: 0, branches: 12, status: 10, duration: 25, cutOff: 72, type: [0], isHighlighted: true },
  { serviceName: 'Asthma', servicePrice: 10, branches: 34, status: 23, duration: 45, cutOff: 48, type: [0,1,2] },
  { serviceName: 'Hepatitis A - vaccination', servicePrice: 20, branches: 45, status: 12, duration: 25, cutOff: 24, type: [0,2] },
  { serviceName: 'Impetigo treatment', servicePrice: 21, branches: 67, status: 18, duration: 35, cutOff: 72, type: [0,2] },
  { serviceName: 'Flu vaccination', servicePrice: 12, branches: 33, status: 42, duration: 45, cutOff: 36, type: [0,1] },
  { serviceName: 'Male hair loss - tablets', servicePrice: 18, branches: 8, status: 5, duration: 15, cutOff: 12, type: [0] },
  { serviceName: 'Period delay - tablets', servicePrice: 20.5, branches: 11, status: 15, duration: 5, cutOff: 36, type: [1,2] },
  { serviceName: 'Surgical operations', servicePrice: 11, branches: 2, status: 19, duration: 25, cutOff: 48, type: [0] },
  { serviceName: 'Specialist medical treatments', servicePrice: 51, branches: 135, status: 40, duration: 10, cutOff: 24, type: [1] },
  { serviceName: 'Maternity and neonatal', servicePrice: 120, branches: 87, status: 10, duration: 15, cutOff: 72, type: [0,1] },
  { serviceName: 'Pathology', servicePrice: 41, branches: 5, status: 20, duration: 20, cutOff: 24, type: [0,1,2], isAlert: true },
  { serviceName: 'Complex dental procedures', servicePrice: 32, branches: 54, status: 30, duration: 30, cutOff: 48, type: [0,1] },
  { serviceName: 'Liaison psychiatry', servicePrice: 22, branches: 3, status: 40, duration: 10, cutOff: 24, type: [0,2] },
  { serviceName: 'IVF clinics providing surgical treatment or endoscopy', servicePrice: 18, branches: 22, status: 50, duration: 5, cutOff: 72, type: [1,2] },
  { serviceName: 'Minor injuries units', servicePrice: 14, branches: 56, status: 60, duration: 45, cutOff: 24, type: [0,1,2] },
];

export const columnsServicesList = [
  { className: 'cell-main', Header: locale.OrganisationServices.table.servicesSummary.headers.serviceName, accessor: 'serviceName', Cell: ({ cell: { value } }) => <span>{value}</span> },
  { className: 'cell-default', Header: locale.OrganisationServices.table.servicesSummary.headers.branches, accessor: 'branches', Cell: ({ cell: { value } }) => <span>{value}</span> },
  { className: 'cell-default', Header: locale.OrganisationServices.table.servicesSummary.headers.servicePrice, accessor: 'servicePrice', Cell: ({ cell: { value } }) => <span>{formatCurrency(value)}</span> },
  { className: 'cell-default', Header: locale.OrganisationServices.table.servicesSummary.headers.status, accessor: 'status', Cell: ({ cell: { value } }) => <span>{value} active</span> },
  { className: 'cell-default', Header: locale.OrganisationServices.table.servicesSummary.headers.duration, accessor: 'duration', Cell: ({ cell: { value } }) => <span>{value} min</span> },
  { className: 'cell-default', Header: locale.OrganisationServices.table.servicesSummary.headers.cutOff, accessor: 'cutOff', Cell: ({ cell: { value } }) => <span>{value} hours</span> },
  { className: 'cell-default', Header: locale.OrganisationServices.table.servicesSummary.headers.modes, accessor: 'type', Cell: ({ cell: { value } }) => <i className="group">{value.map(item => (<Fragment key={item}>{item === 0 ? <IconPerson outline={false} /> : null}{item === 1 ? <IconPhone outline={false} /> : null}{item === 2 ? <IconVideo outline={false} /> : null}</Fragment>))}</i>, sortType: 'basic' },
];

// Use `disableSortBy: true` to disable sorting for a specific column

export function TableServicesList({ columns, data, props }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'serviceName', desc: false }],
      },
    },
    useSortBy,
  );
  const [ isMenuOn, setMenuOn ] = useState(false);

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div className="react-table-settings-background" onClick={() => { setMenuOn(!isMenuOn); }} />
          <div className="react-table-settings-menu">
            <h2>{locale.OrganisationServices.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map(column => (
                <div className="input-checkbox input-checkbox-small" key={column.id}>
                  <label htmlFor={column.id}>
                    <input id={column.id} name={column.id} type="checkbox" disabled={column.id === 'serviceName' ? 'disabled' : null} {...column.getToggleHiddenProps()} />
                    <span>{column.Header}</span>
                  </label>
                </div>
              ))}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  }

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div className="react-table-settings-button" onClick={() => { setMenuOn(!isMenuOn); }}>
          <i><IconCog outline={false} /></i>
        </div>
      </div>
      <table className="react-table react-table-classNameOne spacing-medium" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="sticky grey">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr className={row.original.isHighlighted ? 'highlighted' : ''} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {(cell.column.Header === locale.OrganisationServices.table.servicesSummary.headers.serviceName) ?  <Fragment>{row.original.isAlert ? <div className="tooltip tooltip-error"><Tooltip vertical="middle" horizontal="right" message="All branches with this service added do not have it added to a calendar"><Icon type="inline" size="large" icon={<IconAlert outline={false} />} /></Tooltip></div> : null}<a href="#sidebar" title={cell.value} onClick={(e) => { e.preventDefault(); props.updateForm({ isEditMode: true, isSelectionVisible: false }); }}>{cell.render('Cell')}</a></Fragment> : <Fragment>{cell.render('Cell')}</Fragment>}
                      </td>
                    );
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
