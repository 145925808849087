import type { Action } from 'types/actions';
import * as RouterConstants from 'constants/RouterConstants';

export const setOrganisationHeader = (organisationId: string): Action => ({
  type: RouterConstants.SET_ORGANISATION_ID,
  payload: organisationId,
});

export const setBranchHeader = (branchId: string): Action => ({
  type: RouterConstants.SET_BRANCH_ID,
  payload: branchId,
});

export const removeOrganisationHeader = (): Action => ({
  type: RouterConstants.REMOVE_ORGANISATION_ID,
});

export const removeBranchHeader = (): Action => ({
  type: RouterConstants.REMOVE_BRANCH_ID,
});
