import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

type Props = {
  intl: any,
};

class MetaData extends Component<Props> {
  render() {
    const { intl } = this.props;
    return (
      <Helmet>
        <meta name="description" content={intl.messages['landing.meta.description']} />
        <title>{intl.messages['landing.meta.title']}</title>
        <style type="text/css">{`#beacon-container { display: none; }`}</style>
      </Helmet>
    );
  }
}

export default injectIntl(MetaData);
