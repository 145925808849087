import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentScroll, FeatureContentHeader } from 'components/Share/feature';
import { SkeletonTable } from 'components/Share/skeleton';
import OrganisationSidebar from 'components/Pages/Admin/OrganisationContent/OrganisationSidebar/OrganisationSidebar';
import { getServicesSummary, setUI, resetUserData } from 'actions/organisationServices';
import FilterServicesSummary from './FilterServicesSummary/FilterServicesSummary';
import FilterServicesSummarySkeleton from './FilterServicesSummary/FilterServicesSummarySkeleton';
import OverlayServiceDescription from './OverlayServiceDescription/OverlayServiceDescription';
import OverlayBranches from './OverlayConfirmation/OverlayBranches';
import OverlayConfirmation from './OverlayConfirmation/OverlayConfirmation';
import { columns, TableServicesSummary } from './TableServicesSummary/TableServicesSummary';
import { columnsAffiliates, TableServicesSummaryAffiliates } from './TableServicesSummary/TableServicesSummaryAffiliates';
import SidebarAddService from './SidebarAddService/SidebarAddService';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as RolesConstants from 'constants/RolesConstants';
import * as SearchConstants from 'constants/SearchConstants';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  searchValue: string,
  data: any,
  branches: any,
  isAffiliate: boolean,
  isSuperAdmin: boolean,
  isLoading: boolean,
  isError: boolean,
  getServicesSummary: (organisationId: string) => any,
  setUI: (value: any) => any,
  resetUserData: () => any,
};

const mapStateToProps = (state) => ({
  searchValue: state.organisationServices.user.search.servicesSummary,
  data: state.organisationServices.servicesSummary.data,
  branches: state.organisationDetails.branches,
  isAffiliate: state.organisationDetails.organisationType === OrganisationsConstants.ORGANISATION_TYPE.AFFILIATE,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  isLoading: state.organisationServices.servicesSummary.isLoading,
  isError: state.organisationServices.servicesSummary.isError,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getServicesSummary: (organisationId: string) => dispatch(getServicesSummary(organisationId)),
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserData: () => dispatch(resetUserData()),
});

class OrganisationServices extends Component<Props> {
  componentDidMount = () => {
    const { organisationId, getServicesSummary } = this.props;
    getServicesSummary(organisationId);
  }

  componentWillUnmount = () => {
    const { resetUserData } = this.props;
    resetUserData();
  }

  render() {
    const { organisationId, searchValue, data, branches, isAffiliate, isSuperAdmin, isLoading, isError, setUI } = this.props;
    const isEmptyBranches = branches.length === 0;
    const isEmptyServices = data.length === 0;

    let dataFiltered = data;
    if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
      dataFiltered = data.filter(service => service.serviceName.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const renderTableServicesSummary = () => {
      const isDataFilteredEmpty = dataFiltered.length === 0;
      if (isDataFilteredEmpty) {
        return (
          <NoResults type="info">
            <h2>{locale.OrganisationServices.noResults.servicesSummaryFilter}</h2>
          </NoResults>
        );
      }
      if (isAffiliate) {
        return <TableServicesSummaryAffiliates data={dataFiltered} columns={columnsAffiliates} />;
      }
      return <TableServicesSummary data={dataFiltered} columns={columns} />;
    }

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <FeatureContentSticky className="smallest">
              <FeatureContentHeader className="spacing-medium">
                <FilterServicesSummarySkeleton organisationId={organisationId} isSuperAdmin={isSuperAdmin} />
              </FeatureContentHeader>
            </FeatureContentSticky>
            <FeatureContentScroll className="smallest" isGreyBackground={false}>
              <SkeletonTable className="spacing-medium" columns={6} isSticky={true} isGrey={true} />
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.OrganisationServices.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICES}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmptyServices) {
        if (isEmptyBranches) {
          return (
            <Fragment>
              <FeatureContentScroll className="smallest" isGreyBackground={true}>
                <NoResults type="info">
                  <h2>{locale.OrganisationServices.noResults.servicesSummaryBranches}</h2>
                  <Button to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.goToBranches} />
                </NoResults>
              </FeatureContentScroll>
            </Fragment>
          );
        }
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.OrganisationServices.noResults.servicesSummary}</h2>
                <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.addService} onClick={() => { setUI({ isSidebarAddVisible: true }); }} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <FilterServicesSummary organisationId={organisationId} isSuperAdmin={isSuperAdmin} />
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableServicesSummary()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    return (
      <Feature>
        <FeatureMenu>
          <OrganisationSidebar organisationId={organisationId} />
        </FeatureMenu>
        <FeatureContent>
          {renderContent()}
          <SidebarAddService organisationId={organisationId} isAffiliate={isAffiliate} />
          <OverlayConfirmation organisationId={organisationId} isAddBranches={false} />
          <OverlayServiceDescription />
          <OverlayBranches />
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationServices);
