import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link as LinkScroll } from 'react-scroll';
import { NoResults, Button } from '@patient-access/ui-kit';
import { TaskManager as TaskManagerWrapper, TaskManagerHeader, TaskManagerContent, TaskManagerContentScroll, TaskManagerClose, TaskManagerFooter } from './wrappers';
import { Overlay as TaskManagerOverlay, OverlayBranches } from './overlay';
import { SkeletonTask, SkeletonSelect, SkeletonHeadingOne } from 'components/Share/skeleton';
import { getTasksList, setTaskManagerIsVisible, dismissTask } from 'actions/taskManager';
import { getOrganisationsForAsyncSelect } from 'actions/organisations';
import Tasks from './Tasks';
import * as RolesConstants from 'constants/RolesConstants';
import type { Organisation } from "types/organisation";
import locale from 'service/locale';
import './styles.scss';

type Props = {
  isVisible: boolean,
  isSuperAdmin: boolean,
  isError: boolean,
  isInitialLoading: boolean,
  tasksList: any,
  organisationList: Organisation[],
  getTasksList: () => any,
  setTaskManagerIsVisible: () => Action,
  dismissTask : (taskId: any) => any,
  getOrganisationsForAsyncSelect: () => Action,
};

const mapStateToProps = state => ({
  isVisible: state.taskManager.isVisible,
  isInitialLoading: state.taskManager.tasksList.isInitialLoading,
  isError: state.taskManager.tasksList.isError,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  tasksList: state.taskManager.tasksList.data,
  organisationList: state.organisations.organisationsListAsync,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTasksList: () => dispatch(getTasksList()),
  setTaskManagerIsVisible: (isVisible: boolean) => dispatch(setTaskManagerIsVisible(isVisible)),
  dismissTask: (taskId: any) => dispatch(dismissTask(taskId)),
  getOrganisationsForAsyncSelect: () => dispatch(getOrganisationsForAsyncSelect()),
});

class TaskManager extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      taskDetail: null,
      selectedOrg: {value: null, label: "All organisations"},
    };
  }

  componentDidMount = () => {
    const { getTasksList, getOrganisationsForAsyncSelect } = this.props;
    getOrganisationsForAsyncSelect();
    getTasksList();
    const taskManager = (window.__ENV && window.__ENV.taskManager) || {};
    let { pollingInterval } = taskManager;
    setInterval(() => this.pollTasksListData(), parseInt(pollingInterval));
  };

  // componentDidUpdate = () => {
  //   const { isVisible } = this.props;
  //   if (isVisible && this.state.isInitialLoading) {
  //     const taskManager = (window.__ENV && window.__ENV.taskManager) || {};
  //     let {
  //       pollingInterval
  //     } = taskManager;
  //     this.setState({ interval : setInterval(() => this.pollTasksListData(), parseInt(pollingInterval)), isInitialLoading: false })
  //   }
  // }

  pollTasksListData = () => {
    const { getTasksList } = this.props;
    getTasksList();
  }

  handleDismiss = (e: any) => {
    const { dismissTask, tasksList, isSuperAdmin } = this.props;
    const { selectedOrg } = this.state;
    var tasks = tasksList;
    if (isSuperAdmin && selectedOrg.value) {
      tasks = tasks.filter(t => t.input.OrganisationId === selectedOrg.value);
    }
    if(e.value === locale.TaskManager.button.dismissAllTasks)
    {
      var dismissAllTasks = tasks.filter(task => task.runtimeStatus === "Terminated" || task.runtimeStatus === "Completed" || task.runtimeStatus === "Failed")
      .map(task => task.input.TaskId);
      dismissTask(dismissAllTasks)
    }
    if(e.value === locale.TaskManager.button.dismissCompleted)
    {
      var dismissCompleted = tasks.filter(task => (task.runtimeStatus === "Completed" || task.runtimeStatus === "Failed") && task.customStatus  && task.customStatus.Succeed === task.customStatus.Total)
      .map(task => task.input.TaskId);
      dismissTask(dismissCompleted)
    }
    if(e.value === locale.TaskManager.button.dismissCompletedWithErrors)
    {
      var dismissCompletedWithErrors = tasks.filter(task => (task.runtimeStatus === "Failed" && (task.customStatus === null || (task.customStatus &&  task.customStatus.Succeed < task.customStatus.Total))) || (task.runtimeStatus === "Completed" && task.customStatus.Succeed < task.customStatus.Total))
      .map(task => task.input.TaskId);
      dismissTask(dismissCompletedWithErrors)
    }
  };

  closeTaskManager = () => {
    const { setTaskManagerIsVisible } = this.props;
    setTaskManagerIsVisible(false);
    this.setState({ selectedOrg: {value: null, label: "All organisations"}});
  };

  render() {
    const { isVisible, isSuperAdmin, isError, tasksList, organisationList, isInitialLoading } = this.props;
    const { taskDetail, selectedOrg } = this.state;
    var tasks = tasksList;
    if (isSuperAdmin && selectedOrg.value) {
      tasks = tasks.filter(t => t.input.OrganisationId === selectedOrg.value);
    }
    var completedTaskCount = tasks.filter(task => (task.runtimeStatus === "Completed" || task.runtimeStatus === "Failed") && task.customStatus && task.customStatus.Succeed === task.customStatus.Total)
    .map(task => task.input.TaskId).length;
    var failedTaskCount = tasks.filter(task => (task.runtimeStatus === "Failed" && (task.customStatus === null || (task.customStatus &&  task.customStatus.Succeed < task.customStatus.Total))) || (task.runtimeStatus === "Completed" && task.customStatus && task.customStatus.Succeed < task.customStatus.Total))
    .map(task => task.input.TaskId).length;
    var terminatedTask = tasks.filter(task => task.runtimeStatus === "Terminated").length;

    const options = [
      (((completedTaskCount > 0 && failedTaskCount > 0) || terminatedTask > 0 ) ? { value: locale.TaskManager.button.dismissAllTasks, label: locale.TaskManager.button.dismissAllTasks } : null),
      (completedTaskCount > 0 ? { value: locale.TaskManager.button.dismissCompleted, label: locale.TaskManager.button.dismissCompleted } : null),
      (failedTaskCount > 0 ? { value: locale.TaskManager.button.dismissCompletedWithErrors, label: locale.TaskManager.button.dismissCompletedWithErrors } : null)
    ].filter(x => x !== null);

    const isEmpty = !isError && tasksList.length === 0;

    const renderSelect = () => {
      if (isSuperAdmin && organisationList && organisationList.length > 0) {
        var orgList = tasksList.length > 0 ? [{ value: null, label: "All organisations" }] : [];
        tasksList.map(task => (
          (task && task.input && organisationList) &&
          orgList.push({ value: task.input.OrganisationId, label: organisationList.find(org => org.id === task.input.OrganisationId).companyName })
        ));
        orgList = [...new Map(orgList.map(org => [org["value"], org])).values()];
        return <Select id="organisation-filter" name="organisation-filter" className="patient-care-select" classNamePrefix="patient-care" value={tasksList.length > 0 && selectedOrg} options={orgList} placeholder="Please select" defaultValue={tasksList.length > 0 && orgList[0]} onChange={(e) => { handleOrganisationFilter(e); }} />;
      }
      return null;
    }

    const renderSelectSkeleton = () => {
      if (isSuperAdmin) return <SkeletonSelect />;
      return null;
    }

    const setOverlayData = (task) => {
      this.setState({ taskDetail: task });
    }

    const handleOrganisationFilter = (e: Event) => {
      this.setState({ selectedOrg: e });
    }

    const renderContent = () => {
      if (isVisible) {
        if (isInitialLoading) {
          return (
            <Fragment>
              <TaskManagerHeader>
                <SkeletonHeadingOne />
                {renderSelectSkeleton()}
              </TaskManagerHeader>
              <TaskManagerContent>
                <SkeletonTask />
                <SkeletonTask />
                <SkeletonTask />
              </TaskManagerContent>
              <TaskManagerClose doClose={() => { this.closeTaskManager(); }} />
            </Fragment>
          );
        }
        if (isError) {
          return (
            <Fragment>
              <TaskManagerHeader>
                <h2>{locale.TaskManager.header.default}</h2>
                {renderSelect()}
              </TaskManagerHeader>
              <TaskManagerContent>
                <NoResults type="info">
                  <h2>{locale.TaskManager.error.generic}</h2>
                  <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.TaskManager.button.tryAgain} onClick={(e) => { e.preventDefault(); }} />
                </NoResults>
              </TaskManagerContent>
              <TaskManagerClose doClose={() => { this.closeTaskManager(); }} />
            </Fragment>
          );
        }
        if (isEmpty) {
          return (
            <Fragment>
              <TaskManagerHeader>
                <h2>{locale.TaskManager.header.default}</h2>
                {renderSelect()}
              </TaskManagerHeader>
              <TaskManagerContent>
                <NoResults type="info">
                  <h2>{locale.TaskManager.noResults.noTasks}</h2>
                </NoResults>
              </TaskManagerContent>
              <TaskManagerClose doClose={() => { this.closeTaskManager(); }} />
            </Fragment>
          );
        }
        return (
          <Fragment>
            <TaskManagerHeader>
              <h2>{locale.TaskManager.header.default}</h2>
              {renderSelect()}
            </TaskManagerHeader>
            <TaskManagerContentScroll>
              <Tasks isSuperAdmin={isSuperAdmin} setOverlayData={setOverlayData} selectedOrgId={selectedOrg.value} />
            </TaskManagerContentScroll>
            <TaskManagerFooter>
              {tasksList.length > 0 && (completedTaskCount > 0 || failedTaskCount > 0) &&
              <Select
                options={options}
                placeholder={locale.TaskManager.button.dismissTasks}
                isSearchable={false}
                className="patient-care-select-dismiss"
                classNamePrefix="patient-care"
                menuPlacement="top"
                onChange={this.handleDismiss}
              />}
              <LinkScroll to="task-manager-content" className="ui-kit-button ui-kit-button-secondary ui-kit-button-medium" containerId="task-manager-content-scroll" offset={0} duration={300} smooth="easeInOutCubic">
                <span>{locale.TaskManager.button.scrollTop}</span>
              </LinkScroll>
            </TaskManagerFooter>
            <TaskManagerClose doClose={() => { this.closeTaskManager(); }} />
          </Fragment>
        );
      }
      return null;
    }

    return (
      <Fragment>
        <TaskManagerWrapper>
          {renderContent()}
        </TaskManagerWrapper>
        <TaskManagerOverlay taskDetail={taskDetail} />
        <OverlayBranches />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskManager);