import locale from 'service/locale';

export const SET_BRANCHES = "SET_BRANCHES";
export const SET_BRANCHES_LIST = "SET_BRANCHES_LIST";
export const SET_BRANCH_DETAILS = "SET_BRANCH_DETAILS";
export const UPDATE_BRANCHES_LIST = "UPDATE_BRANCHES_LIST";
export const SET_BRANCHES_PPL_LIST = "SET_BRANCHES_PPL_LIST";
export const SET_BRANCH_CONFIRMATION_STATUS = "SET_BRANCH_CONFIRMATION_STATUS";
export const SET_ALL_SERVICES_LIST = "SET_ALL_SERVICES_LIST";
export const SET_BRANCH_SERVICES_LIST = "SET_BRANCH_SERVICES_LIST";
export const DELETE_NEW_BRANCH_SERVICE = "DELETE_NEW_BRANCH_SERVICE";
export const ADD_NEW_BRANCH_SERVICE = "ADD_NEW_BRANCH_SERVICE";
export const CLEAR_NEW_BRANCH_SERVICE = "CLEAR_NEW_BRANCH_SERVICE";
export const SET_BRANCH_CALENDARS = "SET_BRANCH_CALENDARS";
export const SET_SERVICE_DATA = "SET_SERVICE_DATA";
export const SET_BRANCH_CALENDAR = "SET_BRANCH_CALENDAR";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const REDIRECT_TO_BRANCH_SETTINGS = "REDIRECT_TO_BRANCH_SETTINGS";
export const REMOVE_REDIRECT = "REMOVE_REDIRECT";
export const SET_BRANCHES_PENDING = "SET_BRANCHES_PENDING";
export const SET_BRANCHESPPL_PENDING = "SET_BRANCHESPPL_PENDING";
export const SET_CALENDAR_PENDING = "SET_CALENDAR_PENDING";
export const SET_CALENDAR_PENDING_SUCCESS = "SET_CALENDAR_PENDING_SUCCESS";
export const SET_CALENDAR_PENDING_ERROR = "SET_CALENDAR_PENDING_ERROR";
export const SET_SERVICES_PENDING = "SET_SERVICES_PENDING";
export const SET_SERVICES_PENDING_SUCCESS = "SET_SERVICES_PENDING_SUCCESS";
export const SET_SERVICES_PENDING_ERROR = "SET_SERVICES_PENDING_ERROR";
export const CREATE_BRANCH_PENDING = "CREATE_BRANCH_PENDING";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_ERROR = "CREATE_BRANCH_ERROR";
export const UPDATE_SERVICES_PENDING = "UPDATE_SERVICES_PENDING";
export const UPDATE_SERVICES_SUCCESS = "UPDATE_SERVICES_SUCCESS";
export const UPDATE_SERVICES_ERROR = "UPDATE_SERVICES_ERROR";
export const CREATE_CALENDAR_PENDING = "CREATE_CALENDAR_PENDING";
export const CREATE_CALENDAR_SUCCESS = "CREATE_CALENDAR_SUCCESS";
export const CREATE_CALENDAR_ERROR = "CREATE_CALENDAR_ERROR";
export const UPDATE_BRANCH_SETTINGS_PENDING = "UPDATE_BRANCH_SETTINGS_PENDING";
export const UPDATE_BRANCH_SETTINGS_SUCCESS = "UPDATE_BRANCH_SETTINGS_SUCCESS";
export const UPDATE_BRANCH_SETTINGS_ERROR = "UPDATE_BRANCH_SETTINGS_ERROR";
export const SET_SERVICE_FUTURE_APPOINTMENTS = "SET_SERVICE_FUTURE_APPOINTMENTS";
export const DELETE_BRANCH_PENDING = "DELETE_BRANCH_PENDING";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_ERROR = "DELETE_BRANCH_ERROR";
export const SET_NEW_BRANCH_SERVICE = "SET_NEW_BRANCH_SERVICE";
export const SET_PPL_SERVICE_DETAILS = "SET_PPL_SERVICE_DETAILS";
export const CHANGE_BRANCH_PUBLISH_STATUS = "CHANGE_BRANCH_PUBLISH_STATUS";
export const SET_BRANCHES_LIST_ASYNC = "SET_BRANCHES_LIST_ASYNC";
export const SET_SERVICES_LIST_ASYNC = "SET_SERVICES_LIST_ASYNC";
export const CHANGE_BRANCH_SMART_PHARMACY_ENABLED = "CHANGE_BRANCH_SMART_PHARMACY_ENABLED";

export const SET_ONLINE_BRANCH_OVERLAY_VISIBILITY = "SET_ONLINE_BRANCH_OVERLAY_VISIBILITY";
export const SET_ONLINE_BRANCH_OVERLAY_CREATE_LATER = "SET_ONLINE_BRANCH_OVERLAY_CREATE_LATER";

export const SET_PENDING_APPOINTMENTS_COUNT = "SET_PENDING_APPOINTMENTS_COUNT";
export const SET_DISPLAY_PENDING_ALERT = "SET_DISPLAY_PENDING_ALERT";
export const SET_DISPLAY_PENDING_APPOINTMENT_LIST = "SET_DISPLAY_PENDING_APPOINTMENT_LIST";

export const ELEMENTS_PER_PAGE = 30;
export const BRANCH_STATUSES = {
  DEF: "default",
  WRONG: "wrong password",
  CORRECT: "correct password",
  UPDATED: "updated",
  DELETED: "deleted",
};

export const BRANCHES_SELECT = [
  {
    label: "All branches",
    value: 0,
  },
  {
    label: "Incomplete branches",
    value: 1,
  },
  {
    label: "Complete branches",
    value: 2,
  },
];

export const DROPDOWN_OPTION_DEFAULT = {
  value: undefined,
  label: locale.Share.defaultBranchSelect,
};

export const PASSWORD_CONFIRMATION_STATUSES = {
  CHECKING: "checking",
  WRONG: "Wrong password",
  LAST_ATTEMPT: "Last attempt",
  BLOCKED: "User blocked",
  CONFIRMED: "Password confirmed",
  UPDATED: "Branch updated",
};

export const PASSWORD_WARNING_STATUSES = {
  NO_BOOKINGS: "No bookings",
  HAS_BOOKINGS: "Has bookings",
  HAS_UNCOMPLETED: "Has uncompleted",
  COMMON: "bookings",
};

export const periodsList = [
  { value: 5, label: '5 min' },
  { value: 10, label: '10 min' },
  { value: 15, label: '15 min' },
  { value: 20, label: '20 min' },
  { value: 25, label: '25 min' },
  { value: 30, label: '30 min' },
  { value: 35, label: '35 min' },
  { value: 40, label: '40 min' },
  { value: 45, label: '45 min' },
  { value: 50, label: '50 min' },
  { value: 55, label: '55 min' },
  { value: 60, label: '60 min' },
  { value: 65, label: '65 min' },
  { value: 70, label: '70 min' },
  { value: 75, label: '75 min' },
  { value: 80, label: '80 min' },
  { value: 85, label: '85 min' },
  { value: 90, label: '1 hr 30 min' },
  { value: 120, label: '2 hrs' },
  { value: 180, label: '3 hrs' },
  { value: 240, label: '4 hrs' },
  { value: 300, label: '5 hrs' },
];

export const lastMinuteOptions = [
  { value: 0, label: "No cutoff" },
  { value: 1, label: "1 hr" },
  { value: 3, label: "3 hrs" },
  { value: 6, label: "6 hrs" },
  { value: 12, label: "12 hrs" },
  { value: 24, label: "24 hrs" },
  { value: 48, label: "48 hrs" },
  { value: 72, label: "72 hrs" },
  { value: 96, label: "96 hrs" },
];

export const pausedOptions = [
  { value: true, label: locale.BranchServices.pausedTrue },
  { value: false, label: locale.BranchServices.pausedFalse },
];

export const modesOptions = [
  { value: 0, label: 'Face-to-face appointment' },
  { value: 1, label: 'Video appointment' },
  { value: 2, label: 'Telephone appointment' },
  { value: 5, label: 'Home delivery' },
];

export type CONFIRMATION_TYPES = "calendar" | "branch" | "organisation";

export type ConfirmationStatus = $Values<typeof PASSWORD_CONFIRMATION_STATUSES>;

export const COUNTRY = "United Kingdom";

export const DEFAULT_BRANCHID = "00000000-0000-0000-0000-000000000000";

export const calendarNameConflictError = "Duplicate calendar name";
export const odsDuplicateErrorKey = "Branch ods code duplicate";
export const wrongAvailablePeriods = "CalendarAvailablePeriodNotWithinOpenHours";

export const PENDING_ALERT_PAGE = {
  CALENDAR: "Calendar",
  REPORT: "Report"
};

export const PENDING_APPOINTMENT = "PastAndNotCompleted";