import type { Action } from "types/actions";
import agent from "service/agent";
import * as BranchesConstants from "constants/BranchesConstants";
import { closeModalOverlay } from "actions/panel";
import { showSnackbarStatus } from "actions/snackbar";
import { getBranchDetails } from 'actions/branchDetails';
import { getToken, modifyServicesList } from "helpers/common";
import locale from "service/locale";

const setAllServices = (allServicesList: any[]): Action => ({
  type: BranchesConstants.SET_ALL_SERVICES_LIST,
  payload: allServicesList
});

export const addNewBranchService = (service: Object): Action => ({
  type: BranchesConstants.ADD_NEW_BRANCH_SERVICE,
  payload: service
});

export const deleteNewBranchService = (service: Object): Action => ({
  type: BranchesConstants.DELETE_NEW_BRANCH_SERVICE,
  payload: service
});

export const clearNewBranchServiceList = (): Action => ({
  type: BranchesConstants.CLEAR_NEW_BRANCH_SERVICE
});

export const setServiceDetails = (serviceId: string, data: any): Action => ({
  type: BranchesConstants.SET_SERVICE_DATA,
  payload: { serviceId, data }
});

export const setNewBranchService = (branchDetails: Branch): Action => ({
  type: BranchesConstants.SET_NEW_BRANCH_SERVICE,
  payload: branchDetails
});

export const deleteService = (serviceId: string): Action => ({
  type: BranchesConstants.DELETE_SERVICE,
  payload: serviceId
});

export const updateServicesPending = (): Action => ({
  type: BranchesConstants.UPDATE_SERVICES_PENDING,
});

export const updateServicesSuccess = (): Action => ({
  type: BranchesConstants.UPDATE_SERVICES_SUCCESS,
});

export const updateServicesError = (): Action => ({
  type: BranchesConstants.UPDATE_SERVICES_ERROR,
});

export const setPPLServiceDetails = (service: any): Action => ({
  type: BranchesConstants.SET_PPL_SERVICE_DETAILS,
  payload: service
});

const setServicesListForAsyncSelect = (servicesList: any[], searchValue ? : string): Action => ({
  type: BranchesConstants.SET_SERVICES_LIST_ASYNC,
  payload: {
    servicesListAsync: servicesList,
    searchValueAsync: searchValue
  }
});

export const getAllServicesList = () => (dispatch: Function) => {
  getToken(dispatch)
    .then(accessToken => {
      agent.Branches.getAllServicesList(accessToken)
        .then(allServicesList => {
          dispatch(setAllServices(allServicesList.items));
        })
        .catch(err => {
          console.log("get all possible services list server error", err);
        });
    });

};

export const addBranchServices = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const branchDetails = currentState.branchDetails;
  const newServicesList = currentState.services.newServicesList;
  dispatch(
    setNewBranchService({
      ...branchDetails,
      services: [...branchDetails.services, ...newServicesList]
    })
  );
  dispatch(clearNewBranchServiceList());
  dispatch(closeModalOverlay());
};

export const updateBranchServiceList = () => (dispatch: Function, getState: Function) => {
  const { branchDetails, router } = getState();
  const organisationId = router.organisationId;
  const branchId = branchDetails.branchId;
  const servicesList = branchDetails.services.map((service) => {
    let { id, duration, price, vatIncluded, lastMinute, showPrice, nhsCovered, paused, modes } = service;
    duration = duration || 0;
    price = nhsCovered ? (price || 0) : price;
    paused = paused || false;
    modes = modes || [];
    return { id, duration, price, vatIncluded, lastMinute, showPrice, paused, modes };
  });

  dispatch(updateServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      return agent.Branches.updateBranchServicesList(branchId, servicesList, accessToken);
    })
    .then(() => {
      dispatch(updateServicesSuccess());
      dispatch(getBranchDetails(organisationId, branchId));
      dispatch(showSnackbarStatus(locale.Snackbar.branchServiceListUpdated));
    })
    .catch(err => {
      dispatch(updateServicesError());
      if (err.status === 400) {
        console.log('updateBranch server error. Appointment Conflict');
        dispatch(showSnackbarStatus(locale.Snackbar.branchServiceHasAppointments));
        dispatch(getBranchDetails(organisationId, branchId));
        return;
      }

      console.log("updateBranch server error or branch is not found", err);
      dispatch(showSnackbarStatus(locale.Snackbar.branchNotUpdated));
    });

};

export const getPPLServiceDetails = (serviceId: string) => (dispatch: Function) => {
  getToken(dispatch).then(accessToken => {
    agent.Branches.getPPLServiceDetails(serviceId, accessToken)
      .then(service => {
        dispatch(setPPLServiceDetails(service));
      })
      .catch(err => {
        console.log("get service server error or service is not found", err);
      });
  });
};

export const getServicesForAsyncSelect = (
  searchValue: string = "",
  organisationId?: string,
  branchId?: string,
  callback?: Function,
) => (dispatch: Function) => {
  getToken(dispatch)
    .then(accessToken => {
      const pageNumber = 1;
      const itemsPerPage = 999;
      agent.Branches.getFilteredServices(
        pageNumber,
        searchValue,
        itemsPerPage,
        accessToken,
        organisationId,
        branchId
      )
        .then(servicesList => {
          servicesList.items = modifyServicesList(servicesList);
          !callback && dispatch(setServicesListForAsyncSelect(servicesList, (searchValue || "")));
          callback && callback(servicesList.items);
        })
        .catch(err => {
          console.log("getServicesForAsyncSelect server error or branch was not found", err);
        });
    });

};
