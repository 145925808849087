import React, { Component } from 'react';
import { LinkIcon, IconClose } from '@patient-access/ui-kit';
import './styles/task-manager-close.scss';

type Props = {
  doClose: any,
};

class TaskManagerClose extends Component<Props> {
  render() {
    const { doClose } = this.props;
    return (
      <div className="task-manager-close">
        <LinkIcon to="#close" size="medium" icon={<IconClose outline />} accessibilitySpan="Close" onClick={(e) => { e.preventDefault(); doClose(); }} />
      </div>
    );
  }
};

export default TaskManagerClose;
