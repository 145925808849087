import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form } from "@patient-access/ui-kit";
import Select from "react-select";
import { ROLES_TWO_FACTOR_LIST } from "constants/RolesConstants";

import { updateForm, clearForm } from "actions/form";

type Props = {
  userDetails: any,
  form: any,
  updateForm: (data: any) => any,
  clearForm: () => any,
  isEditMode: boolean,
};

type State = {
  twoFactorSelect: any,
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
});

export class TwoFactorUserPreference extends Component<Props, State> {
  state = {
    twoFactorSelect: {},
  };

  componentDidMount = () => {
    const { userDetails, form } = this.props;

    if (form.requiresMFA === undefined) {
      updateForm({
        requiresMFA: userDetails.requiresMFA,
      });
    }

    this.setState({
      twoFactorSelect: userDetails.requiresMFA
        ? ROLES_TWO_FACTOR_LIST[1]
        : ROLES_TWO_FACTOR_LIST[0],
    });
  };

  handleTwoFactorSelect = (option) => {
    const { updateForm } = this.props;

    updateForm({
      requiresMFA: option.value ? true : false,
    });
    this.setState({ twoFactorSelect: option });
  };

  render() {
    const dropDownValues = ROLES_TWO_FACTOR_LIST;

    const { userDetails, form, isEditMode } = this.props;

    const { twoFactorSelect } = this.state;

    const renderEditMode = () => {
      return (
        <Fragment>
          <Form>
            <Select
              id="user-mfa-status"
              name="user-mfa-status"
              classNamePrefix="patient-care"
              className={`patient-care-select`}
              options={dropDownValues}
              defaultValue={twoFactorSelect}
              value={twoFactorSelect}
              onChange={this.handleTwoFactorSelect}
            />
          </Form>
        </Fragment>
      );
    };

    const renderReadMode = () => {
      return (
        <Fragment>
          <span className="patient-care-filled-input">
            {form.requiresMFA !== undefined
              ? twoFactorSelect.label
              : userDetails.requiresMFA
                ? ROLES_TWO_FACTOR_LIST[1].label
                : ROLES_TWO_FACTOR_LIST[0].label}
          </span>
        </Fragment>
      );
    };
    return (
      <Fragment>{isEditMode ? renderEditMode() : renderReadMode()}</Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorUserPreference);
