import { addLocaleData } from 'react-intl';

import enLocale from 'react-intl/locale-data/en';
import esLocale from 'react-intl/locale-data/es';

import en from './dictionaries/en';
import es from './dictionaries/es';

addLocaleData([...enLocale, ...esLocale]);

export default {
  en,
  es,
};
