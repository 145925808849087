import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, LogoNHS } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarFooter } from 'components/Share/sidebar';
import { getServiceDescription, putBranchServicesUpdate, setUserStep, setUserData, setUI } from 'actions/branchServices';
import { SectionModes, SectionStatus, SectionPrice, SectionDuration, SectionCutOff, SectionBookingUrl } from './section';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as RolesConstants from 'constants/RolesConstants';
import locale from 'service/locale';
import { checkURL } from 'helpers/checkValues';

type Props = {
  organisationId: string,
  branchId: string,
  userData: any,
  servicesSummary: any,
  isSuperAdmin: boolean,
  isAffiliate: boolean,
  isLoading: boolean,
  getServiceDescription: (serviceId: string) => any,
  setUserStep: (value: any) => any,
  setUserData: (data: any) => any,
  setUI: (value: any) => any,
};

type State = {
  isErrorModes: boolean,
  isErrorStatus: boolean,
  isErrorPrice: boolean,
  isErrorDuration: boolean,
  isErrorCutOff: boolean,
  isErrorBookingURL: boolean,
  isBookingUrlEdited: boolean,
};

const mapStateToProps = (state) => ({
  userData: state.branchServices.user.data,
  servicesSummary: state.branchServices.servicesSummary.data,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  isAffiliate: state.organisationDetails.organisationType === OrganisationsConstants.ORGANISATION_TYPE.AFFILIATE,
  isLoading: state.branchServices.servicesUpdate.isLoading,
  orgBookingDetails: state.organisationDetails.booking,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getServiceDescription: (serviceId: string) => dispatch(getServiceDescription(serviceId)),
  putBranchServicesUpdate: (organisationId: string, branchId: string, servicesList: any[], snackbarText: string) => dispatch(putBranchServicesUpdate(organisationId, branchId, servicesList, snackbarText)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUI: (value: any) => dispatch(setUI(value)),
});

class StepTwo extends Component<Props, State> {
  state = {
    isErrorModes: false,
    isErrorStatus: false,
    isErrorPrice: false,
    isErrorDuration: false,
    isErrorCutOff: false,
    isErrorBookingURL: !((this.props.userData.serviceBookingUrl && checkURL(this.props.userData.serviceBookingUrl)) || !this.props.userData.serviceBookingUrl),
    isBookingUrlEdited: this.props.userData.serviceBookingUrl !=null && this.props.userData.serviceBookingUrl !==""
  }

  render() {
    const { organisationId, branchId, userData, servicesSummary, isSuperAdmin, isAffiliate, isLoading, getServiceDescription, putBranchServicesUpdate, setUserStep, setUserData, setUI, orgBookingDetails } = this.props;
    const { isErrorModes, isErrorStatus, isErrorPrice, isErrorDuration, isErrorCutOff,  isErrorBookingURL, isBookingUrlEdited} = this.state;

    const isEmptyModes = userData.serviceModes.length === 0;
    const isEmptyStatus = userData.serviceIsActive === null;
    const isEmptyShowPrice = userData.serviceIsShowPrice === null;
    const isEmptyPrice = userData.servicePrice === null;
    const isValidPrice = !(userData.servicePrice !== '' && userData.servicePrice >= 0);
    const isEmptyDuration = userData.serviceDuration === null;
    const isEmptyCutOff = userData.serviceCutOff === null;

    const isFormValid = () => {
      if (isAffiliate) {
        return !isEmptyModes && !isEmptyStatus && (userData.serviceIsShowPrice ? !isEmptyPrice && !isValidPrice : !isEmptyShowPrice) && !isEmptyDuration && !isErrorBookingURL;
      } else {
        return !isEmptyModes && !isEmptyStatus && !isEmptyPrice && !isValidPrice && !isEmptyDuration && !isEmptyCutOff;
      }
    }

    const handleValidateForm = () => {
      if (isAffiliate) {
        handleValidateModes();
        handleValidateStatus(!isEmptyStatus);
        handleValidatePrice(userData.serviceIsShowPrice ? !isEmptyPrice && !isValidPrice : !isEmptyShowPrice);
        handleValidateDuration(!isEmptyDuration);
        handleValidateBookingUrl(!isErrorBookingURL, isBookingUrlEdited);
      } else {
        handleValidateModes();
        handleValidateStatus(!isEmptyStatus);
        handleValidatePrice(!isEmptyPrice && !isValidPrice);
        handleValidateDuration(!isEmptyDuration);
        handleValidateCutOff(!isEmptyCutOff);
      }
    }

    const handleValidateModes = () => {
      this.setState({ isErrorModes: !userData.serviceModes.length });
    }

    const handleValidateStatus = (value) => {
      this.setState({ isErrorStatus: !value });
    }

    const handleValidatePrice = (value) => {
      this.setState({ isErrorPrice: !value });
    }

    const handleValidateDuration = (value) => {
      this.setState({ isErrorDuration: !value });
    }

    const handleValidateCutOff = (value) => {
      this.setState({ isErrorCutOff: !value });
    }

    const handleValidateBookingUrl = (isUrlValue, isUrlEdited) => {
      this.setState({ isErrorBookingURL: !isUrlValue, isBookingUrlEdited: isUrlEdited });
    }
    

    const handleAddService = () => {
      const formatUserData = {
        id: userData.serviceId,
        name: userData.serviceName,
        allowedModes: userData.serviceAvailableModes,
        nhsCovered: userData.serviceIsNhsCovered,
        modes: userData.serviceModes,
        paused: !userData.serviceIsActive,
        showPrice: userData.serviceIsShowPrice || false, // API_DOESNT_ACCEPT_NULL_VALUES
        price: userData.servicePrice,
        vatIncluded: userData.serviceIsVatIncluded || false, // API_DOESNT_ACCEPT_NULL_VALUES
        duration: userData.serviceDuration,
        lastMinute: userData.serviceCutOff || 0, // API_DOESNT_ACCEPT_NULL_VALUES
        bookingpoint: {
          url: userData.serviceBookingUrl,
        },
      };

      if (!userData.serviceBookingUrl && !isBookingUrlEdited) {
        formatUserData.bookingpoint = null;
      }


      const servicesList = servicesSummary.concat(formatUserData);
      putBranchServicesUpdate(organisationId, branchId, servicesList, locale.Snackbar.branchAddService(userData.serviceName));
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.BranchServicesRedesign.sidebar.addService.header}</h2>
          <p>
            <span>{userData.serviceName}</span>
            {userData.serviceIsNhsCovered ? <i><LogoNHS /></i> : null}
            <Link to="#description" onClick={(e) => { e.preventDefault(); getServiceDescription(userData.serviceId); setUI({ isOverlayDescriptionVisible: true }); }}>
              {locale.BranchServicesRedesign.sidebar.serviceDescription}
            </Link>
          </p>
        </SidebarHeader>
        <SidebarBody>
          <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
            <SectionModes userData={userData} setUserData={setUserData} isError={isErrorModes} isSuperAdmin={isSuperAdmin} handleValidateModes={handleValidateModes} />
            <SectionStatus userData={userData} setUserData={setUserData} isError={isErrorStatus} handleValidateStatus={handleValidateStatus} />
            <SectionPrice userData={userData} setUserData={setUserData} isError={isErrorPrice} isAffiliate={isAffiliate} handleValidatePrice={handleValidatePrice} />
            <SectionDuration userData={userData} setUserData={setUserData} isError={isErrorDuration} handleValidateDuration={handleValidateDuration} />
            <SectionCutOff userData={userData} setUserData={setUserData} isError={isErrorCutOff} isAffiliate={isAffiliate} handleValidateCutOff={handleValidateCutOff} />
            {isAffiliate && <SectionBookingUrl userData={userData} 
                                               setUserData={setUserData} 
                                               isError={isErrorBookingURL} 
                                               isAffiliate={isAffiliate} 
                                               isEdited={isBookingUrlEdited}
                                               defaultUrl = { isBookingUrlEdited && userData.serviceBookingUrl!=null? userData.serviceBookingUrl:(orgBookingDetails.point && orgBookingDetails.point.url) } 
                                               handleValidateBookingUrl={handleValidateBookingUrl} />}
          </Form>
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column" />
            <div className="column">
              <Button buttonType="blueline" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.goBack} onClick={() => { setUserStep(1); }} isLoading={isLoading} />
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchServicesRedesign.button.addServiceToBranch} onClick={isFormValid() ? handleAddService : handleValidateForm} className={isFormValid() ? '' : 'ui-kit-button-disabled-visually'} isLoading={isLoading} />
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
