import React, { Fragment, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { LinkIcon, IconClose, Checkbox, Form, FormItem } from '@patient-access/ui-kit';
import './styles.scss';
import locale from 'service/locale';
import { Button } from '@patient-access/ui-kit';
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import Select from "react-select";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";

export function TableCalendar({ data, columns, getAppointmentDetails, completeBulkAppointment, ouctcomeStatus, currentStatusesOptions, disableListView, pendingAlertPage }) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [appointmentStatus, setOutcomeStatus] = useState(locale.Appointment.status.pending.value);
    const [isOpenBulkAppointment, setOpenBulkAppointment] = useState(false);
    const [isAlerttOverlayOpened, setOverlayOpen] = useState(false);
    const [statuscolor, setStatusColor] = useState(locale.Appointment.status.pending.color);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups
    } = useTable({ columns: columns, data: data }, useSortBy);

    // Handle Edit click
    const handleEdit = (appointmentId) => {
        getAppointmentDetails(appointmentId, AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA, pendingAlertPage);
    };

    const selectedAll = selectedRows.length === data.flatMap((entry) => entry.appointments).length;

    // Handle checkbox click
    const handleCheckboxClick = (e, row) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setOpenBulkAppointment(true);
            setSelectedRows([...selectedRows, row]);
        }
        else {
            let rows = selectedRows.filter((r) => r.id !== row.id);
            if (rows.length === 0) {
                setOpenBulkAppointment(false);
            }
            setSelectedRows(rows);
        }
    };

    // Handle update bulk appointments
    const handleUpdateBulkAppointments = () => {
        let appointmentOutcome;
        let appointmentList = [];
        if (selectedRows.length > 0 && appointmentStatus !== locale.Appointment.status.pending.value) {
            appointmentOutcome = ouctcomeStatus.find((n) => n.value === appointmentStatus).label;
            selectedRows.map((n) => {
                return (appointmentList.push({
                    appointmentId: n.id,
                    isPaid: n.isPaid
                }))
            });

            const bulkAppointmentList = {
                bulkAppointments: appointmentList,
                outcome: appointmentOutcome
            };

            handleCancel();
            completeBulkAppointment(bulkAppointmentList, appointmentOutcome, appointmentList.length);
        }
        else {
            setOverlayOpen(true);
        }
    };

    // Handle cancel onclick
    const handleCancel = () => {
        setSelectedRows([]);
        setOpenBulkAppointment(false);
        setOutcomeStatus(locale.Appointment.status.pending.value);
        setStatusColor(locale.Appointment.status.pending.color);
    }

    const selectedStatus = appointmentStatus !== 6 ? currentStatusesOptions.find(option => option.value === appointmentStatus) : locale.Appointment.status.pending;
    // Handle appointment status change
    const handleStatusChange = (selectedOption) => {
        setOutcomeStatus(selectedOption.value);
        setStatusColor(selectedOption.color);
    };

    // Handle back onclick
    const handleBack = () => {
        setOverlayOpen(false);
    };

    const handleClose = () => {
        disableListView();
    }

    // Toggle Select all
    const toggleSelectAll = () => {
        if (selectedRows.length === data.flatMap((entry) => entry.appointments).length) {
            setSelectedRows([]);
            setOpenBulkAppointment(false);
        } else {
            const allAppointments = data.flatMap((entry) => entry.appointments);
            setSelectedRows(allAppointments);
            setOpenBulkAppointment(true);
        }
    };

    return (
        <div className={`${(isOpenBulkAppointment) ? 'table-container-popup' : 'table-container'}`}>
            <Fragment>
                <table {...getTableProps()} className='react-table-calendar'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='react-table-calendar-th-tr'>
                                <th>
                                    <span>{locale.CalendarListHeader.table.headers.date}</span>
                                </th>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                                <th>
                                    <label>{locale.CalendarListHeader.table.headers.editRow}</label>
                                </th>
                                <th>
                                    <Form>
                                        <FormItem type="item">
                                            <div className="input-checkbox input-checkbox-medium checkbox-calendar">
                                                <Checkbox checked={selectedAll} onChange={() => toggleSelectAll()} />
                                            </div>
                                        </FormItem>
                                    </Form>

                                </th>
                                <th>
                                    <LinkIcon to="#close" size="small" icon={<IconClose outline />} onClick={() => { handleClose(); }} />
                                </th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} >
                        {data.map((entry, i) => (
                            <React.Fragment key={i}>
                                <tr>
                                    <td colSpan={8} className='react-table-calendar-td'>
                                        {entry.date}
                                    </td>
                                </tr>
                                {entry.appointments.map((appointment, index) => (
                                    <tr key={index} className='react-table-calendar-th'>
                                        <td></td>
                                        {columns.map((column, colIndex) => (
                                            <td key={colIndex}>
                                                {column.Cell
                                                    ? column.Cell({ value: appointment[column.accessor], row: { selected: appointment } })
                                                    : appointment[column.accessor]}
                                            </td>
                                        ))}
                                        <td>
                                            <a href="#edit" className='edit-row' onClick={() => handleEdit(appointment.id)}>{locale.CalendarListHeader.table.headers.editRow}</a>
                                        </td>
                                        <td>
                                            <Form>
                                                <div className="input-checkbox input-checkbox-medium checkbox-calendar">
                                                    <label>
                                                        <input type="checkbox" checked={selectedRows.includes(appointment)} onChange={(e) => handleCheckboxClick(e, appointment)} />
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </Form>
                                        </td>
                                        <td></td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </Fragment>
            {isOpenBulkAppointment &&
                <div className='sticky-bulk-appointment-div'>
                    <div className="table-calendar-div-h3">
                        <h3 className="table-calendar-div-style-h3">{locale.CalendarListHeader.labels.appointmentStatus}</h3>
                    </div>

                    <div className='table-calendar-div-p'>
                        <div className="patient-care-block">
                            <p className="patient-care-block-p">
                                {locale.CalendarListHeader.labels.appointmentStatusDescription} <br /> {locale.CalendarListHeader.labels.appointmentStatusSecond}
                            </p>
                        </div>
                        <div className='table-calendar-inner-div'>
                            <table className='table-calendar-list'>
                                <tr>
                                    <td className='table-calendar-inner-div-td'>
                                        <div className={`patient-care-row-status ${statuscolor}`}>
                                            <h3 className="patient-care-status-title">{locale.CalendarListHeader.labels.appointment} </h3>
                                            <Select
                                                id="appointment-status"
                                                name="appointment-status"
                                                options={currentStatusesOptions}
                                                onChange={handleStatusChange}
                                                className="patient-care-select"
                                                classNamePrefix="patient-care"
                                                value={selectedStatus}
                                                menuPlacement="top"
                                            />
                                        </div>
                                    </td>
                                    <td className='table-calendar-td'>
                                        <Button
                                            buttonType="blueline"
                                            messageKey="cancel-btn"
                                            defaultMessage={locale.CalendarListHeader.button.cancel}
                                            onClick={handleCancel}
                                            data-id="cancel-btn"
                                            className="patient-care-btn-in-group"
                                        />
                                    </td>
                                    <td className='table-calendar-td'>
                                        <Button
                                            buttonType="secondary"
                                            messageKey="some-key"
                                            defaultMessage={locale.CalendarListHeader.button.updateBulkAppointments}
                                            onClick={handleUpdateBulkAppointments} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {
                isAlerttOverlayOpened &&
                <InformationalOverlay
                    header={locale.Modals.alertCalendarListOverlay.header}
                    buttonOk={locale.Modals.alertCalendarListOverlay.buttonOk}
                    content={locale.Modals.alertCalendarListOverlay.content}
                    handleOk={handleBack}
                />
            }
        </div>
    )
};
