import { connect } from "react-redux";

import AADLogin from "./AADLogin";

const mapStateToProps = (state) => ({
  login: state.login,
  isAuth: state.login.isAuth,
});

const mapDispatchToProps = (dispatch) => ({
});

const AADLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AADLogin);

export default AADLoginContainer;
