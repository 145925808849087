const defaultEN = {
  // Landing:Head
  'landing.meta.title': 'Patient Access Professional Pharmacy Management',
  'landing.meta.description': 'Enabling users to book pharmacy services and professional pharmacy managers to arrange appointments, resources via a single calendar solution.',

  // Landing:Header
  'landing.header.logo.accessibility': 'Patient Access for Professionals',
  'landing.header.button.more': 'Get started',
  'landing.header.button.sign_in': 'Sign in',

  // Landing:Footer
  'landing.footer.link.terms': 'Terms of Use',
  'landing.footer.link.privacy_policy': 'Privacy policy',
  'landing.footer.link.support': 'Support Centre',
  'landing.footer.copyright': '© Egton Medical Information Systems. All rights reserved. Patient Access for Professionals does not provide medical advice, diagnosis or treatment.',

  // Landing:Footer:Sticky
  'landing.footer.sticky.copy': 'Contact us today for a quote',
  'landing.footer.sticky.button': 'Get started',

  // Landing:Connect
  'landing.connect.header': 'Reach over 17 million customers',
  'landing.connect.copy.line_one': 'Patient Access is the UK’s biggest patient online services platform with over 17 million users.',
  'landing.connect.copy.line_two': 'Whether you’re a pharmacy, a physiotherapist, a podiatrist or any other health professional, we’ll work with you to help reach Patient Access users in your area, helping to increase your customer numbers and grow your business.',
  'landing.connect.button': 'Get started',

  // Landing:Tabs
  'landing.tabs.header': 'What type of healthcare business are you?',
  'landing.tabs.tab_one': 'Pharmacy business',
  'landing.tabs.tab_two': 'Other healthcare business',

  // Landing:Feature:Pharmacy:Bookings
  'landing.feature.pharmacy.bookings.header': 'Supercharge your pharmacy bookings',
  'landing.feature.pharmacy.bookings.feature_one.header': 'More customers, more bookings',
  'landing.feature.pharmacy.bookings.feature_one.copy': 'Make your pharmacy services available to Patient Access users in your area, with the UK’s most widely used online healthcare services.',
  'landing.feature.pharmacy.bookings.feature_two.header': 'Flu jabs, blood tests, travel vaccinations and more',
  'landing.feature.pharmacy.bookings.feature_two.copy': 'Choose which services to make available in Patient Access with over 85 to choose from including flu jabs, weight loss, hair loss, travel vaccinations, and many more.',
  'landing.feature.pharmacy.bookings.feature_three.header': 'Offer face-to-face and video appointments',
  'landing.feature.pharmacy.bookings.feature_three.copy': 'Offer face-to-face appointments for Patient Access users in your local area, or reach all of our users nationally with video and telephone appointments.',
  'landing.feature.pharmacy.bookings.testimonial_copy': '“I’m surprised to see how easily I can book the treatments I need with Patient Access. It’s fast and so convenient to do it online.”',
  'landing.feature.pharmacy.bookings.testimonial_no': 'Over 500,000 reviews in the App Store and Google Play',

  // Landing:Feature:Pharmacy:Nominations
  'landing.feature.pharmacy.nominations.header': 'Drive more nominations',
  'landing.feature.pharmacy.nominations.feature_one.header': 'Stand out from the crowd',
  'landing.feature.pharmacy.nominations.feature_one.copy': 'Promote your pharmacy in the Patient Access app to drive more nominations from our users.',
  'landing.feature.pharmacy.nominations.feature_two.header': 'Allow customers to track their medication orders',
  'landing.feature.pharmacy.nominations.feature_two.copy': 'By joining Patient Access you can enable your customers to track the status of their repeat medication – they’ll no longer need to call or turn up to your pharmacy before their prescription is ready.',

  // Landing:Feature:Pharmacy:Management
  'landing.feature.pharmacy.management.header': 'A platform to manage your services and bookings',
  'landing.feature.pharmacy.management.feature_one.header': 'Branch management system',
  'landing.feature.pharmacy.management.feature_one.copy': 'Manage your services, availability, and appointments with our intuitive admin features.',
  'landing.feature.pharmacy.management.feature_two.header': 'Branch calendar for your appointments and events',
  'landing.feature.pharmacy.management.feature_two.copy': 'Capture walk-in and phone bookings alongside any appointments booked online by customers, all in the same calendar.',

  // Landing:Feature:Pharmacy:Business
  'landing.feature.pharmacy.business.header': 'Receive payments and track business performance',
  'landing.feature.pharmacy.business.feature_one.header': 'Accept online payments',
  'landing.feature.pharmacy.business.feature_one.copy': 'Seamlessly receive payments for services booked online and get paid quickly.',
  'landing.feature.pharmacy.business.feature_two.header': 'Business metrics and reports',
  'landing.feature.pharmacy.business.feature_two.copy': 'Get a detailed breakdown of financial performance and appointment statuses with a self-service reporting tool, and download your data to use however you wish.',

  // Landing:Feature:Other:Bookings
  'landing.feature.other.bookings.header': 'Supercharge your bookings',
  'landing.feature.other.bookings.feature_one.header': 'More customers, more bookings',
  'landing.feature.other.bookings.feature_one.copy': 'Make your pharmacy services available to over 10 million customers with the UK’s most widely used online healthcare services platform.',
  'landing.feature.other.bookings.feature_two.header': 'Physiotherapy, counselling, podiatry, osteopathy and more',
  'landing.feature.other.bookings.feature_two.copy': 'We work with a wide range of healthcare specialists across multiple care settings, with over 120 different service currently being offered to in Patient Access to our users.',
  'landing.feature.other.bookings.feature_three.header': 'Offer face-to-face and video appointments',
  'landing.feature.other.bookings.feature_three.copy': 'Offer face-to-face appointments for Patient Access users in your local area, or reach all of our users nationally with video and telephone appointments.',
  'landing.feature.other.bookings.testimonial_copy': '“I’m surprised to see how easily I can book the treatments I need with Patient Access. It’s fast and so convenient to do it online.”',
  'landing.feature.other.bookings.testimonial_no': 'Over 500,000 reviews in the App Store and Google Play',

  // Landing:Feature:Other:Management
  'landing.feature.other.management.header': 'A platform to manage your services and bookings',
  'landing.feature.other.management.feature_one.header': 'Branch management system',
  'landing.feature.other.management.feature_one.copy': 'Manage your services, availability and appointments with our intuitive admin features.',
  'landing.feature.other.management.feature_two.header': 'Branch calendar for your appointments and events',
  'landing.feature.other.management.feature_two.copy': 'Capture walk-in and phone bookings alongside any appointments booked online by customers, all in the same calendar.',

  // Landing:Feature:Other:Business
  'landing.feature.other.business.header': 'Receive payments and track business performance',
  'landing.feature.other.business.feature_one.header': 'Accept online payments',
  'landing.feature.other.business.feature_one.copy': 'Seamlessly receive payments for appointments booked online and get paid quickly.',
  'landing.feature.other.business.feature_two.header': 'Business metrics and reports',
  'landing.feature.other.business.feature_two.copy': 'Get a detailed breakdown of financial performance and appointment statuses with a self-service reporting tool and download your data to use however you wish.',

  // Landing:Package
  'landing.package.header': 'A package for every pharmacy',
  'landing.package.type_services': 'Patient Access',
  'landing.package.type_smart': 'Smart Pharmacy',
  'landing.package.services': 'Patient Access',
  'landing.package.smart': 'Smart Pharmacy',
  'landing.package.feature_01.name': 'Calendar system for managing appointments',
  'landing.package.feature_02.name': 'Branch management system',
  'landing.package.feature_03.name': 'Take bookings for private services',
  'landing.package.feature_04.name': 'Receive online payments for private services',
  'landing.package.feature_05.name': 'Take telephone bookings for remote appointments',
  'landing.package.feature_06.name': 'Reporting and analytics suite to track performance',
  'landing.package.feature_07.name': 'Smart pharmacies highlighted to Patient Access users for nomination',
  'landing.package.feature_08.name': 'Branch profile pages in Patient Access to market your business',
  'landing.package.feature_09.name': 'Prescription status tracking for your customers',

  // Landing:More
  'landing.more.heading': 'Get started today',
  'landing.more.content': 'Just provide your details below and we will be in touch to discuss how we can partner with you to help get more customers through your doors.',
  'landing.more.form.name_label': 'Your name',
  'landing.more.form.name_placeholder': 'Your name',
  'landing.more.form.email_label': 'Email address',
  'landing.more.form.email_placeholder': 'Email address',
  'landing.more.form.phone_label': 'Phone number',
  'landing.more.form.phone_placeholder': 'Phone number',
  'landing.more.form.pharmacy_label': 'Company name',
  'landing.more.form.pharmacy_placeholder': 'Company name',
  'landing.more.form.postcode_label': 'Postcode',
  'landing.more.form.postcode_placeholder': 'Postcode',
  'landing.more.form.contact_label': 'I am happy to be contacted by Patient Access to arrange a follow-up',
  'landing.more.form.error_empty': 'Please fill in this field',
  'landing.more.form.error_invalid_email': 'Invalid email address',
  'landing.more.form.error_invalid_phone': 'Invalid phone number',
  'landing.more.form.error_invalid_postcode': 'Invalid postcode',
  'landing.more.form.button': 'Send details',
  'landing.more.form.success': 'Your request has been submitted successfully!',
  'landing.more.form.error': 'There was a problem registering your interest, please refresh the page and try again.',
};

const en = Object.assign(
  {},
  defaultEN,
);

export default en;
