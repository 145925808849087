import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormItem, InputLabel, Input, InputError } from "@patient-access/ui-kit";
import { checkPostcode } from 'helpers/checkValues';

import { updateForm } from 'actions/form';
import locale from 'service/locale';

type Props = {
  form: any,
  updateForm: (date: any) => any,
};

type State = {
  addressLine1: string,
  addressLine2: string,
  town: string,
  postcode: string,
  country: string
};

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class AddSoleTraderOrganisationInfo extends Component<Props, State>
{
  state = {
    addressLine1: "",
    addressLine2: "",
    town: "",
    postcode: "",
    country: ""
  };

  componentWillUnmount = () => {
    this.setState({
      addressLine1: "",
      addressLine2: "",
      town: "",
      postcode: "",
      country: ""
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.form) {
      const { address } = nextProps.form;
      if (address) {
        const { addressLine1, addressLine2, town, postcode, country } = address;
        this.setState({
          addressLine1,
          addressLine2,
          town,
          postcode,
          country
        });
      }
    }
  };

  handleAddressChange = (e: Event) => {
    const { updateForm, form } = this.props;
    const {
      target: { name, value }
    } = e;

    updateForm({
      address: {
        ...form.address,
        [name]: value,
      },
    })
  };

  render() {
    const { form } = this.props;
    const { addressLine1, addressLine2, town, postcode, country } = this.state;
    const isEditedAddressLine1 = form.address && Object.keys(form.address).includes('addressLine1');
    const isEditedAddressTown = form.address && Object.keys(form.address).includes('town');
    const isEditedAddressPostcode = form.address && Object.keys(form.address).includes('postcode');
    const isEditedAddressCountry = form.address && Object.keys(form.address).includes('country');
    const isPostcodeCorrect = checkPostcode(postcode);

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddOrganisation.companyInfo.addressTitle}</h3>
          <p>{locale.AddOrganisation.companyInfo.addressText}</p>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item">
              <InputLabel
                htmlFor="organisation_address_1"
                message={locale.AddOrganisation.companyInfo.addressLine1}
                size="small"
              />
              <Input
                type="text"
                id="organisation_address_1"
                name="addressLine1"
                data-id="organisation_address_1"
                value={addressLine1 ? addressLine1 : ""}
                onChange={this.handleAddressChange}
              />
              {
                !addressLine1 && isEditedAddressLine1 &&
                <InputError message={locale.AddOrganisation.companyInfo.addressError} />
              }
            </FormItem>
          </Form>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item">
              <InputLabel
                htmlFor="organisation_address_2"
                message={locale.AddOrganisation.companyInfo.addressLine2}
                size="small"
              />
              <Input
                type="text"
                id="organisation_address_2"
                name="addressLine2"
                data-id="organisation_address_2"
                value={addressLine2 ? addressLine2 : ""}
                onChange={this.handleAddressChange}
              />
            </FormItem>
          </Form>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item">
              <InputLabel
                htmlFor="organisation_town"
                message={locale.AddOrganisation.companyInfo.addressTown}
                size="small"
              />
              <Input
                type="text"
                id="organisation_town"
                name="town"
                data-id="organisation_town"
                value={town ? town : ""}
                onChange={this.handleAddressChange}
              />
              {
                !town && isEditedAddressTown &&
                <InputError message={locale.AddOrganisation.companyInfo.townError} />
              }
            </FormItem>
          </Form>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item">
              <InputLabel
                htmlFor="organisation_postcode"
                message={locale.AddOrganisation.companyInfo.addressPostcode}
                size="small"
              />
              <Input
                type="text"
                id="organisation_postcode"
                name="postcode"
                data-id="organisation_postcode"
                value={postcode ? postcode : ""}
                onChange={this.handleAddressChange}
              />
              {
                !isPostcodeCorrect && isEditedAddressPostcode &&
                <InputError message={locale.AddOrganisation.companyInfo.postcodeError} />
              }
            </FormItem>
          </Form>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item">
              <InputLabel
                htmlFor="organisation_country"
                message={locale.AddOrganisation.companyInfo.addressCountry}
                size="small"
              />
              <Input
                type="text"
                id="organisation_country"
                name="country"
                data-id="organisation_country"
                value={country ? country : ""}
                onChange={this.handleAddressChange}
              />
              {
                !country && isEditedAddressCountry &&
                <InputError message={locale.AddOrganisation.companyInfo.countryError} />
              }
            </FormItem>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSoleTraderOrganisationInfo);