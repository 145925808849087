import React, { Component } from "react";
import { Icon, IconChevronLeft } from "@patient-access/ui-kit";
import { connect } from "react-redux";
import { NavLink, Link } from 'react-router-dom';

import { getOrganisationDetails } from 'actions/organisationDetails';
import { setBranchHeader } from "actions/router";
import type { Action } from 'types/actions';
import type { Branch, OrganisationDetails } from 'types/organisation';
import * as RolesConstants from 'constants/RolesConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import locale from 'service/locale';
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';

type Props = {
  organisationId: string,
  branchId: string,
  branchesList: Branch[],
  currentRole: Object,
  getOrganisationDetails: (organisationId: string) => Action,
  organisationDetails: OrganisationDetails,
  setBranchHeader: (branchId: string) => Action
}

type State = {
  branch: any;
}

const mapStateToProps = (state) => ({
  branchesList: state.branches.branchesList,
  currentRole: state.roles.profileCurrentRole,
  organisationDetails: state.organisationDetails
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getOrganisationDetails: (organisationId) => dispatch(getOrganisationDetails(organisationId)),
  setBranchHeader: (branchId: string) => dispatch(setBranchHeader(branchId))
});

class BranchSidebar extends Component<Props, State> {
  state = {
    branch: this.props.organisationDetails.branches.length ? this.props.organisationDetails.branches.find(branch => branch.id === this.props.branchId) : 'Loading...',
  };

  componentDidMount = () => {
    const { branch } = this.state;
    const { getOrganisationDetails, organisationId, branchId, setBranchHeader } = this.props;

    setBranchHeader(branchId);
    if (branch === 'Loading...') {
      getOrganisationDetails(organisationId);
    }
  };

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      branch: nextProps.organisationDetails.branches.length ? nextProps.organisationDetails.branches.find(branch => branch.id === this.props.branchId) : 'Loading...'
    });
  };

  render() {
    const { branch } = this.state;
    const { organisationId, branchId, currentRole, organisationDetails } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrganisationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    const isAffiliateOrganisation = organisationDetails && organisationDetails.organisationType === ORGANISATION_TYPE.AFFILIATE;

    return (
      <aside className="patient-care-sidebar-left">
        <div className="patient-care-sidebar-block">
          <div className="patient-care-breadcrumbs">
            {(isSuperAdmin || isOrganisationAdmin) &&
              <Link to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`} className="patient-care-breadcrumbs-icon">
                <Icon
                type="inline"
                size="smaller"
                icon={<IconChevronLeft outline={true} />}
              />
              </Link>
            }
            {branch && branch.name}
          </div>
        </div>
        <div className="patient-care-sidebar-block">
          <ul className="patient-care-unstyled-list">
            <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.USERS}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.users}</NavLink></li>
            {!isAffiliateOrganisation ? <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.AVAILABILITY}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.availability}</NavLink></li> : null}
            <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.SERVICES}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.services}</NavLink></li>
            {!isAffiliateOrganisation ? <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.CALENDARS}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.calendars}</NavLink></li> : null}
            {!isAffiliateOrganisation ? <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.ROOMS}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.rooms}</NavLink></li> : null}
            <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.DETAILS}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.branchDetails}</NavLink></li>
            {(isSuperAdmin || isOrganisationAdmin) ? <li><NavLink to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.SETTINGS}`} className="patient-care-sidebar-list-item" activeClassName="active">{locale.Sidebars.branchSettings}</NavLink></li> : null}
          </ul>
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchSidebar);
