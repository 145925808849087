import React, { Component } from 'react';
import './styles/reports-legend-item.scss';

type Props = {
  color: string,
  label: string,
};

class ReportsLegendItem extends Component<Props> {
  render() {
    const { color, label } = this.props;
    return (
      <div className="reports-legend-item">
        <i style={{ background: color }} />
        <span>{label}</span>
      </div>
    );
  }
};

export default ReportsLegendItem;
