import * as UserDetailsConstants from "constants/UserDetailsConstants";
import * as RolesConstants from "constants/RolesConstants";
import { getUsersList, getFilteredUsersList } from "actions/users";
import agent from "service/agent";
import {
  closeUserDetails,
  openUserDetails,
  openProfile,
  closeProfile,
  closeProfileDropdown,
  openRoleConflict,
  closeModalOverlay,
  openDuplicateConflict
} from "actions/panel";
import { showSnackbarStatus } from "actions/snackbar";
import { clearForm } from "actions/form";
import { requestUserData } from "actions/profile";
import { closeTwoFactorOverlay } from "actions/panel";
import { getToken } from "helpers/common";
import type { Action } from "types/actions";
import { areValidRoles } from "helpers/UserRoleFilter";

import locale from 'service/locale';

const setUserInfo = (userInfo: any): Action => ({
  type: UserDetailsConstants.SET_USER_INFO,
  payload: userInfo
});

export const createUserPending = (): Action => ({
  type: UserDetailsConstants.CREATE_USER_PENDING,
});

export const createUserSuccess = (): Action => ({
  type: UserDetailsConstants.CREATE_USER_SUCCESS,
});

export const createUserError = (): Action => ({
  type: UserDetailsConstants.CREATE_USER_ERROR,
});

export const updateUserPending = (): Action => ({
  type: UserDetailsConstants.UPDATE_USER_PENDING,
});

export const updateUserSuccess = (): Action => ({
  type: UserDetailsConstants.UPDATE_USER_SUCCESS,
});

export const updateUserError = (): Action => ({
  type: UserDetailsConstants.UPDATE_USER_ERROR,
});

export const getUserPending = (): Action => ({
  type: UserDetailsConstants.GET_USER_PENDING,
});

export const getUserError = (): Action => ({
  type: UserDetailsConstants.GET_USER_ERROR,
});

export const getUserDetails = (id: string, isCurrentUser?: boolean) => (dispatch: Function) => {
  dispatch(getUserPending());

  getToken(dispatch)
    .then(accessToken => {
      agent.Users.getUserInfo(id, accessToken)
        .then(userInfo => {
          dispatch(setUserInfo(userInfo));
          if (isCurrentUser) {
            dispatch(openProfile());
            dispatch(closeProfileDropdown());
          } else {
            dispatch(openUserDetails());
          }
        })
        .catch(err => {
          dispatch(getUserError());
          console.log('getUserDetails server error or user is not found ', err);
        });
    });

};

export const updateUserMFA = (userId: string, form: any) => (dispatch: Function) => {
  const {requiresMFA} = form;
  dispatch(updateUserPending());

  return getToken(dispatch)
    .then(accessToken => {
      agent.Users.updateUserMFA(userId, requiresMFA, accessToken)
        .then(res => {
          dispatch(updateUserSuccess());
          dispatch(clearForm());
          dispatch(closeProfile());
          dispatch(closeUserDetails());
          dispatch(showSnackbarStatus(locale.Snackbar.changesUpdated));
          return true;
        })
        .catch(err => {
          dispatch(updateUserError());
          console.log('updateUserName ', err);
        });

  });

};

export const removeUserMFA = (userId: string) => (dispatch: Function) => {
  dispatch(updateUserPending());

  getToken(dispatch)
    .then(accessToken => {
      agent.Users.removeUserMFA(userId, accessToken)
        .then(res => {
          dispatch(updateUserSuccess());
          dispatch(clearForm());
          dispatch(closeProfile());
          dispatch(requestUserData(accessToken));
          dispatch(getUsersList());
          dispatch(closeTwoFactorOverlay());
          dispatch(closeUserDetails());
          dispatch(showSnackbarStatus(locale.Snackbar.changesUpdated));
        })
        .catch(err => {
          dispatch(updateUserError());
          console.log('updateUserName ', err);
        });

  });

};

export const updateUser = (userId: string, accounts: any[], organizationId?: string, branchId?: string) => (dispatch: Function) => {
  if (!areValidRoles(accounts)) {
    dispatch(openRoleConflict());
    return;
  }
  const filter = {
    organizationId,
    branchId
  };

  dispatch(updateUserPending());
  return getToken(dispatch)
    .then(accessToken => {
      agent.Users.updateUserRoles(userId, accounts, accessToken)
        .then(res => {
          dispatch(updateUserSuccess());
          dispatch(clearForm());
          dispatch(closeUserDetails());
          filter.organizationId ? dispatch(getFilteredUsersList(filter)) : dispatch(getUsersList());
          dispatch(showSnackbarStatus(locale.Snackbar.changesUpdated));
          return true;
        })
        .catch(err => {
          dispatch(updateUserError());
          const { errors } = err.response.body;
          if (errors && errors[0].code === UserDetailsConstants.ERROR_ROLE_CONFLICT) {
            dispatch(openRoleConflict());
          }
          console.log('updateUser ', err);
        });
    });

};

export const updateUserName = (form: any) => (dispatch: Function) => {
  const { displayName } = form;

  dispatch(updateUserPending());
  return getToken(dispatch)
    .then(accessToken => {
      agent.Users.updateUserName(displayName, accessToken)
        .then(res => {
          dispatch(updateUserSuccess());
          dispatch(clearForm());
          dispatch(closeProfile());
          dispatch(closeUserDetails());
          dispatch(requestUserData(accessToken));
          dispatch(getUsersList());
          dispatch(showSnackbarStatus(locale.Snackbar.changesUpdated));
          return true;
        })
        .catch(err => {
          dispatch(updateUserError());
          console.log('updateUserName ', err);
        });
    });

};

export const createUser = (newUser: any, organisationId?: string, branchId?: string) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const currentRole = state.roles.profileCurrentRole;
  const users = state.usersList.data;

  dispatch(createUserPending());
  getToken(dispatch)
    .then(accessToken => {
      tryGetUser(newUser.new_user_email, accessToken).then(userDetails => {
        if (userDetails && ((currentRole.role === RolesConstants.ADMIN && userDetails.accounts.length) || users.find(user => user.id === userDetails.id))) {
          userExistConflict(userDetails.id, dispatch);
          return;
        }

        if (userDetails && userDetails.id) {
          // no need to create a new user
          const { new_user_role, new_user_organization, new_user_branch } = newUser;
          userDetails.accounts.push(
            {
              role: new_user_role.name,
              organizationId: new_user_organization.id || null,
              branchId: new_user_branch.id || null
            }
          );

          if (!areValidRoles(userDetails.accounts)) {
            dispatch(createUserError());
            dispatch(openRoleConflict());
            return;
          }

          dispatch(addUserRole(userDetails.id, userDetails.accounts, organisationId, branchId));
        } else {
          agent.Users.createUser(newUser, accessToken)
            .then(userId => {
              createUserRole(newUser, userId, dispatch, organisationId, branchId);
            })
            .catch(err => {
              dispatch(createUserError());
              dispatch(showSnackbarStatus(locale.Snackbar.userCreatedError));
              dispatch(closeModalOverlay());
              console.log('createUser ', err);
            })
        }
      })
        .catch(err => {
          dispatch(createUserError());
          dispatch(showSnackbarStatus(locale.Snackbar.userCreatedError));
          dispatch(closeModalOverlay());
          console.log('createUser ', err);
        });
    }
    )
};
const createUserRole = (newUser: any, userId: string, dispatch: Function, organisationId?: string, branchId?: string) => {
  const { new_user_role, new_user_organization, new_user_branch } = newUser;
  const roles = [
    {
      role: new_user_role.name,
      organizationId: new_user_organization.id || null,
      branchId: new_user_branch.id || null
    }
  ];
  dispatch(addUserRole(userId, roles, organisationId, branchId));
};

const userExistConflict = (userId: string, dispatch: Function) => {
  dispatch(openDuplicateConflict(userId));
  dispatch(createUserError());
};

const tryGetUser = (email: string, token: any) => {
  return new Promise((resolve, reject) => {
    agent.Users.findUser(email, token)
      .then(data => {
        let { userId } = data;
        agent.Users.getUserInfo(userId, token)
          .then(user => {
            resolve(user);
          })
          .catch(err => {
            reject();
          })
      }).catch(err => {
        if (err.status === 404) {
          resolve(null);
          return;
        }
        reject();
      });
  });
};

export const addUserRole = (userId: string, role: any, organizationId?: string, branchId?: string) => (dispatch: Function) => {
  getToken(dispatch)
    .then(accessToken => {
      agent.Users.updateUserRoles(userId, role, accessToken)
        .then(res => {
          const filter = {
            organizationId,
            branchId
          };

          dispatch(createUserSuccess());
          dispatch(closeModalOverlay());
          organizationId ? dispatch(getFilteredUsersList(filter)) : dispatch(getUsersList());
          dispatch(showSnackbarStatus(locale.Snackbar.userCreated));
        })
        .catch(err => {
          dispatch(createUserError());
          const errors = err.response && err.response.body && err.response.body.errors;
          if (errors && errors[0].code === UserDetailsConstants.ERROR_ROLE_CONFLICT) {
            dispatch(openRoleConflict());
            return;
          }

          dispatch(closeModalOverlay());
          dispatch(showSnackbarStatus(locale.Snackbar.userCreatedError));
          console.log('addUserRole ', err);
        });
    });

};

// TODO: Create a new endpoint to update both roles and requiresMFA- this is a not an appropirate approach to make cascaded api calls
export const updateUserAndMFA = (userId: string, accounts: any[], form: any) => (dispatch: Function) => {
  if (!areValidRoles(accounts)) {
    dispatch(openRoleConflict());
    return;
  }
  const { requiresMFA } = form;

  dispatch(updateUserPending());
  return getToken(dispatch)
    .then(accessToken => {
      return agent.Users.updateUserRoles(userId, accounts, accessToken)
        .then(res => {
          agent.Users.updateUserMFA(userId, requiresMFA, accessToken)
            .then(mfaRes => {
              dispatch(updateUserSuccess());
              dispatch(clearForm());
              dispatch(closeUserDetails());
              dispatch(getUsersList());
              dispatch(showSnackbarStatus(locale.Snackbar.changesUpdated));
              return true;
            })
            .catch(err => {
              dispatch(updateUserError());
              console.log('updateUserName ', err);
            });
        })
        .catch(err => {
          dispatch(updateUserError());
          const { errors } = err.response.body;
          if (errors && errors[0].code === UserDetailsConstants.ERROR_ROLE_CONFLICT) {
            dispatch(openRoleConflict());
          }
          console.log('updateUser ', err);
        });
    });
};