import React, { Component, Fragment } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import moment from 'moment';
import { NoResults, Loader } from '@patient-access/ui-kit';
import * as ColorConstants from 'constants/ColorConstants';
import { formatCurrency, formatNumber } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

type Props = {
  revenue: any,
  isResizing: boolean,
  isLoading: boolean,
  isEmptyRevenue: boolean,
};

class ChartRevenue extends Component<Props> {
  formatXAxis = (tickItem) => {
    return moment(tickItem).format('D MMM');
  }

  formatYAxis = (tickItem) => {
    return formatNumber(tickItem);
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      return (
        <div className="reports-basic-tooltip">
          <h4>{payload[0] ? <Fragment>{locale.Reports.revenueTooltipHeaderPartOne} {moment(payload[0].payload.date).format('DD/MM/YYYY')}</Fragment> : null}</h4>
          <ul>
            {payload.map((item, index) => {
              return <li key={index}><span><strong>{item.name}:</strong></span> <span>{formatCurrency(item.value)}</span></li>;
            })}
          </ul>
        </div>
      );
    }
    return null;
  }

  render() {
    const { revenue, isResizing, isLoading, isEmptyRevenue } = this.props;
    if (isLoading || isEmptyRevenue || isResizing) return <div className="reports-basic-chart revenue"><Loader type="inline" size="small" className="reports-basic-loader" /></div>;
    if (revenue.graphData.length === 0) return <div className="reports-basic-chart revenue"><NoResults type="info"><p>{locale.Reports.revenueGraphNoResults}</p></NoResults></div>;
    return (
      <div className="reports-basic-chart revenue">
        <ResponsiveContainer width="99%" height={446}>
          <AreaChart data={revenue.graphData}>
           <XAxis tickFormatter={this.formatXAxis} tickLine={false} tickSize={16} stroke={ColorConstants.COLOR_BLACK} dataKey="date" axisLine={false} />
           <YAxis tickFormatter={this.formatYAxis} tickLine={false} tickSize={16} stroke={ColorConstants.COLOR_BLACK} axisLine={false} />
           <CartesianGrid strokeDasharray="0 0" stroke={ColorConstants.COLOR_GREY_LIGHT} />
           <Area type="linear" stroke={ColorConstants.COLOR_ACCENT} strokeWidth={2} fill={ColorConstants.COLOR_ACCENT} fillOpacity={0.1} dataKey="total" name={locale.Reports.revenueTotalEstimated} />
           <Area type="linear" stroke={ColorConstants.COLOR_SUPPORT_FIVE} strokeWidth={2} fill={ColorConstants.COLOR_SUPPORT_FIVE} fillOpacity={0.1} dataKey="online" name={locale.Reports.revenueNetOnline} />
           <Area type="linear" stroke={ColorConstants.COLOR_SUPPORT_THREE} strokeWidth={2} fill={ColorConstants.COLOR_SUPPORT_THREE} fillOpacity={0.1} dataKey="inStore" name={locale.Reports.revenueInStoreEstimated} />
           <Tooltip content={this.formatTooltip} cursor={{ stroke: `${ColorConstants.COLOR_SUPPORT_ONE}`, strokeWidth: 1 }} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default ChartRevenue;
