import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, FormItem, InputLabel, Input } from "@patient-access/ui-kit";

import { updateForm } from "actions/form";
import type { Action } from "types/actions";
import locale from "service/locale";

type Props = {
  updateForm: (data: any) => Action,
  handleChangeMode: (type: string, status: boolean) => any,
  name: string,
  form: Object,
  isEditMode: boolean,
  isEmptyNameError: boolean,
  isDuplicateNameError: boolean,
  isBranchMember: boolean,
  isProcessing: boolean
};

type State = {
  isEditMode: boolean,
  name: string,
};

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: data => dispatch(updateForm(data))
});

class CalendarDetailsName extends Component<Props, State> {
  state = {
    isEditMode: false,
    name: this.props.name
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { name } = nextProps;
    this.setState({ name });
  };

  handleChangeMode = (e: any) => {
    e && e.preventDefault();
    const { isEditMode } = this.state;
    const {
      handleChangeMode,
      isEmptyNameError,
      isDuplicateNameError
    } = this.props;
    if ((isEditMode && !isEmptyNameError && !isDuplicateNameError) || !isEditMode) {
      this.setState({ isEditMode: !isEditMode }, () => {
        handleChangeMode("isEditingName", !isEditMode);
      });
    }
  };

  handleNameChange = ({ target: { name, value } }) => {
    const { updateForm } = this.props;
    value.length <= locale.AddNewCalendar.maxCalendarNameLength && updateForm({ [name]: value });
  };

  render() {
    const {
      form,
      isDuplicateNameError,
      isEmptyNameError,
      isBranchMember, 
      isProcessing
    } = this.props;
    const { isEditMode, name } = this.state;
    const isNameChanged = Object.keys(form).includes("name");
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <p>{locale.AddNewCalendar.description}</p>
        </div>
        {isEditMode ? (
          <Fragment>
            <div className="patient-care-block">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="name"
                    message={locale.AddNewCalendar.name}
                    size="small"
                  />
                  <div className="patient-care-row-align-space-between">
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={isNameChanged ? form.name : name}
                      onChange={this.handleNameChange}
                      data-id="name"
                      placeholder={locale.AddNewCalendar.title}
                      disabled={!isEditMode}
                    />
                    {!isBranchMember && !isProcessing && (
                      <button
                        className="patient-care-btn-link"
                        onClick={this.handleChangeMode}
                      >
                        {locale.Buttons.buttonDone}
                      </button>
                    )}
                  </div>
                </FormItem>
              </Form>
            </div>
            {isDuplicateNameError && (
              <div className="patient-care-input-error">
                <p>{locale.AddNewCalendar.duplicateNameText}</p>
              </div>
            )}
            {isEmptyNameError && (
              <div className="patient-care-input-error">
                <p>{locale.AddNewCalendar.emptyNameText}</p>
              </div>
            )}
            {isNameChanged && form.name.length >= locale.AddNewCalendar.maxCalendarNameLength && (
              <div className="patient-care-input-error">
                <p>{locale.AddNewCalendar.longNameText}</p>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="name"
                  message={locale.AddNewCalendar.name}
                  size="small"
                />
              </FormItem>
            </Form>
            <div className="patient-care-row-align-space-between">
              <span className="patient-care-filled-input">{form.name || name}</span>
              {!isBranchMember && !isProcessing && (
                <button
                  className="patient-care-btn-link"
                  onClick={this.handleChangeMode}
                >
                  {locale.Buttons.buttonEdit}
                </button>
              )}
            </div>
          </div>
        )}

        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarDetailsName);
