import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconExpand extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M6.3,8.11,10.18,12,6.29,15.89A1,1,0,0,0,7.7,17.3l4.59-4.59a1,1,0,0,0,0-1.41L7.7,6.71a1,1,0,1,0-1.4,1.4ZM17,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,17,18Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-expand ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconExpand;
