import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Form, FormItem, Checkbox, Icon, IconAlert } from "@patient-access/ui-kit";
import { setDefaultServiceDetails } from "actions/defaultServices";
import { modesOptions } from "constants/BranchesConstants";
import locale from "service/locale";

type Props = {
  row: any[],
  isEditMode: boolean,
  setDefaultServiceDetails: (serviceId: string, data: any) => Action,
  handleDataChanged: () => any

};

type State = {
  errorModes: boolean,
};

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setDefaultServiceDetails: (serviceId, data) => dispatch(setDefaultServiceDetails(serviceId, data)),
});

class DefaultServicesTableRow extends Component<Props, State> {

  state = {
    errorModes: false
  }

  componentDidMount = () => {
    const { row } = this.props;
    if (row.modes === undefined || row.modes.length === 0) this.setState({ errorModes: true });
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { row } = nextProps;
    // prevent false errorModes on 'Discard changes'
    if (row.modes && row.modes.length !== 0) this.setState({ errorModes: false });
  }

  handleDefault = () => {
    const { setDefaultServiceDetails, row, handleDataChanged } = this.props;
    setDefaultServiceDetails(row.id, { isStandard: !row.isStandard });
    handleDataChanged();
  }

  handleModes = (e: any) => {
    const { setDefaultServiceDetails, row, handleDataChanged } = this.props;
    const modesArray = e.map(option => option.value);
    setDefaultServiceDetails(row.id, { modes: modesArray });
    handleDataChanged();
    if (modesArray.length === 0) {
      this.setState({ errorModes: true });
    } else {
      this.setState({ errorModes: false });
    }
  }

  renderAlert = () => {
    const { row } = this.props;
    if (row.modes && row.modes.length === 0) 
    return <b className="alert"><Icon size="large" icon={<IconAlert />} type="inline" /></b>;
  }

  renderName = () => {
    const { row } = this.props;
    return <b>{row.name}</b>    
  }

  renderDefault = () => {
    const { row, isEditMode } = this.props;
    return (
      <Form>
        <FormItem type="item">
          <Checkbox
            id={`default-id-${row.id}`}
            name={`default-name-${row.id}`}
            checked={row.isStandard ? row.isStandard : false}
            value={row.id}
            disabled={!isEditMode}
            className="patient-care-hidden-label default-check-col"
            label={row.id}
            onChange={this.handleDefault}
          />
        </FormItem>
      </Form>
    );
  }

  renderModes = () => {
    const { row, isEditMode } = this.props;
    const { errorModes } = this.state;
    if (isEditMode) {
      return (
        <Select
          id={`modes-id-${row.id}`}
          name={`modes-name-${row.id}`}
          placeholder={locale.AddNewServices.modesPlaceholder}
          defaultValue={row.modes ? modesOptions.filter(el => row.modes.indexOf(el.value) !== -1) : ''}
          options={modesOptions}
          classNamePrefix="patient-care"
          onChange={this.handleModes}
          className={`patient-care-select patient-care-select-multi ${errorModes ? 'patient-care-select-error' : ''}`}
          hideSelectedOptions={false}
          isSearchable={false}
          closeMenuOnSelect={false}
          isMulti
        />
      );
    }
    if (row.modes && row.modes.length !== 0) {
      if (row.modes.length > 1) return <b>{row.modes.length} {locale.BranchServices.modes}</b>;
      return <b>{modesOptions.find(option => option.value === row.modes[0]).label}</b>;
    }
    return <b>{locale.BranchServices.modesNone}</b>;
  }

  render() {
    const { row } = this.props;
    return (
      <tr key={row.id}>
       <td className="default-service-alert">
          {this.renderAlert()}
        </td>
        <td>
          {this.renderName()}
        </td>
        <td>
          {this.renderDefault()}
        </td>
        <td className="patient-care-services-custom-table-left">
          <div className="patient-care-services-modes">
            {this.renderModes()}
          </div>
        </td>
        <td></td>
      </tr>
    );
  }
}

export default connect(
  undefined,
  mapDispatchToProps
)(DefaultServicesTableRow);
