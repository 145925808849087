import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconAppointments extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M16,13H13a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V14A1,1,0,0,0,16,13ZM16,3V4H8V3A1,1,0,0,0,6,3V4H5A2,2,0,0,0,3,6V20a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2H18V3a1,1,0,0,0-2,0Zm2,17H6a1,1,0,0,1-1-1V9H19V19A1,1,0,0,1,18,20Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-appointments ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconAppointments;
