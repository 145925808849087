import React, { Component } from 'react';
import { LinkIcon, IconClose } from '@patient-access/ui-kit';

import './styles/patient-care-feature-sidebar.scss';

type Props = {
  isOpen: boolean,
  doClose: any,
};

type State = {
  isScrollTop: boolean,
  isScrollBottom: boolean,
};

class FeatureSidebar extends Component<Props, State> {
  state = {
    isScrollTop: true,
    isScrollBottom: false,
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    const sidebarBodyDiv = document.querySelector('.sidebar-body');
    this.setState({
      isScrollTop: sidebarBodyDiv.scrollTop === 0,
      isScrollBottom: sidebarBodyDiv.scrollTop === sidebarBodyDiv.scrollHeight - sidebarBodyDiv.clientHeight,
    });
  }

  render() {
    const { isScrollTop, isScrollBottom } = this.state;
    const { isOpen, doClose, children } = this.props;

    if (isOpen) {
      return (
        <div className={`patient-care-feature-sidebar${!isScrollTop ? ' header-shadow' : ''}${!isScrollBottom ? ' footer-shadow' : ''}`}>
          <div className="patient-care-feature-sidebar-background"></div>
          <div className="patient-care-feature-sidebar-wrapper">
            <div className="patient-care-feature-sidebar-close">
              <LinkIcon to="#close" size="medium" icon={<IconClose outline />} accessibilitySpan="Close" onClick={(e) => { e.preventDefault(); doClose(); }} />
            </div>
            <div className="patient-care-feature-sidebar-content" onScroll={this.scrollHandler}>
              {children}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default FeatureSidebar;
