import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { Button, LogoPatientAccessPro } from '@patient-access/ui-kit';
import Wrapper from '../Wrapper/Wrapper';
import './styles.scss';

import * as RoutesConstants from 'constants/RoutesConstants';

type State = {
  scrollTop: number,
};

class Header extends Component<State> {
  state = {
    scrollTop: 0,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.scrollHandler);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  };

  scrollHandler = () => {
    this.setState({ scrollTop: window.pageYOffset || document.documentElement.scrollTop });
  };

  renderHeaderClass() {
    const { scrollTop } = this.state;
    if (scrollTop !== 0) return 'header header-scroll';
    return 'header';
  };

  render() {
    return (
      <header className={this.renderHeaderClass()}>
        <Wrapper className="header-wrapper">
          <div className="header-logo">
            <Link to={RoutesConstants.HOME}>
              <LogoPatientAccessPro />
              <span><FormattedMessage id="landing.header.logo.accessibility" /></span>
            </Link>
          </div>
          <div className="header-links">
            <ScrollTo to="section-more" className="ui-kit-button ui-kit-button-secondary ui-kit-button-medium" offset={-80} duration={500} smooth="easeInOutCubic">
              <span><FormattedMessage id="landing.header.button.more" /></span>
            </ScrollTo>
            <Button
              to={RoutesConstants.LOGIN}
              buttonType="blueline"
              messageKey="landing.header.button.sign_in"
            />
          </div>
        </Wrapper>
      </header>
    );
  }
}

export default Header;
