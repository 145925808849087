import React, { Component, Fragment } from 'react';
import { Button } from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleResetPassword: () => any
}

export class ResetPassword extends Component<Props> {

  render() {
    const { handleResetPassword } = this.props;
    return (
      <Fragment>
        <hr className="patient-care-separator" />
        <div className="patient-care-description-block">
          <h3>
            {locale.UserDetails.resetPasswordTitle}
          </h3>
          <p>{locale.UserDetails.resetPasswordContent}</p>
        </div>
        <div className="patient-care-block">
          <Button
            buttonType="blueline"
            messageKey="reset-password"
            defaultMessage={locale.UserDetails.resetPasswordButton}
            onClick={handleResetPassword}
            data-id="reset-password"
          />
        </div>
      </Fragment>
    )
  }
}

export default ResetPassword;
