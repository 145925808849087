// UI
export const SET_ORG_SER_UI = "SET_ORG_SER_UI";

// USER
export const SET_ORG_SER_USER_SEARCH = "SET_ORG_SER_USER_SEARCH";
export const SET_ORG_SER_USER_STEP = "SET_ORG_SER_USER_STEP";
export const SET_ORG_SER_USER_DATA = "SET_ORG_SER_USER_DATA";
export const RESET_ORG_SER_USER_DATA = "RESET_ORG_SER_USER_DATA";
export const SET_SERVICE_DETAILS_PENDING = "SET_SERVICE_DETAILS_PENDING";
export const SET_SERVICE_DETAILS_SUCCESS = "SET_SERVICE_DETAILS_SUCCESS";
export const SET_SERVICE_DETAILS_ERROR = "SET_SERVICE_DETAILS_ERROR";

//USER EDIT DATA
export const SET_ORG_SER_USER_EDIT_DATA = "SET_ORG_SER_USER_EDIT_DATA";
export const RESET_ORG_SER_USER_EDIT_DATA = "RESET_ORG_SER_USER_EDIT_DATA";

// SERVICES_SUMMARY
export const GET_ORG_SER_SERVICES_SUMMARY_PENDING = "GET_ORG_SER_SERVICES_SUMMARY_PENDING";
export const GET_ORG_SER_SERVICES_SUMMARY_SUCCESS = "GET_ORG_SER_SERVICES_SUMMARY_SUCCESS";
export const GET_ORG_SER_SERVICES_SUMMARY_ERROR = "GET_ORG_SER_SERVICES_SUMMARY_ERROR";
export const SET_ORG_SER_SERVICES_SUMMARY_DATA = "SET_ORG_SER_SERVICES_SUMMARY_DATA";

// SERVICES_LIST
export const GET_ORG_SER_SERVICES_LIST_PENDING = "GET_ORG_SER_SERVICES_LIST_PENDING";
export const GET_ORG_SER_SERVICES_LIST_SUCCESS = "GET_ORG_SER_SERVICES_LIST_SUCCESS";
export const GET_ORG_SER_SERVICES_LIST_ERROR = "GET_ORG_SER_SERVICES_LIST_ERROR";
export const SET_ORG_SER_SERVICES_LIST_DATA = "SET_ORG_SER_SERVICES_LIST_DATA";

// BRANCHES_SUMMARY
export const GET_ORG_SER_BRANCHES_SUMMARY_PENDING = "GET_ORG_SER_BRANCHES_SUMMARY_PENDING";
export const GET_ORG_SER_BRANCHES_SUMMARY_SUCCESS = "GET_ORG_SER_BRANCHES_SUMMARY_SUCCESS";
export const GET_ORG_SER_BRANCHES_SUMMARY_ERROR = "GET_ORG_SER_BRANCHES_SUMMARY_ERROR";
export const SET_ORG_SER_BRANCHES_SUMMARY_DATA = "SET_ORG_SER_BRANCHES_SUMMARY_DATA";

// BRANCHES_LIST
export const GET_ORG_SER_BRANCHES_LIST_PENDING = "GET_ORG_SER_BRANCHES_LIST_PENDING";
export const GET_ORG_SER_BRANCHES_LIST_SUCCESS = "GET_ORG_SER_BRANCHES_LIST_SUCCESS";
export const GET_ORG_SER_BRANCHES_LIST_ERROR = "GET_ORG_SER_BRANCHES_LIST_ERROR";
export const SET_ORG_SER_BRANCHES_LIST_DATA = "SET_ORG_SER_BRANCHES_LIST_DATA";

// SERVICE_DESCRIPTION
export const GET_ORG_SER_SERVICE_DESCRIPTION_PENDING = "GET_ORG_SER_SERVICE_DESCRIPTION_PENDING";
export const GET_ORG_SER_SERVICE_DESCRIPTION_SUCCESS = "GET_ORG_SER_SERVICE_DESCRIPTION_SUCCESS";
export const GET_ORG_SER_SERVICE_DESCRIPTION_ERROR = "GET_ORG_SER_SERVICE_DESCRIPTION_ERROR";
export const SET_ORG_SER_SERVICE_DESCRIPTION_SERVICE_ID = "SET_ORG_SER_SERVICE_DESCRIPTION_SERVICE_ID";
export const SET_ORG_SER_SERVICE_DESCRIPTION_DATA = "SET_ORG_SER_SERVICE_DESCRIPTION_DATA";

// DELETE SERVICE
export const DELETE_ORG_SERVICES_PENDING = "DELETE_ORG_SERVICES_PENDING";
export const DELETE_ORG_SERVICES_SUCCESS = "DELETE_ORG_SERVICES_SUCCESS";
export const DELETE_ORG_SERVICES_ERROR = "DELETE_ORG_SERVICES_ERROR";

//UPDATE SERVICE
export const UPDATE_SERVICES_DETAILS_PENDING = "UPDATE_SERVICES_DETAILS_PENDING";
export const UPDATE_SERVICES_DETAILS_SUCCESS = "UPDATE_SERVICES_DETAILS_SUCCESS";
export const UPDATE_SERVICES_DETAILS_ERROR = "UPDATE_SERVICES_DETAILS_ERROR";

// OVERLAY_MODE
export const OVERLAY_MODE = {
    ADD: 'Add',
    EDIT: 'Edit',
    DELETE: 'Delete',
    ALL_ERROR: 'AllError',
    PARTIAL_ERROR: 'PartialError'
};

export const ALERT_MODE = {
  BRANCHES_NONE: 0,
  BRANCHES_PARTIAL: 1,
  BRANCHES_ALL: 2,
};
