import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { FormItem, Checkbox, Switch, InputError } from '@patient-access/ui-kit';
import { periodsList } from 'constants/OrganisationsConstants';
import { setTimeOffDataUpdate } from 'actions/availabilityRules';

import locale from 'service/locale';

type Props = {
  data: any,
  setTimeOffDataUpdate: (data: any) => Action,
  handleChangeMode: () => any,
  isEditMode: boolean,
};

type State = {
  isErrorTime: boolean,
};

const mapDispatchToProps = (dispatch: Function): any => ({
  setTimeOffDataUpdate: (data) => dispatch(setTimeOffDataUpdate(data)),
});

class TimeOffSection extends Component<Props, State> {
  state = {
    isErrorTime: false,
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { data } = nextProps;
    // validation
    if (data.isTimeOffEnabled && data.timeOff === 0) {
      this.setState({ isErrorTime: true });
    } else {
      this.setState({ isErrorTime: false });
    }
  }

  render() {
    const { data, setTimeOffDataUpdate, handleChangeMode, isEditMode } = this.props;
    const { isErrorTime } = this.state;

    const renderTimeOffValue = () => {
      if (isEditMode) {
        return (
          <Fragment>
            <div className="patient-care-availability-select">
              <Select
                id="timeOffSelect"
                name="timeOffSelect"
                placeholder={locale.AvailabilityRules.timeOffSelectPlaceholder}
                defaultValue={periodsList.find(option => option.value === data.timeOff)}
                options={periodsList}
                classNamePrefix="patient-care"
                onChange={(e) => { setTimeOffDataUpdate({ timeOff: e.value }); }}
                className={`patient-care-select ${isErrorTime ? 'patient-care-select-error' : ''}`}
                backspaceRemovesValue={false}
                isDisabled={!isEditMode}
              />
            </div>
            {isErrorTime ? <InputError message={locale.AvailabilityRules.timeOffSelectError} /> : null}
          </Fragment>
        );
      }
      return (
        <div className="patient-care-availability-select">
          <b>{data.timeOff} min</b>
        </div>
      );
    }

    const renderTimeOffOptions  = () => {
      if (data.isTimeOffEnabled) {
        return (
          <Fragment>
            <div className="patient-care-section-block-content">
              {renderTimeOffValue()}
            </div>
            <div className="patient-care-section-block-content">
              <FormItem type="item">
                <Checkbox
                  id="timeOffCheckbox"
                  name="timeOffCheckbox"
                  value="isBranchControlTimeOff"
                  label={locale.AvailabilityRules.timeOffCheckboxLabel}
                  onChange={(e) => { setTimeOffDataUpdate({ isBranchControlTimeOff: e.target.checked }); }}
                  disabled={!isEditMode}
                  checked={data.isBranchControlTimeOff}
                />
              </FormItem>
            </div>
          </Fragment>
        );
      }
      return null;
    }

    return (
      <div className="patient-care-section-block">
        <div className="patient-care-section-block-heading">
          <h3>{locale.AvailabilityRules.timeOffHeading}</h3>
          <span className={`patient-care-section-block-edit ${isErrorTime ? 'disabled' : ''}`} onClick={handleChangeMode}>
            {!isEditMode ? locale.Buttons.buttonEdit : locale.Buttons.buttonDone}
          </span>
        </div>
        <div className="patient-care-section-block-content">
          <p>{locale.AvailabilityRules.timeOffDescription}</p>
        </div>
        <div className="patient-care-section-block-content">
          <div className="patient-care-availability-switch">
            <h4>{locale.AvailabilityRules.timeOffSwitchLabel}</h4>
            <FormItem type="item">
              <Switch
                id="timeOffSwitch"
                name="timeOffSwitch"
                value="isTimeOffEnabled"
                accessibilitySpan={locale.AvailabilityRules.timeOffSwitchLabel}
                onChange={(e) => { setTimeOffDataUpdate({ isTimeOffEnabled: e.target.checked }); }}
                disabled={!isEditMode ? 'disabled' : null}
                checked={data.isTimeOffEnabled}
              />
            </FormItem>
          </div>
        </div>
        {renderTimeOffOptions()}
      </div>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(TimeOffSection);
