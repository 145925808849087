import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";

import {
  Form,
  FormItem,
  InputLabel,
  Password,
  Button,
  InputError,
  LogoPatientAccessPro,
  Icon,
  IconHelp
} from '@patient-access/ui-kit';

import type { Action } from "types/actions";
import { confirmNewPassword, checkForgotPasswordToken, setConfirmationStatus } from "actions/profile";
import { checkPassword } from "helpers/checkValues";
import locale from "service/locale";
import LoginFooterLinks from 'components/Share/LoginFooterLinks/LoginFooterLinks';
import MetaData from '../../Share/MetaData/MetaData';
import Recaptcha from "components/Share/Recaptcha/Recaptcha";

import * as RoutesConstants from 'constants/RoutesConstants';

type Props = {
  confirmNewPassword: (data: any, recaptchaToken: string) => Action,
  isTokenExpired: boolean,
  isPasswordContainsEmail: boolean,
  isAuth: boolean,
  type: "reset" | "complete",
  isPending: boolean,
  needRedirect: boolean,
  checkForgotPasswordToken: (data: any) => Action,
  setConfirmationStatus: (status: string) => Action,
};

type State = {
  new_password: string,
  confirm_password: string,
  isPasswordEmpty: boolean,
  isPasswordValidationError: boolean,
  isPasswordFocused: boolean,
  isPasswordsNotEqual: boolean,
};

const mapStateToProps = (state): any => ({
  isTokenExpired: state.profile.email === "InvalidToken",
  isPasswordContainsEmail: state.profile.email === "ContainsEmail",
  isAuth: state.login.isAuth,
  isPending: state.login.isPending,
  needRedirect: state.profile.needRedirect
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  confirmNewPassword: (data, recaptchaToken) => dispatch(confirmNewPassword(data, recaptchaToken)),
  checkForgotPasswordToken: (data) => dispatch(checkForgotPasswordToken(data)),
  setConfirmationStatus: status => dispatch(setConfirmationStatus(status))
});

const recaptcha = React.createRef();

class ResetForm extends Component<Props, State> {
  state = {
    new_password: "",
    confirm_password: "",
    isPasswordEmpty: false,
    isPasswordValidationError: false,
    isPasswordFocused: false,
    isPasswordsNotEqual: false,
  };

  componentDidMount = () => {
    const { checkForgotPasswordToken } = this.props;
    const parsedPathname = window.location.search.slice(1).split('&');
    const data = {
      userId: parsedPathname[0] && parsedPathname[0].slice(2),
      token: parsedPathname[1] && parsedPathname[1].slice(2),
    };
    checkForgotPasswordToken(data);
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.isPasswordContainsEmail) {
      this.setState({
        isPasswordValidationError: true,
      });
    }
  };

  onChange = (response) => {
    const { new_password, confirm_password } = this.state;
    const { confirmNewPassword, setConfirmationStatus } = this.props;
    const parsedPathname = window.location.search.slice(1).split('&');
    setConfirmationStatus("");
    confirmNewPassword({
      token: parsedPathname[1] && parsedPathname[1].slice(2),
      userId: parsedPathname[0] && parsedPathname[0].slice(2),
      newPassword: new_password,
      confirmNewPassword: confirm_password,
    }, response);
    recaptcha.current.reset();
  }

  handleReset = (e: any) => {
    e && e.preventDefault();
    const { new_password, confirm_password } = this.state;
    const isPasswordValidationError = !checkPassword(new_password);
    const isPasswordsNotEqual = new_password !== confirm_password;

    this.setState({
      isPasswordEmpty: (!new_password || new_password.length === 0),
    });

    if (isPasswordValidationError || isPasswordsNotEqual) {
      this.setState({
        isPasswordsNotEqual,
        isPasswordValidationError,
      });
      recaptcha.current.reset();
    } else {
      recaptcha.current.execute();
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value.trim() });
  };

  onBlur = (e: any) => {
    const { value } = e.target;
    const { new_password, confirm_password } = this.state;
    this.setState({
      isPasswordEmpty: (!new_password || new_password.length === 0),
    });
    this.setState({
      isPasswordValidationError: !checkPassword(value),
      isPasswordFocused: false,
      isPasswordsNotEqual: new_password !== confirm_password,
    });
  };

  onFocus = () => {
    this.setState({
      isPasswordFocused: true,
      isPasswordValidationError: false,
      isPasswordsNotEqual: false
    });
  };

  render() {
    const {
      new_password,
      confirm_password,
      isPasswordEmpty,
      isPasswordValidationError,
      isPasswordsNotEqual
    } = this.state;
    const { isTokenExpired, isAuth, type, isPending, needRedirect } = this.props;
    return needRedirect
      ? <Redirect to={RoutesConstants.LOGIN} />
      : isAuth
        ? <Redirect to={RoutesConstants.HOME} />
        : (
          <Fragment>
            <MetaData />
            <header className="patient-care-header">
              <div className="patient-care-header-container">
                <div className="patient-care-logo-full">
                  <a href={RoutesConstants.HOME}>
                    <LogoPatientAccessPro />
                  </a>
                </div>
                <a
                  href={locale.Login.linkSupportCentre}
                  className="patient-care-support-link"
                >
                  <Icon
                    type="inline"
                    size="medium"
                    icon={<IconHelp outline={true} />}
                  />
                  {locale.Login.linkNameSupportCentre}
                </a>
              </div>
            </header>
            <div className="patient-care-login-page">
              {
                type === "reset" &&
                  <b>
                    {locale.Login.backToSign[0]}&nbsp;
                    <Link to={RoutesConstants.LOGIN}>
                      {locale.Login.backToSign[1]}
                    </Link>
                  </b>
              }
              <div className="patient-care-login-holder">
                <div className="patient-care-login-wrap">
                  {
                    isTokenExpired
                      ? <div>
                          <p>{locale.Login.linkExpired}</p>
                        </div>
                      : (
                        <Fragment>
                        <div className="patient-care-login-heading">
                          <h1>{type === "reset" ? locale.Login.new : locale.Login.welcome}</h1>
                        </div>

                        <div className="patient-care-login-description">
                          <p>{type === "reset" ? locale.Login.updatePassword : locale.Login.complete}</p>
                        </div>

                        <iframe
                          title="hidden"
                          className="hidden-iframe"
                          name="hidden-iframe"
                          src="about:blank"
                        />
                        <Recaptcha recaptcha={recaptcha} recaptchaError={locale.Recaptcha.error.signIn} onChange={this.onChange} />
                        <Form
                          target="hidden-iframe"
                          action="about:blank"
                          autoComplete="on"
                          noValidate
                        >
                          <div className="patient-care-form-group">
                            <FormItem type="item">
                              <InputLabel
                                htmlFor="new_password"
                                message={locale.Login.password}
                                size="small"
                              />
                              <Password
                                id="new_password"
                                name="new_password"
                                onChange={this.handleChange}
                                onBlur={this.onBlur}
                                onFocus={this.onFocus}
                                value={new_password}
                              />
                            {isPasswordValidationError && (
                              <div className="patient-care-password-rules">
                                <p>{locale.Login.passwordError[0]}</p>
                                <p>&bull; {locale.Login.passwordError[1]}</p>
                                <p>&bull; {locale.Login.passwordError[2]}</p>
                                <p>&bull; {locale.Login.passwordError[3]}</p>
                                <p>&bull; {locale.Login.passwordError[4]}</p>
                                <p>&bull; {locale.Login.passwordError[5]}</p>
                                <p>&bull; {locale.Login.passwordError[6]}</p>
                              </div>
                            )}
                              {isPasswordEmpty &&
                                <InputError message={locale.Modals.changeYourPasswordOverlay.newPasswordEmpty} />
                              }
                            </FormItem>
                          </div>
                          <div className="patient-care-form-group">
                            <FormItem type="item">
                              <InputLabel
                                htmlFor="confirm_password"
                                message={locale.Login.confirmPassword}
                                size="small"
                              />
                              <Password
                                id="confirm_password"
                                name="confirm_password"
                                onChange={this.handleChange}
                                onBlur={this.onBlur}
                                onFocus={this.onFocus}
                                value={confirm_password}
                              />
                              {isPasswordsNotEqual && (
                                <div className="input-error">
                                  <p>{locale.Login.passwordNotEqual}</p>
                                </div>
                              )}
                            </FormItem>
                          </div>
                          <div className="patient-care-btn-group">
                            <Button
                              buttonType="secondary"
                              messageKey="signin-btn"
                              defaultMessage={locale.Login.signIn}
                              onClick={this.handleReset}
                              isLoading={isPending}
                              data-id="signin-btn"
                              buttonLength="full"
                            />
                          </div>
                        </Form>

                        <div className="patient-care-login-footer">
                          <p>
                            {locale.Login.termsAndUseLink[0]}
                            <b><a href={locale.Login.termsLink}>{locale.Login.termsAndUseLink[1]}</a></b>
                            {locale.Login.termsAndUseLink[2]}
                            <b><a href={locale.Login.policyLink}>{locale.Login.termsAndUseLink[3]}</a></b>.
                          </p>
                        </div>
                      </Fragment>
                    )
                  }
                </div>
                <LoginFooterLinks />
              </div>
            </div>
          </Fragment>
        );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);
