import React, { Component } from 'react'
import { Overlay, Button } from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleStay: () => any,
  isActiveAddBranchError: boolean,
  isActiveAddBranchOdsError: boolean
}

export default class DuplicateBranchModal extends Component<Props> {
  handleStay = (e: Event) => {
    e && e.preventDefault();
    const { handleStay } = this.props;
    handleStay();
  };

  render() {
    const { isActiveAddBranchError , isActiveAddBranchOdsError } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          {isActiveAddBranchError && <h2>{locale.AddBranch.duplicateErrorTitle}</h2>}
          {isActiveAddBranchOdsError && <h2>{locale.AddBranch.duplicateOdsErrorTitle}</h2>}
        </div>
        <div className="overlay-confirmation-content">
          {isActiveAddBranchError && <h4>{locale.AddBranch.duplicateErrorMessageLine1}</h4>}
          {isActiveAddBranchOdsError && <h4>{locale.AddBranch.duplicateOdsErrorMessage}</h4>}
          <h4>
            {isActiveAddBranchError && locale.AddBranch.duplicateErrorMessageLine2}
            {/* eslint-disable-next-line */}
            &nbsp;
            <b>
              <a href="https://supportpro.patientaccess.com/">{locale.AddBranch.duplicateErrorMessageLink}</a>
            </b>
          </h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="secondary"
            messageKey="stay-btn"
            defaultMessage={locale.AddBranch.duplicateErrorButton}
            onClick={this.handleStay}
            data-id="stay-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    )
  }
}
