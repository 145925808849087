import * as AgendaConstants from 'constants/AgendaConstants';
import type { Action } from 'types/actions';

type AgendaState = any[];

const initialState = [];

export default (localState: AgendaState = initialState, action: Action): AgendaState => {
  switch (action.type) {
    case AgendaConstants.SET_AGENDA_APPOINTMENTS:
      return action.payload || [];
    default:
      return localState;
  }
}