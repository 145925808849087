import React, { Component } from "react";
import { connect } from 'react-redux';

import locale from 'service/locale';
import './styles.scss';

type Props = {
  selectedRole: any;
};

const mapStateToProps = (state) => ({

});

class RoleDescription extends Component<Props> {

  findRole = (role: any) => {
    const { selectedRole } = this.props;
    if (selectedRole.name === role.name) {
      return role;
    }

    return false;
  };

  render() {
    const role = locale.AddUser.roles.find(this.findRole) || { actions: [] };
    return (
      <div className="patient-care-block">
        <p>{locale.AddUser.roleDescriptionTitle}</p>
        <ul className="patient-care-two-column-list">
          {role.actions.map(action => (
            <li className="patient-care-two-column-item" key={action.title}>{action.title}</li>
          ))}
        </ul>
        <p>{role.description}</p>
      </div>
    )
  }
}

export default connect(mapStateToProps)(RoleDescription);
