import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Pagination, Button } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentScroll, FeatureContentHeader, FeaturePagination } from 'components/Share/feature';
import { SkeletonTable } from 'components/Share/skeleton';
import { columns, TableCalendars } from './TableCalendars/TableCalendars';
import BranchSidebar from '../BranchSidebar/BranchSidebar';
import FilterCalendars from './FilterCalendars/FilterCalendars';
import { getCalendarsList, getBranchCalendarsServices, setCalendarDetailsOverlayData, setFiltersBranchesValue } from 'actions/calendarsList';
import { getBranchDetails } from 'actions/branchDetails';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as SearchConstants from 'constants/SearchConstants';
import * as RolesConstants from 'constants/RolesConstants';
import locale from 'service/locale';

// CALENDAR_ADD & CALENDAR_DETAILS
import AddNewCalendarModal from '../BranchCalendars/AddNewCalendarModal/AddNewCalendarModal';
import CalendarDetailsModal from '../BranchCalendars/CalendarDetailsModal/CalendarDetailsModal';
import { openModalOverlay, closeModalOverlay, openCalendarDetails, closeCalendarDetails } from 'actions/panel';
import { clearForm } from 'actions/form';

type Props = {
  organisationId: string,
  branchId: string,
  data: any,
  pages: number,
  searchValue: string,
  branchIds: any,
  serviceIds: any,
  calendarDetails: any,
  currentRole: any,
  getCalendarsList: (searchValue: string, branchIds: any, serviceIds: any, pageNumber: number, organisationId: string) => Action,
  getBranchCalendarsServices: (organisationId: string, branchId: string) => Action,
  setCalendarDetailsOverlayData: (value: string) => Action,
  getBranchDetails: (organisationId: string, branchId: string) => any,
  setFiltersBranchesValue: any,

  // CALENDAR_ADD & CALENDAR_DETAILS
  isModalOverlayOpened: boolean,
  isCalendarOpened: boolean,
  openModalOverlay: () => Action,
  closeModalOverlay: () => Action,
  openCalendarDetails: () => Action,
  closeCalendarDetails: () => Action,
  clearForm: () => Action,
};

const mapStateToProps = (state) => ({
  data: state.calendarsList.calendars.data,
  pages: state.calendarsList.calendars.pages,
  searchValue: state.calendarsList.filters.searchValue,
  branchIds: state.calendarsList.filters.branchIds,
  serviceIds: state.calendarsList.filters.serviceIds,
  calendarDetails: state.calendarsList.calendarDetails,
  currentRole: state.roles.profileCurrentRole,
  isLoading: state.calendarsList.calendars.isLoading,
  isError: state.calendarsList.calendars.isError,

  // CALENDAR_ADD & CALENDAR_DETAILS
  isModalOverlayOpened: state.panel.isModalOverlayOpened,
  isCalendarOpened: state.panel.isCalendarOpened,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getCalendarsList: (searchValue: string, branchIds: any, serviceIds: any, pageNumber: number, organisationId: string) => dispatch(getCalendarsList(searchValue, branchIds, serviceIds, pageNumber, organisationId)),
  getBranchCalendarsServices: (organisationId: string, branchId: string) => dispatch(getBranchCalendarsServices(organisationId, branchId)),
  setCalendarDetailsOverlayData: (value: string) => dispatch(setCalendarDetailsOverlayData(value)),
  getBranchDetails: (organisationId: string, branchId: string ) => dispatch(getBranchDetails(organisationId, branchId)),
  setFiltersBranchesValue: (branchIds: string) => dispatch(setFiltersBranchesValue(branchIds)),

  // CALENDAR_ADD
  openModalOverlay: () => dispatch(openModalOverlay()),
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  openCalendarDetails: () => dispatch(openCalendarDetails()),
  closeCalendarDetails: () => dispatch(closeCalendarDetails()),
  clearForm: () => dispatch(clearForm()),
});

class BranchCalendars extends Component<Props> {
  componentDidMount = () => {
    const { organisationId, branchId, getCalendarsList, getBranchCalendarsServices, setFiltersBranchesValue } = this.props;
    setFiltersBranchesValue([branchId]);
    getCalendarsList('', [branchId], [], 1, organisationId);
    getBranchCalendarsServices(organisationId, branchId);
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { organisationId, searchValue, branchIds, serviceIds, getCalendarsList } = this.props;

    if (searchValue !== nextProps.searchValue) {
      if (nextProps.searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
        getCalendarsList(nextProps.searchValue, branchIds, serviceIds, 1, organisationId);
      } else {
        if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
          getCalendarsList('', branchIds, serviceIds, 1, organisationId);
        }
      }
    }
    if (serviceIds !== nextProps.serviceIds && JSON.stringify(serviceIds) !== JSON.stringify(nextProps.serviceIds)) {
      getCalendarsList(searchValue, branchIds, nextProps.serviceIds, 1, organisationId);
    }
  }

  render() {
    const { organisationId, branchId, data, pages, searchValue, branchIds, serviceIds, calendarDetails, currentRole, isLoading, isError, getCalendarsList, isModalOverlayOpened, isCalendarOpened, openModalOverlay, closeModalOverlay, closeCalendarDetails, clearForm } = this.props;
    const isAbleToCreateCalendar = currentRole.role !== RolesConstants.BRANCH_MEMBER;
    const isEmptyData = data.length === 0;
    const isEmptySearch = searchValue.length === 0;
    const isEmpty = isEmptyData && isEmptySearch && !isLoading;

    // CALENDAR_ADD
    const calendarList = data.length ? data.map(calendar => calendar.name) : [];

    // CALENDAR_ADD
    const handleCloseAddCalendarModal = () => {
      closeModalOverlay();
      clearForm();
    };

    const handleCloseCalendar = () => {
      closeCalendarDetails();
      clearForm();
    };

    const handleDiscardChanges = () => {
      closeCalendarDetails();
      closeModalOverlay();
      clearForm();
    };

    const handlePagination = (e: any) => {
      const pageNumber = e.selected + 1;
      getCalendarsList(searchValue, branchIds, serviceIds, pageNumber, organisationId);
    }

    const renderPagination = () => {
      if (pages > 1) {
        return (
          <FeaturePagination hidden={isLoading || isError || isEmptyData}>
            <Pagination pageCount={pages} onPageChange={handlePagination} />
          </FeaturePagination>
        );
      }
      return null;
    }

    const renderTableCalendars = () => {
      if (isLoading) {
        return <SkeletonTable className="spacing-medium" columns={6} isSticky={true} isGrey={true} />;
      }
      if (isEmptyData) {
        return (
          <NoResults type="info">
            <h2>{locale.BranchCalendarsRedesign.noResults.noCalendarsFilter}</h2>
          </NoResults>
        );
      }
      return <TableCalendars data={data} columns={columns} props={this.props} />;
    }

    const renderButtonAddCalendar = () => {
      if (isAbleToCreateCalendar) return <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchCalendarsRedesign.button.addCalendar} onClick={() => { openModalOverlay(); }} />;
      return null;
    }

    const renderContent = () => {
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.BranchCalendarsRedesign.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.CALENDARS}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.BranchCalendarsRedesign.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmpty) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.BranchCalendarsRedesign.noResults.noCalendars}</h2>
                {renderButtonAddCalendar()}
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <FilterCalendars organisationId={organisationId} branchId={branchId} isLoading={isLoading} />
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableCalendars()}
            {renderPagination()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    // CALENDAR_ADD
    const renderCalendarAdd = () => {
      if (isModalOverlayOpened) {
        return (
          <AddNewCalendarModal
            organisationId={organisationId}
            branchId={branchId}
            existingCalendarNamesList={calendarList}
            handleCloseModal={handleCloseAddCalendarModal}
            handleDiscardChanges={handleDiscardChanges}
          />
        );
      }
      return null;
    }

    // CALENDAR_DETAILS
    const renderCalendarDetails = () => {
      if (isCalendarOpened) {
        return (
          <CalendarDetailsModal
            organisationId={organisationId}
            branchId={branchId}
            calendarId={calendarDetails.calendarId}
            existingCalendarNamesList={calendarList}
            handleCloseModal={handleCloseCalendar}
            handleDiscardChanges={handleDiscardChanges}
          />
        );
      }
      return null;
    }

    return (
      <Feature>
        <FeatureMenu>
          <BranchSidebar organisationId={organisationId} branchId={branchId} />
        </FeatureMenu>
        <FeatureContent>
          {renderContent()}
          {renderCalendarAdd()}
          {renderCalendarDetails()}
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchCalendars);
