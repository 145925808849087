import React, { PureComponent, Fragment } from 'react';
import { FeatureContentBreadcrumbs } from 'components/Share/feature';
import './styles.scss';

type Props = {
  isSuperAdmin: boolean,
  isOrgAdmin: boolean,
  isOrgUsersList: boolean,
  isBranchUsersList: boolean,
  organisationId: string,
  branchId?: string,
};

export default class UsersBreadcrumbs extends PureComponent<Props> {

  render() {
    const { isSuperAdmin, isOrgAdmin, isOrgUsersList, isBranchUsersList, organisationId, branchId } = this.props;
    if (isSuperAdmin) {
      if (isOrgUsersList) {
        return (
          <Fragment>
            <div className='users-org-brn-with-breadcrumbs'>
              <FeatureContentBreadcrumbs organisationId={organisationId}></FeatureContentBreadcrumbs>
            </div>
          </Fragment>
        );
      } else if (isBranchUsersList && branchId) {
        return (
          <Fragment>
            <div className='users-org-brn-with-breadcrumbs'>
              <FeatureContentBreadcrumbs organisationId={organisationId} branchId={branchId}></FeatureContentBreadcrumbs>
            </div>
          </Fragment>
        );
      }

      return null;
    } else if (isOrgAdmin) {
      if (isBranchUsersList && branchId) {
        return (
          <Fragment>
            <div className='users-org-brn-with-breadcrumbs'>
              <FeatureContentBreadcrumbs organisationId={organisationId} branchId={branchId}></FeatureContentBreadcrumbs>
            </div>
          </Fragment>
        );
      }

      return null;
    }

    return null;
  }
}
