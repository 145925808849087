import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Form, FormItem, Input, InputError, Checkbox, Radio, Tooltip, LogoNHS } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarBodySection, SidebarBodyHeader, SidebarBodyContent, SidebarFooter } from 'components/Share/sidebar';
import { setUI, setUserData, setUserStep, setUserEditData, resetUserEditData, getBranchesSummary } from 'actions/organisationServices';
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import { ButtonDelete } from 'components/Share/button';
import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';
import locale from 'service/locale';
import { periodsList, lastMinuteOptions } from 'constants/BranchesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import {
  checkURL
} from "helpers/checkValues";

type Props = {
  organisationId: string,
  serviceId: string,
  userData: any,
  userEditData: any,
  isAffiliate: boolean,
  dataBranchesSummary: any,
  dataBranchesList: any,
  isSuperAdmin: boolean,
  isEditByBranch: boolean,
  setUserData: (data: any) => any,
  setUserEditData: (data: any) => any,
  setUserStep: (value: any) => any,
  setUI: (value: any) => any,
  resetUserEditData: () => any,
  getBranchesSummary: (organisationId: string, serviceId: string) => any,
};

type State = {
  modesArray: any,
  cutOff: number,
  duration: number,
  isErrorPrice: boolean,
  isErrorSinglePrice: boolean,
  isErrorDuration: boolean,
  isErrorCutOff: boolean,
  isErrorPaused: boolean,
  isErrorModes: boolean,
  isErrorAffiliatePriceOption: boolean,
  isErrorNegativePrice: boolean,
  isGenericError: boolean,
  isErrorBookingUrl: boolean,
  isEditServiceMode: boolean,
  isEditStatus: boolean,
  isEditServicePrice: boolean,
  isEditVatIncluded: boolean,
  isEditDuration: boolean,
  isEditCutoff: boolean,
  isEditShowPrice: boolean,
  isEditBookingUrl: boolean,
};

const mapStateToProps = (state) => ({
  userData: state.organisationServices.user.data,
  userEditData: state.organisationServices.user.editData,
  dataBranchesSummary: state.organisationServices.branchesSummary.data,
  dataBranchesList: state.organisationDetails.branches,
  isSuperAdmin: [RolesConstants.ADMIN].includes(state.roles.profileCurrentRole.role),
  isEditByBranch: state.organisationServices.ui.isEditByBranch,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserEditData: (data: any) => dispatch(setUserEditData(data)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserEditData: () => dispatch(resetUserEditData()),
  getBranchesSummary: (organisationId: string, serviceId: string) => dispatch(getBranchesSummary(organisationId, serviceId)),
});

class StepOne extends Component<State, Props> {
  state = {
    modesArray: [],
    cutOff: null,
    duration: null,
    bookingUrl: null,
    isErrorPrice: false,
    isErrorSinglePrice: false,
    isErrorDuration: false,
    isErrorCutOff: false,
    isErrorPaused: false,
    isErrorModes: false,
    isErrorAffiliatePriceOption: false,
    isErrorNegativePrice: false,
    isErrorBookingUrl: false,
    isGenericError: false,
    isEditServiceMode: this.props.userEditData.serviceModes.isEdited ? true : false,
    isEditStatus: this.props.userEditData.serviceIsActive.isEdited ? true : false,
    isEditServicePrice: this.props.userEditData.servicePrice.isEdited ? true : false,
    isEditVatIncluded: this.props.userEditData.serviceIsVatIncluded.isEdited ? true : false,
    isEditDuration: this.props.userEditData.serviceDuration.isEdited ? true: false,
    isEditCutoff: this.props.userEditData.serviceCutOff.isEdited ? true : false,
    isEditShowPrice: this.props.userEditData.serviceIsShowPrice.isEdited ? true : false,
    isEditBookingUrl: this.props.userEditData.bookingUrl.isEdited ? true : false,
  }

  componentDidMount = () => {
    const { userData, userEditData } = this.props;
    const { isEditDuration, isEditCutoff } = this.state;
    const isPeriodExists = periodsList.find(option => option.value === userData.serviceDuration);
    const isCutOffExists = lastMinuteOptions.find(option => option.value === userData.serviceCutOff);
    if (isEditDuration) {
      this.setState({ duration: periodsList.find(option => option.value === userEditData.serviceDuration.value)});
    } else if (userData.serviceDuration && isPeriodExists) {
      this.setState({ duration: isPeriodExists});
    } else if (userData.serviceDuration && !isPeriodExists) {
      this.setState({ duration: { label: userData.serviceDuration + locale.OrganisationServices.section.appointmentDuration.labelMinutes, value: userData.serviceDuration }});
    } else {
      this.setState({ duration: null});
    }

    if (isEditCutoff) {
      this.setState({ cutOff: lastMinuteOptions.find(option => option.value === userEditData.serviceCutOff.value)});
    } else if (userData.serviceCutOff !== null && isCutOffExists) {
      this.setState({ cutOff: isCutOffExists});
    } else if (userData.serviceCutOff !== null && !isCutOffExists) {
      this.setState({ cutOff: { label: userData.serviceCutOff + locale.OrganisationServices.section.bookingCutOff.labelHours, value: userData.serviceDuration }});
    } else {
      this.setState({ cutOff: null});
    }
  }

  handleStatusChange = (value: any) => {
    const { setUserEditData } = this.props;
    setUserEditData({ serviceIsActive: {isEdited:true, value: value }});
    this.setState({ isErrorPaused: false, isEditStatus: true, isGenericError: false });
  };

  handlePrice = (e: Event) => {
    const { setUserEditData, isAffiliate } = this.props;
    var t = e.target.value;
    e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    if (e.target.value !== '' && !isNaN(e.target.value) && (Number(e.target.value) >= 0)) {
      this.setState({ isErrorPrice: false, isErrorSinglePrice: false, isErrorNegativePrice: false });
    } else if (e.target.value !== '' && !isNaN(e.target.value) && (Number(e.target.value) < 0)) {
      this.setState({ isErrorNegativePrice: true, isErrorPrice: false, isErrorSinglePrice: false, });
    } else if (e.target.value !== '' && isNaN(e.target.value)) {
      this.setState({ isErrorPrice: false, isErrorSinglePrice: true, isErrorNegativePrice: false });
    } else {
      this.setState({ isErrorPrice: true, isErrorSinglePrice: false, isErrorNegativePrice: false });
    }
    setUserEditData({ servicePrice: {isEdited:true, value: e.target.value }});
    if (isAffiliate) {
      setUserEditData({ serviceIsShowPrice: {isEdited:true, value: true }});
    }
    this.setState({isEditServicePrice: true, isGenericError: false});
  }

  handleDuration = (e: Event) => {
    const { setUserEditData } = this.props;
    if (e.value) {
      this.setState({ duration: periodsList.find(option => option.value === e.value), isErrorDuration: false, isEditDuration: true, isGenericError: false });
      setUserEditData({ serviceDuration: {isEdited:true, value: e.value }});
    } else {
      this.setState({ isErrorDuration: true, isGenericError: false, isEditDuration: true, duration: null });
      setUserEditData({ serviceDuration: {isEdited:true, value: null }});
    }
  }

  handleVATChange = (e: Event) => {
    const { setUserEditData } = this.props;
    if (e.target.checked !== '') {
      this.setState({ isEditVatIncluded: true, isGenericError: false });
      setUserEditData({ serviceIsVatIncluded: { isEdited: true, value: e.target.checked } });
    }
  };

  handleCutoff = (e: Event) => {
    const { setUserEditData } = this.props;
    if (e.value || e.value === 0) {
      this.setState({ cutOff: lastMinuteOptions.find(option => option.value === e.value), isErrorCutOff: false, isEditCutoff: true, isGenericError: false });
      setUserEditData({ serviceCutOff: {isEdited:true, value: e.value }});
    } else {
      this.setState({ isErrorCutOff: true, isGenericError: false, isEditCutoff: true, cutOff: null });
      setUserEditData({ serviceCutOff: {isEdited:true, value: null }});
    }
  }

  handleUrlChange = (e: any) => {
    const { setUserEditData } = this.props;
    
    if(e.target.value)
    {
      setUserEditData({ bookingUrl: {isEdited:true, value: e.target.value }});
      if(checkURL(e.target.value))
      {
        this.setState({ isErrorBookingUrl: false, isGenericError: false, isEditBookingUrl: true });
      }
      else{
        this.setState({ isErrorBookingUrl: true, isGenericError: false, isEditBookingUrl: true });
      }
    }
    else{
      setUserEditData({ bookingUrl: {isEdited:true, value: null }});
      this.setState({ isErrorBookingUrl: false, isGenericError: false, isEditBookingUrl: true });
    }
  }

  handleModes = (e: any) => {
    const { setUserEditData } = this.props;
    const target = e.target;
    var value = parseInt(target.value);
    let { modesArray } = this.state;
    if (target.checked) {
      modesArray.push(value);
      this.setState({ modesArray });
    } else {
      var index = modesArray.indexOf(value)
      modesArray.splice(index, 1);
    }
    modesArray = [...new Set(modesArray)];
    setUserEditData({ serviceModes: {isEdited:true, value: modesArray }});
    this.setState({isEditServiceMode: true, isGenericError: false});
    if (modesArray.length === 0) {
      this.setState({ isErrorModes: true });
    } else {
      this.setState({ isErrorModes: false });
    }
  }

  handleAffiliatesShowPriceChange = (value: any) => {
    const { setUserEditData, userData } = this.props;
    const isNhsService = userData.serviceIsNhsCovered;
    setUserEditData({ serviceIsShowPrice: {isEdited:true, value: value }});
    if (!value) {
      setUserEditData({ servicePrice: {isEdited:false, value: null }});
      this.setState({ isEditServicePrice: false, isErrorPrice: false, });
    }
    if (value && !isNhsService && userData.servicePrice === null) {
      setUserEditData({ servicePrice: {isEdited:true, value: null }});
      this.setState({ isEditServicePrice: true, isErrorPrice: true });
    }
    if (value && isNhsService) {
      setUserEditData({ servicePrice: {isEdited:true, value: "0" }});
      this.setState({ isEditServicePrice: true, isErrorPrice: false });
    }
    this.setState({ isErrorAffiliatePriceOption: false, isEditShowPrice: true, isGenericError: false });
  };

  resetServiceData = () => {
    const { setUserData } = this.props;
    setUserData({
      serviceModes: [],
      serviceIsActive: null,
      servicePrice: null,
      serviceDuration: null,
      serviceCutOff: null,
      serviceIsVatIncluded: null,
      serviceIsShowPrice: null,
      serviceBranches: [],
      bookingUrl: {
        isEdited: false,
        value: null
      }
    });
  };

  handleUndoChangesCutoff = () => {
    const { userData, setUserEditData } = this.props;
    const isCutOffExists = lastMinuteOptions.find(option => option.value === userData.serviceCutOff);
    if (userData.serviceCutOff !== null && isCutOffExists) {
      this.setState({ cutOff: isCutOffExists, isEditCutoff: false, isErrorCutOff: false});
    } else if (userData.serviceCutOff !== null && !isCutOffExists) {
      this.setState({ cutOff: { label: userData.serviceCutOff + locale.OrganisationServices.section.bookingCutOff.labelHours, value: userData.serviceDuration }, isEditCutoff: false, isErrorCutOff: false});
    } else {
      this.setState({ cutOff: null, isEditCutoff: false, isErrorCutOff: false});
    }
    setUserEditData({ serviceCutOff: {isEdited:false, value: null }});
  }

  handleUndoChangesPrice = () => {
    const { setUserEditData, isAffiliate, userEditData } = this.props;
    this.setState({isEditServicePrice: false, isErrorPrice: false, isErrorSinglePrice: false, isErrorNegativePrice: false});
    setUserEditData({ servicePrice: {isEdited:false, value: null }});
    if(userEditData.serviceIsVatIncluded.isEdited) {
      this.setState({isEditVatIncluded: false});
      setUserEditData({ serviceIsVatIncluded: {isEdited:false, value: null }});
    }
    if (isAffiliate) {
      this.setState({isEditShowPrice: false, isErrorAffiliatePriceOption: false});
      setUserEditData({ serviceIsShowPrice: {isEdited:false, value: null }});
    }
  }

  handleUndoChangesDuration = () => {
    const { userData, setUserEditData } = this.props;
    const isPeriodExists = periodsList.find(option => option.value === userData.serviceDuration);
    if (userData.serviceDuration && isPeriodExists) {
      this.setState({ duration: isPeriodExists, isEditDuration: false, isErrorDuration: false});
    } else if (userData.serviceDuration && !isPeriodExists) {
      this.setState({ duration: { label: userData.serviceDuration + locale.OrganisationServices.section.appointmentDuration.labelMinutes, value: userData.serviceDuration }, isEditDuration: false, isErrorDuration: false});
    } else {
      this.setState({ duration: null, isEditDuration: false, isErrorDuration: false});
    }
    setUserEditData({ serviceDuration: {isEdited:false, value: null }});
  }

  handleUndoChangesModes = () => {
    const { setUserEditData } = this.props;
    this.setState({isEditServiceMode: false, modesArray: [], isErrorModes: false });
    setUserEditData({ serviceModes: {isEdited:false, value: [] }});
  }

  handleUndoChangesStatus = () => {
    const { setUserEditData } = this.props;
    this.setState({isEditStatus: false, isErrorPaused: false});
    setUserEditData({ serviceIsActive: {isEdited:false, value: null }});
  }

  handleUndoChangesBookingUrl = () => {
    const { setUserEditData } = this.props;
    this.setState({isEditBookingUrl: false, isErrorBookingUrl: false});
    setUserEditData({ bookingUrl: {isEdited:false, value: null }});
  }

  handleOpenDiscardDialog = () => {
    const { setUserStep, setUI, isEditByBranch } = this.props;
    let editedData = this.props.userEditData;
    let dataKeys = Object.keys(editedData);

    const filterEditedData = (d) => {
      const edited = editedData[d];
      if (edited.hasOwnProperty('isEdited') && edited.isEdited === true) {
        return true;
      }
      return false;
    };

    const editedDataKeys = dataKeys.filter((d) => filterEditedData(d));

    if (!editedDataKeys || editedDataKeys.length > 0) {
      this.setState({ isDiscardModalOpen: true });
    }
    else {
      if (isEditByBranch) {
        this.handleDiscardChanges();
      } else {
        this.setState(
          {
            isDiscardModalOpen: false
          },
          () => {
            setUserStep(1);
            setUI({ isSidebarEditVisible: false });
          }
        );
      }
    }
  };

  handleDiscardChanges = () => {
    const { setUI, resetUserEditData, getBranchesSummary, organisationId, serviceId } = this.props;
    this.setState(
      {
        isDiscardModalOpen: false
      },
      () => {
        this.resetServiceData();
        resetUserEditData();
        setUI({ isSidebarEditVisible: false, isEditByBranch: false });
        getBranchesSummary(organisationId, serviceId);
      }
    );
  };
  
  handleStay = () => {
    this.setState({
      isDiscardModalOpen: false
    });
  };

  renderDuration = () => {
    const { isErrorDuration, duration } = this.state;
    return (
      <Select
        id="appointmentDuration"
        name="appointmentDuration"
        placeholder={locale.OrganisationServices.section.appointmentDuration.placeholder}
        options={periodsList}
        classNamePrefix="patient-care"
        onChange={this.handleDuration}
        value={duration}
        className={`patient-care-select select-number ${isErrorDuration ? 'patient-care-select-error' : ''}`}
      />
    );
  }

  renderBookingCutOff = () => {
    const { isErrorCutOff, cutOff } = this.state;
    return (
      <Select
        id="bookingCutOff"
        name="bookingCutOff"
        placeholder={locale.OrganisationServices.section.bookingCutOff.placeholder}
        options={lastMinuteOptions}
        classNamePrefix="patient-care"
        onChange={this.handleCutoff}
        value={cutOff}
        className={`patient-care-select select-number ${isErrorCutOff ? 'patient-care-select-error' : ''}`}
      />
    );
  }

  handleRemoveService = (e) => {
    const { setUI } = this.props;
    e.preventDefault(); 
    setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.DELETE });
  }

  renderBookingUrl = () => {
    const { isErrorBookingUrl, isEditBookingUrl } = this.state;
    const { userData, userEditData } = this.props;
    return (
        <FormItem
          type="item"
          error={isErrorBookingUrl ? "error" : null}>
          <Input
            type="text"
            id="url"
            name="url"
            data-id="url"
            placeholder={locale.OrganisationsSettings.bookingUrl.placeHolder1}
            onChange={this.handleUrlChange}
            value = {isEditBookingUrl ? userEditData.bookingUrl.value : (userData.bookingUrl.value || '')}
          />
          {isErrorBookingUrl && <InputError message={locale.OrganisationsSettings.bookingUrl.invalidError1} />}
        </FormItem>
    );
  }

  render() {
    const {
      isErrorModes,
      isErrorPrice,
      isErrorDuration,
      isErrorCutOff,
      isErrorPaused,
      isErrorAffiliatePriceOption,
      isGenericError,
      isEditServiceMode,
      isEditStatus,
      isEditServicePrice,
      isEditDuration,
      isEditCutoff,
      isEditShowPrice,
      isEditVatIncluded,
      isErrorSinglePrice,
      isErrorNegativePrice,
      isDiscardModalOpen,
      isEditBookingUrl
    } = this.state;
    const { userData,isAffiliate, setUserStep, userEditData, dataBranchesSummary, dataBranchesList, isSuperAdmin, isEditByBranch } = this.props;
    const isNhsService = userData.serviceIsNhsCovered;

    var userEditDataDuration = userEditData.serviceDuration.value;
    var userEditDataPrice = userEditData.servicePrice.value;
    var userEditDataVatIncluded = userEditData.serviceIsVatIncluded.value;
    var userEditDataCutoff = userEditData.serviceCutOff.value;
    var userEditDataStatus = userEditData.serviceIsActive.value;
    var userEditDataServiceModes = userEditData.serviceModes.value;
    var userEditDataShowPrice = userEditData.serviceIsShowPrice.value;
    var userEditDataBookingUrl = userEditData.bookingUrl.value;

    const isFormValid = () => {
      const { isAffiliate } = this.props;
      if (!isAffiliate) {
        return userEditData &&
          (isEditServiceMode || isEditStatus || isEditServicePrice || isEditVatIncluded || isEditDuration || isEditCutoff) &&
          (isEditServiceMode ? userEditDataServiceModes.length !== 0 : true) &&
          (isEditStatus ? userEditDataStatus != null : true) &&
          (isEditServicePrice ? (userEditDataPrice && !isNaN(userEditDataPrice) && (Number(userEditDataPrice) >= 0)) : true) &&
          (isEditDuration ? (userEditDataDuration) : true) &&
          (isEditCutoff ? userEditDataCutoff != null : true)
      } else {
        if (userEditData.serviceIsShowPrice.isEdited && userEditDataShowPrice) {
          return userEditData &&
            (isEditServiceMode || isEditStatus || (isEditShowPrice || isEditServicePrice || isEditVatIncluded) || isEditDuration || isEditBookingUrl) &&
            (isEditServiceMode ? userEditDataServiceModes.length !== 0 : true) &&
            (isEditStatus ? userEditDataStatus != null : true) &&
            (isEditServicePrice ? (userEditDataPrice && !isNaN(userEditDataPrice) && (Number(userEditDataPrice) >= 0)) : true) &&
            (isEditDuration ? (userEditDataDuration) : true) &&
            (isEditBookingUrl? (userEditDataBookingUrl === null || checkURL(userEditDataBookingUrl)) : true)
        } else {
          return userEditData &&
            (isEditServiceMode || isEditStatus || isEditDuration || isEditShowPrice || isEditVatIncluded || isEditBookingUrl) &&
            (isEditServiceMode ? userEditDataServiceModes.length !== 0 : true) &&
            (isEditStatus ? userEditDataStatus != null : true) &&
            (isEditShowPrice ? userEditDataShowPrice != null : true) &&
            (isEditDuration ? (userEditDataDuration) : true) && 
            (isEditBookingUrl? (userEditDataBookingUrl === null || checkURL(userEditDataBookingUrl)) : true)
        }
      }
    }

    const handleContinueStep = () => {
      const { isEditByBranch, setUI } = this.props;
      if (isEditByBranch) {
        setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.EDIT });
      } else {
        setUserStep(2);
      }
    }

    const validateForm = () => {
      const { isAffiliate } = this.props;
      if (!isAffiliate) {
        if (!(isEditServiceMode || isEditStatus || isEditServicePrice || isEditDuration || isEditCutoff)) {
          this.setState({ isGenericError: true });
        }
      } else {
        if (!(isEditServiceMode || isEditStatus || (isEditShowPrice || isEditServicePrice) || isEditDuration || isEditBookingUrl)) {
          this.setState({ isGenericError: true });
        } else if (userEditDataShowPrice) {
          this.setState({ isErrorPrice: true });
        }
      }
    }

    const hasAppointmentModes = (value) => {
      const branchData = dataBranchesSummary.filter(d => userData.serviceBranches.includes(d.branchId));
      const hasMode = branchData.filter(b => b.modes.includes(value)).length;
      return hasMode > 0;
    }
  
    const renderF2FOption = () => {
      const isModeF2F = userData.serviceAvailableModes.includes(0);
      const hasModeF2F = hasAppointmentModes(0);
      const branchData = dataBranchesList.filter(d => userData.serviceBranches.includes(d.id));
      const onlineBranchesLength = branchData.filter(d => d.isVirtual === true).length;
      const isAllOnlineBranches = onlineBranchesLength === branchData.length;
      if (!isAllOnlineBranches && (isModeF2F || hasModeF2F)) {
        if (onlineBranchesLength > 0) {
          return (
            <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipOnlineF2F}>
              <Checkbox id="appointmentType_F2F_active" name="appointmentType_F2F_active" value={0} label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={this.handleModes} indeterminate={!isEditServiceMode && userData.serviceModes.includes("F") ? 'true' : null} checked={ isEditServiceMode ? userEditDataServiceModes.includes(0) : userData.serviceModes.includes(0)}/>
            </Tooltip>
          );
        }
        return (
          <Checkbox id="appointmentType_F2F_active" name="appointmentType_F2F_active" value={0} label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={this.handleModes} indeterminate={!isEditServiceMode && userData.serviceModes.includes("F") ? 'true' : null} checked={ isEditServiceMode ? userEditDataServiceModes.includes(0) : userData.serviceModes.includes(0)}/>
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipF2F}>
            <Checkbox id="appointmentType_F2F_inactive" name="appointmentType_F2F_inactive" value="appointmentType_F2F_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelF2F} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderVideoOption = () => {
      const isModeVideo = userData.serviceAvailableModes.includes(1);
      const hasModeVideo = hasAppointmentModes(1);
      if (isModeVideo || hasModeVideo) {
        return (
          <Checkbox id="appointmentType_Video_active" name="appointmentType_Video_active" value={1} label={locale.OrganisationServices.section.appointmentTypes.labelVideo} onChange={this.handleModes} indeterminate={!isEditServiceMode && userData.serviceModes.includes("V") ? 'true' : null} checked={isEditServiceMode ? userEditDataServiceModes.includes(1) : userData.serviceModes.includes(1)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipVideo}>
            <Checkbox id="appointmentType_Video_inactive" name="appointmentType_Video_inactive" value="appointmentType_Video_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelVideo} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderPhoneOption = () => {
      const isModePhone = userData.serviceAvailableModes.includes(2);
      const hasModePhone = hasAppointmentModes(2);
      if (isModePhone || hasModePhone) {
        return (
          <Checkbox id="appointmentType_Phone_active" name="appointmentType_Phone_active" value={2} label={locale.OrganisationServices.section.appointmentTypes.labelPhone} onChange={this.handleModes} indeterminate={!isEditServiceMode && userData.serviceModes.includes("P") ? 'true' : null} checked={isEditServiceMode ? userEditDataServiceModes.includes(2) :userData.serviceModes.includes(2)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipPhone}>
            <Checkbox id="appointmentType_Phone_inactive" name="appointmentType_Phone_inactive" value="appointmentType_Phone_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelPhone} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderHomeDeliveryOption = () => {
      const isModeHomeDelivery = userData.serviceAvailableModes.includes(5);
      const hasModeHomeDelivery = hasAppointmentModes(5);
      if (isModeHomeDelivery || hasModeHomeDelivery) {
        return (
          <Checkbox id="appointmentType_HomeDelivery_active" name="appointmentType_HomeDelivery_active" value={5} label={locale.OrganisationServices.section.appointmentTypes.labelHomeDelivery} onChange={this.handleModes} indeterminate={!isEditServiceMode && userData.serviceModes.includes("H") ? 'true' : null} checked={isEditServiceMode ? userEditDataServiceModes.includes(5) :userData.serviceModes.includes(5)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.OrganisationServices.section.appointmentTypes.tooltipHomeDelivery}>
            <Checkbox id="appointmentType_HomeDelivery_inactive" name="appointmentType_HomeDelivery_inactive" value="appointmentType_HomeDelivery_inactive" label={locale.OrganisationServices.section.appointmentTypes.labelHomeDelivery} onChange={this.handleModes} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderShowPriceOption = () => {
      const { isAffiliate } = this.props;
      if (isAffiliate) {
        return (
          <Fragment>
            <FormItem type="item" error={isErrorAffiliatePriceOption ? 'error' : null}>
              <div className="row row-list">
                <Radio id="showServicePrice_inactive" name="servicePriceInActive" value="showServicePrice_inactive" label={locale.OrganisationServices.section.servicePrice.labelDontShowPrice} onChange={() => { this.handleAffiliatesShowPriceChange(false); }} indeterminate={!isEditShowPrice && userData.serviceIsShowPrice === "indeterminate" ? 'true' : null} checked={isEditShowPrice ? userEditDataShowPrice === false : userData.serviceIsShowPrice === false} />
                <Radio id="showServicePrice_active" name="servicePriceActive" value="showServicePrice_active" label={locale.OrganisationServices.section.servicePrice.labelShowPrice} onChange={() => { this.handleAffiliatesShowPriceChange(true); }} indeterminate={!isEditShowPrice && userData.serviceIsShowPrice === "indeterminate" ? 'true' : null} checked={isEditShowPrice ? userEditDataShowPrice === true : userData.serviceIsShowPrice === true} />
              </div>
            </FormItem>
            {renderServicePrice(isEditShowPrice ? userEditDataShowPrice : userData.serviceIsShowPrice)}
          </Fragment>
        );
      } else {
        return (
          renderServicePrice(true)
        );
      }
    }

    const renderServicePrice = (isVisible) => {
      if (isVisible === true){
        return (
          <FormItem type="item" error={isErrorPrice || isErrorNegativePrice || isErrorSinglePrice ? 'error' : null}>
            <div className="row row-inline">
              <div className="input-before"><p><strong>{locale.OrganisationServices.section.servicePrice.currency}</strong></p></div>
              <Input type="text" id="servicePrice" name="servicePrice" size="13" placeholder={locale.OrganisationServices.section.servicePrice.placeholder} value={ isEditServicePrice ? userEditDataPrice : userData.servicePrice || null} onChange={this.handlePrice} disabled={isNhsService} />
              {!isAffiliate ? <Checkbox className="no-validate" id="serviceIsVatIncluded" name="serviceIsVatIncluded" value="VAT" label={locale.OrganisationServices.section.servicePrice.labelVAT} indeterminate={!isEditVatIncluded && userData.serviceIsVatIncluded === "indeterminate" ? 'true' : null} onChange={this.handleVATChange} checked={isEditVatIncluded ? userEditDataVatIncluded : userData.serviceIsVatIncluded === true} disabled={isNhsService} /> : null}
            </div>
          </FormItem>
        );
      }
    }

    const getBranchName = () => {
      const { dataBranchesSummary } = this.props;
      return userData.serviceBranches.length === 1 ?
        dataBranchesSummary.filter((d) => d.branchId === userData.serviceBranches[0])[0]['branchName']
        : locale.OrganisationServices.sidebar.editService.branchesSelected(userData.serviceBranches.length)
    }

    if (isDiscardModalOpen) {
      return (
        <DiscardChangesOverlay
        handleDiscardChanges={this.handleDiscardChanges}
        handleStay={this.handleStay}
      />
      );
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.OrganisationServices.sidebar.editService.header}{userData.serviceName}</h2>
          <p>
            {getBranchName()}{/* Todo replace selected branch count */}
            {userData.serviceIsNhsCovered ? <i><LogoNHS /></i> : null}
          </p>
        </SidebarHeader>
        <SidebarBody>
          <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
            <SidebarBodySection>
              <SidebarBodyHeader>
                {isEditServiceMode ? <button onClick={() => { this.handleUndoChangesModes(); }}>{locale.Buttons.undoChanges}</button> : null}
                <h3>{locale.OrganisationServices.section.appointmentTypes.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorModes ? 'error' : null}>
                  <div className="row row-list">
                    {renderF2FOption()}
                    {renderVideoOption()}
                    {renderPhoneOption()}
                    {isSuperAdmin && renderHomeDeliveryOption()}
                  </div>
                  {isErrorModes ? <InputError message={locale.OrganisationServices.validationError.appointmentType} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection>
            <SidebarBodySection>
              <SidebarBodyHeader>
                {isEditStatus ? <button onClick={() => { this.handleUndoChangesStatus(); }}>{locale.Buttons.undoChanges}</button> : null}
                <h3>{locale.OrganisationServices.section.serviceStatus.header}</h3>
                <p>{locale.OrganisationServices.section.serviceStatus.description}</p>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorPaused ? 'error' : null}>
                  <div className="row row-list">
                    <Radio id="serviceStatus_paused" name="serviceStatus" value="serviceStatus_paused" label={locale.OrganisationServices.section.serviceStatus.labelPaused} onChange={() => { this.handleStatusChange(false); }} indeterminate={!isEditStatus && userData.serviceIsActive === "indeterminate" ? 'true' : null} checked={isEditStatus ? userEditDataStatus === false : userData.serviceIsActive === false} />
                    <Radio id="serviceStatus_active" name="serviceStatus" value="serviceStatus_active" label={locale.OrganisationServices.section.serviceStatus.labelActive} onChange={() => { this.handleStatusChange(true); }} indeterminate={!isEditStatus && userData.serviceIsActive === "indeterminate" ? 'true' : null} checked={isEditStatus ? userEditDataStatus === true : userData.serviceIsActive === true} />
                  </div>
                  {isErrorPaused ? <InputError message={locale.OrganisationServices.validationError.paused} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection>
            <SidebarBodySection>
              <SidebarBodyHeader>
                {isEditServicePrice || isEditShowPrice || isEditVatIncluded ? <button onClick={() => { this.handleUndoChangesPrice(); }}>{locale.Buttons.undoChanges}</button> : null}
                <h3>{locale.OrganisationServices.section.servicePrice.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <Fragment>
                  {renderShowPriceOption()}
                  {isErrorAffiliatePriceOption ? <InputError message={locale.OrganisationServices.validationError.affiliatePriceOption} /> : null}
                  {isErrorPrice ? <InputError message={locale.OrganisationServices.validationError.servicePrice} /> : null}
                  {isErrorSinglePrice ? <InputError message={locale.OrganisationServices.validationError.singleValue} /> : null}
                  {isErrorNegativePrice ? <InputError message={locale.OrganisationServices.validationError.negativePrice} /> : null}
                </Fragment>
              </SidebarBodyContent>
            </SidebarBodySection>
            <SidebarBodySection>
              <SidebarBodyHeader>
                {isEditDuration ? <button onClick={() => { this.handleUndoChangesDuration(); }}>{locale.Buttons.undoChanges}</button> : null}
                <h3>{locale.OrganisationServices.section.appointmentDuration.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorDuration ? 'error' : null}>
                  <div className="row row-inline">
                    {this.renderDuration()}
                  </div>
                  {isErrorDuration ? <InputError message={locale.OrganisationServices.validationError.appointmentDuration} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection>
			      {!isAffiliate ?
            <SidebarBodySection>
              <SidebarBodyHeader>
                {isEditCutoff ? <button onClick={() => { this.handleUndoChangesCutoff(); }}>{locale.Buttons.undoChanges}</button> : null}
                <h3>{locale.OrganisationServices.section.bookingCutOff.header}</h3>
                <p>{locale.OrganisationServices.section.bookingCutOff.description}</p>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <FormItem type="item" error={isErrorCutOff ? 'error' : null}>
                  <div className="row row-inline">
                    {this.renderBookingCutOff()}
                  </div>
                  {isErrorCutOff ? <InputError message={locale.OrganisationServices.validationError.cutOff} /> : null}
                </FormItem>
              </SidebarBodyContent>
            </SidebarBodySection> : null}
            {isAffiliate ? <SidebarBodySection>
              <SidebarBodyHeader>
                {isEditBookingUrl ? <button onClick={() => { this.handleUndoChangesBookingUrl(); }}>{locale.Buttons.undoChanges}</button> : null}
                <h3>{locale.OrganisationsSettings.bookingUrl.title1}</h3>
                <p>{locale.OrganisationsSettings.bookingUrl.hint4}</p>
                <p>{locale.OrganisationsSettings.bookingUrl.hint3}</p>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                {this.renderBookingUrl()}
              </SidebarBodyContent>
            </SidebarBodySection> : null}
          </Form>
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column">
              <ButtonDelete onClick={(e) => { this.handleRemoveService(e); }} />
            </div>
            {isGenericError ? <p className="error">{locale.OrganisationServices.validationError.makeChange}</p> : null}
            <div className="column">
              <Button
               buttonType="blueline"
               messageKey="some-key"
               defaultMessage={locale.OrganisationServices.button.cancel}
               onClick={() => { this.handleOpenDiscardDialog(); }}
              />
              <Button
                className={`patient-care-btn-in-group ${!isFormValid() && "pretend-disabled"}`}
                buttonType="secondary"
                messageKey="some-key"
                defaultMessage={isEditByBranch ? locale.OrganisationServices.button.saveChanges : locale.OrganisationServices.button.continue}
                onClick={isFormValid() ? handleContinueStep : validateForm}
              />
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
