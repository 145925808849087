import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Form, SearchFilter, IconSearch, Skeleton } from '@patient-access/ui-kit';
import { FeatureContentBreadcrumbs } from 'components/Share/feature';
import { setFiltersSearchValue, setFiltersBranchesValue, setFiltersServicesValue } from 'actions/calendarsList';
import * as ServicesConstants from 'constants/ServicesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import { modifyServicesList } from 'helpers/common';
import { formatListAsOptions } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

// CALENDAR_ADD
import { openModalOverlay } from 'actions/panel';

type Props = {
  organisationId: string,
  branchId: string,
  searchValue: string,
  serviceIds: any,
  services: any,
  currentRole: any,
  isLoading: boolean,
  isLoadingServices: boolean,
  setFiltersSearchValue: (searchValue: string) => any,
  setFiltersBranchesValue: (branchIds: any) => any,
  setFiltersServicesValue: (serviceIds: any) => any,

  // CALENDAR_ADD
  openModalOverlay: () => Action,
};

const mapStateToProps = (state) => ({
  searchValue: state.calendarsList.filters.searchValue,
  serviceIds: state.calendarsList.filters.serviceIds,
  services: state.calendarsList.services.data,
  currentRole: state.roles.profileCurrentRole,
  isLoadingServices: state.calendarsList.services.isLoading,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setFiltersSearchValue: (searchValue: string) => dispatch(setFiltersSearchValue(searchValue)),
  setFiltersBranchesValue: (branchIds: string) => dispatch(setFiltersBranchesValue(branchIds)),
  setFiltersServicesValue: (serviceIds: string) => dispatch(setFiltersServicesValue(serviceIds)),

  // CALENDAR_ADD
  openModalOverlay: () => dispatch(openModalOverlay()),
});

class FilterCalendars extends Component<Props> {
  componentWillUnmount = () => {
    const { setFiltersSearchValue, setFiltersBranchesValue, setFiltersServicesValue } = this.props;
    setFiltersSearchValue('');
    setFiltersBranchesValue([]);
    setFiltersServicesValue([]);
  }

  render() {
    const { organisationId, branchId, searchValue, serviceIds, services, currentRole, isLoading, isLoadingServices, setFiltersSearchValue, setFiltersServicesValue, openModalOverlay } = this.props;
    const isAbleToCreateCalendar = currentRole.role !== RolesConstants.BRANCH_MEMBER;

    // Create services list
    const servicesList = services ? modifyServicesList({ items: services || [] }) : [];
    let servicesListOpts = formatListAsOptions(servicesList.slice(0).filter(service => service.value));
    servicesListOpts.unshift(ServicesConstants.DROPDOWN_OPTION_DEFAULT);

    // Exclude services not added
    const servicesListOptsFiltered = servicesListOpts.filter((service) => {
      return service.isServiceAdded !== false;
    });

    const handleServiceIds = (service: Service) => {
      if (service.value) {
        setFiltersServicesValue([service.value]);
      } else {
        setFiltersServicesValue([]);
      }
    };

    const renderSelectServices = () => {
      if (isLoading || isLoadingServices) return <Skeleton className="patient-care-select" type="select" />;
      return (
        <Select
          id="selectCalendarServices"
          name="selectCalendarServices"
          defaultValue={servicesListOptsFiltered.find(option => option.id === serviceIds[0])}
          options={servicesListOptsFiltered}
          classNamePrefix="patient-care"
          className="patient-care-select"
          onChange={handleServiceIds}
        />
      );
    }

    const renderButtonAddCalendar = () => {
      if (isAbleToCreateCalendar) {
        return (
          <div className="column">
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.BranchCalendarsRedesign.button.addCalendar}
              onClick={() => { openModalOverlay(); }}
              isLoading={isLoading}
            />
          </div>
        );
      }
      return null;
    }

    return (
      <Fragment>
        <div className="row">
          <FeatureContentBreadcrumbs organisationId={organisationId} branchId={branchId} />
        </div>
        <div className="row">
          <div className="column column-full">
            <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
              <SearchFilter
                autoComplete="off"
                id="searchCalendarList"
                name="searchCalendarList"
                placeholder={locale.BranchCalendarsRedesign.filter.placeholder}
                clearHandler={() => { setFiltersSearchValue(''); }}
                onChange={(e) => { setFiltersSearchValue(e.target.value); }}
                icon={<IconSearch outline />}
                value={searchValue}
              />
            </Form>
          </div>
          <div className="column column-branch-calendars-select">
            {renderSelectServices()}
          </div>
          {renderButtonAddCalendar()}
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCalendars);
