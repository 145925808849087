import * as BookConstants from 'constants/BookAppointmentConstants';

type WalkInAppointment = {
  service: {
    id: string;
    name: string;
    duration: number | null;
    lastMinute: number;
  };
  startTime: string;
  endTime: string;
  slotId: string;
  branchName: string;
  slotCalendars: {
    calendarId?: string,
    slotId?: string,
  }[],
  patient: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  calendar: {
    id: string;
    name: string;
  };
  slots: {
    id?: string;
    start?: string;
  }[],
  appointmentId: string;
  isAppointmentPending: boolean;
  isBulkAppointmentPending: boolean;
  isSlotExpired: boolean,
  isGetSlotsPending: boolean
}


const initialState = {
  service: {
    id: "",
    name: "",
    duration: null,
    lastMinute: 0,
    type: "",
    modes: []
  },
  startTime: "",
  endTime: "",
  slotId: "",
  slotCalendars: [],
  branchName: "",
  patient: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  },
  calendar: {
    id: "",
    name: "[ Auto selected from avail. ]"
  },
  slots: [],
  appointmentId: "",
  isAppointmentPending: false,
  isBulkAppointmentPending: false,
  isSlotExpired: false,
  isGetSlotPending: false,
  heldcalendarId: ""
};

export default (localState: WalkInAppointment = initialState, action: any): WalkInAppointment => {
  switch (action.type) {
    case BookConstants.SET_BOOKING_SERVICE:
      return {
        ...localState,
        service: action.payload
      };
    case BookConstants.SET_BOOKING_CALENDAR:
      return {
        ...localState,
        calendar: action.payload
      };
    case BookConstants.SET_SLOT_CALENDARS_LIST:
      return {
        ...localState,
        slotCalendars: action.payload
      };
    case BookConstants.SET_BOOKING_SLOTS:
      return {
        ...localState,
        slots: action.payload
      };
    case BookConstants.SET_BOOKING_TIME:
      return {
        ...localState,
        startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          slotId: action.payload.slotId,
          appointmentId: action.payload.appointmentId,
          heldcalendarId: action.payload.calendarId
      };
    case BookConstants.BOOK_APPOINTMENT_PENDING:
      return {
        ...localState,
        isAppointmentPending: true,
      };
    case BookConstants.BOOK_APPOINTMENT_PENDING_STOP:
      return {
        ...localState,
        isAppointmentPending: false,
      };
    case BookConstants.BOOK_APPOINTMENT_SUCCESS:
      return {
        ...localState,
        isAppointmentPending: false,
      };
    case BookConstants.BOOK_APPOINTMENT_ERROR:
      return {
          ...localState,
          isAppointmentPending: false,
            isSlotExpired: action.payload ? action.payload : false
      };
    case BookConstants.BOOK_BULK_APPOINTMENT_SUCCESS:
      return {
        ...localState,
        isBulkAppointmentPending: false,
      };
    case BookConstants.BOOK_BULK_APPOINTMENT_ERROR:
       return {
          ...localState,
          isBulkAppointmentPending: false,
       };  
    case BookConstants.HOLD_APPOINTMENT_ERROR:
      return {
        ...localState,
        isAppointmentPending: false,
          isSlotTaken: action.payload ? action.payload : false
      };
    case BookConstants.GET_SLOTS_PENDING:
      return {
        ...localState,
        isGetSlotsPending: true,
      };
    case BookConstants.GET_SLOTS_SUCCESS:
      return {
        ...localState,
        isGetSlotsPending: false,
      };
    case BookConstants.GET_SLOTS_ERROR:
      return {
        ...localState,
        isGetSlotsPending: false,
      };
    case BookConstants.CREATE_INTERNAL_EVENT_PENDING:
      return {
        ...localState,
        isInternalEventPending: true,
      };
    case BookConstants.CREATE_INTERNAL_EVENT_ERROR:
      return {
        ...localState,
        isInternalEventPending: false,
      };
    case BookConstants.CREATE_INTERNAL_EVENT_SUCCESS:
      return {
        ...localState,
        isInternalEventPending: false,
      };
    default:
      return localState;
  }
}
