import agent from 'service/agent';
import { setOrganisationHeader } from 'actions/router';
import { getToken } from 'helpers/common';
import * as ReportsCustomConstants from 'constants/ReportsCustomConstants';

// REPORTS_CUSTOM_ORGANISATIONS
export const setReportsCustomOrganisationsPending = (): Action => ({
  type: ReportsCustomConstants.GET_REPORTS_CUSTOM_ORGANISATIONS_PENDING,
});

export const setReportsCustomOrganisationsSuccess = (): Action => ({
  type: ReportsCustomConstants.GET_REPORTS_CUSTOM_ORGANISATIONS_SUCCESS,
});

export const setReportsCustomOrganisationsError = (): Action => ({
  type: ReportsCustomConstants.GET_REPORTS_CUSTOM_ORGANISATIONS_ERROR,
});

export const setReportsCustomOrganisationsItems = (reportItems: any): Action => ({
  type: ReportsCustomConstants.SET_REPORTS_CUSTOM_ORGANISATIONS_ITEMS,
  payload: reportItems,
});

export const getReportsCustomOrganisations = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setReportsCustomOrganisationsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsCustom.getOrganisationsReports(accessToken)
        .then(reportItems => {
          dispatch(setReportsCustomOrganisationsItems(reportItems));
          dispatch(setReportsCustomOrganisationsSuccess());
        })
        .catch(err => {
          dispatch(setReportsCustomOrganisationsError());
          console.log("getReportsCustomOrganisations server error or branch was not found", err);
        });
    });
};

// REPORTS_CUSTOM_SERVICES
export const setReportsCustomServicesPending = (): Action => ({
  type: ReportsCustomConstants.GET_REPORTS_CUSTOM_SERVICES_PENDING,
});

export const setReportsCustomServicesSuccess = (): Action => ({
  type: ReportsCustomConstants.GET_REPORTS_CUSTOM_SERVICES_SUCCESS,
});

export const setReportsCustomServicesError = (): Action => ({
  type: ReportsCustomConstants.GET_REPORTS_CUSTOM_SERVICES_ERROR,
});

export const setReportsCustomServicesItems = (reportItems: any): Action => ({
  type: ReportsCustomConstants.SET_REPORTS_CUSTOM_SERVICES_ITEMS,
  payload: reportItems,
});

export const setReportsCustomSelectedService = (selectedService: any): Action => ({
  type: ReportsCustomConstants.SET_REPORTS_CUSTOM_SELECTED_SERVICE,
  payload: selectedService,
});

export const getReportsCustomServices = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const selectedService = currentState.reportsCustom.services.selectedService;

  let selectedServiceIds = [];
  selectedService.forEach(service => {
    selectedServiceIds.push(service.id);
  });

  dispatch(setReportsCustomServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.ReportsCustom.getServicesReports(selectedServiceIds, accessToken)
        .then(reportItems => {
          dispatch(setReportsCustomServicesItems(reportItems));
          dispatch(setReportsCustomServicesSuccess());
        })
        .catch(err => {
          dispatch(setReportsCustomServicesError());
          console.log("getReportsCustomServices server error or branch was not found", err);
        });
    });
};
