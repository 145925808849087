// REPORTS_CUSTOM_ORGANISATIONS
export const GET_REPORTS_CUSTOM_ORGANISATIONS_PENDING = "GET_REPORTS_CUSTOM_ORGANISATIONS_PENDING";
export const GET_REPORTS_CUSTOM_ORGANISATIONS_SUCCESS = "GET_REPORTS_CUSTOM_ORGANISATIONS_SUCCESS";
export const GET_REPORTS_CUSTOM_ORGANISATIONS_ERROR = "GET_REPORTS_CUSTOM_ORGANISATIONS_ERROR";
export const SET_REPORTS_CUSTOM_ORGANISATIONS_ITEMS = "SET_REPORTS_CUSTOM_ORGANISATIONS_ITEMS";

// REPORTS_CUSTOM_SERVICES
export const GET_REPORTS_CUSTOM_SERVICES_PENDING = "GET_REPORTS_CUSTOM_SERVICES_PENDING";
export const GET_REPORTS_CUSTOM_SERVICES_SUCCESS = "GET_REPORTS_CUSTOM_SERVICES_SUCCESS";
export const GET_REPORTS_CUSTOM_SERVICES_ERROR = "GET_REPORTS_CUSTOM_SERVICES_ERROR";
export const SET_REPORTS_CUSTOM_SERVICES_ITEMS = "SET_REPORTS_CUSTOM_SERVICES_ITEMS";
export const SET_REPORTS_CUSTOM_SELECTED_SERVICE = "SET_REPORTS_CUSTOM_SELECTED_SERVICE";