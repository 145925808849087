import React, { Component } from 'react';

import './styles/patient-care-feature-content-sticky.scss';

type Props = {
  children: any,
  className?: string,
};

class FeatureContentSticky extends Component<Props> {
  render() {
    const { children, className } = this.props;
    return (
      <div className={`patient-care-feature-content-sticky${className ? ` ${className}` : ''}`}>
        {children}
      </div>
    );
  }
};

export default FeatureContentSticky;
