import * as RoomsConstants from 'constants/RoomsConstants';
import type { Action } from 'types/actions';
import type { RoomsListState } from "types/branch";
import { cloneDeep } from "lodash";

const initialState: RoomsListState = {
  roomsList: [],
  isRoomsPending: false,
  isUpdatingBranchRooms: false,
  roomFutureAppointments: {
    roomId: null,
    listOfAppointments: []
  },
};

export default (localState: RoomsListState = initialState, action: Action): RoomsListState => {

  let roomsList = localState.roomsList || [];

  switch (action.type) {

    case RoomsConstants.SET_ROOMS_LIST:
      return {
        ...localState,
        roomsList: action.payload,
        roomsListLoadedInitially: cloneDeep(action.payload),
        isRoomsPending: false,
      };

    case RoomsConstants.CREATE_ROOM:
      roomsList.unshift({
        id: action.payload.id,
        name: action.payload.name
      });
      return {
        ...localState,
        roomsList: roomsList
      };

    case RoomsConstants.DELETE_ROOM:
      const deletedServiceIndex = localState.roomsList.findIndex(room => room.id === action.payload);
      return {
        ...localState,
        roomsList: [
          ...localState.roomsList.slice(0, deletedServiceIndex),
          ...localState.roomsList.slice(deletedServiceIndex + 1)
        ]
      };

    case RoomsConstants.UPDATE_ROOM:
      const index = localState.roomsList.findIndex(room => room.id === action.payload.id);
      if (roomsList[index]) roomsList[index].name = action.payload.name;
      return {
        ...localState,
        roomsList: roomsList
      };
    case RoomsConstants.SET_ROOMS_PENDING:
      return {
        ...localState,
        isRoomsPending: true,
      };
    case RoomsConstants.UPDATE_BRANCH_ROOMS_PENDING:
      return {
        ...localState,
        isUpdatingBranchRooms: true,
      };
    case RoomsConstants.UPDATE_BRANCH_ROOMS_SUCCESS:
      return {
        ...localState,
        isUpdatingBranchRooms: false,
      };
    case RoomsConstants.UPDATE_BRANCH_ROOMS_ERROR:
      return {
        ...localState,
        isUpdatingBranchRooms: false,
      };
    case RoomsConstants.SET_ROOM_FUTURE_APPOITNMENTS:
      return {
        ...localState,
        roomFutureAppointments: {
          roomId: action.payload.roomId,
          listOfAppointments: action.payload.listOfAppointments
        }
      };
    default:
      return localState;
  }
}

