import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconFinances extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M23,8V18a2,2,0,0,1-2,2H5a1,1,0,0,1,0-2H21V8a1,1,0,0,1,2,0ZM4,16a3,3,0,0,1-3-3V7A3,3,0,0,1,4,4H16a3,3,0,0,1,3,3v7a2,2,0,0,1-2,2Zm3-6a3,3,0,1,0,3-3A3,3,0,0,0,7,10Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-finances ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconFinances;
