import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, InputLabel, Input, InputError } from "@patient-access/ui-kit";

import { checkPostcode } from 'helpers/checkValues';
import { updateForm } from 'actions/form';
import * as RolesConstants from 'constants/RolesConstants';
import * as BranchesConstants from "constants/BranchesConstants";

import locale from 'service/locale';

type Props = {
  form: any,
  updateForm: (date: any) => any,
  currentRole: object,
  organisationId?: string,
};

type State = {
  addressLine1: string,
  addressLine2: string,
  town: string,
  postcode: string,
};

const mapStateToProps = state => ({
  form: state.form,
  currentRole: state.roles.profileCurrentRole
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class AddBranchAddressInfo extends Component<Props, State> {

  state = {
    addressLine1: "",
    addressLine2: "",
    town: "",
    postcode: "",
  };

  componentDidMount = () => {
    const { updateForm, form } = this.props;
      updateForm({
        address: {
          ...form.address,
          country: BranchesConstants.COUNTRY
        },
      });
  };

  componentWillUnmount = () => {
    this.setState({
      addressLine1: "",
      addressLine2: "",
      town: "",
      postcode: "",
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.form.address) {
      const { addressLine1, addressLine2, town, postcode } = nextProps.form.address;
      this.setState({
        addressLine1,
        addressLine2,
        town,
        postcode,
      });
    }
  };

  handleInputChange = (e: Event) => {
    const { updateForm, form } = this.props;
    const {
      target: { name, value }
    } = e;

    updateForm({
      address: {
        ...form.address,
        [name]: value,
      },
    })
  };

  render() {
    const { form, organisationId, currentRole, } = this.props;
    const { addressLine1, addressLine2, town, postcode } = this.state;
    const isPostcodeCorrect = checkPostcode(postcode);
    const isEditedAddressLine1 = form.address && Object.keys(form.address).includes('addressLine1');
    const isEditedAddressTown = form.address && Object.keys(form.address).includes('town');
    const isEditedAddressPostcode = form.address && Object.keys(form.address).includes('postcode');
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isVirtual = form.isVirtual;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{`${organisationId && isSuperAdmin ? "3. " : "4. "}${locale.AddBranch.addressTitle}`} 
              {isVirtual? " (optional)": null}</h3>
          <p>{locale.AddBranch.addressText}</p>
        </div>
        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item" error={(!isVirtual && !addressLine1 && isEditedAddressLine1) ? 'true' : ''}>
                <InputLabel
                  htmlFor="branch_address_1"
                  message={locale.AddBranch.addressLine1Label}
                  size="small"
                />
                <Input
                  type="text"
                  id="branch_address_1"
                  name="addressLine1"
                  data-id="branch_address_1"
                  value={addressLine1 ? addressLine1 : ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.addressLine1Label}
                />
                {!isVirtual &&
                  !addressLine1 && isEditedAddressLine1 &&
                  <InputError message={locale.AddBranch.addressError} />
                }
              </FormItem>
            </Form>
          </div>
        </div>

        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item">
                <InputLabel
                  htmlFor="branch_address_2"
                  message={isVirtual? 
                            locale.AddBranch.addressLine2VirtualLabel: 
                            locale.AddBranch.addressLine2Label}
                  size="small"
                />
                <Input
                  type="text"
                  id="branch_address_2"
                  name="addressLine2"
                  data-id="branch_address_2"
                  value={addressLine2 ? addressLine2 : ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.addressLine2VirtualLabel}
                />
              </FormItem>
            </Form>
          </div>
        </div>

        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item" error={(!isVirtual && !town && isEditedAddressTown) ? 'true' : ''}>
                <InputLabel
                  htmlFor="branch_town"
                  message={locale.AddBranch.townLabel}
                  size="small"
                />
                <Input
                  type="text"
                  id="branch_town"
                  name="town"
                  data-id="branch_town"
                  value={town ? town : ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.townLabel}
                />
                {
                  !isVirtual && !town && isEditedAddressTown &&
                  <InputError message={locale.AddBranch.townError} />
                }
              </FormItem>
            </Form>
          </div>
        </div>

        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item" error={(!isVirtual && !isPostcodeCorrect && isEditedAddressPostcode) ? 'true' : ''}>
                <InputLabel
                  htmlFor="branch_postcode"
                  message={locale.AddBranch.postcodeLabel}
                  size="small"
                />
                <Input
                  type="text"
                  id="branch_postcode"
                  name="postcode"
                  data-id="branch_postcode"
                  value={postcode ? postcode : ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.postcodeLabel}
                />
                {
                  !isVirtual && !isPostcodeCorrect && isEditedAddressPostcode &&
                  <InputError message={locale.AddBranch.postcodeError} />
                }
              </FormItem>
            </Form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBranchAddressInfo);
