import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import ConflictModal from "components/Share/ConflictModal/ConflictModal";
import AddNewCalendarName from "./AddNewCalendarName/AddNewCalendarName";
import AddNewCalendarBranchClosedDays from "./AddNewCalendarBranchClosedDays/AddNewCalendarBranchClosedDays";
import EditCalendarServices from "components/Share/EditCalendarServices/EditCalendarServices";
import AddNewCalendarAvailability from "./AddNewCalendarAvailability/AddNewCalendarAvailability";
import AddNewCalendarBlockAvailability from "./AddNewCalendarBlockAvailability/AddNewCalendarBlockAvailability";
import AddOrDiscardButtons from "components/Share/AddOrDiscardButtons/AddOrDiscardButtons";
import ModalHeader from "components/Share/ModalHeader/ModalHeader";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import { getAvailabilitySettings } from 'actions/calendars';
import type { Action } from "types/actions";
import { createCalendar } from "actions/calendars";
import { closeCalendarNameConflict, closeCalendarAvailabilityConflict } from "actions/panel";
import { drawerScrollHandler } from "helpers/common";
import {
  isDuplicateNameError,
  isEmptyNameError,
  isNewCalendarDataCorrect
} from "helpers/validateData";
import type { Calendar } from 'types/calendars';
import { checkOpenHours , checkBranchAvailibility } from "helpers/validateData";

import locale from "service/locale";
import AddOrganisationBranches from "./AddOrganisationBranches/AddOrganisationBranches";
import {
  getBranchDetails,
} from "actions/branchDetails";

type Props = {
  isActiveAgenda: boolean,
  form: Object,
  branchId: string,
  organisationId: string,
  handleCloseModal: () => any,
  handleDiscardChanges: () => any,
  addBranchCalendar: () => any,
  createCalendar: (branchId: string, organisationId?: string) => Function,
  getAvailabilitySettings: (organisationId: string, branchId: string) => Action,
  getBranchDetails: (organisationId: string, branchId: string) => any,
  existingCalendarNamesList: any[],
  isCalendarNameConflictOpen: boolean,
  isCalendarAvailabilityConflictOpen: boolean,
  closeCalendarNameConflict: () => any,
  closeCalendarAvailabilityConflict: () => any,
  isLoading: boolean,
  calendar: Calendar,
};

type State = {
  isDiscardChangesOpened: boolean,
  isScrolledStart: boolean,
  isScrolledEnd: boolean,
  needRedirect: boolean,
  selectedBranchId: string
};

const mapStateToProps = state => ({
  isActiveAgenda: state.panel.isActiveAgenda,
  isCalendarNameConflictOpen: state.panel.isCalendarNameConflictOpen,
  isCalendarAvailabilityConflictOpen: state.panel.isCalendarAvailabilityConflictOpen,
  form: state.form,
  isLoading: state.branchDetails.isCreateCalendarPending,
  calendar: state.calendar,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  createCalendar: (branchId: string, organisationId?: string ) => dispatch(createCalendar(branchId, organisationId)),
  getAvailabilitySettings: (organisationId: string, branchId: string) => dispatch(getAvailabilitySettings(organisationId, branchId)),
  closeCalendarNameConflict: () => dispatch(closeCalendarNameConflict()),
  closeCalendarAvailabilityConflict: () => dispatch(closeCalendarAvailabilityConflict()),
  getBranchDetails: (organisationId, branchId) => dispatch(getBranchDetails(organisationId, branchId)),
});

class AddNewCalendarModal extends Component<Props, State> {
  state = {
    isDiscardChangesOpened: false,
    isScrolledStart: false,
    isScrolledEnd: false,
    needRedirect: false,
    selectedBranchId: ''
  };

  componentDidMount = () => {
    drawerScrollHandler.bind(this)();
    const { getAvailabilitySettings, organisationId, branchId } = this.props;
    if (branchId)
      getAvailabilitySettings(organisationId, branchId);
  };

  handleAddCalendar = () => {
    const { branchId, createCalendar, organisationId } = this.props;
    const { selectedBranchId } = this.state;
       
    branchId ? createCalendar(branchId, organisationId) : createCalendar(selectedBranchId, organisationId);
  };

  handleStay = () => {
    const { closeCalendarNameConflict, closeCalendarAvailabilityConflict } = this.props;
    closeCalendarNameConflict();
    closeCalendarAvailabilityConflict();
    this.setState({ isDiscardChangesOpened: false });
  };

  handleOpenDiscardDialog = (e: any) => {
    e && e.preventDefault();
    this.setState({ isDiscardChangesOpened: true });
  };

  handleRedirect = () => {
    const { form, handleCloseModal } = this.props;
    const isFormEdited = Object.keys(form).length > 0;

    this.setState({
      needRedirect: true
    }, () => {
      isFormEdited ? this.handleOpenDiscardDialog() : handleCloseModal(true);
    });
  };

  handleDiscardChanges = () => {
    const { needRedirect } = this.state;
    const { handleDiscardChanges } = this.props;
    handleDiscardChanges(needRedirect);
  };

  handleBranchChange = (branchId: String) => {
    const { getAvailabilitySettings, getBranchDetails, organisationId } = this.props;
    this.setState({
      selectedBranchId: branchId
    });
    getBranchDetails(organisationId,  branchId);
    getAvailabilitySettings(organisationId, branchId);
  }

  render() {
    const {
      isActiveAgenda,
      handleCloseModal,
      form,
      existingCalendarNamesList,
      isCalendarNameConflictOpen,
      isCalendarAvailabilityConflictOpen,
      isLoading,
      calendar,
      organisationId,
      branchId
    } = this.props;
    const { isDiscardChangesOpened, isScrolledStart, isScrolledEnd, selectedBranchId } = this.state;
    const isCalendarEdited = Object.keys(form).length > 0;
    const openHours = form && form.openHours ;
    const branchOpenHours = form && form.branchOpenHours ;
    const {
      isNotMultipleByFive,
      isEqualTime,
      isStartTimeLessThanEndTime,
      isIntervalInvalid,
      hasInvalidTime
    } = checkOpenHours(openHours);
    const { isCalendarIntervalInvalidWithBranchAvailability } = checkBranchAvailibility(branchOpenHours, openHours) ;
    const isInValidCalendar = isNotMultipleByFive || isEqualTime || isStartTimeLessThanEndTime || isIntervalInvalid || isCalendarIntervalInvalidWithBranchAvailability ||hasInvalidTime ;

    return (
      <div
        className={`patient-care-modal-full-height-${
          isActiveAgenda ? "with-agenda-holder" : "holder"
          }`}
        onScroll={drawerScrollHandler.bind(this)}
      >
        <div className="patient-care-modal-full-height">
          <ModalHeader
            ref="modalHeader"
            title={locale.AddNewCalendar.title}
            handleCloseModal={handleCloseModal}
            handleDiscardChanges={this.handleOpenDiscardDialog}
            customClassName={isScrolledStart ? "has-shadow" : ""}
          />
          <div className="patient-care-modal-content" ref="modalContent">
            <div id="patient-care-scrolling-content" ref="modalContentScroll">
              {!branchId && (
                <AddOrganisationBranches 
                organisationId={organisationId} 
                handleBranchChange={this.handleBranchChange} />
              )}
              <AddNewCalendarName
                isDuplicateNameError={isDuplicateNameError(
                  form,
                  existingCalendarNamesList
                )}
                isEmptyNameError={isEmptyNameError(form)}
                name={form.name || ""}
              />
              {(!!branchId || !!selectedBranchId) && (
                <Fragment>
                  <EditCalendarServices defaultChecked={true} />
                  <AddNewCalendarAvailability />
                  { calendar.closedDays && calendar.closedDays.length
                    ? (
                      <AddNewCalendarBranchClosedDays
                        organisationId={organisationId}
                        branchId={branchId || selectedBranchId}
                        handleRedirect={this.handleRedirect}
                      />
                    )
                    : null
                  }
                  <AddNewCalendarBlockAvailability />
                </Fragment>
              )}
            </div>
          </div>
          <AddOrDiscardButtons
            ref="modalAddOrDiscardButtons"
            handleCloseModal={handleCloseModal}
            handleDiscardChanges={this.handleOpenDiscardDialog}
            cancelText={locale.AddNewCalendar.cancelButtonText}
            proceedText={locale.AddNewCalendar.addButtonText}
            proceedFunction={this.handleAddCalendar}
            isDiscard={isCalendarEdited}
            isDisabled={!isNewCalendarDataCorrect(form, existingCalendarNamesList) || isInValidCalendar}
            isLoading={isLoading}
            customClassName={isScrolledEnd ? "has-shadow" : ""}
          />
        </div>
        <span className="patient-care-modal-overlay" />
        {isDiscardChangesOpened && (
          <DiscardChangesOverlay
            handleDiscardChanges={this.handleDiscardChanges}
            handleStay={this.handleStay}
          />
        )}
        {isCalendarNameConflictOpen && (
          <ConflictModal
            title={locale.AddNewCalendar.nameConflictTitle}
            body={locale.AddNewCalendar.nameConflictBody}
            buttonText={locale.AddNewCalendar.nameConflictButton}
            handleStay={this.handleStay}
          />
        )}
        {isCalendarAvailabilityConflictOpen && (
          <ConflictModal
            title={locale.AddNewCalendar.availabilityConflictTitle}
            body={locale.AddNewCalendar.availabilityConflictMessage}
            buttonText={locale.AddNewCalendar.backButtonText}
            handleStay={this.handleStay}
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewCalendarModal);
