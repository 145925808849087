import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "@patient-access/ui-kit";

import { updateForm, clearForm } from "actions/form";
import type { PanelActions } from "types/panel";

import TwoFactorRemoveModal from "./TwoFactorRemoveModal/TwoFactorRemoveModal"
import { removeUserMFA } from "actions/userDetails";

import locale from "service/locale";

import "./styles.scss";
import { closeTwoFactorOverlay, openTwoFactorOverlay } from "actions/panel";

type Props = {
  userDetails: any,
  form: any,
  updateForm: (data: any) => any,
  clearForm: () => any,
  isEditMode: boolean,
  removeUserMFA: (userId: string) => any,
  isRemoveTwoFactorOverlay: boolean,
  openTwoFactorOverlay: ()=> PanelActions,
  closeTwoFactorOverlay: ()=> PanelActions,
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
    isRemoveTwoFactorOverlay: state.panel.isRemoveTwoFactorOverlay
  };
};

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  removeUserMFA: (userId: string) => dispatch(removeUserMFA(userId)),
  openTwoFactorOverlay: () => dispatch(openTwoFactorOverlay()),
  closeTwoFactorOverlay: () => dispatch(closeTwoFactorOverlay())
});

export class TwoFactorRemove extends Component<Props, State> {

  callRemoveOverlay = () => {
    const {openTwoFactorOverlay} = this.props;
    openTwoFactorOverlay();
  }

  handleRemoveOverlayClose = () => {
    const {closeTwoFactorOverlay} = this.props;
    closeTwoFactorOverlay();
  }

  handleRemoveOverlaySave = () => {
    const { removeUserMFA, userDetails } = this.props;

    removeUserMFA(userDetails.id);
    
  }

  render() {
    const { userDetails, isEditMode, isRemoveTwoFactorOverlay } = this.props;


    const renderEditMode = () => {
      return (
        <Fragment>
          <div className="patient-care-block remove-mfa-wrap">
          <div className="patient-care-col-10">
            <span className={`patient-care-filled-input ${ userDetails.isMFAPhoneRegistered ? "mfa-enabled" : "mfa-not-enabled"}`}>
              {userDetails.isMFAPhoneRegistered
                ? locale.UserDetails.twoFactorEnabled
                : locale.UserDetails.twoFactorNotEnabled}
            </span>
            </div>
            {userDetails.isMFAPhoneRegistered ? 
              <div className="patient-care-col-2">
                <Button
                  buttonType="blueline"
                  messageKey="remove-mfa"
                  defaultMessage={locale.UserDetails.twoFactorRemoveButton}
                  data-id="remove-mfa"
                  onClick={this.callRemoveOverlay}
                />
              </div>
            : null}
            
          </div>
        </Fragment>
      );
    };

    const renderReadMode = () => {
      return (
        <Fragment>
          <span className={`patient-care-filled-input ${ userDetails.isMFAPhoneRegistered ? "mfa-enabled" : "mfa-not-enabled"}`}>
            {userDetails.isMFAPhoneRegistered
              ? locale.UserDetails.twoFactorEnabled
              : locale.UserDetails.twoFactorNotEnabled}
          </span>
        </Fragment>
      );
    };
    return (
      <Fragment>{isEditMode ? renderEditMode() : renderReadMode()}
      {isRemoveTwoFactorOverlay ? 
      <TwoFactorRemoveModal 
          handleRemoveOverlayClose={this.handleRemoveOverlayClose}
          handleRemoveOverlaySave={this.handleRemoveOverlaySave}
          />
             : null}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorRemove);
