import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import AsyncSelect from "react-select/lib/Async";
import { Form, FormItem, InputError } from "@patient-access/ui-kit";

import { getOrganisationsForAsyncSelect } from 'actions/organisations';
import { updateForm } from 'actions/form';
import { setOrganisationHeader } from "actions/router";
import type { Organisation } from 'types/organisation';
import type { Action } from "types/actions";

import locale from 'service/locale';

type Props = {
  form: any,
  updateForm: (date: any) => any,
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
  organisations: Organisation[],
  setOrganisationHeader: (organisationId: string) => Action
};

type State = {
  searchValue: string,
};

const mapStateToProps = state => ({
  form: state.form,
  organisations: state.organisations.organisationsListAsync,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
  setOrganisationHeader: (organisationId: string) => dispatch(setOrganisationHeader(organisationId))
});

class AddBranchOrganisation extends Component<Props, State> {

  state = {
    searchValue: "",
  };

  componentDidMount = () => {
    const { updateForm, getOrganisationsForAsyncSelect } = this.props;
    getOrganisationsForAsyncSelect();
     updateForm({
      hasVirtualBranch: true,
     });
  };

  componentWillUnmount = () => {
    this.setState({
      searchValue: "",
    });
  };

  handleSelectOrganisation = (value: Organisation) => {
    const { updateForm, setOrganisationHeader } = this.props;
    setOrganisationHeader(value.id);
    updateForm({
      organisationId: value && value.id,
      hasVirtualBranch: value && value.hasVirtualBranch
    })
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchValue,
      }, () => {
        if (searchValue && searchValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  handleLoadOptions = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  render() {
    const { organisations, form } = this.props;
    const { searchValue } = this.state;
    const isOrganisationEdited = Object.keys(form).includes("organisationId");
    const isOrganisationSelected = isOrganisationEdited && form.organisationId && form.organisationId.length;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{`${"1. "}${locale.AddBranch.selectOrganisationTitle}`}</h3>
          <p>{locale.AddBranch.selectOrganisationText}</p>
        </div>
        <div className="patient-care-block">
          <AsyncSelect
            id="branch_organisation"
            name="branch_organisation"
            loadOptions={this.handleLoadOptions}
            defaultOptions={organisations}
            placeholder={locale.AddBranch.selectOrganisationPlaceholder}
            onChange={this.handleSelectOrganisation}
            onInputChange={this.handleSearchChange}
            inputValue={searchValue}
            classNamePrefix="patient-care"
            className={`patient-care-select ${isOrganisationEdited && !isOrganisationSelected ? "patient-care-select-error" : ""}`}
            openMenuOnFocus={true}
          />
          <Form>
            <FormItem type="item">
              {isOrganisationEdited && !isOrganisationSelected && <InputError message={locale.AddBranch.selectOrganisationError} />}
            </FormItem>
          </Form>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBranchOrganisation);
