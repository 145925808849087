import React, { Component } from "react";
import debounce from 'lodash.debounce';

const debounceTimeout = 300;

type Props = {
  zIndex: number,
  isOpenSlots: boolean,
  showSelectedSlot: boolean
};

export default class CustomView extends Component<Props, any> {

  state = {
    showSlotDebounce: debounce(this.props.showSelectedSlot, debounceTimeout),
  };

  onEventWrapperDivMouseOver = () => {
    const { zIndex } = this.props;
    const { showSlotDebounce } = this.state;
    showSlotDebounce(zIndex);
  };

  onEventWrapperDivMouseLeave = () => {
    const { showSlotDebounce } = this.state;
    showSlotDebounce(null);
  };

  render() {
    const { isOpenSlots } = this.props;
    return (
      <div
        onMouseOver={isOpenSlots ? this.onEventWrapperDivMouseOver : null}
        onMouseLeave={isOpenSlots ? this.onEventWrapperDivMouseLeave : null}
      >
        {isOpenSlots
          ? this.props.elements
          : {
              ...this.props.elements,
              props: {
                ...this.props.elements.props,
                title: this.props.elements.props.title && this.props.elements.props.title.slice(2)
              }
            }
          }
      </div>
    );
  }
}
