import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Overlay, Form, FormItem, Radio } from '@patient-access/ui-kit';
import {
  updateOrganisationSupportedFeatures,
  setSmartPharmacyOverlayVisibility,
  putOrganisationSupportedFeatures,
  updateOrganisationIsEnabledForAllBranches,
} from 'actions/organisationSupportedFeatures';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  isOverlayVisible: boolean,
  IsEnabledForAllBranches: boolean,
  branchCount: Number,
  spBranchCount: Number,
  handleChangeMode: () => any,
  setSmartPharmacyOverlayVisibility: (isOverlayVisible: boolean) => Action,
  putOrganisationSupportedFeatures: (organisationId: string) => Action,
  updateOrganisationIsEnabledForAllBranches: (IsEnabledForAllBranches: boolean) => Action,
  updateOrganisationSupportedFeatures: (featureName: string, data: any) => Action,
};

const mapStateToProps = state => ({
  data: state.organisationSupportedFeatures.data,
  dataClone: state.organisationSupportedFeatures.dataClone,
  IsEnabledForAllBranches: state.organisationSupportedFeatures.IsEnabledForAllBranches,
  isOverlayVisible: state.organisationSupportedFeatures.isOverlayVisible,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  setSmartPharmacyOverlayVisibility: (isOverlayVisible: boolean) => dispatch(setSmartPharmacyOverlayVisibility(isOverlayVisible)),
  putOrganisationSupportedFeatures: (organisationId: string) => dispatch(putOrganisationSupportedFeatures(organisationId)),
  updateOrganisationIsEnabledForAllBranches: (IsEnabledForAllBranches: boolean) => dispatch(updateOrganisationIsEnabledForAllBranches(IsEnabledForAllBranches)),
  updateOrganisationSupportedFeatures: (featureName, data) => dispatch(updateOrganisationSupportedFeatures(featureName, data))
});

class SmartPharmacyOverlay extends Component<Props> {
  render() {
    const {
      organisationId,
      data,
      dataClone,
      isOverlayVisible,
      IsEnabledForAllBranches,
      branchCount,
      spBranchCount,
      handleChangeMode,
      setSmartPharmacyOverlayVisibility,
      putOrganisationSupportedFeatures,
      updateOrganisationIsEnabledForAllBranches,
      updateOrganisationSupportedFeatures
    } = this.props;
    const featureSmartPharmacy = data.find(feature => feature.name === 'SmartPharmacy');
    const featureSmartPharmacyClone = dataClone.find(feature => feature.name === 'SmartPharmacy');
    
    const billingModel = data.find(feature => feature.name === 'SubscriptionModel');

    const renderRadioButtons = () => {
     return (
        <div style={{paddingTop: '20px'}}>
        <Form noValidate>
          <FormItem type="item">
            <Radio
              onClick={(e) => updateOrganisationIsEnabledForAllBranches(false)}
              id="disable-all"
              name="disable-all"
              value="disable-all"
              label={locale.SmartPharmacy.overlayRadio.disable}
              className=""
            />
             <Radio
              onClick={(e) => updateOrganisationIsEnabledForAllBranches(true)}
              id="enable-all"
              name="disable-all"
              value="enable-all"
              label={locale.SmartPharmacy.overlayRadio.enable}
              className=""
            />
          </FormItem>
        </Form>
        </div>
      );
    }

    const renderOverlayContentEnabledWithRadioButtons = () => {
      return (
        <Fragment>
          <div className="overlay-confirmation-header">
            <h1>{locale.SmartPharmacy.overlayEnable.title}</h1>
          </div>
          <div className="overlay-confirmation-content">
            <h4>{locale.SmartPharmacy.overlayEnable.body}</h4>
          </div>
          {renderRadioButtons()}
          <div className="overlay-confirmation-buttons">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.SmartPharmacy.overlayEnable.buttonBack}
              onClick={() => { setSmartPharmacyOverlayVisibility(false); updateOrganisationIsEnabledForAllBranches(undefined) }}
            />
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.SmartPharmacy.overlayEnable.buttonEnable}
              onClick={() => { updateOrganisationSupportedFeatures('SubscriptionModel', { name: 'SubscriptionModel', isEnabled: true }); putOrganisationSupportedFeatures(organisationId); setSmartPharmacyOverlayVisibility(false); handleChangeMode(); }}
              isDisabled={IsEnabledForAllBranches === undefined}
            />
          </div>
        </Fragment>
      );
    }

    const renderOverlayContentEnableForBranches = () => {
      const text = IsEnabledForAllBranches ? locale.SmartPharmacy.overlayEnableBranches : locale.SmartPharmacy.overlayDisableBranches;
      const branches = IsEnabledForAllBranches ? (branchCount - spBranchCount) : spBranchCount;
      const selectedBillingModel = billingModel.isEnabled ? locale.SmartPharmacy.billingModel.subscriptionRadio : locale.SmartPharmacy.billingModel.transactionRadio;
      return (
        <Fragment>
          <div className="overlay-confirmation-header">
            <h1>{text.title(branches, selectedBillingModel)}</h1>
          </div>
          <div className="overlay-confirmation-content">
            <h4>{text.body}</h4>
          </div>
          <div className="overlay-confirmation-buttons">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={text.buttonBack}
              onClick={() => { setSmartPharmacyOverlayVisibility(false); }}
            />
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={text.button(branches)}
              onClick={() => { putOrganisationSupportedFeatures(organisationId); setSmartPharmacyOverlayVisibility(false); handleChangeMode(); }}
            />
          </div>
        </Fragment>
      );
    }

    const renderOverlayContentEnable = () => {
      if (featureSmartPharmacyClone && featureSmartPharmacyClone.isEnabled) {
       return renderOverlayContentEnableForBranches();
      }
      return renderOverlayContentEnabledWithRadioButtons();
    }

    const renderOverlayContentDisable = () => {
      return (
        <Fragment>
          <div className="overlay-confirmation-header">
            <h1>{locale.SmartPharmacy.overlayDisable.title}</h1>
          </div>
          <div className="overlay-confirmation-content">
            <h4>{locale.SmartPharmacy.overlayDisable.body}</h4>
          </div>
          <div className="overlay-confirmation-buttons">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.SmartPharmacy.overlayDisable.buttonBack}
              onClick={() => { setSmartPharmacyOverlayVisibility(false); }}
            />
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.SmartPharmacy.overlayDisable.buttonDisable}
              onClick={() => { putOrganisationSupportedFeatures(organisationId); setSmartPharmacyOverlayVisibility(false); handleChangeMode(); }}
            />
          </div>
        </Fragment>
      );
    }

    if (!isOverlayVisible) {
      return null;
    }

    return (
      <Overlay background="dark" type="confirmation" isOpen={isOverlayVisible} showCloseButton={false} doClose={() => {}}>
        {featureSmartPharmacy.isEnabled ? renderOverlayContentEnable() : renderOverlayContentDisable()}
      </Overlay>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartPharmacyOverlay);
