import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconTaskError extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="M3,12 C3,14.21 3.91,16.2 5.36,17.64 L3.85,19.15 C3.54,19.46 3.76,20 4.21,20 L8.5,20 C8.78,20 9,19.78 9,19.5 L9,15.21 C9,14.76 8.46,14.54 8.15,14.86 L6.76,16.25 C5.68,15.15 5,13.66 5,12 C5,9.61 6.4,7.54 8.43,6.58 C8.77,6.42 9,6.11 9,5.74 L9,5.55 C9,4.87 8.29,4.44 7.68,4.73 C4.92,5.99 3,8.77 3,12 Z M11,17 L13,17 L13,15 L11,15 L11,17 Z M19.79,4 L15.5,4 C15.22,4 15,4.22 15,4.5 L15,8.79 C15,9.24 15.54,9.46 15.85,9.14 L17.24,7.75 C18.32,8.85 19,10.34 19,12 C19,14.39 17.6,16.46 15.57,17.42 C15.23,17.58 15,17.89 15,18.26 L15,18.44 C15,19.12 15.71,19.55 16.32,19.26 C19.08,18.01 21,15.23 21,12 C21,9.79 20.09,7.8 18.64,6.36 L20.15,4.85 C20.46,4.54 20.24,4 19.79,4 L19.79,4 Z M12,13 C12.55,13 13,12.55 13,12 L13,8 C13,7.45 12.55,7 12,7 C11.45,7 11,7.45 11,8 L11,12 C11,12.55 11.45,13 12,13 Z" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-task-error ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconTaskError;
