import * as TaskManagerConstants from 'constants/TaskManagerConstants';

type TaskManager = {
  // TODO
}

const initialState: TaskManager = {
  tasksList: {
    data: [],
    isInitialLoading: true,
    isLoading: false,
    isError: false,
  },
  overlay: {
    isVisible: false,
    type: '',
    isOverlayBranchesVisible: false,
  },
  taskDetail: {
    data: {},
    isLoading: false,
    isError: false,
  },
  // userData: {},
  userActions: {
    isPostCancelLoading: false,
    isPostCancelError: false,
    isPostDismissLoading: false,
    isPostDismissError: false,
    isPostTryagainLoading: false,
    isPostTryagainError: false
  },
  isVisible: false,
};

export default (localState: TaskManager = initialState, action: Action): TaskManager => {
  switch (action.type) {
    // TASK_MANAGER_IS_VISIBLE
    case TaskManagerConstants.SET_TASK_MANAGER_IS_VISIBLE:
      return {
        ...localState,
        isVisible: action.payload,
      };

    // OVERLAY
    case TaskManagerConstants.SET_TASK_MANAGER_OVERLAY_TYPE:
      return {
        ...localState,
        overlay: {
          ...localState.overlay,
          type: action.payload
        },
      };
    case TaskManagerConstants.SET_TASK_MANAGER_OVERLAY_VISIBLE:
      return {
        ...localState,
        overlay: {
          ...localState.overlay,
          isVisible: action.payload
        },
      };
    case TaskManagerConstants.SET_TASK_MANAGER_BRANCHES_OVERLAY_VISIBLE:
      return {
        ...localState,
        overlay: {
          ...localState.overlay,
          isOverlayBranchesVisible: action.payload
        },
      };

    // TASKS_LIST
    case TaskManagerConstants.GET_TASK_MANAGER_TASKS_LIST_PENDING:
      return {
        ...localState,
        tasksList: {
          ...localState.tasksList,
          isLoading: true,
          isError: false,
        }
      };
    case TaskManagerConstants.GET_TASK_MANAGER_TASKS_LIST_SUCCESS:
      return {
        ...localState,
        tasksList: {
          ...localState.tasksList,
          isInitialLoading: false,
          isError: false,
        }
      };
    case TaskManagerConstants.GET_TASK_MANAGER_TASKS_LIST_ERROR:
      return {
        ...localState,
        tasksList: {
          ...localState.tasksList,
          isInitialLoading: false,
          isError: true,
        }
      };
    case TaskManagerConstants.SET_TASK_MANAGER_TASKS_LIST_DATA:
      return {
        ...localState,
        tasksList: {
          ...localState.tasksList,
          data: action.payload,
        }
      };

    // TASK_DETAIL
    case TaskManagerConstants.GET_TASK_MANAGER_TASK_DETAIL_PENDING:
      return {
        ...localState,
        taskDetail: {
          ...localState.taskDetail,
          isLoading: true,
          isError: false,
        }
      };
    case TaskManagerConstants.GET_TASK_MANAGER_TASK_DETAIL_SUCCESS:
      return {
        ...localState,
        taskDetail: {
          ...localState.taskDetail,
          isLoading: false,
          isError: false,
        }
      };
    case TaskManagerConstants.GET_TASK_MANAGER_TASK_DETAIL_ERROR:
      return {
        ...localState,
        taskDetail: {
          ...localState.taskDetail,
          isLoading: false,
          isError: true,
        }
      };
    case TaskManagerConstants.SET_TASK_MANAGER_TASK_DETAIL_DATA:
      return {
        ...localState,
        taskDetail: {
          ...localState.taskDetail,
          data: action.payload,
        }
      };

    // CANCEL_TASKS
    case TaskManagerConstants.CANCEL_TASK_MANAGER_TASK_PENDING:
      return {
        ...localState,
        isPostCancelLoading: true,
        isPostCancelError: false
      };
    case TaskManagerConstants.CANCEL_TASK_MANAGER_TASK_SUCCESS:
      return {
        ...localState,
        isPostCancelLoading: false,
        isPostCancelError: false
      };
    case TaskManagerConstants.CANCEL_TASK_MANAGER_TASK_ERROR:
      return {
        ...localState,
        isPostCancelLoading: false,
        isPostCancelError: true
      };
    
    // DISMISS_TASKS
    case TaskManagerConstants.DISMISS_TASK_MANAGER_TASK_PENDING:
      return {
        ...localState,
        isPostDismissLoading: true,
        isPostDismissError: false
      };
    case TaskManagerConstants.DISMISS_TASK_MANAGER_TASK_SUCCESS:
      return {
        ...localState,
        isPostDismissLoading: false,
        isPostDismissError: false
      };
    case TaskManagerConstants.DISMISS_TASK_MANAGER_TASK_ERROR:
      return {
        ...localState,
        isPostDismissLoading: false,
        isPostDismissError: true
      };

    // TRYAGAIN_TASKS
    case TaskManagerConstants.RETRY_TASK_MANAGER_TASK_PENDING:
      return {
        ...localState,
        isPostTryagainLoading: true,
        isPostTryagainError: false
      };
    case TaskManagerConstants.RETRY_TASK_MANAGER_TASK_SUCCESS:
      return {
        ...localState,
        isPostTryagainLoading: false,
        isPostTryagainError: false
      };
    case TaskManagerConstants.RETRY_TASK_MANAGER_TASK_ERROR:
      return {
        ...localState,
        isPostTryagainLoading: false,
        isPostTryagainError: true
      };
    
    // DEFAULT
    default:
      return localState;
  }
};
