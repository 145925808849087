import * as AvailabilityRulesConstants from 'constants/AvailabilityRulesConstants';
import type { AvailabilityRules } from 'types/availabilityRules';

const initialState: AvailabilityRules = {
  data: {},
  dataClone: {},
  isTimeOffOverlayVisible: false,
  isGetLoading: false,
  isPutLoading: false,
  isGetError: false,
  isPutError: false
};

export default (localState: AvailabilityRules = initialState, action: Action): AvailabilityRules => {
  switch (action.type) {
    // GET
    case AvailabilityRulesConstants.GET_AVAILABILITY_RULES_PENDING:
      return {
        ...localState,
        isGetLoading: true,
        isGetError: false
      };
    case AvailabilityRulesConstants.GET_AVAILABILITY_RULES_SUCCESS:
      return {
        ...localState,
        isGetLoading: false,
        isGetError: false
      };
    case AvailabilityRulesConstants.GET_AVAILABILITY_RULES_ERROR:
      return {
        ...localState,
        isGetLoading: false,
        isGetError: true
      };
    case AvailabilityRulesConstants.SET_AVAILABILITY_RULES_DATA:
      return {
        ...localState,
        data: {
          ...action.payload
        }
      };
    case AvailabilityRulesConstants.SET_AVAILABILITY_RULES_DATA_CLONE:
      return {
        ...localState,
        dataClone: {
          ...action.payload
        }
      };

    // PUT
    case AvailabilityRulesConstants.PUT_AVAILABILITY_RULES_PENDING:
      return {
        ...localState,
        isPutLoading: true,
        isPutError: false
      };
    case AvailabilityRulesConstants.PUT_AVAILABILITY_RULES_SUCCESS:
      return {
        ...localState,
        isPutLoading: false,
        isPutError: false
      };
    case AvailabilityRulesConstants.PUT_AVAILABILITY_RULES_ERROR:
      return {
        ...localState,
        isPutLoading: false,
        isPutError: true
      };

    // UPDATE
    case AvailabilityRulesConstants.SET_TIME_OFF_DATA_UPDATE:
      return {
        ...localState,
        data: {
          ...localState.data,
          timeOff: {
            ...localState.data.timeOff,
            ...action.payload
          }
        }
      };

    // OVERLAY
    case AvailabilityRulesConstants.SET_TIME_OFF_OVERLAY_VISIBILITY:
      return {
        ...localState,
        isTimeOffOverlayVisible: action.payload,
      };
    default:
      return localState;
  }
};
