import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";

import { updateForm } from 'actions/form';
import type { Organisation } from 'types/organisation';
import type { Action } from "types/actions";
import { modifyBranchesList } from "helpers/common";

import locale from 'service/locale';

type Props = {
  form: any,
  updateForm: (date: any) => any,
  organisationDetails: Organisation,
  handleBranchChange: (branchId: String) => Action
};

const mapStateToProps = state => ({
  form: state.form,
  organisationDetails: state.organisationDetails
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});


class AddOrganisationBranches extends Component<Props, State> {

  handleBranchChange = value => {
    const { handleBranchChange, updateForm } = this.props;
    
    handleBranchChange(value.id);
    updateForm({
      selectedBranchId: value && value.id,
    })
  };

  render() {
    const { organisationDetails } = this.props;
    const branchesList = organisationDetails ? modifyBranchesList({ items: organisationDetails.branches || [] }) : [];

    return (
      <Fragment>
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="branch_select"
                  message={locale.AddNewCalendar.branchTitle}
                  size="small"
                />
              </FormItem>
            </Form>
            <Select
              id="branch-select"
              name="branch-select"
              options={branchesList.filter(branch => branch.value)}
              placeholder={locale.AddNewCalendar.placeholderBranch}
              onChange={this.handleBranchChange}
              classNamePrefix="patient-care"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisationBranches);
