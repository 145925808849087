import React, { Component, Fragment } from 'react';
import { Button } from '@patient-access/ui-kit';
import { FeatureContentBreadcrumbs } from 'components/Share/feature';
import { SkeletonSearchFilter } from 'components/Share/skeleton';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  branchId: string,
  isBranchMember: boolean,
  isBreadcrumbsVisible: boolean,
};

class FilterServicesSkeleton extends Component<Props> {
  render() {
    const { organisationId, branchId, isBranchMember, isBreadcrumbsVisible } = this.props;

    const renderBreadcrumbs = () => {
      if (isBreadcrumbsVisible) {
        return (
          <div className="row">
            <FeatureContentBreadcrumbs organisationId={organisationId} branchId={branchId} />
          </div>
        );
      }
      return null;
    }

    const renderAddNewServiceButton = () => {
      if (!isBranchMember) {
        return (
          <div className="column">
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.BranchServicesRedesign.button.addService}
              onClick={() => {}}
              isLoading={true}
            />
          </div>
        );
      }
      return null;
    }

    return (
      <Fragment>
        {renderBreadcrumbs()}
        <div className="row">
          <div className="column column-full">
            <SkeletonSearchFilter />
          </div>
          {renderAddNewServiceButton()}
        </div>
      </Fragment>
    );
  }
}

export default FilterServicesSkeleton;
