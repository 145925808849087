import React, { Fragment, useState } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import {
  Form,
  IconCog,
  IconPerson,
  IconPhone,
  IconVideo,
} from "@patient-access/ui-kit";
import { formatCurrency } from "helpers/formatData";
import IconSort from "svg/IconSort";
import locale from "service/locale";
import "./styles.scss";

export const dataServicesEdit = [
  {
    branchName: "Branch 01",
    servicePrice: 14,
    paused: false,
    duration: 15,
    cutOff: 24,
    type: [0, 1, 2],
  },
  {
    branchName: "Branch 02",
    servicePrice: 21.5,
    paused: true,
    duration: 25,
    cutOff: 24,
    type: [0, 2],
    isHighlighted: true,
  },
  {
    branchName: "Branch 03",
    servicePrice: 9,
    paused: false,
    duration: 5,
    cutOff: 48,
    type: [1],
  },
  {
    branchName: "Branch 04",
    servicePrice: 14,
    paused: false,
    duration: 15,
    cutOff: 24,
    type: [0],
  },
  {
    branchName: "Branch 05",
    servicePrice: 22,
    paused: false,
    duration: 30,
    cutOff: 24,
    type: [0],
  },
  {
    branchName: "Branch 06",
    servicePrice: 0,
    paused: false,
    duration: 25,
    cutOff: 72,
    type: [0],
  },
  {
    branchName: "Branch 07",
    servicePrice: 10,
    paused: false,
    duration: 45,
    cutOff: 48,
    type: [0, 1, 2],
  },
  {
    branchName: "Branch 08",
    servicePrice: 20,
    paused: false,
    duration: 25,
    cutOff: 24,
    type: [0, 2],
  },
  {
    branchName: "Branch 09",
    servicePrice: 21,
    paused: true,
    duration: 35,
    cutOff: 72,
    type: [0, 2],
    isHighlighted: true,
  },
  {
    branchName: "Branch 10",
    servicePrice: 12,
    paused: true,
    duration: 45,
    cutOff: 36,
    type: [0, 1],
    isHighlighted: true,
  },
  {
    branchName: "Branch 11",
    servicePrice: 18,
    paused: false,
    duration: 15,
    cutOff: 12,
    type: [0],
  },
  {
    branchName: "Branch 12",
    servicePrice: 20.5,
    paused: false,
    duration: 5,
    cutOff: 36,
    type: [1, 2],
  },
  {
    branchName: "Branch 13",
    servicePrice: 11,
    paused: false,
    duration: 25,
    cutOff: 48,
    type: [0],
  },
  {
    branchName: "Branch 14",
    servicePrice: 51,
    paused: false,
    duration: 10,
    cutOff: 24,
    type: [1],
  },
  {
    branchName: "Branch 15",
    servicePrice: 120,
    paused: false,
    duration: 15,
    cutOff: 72,
    type: [0, 1],
  },
  {
    branchName: "Branch 16",
    servicePrice: 41,
    paused: false,
    duration: 20,
    cutOff: 24,
    type: [0, 1, 2],
  },
  {
    branchName: "Branch 17",
    servicePrice: 32,
    paused: false,
    duration: 30,
    cutOff: 48,
    type: [0, 1],
  },
  {
    branchName: "Branch 18",
    servicePrice: 22,
    paused: false,
    duration: 10,
    cutOff: 24,
    type: [0, 2],
  },
  {
    branchName: "Branch 19",
    servicePrice: 18,
    paused: false,
    duration: 5,
    cutOff: 72,
    type: [1, 2],
  },
  {
    branchName: "Branch 20",
    servicePrice: 14,
    paused: false,
    duration: 45,
    cutOff: 24,
    type: [0, 1, 2],
  },
];

export const columnsServicesEdit = [
  {
    className: "cell-main",
    Header:
      locale.OrganisationServices.table.branchesSummary.headers.branchName,
    accessor: "branchName",
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: "cell-default",
    Header:
      locale.OrganisationServices.table.branchesSummary.headers.servicePrice,
    accessor: "servicePrice",
    Cell: ({ cell: { value } }) => <span>{formatCurrency(value)}</span>,
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.status,
    accessor: "paused",
    Cell: ({ cell: { value } }) => <span>{value ? "Paused" : "Active"}</span>,
    sortType: "basic",
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.duration,
    accessor: "duration",
    Cell: ({ cell: { value } }) => <span>{value} min</span>,
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.cutOff,
    accessor: "cutOff",
    Cell: ({ cell: { value } }) => <span>{value} hours</span>,
  },
  {
    className: "cell-default",
    Header: locale.OrganisationServices.table.branchesSummary.headers.modes,
    accessor: "type",
    Cell: ({ cell: { value } }) => (
      <i className="group">
        {value.map((item) => (
          <Fragment key={item}>
            {item === 0 ? <IconPerson outline={false} /> : null}
            {item === 1 ? <IconPhone outline={false} /> : null}
            {item === 2 ? <IconVideo outline={false} /> : null}
          </Fragment>
        ))}
      </i>
    ),
    sortType: "basic",
  },
];

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="input-checkbox input-checkbox-medium">
        <label>
          <input type="checkbox" ref={resolvedRef} {...rest} />
          <span>&nbsp;</span>
        </label>
      </div>
    );
  }
);

export function TableServicesEdit({ columns, data, props }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
    // selectedFlatRows,
    // state: { selectedRowIds },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: "branchName", desc: false }],
        hiddenColumns: ["selection"],
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          className: "cell-checkbox",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
          disableSortBy: true,
        },
        ...columns,
      ]);
    }
  );

  const [isMenuOn, setMenuOn] = useState(false);

  React.useEffect(() => {
    if (props.form.isSelectionVisible) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(["selection"]);
    }
  }, [props.form.isSelectionVisible, setHiddenColumns]);

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div
            className="react-table-settings-background"
            onClick={() => {
              setMenuOn(!isMenuOn);
            }}
          />
          <div className="react-table-settings-menu">
            <h2>{locale.OrganisationServices.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map((column) => {
                if (column.id === "selection") return null;
                return (
                  <div
                    className="input-checkbox input-checkbox-small"
                    key={column.id}
                  >
                    <label htmlFor={column.id}>
                      <input
                        id={column.id}
                        name={column.id}
                        type="checkbox"
                        disabled={
                          column.id === "branchName" ? "disabled" : null
                        }
                        {...column.getToggleHiddenProps()}
                      />
                      <span>
                        {column.id === "selection"
                          ? "Selection"
                          : column.Header}
                      </span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div
          className="react-table-settings-button"
          onClick={() => {
            setMenuOn(!isMenuOn);
          }}
        >
          <i>
            <IconCog outline={false} />
          </i>
        </div>
      </div>
      <Form noValidate>
        <table
          className="react-table react-table-classNameFour spacing-medium"
          columns={visibleColumns.length}
          {...getTableProps()}
        >
          <thead className="sticky grey">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className={column.className}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sorting-down"
                              : "sorting-up"
                            : "sorting-none"
                        }
                      >
                        {column.id === "selection" ? (
                          <Fragment>{column.render("Header")}</Fragment>
                        ) : (
                          <span>{column.render("Header")}</span>
                        )}
                        {column.disableSortBy ? null : (
                          <i>
                            <IconSort outline={false} />
                          </i>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={row.original.isHighlighted ? "highlighted" : ""}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={cell.column.className}
                        {...cell.getCellProps()}
                      >
                        {cell.column.Header ===
                        locale.OrganisationServices.table.branchesSummary
                          .headers.branchName ? (
                          <a
                            href="#sidebar"
                            title={cell.value}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {cell.render("Cell")}
                          </a>
                        ) : (
                          <Fragment>{cell.render("Cell")}</Fragment>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/*
        <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
        <pre>
          <code>{JSON.stringify({ selectedRowIds: selectedRowIds, 'selectedFlatRows[].original': selectedFlatRows.map(d => d.original) }, null, 2)}</code>
        </pre>
        */}
      </Form>
    </Fragment>
  );
}
