import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Checkbox } from '@patient-access/ui-kit';
import { CalendarFiltersSection, CalendarFiltersTitle, CalendarFiltersBody, CalendarFiltersBodyEmpty } from '../wrappers';
import { ButtonAdd, ButtonToggle } from '../button';
import SectionServicesItem from './SectionServicesItem';
import { filterEventsByServices } from 'actions/calendarView';
import { setActiveAdminMenu } from 'actions/profile';
import type { Action } from 'types/actions';
import * as RolesConstants from 'constants/RolesConstants';
import * as RoutesConstants from 'constants/RoutesConstants';
import locale from 'service/locale';

type Props = {
  currentRole: Object,
  organisationId: string,
  branchId: string,
  servicesList: any[],
  serviceFilters: any[],
  filterEventsByServices: (calendarId: string) => any,
  setActiveAdminMenu: (status: boolean) => any,
};

type State = {
  isRedirect: boolean,
  isSectionExpanded: boolean,
}

const mapStateToProps = state => ({
  currentRole: state.roles.profileCurrentRole,
  organisationId: state.router.organisationId,
  branchId: state.router.branchId,
  servicesList: state.branchDetails.services,
  serviceFilters: state.calendarView.serviceFilters,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  setActiveAdminMenu: (status) => dispatch(setActiveAdminMenu(status)),
  filterEventsByServices: calendarId => dispatch(filterEventsByServices(calendarId)),
});

class SectionServices extends Component<Props, State> {
  state = {
    isRedirect: false,
    isSectionExpanded: true, // TODO: Redux
  }

  handleAddService = (e: any) => {
    e && e.preventDefault();
    const { setActiveAdminMenu } = this.props;
    this.setState({ isRedirect: true }, () => { setActiveAdminMenu(true); });
  };

  handleSelectAll = (e: any) => {
    const { filterEventsByServices, serviceFilters, servicesList } = this.props;
    if (serviceFilters.length === 0) {
      servicesList.forEach(service => {
        if (![...serviceFilters].find(filteredService => filteredService === service.id))
          filterEventsByServices(service.id);
      })
    } else {
      [...serviceFilters].forEach(filteredService => filterEventsByServices(filteredService));
    }
  };

  render() {
    const { currentRole, organisationId, branchId, servicesList, serviceFilters } = this.props;
    const { isRedirect, isSectionExpanded } = this.state;
    const isAbleToAddAndEdit = currentRole.role !== RolesConstants.BRANCH_MEMBER;
    const noSelectedServices = servicesList.length - serviceFilters.length;

    const renderServicesList = () => {
      if (servicesList && servicesList.length > 0) {
        return (
          <Fragment>
            {servicesList.map(service => (
              <SectionServicesItem
                key={service.id}
                service={service}
                organisationId={organisationId}
                branchId={branchId}
                isAbleToAddAndEdit={isAbleToAddAndEdit}
              />
            ))}
          </Fragment>
        );
      }
      return (
        <CalendarFiltersBodyEmpty>
          <p>{locale.Sidebars.noBranches}</p>
          {isAbleToAddAndEdit ? <Button buttonType="secondary" buttonLength="full" buttonSize="small" messageKey="some-key" defaultMessage={locale.Sidebars.addServices} onClick={this.handleAddService} /> : null}
        </CalendarFiltersBodyEmpty>
      );
    }

    if (isRedirect) {
      return <Redirect push to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.SERVICES}`} />;
    }
    return (
      <CalendarFiltersSection>
        <CalendarFiltersTitle>
          <Checkbox
            size="small"
            id="section_services"
            name="section_services"
            value={serviceFilters.length === 0 ? 'true' : 'false'}
            label={`Services (${noSelectedServices})`}
            onChange={this.handleSelectAll}
            checked={serviceFilters.length === 0}
          />
          {isAbleToAddAndEdit ? <ButtonAdd onClick={this.handleAddService} /> : null}
          <ButtonToggle onClick={(e) => { e.preventDefault(); this.setState({ isSectionExpanded: !isSectionExpanded }); }} isExpanded={isSectionExpanded} />
        </CalendarFiltersTitle>
        <CalendarFiltersBody isExpanded={isSectionExpanded}>
          {renderServicesList()}
        </CalendarFiltersBody>
      </CalendarFiltersSection>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionServices);
