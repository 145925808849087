import moment from "moment";
import * as DateConstants from "constants/DateTimeConstants";

import { setUsersCookie, getUsersCookie } from "service/cookie";

const timeFormat = "HH:mm";

export const formatDOBToString = (dateOfBirth: string): string => {
  const convertedToDateObject = new Date(Date.parse(dateOfBirth));
  return convertedToDateObject.toLocaleDateString("en-gb");
};

export const formatSearchDOB = (dob: string): string => {
  let dob_string = moment(dob, "DD/MM/YYYY").format('YYYY-MM-DD');
  return dob_string;
};

export const getAppointmentDateAndTime = (
  startTime: string,
  endTime: string
): string => {

  // in order to avoid timezone issue
  const startDateStringWithoutTimezone = startTime.substring(0, 19);
  const endDateStringWithoutTimezone = endTime.substring(0, 19);

  const startDateFormatted = moment
    .utc(startDateStringWithoutTimezone)
    .format(`dddd DD MMMM YYYY, ${timeFormat}`);

  const endDateFormatted = moment
    .utc(endDateStringWithoutTimezone)
    .format(timeFormat);

  return `${startDateFormatted} - ${endDateFormatted}`;
};

export const getAppointmentTime = (
  startTime: string,
  endTime: string
): string => {

  // in order to avoid timezone issue
  const startDateStringWithoutTimezone = startTime.substring(0, 19);
  const endDateStringWithoutTimezone = endTime.substring(0, 19);

  const startDateFormatted = moment
    .utc(startDateStringWithoutTimezone)
    .format(timeFormat);

  const endDateFormatted = moment
    .utc(endDateStringWithoutTimezone)
    .format(timeFormat);

  return `${startDateFormatted} - ${endDateFormatted}`;
};

export const formatListAsOptions = (list: any[]): any[] => {
  return list
    ? list.map(item => {
      return {
        ...item,
        value: item.id,
        label: item.name,
        duration: item.duration ? item.duration : 5,
        price: item.price ? item.price : 0,
        slotId: item.slotId,
      };
    })
    : [];
};

export const formatItemAsOption = (item: Object): Object => {
  return item
    ? {
      value: item.id,
      label: item.name,
      duration: item.duration ? item.duration : 5,
      price: item.price ? item.price : 0
    }
    : {};
};

export const formatTimeValue = (value: string) => {
  const digits = value.replace(/\D/g, "");
  switch (digits.length) {
    case 0:
      return "";
    case 1:
      if (digits >= "0" && digits <= "2") return digits;
      else return null;
    case 2:
      if (
        (digits[0] === "2" && digits[1] >= "0" && digits[1] <= "3") ||
        (digits[0] !== "2" && digits[1] >= "0" && digits[1] <= "9")
      )
        return digits;
      else return null;
    case 3:
      if (digits[2] >= "0" && digits[2] <= "5")
        return digits.slice(0, 2) + ":" + digits[2];
      else return null;
    case 4:
      if (digits[3] >= "0" && digits[3] <= "9")
        return digits.slice(0, 2) + ":" + digits.slice(2);
      else return null;
    default:
      return null;
  }
};

export const formatOpeningHours = (data: any[] = []) => {
  const days = [];

  if (data.length === 0) return [];
  data.forEach(date => {
    const { timesSessions, day } = date;

    if (timesSessions.length) {
      timesSessions.map(session => {
        return days.push({
          dayOfWeek: day,
          isOpen: true,
          startTime: session.from,
          endTime: session.to
        });
      });
    }
  });

  return days;
};

export const formatCalendarAvailablePeriods = (data: any[]) => {
  const days = [];

  if (data.length === 0) return [];
  data.forEach(date => {
    const { periods, dayOfWeek } = date;

    if (periods.length) {
      periods.map(period => {
        return days.push({
          dayOfWeek,
          startTime: period.start,
          endTime: period.end
        });
      });
    }
  });

  return days;
};

export const formatOpeningHoursRevert = (data: any) => {
  const openingHours = [];

  if (data.length === 0) return [];
  data.forEach(date => {
    const dayIndex = openingHours.findIndex(day => day.day === date.dayOfWeek);

    if (dayIndex !== -1) {
      openingHours[dayIndex].timesSessions.push({
        from: date.startTime,
        to: date.endTime
      });
    } else {
      openingHours.push({
        day: date.dayOfWeek,
        timesSessions: date.isOpen
          ? [{
            from: date.startTime,
            to: date.endTime
          }]
          : []
      });
    }
  });

  return openingHours;
};

export const removeEmptyTimesIntervals = (data: any) => {
  const openingHours = [];

  if (data.length === 0) return [];
  data.forEach((date, index) => {
    const copiedSessions = [];

    date.timesSessions.forEach(session => {
      if (session.from === "" && session.to === "") return;
      copiedSessions.push(session);
    });

    openingHours.push({
      day: date.day,
      timesSessions: copiedSessions
    })
  });

  return openingHours;
};

export const formatPriceToPounds = (
  amount,
  decimalCount,
  decimal,
  thousands
): string => {

  if (amount === null || isNaN(amount))
    return;
  // get decimal count
  decimalCount = isNaN((decimalCount = Math.abs(decimalCount)))
    ? 2
    : decimalCount;
  // get divider for decimal number
  decimal = decimal === undefined ? "." : decimal;
  // get divider for thousands
  thousands = thousands === undefined ? "," : thousands;
  let i = String(
    parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)))
  );
  //TODO: figure out what does it mean
  // eslint-disable-next-line
  let j = (j = i.length) > 3 ? j % 3 : 0;
  // get float number
  const value =
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimal +
      Math.abs(amount - i)
        .toFixed(decimalCount)
        .slice(2)
      : "");
  return value;
};

export const addFilterToCookie = (email: string, data: any) => {
  let users = null;
  const cookie = getUsersCookie();
  if (cookie.length !== 0) {
    users = JSON.parse(cookie);
  }

  if (!users)
    users = [
      {
        user: email,
        [data.param]: data.value
      }
    ];
  else {
    const existingUserIndex = users.findIndex(user => user.user === email);
    if (existingUserIndex !== -1) {
      users[existingUserIndex][data.param] = data.value;
    }
    else {
      users.push(
        {
          user: email,
          [data.param]: data.value
        }
      );
    }
  }
  setUsersCookie(JSON.stringify(users));
};

export const formatDate = (d: any, format: String = DateConstants.dateFormat) => {
  return moment(d).format(format)
};

export const beforeMaskedValueChange = (newState, oldState, userInput) => {
  let { value } = newState;
  let selection = newState.selection;

  if (userInput === "2" && parseInt(value[1], 10) > 9) {
    value = value[0] + "0" + value.slice(2);
  }

  return {
    value,
    selection
  };
};

export const getAppointmentDuration = (
  startTime: string,
  endTime: string): string => {

  const startDateStringWithoutTimezone = startTime.substring(0, 19);
  const endDateStringWithoutTimezone = endTime.substring(0, 19);

  const start = moment(startDateStringWithoutTimezone);
  const end = moment(endDateStringWithoutTimezone);
  return end.diff(start, 'minutes');
}

export const formatCurrency = (value) => {
  const formattedValue = Math.abs(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  if (value >= 0) return `£${formattedValue}`;
  return `-£${formattedValue}`;
}

export const formatAsCurrency = (value) => {
  const formattedValue = Math.abs(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  if (value >= 0) return `${formattedValue}`;
  return `-${formattedValue}`;
}

export const formatNumber = (value) => {
  return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').slice(0, -3);
}

export const formatNumberAbbreviate = (value) => {
  if (value >= 1000000) return `${(value / 1000000).toFixed(1).replace(/\.0$/, '')}m`;
  if (value >= 1000) return `${(value / 1000).toFixed(1).replace(/\.0$/, '')}k`;
  return value;
}

export const formatPercentage = (value, total, decimals) => {
  const percentage = (value * 100 / total);
  return `${percentage.toFixed(decimals)}%`;
}

export const formatPercentageString = (value, decimals) => {
  return `${value.toFixed(decimals)}%`;
}

export const formatOrganisationListAsOptions = (list: any[]): any[] => {
  return list
    ? list.map(item => {
      return {
        ...item,
        value: item.id,
        label: item.companyName
      };
    })
    : [];
};
