import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ScrollTo } from 'react-scroll';
import Wrapper from '../Wrapper/Wrapper';

type State = {
  scrollTop: number,
};

class FooterSticky extends Component<State> {
  state = {
    scrollTop: 0,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.scrollHandler);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  };

  scrollHandler = () => {
    this.setState({ scrollTop: window.pageYOffset || document.documentElement.scrollTop });
  };

  renderFooterClass() {
    const { scrollTop } = this.state;
    if (scrollTop !== 0) return 'footer-sticky footer-sticky-scroll';
    return 'footer-sticky';
  };

  render() {
    return (
      <footer className={this.renderFooterClass()}>
        <Wrapper>
          <p><FormattedMessage id="landing.footer.sticky.copy" /></p>
          <ScrollTo to="section-more" className="ui-kit-button ui-kit-button-secondary ui-kit-button-medium" offset={-80} duration={500} smooth="easeInOutCubic">
            <span><FormattedMessage id="landing.footer.sticky.button" /></span>
          </ScrollTo>
        </Wrapper>
      </footer>
    );
  }
}

export default FooterSticky;
