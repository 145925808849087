import React, { Component } from 'react';
import { Markup as UiMarkup } from '@patient-access/ui-kit';
import Markdown from 'react-markdown';

type Props = {
  text: string,
};


export default class Markup extends Component<Props, State> {
  render() {
    const { text } = this.props;
    return (
      <UiMarkup>
        <Markdown source={text} />
      </UiMarkup>
    )
  }
}