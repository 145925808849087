import React, { Component } from 'react';
import './styles/reports-tooltip.scss';

type Props = {
  children: any,
};

class ReportsTooltip extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="reports-tooltip">
        {children}
      </div>
    );
  }
};

export default ReportsTooltip;
