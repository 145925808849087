import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormItem } from "@patient-access/ui-kit";

import { validateAddUserForm } from "helpers/validateData";
import { updateForm } from 'actions/form';

import locale from "service/locale";
import "./styles.scss";

type Props = {
  form: any,
  isDataChanged: boolean,
  handleDiscardChanges: () => any,
  handleSaveAddUser: () => any,
  handleCloseAddUser: () => any,
  isCreationPending: boolean,
  updateForm: (date: any) => any,
};

const mapStateToProps = state => ({
  form: state.form,
  isCreationPending: state.userDetails.isCreationPending
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

export class AddUserButtons extends Component<Props> {

  handleValidateForm = () => {
    const { form, updateForm } = this.props;

    updateForm({
      ...form,
      new_user_firstName: form.new_user_firstName || "",
      new_user_email: form.new_user_email || "",
      new_user_role: form.new_user_role || {}
    });
  };

  render() {
    const {
      form,
      isDataChanged,
      handleDiscardChanges,
      handleSaveAddUser,
      handleCloseAddUser,
      isCreationPending,
      customClassName
    } = this.props;
    const isFormValid = validateAddUserForm(form);

    return (
      <div className={`patient-care-modal-footer ${customClassName}`}>
        <FormItem type="buttons">
          <div className="patient-care-row-align-right">
            <div className="patient-care-buttons-group">
              <Button
                buttonType="blueline"
                messageKey="cancel-btn"
                defaultMessage={locale.AddUser.addUserButtonCancel}
                onClick={isDataChanged ? handleDiscardChanges : handleCloseAddUser}
                isLoading={isCreationPending}
                data-id="cancel-changes-btn"
              />
              <Button
                buttonType="secondary"
                messageKey="save-changes-btn"
                defaultMessage={locale.AddUser.addUserButtonSave}
                onClick={isFormValid ? handleSaveAddUser : this.handleValidateForm}
                isLoading={isCreationPending}
                data-id="save-changes-btn"
                className={`patient-care-btn-in-group ${!isFormValid && "pretend-disabled"}`}
                tabIndex={!isFormValid ? -1 : 0}
              />
            </div>
          </div>
        </FormItem>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserButtons);
