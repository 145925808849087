import * as RolesConstants from "constants/RolesConstants";
import type { UserAction } from "types/user";

const initialState: any = {
  profileAccounts: [],
  profileCurrentRole: Object,
  profileRoles: null,
  deleted: false,
  uniqueRoleCount: 0,
  uniqueOrganizationCount: 0,
  uniqueBranchCount: 0,
};

export default (localState: any = initialState, action: UserAction): any => {
  switch (action.type) {
    case RolesConstants.SET_PROFILE_ROLES: {
      const { accounts, currentRole, currentUserRoles, deleted, uniqueRoleCount, uniqueOrganizationCount, uniqueBranchCount } = action.payload;
      return {
        profileAccounts: accounts,
        profileCurrentRole: currentRole,
        profileRoles: currentUserRoles,
        deleted,
        uniqueBranchCount,
        uniqueOrganizationCount,
        uniqueRoleCount,
      };
    }
    case RolesConstants.SET_CURRENT_PROFILE_ROLE:
      return {
        ...localState,
        profileCurrentRole: action.payload,
      };
    default: {
      return localState;
    }
  }
};
