import React, { Component } from "react";
import {
  Checkbox,
  FormItem,
  Icon,
  IconSortUp,
  IconSortDown,
  Form,
} from "@patient-access/ui-kit";

import { FeatureContentTable } from "components/Share/feature";

// import ServicesTableColgroup from './ServicesTableColgroup';
import locale from "service/locale";

import "./styles.scss";

type Props = {
  isEditMode: boolean,
  isAbleToEdit: boolean,
  isSorting: boolean,
  isAffiliateOrganisation: boolean,
  handleChangeMode: () => any,
  handleChangeSortingOrder: () => any,
  handleSelectionClick: () => any,
  defaultChecked: boolean,
  data: any[]
};


class EnabledServicesTableHeader extends Component<Props, State> {
  render() {
    const {
      isEditMode,
      isAbleToEdit,
      isSorting,
      handleChangeMode,
      handleChangeSortingOrder,
      handleSelectionClick,
      data
    } = this.props;

    return <FeatureContentTable>
        <colgroup>
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.name.width }} />
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.enabledService.width }} />
          <col style={{ width: locale.EnabledServices.enabledServicesTableHeaders.edit.width }} />
        </colgroup>
        <thead>
          <tr>
            <td className="first " onClick={handleChangeSortingOrder}>
              <span className="patient-care-link">
                {locale.EnabledServices.enabledServicesTableHeaders.name.label}
                {isSorting ? <Icon size="smaller" icon={<IconSortUp outline />} type="inline" /> : <Icon size="smaller" icon={<IconSortDown outline />} type="inline" />}
              </span>
            </td>
            <td className="extra">
              <span className="header-span-wrap">
                <Form>
                  <FormItem type="item">
                    <Checkbox 
                      id="select_all" 
                      name="select_all" 
                      label={locale.EnabledServices.enabledServicesTableHeaders.enabledService.label} 
                      checked={data.every((data) => data.enabled === true)} 
                      value={locale.EnabledServices.checkboxValue} 
                      onChange={handleSelectionClick} 
                      data-id="select_all" 
                      disabled={!isEditMode} 
                      className="select-all-checkbox" />
                  </FormItem>
                </Form>
              </span>
            </td>
            <td className="extra">
              {locale.EnabledServices.enabledServicesTableHeaders.modes.label}
            </td>
            <td className="last right">
              {isAbleToEdit ? <span className="patient-care-link header-span-wrap" onClick={handleChangeMode}>
                  {isEditMode ? `${locale.EnabledServices.enabledServicesTableHeaders.edit.done}` : `${locale.EnabledServices.enabledServicesTableHeaders.edit.edit}`}
                </span> : null}
            </td>
          </tr>
        </thead>
      </FeatureContentTable>;
  }
}

export default EnabledServicesTableHeader;
