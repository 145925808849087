import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  Input,
  InputLabel,
  InputError
} from "@patient-access/ui-kit";

import locale from "service/locale";

import { updateForm } from "actions/form";
import { checkPostcode } from 'helpers/checkValues';

// import "./styles.scss";

type Props = {
  isAbleToEdit: boolean,
  organisationDetails: Organisation,

  form: Object,
  updateForm: (data: any) => Action,
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
   updateForm: data => dispatch(updateForm(data)),
});

class RegisteredAddress extends Component<Props, State> {

  renderReadOnlyMode = () => {
    const { organisationDetails } = this.props;
    const { form } = this.props;
    const keys = Object.keys(form);

    const isEmptyAddressLine2 =
      (form.organisation_address_2 && !form.organisation_address_2.length) === "";

    return (
      <Fragment>
              <div className="patient-care-block">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="organisation_address_1"
                      message={locale.OrganisationsSettings.addressLine1}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {
                    keys.includes("organisation_address_1")
                    ? form.organisation_address_1
                    : organisationDetails.companyAddress && organisationDetails.companyAddress.addressLine1
                  }
                </span>
              </div>
              <div className="patient-care-block">
                  <Form noValidate>
                    <FormItem type="item">
                      <InputLabel
                        htmlFor="organisation_address_2"
                        message={locale.OrganisationsSettings.addressLine2}
                        size="small"
                      />
                    </FormItem>
                  </Form>
                  <span className="patient-care-filled-input">
                    {isEmptyAddressLine2 || !(organisationDetails.companyAddress && organisationDetails.companyAddress.addressLine2)
                      ? locale.BranchSettings.notProvided
                      : keys.includes("organisation_address_2")
                      ? form.organisation_address_2
                      : organisationDetails.companyAddress && organisationDetails.companyAddress.addressLine2}
                  </span>
              </div>
              <div className="patient-care-block">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="organisation_city"
                      message={locale.OrganisationsSettings.city}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                    {
                      keys.includes("organisation_city")
                      ? form.organisation_city
                      : organisationDetails.companyAddress && organisationDetails.companyAddress.town
                    }
                </span>
              </div>
              <div className="patient-care-block">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="organisation_postcode"
                      message={locale.OrganisationsSettings.postcode}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {
                    keys.includes("organisation_postcode")
                    ? form.organisation_postcode
                    : organisationDetails.companyAddress && organisationDetails.companyAddress.postcode
                  }
                </span>
              </div>
              <div className="patient-care-block">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="organisation_country"
                      message={locale.OrganisationsSettings.country}
                      size="small"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {
                    keys.includes("organisation_country")
                    ? form.organisation_country
                    : organisationDetails.companyAddress && organisationDetails.companyAddress.country
                  }
                </span>
              </div>
            </Fragment>
    );
  }

  renderEditMode = () => {
    const { organisationDetails } = this.props;
    const {
      form,
    } = this.props;

    const isEmptyAddressLine =
      (form.organisation_address_1 && !form.organisation_address_1.length) === "";
    const isEmptyAddressCity =
      (form.organisation_city && !form.organisation_city.length) === "";
    const isEmptyPostCode =
      (form.organisation_postcode && !form.organisation_postcode.length) === "";
    const isValidPostCode =
      form.organisation_postcode && form.organisation_postcode.length > 0
        ? checkPostcode(form.organisation_postcode)
        : true;
    const isEmptyCountry =
      (form.organisation_country && !form.organisation_country.length) === "";

    return (
      <Fragment>
      {organisationDetails.companyAddress &&
        organisationDetails.companyAddress.addressLine1 && (
          <div className="patient-care-block">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem
                  type="item"
                  error={isEmptyAddressLine ? "error" : null}
                >
                  <InputLabel
                    htmlFor="organisation_address_1"
                    message={locale.OrganisationsSettings.addressLine1}
                    size="small"
                  />
                  <Input
                    type="text"
                    id="organisation_address_1"
                    name="organisation_address_1"
                    defaultValue={
                      organisationDetails.companyAddress.addressLine1
                    }
                    onChange={this.handleFormChange}
                  />
                  {isEmptyAddressLine && (
                    <InputError
                      message={
                        locale.OrganisationsSettings
                          .organisationAddressError
                      }
                    />
                  )}
                </FormItem>
              </Form>
            </div>
          </div>
        )}
      {(
        <div className="patient-care-block">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="organisation_address_2"
                    message={locale.OrganisationsSettings.addressLine2}
                    size="small"
                  />
                  <Input
                    type="text"
                    id="organisation_address_2"
                    name="organisation_address_2"
                    defaultValue={
                      organisationDetails.companyAddress.addressLine2
                    }
                    onChange={this.handleFormChange}
                  />
                </FormItem>
              </Form>
            </div>
          </div>
        )}
      {organisationDetails.companyAddress && organisationDetails.companyAddress.town && (
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem
                type="item"
                error={isEmptyAddressCity ? "error" : null}
              >
                <InputLabel
                  htmlFor="organisation_city"
                  message={locale.OrganisationsSettings.city}
                  size="small"
                />
                <Input
                  type="text"
                  id="organisation_city"
                  name="organisation_city"
                  defaultValue={organisationDetails.companyAddress.town}
                  onChange={this.handleFormChange}
                />
                {isEmptyAddressCity && (
                  <InputError
                    message={
                      locale.OrganisationsSettings.organisationCityError
                    }
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
      )}
      {organisationDetails.companyAddress &&
        organisationDetails.companyAddress.postcode && (
          <div className="patient-care-block">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem
                  type="item"
                  error={
                    !isValidPostCode || isEmptyPostCode ? "error" : null
                  }
                >
                  <InputLabel
                    htmlFor="organisation_postcode"
                    message={locale.OrganisationsSettings.postcode}
                    size="small"
                  />
                  <Input
                    type="text"
                    id="organisation_postcode"
                    name="organisation_postcode"
                    defaultValue={organisationDetails.companyAddress.postcode}
                    onChange={this.handleFormChange}
                  />
                  {(!isValidPostCode || isEmptyPostCode) && (
                    <InputError
                      message={
                        locale.OrganisationsSettings
                          .organisationPostcodeError
                      }
                    />
                  )}
                </FormItem>
              </Form>
            </div>
          </div>
        )}
      {organisationDetails.companyAddress &&
        organisationDetails.companyAddress.country && (
          <div className="patient-care-block">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem
                  type="item"
                  error={isEmptyCountry ? "error" : null}
                >
                  <InputLabel
                    htmlFor="organisation_country"
                    message={locale.OrganisationsSettings.country}
                    size="small"
                  />
                  <Input
                    type="text"
                    id="organisation_country"
                    name="organisation_country"
                    defaultValue={
                      organisationDetails.companyAddress &&
                      organisationDetails.companyAddress.country
                    }
                    onChange={this.handleFormChange}
                  />
                  {isEmptyCountry && (
                    <InputError
                      message={
                        locale.OrganisationsSettings
                          .organisationCountryError
                      }
                    />
                  )}
                </FormItem>
              </Form>
            </div>
          </div>
        )}
    </Fragment>
    );
  }

  renderActionButton = () => {
    const { form, isAbleToEdit, organisationDetails } = this.props;
    if (isAbleToEdit && !organisationDetails.registrationNumber) {
      return (
        <button
          className="patient-care-btn-link"
          onClick={this.handleOfficeAddressEditMode}
        >
          {form.isEditRegisteredAddressMode
            ? locale.OrganisationsSettings.organisationDetails
              .buttonDone
            : locale.OrganisationsSettings.organisationDetails
              .buttonEdit}
        </button>
      );
    }

    return null;
  }

  handleOfficeAddressEditMode = (e: any) => {
    e && e.preventDefault();
    const { form, organisationDetails, updateForm } = this.props;

    if (form.isEditRegisteredAddressMode) {
      const {
        organisation_address_1,
        organisation_city,
        organisation_postcode,
        organisation_country
      } = form;
      const {
        addressLine1,
        town,
        postcode,
        country
      } = organisationDetails.companyAddress;

      const isEnteredAddressLine1 =
        organisation_address_1 === undefined
          ? addressLine1.length > 0
          : organisation_address_1.length > 0;

      const isEnteredCity =
        organisation_city === undefined
          ? town.length > 0
          : organisation_city.length > 0;

      const isEnteredPostCode =
        organisation_postcode === undefined
          ? postcode.length > 0
          : organisation_postcode.length > 0;

      const isValidPostCode = checkPostcode(
        organisation_postcode === undefined ? postcode : organisation_postcode
      );

      const isEnteredCountry =
        organisation_country === undefined
          ? country.length > 0
          : organisation_country.length > 0;

      if (
        isEnteredAddressLine1 &&
        isEnteredCity &&
        isEnteredPostCode &&
        isValidPostCode &&
        isEnteredCountry
      ) {
        updateForm({ isEditRegisteredAddressMode: false });
      }
    } else {
      updateForm({ isEditRegisteredAddressMode: true });
    }
  };

  handleFormChange = (e: any) => {
    const { updateForm } = this.props;
    const {
      target: { name, value }
    } = e;
    updateForm({ isDataChanged: true, [name]: value });
  };

  render() {
    const { isEditRegisteredAddressMode } = this.props.form;

    return (
      <Fragment>
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.OrganisationsSettings.registeredAddress}</h3>
              {this.renderActionButton()}
            </div>
            <p>{locale.OrganisationsSettings.addressDescription}</p>
          </div>
        {isEditRegisteredAddressMode ? this.renderEditMode() : this.renderReadOnlyMode()}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisteredAddress);