import React, { Component, Fragment } from "react";
import { getAppointmentDateAndTime } from "helpers/formatData";
import { connect } from "react-redux";
import moment from "moment";
import {
  Icon,
  IconPin,
  IconPerson,
  IconVideo,
  IconPhone,
  IconHome,
  IconOnline,
  IconHelp
} from "@patient-access/ui-kit";

import locale from "service/locale";
import "./styles.scss";

type Props = {
  appointmentDetails?: {
    endTime: string,
    startTime: string,
    serviceName: string,
    branchName: string,
    bookTime: string,
    bookingMethod: number,
  },
  booking: Object,
  branchName: string | null,
  type: number,
};

const mapStateToProps = (state: any) => ({
  booking: state.book,
  branchName: state.calendarView.branchName,
  type: state.appointmentDetails.type,
});

class AppointmentInfo extends Component<Props> {
  render() {
    const { booking, branchName, appointmentDetails, type } = this.props;

    const renderBookingTypeIcon = () => {
      if (type === 1) return <IconVideo outline={false} />;
      if (type === 2) return <IconPhone outline={false} />;
      return (<IconPerson outline={false} />);
    }

    const renderBookingMethodIcon = () => {
      if (appointmentDetails.bookingMethod === 1) return (<IconOnline outline={true} />);
      if (appointmentDetails.bookingMethod === 2) return (<IconHome outline={false} />);
      return (<IconHelp outline={false} />);
    }

    return (
      <div className="patient-care-block">
        <ul className="patient-care-appointment-details-list">
          <li className="patient-care-appointment-info-item">
            <h3>{
              appointmentDetails
                ? getAppointmentDateAndTime(appointmentDetails.startTime, appointmentDetails.endTime)
                : getAppointmentDateAndTime(booking.startTime, booking.endTime)
              }</h3>
          </li>
          <li className="patient-care-appointment-info-item">
            <h3>{
              appointmentDetails
                ? appointmentDetails.serviceName
                : booking.service.name
              }</h3>
          </li>
        </ul>
        <ul className="patient-care-appointment-details-list">
          <li className="patient-care-appointment-info-item">
            <Icon
              type="inline"
              size="small"
              icon={<IconPin outline={false} />}
            />
            <span className="patient-care-appointment-details-text">
              {
                appointmentDetails
                ? appointmentDetails.branchName
                : branchName
              }
            </span>
          </li>
          {appointmentDetails && appointmentDetails.bookTime && (
            <Fragment>
              <li className="patient-care-appointment-info-item">
                <Icon type="inline" size="small" icon={renderBookingMethodIcon()} />
                <span className="patient-care-appointment-details-text">
                  {locale.Appointment.bookingMethodLabel.before}&nbsp;
                  {locale.Appointment.bookingMethod[appointmentDetails.bookingMethod]}&nbsp;
                  {locale.Appointment.bookingMethodLabel.after}&nbsp;
                  {moment(appointmentDetails.bookTime).format("DD/MM/YYYY [at] HH:mm")}
                </span>
              </li>
              <li className="patient-care-appointment-info-item">
                <Icon type="inline" size="small" icon={renderBookingTypeIcon()} />
                <span className="patient-care-appointment-details-text">
                  {locale.Appointment.bookingType[type]}
                </span>
              </li>
            </Fragment>
          )}
        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AppointmentInfo);
