import React, { Component } from 'react';
import { ReportsLegend, ReportsLegendItem } from 'components/Pages/Reports/wrappers';
import ChartDeliveryTrend from '../ChartDeliveryTrend/ChartDeliveryTrend';
import * as ColorConstants from 'constants/ColorConstants';
import './styles/card-reports-deliveryTrend-chart.scss';
import locale from 'service/locale';

type Props = {
  isResizing: boolean,
};

class CardDeliveryTrendChart extends Component<Props> {
  render() {
    const { isResizing } = this.props;

    return (
      <div className="card-reports-deliveryTrend-chart">
        <div className="card-reports-deliveryTrend-chart-header">
          <h3>{locale.Reports.smartPharmacyDeliveryTrendGraphTitle}</h3>
        </div>
        <div className="card-reports-deliveryTrend-chart-content">
          <ChartDeliveryTrend isResizing={isResizing}/>
        </div>
        <div className="card-reports-deliveryTrend-chart-footer">
          <ReportsLegend>
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_THREE} label={locale.Reports.smartPharmacyDeliveryTrendGraphLegend1} />
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_TWO} label={locale.Reports.smartPharmacyDeliveryTrendGraphLegend2} />
          </ReportsLegend>
        </div>
      </div>
    );
  }
};

export default CardDeliveryTrendChart;
