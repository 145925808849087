import React, { Component } from 'react';

import './styles/skeleton-heading-one.scss';

class SkeletonHeadingOne extends Component {
  render() {
    return (
      <div className="skeleton-heading-one">
        <div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" />
      </div>
    );
  }
};

export default SkeletonHeadingOne;
