import React, { Component } from 'react';
import { IconCloseCircle } from '@patient-access/ui-kit';

import './styles/patient-care-button-remove.scss';

type Props = {
  onClick: any,
};

class ButtonRemove extends Component<Props> {
  render() {
    const { onClick } = this.props;
    return (
      <button className="patient-care-button-remove" onClick={onClick}>
        <i>
          <IconCloseCircle outline />
          <IconCloseCircle outline={false} />
        </i>
      </button>
    );
  }
};

export default ButtonRemove;
