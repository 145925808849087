import {PROFILE_ROLES_LEVEL} from "constants/RolesConstants";

export function areValidRoles (roles) {
    let rolesCount = roles.length;
    for (let role of roles) {
        if (isSuperRole(role)) return rolesCount === 1;
        if (isDuplicate(role, roles)) return false;
        if (isLowerRole(role, roles)) return false;
    }
    return true;
}
const isSuperRole = (role) => role.roleLevel === PROFILE_ROLES_LEVEL.SuperAdmin;

const isDuplicate = (role, roles) => {
    let res = roles.filter(r => r.branchId === role.branchId && r.organizationId === role.organizationId && r.role === role.role);
    return res.length > 1;
};

const isLowerRole = (role, roles) => {
    if (role.roleLevel === PROFILE_ROLES_LEVEL.OrganizationAdmin) return false;

    return roles.some( r => {
        if (r.organizationId !== role.organizationId) return false;
        if (PROFILE_ROLES_LEVEL[r.role] < PROFILE_ROLES_LEVEL[role.role]) {
            if (PROFILE_ROLES_LEVEL[r.role] > PROFILE_ROLES_LEVEL.OrganizationAdmin) {
                return r.branchId === role.branchId;
            } else {
                return true;
            }
        }
        if (r.branchId !== role.branchId) return false;

        return PROFILE_ROLES_LEVEL[r.role] < PROFILE_ROLES_LEVEL[role.role];
    })
};
