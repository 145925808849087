import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconCog extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M19.5 12C19.5 11.77 19.49 11.55 19.47 11.32L21.33 9.91C21.73 9.61 21.84 9.05 21.59 8.61L19.72 5.38C19.47 4.94 18.93 4.76 18.47 4.96L16.32 5.87C15.95 5.61 15.56 5.38 15.15 5.19L14.86 2.88C14.8 2.38 14.37 2 13.87 2H10.14C9.63 2 9.2 2.38 9.14 2.88L8.85 5.19C8.44 5.38 8.05 5.61 7.68 5.87L5.53 4.96C5.07 4.76 4.53 4.94 4.28 5.38L2.41 8.62C2.16 9.06 2.27 9.61 2.67 9.92L4.53 11.33C4.51 11.55 4.5 11.77 4.5 12C4.5 12.23 4.51 12.45 4.53 12.68L2.67 14.09C2.27 14.39 2.16 14.95 2.41 15.39L4.28 18.62C4.53 19.06 5.07 19.24 5.53 19.04L7.68 18.13C8.05 18.39 8.44 18.62 8.85 18.81L9.14 21.12C9.2 21.62 9.63 22 10.13 22H13.86C14.36 22 14.79 21.62 14.85 21.12L15.14 18.81C15.55 18.62 15.94 18.39 16.31 18.13L18.46 19.04C18.92 19.24 19.46 19.06 19.71 18.62L21.58 15.39C21.83 14.95 21.72 14.4 21.32 14.09L19.46 12.68C19.49 12.45 19.5 12.23 19.5 12ZM12.04 15.5C10.11 15.5 8.54 13.93 8.54 12C8.54 10.07 10.11 8.5 12.04 8.5C13.97 8.5 15.54 10.07 15.54 12C15.54 13.93 13.97 15.5 12.04 15.5Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-cog ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconCog;
