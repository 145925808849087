import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkIcon, IconDelete, Button } from "@patient-access/ui-kit";

import * as RolesConstants from "constants/RolesConstants";
import { openConfirmationDeleteUser } from 'actions/panel';
import locale from 'service/locale';

type Props = {
  userId: string,
  isDisabled: boolean,
  isDataChanged: boolean,
  closeUserDetails: () => any,
  handleUpdateUser: () => any,
  handleDiscardConfirmation: () => any,
  openConfirmationDeleteUser: () => any,
  canDelete: boolean,
  currentRole: any,
  isUpdatePending: boolean,
}

const mapStateToProps = (state) => ({
  userId: state.userDetails.id,
  currentRole: state.roles.profileCurrentRole,
  isUpdatePending: state.userDetails.isUpdatePending,
});

const mapDispatchToProps = (dispatch: Function) => ({
  openConfirmationDeleteUser: () => dispatch(openConfirmationDeleteUser()),
});

class UserDetailsButtons extends Component<Props> {

  handleDeleteUser = (e: Event) => {
    e && e.preventDefault();
    const { openConfirmationDeleteUser } = this.props;
    openConfirmationDeleteUser();
  };

  render() {
    const {
      isDataChanged,
      closeUserDetails,
      handleUpdateUser,
      handleDiscardConfirmation,
      isDisabled,
      canDelete,
      currentRole,
      isUpdatePending,
      customClassName
    } = this.props;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;
    return (
      <div className={`patient-care-modal-footer ${customClassName}`}>
          <div className={`patient-care-modal${!isBranchMember && canDelete ? "-buttons-row" : "-button-wrap"}`}>
            {
              !isBranchMember && canDelete &&
              <div className="patient-care-btn-delete">
                <LinkIcon
                  to=""
                  icon={<IconDelete outline />}
                  size="medium"
                  accessibilitySpan={locale.UserDetails.userDetailsButtonDelete}
                  onClick={this.handleDeleteUser}
                />
              </div>
            }
            {!isDataChanged && (
              <Button
                buttonType="blueline"
                messageKey="close-btn"
                defaultMessage={locale.UserDetails.userDetailsButtonClose}
                onClick={closeUserDetails}
                isLoading={isUpdatePending}
                data-id="close-user-details-btn"
              />
            )}
            {isDataChanged && (
              <div className="patient-care-buttons-group">
                <Button
                  buttonType="blueline"
                  messageKey="discard-btn"
                  defaultMessage={locale.UserDetails.userDetailsButtonDiscard}
                  onClick={handleDiscardConfirmation}
                  isLoading={isUpdatePending}
                  data-id="discard-btn"
                  className="patient-care-btn-in-group"
                />
                <Button
                  buttonType="secondary"
                  messageKey="save-changes-btn"
                  defaultMessage={locale.UserDetails.userDetailsButtonSave}
                  onClick={handleUpdateUser}
                  isLoading={isUpdatePending}
                  data-id="save-changes-btn"
                  className="patient-care-btn-in-group"
                  isDisabled={isDisabled}
                  tabIndex={isDisabled ? -1 : 0}
                />
              </div>
              )}
          </div>
        </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsButtons);
