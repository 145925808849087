import React, { Component, Fragment } from 'react';
import moment from 'moment';
import locale from 'service/locale';

type Props = {
  isAbleToEdit: Boolean,
  isEditMode: Boolean,
  featureMedicationNotifications: any,
  featureMedicationNotificationsClone: any,
  handleIsEditSmartPharmacy: () => Action,
  handleToggleSmartPharmacy: () => ACtion,
};

class SmartPharmacy extends Component<Props> {
  render() {
    const { isAbleToEdit, isEditMode, featureMedicationNotifications, featureMedicationNotificationsClone, handleIsEditSmartPharmacy, handleToggleSmartPharmacy } = this.props;
    const isEnabled = featureMedicationNotifications && featureMedicationNotifications.isEnabled;
    const visibleClass = isEnabled ? 'active' : '';
    const invisibleClass = isEnabled ? '' : 'active';

    const renderEditButton = () => {
      if (isAbleToEdit) {
        return (
          <button className="patient-care-btn-link" onClick={handleIsEditSmartPharmacy}>
            {isEditMode ? locale.Buttons.buttonDone : locale.Buttons.buttonEdit}
          </button>
        );
      }
      return null;
    }

    const renderLastModifiedDate = () => {
      if (featureMedicationNotificationsClone && featureMedicationNotificationsClone.lastModifiedDate) {
        return (
          <Fragment>
            {featureMedicationNotificationsClone.isEnabled ? locale.BranchSettings.smartPharmacy.lastEnabled : locale.BranchSettings.smartPharmacy.lastDisabled}&nbsp;
            {moment(featureMedicationNotificationsClone.lastModifiedDate).format("DD/MM/YYYY")}
          </Fragment>
        );
      }
      return locale.BranchSettings.smartPharmacy.neverEnabled;
    }

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.BranchSettings.smartPharmacy.header}</h3>
            {renderEditButton()}
          </div>
          <p>{locale.BranchSettings.smartPharmacy.description}</p>
        </div>
        <div className="patient-care-description-block">
          <ul className={`patient-care-unstyled-list patient-care-status-list ${isEditMode ? '' : 'patient-care-status-list-disabled'}`}>
            <li className={`patient-care-status-item ${invisibleClass}`} onClick={() => { handleToggleSmartPharmacy(false); }}>
              {locale.BranchSettings.smartPharmacy.labelDisabled}
            </li>
            <li className={`patient-care-status-item ${visibleClass}`} onClick={() => { handleToggleSmartPharmacy(true); }}>
              {locale.BranchSettings.smartPharmacy.labelEnabled}
            </li>
          </ul>
        </div>
        <div className="patient-care-description-block">
          <p>{renderLastModifiedDate()}</p>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default SmartPharmacy;
