import * as BranchesListConstants from 'constants/BranchesListConstants';

type BranchesList = {
  // TODO
};

const initialState: BranchesList = {
  searchValue: '',
  data: [],
  pages: 0,
  pageNumber: 0,
  isLoading: false,
  isError: false,
};

export default (localState: BranchesList = initialState, action: Action): BranchesList => {
  switch (action.type) {
    // SEARCH_VALUE
    case BranchesListConstants.SET_BRANCHES_LIST_SEARCH_VALUE:
      return {
        ...localState,
        searchValue: action.payload,
      };

    // SERVICES_SUMMARY
    case BranchesListConstants.GET_BRANCHES_LIST_PENDING:
      return {
        ...localState,
        isLoading: true,
        isError: false,
      };
    case BranchesListConstants.GET_BRANCHES_LIST_SUCCESS:
      return {
        ...localState,
        isLoading: false,
        isError: false,
      };
    case BranchesListConstants.GET_BRANCHES_LIST_ERROR:
      return {
        ...localState,
        isLoading: false,
        isError: true,
      };
    case BranchesListConstants.SET_BRANCHES_LIST_DATA:
      const { items, pages, pageNumber } = action.payload;
      return {
        ...localState,
        data: items,
        pages: pages === 0 ? localState.pages : pages,
        pageNumber,
      };

    // DEFAULT
    default:
      return localState;
  }
};
