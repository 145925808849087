import React, { Component } from "react";
import { Overlay, Button, Skeleton } from "@patient-access/ui-kit";

import locale from "service/locale";

type Props = {
  handleConfirm?: () => any,
  handleCancel: () => any,
  header?: string,
  content?: string,
  buttonConfirm?: string,
  buttonCancel: string,
  isShowConfirmButton?: boolean,
  isLoading?: boolean
};

export default class ConfirmationOverlayCommon extends Component<Props> {
  render() {
    const {
      handleConfirm,
      handleCancel,
      header,
      content,
      buttonConfirm,
      buttonCancel,
      isShowConfirmButton,
      isLoading
    } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="confirmation-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{header || locale.Modals.confirmationOverlayCommon.header}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>
            {isLoading ? (
              <Skeleton type="body" />
            ) : (
              content || locale.Modals.confirmationOverlayCommon.content
            )}
          </h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="cancel-btn"
            defaultMessage={
              buttonCancel ||
              locale.Modals.confirmationOverlayCommon.buttonCancel
            }
            data-id="cancel-btn"
            className="patient-care-btn-in-group"
            onClick={handleCancel}
          />
          {isLoading ? (
            <Skeleton type="body" />
          ) : (
            isShowConfirmButton !== false && (
              <Button
                buttonType="secondary"
                messageKey="confirm-btn"
                defaultMessage={
                  buttonConfirm ||
                  locale.Modals.confirmationOverlayCommon.buttonConfirm
                }
                onClick={handleConfirm}
                data-id="confirm-btn"
                className="patient-care-btn-in-group"
              />
            )
          )}
        </div>
      </Overlay>
    );
  }
}
