import React, { Component } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import MediaQuery from 'react-responsive';
import { NoResults, Loader } from '@patient-access/ui-kit';
import * as ColorConstants from 'constants/ColorConstants';
import * as ReportsConstants from 'constants/ReportsConstants';
import { formatNumber, formatPercentage } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

type Props = {
  appointment: any,
  isResizing: boolean,
  isLoading: boolean,
  isEmptyAppointment: boolean,
};

class ChartStatus extends Component<Props> {
  getStatusKey = (status, key) => {
    const statusKey = ReportsConstants.STATUS_FORMATTER.find(o => o.status === status);
    return statusKey[key];
  }

  formatLabel = (props) => {
    const { x, y, cx, cy, value } = props;
    const { appointment } = this.props;
    if (value === 0) return null;
    return (
      <text cx={cx} cy={cy} stroke="none" fill={ColorConstants.COLOR_PRIMARY} x={x} y={y} alignmentBaseline="middle" className="recharts-text recharts-pie-label-text" textAnchor={x > cx ? 'start' : 'end'}>
        {formatPercentage(value, appointment.total, 1)}
      </text>
    );
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      const { appointment } = this.props;
      return (
        <div className="reports-basic-tooltip">
          <ul>
            {payload.map((item, index) => {
              const { status, value } = item.payload;
              return <li key={index}><span><strong>{this.getStatusKey(status, 'name')}:</strong></span> <span>{formatNumber(value)} ({formatPercentage(value, appointment.total, 1)})</span></li>;
            })}
          </ul>
        </div>
      );
    }
    return null;
  }

  renderSmallChart = (appointment) => {
    return (
      <ResponsiveContainer width="99%" height={250}>
        <PieChart>
          <Pie dataKey="value" data={appointment.appointmentPerStatus} cx="50%" cy="50%" innerRadius={30} outerRadius={80} label={this.formatLabel} labelLine={false}>
            {appointment.appointmentPerStatus.map((entry, index) => <Cell key={index} fill={this.getStatusKey(entry.status, 'color')} />)}
          </Pie>
          <Tooltip content={this.formatTooltip} />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  renderLargeChart = (appointment) => {
    return (
      <ResponsiveContainer width="99%" height={350}>
        <PieChart>
          <Pie dataKey="value" data={appointment.appointmentPerStatus} cx="50%" cy="50%" innerRadius={55} outerRadius={118} label={this.formatLabel} labelLine={false}>
            {appointment.appointmentPerStatus.map((entry, index) => <Cell key={index} fill={this.getStatusKey(entry.status, 'color')} />)}
          </Pie>
          <Tooltip content={this.formatTooltip} />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const { appointment, isResizing, isLoading, isEmptyAppointment } = this.props;
    if (isLoading || isEmptyAppointment || isResizing) return <div className="reports-basic-chart status"><Loader type="inline" size="small" className="reports-basic-loader" /></div>;
    if (appointment.appointmentPerStatus.length === 0) return <div className="reports-basic-chart status"><NoResults type="info"><p>{locale.Reports.appointmentsGraphStatusNoResults}</p></NoResults></div>;
    return (
      <div className="reports-basic-chart status">
        <MediaQuery minWidth={1100} maxWidth={1299}>
          {this.renderSmallChart(appointment)}
        </MediaQuery>
        <MediaQuery maxWidth={1099}>
          {this.renderLargeChart(appointment)}
        </MediaQuery>
        <MediaQuery minWidth={1300}>
          {this.renderLargeChart(appointment)}
        </MediaQuery>
      </div>
    );
  }
}

export default ChartStatus;
