import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

class SectionFeatureOtherBusiness extends Component {
  render() {
    return (
      <div className="section-block section-feature">
        <div className="wrapper section-feature-wrapper">
          <div className="section-feature-header">
            <h2><FormattedMessage id="landing.feature.other.business.header" /></h2>
          </div>
          <div className="section-feature-body rside">
            <div className="section-feature-image">
              <img src="/assets/images/landing/section-business.png" alt="" />
            </div>
            <div className="section-feature-content">
              <ul className="section-block-list">
                <li>
                  <h3><FormattedMessage id="landing.feature.other.business.feature_one.header" /></h3>
                  <p><FormattedMessage id="landing.feature.other.business.feature_one.copy" /></p>
                  <span className="line" />
                </li>
                <li>
                  <h3><FormattedMessage id="landing.feature.other.business.feature_two.header" /></h3>
                  <p><FormattedMessage id="landing.feature.other.business.feature_two.copy" /></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionFeatureOtherBusiness;
