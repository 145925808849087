import * as AppointmentDetailsConstants from 'constants/AppointmentDetailsConstants';
import type { AppointmentDetailsAction } from 'types/appointmentDetails';
import type { AppointmentDetails } from 'types/appointments';

const initialState = {
  isAppointmentDataChanged: false
};

export default (localState: AppointmentDetails | null = initialState, action: AppointmentDetailsAction): AppointmentDetails | null => {
  switch (action.type) {
    case AppointmentDetailsConstants.EDIT_APPOINTMENT_DETAILS:
      const { firstName, lastName, dateOfBirth, postcode, email, phoneNumber } = action.payload;
      return {
        ...localState,
        firstName,
        lastName,
        dateOfBirth,
        postcode,
        email,
        phoneNumber
      };
    case AppointmentDetailsConstants.SET_APPOINTMENT_DETAILS:
      return action.payload ? action.payload : {};
    case AppointmentDetailsConstants.CHANGE_PAYMENT_STATUS:
      return {
        ...localState, 
        payment: {
          ...localState.payment,
          paymentStatus: action.payload
        }
      };
    case AppointmentDetailsConstants.CHANGE_APPOINTMENT_STATUS:
      return {
        ...localState,
        status: action.payload.status,
        outcomeStatus: action.payload.outcomeStatus,
        behalfOfPatient: action.payload.behalfOfPatient
      };
    case AppointmentDetailsConstants.CHANGE_AGENDA_CANCELLATION_REASON:
      return {
        ...localState,
        cancellationReason: action.payload
      };
    case AppointmentDetailsConstants.SET_APPOINTMENT_CHANGES: 
      return {
        ...localState,
        isAppointmentDataChanged: action.payload
      };
    case AppointmentDetailsConstants.SET_APPOINTMENT_REFUND_DETAILS:
      return {
        ...localState,
        refundDetails: action.payload
      };
    case AppointmentDetailsConstants.GET_REFUND_AMOUNT_PENDING:
      return{
        ...localState,
        getRefundAmountPending:true
      };
    case AppointmentDetailsConstants.GET_REFUND_AMOUNT_SUCCESS:
      return{
        ...localState,
        getRefundAmountPending:false
      };
    case AppointmentDetailsConstants.GET_REFUND_AMOUNT_ERROR:
      return{
        ...localState,
        getRefundAmountPending:false
      };
    case AppointmentDetailsConstants.REFUND_CUSTOMER_PENDING:
      return{
        ...localState,
        isRefundCustomerPending:true
      };
    case AppointmentDetailsConstants.REFUND_CUSTOMER_SUCCESS:
      return{
        ...localState,
        isRefundCustomerPending:false
      };
    case AppointmentDetailsConstants.REFUND_CUSTOMER_ERROR:
      return{
        ...localState,
        isRefundCustomerPending:false
      }; 
    case AppointmentDetailsConstants.SET_INTERNAL_EVENT:
      return {
        ...localState,
        internalEvent: action.payload
      };      
    case AppointmentDetailsConstants.DELETE_INTERNAL_EVENT_PENDING:
      return {
        ...localState,
        isDeleteEventPending: true,
      };
    case AppointmentDetailsConstants.DELETE_INTERNAL_EVENT_SUCCESS:
      return {
        ...localState,
        isDeleteEventPending: false
      };
    case AppointmentDetailsConstants.DELETE_INTERNAL_EVENT_ERROR:
      return {
        ...localState,
        isDeleteEventPending: false
      };
    default:
      return localState;
  }
}
