import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Form, FormItem, InputError, Switch } from "@patient-access/ui-kit";
import Select from "react-select";
import { updateForm } from "actions/form";

import type { Calendar } from "types/calendars";
import * as RolesConstants from "constants/RolesConstants";
import { PeriodsList } from "constants/CalendarsConstants";
import locale from "service/locale";

import "./styles.scss";

type Props = {
  form: any,
  currentRole: Object,
  isEditMode: boolean,
  calendar: Calendar,
  handleEditTimeOff: () => Action,
  handleBranchTimeOff: () => Action,
  updateForm: (data: any) => Action,
};

type State = {
  isTimeOffToggleEnabled: boolean,
  timeOffSelected: number,
  SelectTimeOffError: boolean,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  calendar: state.calendar,
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class TimeOff extends Component<Props, State> {
  state = {
    isTimeOffToggleEnabled: false,
    toggleInitialState: false,
    timeOffSelected: 0,
    SelectTimeOffError: false,
  };

  componentDidMount = () => {
    const { calendar } = this.props;
    const { isBranchControlToggle, timeOff } = calendar;
    this.setState({
      isTimeOffToggleEnabled:
        isBranchControlToggle && timeOff !== 0 ? true : false,
        toggleInitialState:
        isBranchControlToggle && timeOff !== 0 ? true : false,
      timeOffSelected: timeOff ? timeOff : 0,
    });
  };

  onTimeOffToggle = (checked) => {
    const { calendar, updateForm, handleBranchTimeOff } = this.props;
    const { isTimeOffToggleEnabled, toggleInitialState } = this.state;
    const { isBranchTimeOffEnabled, timeOff } = calendar;

    this.setState({
      isTimeOffToggleEnabled: checked,
      SelectTimeOffError: timeOff ? false : true,
      timeOffSelected: calendar.timeOff ? calendar.timeOff : 0,
    });
    
    if (!checked) {
      this.setState({
        timeOffSelected: timeOff ? timeOff : 0,
        SelectTimeOffError: false,
      });
    }

    updateForm({
      timeOffModel: {
        isTimeOffEnabled: isBranchTimeOffEnabled,
        timeOff: !isTimeOffToggleEnabled ? timeOff : 0,
        isBranchControlTimeOff: isTimeOffToggleEnabled,
      },
    });

    this.setState(() => {
      (checked !== toggleInitialState && timeOff !== 0) ? handleBranchTimeOff(true) : handleBranchTimeOff(false);
    });
    
  };

  handleChangeTimeOff = (option: any) => {
    const { calendar, updateForm, handleBranchTimeOff } = this.props;
    const { isTimeOffToggleEnabled } = this.state;
    const { isBranchTimeOffEnabled, timeOff } = calendar;

    this.setState({ timeOffSelected: option.value });
    option.value === 0 ? this.setState({
          SelectTimeOffError: true,
        }) : this.setState({ SelectTimeOffError: false });

    updateForm({
      timeOffModel: {
        isTimeOffEnabled: isBranchTimeOffEnabled,
        timeOff: isTimeOffToggleEnabled ? option.value : 0,
        isBranchControlTimeOff: isTimeOffToggleEnabled,
      },
    });

    this.setState(() => {
      (option.value === timeOff) ? handleBranchTimeOff(false) : handleBranchTimeOff(true);
    });
  };

  render() {
    const { currentRole, isEditMode, calendar, handleEditTimeOff } = this.props;
    const {
      isTimeOffToggleEnabled,
      timeOffSelected,
      SelectTimeOffError,
    } = this.state;
    const { isBranchTimeOffEnabled, timeOff, isBranchControlToggle } = calendar;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;

    return isBranchTimeOffEnabled ? (
      <Fragment>
        <div className="patient-care-branch-time-out">
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.AvailabilitySettings.timeOff}</h3>

              {!isBranchMember &&
                isBranchTimeOffEnabled &&
                isBranchControlToggle && (
                  <button
                    className={`patient-care-btn-link ${SelectTimeOffError ? 'disabled': ''}`}
                    onClick={handleEditTimeOff}
                  >
                    {isEditMode
                      ? locale.Buttons.buttonDone
                      : locale.Buttons.buttonEdit}
                  </button>
                )}
            </div>
            <p className="branch-timeout-description">
              {locale.AvailabilitySettings.timeOffDescription}
              {!isBranchControlToggle ? (
                <span>{locale.AvailabilitySettings.timeOffDescription2}</span>
              ) : null}
            </p>

            {isBranchTimeOffEnabled &&
            isBranchControlToggle ?
             (
              <Fragment>
                {isEditMode ? (
                  <Fragment>
                    <div className="patient-care-block">
                      <Form>
                        <div className="patient-care-timeoff-branch">
                        <h4>
                            {locale.AvailabilitySettings.timeOffAdd}
                          </h4>
                          <FormItem type="item">
                            <Switch
                              id="test"
                              className="time-off-branch-switch"
                              name="some-name"
                              value="0"
                              accessibilitySpan="some-label"
                              checked={isTimeOffToggleEnabled ? true : false}
                              onChange={(e) => {
                                this.onTimeOffToggle(e.target.checked);
                              }}
                            />
                          </FormItem>
                        </div>

                        {isTimeOffToggleEnabled ? (
                          <FormItem type="item">
                            <div className="patient-care-branch-availability-select">
                              <Select
                                id="timeoff-branch-select"
                                name="timeoff-branch-select"
                                placeholder={
                                  locale.AvailabilityRules
                                    .timeOffSelectPlaceholder
                                }
                                isSelected={this.state.isSelected}
                                options={PeriodsList}
                                classNamePrefix="patient-care"
                                onChange={this.handleChangeTimeOff}
                                className={`patient-care-select timeoff-branch-select ${
                                  SelectTimeOffError
                                    ? "patient-care-select-error"
                                    : null
                                } `}
                                defaultValue={
                                  timeOffSelected
                                    ? PeriodsList.find(
                                        (option) =>
                                          option.value === timeOffSelected
                                      )
                                    : PeriodsList.find(
                                        (option) => option.value === timeOff
                                      )
                                }
                              />
                              {SelectTimeOffError ? (
                                <InputError
                                  message={
                                    locale.AvailabilitySettings
                                      .timeOffSelectDefault
                                  }
                                />
                              ) : null}
                            </div>
                          </FormItem>
                        ) : null}
                      </Form>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="patient-care-block">
                      <Form>
                        <div className="patient-care-timeoff-branch">
                          <h4>
                            {locale.AvailabilitySettings.timeOffAdd}
                          </h4>
                          <FormItem type="item">
                            <Switch
                              id="test"
                              className="time-off-branch-switch"
                              name="some-name"
                              value="1"
                              accessibilitySpan="some-label"
                              disabled="disabled"
                              checked={isTimeOffToggleEnabled ? true : false}
                            />
                          </FormItem>
                        </div>

                        {(timeOffSelected ? timeOffSelected : timeOff) ===
                        0 ? null : (
                          <Fragment>
                            {isTimeOffToggleEnabled ? (
                              <FormItem type="item">
                                <div className="patient-care-availability-value">
                                  <h4>
                                    {timeOffSelected
                                      ? timeOffSelected
                                      : timeOff}{" "}
                                    {locale.AvailabilitySettings.timeOffReadOnly["minutes"]}
                                  </h4>
                                </div>
                              </FormItem>
                            ) : null}
                          </Fragment>
                        )}
                      </Form>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <h4 className="branch-timeout-info">
                  {locale.AvailabilitySettings.timeOffReadOnly["opening"]}{" "}
                  {timeOff === 0 || timeOff === 1
                    ? `${locale.AvailabilitySettings.timeOffReadOnly["is"]}`
                    : `${locale.AvailabilitySettings.timeOffReadOnly["are"]}`}{" "}
                  {timeOff}{" "}
                  {timeOff === 0 || timeOff === 1
                    ? `${locale.AvailabilitySettings.timeOffReadOnly["minute"]}`
                    : `${locale.AvailabilitySettings.timeOffReadOnly["minutes"]}`}{" "}
                  {locale.AvailabilitySettings.timeOffReadOnly["closing"]}
                </h4>
              </Fragment>
            )}
          </div>

          <hr className="patient-care-separator-small" />
        </div>
      </Fragment>
    ) : null;
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeOff);
