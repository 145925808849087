import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

import { formatListAsOptions, formatItemAsOption } from "helpers/formatData";
import { changeCalendarForSelectedSlot, getCalendarsWithParticularEmptySlot } from "actions/bookAppointment";
import type { IdName } from 'types/appointmentDetails';
import type { AppointmentDetailsType } from "constants/AppointmentDetailsConstants";

import locale from "service/locale";

type Props = {
  slotCalendars: any[],
  appointmentCalendars: any[],
  bookingCalendar: Object,
  room?: IdName,
  appointmentCalendar?: IdName,
  isDisabled: boolean,
  changeCalendarForSelectedSlot: (calendar: any) => any,
  getCalendarsWithParticularEmptySlot: (type: AppointmentDetailsType) => any,
  handleCalendarChange: (slotId: string) => any,
  type: AppointmentDetailsType,
};

const mapStateToProps = (state: any) => ({
  bookingCalendar: state.book.calendar,
  slotCalendars: state.book.slotCalendars,
  appointmentCalendars: state.branchDetails.calendarsList
});

const mapDispatchToProps = (dispatch: any) => ({
  changeCalendarForSelectedSlot: calendar => dispatch(changeCalendarForSelectedSlot(calendar)),
  getCalendarsWithParticularEmptySlot: type => dispatch(getCalendarsWithParticularEmptySlot(type))
});

class ClinicianInfo extends Component<Props> {

  handleChangeCalendar = calendar => {
    const { changeCalendarForSelectedSlot, appointmentCalendar, handleCalendarChange } = this.props;

    if (appointmentCalendar) {
      appointmentCalendar.id !== calendar.id && handleCalendarChange(calendar.slotId);
    } else {
      changeCalendarForSelectedSlot(calendar);
    }
  };

  handleLoadCalendars = () => {
    const { getCalendarsWithParticularEmptySlot, appointmentCalendar, appointmentCalendars, type } = this.props;

    return getCalendarsWithParticularEmptySlot(type)
      .then(calendars => {
        const availableCalendars = calendars.filter(
          calendar => appointmentCalendars.find(
          slotCalendar => {
            return calendar.id === slotCalendar.id;
          }
          )
        );

        appointmentCalendar && availableCalendars.push(appointmentCalendar);
        return formatListAsOptions(availableCalendars);
      });
  };

  render() {
    const {
      room,
      slotCalendars,
      bookingCalendar,
      appointmentCalendar,
      appointmentCalendars,
      isDisabled
    } = this.props;
    return (
      <Fragment>
        <div className="patient-care-block-row">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="appointment-calendarName"
                  message={locale.Appointment.clinicianInfoCalendar}
                  size="small"
                />
              </FormItem>
            </Form>
            {
              appointmentCalendar
                ? (
                  <AsyncSelect
                    defaultOptions
                    id="appointment-calendarName"
                    name="appointment-calendarName"
                    defaultValue={formatItemAsOption(appointmentCalendar ? appointmentCalendar : bookingCalendar)}
                    loadOptions={this.handleLoadCalendars}
                    classNamePrefix="patient-care"
                    className="patient-care-select"
                    onChange={this.handleChangeCalendar}
                    isDisabled={isDisabled}
                  />
                )
                : (
                  <Select
                    id="appointment-calendarName"
                    name="appointment-calendarName"
                    defaultValue={formatItemAsOption(appointmentCalendar ? appointmentCalendar : bookingCalendar)}
                    options={formatListAsOptions(appointmentCalendar ? appointmentCalendars : slotCalendars)}
                    classNamePrefix="patient-care"
                    className="patient-care-select"
                    onChange={this.handleChangeCalendar}
                    isDisabled={isDisabled}
                  />
                )
            }
          </div>
          {room && (
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="appointment-room"
                    message={locale.Appointment.clinicianInfoRoom}
                    size="small"
                  />
                </FormItem>
              </Form>
              <span className="patient-care-filled-input"> {room.name}</span>
            </div>
          )}
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicianInfo);
