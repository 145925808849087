import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ResponsiveContainer, ComposedChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { SkeletonChart } from 'components/Pages/Reports/skeleton';
import { formatNumber } from 'helpers/formatData';
import * as ColorConstants from 'constants/ColorConstants';
import * as RolesConstants from 'constants/RolesConstants';
import { ReportsTooltip } from 'components/Pages/Reports/wrappers';
import './styles.scss';

// SuperAdmin: 376px

type Props = {
  currentRole:any,
  isResizing: boolean,
  nominationRequests: any,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  filters: state.reportsSmartPharmacy.filters,
  nominationRequests: state.reportsSmartPharmacy.metrics.nomination.data,
});

class ChartNominations extends Component<Props> {
  formatXAxis = (tickItem) => {
    return moment(tickItem).format('MMMM');
  }

  formatYAxis = (tickItem) => {
    return formatNumber(tickItem);
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      return (
        <ReportsTooltip>
          <h4>{payload[0] ? <Fragment>{moment(payload[0].payload.date).format('MMMM YYYY')} nominations</Fragment> : null}</h4>
          <ul>
            {payload.map((item, index) => {
              return <li key={index}><span><strong>{item.name}:</strong></span><span>{formatNumber(item.value)}</span></li>;
            })}
          </ul>
        </ReportsTooltip>
      );
    }
    return null;
  }

  render() {
    const { isResizing, filters, nominationRequests, currentRole } = this.props;
    const isOrgVisible = filters.organisationId === "" ? true : false;

    let height = 376;
    if (currentRole.role === RolesConstants.BRANCH_ADMIN) {
      height = 167;
    }
    else if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) {
      height = 283;
    } else if (currentRole.role === RolesConstants.ADMIN && !isOrgVisible) {
      height = 283;
    }

    if (isResizing) {
      return (
        <SkeletonChart height={height} />
      );
    }
    
    return (
      <ResponsiveContainer className="recharts-nominations" width="99%" height={height}>
        <ComposedChart data={nominationRequests}>
          <XAxis tickFormatter={this.formatXAxis} tickLine={false} tickSize={16} axisLine={false} dataKey="date" scale="band" />
          <YAxis tickFormatter={this.formatYAxis} tickLine={false} tickSize={16} axisLine={false} />
          <CartesianGrid strokeDasharray="0 0" stroke={ColorConstants.COLOR_GREY_LIGHT} vertical={false} />
          <Bar dataKey="newNomination" fill={ColorConstants.COLOR_GRAPH_TWO} name="Nominations this month" maxBarSize={80} />
          <Tooltip content={this.formatTooltip} cursor={{ stroke: `${ColorConstants.COLOR_SUPPORT_ONE}`, strokeWidth: 0 }} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};

export default connect(mapStateToProps)(ChartNominations);
