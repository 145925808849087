import moment from "moment";
import type { Action } from "types/actions";
import * as AppointmentConstants from "constants/AppointmentConstants";
import * as CalendarViewConstants from "constants/CalendarViewConstants";
import type { CalendarView } from "constants/AppointmentConstants";

type CalendarViewState = {
  events: any[],
  calendarList: any[],
  currentView: CalendarView,
  currentDate: Date,
  resourceMap: any[] | null,
  branchName: string,
  calendarFilters: string[],
  serviceFilters: string[],
  statusFilters: Object,
  isShowCancellation: boolean,
  timeInterval: {
    startTime: number,
    endTime: number
  },
  selectedSlot: number | null,
  branchResources: any[]
};

const initialState = {
  events: [],
  calendarList: [],
  currentView: AppointmentConstants.CALENDAR_VIEWS.WEEK,
  currentDate: moment.utc().toDate(),
  resourceMap: [],
  branchName: "",
  calendarFilters: [],
  serviceFilters: [],
  statusFilters: {},
  isShowCancellation: true,
  timeInterval: {
    startTime: 0,
    endTime: 0
  },
  selectedSlot: null,
  branchResources: []
};

export default (
  localState: CalendarViewState = initialState,
  action: Action
): CalendarViewState => {
  switch (action.type) {
    case CalendarViewConstants.SET_CALENDAR_EVENTS:
      return {
        ...localState,
        events: action.payload
      };
      case CalendarViewConstants.SET_CALENDAR_LIST:
      return {
        ...localState,
        calendarList: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_DATE:
      return {
        ...localState,
        currentDate: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_PERIOD:
      return {
        ...localState,
        currentView: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_RESOURCES:
      return {
        ...localState,
        resourceMap: action.payload
      };
    case CalendarViewConstants.SET_BRANCH_RESOURCES:
      return {
        ...localState,
        branchResources: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_TIME_INTERVAL:
      return {
        ...localState,
        timeInterval: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime
        }
      };
    case CalendarViewConstants.SET_CALENDAR_FILTERS:
      return {
        ...localState,
        calendarFilters: action.payload
      };
    case CalendarViewConstants.SET_BRANCH_NAME:
      return {
        ...localState,
        branchName: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_ORGANISATION_ID:
      return {
        ...localState,
        organisationId: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_BRANCH_ID:
      return {
        ...localState,
        branchId: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_SERVICE_FILTERS:
      return {
        ...localState,
        serviceFilters: action.payload
      };
    case CalendarViewConstants.SET_CALENDAR_CANCELLATION_VIEW:
      return {
        ...localState,
        isShowCancellation: action.payload
      };
    case CalendarViewConstants.SHOW_SELECTED_SLOT:
      return {
        ...localState,
        selectedSlot: action.payload ? action.payload : null
      };
      case CalendarViewConstants.SET_CALENDAR_APPOINTMENT_STATUS_FILTERS:
      return {
        ...localState,
        statusFilters: action.payload
      };
    default:
      return localState;
  }
};
