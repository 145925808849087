import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTable, useSortBy } from 'react-table';
import { Form, IconCog } from '@patient-access/ui-kit';
import { IconSort, IconAlert } from 'svg';
import locale from 'service/locale';
import './styles.scss';

export const columns = [
  {
    className: 'cell-alert',
    Header: locale.OrganisationCalendars.table.headers.alert,
    accessor: 'alert',
  },
  {
    className: 'cell-main',
    Header: locale.OrganisationCalendars.table.headers.name,
    accessor: 'name',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
    sortType: 'basic',
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationCalendars.table.headers.branch,
    accessor: 'branchName',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
    sortType: 'basic',
  },
  {
    className: 'cell-medium',
    Header: locale.OrganisationCalendars.table.headers.startDate,
    accessor: 'start',
    Cell: ({ cell: { value } }) => <span>{moment.utc(value).format("DD MMM YYYY")}</span>,
    sortType: 'basic',
  },
  {
    className: 'cell-medium',
    Header: locale.OrganisationCalendars.table.headers.endDate,
    accessor: 'end',
    Cell: ({ cell: { value } }) => <span>{value ? moment.utc(value).format("DD MMM YYYY") : <em>{locale.OrganisationCalendars.table.labels.none}</em>}</span>,
    sortType: 'basic',
  },
  {
    className: 'cell-small',
    Header: locale.OrganisationCalendars.table.headers.services,
    accessor: 'servicesNumber',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
    sortType: 'basic',
  },
];

export function TableCalendars({ columns, data, props }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: 'name', desc: false }],
      },
    },
    useSortBy,
  );
  const [ isMenuOn, setMenuOn ] = useState(false);

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div className="react-table-settings-background" onClick={() => { setMenuOn(!isMenuOn); }} />
          <div className="react-table-settings-menu">
            <h2>{locale.OrganisationCalendars.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map(column => {
                if (column.id === 'name') return null;
                return (
                  <div className="input-checkbox input-checkbox-small" key={column.id}>
                    <label htmlFor={column.id}>
                      <input id={column.id} name={column.id} type="checkbox" {...column.getToggleHiddenProps()} />
                      <span>{column.Header}</span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  }

  const renderCell = (row, cell) => {
    if (cell.column.id === 'alert') {
      if (cell.value === 3) return <i className="group warning"><span title={locale.OrganisationCalendars.table.labels.labelAlert3}><IconAlert outline={false} /></span></i>;
      if (cell.value === 2) return <i className="group caution"><span title={locale.OrganisationCalendars.table.labels.labelAlert2}><IconAlert outline={false} /></span></i>;
      if (cell.value === 1) return <i className="group caution"><span title={locale.OrganisationCalendars.table.labels.labelAlert1}><IconAlert outline={false} /></span></i>;
      return null;
    }
    if (cell.column.id === 'name') {
      return (
        <Link to="#details" title={cell.value} onClick={(e) => { e.preventDefault(); props.getBranchDetails(props.organisationId, cell.row.original.branchId); props.openCalendarDetails(); props.setCalendarDetailsOverlayData({ calendarId: cell.row.original.id, branchId: cell.row.original.branchId }); }}>
          {cell.render('Cell')}
        </Link>
      );
    }
    return <Fragment>{cell.render('Cell')}</Fragment>;
  }

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div className="react-table-settings-button" onClick={() => { setMenuOn(!isMenuOn); }}>
          <i><IconCog outline={false} /></i>
        </div>
      </div>
      <table className="react-table organisation-calendars spacing-medium" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="sticky grey">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())} title={locale.OrganisationCalendars.table.sortByLabel(column.Header)}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {renderCell(row, cell)}
                      </td>
                    );
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
