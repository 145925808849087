import * as ReportsConstants from "constants/ReportsSmartPharmacyConstants";

type ReportsSmartPharmacy = {
  // TODO
}

const initialState: ReportsSmartPharmacy = {
  filters: {
    organisationId: "",
    branchId: "",
    careProviderId: "",
    startDate: ReportsConstants.DEFAULT_REPORTSMARTPHARMACY_STARTDATE,
    endDate: ReportsConstants.DEFAULT_REPORTSMARTPHARMACY_ENDDATE
  },
  search: {
    selectedOrganisation: {},
    selectedBranch: {},
  },
  metrics: {
    summary: {
      data: {
        nominations: 0,
        previousNominations: 0,
        totalFees: 0,
        unitPrice: 0,
        smartPharmacyEnabledBranches: 0,
        smartPharmacyEnabledOrganisations: 0,
        lastUpdatedDateTime: null,
        isSubscriptionModel: false
      },
      isLoading: false,
      isError: false,
    },
    nomination: {
      data: [],
      isLoading: false,
      isError: false,
    },
    medication: {
      data: [],
      isLoading: false,
      isError: false,
    },
    prescription: {
      data: [],
      isLoading: false,
      isError: false,
    },
  },
  download: {
    data: [],
    isOverlayVisible: false,
    isLoading: false,
    isError: false
  },
};

export default (
  localState: ReportsSmartPharmacy = initialState,
  action: Action
): ReportsSmartPharmacy => {
  switch (action.type) {
    case ReportsConstants.SET_REPORTSMARTPHARMACY_DROPDOWN_ORGANISATION:
      return {
        ...localState,
        search: {
          ...localState.search,
          selectedOrganisation: action.payload,
        }
      };
    case ReportsConstants.SET_REPORTSMARTPHARMACY_DROPDOWN_BRANCH:
      return {
        ...localState,
        search: {
          ...localState.search,
          selectedBranch: action.payload,
        }
      };
    case ReportsConstants.CLEAR_REPORTSMARTPHARMACY_DROPDOWN_BRANCH:
      return {
        ...localState,
        search: {
          ...localState.search,
          selectedBranch: initialState.selectedBranch,
        }
      };
    case ReportsConstants.SET_REPORTSMARTPHARMACY_FILTER:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          ...action.payload
        }
      };
    case ReportsConstants.RESET_REPORTSMARTPHARMACY_FILTER_ORGANISATION:
      return {
        ...localState,
        search: {
          ...localState.search,
          selectedOrganisation: {},
        },
        filters: {
          ...localState.filters,
          organisationId: "",
        }
      };
    case ReportsConstants.RESET_REPORTSMARTPHARMACY_FILTER_BRANCH:
      return {
        ...localState,
        search: {
          ...localState.search,
          selectedBranch: {},
        },
        filters: {
          ...localState.filters,
          branchId: "",
          careProviderId: "",
        }
      };
    case ReportsConstants.RESET_REPORTSMARTPHARMACY_FILTER_REST:
      return {
        ...localState,
        filters: {
          ...localState.filters,
          startDate: ReportsConstants.DEFAULT_REPORTSMARTPHARMACY_STARTDATE,
          endDate: ReportsConstants.DEFAULT_REPORTSMARTPHARMACY_ENDDATE
        }
      };
    case ReportsConstants.SET_SMARTPHARMACY_SUMMARYREPORT:
      const { summary, smartPharmacyInformation, isSubscriptionModel } = action.payload;
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          summary: {
            ...localState.metrics.summary,
            data: {
              ...summary,
              ...smartPharmacyInformation,
              isSubscriptionModel
            }
          }
        }
      };
    case ReportsConstants.SET_SMARTPHARMACY_NOMINATIONREPORT:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          nomination: {
            ...localState.metrics.nomination,
            data: action.payload,
          }
        }
      };
    case ReportsConstants.SET_SMARTPHARMACY_MEDICATIONREPORT:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          medication: {
            ...localState.metrics.medication,
            data: action.payload,
          }
        }
      };
    case ReportsConstants.SET_REPORTSMARTPHARMACY_DOWNLOAD_OVERLAY:
      return {
        ...localState,
        download: {
          ...localState.download,
          isOverlayVisible: action.payload,
        },
      };
    case ReportsConstants.GET_REPORTSMARTPHARMACY_DOWNLOAD_PENDING:
      return {
        ...localState,
        download: {
          ...localState.download,
          data: initialState.download.data,
          isLoading: true,
          isError: false
        },
      };
    case ReportsConstants.GET_REPORTSMARTPHARMACY_DOWNLOAD_SUCCESS:
      return {
        ...localState,
        download: {
          ...localState.download,
          isLoading: false,
          isError: false
        },
      };
    case ReportsConstants.GET_REPORTSMARTPHARMACY_DOWNLOAD_ERROR:
      return {
        ...localState,
        download: {
          ...localState.download,
          isLoading: false,
          isError: true
        },
      };
    case ReportsConstants.SET_REPORTSMARTPHARMACY_DOWNLOAD_ITEMS:
      return {
        ...localState,
        download: {
          ...localState.download,
          data: action.payload
        },
      };
    case ReportsConstants.GET_MEDICATION_REQUESTS_PENDING:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          medication: {
            ...localState.metrics.medication,
            isLoading: true,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_MEDICATION_REQUESTS_SUCCESS:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          medication: {
            ...localState.metrics.medication,
            isLoading: false,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_MEDICATION_REQUESTS_ERROR:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          medication: {
            ...localState.metrics.medication,
            isLoading: false,
            isError: true
          }
        }
      };
    case ReportsConstants.GET_SUMMARY_REPORT_PENDING:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          summary: {
            ...localState.metrics.summary,
            isLoading: true,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_SUMMARY_REPORT_SUCCESS:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          summary: {
            ...localState.metrics.summary,
            isLoading: false,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_SUMMARY_REPORT_ERROR:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          summary: {
            ...localState.metrics.summary,
            isLoading: false,
            isError: true
          }
        }
      };
    case ReportsConstants.GET_NOMINATION_REQUESTS_PENDING:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          nomination: {
            ...localState.metrics.nomination,
            isLoading: true,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_NOMINATION_REQUESTS_SUCCESS:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          nomination: {
            ...localState.metrics.nomination,
            isLoading: false,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_NOMINATION_REQUESTS_ERROR:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          nomination: {
            ...localState.metrics.nomination,
            isLoading: false,
            isError: true
          }
        }
      };
    case ReportsConstants.SET_SMARTPHARMACY_PRESCRIPTIONREPORT:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          prescription: {
            ...localState.metrics.prescription,
            data: action.payload,
          }
        }
      };
    case ReportsConstants.GET_PRESCRIPTION_REQUESTS_PENDING:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          prescription: {
            ...localState.metrics.prescription,
            isLoading: true,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_PRESCRIPTION_REQUESTS_SUCCESS:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          prescription: {
            ...localState.metrics.prescription,
            isLoading: false,
            isError: false
          }
        }
      };
    case ReportsConstants.GET_PRESCRIPTION_REQUESTS_ERROR:
      return {
        ...localState,
        metrics: {
          ...localState.metrics,
          prescription: {
            ...localState.metrics.prescription,
            isLoading: false,
            isError: true
          }
        }
      };
    default:
      return localState;
  }
};
