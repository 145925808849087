import { SET_FILTER, CLEAR_FILTER } from './../constants/FilterConstants.js';

const initialState = {};

export default (localState: Object = initialState, action: any): Object => {
  switch (action.type) {
    case SET_FILTER:
      if (action.payload)
        return {
          ...localState,
          ...action.payload
        };
      return initialState;
    case CLEAR_FILTER:
      return {};
    default:
      return localState
  }
}
