import React, { Component } from "react";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import moment from "moment";
import { NoResults, Skeleton } from "@patient-access/ui-kit";

import { checkTodayDate } from "helpers/validateData";
import AppointmentItem from "../AppointmentItem/AppointmentItem";
import ScrollUpToToday from "components/Share/ScrollUpToToday/ScrollUpToToday";
import { setHiddenAttributeToAppointmentsByCalendar } from "helpers/common";
import locale from "service/locale";
import "./styles.scss";

type Props = {
  isActiveAgendaDetails: boolean,
  agendaAppointments: any[],
  getAppointmentsPending: boolean,
  filterCalendarId: string,
  openedAppointmentId: string,
};

type State = {
  showScrollButton: boolean,
  filteredAppointments: any[]
};

const mapStateToProps = state => ({
  agendaAppointments: state.agenda,
  getAppointmentsPending: state.appointments.getAppointmentsPending,
  openedAppointmentId: state.appointmentDetails.appointmentId,
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails
});
export class AppointmentsList extends Component<Props, State> {
  state = {
    showScrollButton: false,
    filteredAppointments: this.props.agendaAppointments,
  };

  events = [
    "scroll",
    "mousewheel",
    "DOMMouseScroll",
    "MozMousePixelScroll",
    "resize",
    "touchmove",
    "touchend"
  ];

  componentDidMount = () => {
    const { appointmentList } = this.refs;
    appointmentList &&
      this.events.forEach(event => {
        findDOMNode(appointmentList).addEventListener(
          event,
          this.onScroll.bind(this),
          false
        );
      });
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (this.props.filterCalendarId !== nextProps.filterCalendarId || this.props.agendaAppointments !== nextProps.agendaAppointments) {
      setHiddenAttributeToAppointmentsByCalendar(nextProps.agendaAppointments, nextProps.filterCalendarId);
      this.setState({
        filteredAppointments: nextProps.agendaAppointments.filter(date => !date.isHiddenDay)
      });
    }
  };

  componentWillUnmount = () => {
    const { appointmentList } = this.refs;
    appointmentList &&
      this.events.forEach(event => {
        findDOMNode(appointmentList).removeEventListener(
          event,
          this.onScroll.bind(this),
          false
        );
      });
  };

  onScroll = () => {
    const { appointmentList, topElement } = this.refs;
    const topElem = topElement || appointmentList;
    const topPosition = appointmentList.offsetTop;
    const position = topElem && topElem.getBoundingClientRect().top;
    this.setState({ showScrollButton: position < topPosition });
  };

  handleScrollClick = () => {
    const { appointmentList } = this.refs;
    appointmentList.scrollTop = 0;
  };

  render() {
    const {
      getAppointmentsPending,
      filterCalendarId,
      openedAppointmentId,
      isActiveAgendaDetails
    } = this.props;
    const { showScrollButton, filteredAppointments } = this.state;

    return filteredAppointments.length ? (
      <div
        className={`patient-care-sidebar-agenda-body${
          showScrollButton ? "-with-btn" : ""
        }`}
        ref="appointmentList"
      >
        {filteredAppointments.map((date, index) => (
          <div className="patient-care-search-items" key={date.date}>
            <div
              className={`patient-care-sidebar-date ${
                checkTodayDate(date.date) ? "active" : ""
              }`}
              ref={index === 0 ? "topElement" : null}
            >
              <span>
                {moment(date.date).format("dddd D MMM")}{" "}
                {checkTodayDate(date.date) ? "(today)" : ""}
              </span>
            </div>
            {date.appointments.map(appointment => {
              if (filterCalendarId === appointment.calendar.id || filterCalendarId === "all")
                return (
                  <AppointmentItem
                    key={appointment.appointmentId}
                    appointment={appointment}
                    selected={isActiveAgendaDetails && openedAppointmentId === appointment.appointmentId}
                  />
                );
              else return null;
            })}
          </div>
        ))}
        {showScrollButton && (
          <ScrollUpToToday onScrollClick={this.handleScrollClick} />
        )}
      </div>
    ) : getAppointmentsPending ? (
      <Skeleton type="basic" className="appointments-skeleton" />
    ) : (
      <NoResults type="info">
        <h2>{locale.Agenda.agendaEmpty}</h2>
      </NoResults>
    );
  }
}

export default connect(mapStateToProps)(AppointmentsList);
