import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { LinkIcon, IconClose } from "@patient-access/ui-kit";

import { updateForm, clearForm } from "actions/form";
import { closeAddOrganisation } from "actions/panel";

import AddOrganisationSearch from "./AddOrganisationSearch/AddOrganisationSearch";
import AddOrganisationCompanyInfo from "./AddOrganisationCompanyInfo/AddOrganisationCompanyInfo";
import AddOrganisationAddressInfo from "./AddOrganisationAddressInfo/AddOrganisationAddressInfo";
import AddOrganisationButtons from "./AddOrganisationButtons/AddOrganisationButtons";
import AddOrganisationType from "./AddOrganisationType/AddOrganisationType";
import { drawerScrollHandler } from 'helpers/common';
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';

import locale from "service/locale";
import AddOrganisationBookingUrl from "./AddOrganisationBookingUrl/AddOrganisationBookingUrl";
import AddSoleTraderHeader from "./AddSoleTraderHeader/AddSoleTraderHeader";
import AddSoleTraderOrganisationInfo from "./AddSoleTraderOrganisationInfo/AddSoleTraderOrganisationInfo";
import AddSoleTraderOrganisationName from "./AddSoleTraderOrganisationName/AddSoleTraderOrganisationName";
import AddTwoFactorAuthentication from "./AddTwoFactorAuthentication/AddTwoFactorAuthentication"

type Props = {
  form: any,
  isActiveAgenda: boolean,
  updateForm: (organisation: any) => any,
  clearForm: () => any,
  closeAddOrganisation: () => any,
  handleOpenDiscardDialog: () => any,
};

type State = {
  isScrolledStart: boolean,
  isScrolledEnd: boolean,
  isSoleTrader: boolean,
}

const mapStateToProps = state => ({
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (organisation: any) => dispatch(updateForm(organisation)),
  clearForm: () => dispatch(clearForm()),
  closeAddOrganisation: () => dispatch(closeAddOrganisation())
});

export class AddOrganisationModal extends Component<Props, State> {

  state = {
    isScrolledStart: false,
    isScrolledEnd: false,
    isSoleTrader: false,
  };

  handleSelectOrganisation = (company: Object) => {
    const { clearForm, updateForm } = this.props;

    clearForm();
    updateForm(company);
    this.setState({ isScrolledEnd: true });
  };

  handleDiscard = (e: any) => {
    e && e.preventDefault();
    const { handleOpenDiscardDialog } = this.props;
    handleOpenDiscardDialog();
  };

  handleOrganisationTypeChange = (organisationType: any) => {
    const { updateForm } = this.props;
    this.setState({
      new_org_type: organisationType,
    });
    updateForm({
      organisationType: organisationType,
      booking: {
        mode: organisationType && organisationType.mode
      }
    })
  }

  handleTwoFactorChange = (twoFactorValue) => {
    const { updateForm } = this.props;
    this.setState({
      two_factor_value: twoFactorValue
    });

    updateForm({
      twoFactorValue: twoFactorValue
    })
  }

  handleRegisteredCompany = (e: any) => {
    e && e.preventDefault();
    const { clearForm } = this.props;
    clearForm();
    this.setState({ isSoleTrader: false });
  }

  handleSoleTrader = (e: any) => {
    e && e.preventDefault();
    const { clearForm } = this.props;
    clearForm();
    this.setState({ isSoleTrader: true });
  }

  render() {
    const {
      isActiveAgenda,
      closeAddOrganisation,
      form,
      handleOpenDiscardDialog
    } = this.props;
    const { isScrolledStart, isScrolledEnd, new_org_type, isSoleTrader } = this.state;

    return (
      <div className={`patient-care-modal-full-height-${isActiveAgenda ? "with-agenda-holder" : "holder"}`}>
        <div
          className="patient-care-modal-full-height"
          onScroll={drawerScrollHandler.bind(this)}
        >
          <div
            className={`patient-care-modal-header ${isScrolledStart ? "has-shadow" : ""}`}
            ref="modalHeader"
          >
            <h2>{locale.AddOrganisation.addOrganisationTitle}</h2>
            <div className="patient-care-btn-close">
              <LinkIcon
                to="#"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.AddOrganisation.addOrganisationCloseIcon}
                onClick={form.companyNumber ? this.handleDiscard : closeAddOrganisation}
              />
            </div>
          </div>
          <div className="patient-care-modal-content" ref="modalContent">
            <div id="patient-care-scrolling-content" ref="modalContentScroll">
              {
                isSoleTrader
                  ?
                  (
                    <Fragment>
                      <AddSoleTraderHeader handleRegisteredCompany={this.handleRegisteredCompany} />
                      <AddSoleTraderOrganisationName />
                      <AddOrganisationType handleOrganisationTypeChange={this.handleOrganisationTypeChange} />
                      <AddTwoFactorAuthentication handleTwoFactorChange={this.handleTwoFactorChange}/>
                      {
                        new_org_type && new_org_type.type === ORGANISATION_TYPE.AFFILIATE &&
                        (
                          <AddOrganisationBookingUrl />
                        )
                      }                      
                      <AddSoleTraderOrganisationInfo />                                          
                    </Fragment>
                  )
                  :
                  (
                    <Fragment>
                      <AddOrganisationSearch handleSelectOrganisation={this.handleSelectOrganisation} handleSoleTrader={this.handleSoleTrader} />
                      {form.companyNumber &&
                        <Fragment>
                          <AddOrganisationCompanyInfo />
                          <AddOrganisationType handleOrganisationTypeChange={this.handleOrganisationTypeChange} />
                          <AddTwoFactorAuthentication handleTwoFactorChange={this.handleTwoFactorChange}/>
                          {
                            new_org_type && new_org_type.type === ORGANISATION_TYPE.AFFILIATE &&
                            (
                              <AddOrganisationBookingUrl />
                            )
                          }
                          <AddOrganisationAddressInfo />
                        </Fragment>
                      }
                    </Fragment>
                  )
              }
            </div>
          </div>
          <AddOrganisationButtons
            handleOpenDiscardDialog={handleOpenDiscardDialog}
            ref="modalAddOrDiscardButtons"
            customClassName={isScrolledEnd ? "has-shadow" : ""}
            isSoleTrader={isSoleTrader}
          />
        </div>
        <span className="patient-care-modal-overlay" />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisationModal);
