import React, {Component, Fragment} from 'react';
import locale from 'service/locale';

export default class BranchType extends Component<Props, State>{
 render(){
     const {isVirtual} = this.props;
     const branchType = isVirtual ? locale.BranchSettings.type.online.label : locale.BranchSettings.type.physical.label;

    return (
     <Fragment>
       <div className="patient-care-description-block">
         <div className="patient-care-heading-row">
           <h3>{locale.BranchSettings.typeTitle}</h3>
         </div>
         <p>{locale.BranchSettings.typeHint}</p>
       </div>
       <div className="patient-care-block">
         <span className="patient-care-filled-input patient-care-filled-input-full">
           {branchType}
         </span>
       </div>
       <hr className="patient-care-separator" />
     </Fragment>
    )
 }
}