import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import locale from "service/locale";
import { Form, FormItem, IconPin, IconOnline, Tooltip } from "@patient-access/ui-kit";
import { updateForm } from "actions/form";
import * as RolesConstants from "constants/RolesConstants";
import AddBranchTypeRadio from "./AddBranchTypeRadio";

import "./styles.scss";

type Props = {
  currentRole: object,
  organisationId?: string,
};

const mapStateToProps = (state) => ({
  form: state.form,
  currentRole: state.roles.profileCurrentRole,
  hasVirtualBranch: state.organisationDetails.hasVirtualBranch
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class AddBranchType extends Component<Props, State> {
  componentDidMount = () => {
    const { updateForm } = this.props;
    updateForm({
      isVirtual: false,
    });
  };

  handleSelectBranchType = (e: Event) => {
    const {
      target: { value },
    } = e;
    const { updateForm } = this.props;

    updateForm({
      isVirtual: value === locale.AddBranch.type.online.value,
    });
  };

  render() {
    const { form, currentRole, organisationId } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isVirtual = form.isVirtual;
    const hasVirtualBranch = this.props.hasVirtualBranch || form.hasVirtualBranch;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{`${organisationId && isSuperAdmin ? "1. " : "2. "}${
            locale.AddBranch.typeTitle
          }`}</h3>
          <p>{locale.AddBranch.typeText}</p>
        </div>
        <div className="patient-care-radio-group">
          <Form>
            <FormItem type="item">
              <Tooltip
                vertical="bottom"
                horizontal="center"
                message={locale.AddBranch.tooltip.physical}
              >
              <AddBranchTypeRadio
                id="physical"
                name="branchType"
                value="physical"
                label={locale.AddBranch.type.physical.label}
                onClick={this.handleSelectBranchType}
                icon={<IconPin outline={false} />}
                checked={hasVirtualBranch || !isVirtual}
              />
              </Tooltip>
              <Tooltip
                vertical="bottom"
                horizontal="center"
                message={hasVirtualBranch ? locale.AddBranch.tooltip.onlineDisabled : locale.AddBranch.tooltip.online}
              >
              <AddBranchTypeRadio
                id="online"
                name="branchType"
                value="online"
                label={locale.AddBranch.type.online.label}
                onClick={this.handleSelectBranchType}
                icon={<IconOnline outline />}
                checked={isVirtual && form.organisationId && !hasVirtualBranch}
                isDisabled = {!form.organisationId || hasVirtualBranch}
              />
              </Tooltip>
            </FormItem>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBranchType);
