import React, { Component } from "react";

import locale from "service/locale";
import "./styles.scss";

type Props = {
  bookingMethod: number,
  price: number,
  paymentStatus: number,
  status: number,
  outcomeStatus: number,
  isPastAppointment: boolean,
  isTodayAppointment: boolean,
  startStatus: number,
  handleRefundDialog: () => any,
  handlePaymentStatusChange: (newPaymentStatus: number) => any
};

class PaymentStatus extends Component<Props> {

  changePaymentStatus = (e?: Event) => {
    e && e.preventDefault();
    const { handlePaymentStatusChange, status, paymentStatus, startStatus, outcomeStatus } = this.props;
    if (
      startStatus === locale.Appointment.status.booked.status ||
      (
        startStatus === locale.Appointment.status.provided.status &&
        status === locale.Appointment.status.provided.status &&
        outcomeStatus === locale.Appointment.status.provided.outcomeStatus
      )
    ) {
      const newPaymentStatus = paymentStatus === locale.Appointment.paymentStatus.notPaid.value
          ? locale.Appointment.paymentStatus.paid.value
          : locale.Appointment.paymentStatus.notPaid.value;
      handlePaymentStatusChange(newPaymentStatus);
    }
  };

  render() {
    const { bookingMethod, price, paymentStatus, isRefundedbySupportTeam } = this.props;
    const isFreeAppointment = price === 0;
    const isPaid = paymentStatus === locale.Appointment.paymentStatus.paid.value;
    const isNotPaid = paymentStatus === locale.Appointment.paymentStatus.notPaid.value;
    const isRefunded = paymentStatus === locale.Appointment.paymentStatus.refunded.value && !isRefundedbySupportTeam;
    return (
      <div className="patient-care-block">
        <div className="patient-care-payment-row">
          <div className="patient-care-payment-item">
            <span className="h3 patient-care-title-in-row">
              {locale.Appointment.paymentStatus.price}:
            </span>
            <span className="h1">£{price.toFixed(2)}</span>
          </div>
          {!isFreeAppointment && (
            <div className="patient-care-payment-item">
              <span className="h3 patient-care-title-in-row">
                {locale.Appointment.paymentStatus.status}:
              </span>
              {bookingMethod === locale.Appointment.type.online.value ? (
                <b>
                  {isRefunded && locale.Appointment.paymentStatus.refunded.title}
                  {isPaid && locale.Appointment.paymentStatus.paidOnline}
                  {isNotPaid && locale.Appointment.paymentStatus.notPaid.title}
                  {isRefundedbySupportTeam && locale.Appointment.paymentStatus.refundedbySupportTeam.title}
                </b>
              ) : (
                <ul className="patient-care-unstyled-list patient-care-status-list">
                  <li
                    onClick={this.changePaymentStatus}
                    className={`patient-care-status-item ${isNotPaid ? "active" : ""}`}>
                    {locale.Appointment.paymentStatus.notPaid.title}
                  </li>
                  <li
                    onClick={this.changePaymentStatus}
                    className={`patient-care-status-item ${isPaid? "active" : ""}`}>
                    {locale.Appointment.paymentStatus.paid.title}
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
        <hr className="patient-care-separator-small" />
      </div>
    );
  }
}

export default PaymentStatus;
