
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getAppointmentDateAndTime } from "helpers/formatData";
import {
  Icon,
  IconPin,
  IconAppointment,
  Form,
  FormItem,
  InputLabel
} from "@patient-access/ui-kit";
import moment from "moment";
import locale from "service/locale";

type Props = {
  eventDetails?: {
    endTime: string,
    startTime: string,
    name: string,
    bookTime: string,
  },
  branchName: string | null,
};

const mapStateToProps = (state: any) => ({
  branchName: state.calendarView.branchName
});

class InternalEventInfo extends Component<Props, State> {
  render() {
    const { branchName, eventDetails } = this.props

    return (
      <div className="patient-care-block">
        <ul className="patient-care-appointment-details-list">
          <li className="patient-care-appointment-info-item">
            <h3>{getAppointmentDateAndTime(eventDetails.startTime, eventDetails.endTime)}</h3>
          </li>
          <li className="patient-care-appointment-info-item">
            <h3>{eventDetails.name}</h3>
          </li>
        </ul>
        <ul className="patient-care-appointment-details-list">
          <li className="patient-care-appointment-info-item">
            <Icon
              type="inline"
              size="small"
              icon={<IconPin outline={false} />}
            />
            <span className="patient-care-appointment-details-text">
              {branchName}
            </span>
          </li>
          {eventDetails && eventDetails.bookTime && (
            <Fragment>
              <li className="patient-care-appointment-info-item">
                <Icon
                  type="inline"
                  size="small"
                  icon={<IconAppointment outline={false} />}
                />
                <span className="patient-care-appointment-details-text">
                  Event created on{" "}
                  {moment(eventDetails.bookTime).format("DD/MM/YYYY [at] HH:mm")}
                </span>
              </li>
            </Fragment>
          )}
          <li className="patient-care-appointment-info-item">
            <Form>
              <FormItem type="item">
                <InputLabel
                  htmlFor="calendar_name"
                  message={locale.InternalEventDetails.calendarLabel}
                  size="small"
                />
              </FormItem>
            </Form>
            <b className="patient-care-filled-input">
              {eventDetails.calendarName}
            </b>
          </li>
        </ul>
      </div>
    )
  }
}

export default connect(
  mapStateToProps
)(InternalEventInfo);
