import React, { Component, Fragment } from 'react';
import { Link as ScrollTo } from 'react-scroll';
import { connect } from 'react-redux';
import { Button, IconCheckCircle } from '@patient-access/ui-kit';
import {
  Feature,
  FeatureMenu,
  FeatureContent,
  FeatureContentScroll,
} from 'components/Share/feature';
import OrganisationSidebar from '../OrganisationSidebar/OrganisationSidebar';
import { getOrganisationDetails } from 'actions/organisationDetails';
import locale from 'service/locale';
import agent from 'service/agent';

import './styles.scss';

type State = {
  isFormSubmitted: boolean,
  isFormError: boolean,
};

type Props = {
  userId: string,
  userEmail: string,
  userName: string,
  organisationId: string,
  organisationName: string,
  getOrganisationDetails: (organisationId: string) => Action,
};

const mapStateToProps = (state) => ({
  userId: state.profile.id,
  userEmail: state.profile.email,
  userName: state.profile.displayName,
  organisationName: state.organisationDetails && state.organisationDetails.name,
  organisationPostCode: state.organisationDetails && state.organisationDetails.companyAddress && state.organisationDetails.companyAddress.postcode,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  getOrganisationDetails: (organisationId: string) => dispatch(getOrganisationDetails(organisationId)),
});

class SmartPharmacyRegister extends Component<Props, State> {
  state = {
    isFormSubmitted: false,
    isFormError: false
  }

  componentDidMount = () => {
    const { getOrganisationDetails, organisationId } = this.props;
    getOrganisationDetails(organisationId);
  }

  onSubmit = async (response) => {
    const { userId, userEmail, userName, organisationId, organisationName, organisationPostCode } = this.props;

    try {
        await agent.Register.sendRegisterInterest({
        userId,
        userEmail,
        userName,
        organisationId,
        organisationName,
        organisationPostCode,
      },
      response);
      this.setState({ isFormSubmitted: true, isFormError: false });
    } catch (error) {
      this.setState({ isFormError: true });
    }
  }

  render() {
    const { isFormSubmitted, isFormError } = this.state;
    const { organisationId } = this.props;

    const renderSectionHero = () => {
      return (
        <div className="patient-care-register-section patient-care-register-hero">
          <div className="patient-care-register-hero-wrapper">
            <div className="patient-care-register-hero-image">
              <img src="/assets/images/sm-register/hero.png" alt="" />
            </div>
            <div className="patient-care-register-hero-content">
              <h1>{locale.SmartPharmacyRegister.sectionHero.header}</h1>
              <p>{locale.SmartPharmacyRegister.sectionHero.content}</p>
              <ScrollTo to="patient-care-register-more" className="ui-kit-button ui-kit-button-secondary ui-kit-button-large" containerId="scroll-to" offset={-80} duration={500} smooth="easeInOutCubic">
                <span>{locale.SmartPharmacyRegister.sectionHero.button}</span>
              </ScrollTo>
            </div>
          </div>
        </div>
      );
    }

    const renderSectionNomination = () => {
      return (
        <div className="patient-care-register-section patient-care-register-feature">
          <div className="patient-care-register-feature-wrapper">
            <div className="patient-care-register-feature-header">
              <h2>{locale.SmartPharmacyRegister.sectionNomination.header}</h2>
            </div>
            <div className="patient-care-register-feature-body lside">
              <div className="patient-care-register-feature-blob" style={{ top: '-50px', right: '410px' }}>
                <img src="/assets/images/sm-register/blob-one.svg" alt="" />
              </div>
              <div className="patient-care-register-feature-image">
                <img src="/assets/images/sm-register/nomination.png" alt="" />
              </div>
              <div className="patient-care-register-feature-content">
                <ul>
                  <li>
                    <h3>{locale.SmartPharmacyRegister.sectionNomination.featureOneHeader}</h3>
                    <p>{locale.SmartPharmacyRegister.sectionNomination.featureOneContent}</p>
                    <span className="line" />
                  </li>
                  <li>
                    <h3>{locale.SmartPharmacyRegister.sectionNomination.featureTwoHeader}</h3>
                    <p>{locale.SmartPharmacyRegister.sectionNomination.featureTwoContent}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const renderSectionProfile = () => {
      return (
        <div className="patient-care-register-section patient-care-register-feature">
          <div className="patient-care-register-feature-wrapper">
            <div className="patient-care-register-feature-header">
              <h2>{locale.SmartPharmacyRegister.sectionProfile.header}</h2>
            </div>
            <div className="patient-care-register-feature-body rside">
              <div className="patient-care-register-feature-blob" style={{ top: '-140px', left: '400px' }}>
                <img src="/assets/images/sm-register/blob-two.svg" alt="" />
              </div>
              <div className="patient-care-register-feature-image">
                <img src="/assets/images/sm-register/profile.png" alt="" />
              </div>
              <div className="patient-care-register-feature-content">
                <ul>
                  <li>
                    <h3>{locale.SmartPharmacyRegister.sectionProfile.featureOneHeader}</h3>
                    <p>{locale.SmartPharmacyRegister.sectionProfile.featureOneContent}</p>
                    <span className="line" />
                  </li>
                  <li>
                    <h3>{locale.SmartPharmacyRegister.sectionProfile.featureTwoHeader}</h3>
                    <p>{locale.SmartPharmacyRegister.sectionProfile.featureTwoContent}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const renderSectionRegister = () => {
      return (
        <div className="patient-care-register-section patient-care-register-more">
          <div className="patient-care-register-more-wrapper">
            <div className="patient-care-register-more-content">
              {renderSectionRegisterMessage()}
            </div>
            <div className="patient-care-register-more-image">
              <img src="/assets/images/sm-register/more.png" alt="" />
            </div>
          </div>
        </div>
      );
    }

    const renderSectionRegisterMessage = () => {
      if (isFormError) return <h4>{locale.SmartPharmacyRegister.sectionRegister.errorMessage}</h4>;
      if (isFormSubmitted) return <h4><IconCheckCircle outline={false} /> {locale.SmartPharmacyRegister.sectionRegister.successMessage}</h4>;
      return (
        <Fragment>
          <h2>{locale.SmartPharmacyRegister.sectionRegister.header}</h2>
          <p>{locale.SmartPharmacyRegister.sectionRegister.content}</p>
          <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.SmartPharmacyRegister.sectionRegister.button} onClick={this.onSubmit} />
        </Fragment>
      );
    }

    return (
      <Feature>
        <FeatureMenu>
          <OrganisationSidebar organisationId={organisationId} />
        </FeatureMenu>
        <FeatureContent>
          <FeatureContentScroll className="smallest" id="scroll-to">
            <div className="patient-care-register-wrapper">
              {renderSectionHero()}
              {renderSectionNomination()}
              {renderSectionProfile()}
              {renderSectionRegister()}
            </div>
          </FeatureContentScroll>
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartPharmacyRegister);
