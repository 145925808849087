import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconContract extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M17.7,15.89,13.82,12l3.89-3.89A1,1,0,1,0,16.3,6.7l-4.59,4.59a1,1,0,0,0,0,1.41l4.59,4.59a1,1,0,1,0,1.4-1.4ZM7,6A1,1,0,0,1,8,7V17a1,1,0,0,1-2,0V7A1,1,0,0,1,7,6Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-contract ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconContract;
