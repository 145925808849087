import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import moment from "moment";

import ClosedDaysItem from "./ClosedDaysItem/ClosedDaysItem";
import ClosedDaysEditItem from "./ClosedDaysEditItem/ClosedDaysEditItem";

import { updateForm } from "actions/form";
import type { Action } from 'types/actions';
import type { Calendar, ClosedDay } from 'types/calendars';
import * as RolesConstants from 'constants/RolesConstants';
import * as DateConstants from "constants/DateTimeConstants";
import locale from 'service/locale';

type Props = {
  form: any,
  currentRole: Object,
  isEditMode: boolean,
  handleEditClosedDays: (newClosedDays: ClosedDay[]) => Action,
  updateForm: (data: any) => Action,
  handleDeleteDay: () => any,
  calendar: Calendar,
};

type State = {
  newClosedDays: ClosedDay[],
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  calendar: state.calendar,
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class ClosedDays extends Component<Props, State> {

  state = {
    newClosedDays: [],
  };

  handleDeleteRole = (name: string) => {
    const { newClosedDays } = this.state;
    const { form, updateForm, handleDeleteDay } = this.props;
    const { closedDays } = form;
    const index = closedDays.findIndex(day => day.name === name);
    const newItemsIndex = newClosedDays.findIndex(day => day.name === name);

    handleDeleteDay();

    if (index !== -1) {
      const updatedDays = closedDays.slice(0);
      updatedDays.splice(index, 1);
      updateForm({
        ...form,
        closedDays: updatedDays
      });
    }

    if (newItemsIndex !== -1) {
      this.setState(() => {
        const updatedNewDays = newClosedDays.slice(0);
        updatedNewDays.splice(newItemsIndex,1);
        return {
          newClosedDays: updatedNewDays
        };
      });
    }
  };

  handleClosedDayChange = (name: string, value: string, isPastDate: boolean) => {
    const { handleEditClosedDays } = this.props;
    const { newClosedDays } = this.state;
    const newItemsIndex = newClosedDays.findIndex(day => day.name === name);

    const dateString = moment.utc(value, "YYYY-MM-DD").format(DateConstants.dateFormat);
    this.setState(() => {
      (newItemsIndex !== -1) && (newClosedDays[newItemsIndex].date = dateString);
      handleEditClosedDays(newClosedDays, isPastDate);
      return {
        newClosedDays
      };
    })
  };

  handleAddClosedDay = () => {
    const { newClosedDays } = this.state;

    if (newClosedDays[newClosedDays.length - 1] && newClosedDays[newClosedDays.length - 1].date === "") return;
    this.setState(() => {
      newClosedDays.push({
        date: "",
        name: Date.now().toString(),
      });
      return {
        newClosedDays
      };
    });
  };

  handleEditClosedDays = () => {
    const { handleEditClosedDays, form, updateForm, calendar, isEditMode } = this.props;
    const { newClosedDays } = this.state;
    let closedDays = (newClosedDays || []).filter(day => !!day.date);
    let updatedClosedDays = (isEditMode && closedDays.length) ? (form.closedDays || calendar.closedDays).concat(closedDays) : (form.closedDays || calendar.closedDays);

    updateForm({
      ...form,
      closedDays: updatedClosedDays,
    });

    this.setState({
        newClosedDays: []
    }, () => {
      handleEditClosedDays();
    });
  };

  render() {
    const { newClosedDays } = this.state;
    const { calendar, currentRole, isEditMode, form } = this.props;
    const { closedDays } = calendar;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;
    const showedClosedDays = form.closedDays || closedDays;

    return (
      <div className="patient-care-closed-days">
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>
              {locale.AvailabilitySettings.closedDays}
            </h3>

            {
              !isBranchMember &&
              <button
                className="patient-care-btn-link"
                onClick={this.handleEditClosedDays}>
                {
                  isEditMode
                  ? locale.Buttons.buttonDone
                  : locale.Buttons.buttonEdit
                }
              </button>
            }

          </div>
          <p>{locale.AvailabilitySettings.closedDaysDescription}</p>
        </div>

        <div className="patient-care-days-wrap">
          {
            (showedClosedDays && showedClosedDays.length) ? showedClosedDays.map(day =>

                <ClosedDaysItem
                  key={day.name}
                  isEditMode={isEditMode}
                  day={day}
                  handleDeleteRole={name => this.handleDeleteRole(name)}
                />
            ) : null
          }
          {
            (isEditMode && newClosedDays.length) ? newClosedDays.map(day =>
              <ClosedDaysEditItem
                key={day.name}
                isEditMode={isEditMode}
                day={day}
                handleDeleteRole={(name: string) => this.handleDeleteRole(name)}
                handleClosedDayChange={(name: string, value: string, isPastDate: boolean) => this.handleClosedDayChange(name, value, isPastDate)}
              />
            ) : null
          }
        </div>
        {
          isEditMode ? (
            <Fragment>
              <div className="patient-care-block">
                <button
                  className="patient-care-btn-link"
                  onClick={this.handleAddClosedDay}
                >
                  {locale.AvailabilitySettings.addClosedDay}
                </button>
              </div>
            </Fragment>
          ) : null
        }
        <hr className="patient-care-separator-small" />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (ClosedDays);
