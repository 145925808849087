import React, { Component } from 'react';
import './styles/calendar-filters-body-item.scss';

type Props = {
  children: any,
};

class CalendarFiltersBodyItem extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="calendar-filters-body-item">
        {children}
      </div>
    );
  }
};

export default CalendarFiltersBodyItem;
