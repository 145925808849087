import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import moment from "moment";
import { NoResults, Skeleton } from "@patient-access/ui-kit";

import { checkTodayDate } from "helpers/validateData";
import ScrollUpToToday from "components/Share/ScrollUpToToday/ScrollUpToToday";
import AppointmentDetails from "components/Share/AppointmentDetails/AppointmentDetails";
import SearchResultItem from "./SearchResultItem/SearchResultItem";
import Modal from "components/Share/Modal/Modal";
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import * as ServiceConstants from "constants/ServicesConstants";
import { getSearchResultsQuantity } from "helpers/common";
import locale from "service/locale";
import "./styles.scss";

type Props = {
  isActiveSearchDetails: boolean,
  searchResults: any[],
  isSearchResultsPending: boolean
};

type State = {
  showScrollButton: boolean,
  filteredSearch: any[]
};

const mapStateToProps = (state: any) => ({
  isActiveSearchDetails: state.panel.isActiveSearchDetails,
  searchResults: state.search.results,
  isSearchResultsPending: state.search.isSearchResultsPending
});

const SEARCH_DATE_REF_PREFIX = "search-date-ref-";
let searchDateOffsetTop = {};

class SearchResults extends Component<Props, State> {
  state = {
    showScrollButton: false,
    filteredSearch: this.props.searchResults,
    filteredSearchDateRef: this.props.searchResults.map((d, i) => `${SEARCH_DATE_REF_PREFIX}${i}`),
    searchDateStickHeaderRefId: this.props.searchResults.map((d, i) => `${SEARCH_DATE_REF_PREFIX}${i}`)[0],
  };

  events = [
    "scroll",
    "mousewheel",
    "DOMMouseScroll",
    "MozMousePixelScroll",
    "resize",
    "touchmove",
    "touchend"
  ];

  componentDidMount = () => {
    const { filteredSearchDateRef } = this.state;
    const { searchList, today } = this.refs;

    let newSearchDateOffsetTop = {};
    filteredSearchDateRef.forEach(refId => {
      const ref = this.refs[refId];
      newSearchDateOffsetTop[refId] = ref.offsetTop;
    });

    searchDateOffsetTop = newSearchDateOffsetTop;

    searchList &&
      this.events.forEach(event => {
        findDOMNode(searchList).addEventListener(
          event,
          this.onScroll.bind(this),
          false
        );
      });

    if (today) {
      searchList.scrollTop = today.offsetTop - 380;
    }
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (this.props.searchResults !== nextProps.searchResults) {
      const filteredSearch = nextProps.searchResults.filter(searchDay => !searchDay.isHiddenDay);
      const filteredSearchDateRef = filteredSearch.map((d, i) => `${SEARCH_DATE_REF_PREFIX}${i}`);
      this.setState({
        filteredSearch,
        filteredSearchDateRef,
        searchDateStickHeaderRefId: filteredSearchDateRef[0]
      });
    }
  };

  componentWillUnmount = () => {
    const { searchList } = this.refs;
    this.events.forEach(event => {
      const el = findDOMNode(searchList);
      el && el.removeEventListener(event, this.onScroll.bind(this), false);
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    const { filteredSearchDateRef } = this.state;
    const keys = Object.keys(searchDateOffsetTop);

    if (keys.length === filteredSearchDateRef.length) return;

    let newSearchDateOffsetTop = {};
    filteredSearchDateRef.forEach(refId => {
      const ref = this.refs[refId];
      newSearchDateOffsetTop[refId] = ref.offsetTop;
    });

    searchDateOffsetTop = newSearchDateOffsetTop;
  };

  getSearchDateStickHeaderRefId = () => {
    const { searchList, header } = this.refs;
    const { filteredSearchDateRef } = this.state;

    let newSearchDateStickHeaderRefId = null;
    const scrollTop = searchList.scrollTop + header.clientHeight;
    for (let i = 0; i < filteredSearchDateRef.length ; i++) {
      const refId = filteredSearchDateRef[i];
      const refOffsetTop = searchDateOffsetTop[refId];

      if (i === 0 || refOffsetTop <= scrollTop + 56) {
        newSearchDateStickHeaderRefId = refId;
      }
    }

    return newSearchDateStickHeaderRefId;
  };

  onScroll = () => {
    const { showScrollButton, filteredSearchDateRef, filteredSearch } = this.state;
    const { searchList } = this.refs;
    const newSearchDateStickHeaderRefId = this.getSearchDateStickHeaderRefId();

    let newShowScrollButton = showScrollButton;
    if (filteredSearch && filteredSearch[0]) {
      const topElem = this.refs[filteredSearchDateRef[0]] || searchList;
      const topPosition = searchList.offsetTop;
      const position = topElem.getBoundingClientRect().top;
      newShowScrollButton = position < topPosition + 56;
    }

    this.setState({
      showScrollButton: newShowScrollButton,
      searchDateStickHeaderRefId: newSearchDateStickHeaderRefId
    })
  };

  handleScrollClick = () => {
    const { searchList } = this.refs;
    searchList.scrollTop = 0;
  };

  render() {
    const {
      isActiveSearchDetails,
      isSearchResultsPending
    } = this.props;
    const {
      showScrollButton,
      filteredSearch,
      filteredSearchDateRef,
      searchDateStickHeaderRefId
    } = this.state;

    return (
      <div className="patient-care-results-holder">
        {filteredSearch && filteredSearch.length ? (
          <Fragment>
            <div className="patient-care-sidebar-header" ref="header">
              <h2>{`${getSearchResultsQuantity(filteredSearch)} results`}</h2>
            </div>
            <div
                className={`patient-care-sidebar-agenda-body${showScrollButton ? "-with-btn" : ""}`}
                ref="searchList"
              >
                {
                  filteredSearch.map((day, index) => {
                    const { date, appointments } = day;
                    const isToday = checkTodayDate(date);
                    const isStickyTop = searchDateStickHeaderRefId === filteredSearchDateRef[index];
                    const filteredAppointments = appointments.filter(appointment => !appointment.isHiddenSearch && appointment.service.id !== ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID);
                    return (
                      <div
                        className="patient-care-search-items"
                        key={date}
                        ref={isToday ? "today" : null}
                      >
                        <div
                          className={
                            [
                              "patient-care-sidebar-date",
                              isToday ? "active" : "",
                              isStickyTop ? "sticky-top": ""
                            ].join(' ')
                          }
                        >
                          <span>
                            {moment(date).format("dddd D MMM")}{" "}
                            {isToday ? "(today)" : ""}
                          </span>
                        </div>
                        <div className="patient-care-date-search-block" ref={filteredSearchDateRef[index]}>
                          <SearchResultItem appointments={filteredAppointments} />
                        </div>
                      </div>
                    );
                  })
                }
            </div>
            {showScrollButton && (
              <ScrollUpToToday onScrollClick={this.handleScrollClick} buttonText={locale.Share.scrollUpSearchButton} />
            )}
            {isActiveSearchDetails && (
              <Modal>
                <AppointmentDetails type={AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.SEARCH} />
              </Modal>
            )}
          </Fragment>
        ) : isSearchResultsPending ? (
          <Skeleton type="basic" className="appointments-skeleton" />
        ) : (
          <NoResults type="info">
            <h2>{locale.Share.searchNoItems}</h2>
          </NoResults>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SearchResults);
