const {
    instance,
    domain,
    signinpolicy,
    forgotpasswordpolicy,
    changepasswordselfpolicy,
} = window.__ENV.azureadb2c || {};

export const b2cPolicies = {
    authorities: {
        signIn: {
            authority: `https://${instance}/${domain}/${signinpolicy}`,
        },
        forgotPassword: {
            authority: `https://${instance}/${domain}/${forgotpasswordpolicy}`,
        },
        changePasswordSelf: {
            authority: `https://${instance}/${domain}/${changepasswordselfpolicy}`,
        },
    },
}
