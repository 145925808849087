import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import type {
  Action
} from 'types/actions';
import type {
  Organisation
} from 'types/organisation';

const initialState: Organisation = {
  branches: [],
  isCreationPending: false,
  isUpdatingPayoutSettingsPending: false,
  getOrganisatonDetailsPending: false,
  isBookingURLEmpty: false,
  isUpdatingOrganisationSettingsPending: false,
  isUpdateOrganisationDetailsPending: false,
  confirmationStatus: "unchanged",
  isTwoFactorOverlay: false
};

export default (localState: Organisation = initialState, action: Action): Organisation => {
  switch (action.type) {
    case OrganisationsConstants.SET_INITIAL_STATE:
      return initialState;
    case OrganisationsConstants.SET_ORGANISATIONS_DETAILS:
      return action.payload;
    case OrganisationsConstants.SET_DELETE_ORGANISATION_CONFIRMATION_STATUS:
      return {
        ...localState,
        confirmationStatus: action.payload
      };
    case OrganisationsConstants.CREATE_ORGANISATION_PENDING:
      return {
        ...localState,
        isCreationPending: true,
      };
    case OrganisationsConstants.CREATE_ORGANISATION_SUCCESS:
      return {
        ...localState,
        isCreationPending: false,
      };
    case OrganisationsConstants.CREATE_ORGANISATION_ERROR:
      return {
        ...localState,
        isCreationPending: false,
      };
    case OrganisationsConstants.UPDATE_PAYOUT_SETTINGS_PENDING:
      return {
        ...localState,
        isUpdatingPayoutSettingsPending: true,
      };
    case OrganisationsConstants.UPDATE_PAYOUT_SETTINGS_SUCCESS:
      return {
        ...localState,
        isUpdatingPayoutSettingsPending: false,
      };
    case OrganisationsConstants.UPDATE_PAYOUT_SETTINGS_ERROR:
      return {
        ...localState,
        isUpdatingPayoutSettingsPending: false,
      };
    case OrganisationsConstants.GET_ORGANISATIONS_DETAILS_PENDING:
      return {
        ...localState,
        getOrganisatonDetailsPending: true,
      };
    case OrganisationsConstants.GET_ORGANISATIONS_DETAILS_SUCCESS:
      return {
        ...localState,
        getOrganisatonDetailsPending: false,
      };
    case OrganisationsConstants.GET_ORGANISATIONS_DETAILS_ERROR:
      return {
        ...localState,
        getOrganisatonDetailsPending: false,
      };
    case OrganisationsConstants.UPDATE_SETTINGS_PENDING:
      return {
        ...localState,
        isUpdatingOrganisationSettingsPending: true,
      };
    case OrganisationsConstants.UPDATE_SETTINGS_SUCCESS:
      return {
        ...localState,
        isUpdatingOrganisationSettingsPending: false,
      };
    case OrganisationsConstants.UPDATE_SETTINGS_ERROR:
      return {
        ...localState,
        isUpdatingOrganisationSettingsPending: false,
      };
    case OrganisationsConstants.UPDATE_DETAILS_PENDING:
      return {
        ...localState,
        isUpdateOrganisationDetailsPending: true,
      };
    case OrganisationsConstants.UPDATE_DETAILS_SUCCESS:
      return {
        ...localState,
        isUpdateOrganisationDetailsPending: false,
      };
    case OrganisationsConstants.UPDATE_DETAILS_ERROR:
      return {
        ...localState,
        isUpdateOrganisationDetailsPending: false,
      };
    case OrganisationsConstants.SET_ORGANISATION_CONFIRMATION_STATUS:
      return {
        ...localState,
        confirmationStatus: action.payload
      };  
    case OrganisationsConstants.OPEN_EMPTY_BOOKING_URL_WARNING:
      return {
        ...localState,
        isBookingURLEmpty: true,
      };
    case OrganisationsConstants.CLOSE_EMPTY_BOOKING_URL_WARNING:
      return {
        ...localState,
        isBookingURLEmpty: false,
      };
    case OrganisationsConstants.OPEN_TWO_FACTOR_MODAL:
      return {
        ...localState,
        isTwoFactorOverlay: true,
      };
    case OrganisationsConstants.CLOSE_TWO_FACTOR_MODAL:
      return {
        ...localState,
        isTwoFactorOverlay: false,
      };
    default:
      return localState;
  }
}
