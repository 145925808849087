import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconAccount extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M18.36,16.83 C16.93,15.09 13.46,14.5 12,14.5 C10.54,14.5 7.07,15.09 5.64,16.83 C4.62,15.49 4,13.82 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,13.82 19.38,15.49 18.36,16.83 L18.36,16.83 Z M12,6 C10.06,6 8.5,7.56 8.5,9.5 C8.5,11.44 10.06,13 12,13 C13.94,13 15.5,11.44 15.5,9.5 C15.5,7.56 13.94,6 12,6 Z" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-account ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconAccount;
