import React, { Component } from 'react';
import './styles/calendar-filters-title.scss';

type Props = {
  children: any,
};

class CalendarFiltersTitle extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="calendar-filters-title">
        {children}
      </div>
    );
  }
};

export default CalendarFiltersTitle;
