import React, { Component } from 'react';
import './styles/reports-content.scss';

type Props = {
  children: any,
};

class ReportsContent extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="reports-content">
        {children}
      </div>
    );
  }
};

export default ReportsContent;
