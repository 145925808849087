import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button } from "@patient-access/ui-kit";

import { openSlotsPanel } from 'actions/bookAppointment';
import { formatListAsOptions } from 'helpers/formatData';
import locale from 'service/locale';
import { INTERNAL_EVENTS_AS_SERVICE } from 'constants/ServicesConstants';

type Props = {
  servicesList: any[];
  openSlotsPanel: (data: Object) => any;
};

const mapStateToProps = (state) => ({
  servicesList: state.branchDetails.services,
});

const mapDispatchToProps = (dispatch: any): any => ({
  openSlotsPanel: (service) => dispatch(openSlotsPanel(service)),
});

class Content extends Component<Props> {

  handleOpenSlots = (service: any) => {
    const { openSlotsPanel } = this.props;
    openSlotsPanel(service);
  };

  handleInternalEvent = () => {
    const { openSlotsPanel } = this.props;
    const service = INTERNAL_EVENTS_AS_SERVICE;
    openSlotsPanel(service);
  }



  render() {
    const { servicesList } = this.props;
    const servicesListNoVideo = servicesList.filter((item) => {
        return item.appointmentType !== 1 && item.modes && item.modes.length;
    });
    return (
      <div className="patient-care-add-appointment">
        <Button
          buttonType="blueline"
          messageKey="add-internal-event-btn"
          defaultMessage={locale.Share.internalEventButton}
          onClick={this.handleInternalEvent}
          data-id="add-internal-event-btn"
          className="patient-care-btn-in-group"
        />
        <Select
          onChange={this.handleOpenSlots}
          options={formatListAsOptions(servicesListNoVideo)}
          placeholder={locale.Share.addAppointmentSelect}
          className="patient-care-select-primary patient-care-btn-in-group"
          classNamePrefix="patient-care"
          isSearchable={false}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
