import React, { Component } from 'react';
import { Icon, IconAppointment } from '@patient-access/ui-kit';
import './styles.scss';

type Props = {
  children: any,
};

class DatePickerWrapper extends Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div className="date-picker-wrapper">
        <Icon type="block" size="large" icon={<IconAppointment outline={false} />} />
        {children}
      </div>
    );
  }
};

export default DatePickerWrapper;
