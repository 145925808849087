import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Overlay as OverlayUiKit } from '@patient-access/ui-kit';
import { OverlayError, OverlayErrorPartial, OverlayCancel, OverlayCancelConfirmation } from './';
import { setTaskManagerOverlayIsVisible, setTaskManagerOverlayType } from 'actions/taskManager';
import * as TaskManagerConstants from 'constants/TaskManagerConstants';
import './styles/overlay-content-task-manager.scss';

type Props = {
  isVisible: boolean,
  type: string,
  taskDetail: any,
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => any,
  setTaskManagerOverlayType: (type: string) => any,
};

const mapStateToProps = state => ({
  isVisible: state.taskManager.overlay.isVisible,
  type: state.taskManager.overlay.type,
});

const mapDispatchToProps = (dispatch: any) => ({
  setTaskManagerOverlayIsVisible: (isVisible: boolean) => dispatch(setTaskManagerOverlayIsVisible(isVisible)),
  setTaskManagerOverlayType: (type: string) => dispatch(setTaskManagerOverlayType(type)),
});

class Overlay extends Component<Props> {

  render() {
    const { isVisible, type, taskDetail } = this.props;

    const renderContent = () => {
      switch (type) {
        case TaskManagerConstants.OVERLAY_TYPE.ERROR:
          return <OverlayError taskDetail={taskDetail} />;
        case TaskManagerConstants.OVERLAY_TYPE.ERROR_PARTIAL:
          return <OverlayErrorPartial taskId={taskDetail} />;
        case TaskManagerConstants.OVERLAY_TYPE.CANCEL_CONFIRMATION:
          return <OverlayCancelConfirmation taskDetail={taskDetail} />;
        case TaskManagerConstants.OVERLAY_TYPE.CANCEL:
          return <OverlayCancel taskId={taskDetail} />;

        default:
          return ''; // no null values in Overlay content please!! Overlay has required prop: children
      }
    }

    return (
      <OverlayUiKit background="dark" type="confirmation" isOpen={isVisible} showCloseButton={false} doClose={() => {}}>
        {renderContent()}
      </OverlayUiKit>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
