import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isEqual, values, find } from "lodash";
import { Form, FormItem, InputLabel, LinkIcon, IconClose } from "@patient-access/ui-kit";

import { closeUserDetails, closeProfile, openChangePassword, closeConfirmationDeleteUser, closeRoleConflict } from "actions/panel";
import { updateForm, clearForm } from "actions/form";
import type { PanelActions } from "types/panel";
import { updateUser, updateUserAndMFA, updateUserName, updateUserMFA } from "actions/userDetails";
import { sendResetPasswordEmailFromAdmin } from "actions/profile";

import ConflictModal from "components/Share/ConflictModal/ConflictModal";
import ConfirmationOverlayCommon from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import UserRoleItem from "../UserRoleItem/UserRoleItem";
import UserNewRoleItem from "../UserNewRoleItem/UserNewRoleItem";
import UserInfo from "../UserInfo/UserInfo";
import ChangePassword from "../ChangePassword/ChangePassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import UserDetailsButtons from "../UserDetailsButtons/UserDetailsButtons";
import UserSecurity from "../UserSecurity/UserSecurity";
import * as RolesConstants from "constants/RolesConstants";
import { drawerScrollHandler } from "helpers/common";
import { validateUserRoles } from "helpers/validateData";
import { changePasswordRedirect } from "components/Pages/Login/AAD/aadAuthHelper";

import locale from "service/locale";
import "./styles.scss";

type Props = {
  accounts: any[];
  closeProfile: () => PanelActions;
  closeUserDetails: () => PanelActions;
  currentUser: any;
  roles: any;
  form: any;
  handleDiscardConfirmation: () => any;
  isActiveAgenda: boolean;
  isActiveUserDetails: boolean;
  openChangePassword: () => PanelActions;
  userDetails: any;
  sendResetPasswordEmailFromAdmin: (email: string) => any;
  updateForm: (data: any) => any;
  clearForm: () => any;
  updateUser: (userId: string, accounts: any[], organisationId?: string, branchId?: string) => any;
  updateUserName: (form: any) => any;
  updateUserMFA: (userId: string, form: any) => AnyAction;
  isConfirmationDeleteUserOpened: boolean,
  isConfirmationOpened: boolean,
  closeRoleConflict: () => any,
  closeConfirmationDeleteUser: () => any,
  currentRole: any,
  organisationId: string,
  branchId: string,
};

type State = {
  accounts: any[],
  accountsLoadedInitially: any[],
  isEditInfo: boolean,
  isEditRoles: boolean,
  isEditMode: boolean,
  newAccounts: any[],
  userDetails: any,
  isDataChanged: boolean,
  isScrolledStart: boolean,
  isScrolledEnd: boolean
}

const mapStateToProps = (state: any) => ({
  currentRole: state.roles.profileCurrentRole,
  accounts: state.userDetails.accounts,
  accountsLoadedInitially: state.userDetails.accountsLoadedInitially,
  currentUser: state.profile,
  roles: state.roles,
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda,
  isConfirmationOpened: state.panel.isConfirmationOpened,
  userDetails: state.userDetails,
  isConfirmationDeleteUserOpened: state.panel.isConfirmationDeleteUserOpened
});

const mapDispatchToProps = (dispatch: Function) => ({
  closeUserDetails: () => dispatch(closeUserDetails()),
  closeProfile: () => dispatch(closeProfile()),
  openChangePassword: () => dispatch(openChangePassword()),
  sendResetPasswordEmailFromAdmin: (email: string) => dispatch(sendResetPasswordEmailFromAdmin(email)),
  updateForm: (data: any) => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  updateUser: (userId: string, accounts: any[], organisationId?: string, branchId?: string) => dispatch(updateUser(userId, accounts, organisationId, branchId)),
  updateUserAndMFA: (userId: string, accounts: any[], form: any) => dispatch(updateUserAndMFA(userId, accounts, form)),
  updateUserName: (form: any) => dispatch(updateUserName(form)),
  updateUserMFA: (userId: string, form: any) => dispatch(updateUserMFA(userId, form)),
  closeConfirmationDeleteUser: () => dispatch(closeConfirmationDeleteUser()),
  closeRoleConflict: () => dispatch(closeRoleConflict())
});

class UserDetailsContainer extends Component<Props, State> {

  state = {
    accounts: this.props.accounts,
    accountsLoadedInitially: this.props.accountsLoadedInitially,
    isEditInfo: false,
    isEditRoles: false,
    isEditMode: false,
    newAccounts: [],
    userDetails: this.props.userDetails,
    isDataChanged: false,
    isScrolledStart: false,
    isScrolledEnd: false
  };

  componentDidMount = () => {
    drawerScrollHandler.bind(this)();
  };

  isDataChangedUpdate = (form: Object) => {
    const { accounts, accountsLoadedInitially } = this.state;
    const newAccountsList = this.formattedAccountsList(accounts, form);
    const isDataChanged = !isEqual(newAccountsList, accountsLoadedInitially);

    this.setState({
      isDataChanged: isDataChanged
    })

  };

  componentWillReceiveProps = (nextProps: Props) => {
    const keys = Object.keys(nextProps.form);

    if (nextProps.userDetails && nextProps.accounts) {
      this.setState({
        userDetails: nextProps.userDetails,
      });
    }

    if(nextProps.form.requiresMFA !== undefined )  {
      this.setState({
         isDataChanged: (nextProps.form.requiresMFA) !== nextProps.userDetails.requiresMFA
      });
    }

    if (keys.includes("displayName")) {
      this.setState({
        isDataChanged:  nextProps.form.displayName !== nextProps.userDetails.displayName
      })
    }

    if (keys.includes("newAccounts")) {
      this.isDataChangedUpdate(nextProps.form);
    }

  };

  isCurrentUser = () => {
    const { currentUser } = this.props;
    const { userDetails } = this.state;
    return userDetails && (currentUser.id === userDetails.id);
  };

  isRoleVisible = (account: any) => {
    const { roles, currentRole } = this.props;
    const isCurrentUser = this.isCurrentUser();

    const profileAccount0 = roles.profileAccounts[0];

    if (isCurrentUser) return true;
    if (!profileAccount0) return false;
    if (account.roleLevel === RolesConstants.PROFILE_ROLES_LEVEL[RolesConstants.ORGANIZATION_ADMIN] &&
      currentRole.organizationId === account.organizationId) return true;

    switch (currentRole.roleLevel) {
      case RolesConstants.PROFILE_ROLES_LEVEL[RolesConstants.ADMIN]:
        return true;
      case RolesConstants.PROFILE_ROLES_LEVEL[RolesConstants.ORGANIZATION_ADMIN]: {
        // role has just been created, it has no organizationId assigned
        // so it should be visible
        if (!account.organizationId) {
          return true;
        } else
          return currentRole.organizationId === account.organizationId;
      }
      case RolesConstants.PROFILE_ROLES_LEVEL[RolesConstants.BRANCH_ADMIN]: {
        // role has just been created, it has no branchId yet,
        // so it should be visible
        if (!account.branchId) {
          return true
        } else {
          return currentRole.branchId === account.branchId;
        }
      }
      default:
        return false;
    }
  };

  closeUserDetails = (e?: Event) => {
    const { closeUserDetails, clearForm, currentUser, closeProfile, handleDiscardConfirmation } = this.props;
    const { userDetails, isDataChanged } = this.state;
    const isCurrentUser = userDetails && (currentUser.id === userDetails.id);

    if (isDataChanged) {
      e && e.preventDefault();
      handleDiscardConfirmation();
    } else {
      isCurrentUser ? closeProfile() : closeUserDetails();
      clearForm();
      this.setState({
        isEditRoles: false,
        newAccounts: [],
      });
    }
  };

  formattedAccountsList = (accounts, form) => {
    const newAccounts = values(form.newAccounts);
    return accounts.concat(newAccounts.map(account => {
      const branch = account.branch;
      const organisation = account.organisation;
      const role = account.role;
      return {
        branchId: branch && branch.id,
        branchName: branch && branch.name,
        organizationId: organisation && organisation.id,
        organizationName: organisation && (organisation.companyName || organisation.name),
        role: role && role.name,
        roleLevel: role && role.level,
      }
    }));
  };

  handleChange = (type: string) => {
    const { clearForm, form } = this.props;
    const { accounts } = this.state;

    this.setState( prevState => {
      if (prevState[type]) {
        if (!Object.keys(form).includes("displayName")) {
          clearForm();
        }
        return {
          newAccounts: [],
          accounts: this.formattedAccountsList(accounts, form),
        }
      }
    });
  };

  handleChangeEditMode = (type: string) => (bool: boolean) => {
    // if finishing editing, run change event
    if (!bool) this.handleChange(type);
    this.setState({ [type]: bool });
  };

  handleDeleteCurrentUserRole = (role: any) => {
    const { form } = this.props;
    let { accounts } = this.state;
    const deletedAccountIndex = accounts.findIndex(account => isEqual(account, role));

    accounts = [
      ...accounts.slice(0, deletedAccountIndex),
      ...accounts.slice(deletedAccountIndex + 1)
    ];

    this.setState({
      accounts,
      isEditRoles: true
    }, () => {
      this.isDataChangedUpdate(form);
    });
  };

  handleAddNewRole = () => {
    let { newAccounts } = this.state;
    newAccounts.push(Date.now());
    this.setState({
      newAccounts,
      isEditRoles: true
    });
  };

  handleDeleteNewUserRole = (index: number) => {
    const { updateForm } = this.props;
    let { newAccounts } = this.state;
    const deletedAccountIndex = newAccounts.findIndex(
      account => account === index
    );
    newAccounts = [
      ...newAccounts.slice(0, deletedAccountIndex),
      ...newAccounts.slice(deletedAccountIndex + 1)
    ];
    updateForm({ newAccounts });
    this.setState({ newAccounts });
  };

  handleUpdateUser = async () => {
    const { userDetails, accounts, accountsLoadedInitially } = this.state;
    const { updateUser, updateUserAndMFA, updateUserName, updateUserMFA, form, currentUser, clearForm } = this.props;
    const isCurrentUser = currentUser.id === userDetails.id;
    const keys = Object.keys(form);
    const isNameUpdated = keys.includes("displayName");
    const userRoles = keys.includes("newAccounts") ? this.formattedAccountsList(accounts, form) : accounts;
    const areRolesUpdated = ((userRoles && userRoles.length) !== (accountsLoadedInitially && accountsLoadedInitially.length)) || (!isEqual(userRoles, accountsLoadedInitially));
    const isMFAStatusUpdated = (form.requiresMFA !== undefined) && (form.requiresMFA !== userDetails.requiresMFA)
    // TODO: Create a new endpoint to update both roles and requiresMFA- this is a not an appropirate approach to make cascaded api calls
    let success = false;

    if (isCurrentUser) {
      if (isNameUpdated)
        success = await updateUserName(form);
      if (isMFAStatusUpdated)
        success = await updateUserMFA(userDetails.id, form);
    } else {
      if (areRolesUpdated && isMFAStatusUpdated) {
        success = await updateUserAndMFA(userDetails.id, userRoles, form);
      } else {
        if (areRolesUpdated) {
          success = await updateUser(userDetails.id, userRoles);
        }
        if (isMFAStatusUpdated) {
          success = await updateUserMFA(userDetails.id, form);
        }
      }
      clearForm();
    }

    if (success) {
      this.setState({
        isEditInfo: false,
        isEditRoles: false,
        accounts: userRoles,
        newAccounts: [],
      });
    }
  };

  handleResetPassword = (e: any) => {
    e && e.preventDefault();
    const { sendResetPasswordEmailFromAdmin, userDetails } = this.props;
    sendResetPasswordEmailFromAdmin(userDetails.email);
  };

  handleChangePassword = () => {
    const { userDetails } = this.props;
    changePasswordRedirect(userDetails.email);
  };

  removeUser = (e: Event) => {
    e && e.preventDefault();
    const { roles, userDetails, accounts, updateUser, closeConfirmationDeleteUser, organisationId, branchId } = this.props;

    switch (roles.profileCurrentRole.role) {
      case RolesConstants.ADMIN:
        updateUser(userDetails.id, [], organisationId, branchId);
        break;
      case RolesConstants.ORGANIZATION_ADMIN:
        updateUser(userDetails.id, accounts.filter(account => account.organizationId !== roles.profileCurrentRole.organizationId), organisationId, branchId);
        break;
      case RolesConstants.BRANCH_ADMIN:
        updateUser(userDetails.id, accounts.filter(account => account.branchId !== roles.profileCurrentRole.branchId), organisationId, branchId);
        break;
      default:
        break;
    }
    closeConfirmationDeleteUser();

  };

  handleCloseRoleConflictModal = () => {
    const { closeRoleConflict } = this.props;
    closeRoleConflict();
  };

  getContentForConfirmationModal = () => {
    const { currentRole } = this.props;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isOrgAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
    let content = locale.Modals.confirmationDeleteUser.content.other;

    isSuperAdmin
      ? content = locale.Modals.confirmationDeleteUser.content.admin
      : isOrgAdmin
        ? content = locale.Modals.confirmationDeleteUser.content.orgAdmin
        : content = locale.Modals.confirmationDeleteUser.content.other;

    return content;
  };

  render() {
    const {
      handleDiscardConfirmation, isActiveAgenda, currentUser, roles,
      isConfirmationDeleteUserOpened, closeConfirmationDeleteUser,
      isConfirmationOpened, currentRole, organisationId, branchId, form
    } = this.props;

    const {
      isEditRoles, userDetails,
      accounts, newAccounts, isDataChanged,
      isScrolledStart, isScrolledEnd
    } = this.state;

    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;
    const isBranchMember = roles.profileCurrentRole.role === RolesConstants.BRANCH_MEMBER;
    const isCurrentUser = userDetails && (currentUser.id === userDetails.id);
    const visibleAccounts = accounts.filter(account => this.isRoleVisible(account));
    const higherRoles = visibleAccounts.filter(account => account.roleLevel < currentRole.roleLevel);
    const canEditRoles = !isCurrentUser && !isBranchMember && higherRoles.length === 0;
    const isBothSuperAdmins = isSuperAdmin && accounts[0] && accounts[0].role === RolesConstants.ADMIN;
    const isButtonDisabled = isCurrentUser ? Object.keys(form).includes("displayName") && !form.displayName.length : !validateUserRoles(accounts, form.newAccounts);

    return (
      <div className={isActiveAgenda ? "patient-care-modal-full-height-with-agenda-holder" : "patient-care-modal-full-height-holder"}>
        <div
          className="patient-care-modal-full-height"
          onScroll={drawerScrollHandler.bind(this)}
        >
          <div
            className={`patient-care-modal-header ${isScrolledStart ? "has-shadow" : ""}`}
            ref="modalHeader"
          >
            <h1>
              {locale.UserDetails.userDetailsTitle}
            </h1>
            <div className="patient-care-btn-close">
              <LinkIcon
                to="#close"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.UserDetails.userDetailsCloseIcon}
                onClick={this.closeUserDetails}
              />
            </div>
          </div>
          <div className="patient-care-modal-content" ref="modalContent">
            <div id="patient-care-scrolling-content" ref="modalContentScroll">

              <UserInfo
                isCurrentUser={isCurrentUser}
                displayName={userDetails && userDetails.displayName}
                email={userDetails && userDetails.email}
                handleChangeEditMode={ this.handleChangeEditMode('isEditInfo')}
              />

              <hr className="patient-care-separator-small" />

              <div className="patient-care-description-block">
                <div className="patient-care-heading-row">
                  <h3>
                    User role{ visibleAccounts && visibleAccounts.length === 1 ? '' : `s (${ visibleAccounts && visibleAccounts.length })` }
                  </h3>
                  {
                    !isBothSuperAdmins && canEditRoles
                    && (
                      <button
                        className="patient-care-btn-link"
                        onClick={() => this.handleChangeEditMode('isEditRoles')(!isEditRoles) }
                      >
                        {
                          isEditRoles
                            ? locale.UserDetails.editRolesButtons[0]
                            : locale.UserDetails.editRolesButtons[1]
                        }
                      </button>
                    )
                  }
                </div>
                <p>{locale.UserDetails.userRoleDescription}</p>
              </div>
              <div className="patient-care-roles-heading">
                <div className="patient-care-block-row">
                  <div className="patient-care-col-4">
                    <Form noValidate>
                      <FormItem type="item">
                        <InputLabel
                          htmlFor="userRole"
                          message="Role"
                          size="small"
                          className="patient-care-roles-item-heading"
                        />
                      </FormItem>
                    </Form>
                  </div>
                  <div className="patient-care-col-4">
                    <Form noValidate>
                      <FormItem type="item">
                        <InputLabel
                          htmlFor="userOrganisation"
                          message="Organisation"
                          size="small"
                          className="patient-care-roles-item-heading"
                        />
                      </FormItem>
                    </Form>
                  </div>
                  <div className="patient-care-col-4">
                    <Form noValidate>
                      <FormItem type="item">
                        <InputLabel
                          htmlFor="userBranch"
                          message="Branch"
                          size="small"
                          className="patient-care-roles-item-heading"
                        />
                      </FormItem>
                    </Form>
                  </div>
                </div>
              </div>

              { isEditRoles &&
                visibleAccounts.map((account, index) => {
                  return (
                    <UserRoleItem
                      key={index}
                      account={account}
                      handleDeleteUserRole={this.handleDeleteCurrentUserRole}
                    />
                  )
                })
              }

              { newAccounts.length > 0 &&
                isEditRoles &&
                  newAccounts.map(account => {
                    return this.isRoleVisible(account) && (
                      <UserNewRoleItem
                        key={account}
                        index={account}
                        handleDeleteNewUserRole={this.handleDeleteNewUserRole}
                        organisationId={organisationId}
                        branchId={branchId}
                      />
                    )
                })
              }

              { !isEditRoles &&
                accounts.map((account, index) => {
                  const isOrgAdmin = account.role === RolesConstants.ORGANIZATION_ADMIN;
                  const role = find(RolesConstants.PROFILE_ROLES_LIST, { id: account.role});
                  const label = role && role.label;
                  return this.isRoleVisible(account) && (
                    <React.Fragment key={index}>
                      <hr className="patient-care-roles-separator" />
                      <div className="patient-care-modal-edit-row">
                        <div className="patient-care-col-4">
                          <b className="patient-care-roles-item">
                            {label}
                          </b>
                        </div>
                        <div className="patient-care-col-4">
                          <b className="patient-care-roles-item">
                            {account.organizationName || locale.UserDetails.notAvailableDescription}
                          </b>
                        </div>
                        <div className="patient-care-col-4">
                          <b className="patient-care-roles-item">
                            {!isOrgAdmin && account.branchName !== null
                              ? account.branchName
                              : locale.UserDetails.notAvailableDescription
                            }
                          </b>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              }

              { isEditRoles && (
                <Fragment>
                  <hr className="patient-care-roles-separator" />
                  <div className="patient-care-block">
                    <button
                      className="patient-care-btn-link"
                      onClick={this.handleAddNewRole}
                    >
                      {locale.UserDetails.addRoleButton}
                    </button>
                  </div>
                </Fragment>
              )}

              
              <UserSecurity 
                    isSuperAdmin={isSuperAdmin}
                    userDetails={userDetails}
                    isCurrentUser = { isCurrentUser }
                    isBothSuperAdmins = { isBothSuperAdmins}
                    canEditRoles = { canEditRoles}
              /> 

              {
                isCurrentUser
                  ? <ChangePassword handleChangePassword={this.handleChangePassword} />
                  : canEditRoles
                    ? <ResetPassword handleResetPassword={this.handleResetPassword} />
                    : null
              }

            </div>
          </div>
          <UserDetailsButtons
            isDisabled={isButtonDisabled}
            isDataChanged={isDataChanged}
            closeUserDetails={this.closeUserDetails}
            handleUpdateUser={this.handleUpdateUser}
            handleDiscardConfirmation={handleDiscardConfirmation}
            canDelete={higherRoles.length === 0 && !isCurrentUser}
            ref="modalAddOrDiscardButtons"
            customClassName={isScrolledEnd ? "has-shadow" : ""}
          />
        </div>
        <span className="patient-care-modal-overlay" />
        {
          isConfirmationDeleteUserOpened &&
          <ConfirmationOverlayCommon
            handleConfirm={this.removeUser}
            handleCancel={closeConfirmationDeleteUser}
            header={locale.Modals.confirmationDeleteUser.header}
            content={this.getContentForConfirmationModal()}
            buttonConfirm={locale.Modals.confirmationDeleteUser.buttonConfirm}
            buttonCancel={locale.Modals.confirmationDeleteUser.buttonCancel}
          />
        }
        {
          isConfirmationOpened &&
            <ConflictModal
              title={locale.UserDetails.roleConflictTitle}
              body={locale.UserDetails.roleConflictBody}
              buttonText={locale.UserDetails.roleConflictButton}
              handleStay={this.handleCloseRoleConflictModal}
            />
        }
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailsContainer);
