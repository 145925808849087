import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconTaskActive extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="M12,4.29153441 L12,2.50153441 C12,2.05153441 11.46,1.83153441 11.15,2.15153441 L8.35,4.94153441 C8.15,5.14153441 8.15,5.45153441 8.35,5.65153441 L11.14,8.44153441 C11.46,8.75153441 12,8.53153441 12,8.08153441 L12,6.29153441 C15.31,6.29153441 18,8.98153441 18,12.2915344 C18,13.0815344 17.85,13.8515344 17.56,14.5415344 C17.41,14.9015344 17.52,15.3115344 17.79,15.5815344 C18.3,16.0915344 19.16,15.9115344 19.43,15.2415344 C19.8,14.3315344 20,13.3315344 20,12.2915344 C20,7.87153441 16.42,4.29153441 12,4.29153441 Z M12,18.2915344 C8.69,18.2915344 6,15.6015344 6,12.2915344 C6,11.5015344 6.15,10.7315344 6.44,10.0415344 C6.59,9.68153441 6.48,9.27153441 6.21,9.00153441 C5.7,8.49153441 4.84,8.67153441 4.57,9.34153441 C4.2,10.2515344 4,11.2515344 4,12.2915344 C4,16.7115344 7.58,20.2915344 12,20.2915344 L12,22.0815344 C12,22.5315344 12.54,22.7515344 12.85,22.4315344 L15.64,19.6415344 C15.84,19.4415344 15.84,19.1315344 15.64,18.9315344 L12.85,16.1415344 C12.54,15.8315344 12,16.0515344 12,16.5015344 L12,18.2915344 Z" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-task-active ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconTaskActive;
