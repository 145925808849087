import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Overlay, Button } from '@patient-access/ui-kit';
import { setOnlineBranchOverlayVisibility } from 'actions/branches';
import locale from 'service/locale';

type Props = {
  isOverlayVisible: boolean,
  isCreateBranchLater: boolean,
  setOnlineBranchOverlayVisibility: (isOverlayVisible: boolean) => Action,
  handleCreateBranch: any
};

const mapStateToProps = (state) => ({
  isOverlayVisible: state.branches.onlineBranch.isOverlayVisible,
  isCreateBranchLater: state.branches.onlineBranch.isCreateBranchLater,
  isCreationPending: state.branchDetails.isCreationPending
});

const mapDispatchToProps = (dispatch: any) => ({
  setOnlineBranchOverlayVisibility: (isOverlayVisible) => dispatch(setOnlineBranchOverlayVisibility(isOverlayVisible)),
});

class AddNewOnlineBranchOverlay extends Component<Props> {
  createBranchHandler = () => {
    const { isCreateBranchLater, handleCreateBranch, setOnlineBranchOverlayVisibility } = this.props;
    if (isCreateBranchLater) {
      handleCreateBranch(false);
    } else {
      handleCreateBranch(true);
    }
    setOnlineBranchOverlayVisibility(false);
  }

  render() {
    const { isOverlayVisible, setOnlineBranchOverlayVisibility, isCreationPending } = this.props;

    if (!isOverlayVisible) return null;
    return (
      <Overlay
        background="dark"
        type="confirmation"
        isOpen={isOverlayVisible}
        showCloseButton={false}
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h1>{locale.AddBranch.onlineBranchOverlay.heading}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.AddBranch.onlineBranchOverlay.bodyLine1}</h4>
          <h4>{locale.AddBranch.onlineBranchOverlay.bodyLine2}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="some-key"
            defaultMessage={locale.AddBranch.onlineBranchOverlay.buttonBack}
            onClick={() => { setOnlineBranchOverlayVisibility(false); }}
            isLoading={isCreationPending}
          />
          <Button
            buttonType="secondary"
            messageKey="some-key"
            defaultMessage={locale.AddBranch.onlineBranchOverlay.buttonConfirm}
            onClick={this.createBranchHandler}
            isLoading={isCreationPending}
          />
        </div>
      </Overlay>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewOnlineBranchOverlay);
