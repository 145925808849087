export const CREATE_NEW_APPOINTMENT = "CREATE_NEW_APPOINTMENT";

export const CALENDAR_VIEWS = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week"
};

export const CHANGE_DATE_ACTIONS = {
  NEXT: "next",
  PREV: "prev"
};

export const REFUND_TYPE_FULL = "Full refund";
export const REFUND_TYPE_PARTIAL = "Partial refund";
export const refundTypeOptions = [
  { value: "CustomerServiceFullRefund", label: REFUND_TYPE_FULL },
  { value: "CustomerServicePartialRefund", label: REFUND_TYPE_PARTIAL }
];

export type CalendarView = $Values<typeof CALENDAR_VIEWS>;
export type ChangeDateAction = $Values<typeof CHANGE_DATE_ACTIONS>;