import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { LinkIcon, IconClose } from '@patient-access/ui-kit';
import isEqual from "lodash.isequal";
import { toggleAgenda } from 'actions/panel';
import type { Action } from "types/actions";
import * as RolesConstants from "constants/RolesConstants";
import AppointmentsList from "./AppointmentsList/AppointmentsList";
import type { AppointmentsAction } from "types/actions";
import { filterAppointments } from "actions/appointments";
import { openAgenda } from "actions/panel";
import { getAgendaAppointments } from "actions/agenda";
import locale from "service/locale";
import { formatListAsOptions } from "helpers/formatData";
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';

import "./styles.scss";

type Props = {
  isActiveAgenda: boolean,
  calendarsList: any[],
  filterAppointments: (id: string) => AppointmentsAction,
  getAgendaAppointments: () => any,
  currentRole: any,
  openAgenda: () => Action,
  isLowResolution: boolean,
  branchId: string,
  toggleAgenda: () => any,
};

type State = {
  filterCalendarId: string,
  timerId: any,
  selectedFilter: any
};

const mapStateToProps = state => ({
  calendarsList: state.branchDetails.calendarsList,
  isActiveAgenda: state.panel.isActiveAgenda,
  currentRole: state.roles.profileCurrentRole,
  isLowResolution: state.panel.isLowResolution,
  branchId: state.router.branchId
});

const mapDispatchToProps = (dispatch: (action: any) => any): any => ({
  filterAppointments: id => dispatch(filterAppointments(id)),
  getAgendaAppointments: () => dispatch(getAgendaAppointments()),
  openAgenda: () => dispatch(openAgenda()),
  toggleAgenda: () => dispatch(toggleAgenda()),
});

export class Agenda extends Component<Props, State> {
  state = {
    filterCalendarId: "all",
    timerId: null,
    selectedFilter: { value: "all", label: "All calendars" }
  };

  componentDidMount = () => {
    const { isActiveAgenda, getAgendaAppointments } = this.props;
    if (isActiveAgenda) {
      getAgendaAppointments();
      this.updateAppointments();
    }
  };

  shouldComponentUpdate = (nextProps) => {
    const { isActiveAgenda, isLowResolution, calendarsList, currentRole } = this.props;
    if (isActiveAgenda !== nextProps.isActiveAgenda) return true;
    if (!isEqual(calendarsList, nextProps.calendarsList)) return true;
    if (nextProps.isActiveAgenda === null && !([RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(nextProps.currentRole.role) || currentRole.organisationType === ORGANISATION_TYPE.AFFILIATE) && !isLowResolution) {
      nextProps.openAgenda();
    }
    return true;
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { isActiveAgenda, branchId } = nextProps;
    const { timerId } = this.state;

    if (isActiveAgenda && !this.props.isActiveAgenda) {
      this.updateAppointments();
    }
    if (!isActiveAgenda && this.props.isActiveAgenda) {
      timerId && clearInterval(timerId);
    }
    if (branchId !== this.props.branchId) {
      this.handleClinicianChange({ value: "all" });
    }
  };

  updateAppointments = () => {
    const timerId = setInterval(() => {
      const { getAgendaAppointments } = this.props;
      getAgendaAppointments();
    }, 30000);
    this.setState({ timerId });
  };

  componentWillUnmount = () => {
    const { timerId } = this.state;
    timerId && clearInterval(timerId);
  };

  getFormattedOptions = (calendarsList: any[]) => {
    let fullCalendarList = formatListAsOptions(calendarsList);
    fullCalendarList.unshift({
      value: "all",
      label: "All calendars"
    });
    return fullCalendarList;
  };

  handleClinicianChange = (e: any) => {
    const { calendarsList } = this.props;
    const options = this.getFormattedOptions(calendarsList);
    const selectedValue = options.find(option => option.value === e.value);
    this.setState({
      filterCalendarId: e.value,
      selectedFilter: selectedValue
    });
  };

  handleCloseButton = (event: Event) => {
    const { toggleAgenda } = this.props;
    event.preventDefault();
    toggleAgenda();
  };

  render() {
    const { calendarsList, isActiveAgenda } = this.props;
    const { filterCalendarId, selectedFilter } = this.state;
    const displayedOptions = this.getFormattedOptions(calendarsList);

    if (isActiveAgenda) {
      return (
        <aside className="agenda-sidebar">
          <div className="patient-care-sidebar-header">
            <LinkIcon to="#close" size="medium" icon={<IconClose outline />} accessibilitySpan="Close" onClick={this.handleCloseButton} />
            <h2 className="patient-care-sidebar-heading">
              {locale.Agenda.agendaTitle}
            </h2>
            <Select
              id="agenda-clinic-select"
              name="agenda-clinic-select"
              options={displayedOptions}
              defaultValue={displayedOptions[0]}
              onChange={this.handleClinicianChange}
              classNamePrefix="patient-care"
              value={selectedFilter || displayedOptions[0]}
            />
          </div>
          <AppointmentsList filterCalendarId={filterCalendarId} />
        </aside>
      );
    }
    return <aside className="agenda-sidebar agenda-sidebar-inactive" />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agenda);
