import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { Form, IconCog } from '@patient-access/ui-kit';
import { IconSort } from 'svg';
import * as RoutesConstants from 'constants/RoutesConstants';
import { formatNumber } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

export const columns = [
  {
    className: 'cell-main',
    Header: locale.OrganisationsListRedesign.table.headers.name,
    accessor: 'companyName',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
    sortType: 'basic',
  },
  {
    className: 'cell-medium',
    Header: locale.OrganisationsListRedesign.table.headers.number,
    accessor: 'companyNumber',
    Cell: ({ cell: { value } }) => <span>{value ? value : <em>{locale.OrganisationsListRedesign.table.labels.none}</em>}</span>,
  },
  {
    className: 'cell-small',
    Header: locale.OrganisationsListRedesign.table.headers.branches,
    accessor: data => data.branches.length,
    Cell: ({ cell: { value } }) => <span>{formatNumber(value)}</span>,
  },
  {
    className: 'cell-default',
    Header: locale.OrganisationsListRedesign.table.headers.address,
    accessor: 'addressAsString',
    Cell: ({ cell: { value } }) => <span>{value}</span>,
  },
  {
    className: 'cell-medium',
    Header: locale.OrganisationsListRedesign.table.headers.town,
    accessor: 'address.town',
    Cell: ({ cell: { value } }) => <span>{value ? value : <em>{locale.OrganisationsListRedesign.table.labels.none}</em>}</span>,
  },
];

export function TableOrganisations({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [{ id: 'companyName', desc: false }],
      },
    },
    useSortBy,
  );
  const [ isMenuOn, setMenuOn ] = useState(false);

  const renderSettingsMenu = () => {
    if (isMenuOn) {
      return (
        <Fragment>
          <div className="react-table-settings-background" onClick={() => { setMenuOn(!isMenuOn); }} />
          <div className="react-table-settings-menu">
            <h2>{locale.OrganisationsListRedesign.table.menuHeader}</h2>
            <Form noValidate>
              {allColumns.map(column => {
                if (column.id === 'companyName') return null;
                return (
                  <div className="input-checkbox input-checkbox-small" key={column.id}>
                    <label htmlFor={column.id}>
                      <input id={column.id} name={column.id} type="checkbox" {...column.getToggleHiddenProps()} />
                      <span>{column.Header}</span>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Fragment>
      );
    }
    return null;
  }

  const renderCell = (row, cell) => {
    if (cell.column.id === 'companyName') {
      return (
        <Link to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${row.original.id}/${RoutesConstants.BRANCHES}`} title={cell.value}>
          {cell.render('Cell')}
        </Link>
      );
    }
    return <Fragment>{cell.render('Cell')}</Fragment>;
  }

  return (
    <Fragment>
      <div className="react-table-settings">
        {renderSettingsMenu()}
        <div className="react-table-settings-button" onClick={() => { setMenuOn(!isMenuOn); }}>
          <i><IconCog outline={false} /></i>
        </div>
      </div>
      <table className="react-table organisations-list spacing-medium" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="sticky grey">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())} title={locale.OrganisationsListRedesign.table.sortByLabel(column.Header)}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {renderCell(row, cell)}
                      </td>
                    );
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
