import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: window.__ENV.appInsightsKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        disableAjaxTracking: true,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig,
        },
      },
    });
    this.appInsights.loadAppInsights();
  }
}

export let ai = new TelemetryService();
