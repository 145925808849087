import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { SkeletonChart } from 'components/Pages/Reports/skeleton';
import { formatNumber } from 'helpers/formatData';
import * as ColorConstants from 'constants/ColorConstants';
import * as RolesConstants from 'constants/RolesConstants';
import { ReportsTooltip } from 'components/Pages/Reports/wrappers';
import './styles.scss';

// SuperAdmin: 376px

type Props = {
  currentRole:any,
  isResizing: boolean,
  nominationRequests: any,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  filters: state.reportsSmartPharmacy.filters,
  deliveryTrend: state.reportsSmartPharmacy.metrics.prescription.data,
});

class ChartDeliveryTrend extends Component<Props> {
  formatXAxis = (tickItem) => {
    return moment(tickItem).format('DD-MMM');
  }

  formatYAxis = (tickItem) => {
    return tickItem;
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      return (
        <ReportsTooltip>
          <ul>
            <li><span><strong>Prescriptions downloaded on {moment(payload[0].payload.weekDate).format('DD/MM/YYYY')}:</strong></span><span>{formatNumber(payload[0].payload.totalPrescription)}</span></li>
            <li><span><strong>Notifications sent:</strong></span><span>{formatNumber(payload[0].payload.prescriptionsCompleted)}</span></li>
          </ul>
        </ReportsTooltip>
      );
    }
    return null;
  }

  render() {
    const { isResizing, filters, deliveryTrend, currentRole } = this.props;
    const isOrgVisible = filters.organisationId === "" ? true : false;

    let height = 376;
    if (currentRole.role === RolesConstants.BRANCH_ADMIN) {
      height = 167;
    }
    else if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) {
      height = 283;
    } else if (currentRole.role === RolesConstants.ADMIN && !isOrgVisible) {
      height = 283;
    }

    if (isResizing) {
      return (
        <SkeletonChart height={height} />
      );
    }

    return (
      <ResponsiveContainer className="recharts-deliveryTrend" width="99%" height={height}>
        <BarChart data={deliveryTrend}>
          <CartesianGrid strokeDasharray="0 0" stroke={ColorConstants.COLOR_GREY_LIGHT} vertical={false} />
          <XAxis tickFormatter={this.formatXAxis} tickLine={false} tickSize={16} axisLine={false} dataKey="weekDate" />
          <YAxis tickFormatter={this.formatYAxis} tickLine={false} tickSize={16} axisLine={false} dataKey="totalPrescription" />
          <Tooltip content={this.formatTooltip} cursor={false} />
          <Bar dataKey="totalPrescription" fill={ColorConstants.COLOR_GRAPH_THREE} name="totalPrescription" maxBarSize={30} />
          <Bar dataKey="prescriptionsCompleted" fill={ColorConstants.COLOR_GRAPH_TWO} name="prescriptionsCompleted" maxBarSize={30} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default connect(mapStateToProps)(ChartDeliveryTrend);
