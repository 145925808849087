import React, { Component } from "react";
import { Form, FormItem, Checkbox } from "@patient-access/ui-kit";

type Props = {
  service: Object,
  isEditMode: boolean,
  handleUpdateServiceList: (type: string, service: Object) => any,
  checked: boolean
};

export class CalendarDetailsServiceItem extends Component<Props> {

  handleUpdateStatus = () => {
    const { service, handleUpdateServiceList, checked } = this.props;
    handleUpdateServiceList(!checked ? "add" : "delete", service);
  };

  render() {
    const { service, isEditMode, checked } = this.props;
    return (
      <li className="patient-care-table-item">
        <Form noValidate>
          <FormItem type="item">
            <Checkbox
              id={service.name}
              name={service.name}
              value={service.name}
              checked={checked}
              onChange={this.handleUpdateStatus}
              label={service.name}
              data-id={service.name}
              disabled={!isEditMode}
            />
          </FormItem>
        </Form>
      </li>
    );
  }
}

export default CalendarDetailsServiceItem;
