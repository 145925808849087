import React, { Fragment } from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import IconSort from 'svg/IconSort';
import locale from 'service/locale';
import './styles.scss';

export const dataServicesAddBranch = [
  { branchName: 'Branch 01' },
  { branchName: 'Branch 02' },
  { branchName: 'Branch 03' },
  { branchName: 'Branch 04' },
  { branchName: 'Branch 05' },
  { branchName: 'Branch 06' },
  { branchName: 'Branch 07' },
  { branchName: 'Branch 08' },
  { branchName: 'Branch 09' },
  { branchName: 'Branch 10' },
  { branchName: 'Branch 11' },
  { branchName: 'Branch 12' },
  { branchName: 'Branch 13' },
  { branchName: 'Branch 14' },
  { branchName: 'Branch 15' },
  { branchName: 'Branch 16' },
  { branchName: 'Branch 17' },
  { branchName: 'Branch 18' },
  { branchName: 'Branch 19' },
  { branchName: 'Branch 20' },
];

export const columnsServicesAddBranch = [
  { className: 'cell-main', Header: locale.OrganisationServices.table.branchesList.headers.branchName, accessor: 'branchName', Cell: ({ cell: { value } }) => <span>{value}</span> },
];

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div className="input-checkbox input-checkbox-medium">
        <label>
          <input type="checkbox" ref={resolvedRef} {...rest} />
          <span>&nbsp;</span>
        </label>
      </div>
    );
  }
)

export function TableServicesAddBranch({ columns, data, props }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'branchName', desc: false }],
      },
    },
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          className: 'cell-checkbox',
          Header: ({ getToggleAllRowsSelectedProps }) => (<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />),
          Cell: ({ row }) => (<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />),
          disableSortBy: true,
        },
        ...columns,
      ])
    }
  );

  return (
    <Fragment>
      <table className="react-table react-table-classNameThree" columns={visibleColumns.length} {...getTableProps()}>
        <thead className="white">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={column.isSorted ? column.isSortedDesc ? 'sorting-down' : 'sorting-up' : 'sorting-none'}>
                      {column.id === 'selection' ? <Fragment>{column.render('Header')}</Fragment> : <span>{column.render('Header')}</span>}
                      {column.disableSortBy ? null : <i><IconSort outline={false} /></i>}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </Fragment>
  )
};
