import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Select from "react-select";

import { Form, FormItem, InputError } from "@patient-access/ui-kit";
import { ORGANISATION_TWO_FACTOR_LIST } from 'constants/OrganisationsConstants';
import locale from 'service/locale';

type Props = {
  handleTwoFactorChange: () => any,
  form: any,
};

type State = {
  two_factor_select: boolean
};

const mapStateToProps = state => ({
  form: state.form,
});

class AddTwoFactorAuthentication extends Component<Props, State> {

  state = {
    two_factor_select: false
  }

  componentDidMount = () => {
    // preselect `required``
    const { handleTwoFactorChange } = this.props;
    handleTwoFactorChange(ORGANISATION_TWO_FACTOR_LIST[1]);
  }

  handleTwoFactorChange = value => {
    const { handleTwoFactorChange } = this.props;
    handleTwoFactorChange(value);
    this.setState({ two_factor_select: false });
  };

  handleBlur = () => {
    this.setState({ two_factor_select: true });
  };

  render() {
    const { form } = this.props;
    const { two_factor_select } = this.state;
    const isOrganisationTypeError = two_factor_select && !form.twoFactorValue;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddOrganisation.twoFactorAuth.title}</h3>
          <p>{locale.AddOrganisation.twoFactorAuth.hint}</p>
        </div>
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Select
              id="two_factor_select"
              name="two_factor_select"
              placeholder={locale.AddOrganisation.twoFactorAuth.placeHolder}
              onChange={this.handleTwoFactorChange}
              classNamePrefix="patient-care"
              className={`patient-care-select ${isOrganisationTypeError ? "patient-care-select-error" : ""}`}
              options={ORGANISATION_TWO_FACTOR_LIST}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              defaultValue={ORGANISATION_TWO_FACTOR_LIST[1]}
            />
          </div>
          <Form>
            <FormItem type="item">
              {isOrganisationTypeError && <InputError message={locale.AddOrganisation.twoFactorAuth.selectError} />}
            </FormItem>
          </Form>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(AddTwoFactorAuthentication);
