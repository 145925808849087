import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkIcon, IconClose } from "@patient-access/ui-kit";
import find from 'lodash.find';

import { updateForm, clearForm } from "actions/form";
import { closeModalOverlay } from "actions/panel";

import AddBranchType from './AddBranchType/AddBranchType';
import AddBranchOrganisation from './AddBranchOrganisation/AddBranchOrganisation';
import AddBranchInfo from './AddBranchInfo/AddBranchInfo';
import AddBranchAddressInfo from './AddBranchAddressInfo/AddBranchAddressInfo';
import AddBranchButtons from './AddBranchButtons/AddBranchButtons';

import type { Action } from "types/actions";
import type { Organisation, Branch } from "types/organisation";
import * as RolesConstants from 'constants/RolesConstants';
import { drawerScrollHandler } from 'helpers/common';

import locale from 'service/locale';

type Props = {
  roles: any,
  form: any,
  isActiveAgenda: boolean,
  updateForm: (organisation: any) => Action,
  clearForm: () => Action,
  closeModalOverlay: () => Action,
  handleOpenDiscardDialog: () => any,
  organisations: Organisation[],
  organisationId?: string,
  handleCreateBranch: any
};

type State = {
  isScrolledStart: boolean;
  isScrolledEnd: boolean;
}

const mapStateToProps = state => ({
  roles: state.roles,
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda,
  organisations: state.organisations.organisationsList,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (organisation: any) => dispatch(updateForm(organisation)),
  clearForm: () => dispatch(clearForm()),
  closeModalOverlay: () => dispatch(closeModalOverlay())
});

export class AddBranchModal extends Component<Props, State> {

  state = {
    isScrolledStart: false,
    isScrolledEnd: false
  };

  componentDidMount = () => {
    const { organisations, organisationId, updateForm } = this.props;
    const selectedOrganisation = find(organisations, { id: organisationId });
    if (organisationId || (selectedOrganisation && selectedOrganisation.id)) {
      updateForm({organisationId: selectedOrganisation ? selectedOrganisation.id : organisationId});
    }
    drawerScrollHandler.bind(this)();
  };

  handleSelectBranch = (branch: Branch) => {
    const { clearForm, updateForm, roles, organisationId, form } = this.props;

    if (organisationId) {
      branch.organisationId = organisationId;
    } else {
      branch.organisationId = form.organisationId;
    }

    if (roles.profileCurrentRole && (roles.profileCurrentRole.role === RolesConstants.ORGANIZATION_ADMIN)) {
      branch.organisationId = roles.profileCurrentRole.organizationId;
    }
    const isVirtual = form.isVirtual;

    clearForm();
    updateForm({
      ...branch,
      isVirtual : isVirtual
    });
  };

  handleDiscard = (e: any) => {
    e && e.preventDefault();
    const { handleOpenDiscardDialog } = this.props;
    handleOpenDiscardDialog();
  };

  handleCloseAddBranch = () => {
    const { clearForm, closeModalOverlay } = this.props;
    clearForm();
    closeModalOverlay();
  };

  render() {
    const { isActiveAgenda, form, handleOpenDiscardDialog, roles, organisationId, handleCreateBranch } = this.props;
    const { isScrolledStart, isScrolledEnd } = this.state;

    return (
      <div className={isActiveAgenda ? "patient-care-modal-full-height-with-agenda-holder" : "patient-care-modal-full-height-holder"}>
        <div
          className="patient-care-modal-full-height"
          onScroll={drawerScrollHandler.bind(this)}
        >
          <div
            className={`patient-care-modal-header ${isScrolledStart ? "has-shadow" : ""}`}
            ref="modalHeader"
          >
            <h2>
              {locale.AddBranch.title}
            </h2>
            <div className="patient-care-btn-close">
              <LinkIcon
                to="#"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={locale.AddBranch.closeIcon}
                onClick={form.name ? this.handleDiscard : this.handleCloseAddBranch}
              />
            </div>
          </div>
          <div className="patient-care-modal-content" ref="modalContent">
            <div id="patient-care-scrolling-content" ref="modalContentScroll">
              <hr className="patient-care-separator first" />
              {
                !organisationId && roles.profileCurrentRole && (roles.profileCurrentRole.role === RolesConstants.ADMIN) && (
                  <AddBranchOrganisation />
                )
              }
              <AddBranchType organisationId={organisationId}/>
              <hr className="patient-care-separator" />
              <AddBranchInfo handleSelectBranch={this.handleSelectBranch} organisationId={organisationId} />
              <hr className="patient-care-separator" />
              <AddBranchAddressInfo organisationId={organisationId}/>
            </div>
          </div>
          <AddBranchButtons
            handleOpenDiscardDialog={handleOpenDiscardDialog}
            handleCloseAddBranch={this.handleCloseAddBranch}
            ref="modalAddOrDiscardButtons"
            customClassName={isScrolledEnd ? "has-shadow" : ""}
            organisationId={organisationId}
            handleCreateBranch={handleCreateBranch}
          />
        </div>
        <span className="patient-care-modal-overlay" />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBranchModal);
