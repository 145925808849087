import React, { Component, Fragment } from 'react';
import { Button } from '@patient-access/ui-kit';
import { SkeletonSearchFilter } from 'components/Share/skeleton';
import BreadcrumbsServices from '../BreadcrumbsServices/BreadcrumbsServices';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  isSuperAdmin: boolean,
};

class FilterServicesSummarySkeleton extends Component<Props> {
  render() {
    const { organisationId, isSuperAdmin } = this.props;

    return (
      <Fragment>
        {isSuperAdmin ? <div className="row"><BreadcrumbsServices organisationId={organisationId} /></div> : null}
        <div className="row">
          <div className="column column-full">
            <SkeletonSearchFilter />
          </div>
          <div className="column">
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.addService}
              onClick={() => {}}
              isLoading={true}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FilterServicesSummarySkeleton;
