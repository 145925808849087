import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button, Form, FormItem, SearchFilter, IconSearch, LogoNHS } from '@patient-access/ui-kit';
import { SidebarHeader, SidebarBody, SidebarBodySection, SidebarBodyHeader, SidebarBodyContent, SidebarFooter } from 'components/Share/sidebar';
import { SkeletonSearchFilter, SkeletonTable } from 'components/Share/skeleton';
import { setUserSearch, setUI, getBranchesList, getServiceDescription, setUserData, setUserStep } from 'actions/organisationServices';
import { columns, TableBranchesList } from '../../TableBranchesList/TableBranchesList';
import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';
import * as SearchConstants from 'constants/SearchConstants';
import locale from 'service/locale';
import { setAddBranchButtonText } from '../../helpers/OrganisationService';

type Props = {
  organisationId: string,
  searchValue: string,
  data: any,
  isLoading: boolean,
  isError: boolean,
  userData: any,
  getBranchesList: (organisationId: string, serviceId: string) => any,
  getServiceDescription: (serviceId: string) => any,
  setUserSearch: (value: string) => any,
  setUI: (value: any) => any,
  setUserData: (data: any) => any,
  setUserStep: (value: any) => any,
};

type State = {
  isValidationError: boolean,
  isBranchSelected: boolean
};

const mapStateToProps = (state) => ({
  searchValue: state.organisationServices.user.search.branchesList,
  data: state.organisationServices.branchesList.data,
  isLoading: state.organisationServices.branchesList.isLoading,
  isError: state.organisationServices.branchesList.isError,
  userData: state.organisationServices.user.data,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getBranchesList: (organisationId: string, serviceId: string) => dispatch(getBranchesList(organisationId, serviceId)),
  getServiceDescription: (serviceId: string) => dispatch(getServiceDescription(serviceId)),
  setUserSearch: (value: string) => dispatch(setUserSearch(value)),
  setUI: (value: any) => dispatch(setUI(value)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
});

class StepThree extends Component<Props, State> {
  state = {
    isValidationError: false,
    isBranchSelected: false
  }

  componentDidMount = () => {
    const { organisationId, getBranchesList, userData } = this.props;
    getBranchesList(organisationId, userData.serviceId);
  }

  componentWillUnmount = () => {
    const { setUserSearch } = this.props;
    setUserSearch({ branchesList: '' });
  }

  checkServiceSelected = (isSelected) => {
    this.setState({isBranchSelected: isSelected });
    if (isSelected) {
      this.setState({ isValidationError: false });
    }
  }

  render() {
    const { isValidationError, isBranchSelected } = this.state;
    const { organisationId, searchValue, data, isLoading, isError, userData, getBranchesList, getServiceDescription, setUserSearch, setUI, setUserStep } = this.props;
    const branchCount = userData.serviceBranches.length;

    let dataFiltered = data;
    if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
      dataFiltered = data.filter(service => service.branchName.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const isDataFilteredEmpty = dataFiltered.length === 0;

    const renderTableBranchesList = () => {
      if (isDataFilteredEmpty) {
        return (
          <NoResults type="info">
            <h2>{locale.OrganisationServices.noResults.branchesSummaryFilter}</h2>
          </NoResults>
        );
      }
      return (
        <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
          <FormItem type="item" error={isValidationError ? 'error' : null}>
            <TableBranchesList data={dataFiltered} columns={columns} props={this.props} checkServiceSelected={this.checkServiceSelected} />
          </FormItem>
        </Form>
      );
    }

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <SidebarBodySection>
              <SidebarBodyHeader>
                <h3>{locale.OrganisationServices.filter.branchesList.header}</h3>
              </SidebarBodyHeader>
              <SidebarBodyContent>
                <div className="row">
                  <SkeletonSearchFilter />
                </div>
              </SidebarBodyContent>
              <SidebarBodyContent>
                <SkeletonTable columns={1} isSticky={false} isGrey={false} />
              </SidebarBodyContent>
            </SidebarBodySection>
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <NoResults type="info">
              <h2>{locale.OrganisationServices.error.generic}</h2>
              <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.tryAgain} onClick={() => { getBranchesList(organisationId, 'SELECTED_SERVICE_ID'); }} />
            </NoResults>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <SidebarBodySection className={isDataFilteredEmpty ? 'sidebar-body-section-empty' : ''}>
            <SidebarBodyHeader>
              <h3>{locale.OrganisationServices.filter.branchesList.header}</h3>
            </SidebarBodyHeader>
            <SidebarBodyContent>
              <Form onSubmit={(e) => { e.preventDefault(); }} noValidate>
                <div className="row">
                  <SearchFilter
                    id="searchBranchesList"
                    name="searchBranchesList"
                    placeholder={locale.OrganisationServices.filter.branchesList.placeholder}
                    autoComplete="off"
                    value={searchValue}
                    clearHandler={() => { setUserSearch({ branchesList: '' }); }}
                    onChange={(e) => { setUserSearch({ branchesList: e.target.value }); }}
                    icon={<IconSearch outline />}
                  />
                </div>
              </Form>
            </SidebarBodyContent>
            <SidebarBodyContent>
              {renderTableBranchesList()}
            </SidebarBodyContent>
          </SidebarBodySection>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <SidebarHeader>
          <h2>{locale.OrganisationServices.sidebar.addService.header}</h2>
          <p>
            <span>{userData.serviceName}</span>
            {userData.serviceIsNhsCovered ? <i><LogoNHS /></i> : null}
            <a
              href="#description"
              onClick={(e) => {
                e.preventDefault();
                setUI({ isOverlayDescriptionVisible: true });
                getServiceDescription(userData.serviceId);
            }}>
              {locale.OrganisationServices.sidebar.serviceDescription}
            </a>
          </p>
        </SidebarHeader>
        <SidebarBody>
          {renderContent()}
        </SidebarBody>
        <SidebarFooter>
          <div className="row">
            <div className="column">
              {isValidationError ? <p className="error">{locale.OrganisationServices.validationError.selectOneBranch}</p> : null}
            </div>
            <div className="column">
              <Button
                buttonType="blueline"
                messageKey="some-key"
                defaultMessage={locale.OrganisationServices.button.goBack}
                onClick={() => { setUserStep(2) }}
              />
              <Button
                className={isBranchSelected ? "" : "ui-kit-button-disabled-visually"}
                buttonType="secondary"
                messageKey="some-key"
                defaultMessage={setAddBranchButtonText(branchCount, data.length)}
                onClick={() => {isBranchSelected ? setUI({ isOverlayConfirmationVisible: true, overlayCofirmationMode: OrganisationServicesConstants.OVERLAY_MODE.ADD }) : this.setState({ isValidationError: true })}}
              />
            </div>
          </div>
        </SidebarFooter>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
