import React, { Component } from 'react';
import { Tooltip } from '@patient-access/ui-kit';
import IconInfo from 'svg/IconInfo';
import './styles/task-info.scss';

type Props = {
  label: string,
};

class TaskInfo extends Component<Props> {
  render() {
    const { label } = this.props;
    return (
      <div className="task-info">
        <Tooltip
          vertical="middle"
          horizontal="left"
          // vertical="bottom"
          // horizontal="right"
          message={label}
        >
          <i><IconInfo outline={false} /></i>
        </Tooltip>
      </div>
    );
  }
};

export default TaskInfo;
