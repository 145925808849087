import * as UsersConstants from 'constants/UsersConstants';
import type { Action } from 'types/actions';

type UsersState = {
  usersList: any[],
  pages: number,
  pageNumber: number,
  searchValue: string,
  isUsersPending: boolean,
}

const initialState: UsersState = {
  usersList: [],
  pages: 0,
  pageNumber: 0,
  searchValue: "",
  isUsersPending: false,
};

export default (localState: UsersState = initialState, action: Action): UsersState => {
  switch (action.type) {
    case UsersConstants.SET_USERS_LIST: {
      const { usersList, searchValue } = action.payload;
      const { items, pages, pageNumber } = usersList;
      return {
        ...localState,
        usersList: items,
        pages: pages === 0 ? localState.pages : pages,
        pageNumber: pageNumber,
        searchValue,
        isUsersPending: false,
      };
    }
    case UsersConstants.SET_USERS_PENDING:
      return {
        ...localState,
        isUsersPending: true,
      };
    default: {
      return localState;
    }
  }
}
