import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';

import locale from 'service/locale';

type Props = {
  name: string,
  stripeId: string,
  accountStatus: boolean,
};

const mapStateToProps = (state) => ({});

class BankAccountDetails extends Component<Props> {

  render() {
    const { accountStatus, name, stripeId } = this.props;

    return (
      <Fragment>
        <div className="patient-care-block-margin-medium">
          <h3>
            {locale.PayoutDetails.payoutDetails}
          </h3>
        </div>
        <div className="patient-care-description-block">
          <p>
            <b>{name}</b>
            {locale.PayoutDetails.payoutMethodDescription1}
            <b>{stripeId}</b>
            {locale.PayoutDetails.payoutMethodDescription2}
          </p>
        </div>
        <div className="patient-care-heading">
          <h4>
            {locale.PayoutDetails.payoutStep1}
          </h4>
        </div>
        <b className={`patient-care-status ${accountStatus ? "success" : "error"}`}>{accountStatus ? locale.PayoutDetails.bankDetailsProvided : locale.PayoutDetails.bankDetailsNotProvided}</b>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(BankAccountDetails);
