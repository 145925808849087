import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormItem, Input, LinkIcon, IconClose } from '@patient-access/ui-kit';
import { deleteRoomAction, setRoomFutureAppointments } from 'actions/rooms';

import type { Action } from 'types/actions';

import locale from 'service/locale';
import ConfirmationOverlay from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";

type Props = {
  isEditMode: boolean,
  data: { id: string | null, text: string},
  handleDeleteItem: Function,
  handleDataChanged: (id: string | null, text: string) => Function,
  deleteRoom: Function,
  roomFutureAppointments: { roomId: string | null, listOfAppointments: any[]}[],
  setRoomFutureAppointments: Function,
};

type State = {
  text: string,
  id: string | null,
  openDiscardChanges: boolean,
};

const mapStateToProps = (state) => ({
  roomFutureAppointments: state.rooms.roomFutureAppointments
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  deleteRoom: (id) => dispatch(deleteRoomAction(id)),
  setRoomFutureAppointments: (id) => dispatch(setRoomFutureAppointments(id)),
});

class RoomItem extends Component<Props, State> {

  state = {
    text: "",
    id: null,
    openDiscardChanges: false,
  };

  componentDidMount = () => {
    const { data } = this.props;
    const { id, text } = data;
    this.setState({ id, text });
  };

  handleNameChange = (e: any) => {
    const { handleDataChanged  } = this.props;
    const { id  } = this.state;
    e && e.persist();

    const text = e.target.value;
    this.setState({ text }, () => {
      handleDataChanged(id, text);
    });

  };

  handleDeleteItem = (e: any) => {
    e && e.preventDefault();
    const { deleteRoom, data, handleDataChanged } = this.props;
    deleteRoom(data.id);
    handleDataChanged();
  };

  deleteClicked = () => {
    const { id, text } = this.state;
    const { setRoomFutureAppointments, deleteRoom } = this.props;

    text.length ? setRoomFutureAppointments(id) : deleteRoom(id);

    this.setState({
      openDiscardChanges: true
    });
  };

  handleConfirm = () => {
    this.handleDeleteItem();
    this.setState({
      openDiscardChanges: false
    });
  };

  handleCancel = () => {
    this.setState({
      openDiscardChanges: false
    });
  };

  getConfirmationContent = () => {
    const { text } = this.state;
    const { roomFutureAppointments } = this.props;
    const { listOfAppointments } = roomFutureAppointments;

    let allAppointments = [];
    listOfAppointments.forEach(({date, appointments}) => {
      allAppointments = allAppointments.concat(appointments);
    });
    const listOfAppointmentsLen = allAppointments.length;

    const confirmationWithoutAppointments = locale.BranchRooms.confirmationWithoutAppointments;
    const confirmationWithAppointments = locale.BranchRooms.confirmationWithAppointments;
    const confirmation = [
      `${confirmationWithoutAppointments[0]} ${text}${confirmationWithoutAppointments[1]}`,
      `${confirmationWithAppointments[0]}
      ${listOfAppointmentsLen}
      ${listOfAppointmentsLen === 1 ? confirmationWithAppointments[1] : confirmationWithAppointments[2]}
      ${confirmationWithAppointments[3]}`
    ];

    const index = listOfAppointmentsLen === 0 ? 0 : 1;
    return  { confirmation: confirmation[index], isRoomHasNoAppointments: listOfAppointmentsLen === 0  };
  };

  render() {
    const { isEditMode, roomFutureAppointments } = this.props;
    const { id, text, openDiscardChanges } = this.state;

    return (
      <Fragment>
        {
          isEditMode ? (
            <Fragment>
              <div className="patient-care-custom-table-cell" style={{width: "80%"}}>
                <Form noValidate className="patient-care-rooms-form">
                  <FormItem type="item">
                    <Input
                      type="text"
                      id={id}
                      name="some-name"
                      placeholder={locale.BranchRooms.newRoomPlaceholder}
                      value={text}
                      onChange={this.handleNameChange}
                      data-id="data-id"
                    />
                  </FormItem>
                </Form>
              </div>

              <div className="patient-care-custom-table-cell patient-care-align-right" style={{width: "20%"}}>
                <div
                  className="patient-care-btn-delete-row"
                  onClick={this.deleteClicked}
                >
                  <LinkIcon
                    to="#"
                    size="small"
                    icon={<IconClose outline />}
                    accessibilitySpan={locale.UserDetails.deleteRoleIcon}
                    onClick={(e)=>{e.preventDefault()}}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            <b className="patient-care-custom-table-cell patient-care-editable-cell">{text}</b>
          )
        }
        {
          openDiscardChanges ? (
            <ConfirmationOverlay
              handleConfirm={this.handleConfirm}
              handleCancel={this.handleCancel}
              content={this.getConfirmationContent().confirmation}
              buttonConfirm={locale.BranchRooms.removeRoomBtn}
              buttonCancel={locale.BranchRooms.cancelBtn}
              isShowConfirmButton={this.getConfirmationContent().isRoomHasNoAppointments}
              isLoading={!(roomFutureAppointments && typeof roomFutureAppointments.roomId === "string")}
            />
          ) : (
            null
          )
        }
      </Fragment>
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomItem)

