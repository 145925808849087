import React, { Component } from "react";
import { Icon } from "@patient-access/ui-kit";

import "./styles.scss";

type Props = {
  id: string,
  name: string,
  value: string,
  label: string,
  onClick: any,
  icon: any,
  defaultChecked: any,
  checked:any,
  isDisabled: any
};

class AddBranchTypeRadio extends Component<Props> {
  render() {
    const { id, name, value, label, onClick, icon, defaultChecked, checked , isDisabled } = this.props;
    return (
      <div className="input-radio input-radio-card patient-care-radio-icon">
        <label htmlFor={id} className={isDisabled? "radio-disabled": null}>
          <input type="radio" id={id} name={name} value={value} defaultChecked={defaultChecked} checked={checked !== undefined ? checked : false} disabled={isDisabled} onChange={onClick}/>
          <span>{label}</span>
          <Icon type="inline" size="large" icon={icon} />
        </label>
      </div>
    );
  }
}

export default AddBranchTypeRadio;
