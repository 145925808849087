import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addNewBranchService, deleteNewBranchService } from 'actions/services';
import { Form, FormItem, Checkbox } from '@patient-access/ui-kit';

type Props = {
  service: Object;
  serviceIndex: number;
  defaultChecked?: boolean;
  addNewBranchService: (service: Object) => any;
  deleteNewBranchService: (service: Object) => any;
  onCheckHandler?: Function
}

type State = {
  checked: boolean;
}

const mapDispatchToProps = (dispatch: (action: any) => any): any => ({
  addNewBranchService: (service) => dispatch(addNewBranchService(service)),
  deleteNewBranchService: (service) => dispatch(deleteNewBranchService(service)),
});

export class NewServiceItem extends Component<Props, State> {
  state = {
    checked: this.props.defaultChecked === true
      ? true
      : this.props.defaultChecked === false
        ? false
        : this.props.service.isExist
  };

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      checked: nextProps.defaultChecked === true
        ? true
        : nextProps.defaultChecked === false
          ? false
          : nextProps.service.isExist
    })
  };


  handleUpdateStatus = (e: any) => {
    const { service, addNewBranchService, deleteNewBranchService, serviceIndex } = this.props;
    const { checked } = this.state;
    const checkedUpdated = !checked;

    this.setState({
      checked: checkedUpdated,
    }, () => {
      checkedUpdated
        ? addNewBranchService(service)
        : deleteNewBranchService(service);
    });

    const onCheckHandler = this.props.onCheckHandler || (() => { });
    onCheckHandler(service, checkedUpdated, serviceIndex);
  };

  handleViewDescription = () => {
    const { handleViewDescription, service } = this.props;
    handleViewDescription(service.id);
  };


  render() {
    const { service } = this.props;
    const { checked } = this.state;

    return (
      <li className="patient-care-table-item">
        <div className="patient-care-row-align-space-between">
          <Form noValidate>
            <FormItem type="item">
              <Checkbox
                id={service.name}
                name={service.name}
                value={service.name}
                checked={service.isExist || checked}
                onChange={this.handleUpdateStatus}
                label={`${service.name} ${service.isExist ? "(Already added)" : ""}`}
                data-id={service.name}
                disabled={service.isExist}
              />
            </FormItem>
          </Form>
          <button className="patient-care-btn-link" onClick={this.handleViewDescription}>View description</button>
        </div>
      </li>
    )
  }
}

export default connect(null, mapDispatchToProps)(NewServiceItem)

