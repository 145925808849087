import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { LinkIcon, IconClose } from "@patient-access/ui-kit";
import { drawerScrollHandler } from "helpers/common";
import {
  Button,
  IconDelete,
  FormItem
} from "@patient-access/ui-kit";
import locale from "service/locale";
import InternalEventInfo from "./InternalEventInfo/InternalEventInfo";
import type { PanelActions } from "types/panel";
import { closeInternalEventDetails } from "actions/panel";
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import * as RoutesConstants from 'constants/RoutesConstants';
import { cancelAppointment, removeInternalEvent } from "actions/appointmentDetails"
import RemoveInternalEventWarningModal from "./removeInternalEventWarningModal/removeInternalEventWarningModal";

type Props = {
  handleCloseAppointmentDetails: () => any,
  handleDiscardChanges: () => any,
  isActiveAgenda: boolean,
  internalEvent: any,
  closeInternalEventDetails: () => PanelActions,
  removeInternalEvent: (calendarId, eventId) => any,
  cancelAppointment: (reason, appId, type, behalfOfPatient) => any
};

type State = {
  isScrolledStart: boolean,
  isScrolledEnd: boolean,
  isDeletionWarning: boolean
};

const mapStateToProps = state => ({
  isActiveAgenda: state.panel.isActiveAgenda,
  internalEvent: state.appointmentDetails.internalEvent,
  isDeleteEventPending: state.appointmentDetails.isDeleteEventPending
});

const mapDispatchToProps = (dispatch: Function): any => ({
  closeInternalEventDetails: () => dispatch(closeInternalEventDetails()),
  removeInternalEvent: (calendarId, eventId) => dispatch(removeInternalEvent(calendarId, eventId)),
  cancelAppointment: (reason, appId, type, behalfOfPatient) => dispatch(cancelAppointment(reason, appId, type, behalfOfPatient))
});

class InternalEventDetails extends Component<Props, State> {

  state = {
    isScrolledStart: false,
    isScrolledEnd: false,
    isDeletionWarning: false
  };

  componentDidMount = () => {
    drawerScrollHandler.bind(this)();
  };

  handleCloseEventDetails = () => {
    const { closeInternalEventDetails } = this.props;
    closeInternalEventDetails();
  }

  handleRemoveEventDetails = () => {
    this.setState({ isDeletionWarning: true });
  }

  handleCloseBookingWarning = (e: any) => {
    e && e.preventDefault();
    this.setState({
      isDeletionWarning: false
    });
  };

  handleDeleteEventDetails = (e: any) => {
    e && e.preventDefault();
    const { removeInternalEvent, cancelAppointment,  internalEvent } = this.props;
    const { calendar, eventId, appointmentId } = internalEvent;
    if(appointmentId === AppointmentDetailsConstants.DEFAULT_APPOINTMENTID)
      removeInternalEvent(calendar.id, eventId);
    else
      cancelAppointment(null, appointmentId, null ,false);
  }

  render() {
    const {
      isActiveAgenda,
      internalEvent,
      isDeleteEventPending
    } = this.props;

    const { isDeletionWarning } = this.state;

    const { isScrolledStart } = this.state;
    return (
      <Fragment>
        <div
          className={`patient-care-modal-holder ${
            isActiveAgenda ? "agenda-active" : ""
            }`}
        >
          <div
            className="patient-care-modal"
            onScroll={drawerScrollHandler.bind(this)}
          >
            <div
              className={`patient-care-modal-header ${
                isScrolledStart ? "has-shadow" : ""
                }`}
              ref="modalHeader"
            >
              <h2>{locale.InternalEventDetails.header}</h2>
              <div className="patient-care-btn-close">
                <LinkIcon
                  to="#"
                  size="small"
                  icon={<IconClose outline />}
                  accessibilitySpan={
                    locale.InternalEventDetails.closeIcon
                  }
                  onClick={this.handleCloseEventDetails}
                />
              </div>
            </div>
            <div className="patient-care-modal-content" ref="modalContent">
              <div
                id="patient-care-scrolling-content"
                ref="modalContentScroll"
              >
                {internalEvent &&
                  <InternalEventInfo eventDetails={{
                    endTime: internalEvent.endTime,
                    startTime: internalEvent.startTime,
                    name: internalEvent.title,
                    calendarName: internalEvent.calendar.name,
                    bookTime: internalEvent.createdDate
                  }} />
                }
              </div>
            </div>
            <div className="patient-care-modal-footer">
              <FormItem type="buttons">
                <div className="patient-care-modal-buttons-row">
                  <div className="patient-care-btn-delete">
                    <LinkIcon
                      to={RoutesConstants.HOME}
                      icon={<IconDelete outline />}
                      size="medium"
                      accessibilitySpan={locale.Appointment.buttons.delete}
                      onClick={this.handleRemoveEventDetails}
                    />
                  </div>
                  <div className="patient-care-buttons-group">
                    <Button
                      buttonType="blueline"
                      messageKey="cancel-btn"
                      defaultMessage={locale.Appointment.buttons.close}
                      onClick={this.handleCloseEventDetails}
                      data-id="cancel-changes-btn"
                      isLoading={isDeleteEventPending}
                    />
                  </div>
                </div>
              </FormItem>
            </div>
          </div>
          <span className="patient-care-modal-overlay" />
        </div>

        {isDeletionWarning &&
          <RemoveInternalEventWarningModal
            isLoading={isDeleteEventPending}
            handleConfirmDeleteEvent={this.handleDeleteEventDetails}
            handleCloseModal={this.handleCloseBookingWarning} />
        }
      </Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalEventDetails);
