import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { setService, filterSlotsByCalendar, closeSlotsPanel, getSlots } from 'actions/bookAppointment';
import { formatListAsOptions, formatItemAsOption } from "helpers/formatData";
import { LinkIcon, IconChevronLeft } from "@patient-access/ui-kit";
import * as ServiceConstants from "constants/ServicesConstants";

import locale from "service/locale";

type Props = {
  closeSlotsPanel: () => any,
  servicesList: any[],
  calendarsList: any[];
  selectedService: Object;
  setService: (service: Object) => any;
  filterSlotsByCalendar: (calendar: Object) => any;
  form: any,
  getSlots: (branchId: string) => Function,
};

const mapStateToProps = state => ({
  servicesList: state.branchDetails.services,
  calendarsList: state.branchDetails.calendarsList,
  selectedService: state.book.service,
  form: state.form
});

const mapDispatchToProps = (dispatch: any) => ({
  closeSlotsPanel: () => dispatch(closeSlotsPanel()),
  setService: (service) => dispatch(setService(service)),
  filterSlotsByCalendar: (calendar) => dispatch(filterSlotsByCalendar(calendar)),
  getSlots: (branchId) => dispatch(getSlots(branchId)),
});

class SlotsFilters extends Component<Props> {

  handleCloseSlots = (e: any) => {
    e && e.preventDefault();
    const { closeSlotsPanel } = this.props;
    closeSlotsPanel();
  };

  handleChangeService = (service) => {
    const { setService, filterSlotsByCalendar, selectedService, form } = this.props;
    let duration;

    if ([ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID, ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID].includes(service.value))
      duration = form.duration || service.duration;
    else
      duration = service.duration;

    setService({
      name: service.label,
      id: service.value,
      duration: duration,
      price: service.price,
      lastMinute: service.lastMinute,
      type: service.appointmentType,
      modes: service.modes
    });

    if ([ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID, ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID].includes(service.value)
      && selectedService.id !== service.value) {
      var calendar = {
        value: "",
        label: "[ Auto selected from avail. ]"
      }
      filterSlotsByCalendar(calendar)
    }
  };

  handleChangeCalendar = (calendar) => {
    const { filterSlotsByCalendar, getSlots, branchId } = this.props;
    filterSlotsByCalendar(calendar)
      .then(() =>
        getSlots(branchId))
  };

  render() {
    const { servicesList, calendarsList, selectedService } = this.props;
    const calendarFilterOptions = formatListAsOptions(calendarsList);
    const serviceFilterOptions = formatListAsOptions(servicesList);
    const canSeeCalendarFilter = ![ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID, ServiceConstants.CUSTOM_APPOINTMENT_SERVICE_ID].includes(selectedService.id);

    calendarFilterOptions.unshift({
      value: "all",
      label: "All calendars"
    });
    serviceFilterOptions.unshift(ServiceConstants.INTERNAL_EVENTS_AS_SERVICE);

    const serviceFilterOptionsNoVideo = serviceFilterOptions.filter((item) => {
        return item.appointmentType !== 1 && item.modes && item.modes.length;
    });

    return (
      <div className="patient-care-container">
        <div className="patient-care-row">
          <div className="patient-care-filter-slots-title">
            <LinkIcon
              to="#"
              size="small"
              icon={<IconChevronLeft outline={true} />}
              accessibilitySpan={locale.AppointmentSlots.accessibilitySpan}
              onClick={this.handleCloseSlots}
            />
            <h2>{locale.AppointmentSlots.header}</h2>
          </div>
          <div className="patient-care-slots-filters">
            <Select
              onChange={this.handleChangeService}
              defaultValue={formatItemAsOption(selectedService)}
              options={serviceFilterOptionsNoVideo}
              placeholder={locale.AppointmentSlots.typePlaceholder}
              className="patient-care-select"
              classNamePrefix="patient-care"
              isSearchable={false}
            />
            { canSeeCalendarFilter &&
              <Select
                onChange={this.handleChangeCalendar}
                defaultValue={calendarFilterOptions[0]}
                options={calendarFilterOptions}
                placeholder={locale.AppointmentSlots.calendarPlaceholder}
                className="patient-care-select"
                classNamePrefix="patient-care"
                isSearchable={false}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlotsFilters);
