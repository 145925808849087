import React, { Component, Fragment } from 'react';
import { Button } from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleChangePassword: () => any;
}

class ChangePassword extends Component<Props> {
  render() {
    const { handleChangePassword } = this.props;
    return (
      <Fragment>
        <hr className="patient-care-separator" />
        <div className="patient-care-description-block">
          <h3>
            {locale.UserDetails.changePasswordTitle}
          </h3>
          <p>{locale.UserDetails.changePasswordContent}</p>
        </div>
        <div className="patient-care-block">
          <Button
            buttonType="blueline"
            messageKey="change-password"
            defaultMessage={locale.UserDetails.changePasswordButton}
            onClick={handleChangePassword}
            data-id="change-password"
          />
        </div>
      </Fragment>
    )
  }
}

export default ChangePassword;
