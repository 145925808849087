import React, { Component, Fragment } from "react";
import Select from "react-select";
import { connect } from 'react-redux';
import { Form } from "@patient-access/ui-kit";
import TimeInterval from "components/Pages/Admin/BranchContent/TimeInterval/TimeInterval" 
import * as CalendarsConstants from 'constants/CalendarsConstants';
import { updateForm } from "actions/form";
import type { Action } from 'types/actions';
import locale from "service/locale";
import moment from 'moment';

type Props = {
  updateForm: (data: any) => Action;
  form: any;
  dayLabel: string,
  dayName: string,
  status: string,
  isEditMode: boolean,
  startValue: string,
  endValue: string,
  handleEditTime: () => Action,
  isBranchClosed: boolean
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data) => dispatch(updateForm(data)),
});

class AvailabilityItem extends Component<Props> {

  handleTimeChange = ({ target: { name, value } }) => {
    const { updateForm, form } = this.props;
    const parameters = name.split("_");
    updateForm({
      [parameters[0]]: {
        ...form[parameters[0]],
        [parameters[1]]: value
      }
    });
  };

  handleSelectDayStatus = (data: any) => {
    const { updateForm, form, dayLabel } = this.props;
    const { value, name } = data;
    const changedOpenHours = form.openHours.slice(0);

    const matchedDayOfWeek = locale.AddNewCalendar.daysOfWeek.find(
            day => day.label === dayLabel
          );    
    const index = form.openHours && form.openHours.findIndex(date => date.day === matchedDayOfWeek.dayOfWeek);
    const daySettings = {
      day: matchedDayOfWeek.dayOfWeek,
      timesSessions: value === locale.AddNewCalendar.dayAvailableOptions[1].value ? 
      [
        {          
          from: CalendarsConstants.EMPTY_VALUE,
          to: CalendarsConstants.EMPTY_VALUE
        }
      ] :
      [
        {
          from: "",
          to: ""
        }
      ]
    }
    changedOpenHours.splice(index, 1, daySettings);
    updateForm({
      [name]: {
        ...form[name],        
        status: value
      },
      openHours: changedOpenHours
    });    
  };
  
  render() {
    const {      
      dayLabel,
      dayName,
      status,
      isEditMode,
      handleEditTime,
      timeSessionForDay,
      isBranchClosed
    } = this.props;
    
    const isStatusAvailable = status.value === locale.AddNewCalendar.dayAvailableOptions[0].value;    
    
    return (
      <div className="patient-care-block-row">
          { isEditMode ? ( 
            <Fragment>           
              <div className="patient-care-day-col">
                <span className="patient-care-day">{dayLabel}</span>
                <Select
                  id={dayName}
                  name={dayName}
                  options={locale.AddNewCalendar.dayAvailableOptions}
                  value={status}
                  classNamePrefix="patient-care"
                  className={`patient-care-select`}
                  isDisabled={!isStatusAvailable && isBranchClosed}
                  onChange={e =>
                    this.handleSelectDayStatus({
                      value: e.value,
                      name: dayName
                    })
                  }
                  isSearchable={false}
                />
              </div>              
              { isStatusAvailable
                ? (
                  <div className="patient-care-time-col edited">
                    { 
                      timeSessionForDay &&                        
                      timeSessionForDay.timesSessions && timeSessionForDay.timesSessions.map((timeSession,index) => {
                            return (
                              <TimeInterval
                                intervalIndex={index}
                                timesSessions={timeSessionForDay.timesSessions}
                                dayOfWeek={timeSessionForDay.day}  
                                handleEditTime={handleEditTime}                 
                                key={index} />
                            );
                        })
                    }
                  </div>
                  )
                : null
              }              
            </Fragment>
          ) : (
            <Fragment>
            <div className="patient-care-day-col">
                <span className="patient-care-day">{dayLabel}</span>
                <span className="patient-care-filled-select patient-care-filled-status">{status.label}</span>
              </div>
              {isStatusAvailable ? (
                <div className="patient-care-time-col">
                {
                  timeSessionForDay.timesSessions && timeSessionForDay.timesSessions.length ? (
                    timeSessionForDay.timesSessions.map((timeSession, index) => {
                      const { from, to } = timeSession;
                      return (
                        <Form noValidate className="patient-care-time-inputs" key={index}>
                          <span className="patient-care-filled-input">
                            {
                              (from && from.length)
                                ? moment(from, CalendarsConstants.SERVER_TIME_FORMAT).format(CalendarsConstants.CLIENT_TIME_FORMAT)
                                : CalendarsConstants.EMPTY_VALUE
                            }
                          </span>
                                <span className="patient-care-filled-input">
                            {
                              (to && to.length)
                                ? moment(to, CalendarsConstants.SERVER_TIME_FORMAT).format(CalendarsConstants.CLIENT_TIME_FORMAT)
                                : CalendarsConstants.EMPTY_VALUE
                            }
                          </span>
                        </Form>)
                        }) ) : null
                  }
                  </div>
                  ) : null}
            </Fragment>
          )
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityItem);
