import locale from 'service/locale';
import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';

export const setAddBranchButtonText = (selectedBranchCount, branchListCount) => {
    if (selectedBranchCount === branchListCount) {
        return locale.OrganisationServices.button.addServiceToAllBranches;
    } else if (selectedBranchCount === 0) {
        return locale.OrganisationServices.button.addServiceToBranches;
    }
    return locale.OrganisationServices.button.addServiceToNoBranches(selectedBranchCount);
}

export const setAddBranchHeaderText = (selectedBranchCount, branchListCount, serviceName) => {
    if (selectedBranchCount === branchListCount) {
        return locale.OrganisationServices.overlay.confirmation.headerAllBranches(serviceName);
    }
    return locale.OrganisationServices.overlay.confirmation.header(serviceName, selectedBranchCount);
}

export const setEditBranchHeaderText = (selectedBranchCount, branchListCount, serviceName) => {
    if (selectedBranchCount === branchListCount) {
        return locale.OrganisationServices.overlay.confirmation.editHeaderAllBranches(serviceName);
    }
    return locale.OrganisationServices.overlay.confirmation.editHeader(serviceName, selectedBranchCount);
}

export const setEditBranchButtonText = (selectedBranchCount, branchListCount) => {
    if (selectedBranchCount === branchListCount) {
        return locale.OrganisationServices.button.editAtAllBranches;
    }
    return locale.OrganisationServices.button.editAtNoBranches(selectedBranchCount);
}

export const setErrorBranchHeaderText = (mode, branchCount) => {
    switch (mode) {
        case OrganisationServicesConstants.OVERLAY_MODE.ADD:
            return locale.OrganisationServices.overlay.confirmation.errorHeaderAddPartialBranches(branchCount);
        case OrganisationServicesConstants.OVERLAY_MODE.EDIT:
            return locale.OrganisationServices.overlay.confirmation.errorHeaderEditPartialBranches(branchCount);
        case OrganisationServicesConstants.OVERLAY_MODE.DELETE:
            return locale.OrganisationServices.overlay.confirmation.errorHeaderDeletePartialBranches(branchCount);
        default:
            return null;
    }
}

export const setErrorAllBranchContentText = (mode) => {
    switch (mode) {
        case OrganisationServicesConstants.OVERLAY_MODE.ADD:
            return locale.OrganisationServices.overlay.confirmation.errorAddAllContent;
        case OrganisationServicesConstants.OVERLAY_MODE.EDIT:
            return locale.OrganisationServices.overlay.confirmation.errorEditAllContent;
        case OrganisationServicesConstants.OVERLAY_MODE.DELETE:
            return locale.OrganisationServices.overlay.confirmation.errorDeleteAllContent;
        default:
            return null;
    }
}

export const setErrorPartialBranchContentText = (mode, branchCount) => {
    switch (mode) {
        case OrganisationServicesConstants.OVERLAY_MODE.ADD:
            return locale.OrganisationServices.overlay.confirmation.errorAddPartialContent(branchCount);
        case OrganisationServicesConstants.OVERLAY_MODE.EDIT:
            return locale.OrganisationServices.overlay.confirmation.errorEditPartialContent(branchCount);
        case OrganisationServicesConstants.OVERLAY_MODE.DELETE:
            return locale.OrganisationServices.overlay.confirmation.errorDeletePartialContent(branchCount);
        default:
            return null;
    }
}
