import React, { Component } from 'react';
import { Loader } from '@patient-access/ui-kit';
import './styles/skeleton-chart.scss';

type Props = {
  height: number,
};

class SkeletonChart extends Component<Props> {
  render() {
    const { height } = this.props;
    return (
      <div className="skeleton-chart" style={{ height: height }}>
        <div className="reports-smartpharmacy-chart">
          <Loader type="inline" size="small" className="reports-smartpharmacy-chart-loader" />
        </div>
      </div>
    );
  }
};

export default SkeletonChart;
