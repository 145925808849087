import React, { Component, Fragment } from 'react';
import { CalendarFiltersSection, CalendarFiltersBody } from '../wrappers';
import { SkeletonCalendarFiltersTitle, SkeletonCalendarFiltersItem } from '../skeleton';

class SectionSkeleton extends Component {
  render() {
    return (
      <Fragment>
        <CalendarFiltersSection>
          <SkeletonCalendarFiltersTitle />
          <CalendarFiltersBody isExpanded={true}>
            <SkeletonCalendarFiltersItem size={40} />
            <SkeletonCalendarFiltersItem size={80} />
            <SkeletonCalendarFiltersItem size={60} />
          </CalendarFiltersBody>
        </CalendarFiltersSection>
        <CalendarFiltersSection>
          <SkeletonCalendarFiltersTitle />
          <CalendarFiltersBody isExpanded={true}>
            <SkeletonCalendarFiltersItem size={40} />
            <SkeletonCalendarFiltersItem size={60} />
            <SkeletonCalendarFiltersItem size={70} />
            <SkeletonCalendarFiltersItem size={50} />
            <SkeletonCalendarFiltersItem size={60} />
            <SkeletonCalendarFiltersItem size={40} />
          </CalendarFiltersBody>
        </CalendarFiltersSection>
        <CalendarFiltersSection>
          <SkeletonCalendarFiltersTitle />
          <CalendarFiltersBody isExpanded={true}>
            <SkeletonCalendarFiltersItem size={80} />
            <SkeletonCalendarFiltersItem size={40} />
            <SkeletonCalendarFiltersItem size={60} />
          </CalendarFiltersBody>
        </CalendarFiltersSection>
      </Fragment>
    );
  }
};

export default SectionSkeleton;
