import React, { Component, Fragment } from 'react';
import { Button } from '@patient-access/ui-kit';
import BreadcrumbsServices from '../BreadcrumbsServices/BreadcrumbsServices';
import { SkeletonSearchFilter } from 'components/Share/skeleton';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  serviceId: string,
};

class FilterBranchesSummarySkeleton extends Component<Props> {
  render() {
    const { organisationId, serviceId } = this.props;

    return (
      <Fragment>
        <div className="row">
          <BreadcrumbsServices organisationId={organisationId} serviceId={serviceId} />
        </div>
        <div className="row">
          <div className="column column-full">
            <SkeletonSearchFilter />
          </div>
          <div className="column">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.addToBranches}
              onClick={() => {}}
              isLoading={true}
            />
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.OrganisationServices.button.editService}
              onClick={() => {}}
              isLoading={true}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FilterBranchesSummarySkeleton;
