import React, { Component } from 'react';

type Props = {
  outline: boolean,
};

class IconSmartPharmacy extends Component<Props> {
  renderIconOutline = () => {
    return (
      <path d="" />
    );
  }

  renderIconFill = () => {
    return (
      <path d="M5,6H19a1,1,0,0,0,0-2H5A1,1,0,0,0,5,6ZM20.16,7.8a1,1,0,0,0-1-.8H4.82a1,1,0,0,0-1,.8l-1,5a1,1,0,0,0,1,1.2H4v5a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V14h4v5a1,1,0,0,0,2,0V14h.18a1,1,0,0,0,1-1.2ZM12,18H6V14h6Z" />
    );
  }

  render() {
    const { outline } = this.props;
    return (
      <svg className={`icon-smart-pharmacy ${outline ? 'icon-outline' : 'icon-fill'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {outline ? this.renderIconOutline() : this.renderIconFill()}
      </svg>
    );
  }
}

export default IconSmartPharmacy;
