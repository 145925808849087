import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, InputLabel } from "@patient-access/ui-kit";
import Select from "react-select";

import { updateForm } from "actions/form";
import type { Action } from 'types/actions';
import type { Calendar } from 'types/calendars';
import * as RolesConstants from 'constants/RolesConstants';
import * as CalendarsConstants from 'constants/CalendarsConstants';

import locale from 'service/locale';

type Props = {
  form: any,
  currentRole: Object,
  isEditMode: boolean,
  handleEditBookingCutoff: () => Action,
  calendar: Calendar,
  updateForm: (data: any) => Action,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  calendar: state.calendar,
  form: state.form,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class BookingCutoff extends Component<Props> {

  handleChangeBookingCutoff = (option: any) => {
    const { updateForm } = this.props;
    updateForm({
      bookingCutoff: {
        advance: option
      }
    });
  };

  handleEditBookingCutoff = () => {
    const { handleEditBookingCutoff } = this.props;
    handleEditBookingCutoff();
  };

  render() {
    const { calendar, currentRole, isEditMode, form } = this.props;
    const { bookingCutoff } = calendar;
    const isBranchMember = currentRole.role === RolesConstants.BRANCH_MEMBER;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>
              {locale.AvailabilitySettings.bookingCutoff}
            </h3>

            {
              !isBranchMember &&
              <button
                className="patient-care-btn-link"
                onClick={this.handleEditBookingCutoff}>
                { isEditMode
                  ? locale.Buttons.buttonDone
                  : locale.Buttons.buttonEdit
                }
              </button>
            }

          </div>
          <p>{locale.AvailabilitySettings.bookingCutoffDescription}</p>
        </div>

        {isEditMode ? (
          <Fragment>
            <div className="patient-care-block">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="advance"
                    message={locale.AvailabilitySettings.labelMaximum}
                    size="small"
                    className="patient-care-roles-item-heading"
                  />
                </FormItem>
              </Form>
              <div className="patient-care-modal-edit-row">
                <div className="patient-care-col-6">
                  <Select
                    id="advance"
                    name="advance"
                    options={CalendarsConstants.AdvancedOptions}
                    placeholder="Available"
                    classNamePrefix="patient-care"
                    className="patient-care-select"
                    value={form.bookingCutoff && form.bookingCutoff.advance}
                    defaultOption={form.bookingCutoff && form.bookingCutoff.advance}
                    onChange={this.handleChangeBookingCutoff}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="advance"
                      message={locale.AvailabilitySettings.labelMaximum}
                      size="small"
                      className="patient-care-roles-item-heading"
                    />
                  </FormItem>
                </Form>
                <span className="patient-care-filled-input">
                  {
                    form.bookingCutoff
                    ? ( form.bookingCutoff.advance && form.bookingCutoff.advance.label )
                    : ( bookingCutoff && bookingCutoff.advance && bookingCutoff.advance.label )
                  }
                </span>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (BookingCutoff);
