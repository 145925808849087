import React, { Component } from "react";
import Select from "react-select";

import * as UserConstants from 'constants/UsersConstants';

type Props = {
  handleSelectMFA: () => Action;
};

type State = {
  mfaStatus: String,
}

class MFAFilter extends Component<Props, State> {

  state = {
    mfaStatus: UserConstants.MFA_STATUS_FILTER_OPTIONS,
  };

  handleMFAStatusChange = (mfaStatusVal) => {

    const {handleSelectMFA} = this.props;

    this.setState({
      mfaStatus: mfaStatusVal
    });

    handleSelectMFA(mfaStatusVal);

  };

  render() {
    const {mfaStatus} = this.state;

    return (
      <Select
        id="branch-select"
        name="branch-select"
        value={mfaStatus}
        options={UserConstants.MFA_STATUS_FILTER_OPTIONS}
        classNamePrefix="patient-care"
        onChange={this.handleMFAStatusChange}
      />
    )
  }
}

export default MFAFilter;

