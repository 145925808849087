import React, { Component } from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts';
import { NoResults, Loader } from '@patient-access/ui-kit';
import * as ColorConstants from 'constants/ColorConstants';
import { formatNumber, formatPercentage } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

type Props = {
  appointment: any,
  isResizing: boolean,
  isLoading: boolean,
  isEmptyAppointment: boolean,
};

class ChartBranch extends Component<Props> {
  formatTick = (tickItem) => {
    const MAX_CHARACTERS = 25;
    if (tickItem.length <= MAX_CHARACTERS) return tickItem;
    return `${tickItem.substring(0, MAX_CHARACTERS)}…`;
  }

  formatLabel = (value) => {
    return formatNumber(value);
  }

  formatTooltip = (props) => {
    if (props.active) {
      const { payload } = props;
      return (
        <div className="reports-basic-tooltip">
          <h4>{payload[0] ? payload[0].payload.name : null}</h4>
          <ul>
            {payload.map((item, index) => {
              if (item.value === 0) return null;
              return <li key={index}><span><strong>{item.name}:</strong></span> <span>{formatNumber(item.value)} ({formatPercentage(item.value, item.payload.total, 1)})</span></li>;
            })}
          </ul>
        </div>
      );
    }
    return null;
  }

  render() {
    const { appointment, isResizing, isLoading, isEmptyAppointment } = this.props;
    if (isLoading || isEmptyAppointment || isResizing) return <div className="reports-basic-chart branch"><Loader type="inline" size="small" className="reports-basic-loader" /></div>;
    if (appointment.appointmentDetail.length === 0) return <div className="reports-basic-chart branch"><NoResults type="info"><p>{locale.Reports.appointmentsGraphOrganisationNoResults}</p></NoResults></div>;
    return (
      <div className="reports-basic-chart branch">
        <ResponsiveContainer width="99%" height={appointment.appointmentDetail.length * 56}>
          <BarChart data={appointment.appointmentDetail} layout="vertical" barCategoryGap={7} maxBarSize={40}>
             <YAxis type="category" dataKey="name" width={260} tickLine={false} tickSize={32} axisLine={false} tickFormatter={this.formatTick} />
             <XAxis type="number" hide />
             <Tooltip content={this.formatTooltip} cursor={false} />
             <Bar dataKey="serviceProvided" stackId="a" fill={ColorConstants.COLOR_ACCENT} name={locale.Reports.appointmentsStatus.serviceProvided} />
             <Bar dataKey="statusRequired" stackId="a" fill={ColorConstants.COLOR_STATUS_ERROR} name={locale.Reports.appointmentsStatus.statusRequired} />
             <Bar dataKey="cancelled" stackId="a" fill={ColorConstants.COLOR_GREY_LIGHT} name={locale.Reports.appointmentsStatus.cancelled} />
             <Bar dataKey="missed" stackId="a" fill={ColorConstants.COLOR_SUPPORT_TWO} name={locale.Reports.appointmentsStatus.missed} />
             <Bar dataKey="serviceNotProvided" stackId="a" fill={ColorConstants.COLOR_NOTIFICATIONS_WARNING} name={locale.Reports.appointmentsStatus.serviceNotProvided} />
             <Bar dataKey="upcoming" stackId="a" fill={ColorConstants.COLOR_STATUS_SUCCESS} name={locale.Reports.appointmentsStatus.upcoming}>
              <LabelList dataKey="total" position="right" offset={16} formatter={this.formatLabel} />
             </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default ChartBranch;
