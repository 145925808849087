import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, LinkExternal } from '@patient-access/ui-kit';
import { setUI } from 'actions/organisationServices';
import locale from 'service/locale';
import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';
import { resetUserData, setUserStep, resetUserEditData } from 'actions/organisationServices';
import { setErrorBranchHeaderText, setErrorAllBranchContentText } from '../../helpers/OrganisationService';

type Props = {
  setUI: (value: any) => any,
  userData: any,
  mode: string,
  resetUserData: () => any,
  setUserStep: (value: any) => any,
  resetUserEditData: () => any,
};

const mapStateToProps = state => ({
  mode: state.organisationServices.ui.requestMode,
  userData: state.organisationServices.user.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserData: () => dispatch(resetUserData()),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  resetUserEditData: () => dispatch(resetUserEditData()),
});

class OverlayConfirmationAllError extends Component<Props> {
  render() {
    const { setUI, userData, mode, resetUserData, setUserStep, resetUserEditData } = this.props;
    const branchCount = userData.serviceBranches.length;

    const handleClick = () => {
      setUI({ isOverlayConfirmationVisible: false, isSidebarEditVisible: false, responseFromApi: [] }); 
      setUserStep(1); 
      if(mode === OrganisationServicesConstants.OVERLAY_MODE.EDIT ) {
        resetUserEditData(); 
        setUI({ isSidebarEditVisible: false }); 
      }
      else{
        setUI({ isSidebarAddVisible: false }); 
        resetUserData(); 
      }
    }

    return (
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{setErrorBranchHeaderText(mode, branchCount)}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{setErrorAllBranchContentText(mode)}</h4>
          <h4>
            {locale.OrganisationServices.overlay.confirmation.errorSupportContent1}
            <LinkExternal
              to="https://supportpro.patientaccess.com/contact-support-form"
              message={locale.Header.supportLinkOne}
              baseClassName="patient-care-link"
              target={locale.Share.targetBlank}
            />
            {locale.OrganisationServices.overlay.confirmation.errorSupportContent2}
          </h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="some-key"
            defaultMessage={locale.OrganisationServices.button.goBack}
            onClick={() => { handleClick(); }}
          />
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayConfirmationAllError);
