import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, FormItem, InputError } from "@patient-access/ui-kit";

import BlockAvailabilityItem from 'components/Share/BlockAvailabilityItem/BlockAvailabilityItem';
import { updateForm } from "actions/form";
import type { Action } from "types/actions";
import { checkBlockedPeriods } from "helpers/validateData";

import locale from "service/locale";
import "./styles.scss";

type Props = {
  form: any,
  updateForm: (data: any) => Action
};

type State = {
  blockedPeriods: any[]
};

const mapStateToProps = state => ({
  form: state.form
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: data => dispatch(updateForm(data))
});

class AddNewCalendarBlockAvailability extends Component<Props, State> {
  state = {
    blockedPeriods: []
  };

  handleAddNewPeriod = () => {
    const { updateForm, form } = this.props;
    const { blockedPeriods } = this.state;
    const newId = Date.now();
    const today = moment.utc().toISOString().slice(0, 10);
    const newBlockedPeriod = {
      id: newId,
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      isAllDay: false
    };
    this.setState({
      blockedPeriods: [
        ...blockedPeriods,
        newBlockedPeriod
      ]
    }, () => {
      updateForm({
        blockedPeriods: {
          ...form.blockedPeriods,
          [newId]: {
            startTime: null,
            endTime: null,
            isAllDay: false,
            startDate: today,
            endDate: today
          }
        }
      })
    });
  };

  handleDeletePeriod = (id: string) => {
    const { blockedPeriods } = this.state;
    const { updateForm, form } = this.props;
    const deletedPeriodIndex = blockedPeriods.findIndex(
      period => period.id === id
    );
    if (form.blockedPeriods && form.blockedPeriods[id]) {
      delete form.blockedPeriods[id];
      updateForm({
        ...form
      });
    }
    this.setState({
      blockedPeriods: [
        ...blockedPeriods.slice(0, deletedPeriodIndex),
        ...blockedPeriods.slice(deletedPeriodIndex + 1)
      ]
    });
  };

  render() {
    const { blockedPeriods } = this.state;
    const { form } = this.props;
    const { isNotMultipleByFive, isEqualTime, isEndTimeLessThanStartTime } = checkBlockedPeriods(form.blockedPeriods);
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddNewCalendar.blockedTimes.title}</h3>
          <p>{locale.AddNewCalendar.blockedTimes.description}</p>
        </div>
        {blockedPeriods.length > 0 &&
          blockedPeriods.map(period => {
            return (
              <BlockAvailabilityItem
                key={period.id}
                period={period}
                handleDeletePeriod={this.handleDeletePeriod}
                isEditMode={true}
              />
            );
          })}
        {isNotMultipleByFive ? (
          <Form noValidate>
            <FormItem type="item">
              <InputError
                message={locale.AvailabilitySettings.timePeriodValidationError}
              />
            </FormItem>
          </Form>
        ) : null}
        {!isNotMultipleByFive && isEqualTime ? (
          <Form noValidate>
            <FormItem type="item">
              <InputError
                message={locale.AvailabilitySettings.equalTimeValidationError}
              />
            </FormItem>
          </Form>
        ) : null}
        {!isNotMultipleByFive && !isEqualTime && isEndTimeLessThanStartTime ? (
          <Form noValidate>
            <FormItem type="item">
              <InputError
                message={locale.AvailabilitySettings.startTimeValidationError}
              />
            </FormItem>
          </Form>
        ) : null}
        <div className="patient-care-block">
          <button
            className="patient-care-btn-link"
            onClick={this.handleAddNewPeriod}
          >
            {locale.AddNewCalendar.addBlockedPeriodText}
          </button>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewCalendarBlockAvailability);
