import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button, Notification2 } from '@patient-access/ui-kit';
import { Feature, FeatureMenu, FeatureContent, FeatureContentSticky, FeatureContentScroll, FeatureContentHeader } from 'components/Share/feature';
import { IconAlert } from 'svg';
import { SkeletonTable } from 'components/Share/skeleton';
import OrganisationSidebar from 'components/Pages/Admin/OrganisationContent/OrganisationSidebar/OrganisationSidebar';
import { getServicesSummary, getBranchesSummary, getBranchesList, setUserData, resetUserData, setUI, setUserEditData } from 'actions/organisationServices';
import FilterBranchesSummary from './FilterBranchesSummary/FilterBranchesSummary';
import FilterBranchesSummarySkeleton from './FilterBranchesSummary/FilterBranchesSummarySkeleton';
import OverlayConfirmation from './OverlayConfirmation/OverlayConfirmation';
import OverlayBranches from './OverlayConfirmation/OverlayBranches';
import { columns, TableBranchesSummary } from './TableBranchesSummary/TableBranchesSummary';
import { columnsAffiliates, TableBranchesSummaryAffiliates } from './TableBranchesSummary/TableBranchesSummaryAffiliates';
import SidebarAddBranches from './SidebarAddBranches/SidebarAddBranches';
import SidebarEditService from './SidebarEditService/SidebarEditService';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as SearchConstants from 'constants/SearchConstants';
import locale from 'service/locale';

type Props = {
  organisationId: string,
  serviceId: string,
  searchValue: string,
  dataServicesSummary: any,
  dataBranchesSummary: any,
  isAffiliate: boolean,
  isLoading: boolean,
  isError: boolean,
  userData: any,
  getServicesSummary: (organisationId: string) => any,
  getBranchesList: (organisationId: string, serviceId: string) => any,
  getBranchesSummary: (organisationId: string, serviceId: string) => any,
  setUserData: (data: any) => any,
  setUserEditData: (data: any) => any,
  resetUserData: () => any,
  setUI: (value: any) => any,
};

const mapStateToProps = (state) => ({
  searchValue: state.organisationServices.user.search.branchesSummary,
  dataServicesSummary: state.organisationServices.servicesSummary.data,
  dataBranchesSummary: state.organisationServices.branchesSummary.data,
  isAffiliate: state.organisationDetails.organisationType === OrganisationsConstants.ORGANISATION_TYPE.AFFILIATE,
  isLoading: state.organisationServices.branchesSummary.isLoading,
  isError: state.organisationServices.branchesSummary.isError,
  userData: state.organisationServices.user.data,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getServicesSummary: (organisationId: string) => dispatch(getServicesSummary(organisationId)),
  getBranchesSummary: (organisationId: string, serviceId: string) => dispatch(getBranchesSummary(organisationId, serviceId)),
  getBranchesList: (organisationId: string, serviceId: string) => dispatch(getBranchesList(organisationId, serviceId)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setUserEditData: (data: any) => dispatch(setUserEditData(data)),
  resetUserData: () => dispatch(resetUserData()),
  setUI: (value: any) => dispatch(setUI(value)),
});

class ServiceDetails extends Component<Props> {
  state = {
    isSideBarForAddBranch: false,
    isSideBarForEditService: false,
  };

  componentDidMount = () => {
    const { organisationId, serviceId, dataServicesSummary, getServicesSummary, getBranchesSummary, getBranchesList } = this.props;
    const isServicesSummaryEmpty = dataServicesSummary.length === 0;

    if (isServicesSummaryEmpty) {
      getServicesSummary(organisationId);
      getBranchesSummary(organisationId, serviceId);
    } else {
      getBranchesSummary(organisationId, serviceId);
    }

    getBranchesList(organisationId, serviceId);
  }

  componentDidUpdate = (prevProps) => {
    const { serviceId, dataServicesSummary, dataBranchesSummary, setUserData } = this.props;

    if (dataServicesSummary !== prevProps.dataServicesSummary || dataBranchesSummary !== prevProps.dataBranchesSummary) {
      if (dataServicesSummary.length !== 0 && dataBranchesSummary.length !== 0) {
        const selectedService = dataServicesSummary.find(service => service.serviceId === serviceId);
        setUserData({
          serviceId: selectedService.serviceId,
          serviceName: selectedService.serviceName,
          serviceAvailableModes: selectedService.allowedModes,
          serviceIsNhsCovered: selectedService.nhsCovered,
          serviceBranches:[]
        });
      }
    }
  }

  componentWillUnmount = () => {
    const { resetUserData } = this.props;
    resetUserData();
  }

  addBranches = () => {
    const { setUserData } = this.props;
    setUserData({
      serviceModes: [],
      serviceIsActive: null,
      servicePrice: null,
      serviceDuration: null,
      serviceCutOff: null,
      serviceIsVatIncluded: null,
      serviceIsShowPrice: null,
      serviceBranches: [],
    });
    this.setState({ isSideBarForAddBranch: true });
    this.setState({ isSideBarForEditService: false });
  }

  editAllBranches = () => {
    this.setState({ isSideBarForAddBranch: false });
    this.setState({ isSideBarForEditService: true });
  }

  render() {
    const { organisationId, serviceId, searchValue, dataBranchesSummary, isLoading, isError, isAffiliate } = this.props;
    const { isSideBarForAddBranch, isSideBarForEditService } = this.state;
    const isEmpty = dataBranchesSummary.length === 0;

    let dataFiltered = dataBranchesSummary;
    if (searchValue.length > SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH) {
      dataFiltered = dataBranchesSummary.filter(service => service.branchName.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const renderNotification = () => {
      const noBranches = dataFiltered.filter(service => service.branchName).length;
      const noAlerts = dataFiltered.filter(service => !service.isServiceAdded).length;

      if (noAlerts) {
        if (noAlerts === noBranches) {
          return (
            <div className="row">
              <div className="column column-full">
                <Notification2 type="warning" icon={<IconAlert outline={false} />}>
                  <p>{locale.OrganisationServices.notification.branchesNone}</p>
                </Notification2>
              </div>
            </div>
          );
        }
        return (
          <div className="row">
            <div className="column column-full">
              <Notification2 type="info" icon={<IconAlert outline={false} />}>
                <p>{locale.OrganisationServices.notification.branchesNumber(noAlerts)}</p>
              </Notification2>
            </div>
          </div>
        );
      }
      return null;
    }

    const renderTableBranchesSummary = () => {
      const isDataFilteredEmpty = dataFiltered.length === 0;
      if (isDataFilteredEmpty) {
        return (
          <NoResults type="info">
            <h2>{locale.OrganisationServices.noResults.branchesSummaryFilter}</h2>
          </NoResults>
        );
      }
      if (isAffiliate) {
        return <TableBranchesSummaryAffiliates data={dataFiltered} columns={columnsAffiliates} props={this.props} editAllBranches={this.editAllBranches} />;
      }
      return <TableBranchesSummary data={dataFiltered} columns={columns} props={this.props} editAllBranches={this.editAllBranches} />;
    }

    const renderContent = () => {
      if (isLoading) {
        return (
          <Fragment>
            <FeatureContentSticky className="smallest">
              <FeatureContentHeader className="spacing-medium">
                <FilterBranchesSummarySkeleton organisationId={organisationId} serviceId={serviceId} />
              </FeatureContentHeader>
            </FeatureContentSticky>
            <FeatureContentScroll className="smallest" isGreyBackground={false}>
              <SkeletonTable className="spacing-medium" columns={6} isSticky={true} isGrey={true} />
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isError) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.OrganisationServices.error.generic}</h2>
                <Button to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.SERVICE}/${serviceId}/${RoutesConstants.DETAILS}`} buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.tryAgain} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      if (isEmpty) {
        return (
          <Fragment>
            <FeatureContentScroll className="smallest" isGreyBackground={true}>
              <NoResults type="info">
                <h2>{locale.OrganisationServices.noResults.branchesSummary}</h2>
                <Button buttonType="secondary" messageKey="some-key" defaultMessage={locale.OrganisationServices.button.addToBranches} onClick={() => {}} />
              </NoResults>
            </FeatureContentScroll>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <FeatureContentSticky className="smallest">
            <FeatureContentHeader className="spacing-medium">
              <FilterBranchesSummary organisationId={organisationId} serviceId={serviceId} addBranches={this.addBranches} editAllBranches={this.editAllBranches} />
              {renderNotification()}
            </FeatureContentHeader>
          </FeatureContentSticky>
          <FeatureContentScroll className="smallest" isGreyBackground={false}>
            {renderTableBranchesSummary()}
          </FeatureContentScroll>
        </Fragment>
      );
    }

    return (
      <Feature>
        <FeatureMenu>
          <OrganisationSidebar organisationId={organisationId} />
        </FeatureMenu>
        <FeatureContent>
          {renderContent()}
          {isSideBarForAddBranch ? <SidebarAddBranches organisationId={organisationId} serviceId={serviceId} isAffiliate={isAffiliate}/> : null}
          {isSideBarForEditService ? <SidebarEditService organisationId={organisationId} serviceId={serviceId} isAffiliate={isAffiliate}/> : null}
          <OverlayConfirmation organisationId={organisationId} isAddBranches={isSideBarForAddBranch} />
          <OverlayBranches />
        </FeatureContent>
      </Feature>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
