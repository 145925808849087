import React, { Component } from 'react'
import {
  Overlay,
  Button,
} from "@patient-access/ui-kit";

import locale from 'service/locale';

type Props = {
  handleDiscardChanges: () => any;
  handleStay: () => any;
}

export default class DiscardChangesOverlay extends Component<Props> {
  render() {
    const { handleDiscardChanges, handleStay } = this.props;
    return (
      <Overlay
        isOpen={true}
        showCloseButton={false}
        background="dark"
        type="confirmation"
        dataId="appointment-overlay"
        doClose={() => {}}
      >
        <div className="overlay-confirmation-header">
          <h2>{locale.Modals.discardChangesOverlay.header}</h2>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.Modals.discardChangesOverlay.content}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          <Button
            buttonType="blueline"
            messageKey="stay-btn"
            defaultMessage={locale.Modals.discardChangesOverlay.buttonStay}
            data-id="stay-btn"
            className="patient-care-btn-in-group"
            onClick={handleStay}
          />
          <Button
            buttonType="secondary"
            messageKey="discard-btn"
            defaultMessage={locale.Modals.discardChangesOverlay.buttonDiscard}
            onClick={handleDiscardChanges}
            data-id="discard-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    )
  }
}
