import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import AsyncSelect from "react-select/lib/Async";
import { Icon, IconSearch } from '@patient-access/ui-kit';
import { Link } from "react-router-dom";

import type { Organisation } from "types/organisation";
import type { Action } from "types/actions";
import { getFilteredOrganisationsCH, setOrganisationsCHList } from 'actions/organisations';

import * as RoutesConstants from 'constants/RoutesConstants';

import locale from 'service/locale';
import './styles.scss';

type State = {
  searchValue: string,
  isMenuOpen: boolean,
};

type Props = {
  chOrganisationsList: Organisation[];
  handleSelectOrganisation: (organisation: Organisation) => any;
  getFilteredOrganisations: (searchValue: string, callback: Function) => Organisation[];
  setOrganisationsCHList: (organisationsList: Organisation[], searchValue?: string) => Action,
};

const mapStateToProps = (state: any) => ({
  chOrganisationsList: state.organisations.chOrganisationsList,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getFilteredOrganisations: (searchValue, callback) => dispatch(getFilteredOrganisationsCH(searchValue, callback)),
  setOrganisationsCHList: (organisationsList, searchValue) => dispatch(setOrganisationsCHList(organisationsList, searchValue)),
});

class AddOrganisationSearch extends Component<Props, State> {
  state = {
    searchValue: "",
    isMenuOpen: false,
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { setOrganisationsCHList } = this.props;
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({ searchValue, isMenuOpen: searchValue.length > 2 }, () => {
        if (searchValue && searchValue.length < 2) {
          setOrganisationsCHList([], searchValue);
        }
      });
    }

    if (action.action === "input-blur" || action.action === "menu-close") {
      this.setState({ isMenuOpen: false });
    }
  };

  handleEmptyList = () => {
    const { searchValue } = this.state;

    if (searchValue.length) {
      return locale.AddOrganisation.addOrganisationSearchEmpty
    }

    return null;
  };

  handleLoadOptions = (inputValue: string, callback: Function) => {
    const { getFilteredOrganisations } = this.props;

    if (inputValue.length > 2) {
      return getFilteredOrganisations(inputValue, callback);
    }
  };

  handleFocus = (e: Event) => {
    const { value } = e && e.target;

    if (value.length) {
      this.setState({
        isMenuOpen: false,
        searchValue: "",
      });
    }

    setTimeout(() => {
      const hiddenDiv = document.getElementsByClassName("patient-care__single-value");
      if (hiddenDiv && hiddenDiv[0] && hiddenDiv[0].innerText.length > 0) {
        document.getElementsByClassName("patient-care__single-value")[0].innerText = "";
      }
    }, 100);

}

  handleChange = (value, action) => {
    const { handleSelectOrganisation } = this.props;

    if (action.action === "select-option") {
      handleSelectOrganisation(value);
    }
  };

  render() {
    const { chOrganisationsList, handleSoleTrader } = this.props;
    const { searchValue, isMenuOpen } = this.state;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-block">
            <p>{locale.AddOrganisation.addOrganisationSearchHint}</p>
            <p>or <b><Link onClick={handleSoleTrader} to={RoutesConstants.HOME}>{locale.AddOrganisation.addSoleTraderLink}</Link></b></p>
          </div>
          <div className="patient-care-search-block">
            <AsyncSelect
              autoFocus
              defaultOptions={searchValue.length ? chOrganisationsList : []}
              cacheOptions
              id="new_organisation"
              name="new_organisation"
              loadOptions={this.handleLoadOptions}
              placeholder={locale.AddOrganisation.addOrganisationSearchPlaceholder}
              onChange={this.handleChange}
              onInputChange={this.handleSearchChange}
              classNamePrefix="patient-care"
              className="patient-care-search-select"
              inputValue={searchValue}
              noOptionsMessage={this.handleEmptyList}
              menuIsOpen={isMenuOpen}
              onFocus={this.handleFocus}
            />
            <Icon
              type="inline"
              size="medium"
              icon={
                <IconSearch outline={true} />
              }
            />
          </div>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisationSearch);
