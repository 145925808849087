import { PublicClientApplication } from "@azure/msal-browser";
import * as RoutesConstants from 'constants/RoutesConstants';
import { b2cPolicies } from './policies';

const {
    clientid,
    domain,
} = window.__ENV.azureadb2c || {};

const b2cReadScope = `https://${domain}/api/read`;
export const loginCallbackUri = `${window.location.origin}${RoutesConstants.LOGINCAllBACK}`;
export const postLogoutRedirectUri = `${window.location.origin}${RoutesConstants.LOGOUT}`;
export const forgotPasswordCallbackUri = `${window.location.origin}${RoutesConstants.FORGOTPASSWORDCAllBACK}`;
export const changePasswordCallbackUri = `${window.location.origin}${RoutesConstants.CHANGEPASSWORDCAllBACK}`;

export const LOGIN_REQUEST = {
    LOGIN: {
        scopes: [b2cReadScope],
        prompt: 'login',
    },
    REFRESH: {
        scopes: [b2cReadScope],
    },
};

const cache = {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
};

const system = {
    navigateFrameWait: 500,
};

export const msalApp = new PublicClientApplication({
    auth: {
        clientId: clientid,
        authority: b2cPolicies.authorities.signIn.authority,
        redirectUri: loginCallbackUri,
        validateAuthority: true,
        postLogoutRedirectUri: postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
        knownAuthorities: [
            b2cPolicies.authorities.signIn.authority,
            b2cPolicies.authorities.forgotPassword.authority,
        ],
    },
    cache: cache,
    system: system
});

export const forgotPwdMsalApp = new PublicClientApplication({
    auth: {
        clientId: clientid,
        authority: b2cPolicies.authorities.forgotPassword.authority,
        redirectUri: forgotPasswordCallbackUri,
        validateAuthority: true,
        postLogoutRedirectUri: postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
        knownAuthorities: [
            b2cPolicies.authorities.signIn.authority,
            b2cPolicies.authorities.forgotPassword.authority,
        ],
    },
    cache: cache,
    system: system
});

export const changePasswordSelfMsalApp = new PublicClientApplication({
    auth: {
        clientId: clientid,
        authority: b2cPolicies.authorities.changePasswordSelf.authority,
        redirectUri: changePasswordCallbackUri,
        validateAuthority: true,
        postLogoutRedirectUri: postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
        knownAuthorities: [
            b2cPolicies.authorities.signIn.authority,
            b2cPolicies.authorities.changePasswordSelf.authority,
        ],
    },
    cache: cache,
    system: system
});