import React, { Component } from 'react';
import './styles/card-reports-fees-skeleton.scss';

class CardFeesSkeleton extends Component<Props> {
  render() {
    return (
      <div className="card-reports-fees-skeleton">
        <div className="card-reports-fees-skeleton-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-60" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-40" />
        </div>
        <div className="card-reports-fees-skeleton-row">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-60" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-40" />
        </div>
      </div>
    );
  }
};

export default CardFeesSkeleton;
