import React, { Component } from 'react';
import { connect } from "react-redux";

import { Snackbar } from '@patient-access/ui-kit';
import type { SnackbarState } from 'types/snackbar';

type Props = {
  snackbar: SnackbarState;
}

const mapStateToProps = (state) => ({
  snackbar: state.snackbar
});

class SnackbarWrapper extends Component<Props> {

  render() {
    const {  snackbar } = this.props;
    return (
        <Snackbar type="inline" isOpen={snackbar.isActive} doClose={() => {}}>
            <p>{snackbar.message}</p>
        </Snackbar>
    )
  }
}

export default connect(mapStateToProps)(SnackbarWrapper);