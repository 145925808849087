import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '@patient-access/ui-kit';
import { setUI, resetUserEditData, setUserStep, getBranchesSummary, updateServiceDetails } from 'actions/organisationServices';
import { showSnackbarStatus } from 'actions/snackbar';
import locale from 'service/locale';
import { setEditBranchHeaderText, setEditBranchButtonText } from '../helpers/OrganisationService';

type Props = {
  organisationId: string,
  isLoading: boolean,
  isBulkUpdateCancel: boolean,
  isBulkUpdateComplete: boolean,
  userData: any,
  branchList: any,
  responseFromApi: any,
  setUI: (value: any) => any,
  resetUserEditData: () => any,
  showSnackbarStatus: () => any,
  setUserStep: (value: any) => any,
  getBranchesSummary: (organisationId: string, serviceId: string) => any,
  updateServiceDetails: (organisationId: string) => any,
};

const mapStateToProps = state => ({
  branchList: state.organisationServices.branchesList.data,
  userData: state.organisationServices.user.data,
  responseFromApi: state.organisationServices.ui.responseFromApi,
  isLoading: state.organisationServices.user.isUpdateServiceDetailsPending,
  isBulkUpdateCancel: state.organisationServices.ui.isBulkUpdateCancel,
  isBulkUpdateComplete: state.organisationServices.ui.isBulkUpdateComplete,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUI: (value: any) => dispatch(setUI(value)),
  resetUserEditData: () => dispatch(resetUserEditData()),
  showSnackbarStatus: (status: any) => dispatch(showSnackbarStatus(status)),
  setUserStep: (value: any) => dispatch(setUserStep(value)),
  getBranchesSummary: (organisationId: string, serviceId: string) => dispatch(getBranchesSummary(organisationId, serviceId)),
  updateServiceDetails: (organisationId: string) => dispatch(updateServiceDetails(organisationId)),
});

class OverlayConfirmationEdit extends Component<Props> {

  renderConfirmationButtons = () => {
    const { userData, branchList, isLoading, setUI } = this.props;
    const branchCount = userData.serviceBranches.length;
    const availableBranchesCount = branchList.filter(service => service.isEnabled === false).length;
    return (
      <Fragment>
         <Button
          buttonType="blueline"
          messageKey="goBack"
          defaultMessage={locale.OrganisationServices.button.goBack}
          onClick={() => { setUI({ isOverlayConfirmationVisible: false }); }}
          isLoading={isLoading}
        />
        <Button
          buttonType="secondary"
          messageKey="EditBranch"
          defaultMessage={setEditBranchButtonText(branchCount, availableBranchesCount)}
          onClick={() => { this.handleUpdate() }}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }

  handleUpdate () {
    const { organisationId, updateServiceDetails } = this.props;
    updateServiceDetails(organisationId);
    // this.handleClose();
  }

  handleClose () {
    const { setUI, setUserStep } = this.props;
    setUserStep(1);
    setUI({ isOverlayConfirmationVisible: false, isSidebarEditVisible: false, responseFromApi: [], isBulkUpdateComplete: false });
  }

  render() {
    const { userData, branchList } = this.props;
    const branchCount = userData.serviceBranches.length;
    const availableBranchesCount = branchList.filter(service => service.isEnabled === true).length;

    return (
      <Fragment>
        <div className="overlay-confirmation-header">
          <h1>{setEditBranchHeaderText(branchCount, availableBranchesCount, userData.serviceName)}</h1>
        </div>
        <div className="overlay-confirmation-content">
          <h4>{locale.OrganisationServices.overlay.confirmation.editContent}</h4>
        </div>
        <div className="overlay-confirmation-buttons">
          {this.renderConfirmationButtons()}
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayConfirmationEdit);
