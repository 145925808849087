import type { Action } from "types/actions";
import { getToken } from 'helpers/common';
import agent from 'service/agent';
import { showSnackbarStatus } from "actions/snackbar";
import { setOrganisationHeader } from "actions/router";
import * as EnabledServicesConstants from "constants/EnabledServicesConstants";
import locale from "service/locale"; 

export const setEnabledServiceDetails = (serviceId: string, data: any): Action => ({
  type: EnabledServicesConstants.SET_ENABLED_SERVICE_DATA,
  payload: { serviceId, data }
});

export const setServiceDetails = (serviceDetails: any): Action => ({
  type: EnabledServicesConstants.SET_ENABLED_SERVICE_DETAILS,
  payload: serviceDetails
});

export const setServicesPending = (): Action => ({
  type: EnabledServicesConstants.SET_SERVICES_PENDING,
});

export const updateServicesPending = (): Action => ({
  type: EnabledServicesConstants.UPDATE_SERVICES_PENDING,
});

export const updateServicesSuccess = (): Action => ({
  type: EnabledServicesConstants.UPDATE_SERVICES_SUCCESS,
});

export const updateServicesError = (): Action => ({
  type: EnabledServicesConstants.UPDATE_SERVICES_ERROR,
});

export const getEnabledServiceDetails = (organisationId: string) => (dispatch: Function) => {
  dispatch(setOrganisationHeader(organisationId));
  dispatch(setServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Organisations.getEnabledServiceDetails(organisationId, accessToken)
        .then(enabledServices => {
          dispatch(setServiceDetails({services : enabledServices}));
        })
        .catch(err => {
          console.log('getEnabledServiceDetails server error', err);
        });
    });
};

export const updateEnabledServiceList = (organisationId: string) => (dispatch: Function, getState: Function) => {
   const { enabledServices } = getState();
   const servicesList = enabledServices.services;
  dispatch(updateServicesPending());
  getToken(dispatch)
    .then(accessToken => {
      return agent.Organisations.updateEnabledServicesList(organisationId, servicesList, accessToken);
    })
    .then(() => {
      dispatch(updateServicesSuccess());
      dispatch(getEnabledServiceDetails(organisationId));
      dispatch(showSnackbarStatus(locale.Snackbar.enabledServiceListUpdated));
    })
    .catch(err => {
      dispatch(updateServicesError());
      console.log("updateEnabledServiceList server error", err);
      dispatch(showSnackbarStatus(locale.Snackbar.enabledServiceListNotUpdated));
    });
};