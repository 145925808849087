import React, { Component } from 'react';
import { connect } from "react-redux";
import './styles/reports-main.scss';

type Props = {
  children: any,
};

const mapStateToProps = state => ({
  isDisplayListView: state.branches.isDisplayPendingAppointmentList
});

class ReportsMain extends Component<Props> {
  render() {
    const { children, isDisplayListView } = this.props;
    return (
      <div className={isDisplayListView ? "reports-pending-list-main" : "reports-main"}>
        {children}
      </div>
    );
  }
};

export default connect(mapStateToProps)(ReportsMain);
