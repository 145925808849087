import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import * as RoutesConstants from 'constants/RoutesConstants';

import locale from 'service/locale';
import './styles.scss';

class AdminSidebar extends Component<Props> {
  render() {
    return (
      <aside className="patient-care-sidebar-left">
        <div className="patient-care-sidebar-block">
          <div className="patient-care-breadcrumbs">
            {locale.Sidebars.superAdminPanel}
          </div>
        </div>
        <div className="patient-care-sidebar-block">
          <ul className="patient-care-unstyled-list">
            <li>
              <NavLink to={RoutesConstants.ADMIN_ORGANISATIONS} className="patient-care-sidebar-list-item" activeClassName="active">
                {locale.Sidebars.organisations}
              </NavLink>
            </li>
            <li>
              <NavLink to={`${RoutesConstants.ADMIN_BRANCHES}`} className="patient-care-sidebar-list-item" activeClassName="active">
                {locale.Sidebars.branches}
              </NavLink>
            </li>
            <li>
              <NavLink to={`${RoutesConstants.ADMIN_USERS}`} className="patient-care-sidebar-list-item" activeClassName="active">
                {locale.Sidebars.users}
              </NavLink>
            </li>
             <li>
              <NavLink to={RoutesConstants.ADMIN_DEFAULT_SERVICES} className="patient-care-sidebar-list-item" activeClassName="active">
                {locale.Sidebars.defaultServices}
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    );
  }
}

export default AdminSidebar;
