import React, { Component } from 'react';
import { FormItem, InputError, Input } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import { checkURL } from 'helpers/checkValues';
import locale from 'service/locale';

type Props = {
  isError: boolean,
  isAffiliate: boolean,
  setUserData: any,
  handleValidateBookingUrl: any,
  defaultUrl: string,
  isEdited: boolean,
};

class SectionBookingUrl extends Component<Props> {
  render() {
    const { isError, isAffiliate, setUserData, handleValidateBookingUrl, defaultUrl, isEdited } = this.props;

    const handleBookingUrl = (e: any) => {
      var value = e.target.value;
      if (isEdited || value)
        setUserData({ serviceBookingUrl: value });
      if (e.target.value === '') {
        handleValidateBookingUrl(true, true);
      } else {
        if (checkURL(e.target.value)) {
          handleValidateBookingUrl(true, true);
        } else {
          handleValidateBookingUrl(false, true);
        }
      }
    }

    if (isAffiliate) {
      return (
        <SidebarBodySection>
          <SidebarBodyHeader>
            <h3>{locale.BranchServicesRedesign.section.bookingUrl.header}</h3>
            <p>{locale.BranchServicesRedesign.section.bookingUrl.descriptionOne}</p>
            <p>{locale.BranchServicesRedesign.section.bookingUrl.descriptionTwo}</p>
          </SidebarBodyHeader>
          <SidebarBodyContent>
            <FormItem type="item" error={isError ? 'error' : null}>
              <div className="row">
                <Input
                  type="text"
                  id="some-id"
                  name="some-name"
                  placeholder={locale.BranchServicesRedesign.section.bookingUrl.placeholder}
                  onChange={handleBookingUrl}
                  value={defaultUrl}
                />
              </div>
              {isError ? <InputError message={locale.BranchServicesRedesign.validationError.bookingUrl} /> : null}
            </FormItem>
          </SidebarBodyContent>
        </SidebarBodySection>
      );
    }
    return null;
  }
}

export default SectionBookingUrl;
