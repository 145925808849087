import React, { Component, Fragment } from "react";
import locale from "service/locale";
type Props = {
  refundReason: string
};
class RefundReason extends Component<Props> {
render() {
        const { refundReason } = this.props;
        const reasonForRefund =  refundReason || "";        
        return (
            <Fragment>
            <div className="patient-care-block">                 
                { reasonForRefund.length ?
                (
                    <Fragment>
                        <h3 className="patient-care-title-in-row">
                            {locale.Appointment.refundAppointment.refundReasonLabel}
                        </h3>
                        <br/>
                        <p>{refundReason}</p>
                    </Fragment>
                ):''
                }                
            </div>
            </Fragment>
        );
    }
}
export default RefundReason;