import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button, FormItem } from "@patient-access/ui-kit";

import { closeAddOrganisation } from 'actions/panel';
import { createOrganisation } from 'actions/organisationDetails';
import { validateAddOrganisationModel, validateSoleTraderAddOrganisationModel } from "helpers/validateData";

import locale from 'service/locale';

type Props = {
  form: any,
  handleOpenDiscardDialog: () => any,
  closeAddOrganisation: () => any,
  createOrganisation: (organisation: any) => any,
  isCreationPending: boolean,
};

const mapStateToProps = (state: any) => ({
  form: state.form,
  isCreationPending: state.organisationDetails.isCreationPending,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeAddOrganisation: () => dispatch(closeAddOrganisation()),
  createOrganisation: (organisation: any) => dispatch(createOrganisation(organisation)),
});

class AddOrganisationButtons extends Component<Props> {

  handleCreateOrganisation = () => {
    const { form, createOrganisation } = this.props;
    createOrganisation(form);
  };

  render() {
    const { handleOpenDiscardDialog, form, closeAddOrganisation, isCreationPending, customClassName, isSoleTrader } = this.props;
    return (
      <div className={`patient-care-modal-footer ${customClassName}`}>
        <FormItem type="buttons">
          <div className="patient-care-row-align-right">
            <div className="patient-care-buttons-group">
              <Button
                buttonType="blueline"
                messageKey="cancel-btn"
                defaultMessage={locale.AddOrganisation.addOrganisationButtonCancel}
                onClick={form.companyNumber ? handleOpenDiscardDialog : closeAddOrganisation}
                isLoading={isCreationPending}
                data-id="cancel-btn"
                className="patient-care-btn-in-group"
              />
              <Button
                buttonType="secondary"
                messageKey="create-btn"
                defaultMessage={locale.AddOrganisation.addOrganisationButtonCreate}
                isDisabled={isSoleTrader ? !validateSoleTraderAddOrganisationModel(form) : !validateAddOrganisationModel(form)}
                onClick={this.handleCreateOrganisation}
                isLoading={isCreationPending}
                data-id="create-btn"
                className="patient-care-btn-in-group"
                tabIndex={!form.companyNumber ? -1 : 0}
              />
            </div>
          </div>
        </FormItem>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisationButtons);
