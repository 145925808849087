import React, { Component } from 'react';
import { ReportsLegend, ReportsLegendItem } from 'components/Pages/Reports/wrappers';
import ChartMedication from '../ChartMedication/ChartMedication';
import * as ColorConstants from 'constants/ColorConstants';
import './styles/card-reports-medication-chart.scss';
import locale from 'service/locale';

type Props = {
  isResizing: boolean,
  isSubscriptionModel: boolean
};

class CardMedicationChart extends Component<Props> {
  render() {
    const { isResizing, isSubscriptionModel } = this.props;

    return (
      <div className="card-reports-medication-chart">
        <div className="card-reports-medication-chart-header">
          <h3>{locale.Reports.smartPharmacyMedicationGraphTitle}</h3>
        </div>
        <div className="card-reports-medication-chart-content">
          <ChartMedication isResizing={isResizing} />
        </div>
        <div className="card-reports-medication-chart-footer">
          <ReportsLegend>
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_TWO} label={locale.Reports.smartPharmacyMedicationGraphLegend1} />
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_THREE} label={locale.Reports.smartPharmacyMedicationGraphLegend2} />
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_ONE} label={isSubscriptionModel ? locale.Reports.smartPharmacyMedicationGraphLegend32 : locale.Reports.smartPharmacyMedicationGraphLegend3} />
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_FOUR} label={locale.Reports.smartPharmacyMedicationGraphLegend4} />
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_FIVE} label={locale.Reports.smartPharmacyMedicationGraphLegend5} />
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_SIX} label={locale.Reports.smartPharmacyMedicationGraphLegend6} />
          </ReportsLegend>
        </div>
      </div>
    );
  }
};

export default CardMedicationChart;
