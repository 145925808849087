import * as RolesConstants from "constants/RolesConstants";
import type { Action } from "types/actions";
import type { UserRole, UserState } from "types/user";
import { getAdminHomepage } from "helpers/common";
import { setCurrentAdminUrl } from "actions/profile";
import { getOrganisationDetails } from "./organisationDetails";
import { getFilteredBranches } from "./branches";

import { setUsersCookie, getUsersCookie } from "service/cookie";

export const setProfileRoles = (currentUser: UserState, currentUserRoles: UserRole[]): Action => ({
  type: RolesConstants.SET_PROFILE_ROLES,
  payload: {
    ...currentUser,
    currentUserRoles: formatUserRolesList(currentUserRoles),
  },
});

export const updateCurrentUserRole = (account: Account): Action => ({
  type: RolesConstants.SET_CURRENT_PROFILE_ROLE,
  payload: account,
});

export const setCurrentUserRole = (account: Account, currentUser: Object) => (dispatch: Function) => {
  const adminUrl = getAdminHomepage(account);
  dispatch(updateCurrentUserRole(account));
  dispatch(setCurrentAdminUrl(adminUrl));
  dispatch(getOrganisationDetails(account.organizationId));
  dispatch(getFilteredBranches(1, "", account.organizationId));
  changeUserRoleInCookie(currentUser, account);
};

export const formatUserRolesList = (userRoles: any) => {
  userRoles.roles.forEach((role, index) => {
    role.label = role.displayName;
    role.value = index + 1;
  });
  return userRoles.roles;
};

const changeUserRoleInCookie = (currentUser: any, role: Account) => {
  const users = JSON.parse(getUsersCookie());
  const { email } = currentUser;
  const existingUserIndex = users.findIndex(user => user.user === email);
  if (existingUserIndex !== -1) {
    users[existingUserIndex].role = role;
    setUsersCookie(JSON.stringify(users));
  }
};

export const saveRoleToCookie = (currentUser: any, email: string) => {
  const { accounts } = currentUser;

  let users = null;
  const cookie = getUsersCookie();
  if (cookie.length !== 0) {
    users = JSON.parse(cookie);
  }

  let role = currentUser.accounts[0];
  let showCancellation = true;
  let calendarFilters = [];
  let serviceFilters = [];
  let statusFilters = {};

  if (!users) {
    users = [
      {
        user: email,
        role,
        showCancellation,
        calendarFilters,
        serviceFilters,
        statusFilters
      }
    ];
  } else {
    const existingUserIndex = users.findIndex(user => user.user === email);
    if (existingUserIndex !== -1) {
      role = users[existingUserIndex].role;
      const existingUserRole = accounts.findIndex(
        account =>
          account.role === role.role &&
          account.organizationId === role.organizationId &&
          account.branchId === role.branchId
      );

      if (existingUserRole === -1) {
        role = accounts[0];
        users[existingUserIndex].role = role;
      }
      showCancellation = users[existingUserIndex].showCancellation === undefined || users[existingUserIndex].showCancellation === true;
      calendarFilters = users[existingUserIndex].calendarFilters || calendarFilters;
      serviceFilters = users[existingUserIndex].serviceFilters || serviceFilters;
      statusFilters = users[existingUserIndex].statusFilters || statusFilters;
    } else {
      users.push(
        {
          user: email,
          role,
          showCancellation,
          calendarFilters,
          serviceFilters,
          statusFilters
        }
      );
    }
  }
  currentUser.currentRole = role;
  currentUser.showCancellation = showCancellation;
  currentUser.calendarFilters = calendarFilters;
  currentUser.serviceFilters = serviceFilters;
  currentUser.statusFilters = statusFilters;
  currentUser.adminUrl = getAdminHomepage(currentUser.currentRole);
  currentUser.isActiveAdmin = ![RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(currentUser.currentRole.role);
  setUsersCookie(JSON.stringify(users));
  return currentUser;
};
