import * as BranchesConstants from "constants/BranchesConstants";
import * as AppointmentsConstants from "constants/AppointmentsConstants";
import type {
  Action
} from "types/actions";
import type {
  BranchDetailsState
} from 'types/branch';
import {
  cloneDeep
} from "lodash";

const initialState: BranchDetailsState = {
  confirmationStatus: "unchanged",
  calendarsList: [],
  services: [],
  isCalendarsPending: false,
  isServicesPending: false,
  isCreationPending: false,
  isUpdateServicesPending: false,
  isUpdatingBranchCalendars: false,
  isCreateCalendarPending: false,
  isCreateServicePending: false,
  isUpdateBranchSettingsPending: false,
  getAppointmentsPending: false,
  isUpdatingBranchRooms: false,
  serviceFutureAppointments: {
    serviceId: null,
    listOfAppointments: []
  },
  serviceDetails: {}
};

export default (localState: BranchDetailsState = initialState, action: Action): BranchDetailsState => {
  switch (action.type) {
    case BranchesConstants.SET_BRANCH_DETAILS:
      const {
        payload
      } = action;
      if (payload) {
        payload.services.forEach(service => service["selected"] = true);
        payload.servicesLoadedInitially = cloneDeep(payload.services);
        payload.supportedFeaturesClone = payload.supportedFeatures;
      }
      return {
        ...localState,
        ...payload,
        isServicesPending: false,
      };
    case BranchesConstants.SET_NEW_BRANCH_SERVICE:
      if (action.payload) {
        action.payload.services.forEach(service => service["selected"] = true);
      }

      return {
        ...localState,
        ...action.payload,
          isServicesPending: false,
      };
    case BranchesConstants.DELETE_SERVICE:
      const deletedServiceIndex = localState.services.findIndex(
        service => service.id === action.payload
      );
      return {
        ...localState,
        services: [
          ...localState.services.slice(0, deletedServiceIndex),
          ...localState.services.slice(deletedServiceIndex + 1)
        ]
      };
    case BranchesConstants.SET_SERVICE_DATA:
      const newBranchDetails = {
        ...localState
      };
      const editedServiceIndex = newBranchDetails.services.findIndex(
        service => service.id === action.payload.serviceId
      );
      newBranchDetails.services[editedServiceIndex] = {
        ...newBranchDetails.services[editedServiceIndex],
        ...action.payload.data
      };
      return newBranchDetails;
    case BranchesConstants.SET_BRANCH_CALENDARS:
      return {
        ...localState,
        calendarsList: action.payload,
      };
    case BranchesConstants.SET_BRANCH_CONFIRMATION_STATUS:
      return {
        ...localState,
        confirmationStatus: action.payload
      };
    case BranchesConstants.SET_BRANCH_SERVICES_LIST:
      action.payload.forEach(service => service["selected"] = true);
      return {
        ...localState,
        services: action.payload,
          isServicesPending: false,
      };
    case BranchesConstants.SET_CALENDAR_PENDING:
      return {
        ...localState,
        isCalendarsPending: true,
      };
    case BranchesConstants.SET_CALENDAR_PENDING_SUCCESS:
      return {
        ...localState,
        isCalendarsPending: false,
      };
    case BranchesConstants.SET_CALENDAR_PENDING_ERROR:
      return {
        ...localState,
        isCalendarsPending: false,
      };
    case BranchesConstants.SET_SERVICES_PENDING:
      return {
        ...localState,
        isServicesPending: true,
      };
    case BranchesConstants.CREATE_BRANCH_PENDING:
      return {
        ...localState,
        isCreationPending: true,
      };
    case BranchesConstants.CREATE_BRANCH_SUCCESS:
      return {
        ...localState,
        isCreationPending: false,
      };
    case BranchesConstants.CREATE_BRANCH_ERROR:
      return {
        ...localState,
        isCreationPending: false,
      };
    case BranchesConstants.UPDATE_SERVICES_PENDING:
      return {
        ...localState,
        isUpdateServicesPending: true,
      };
    case BranchesConstants.UPDATE_SERVICES_SUCCESS:
      return {
        ...localState,
        isUpdateServicesPending: false,
      };
    case BranchesConstants.UPDATE_SERVICES_ERROR:
      return {
        ...localState,
        isUpdateServicesPending: false,
      };
    case BranchesConstants.CREATE_CALENDAR_PENDING:
      return {
        ...localState,
        isCreateCalendarPending: true,
      };
    case BranchesConstants.CREATE_CALENDAR_SUCCESS:
      return {
        ...localState,
        isCreateCalendarPending: false,
      };
    case BranchesConstants.CREATE_CALENDAR_ERROR:
      return {
        ...localState,
        isCreateCalendarPending: false,
      };
    case BranchesConstants.UPDATE_BRANCH_SETTINGS_PENDING:
      return {
        ...localState,
        isUpdateBranchSettingsPending: true,
      };
    case BranchesConstants.UPDATE_BRANCH_SETTINGS_SUCCESS:
      return {
        ...localState,
        isUpdateBranchSettingsPending: false,
      };
    case BranchesConstants.UPDATE_BRANCH_SETTINGS_ERROR:
      return {
        ...localState,
        isUpdateBranchSettingsPending: false,
      };
    case AppointmentsConstants.GET_APPOINTMENTS_PENDING:
      return {
        ...localState,
        getAppointmentsPending: true,
      };
    case AppointmentsConstants.GET_APPOINTMENTS_SUCCESS:
      return {
        ...localState,
        getAppointmentsPending: false,
      };
    case AppointmentsConstants.GET_APPOINTMENTS_ERROR:
      return {
        ...localState,
        getAppointmentsPending: false,
      };
    case BranchesConstants.SET_SERVICE_FUTURE_APPOINTMENTS:
      return {
        ...localState,
        serviceFutureAppointments: {
          serviceId: action.payload.serviceId,
          listOfAppointments: action.payload.listOfAppointments
        }
      };
    case BranchesConstants.SET_PPL_SERVICE_DETAILS:
      return {
        ...localState,
        serviceDetails: action.payload,
      };
    case BranchesConstants.CHANGE_BRANCH_PUBLISH_STATUS:
      return {
        ...localState,
        isDraft: action.payload,
      };
    case BranchesConstants.CHANGE_BRANCH_SMART_PHARMACY_ENABLED:
      const newData = [
        ...localState.supportedFeaturesClone
      ];
      const editedDataIndex = newData.findIndex(
        feature => feature.name === action.payload.name
      );
      if (editedDataIndex !== -1) {
        newData[editedDataIndex] = {
          ...newData[editedDataIndex],
          ...action.payload,
        };
      } else {
        newData.push(action.payload);
      }
      return {
        ...localState,
        supportedFeatures: newData
      };
    default:
      return localState;
  }
};
