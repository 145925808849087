import React, { Component } from 'react';

import './styles/skeleton-task.scss';

class SkeletonTask extends Component {
  render() {
    return (
      <div className="skeleton-task">
        <div className="skeleton-task-header">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-70" />
        </div>
        <div className="skeleton-task-content">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-100" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-50" />
        </div>
        <div className="skeleton-task-footer">
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-40" />
          <div className="ui-kit-skeleton-line ui-kit-skeleton-line-30" />
        </div>
      </div>
    );
  }
};

export default SkeletonTask;
