import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormItem, SearchFilter, IconSearch } from "@patient-access/ui-kit";

import { updateFilter } from 'actions/filter';
import type { Action } from 'types/actions';
import type { Filter } from 'types/filter';

import locale from 'service/locale';
import * as SearchConstants from 'constants/SearchConstants';

type Props = {
  filter: Filter,
  updateFilter: () => Action,
  isLowResolution: boolean,
  isOrgUsersList: string, 
  isBranchUsersList: string,
}

type State = {
  searchValue: string,
}

const mapStateToProps = (state: any) => ({
  filter: state.filter,
  isLowResolution: state.panel.isLowResolution,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateFilter: (data: any) => dispatch(updateFilter(data)),
});

class SearchUsersForm extends Component<Props, State> {
  state = {
    searchValue: ""
  };

  handleChangeSearch = (e: Event) => {
    e && e.preventDefault();
    const { updateFilter } = this.props;
    const { value } = e && e.target;
    const searchValue = value.length <= SearchConstants.MINIMUN_SEARCH_VALUE_LENGTH ? "" : value;
    this.setState({ searchValue: value }, () => {
      updateFilter({
        pattern: searchValue,
      });
    });
  };


  handleClearSearch = () => {
    const { updateFilter } = this.props;

    this.setState({ searchValue: "" }, () => {
      updateFilter({pattern: ""});
    });
  }

  render() {
    const { isLowResolution, isOrgUsersList, isBranchUsersList } = this.props;
    const { searchValue } = this.state;

    const setPlaceholder = () => {
      let searchPlaceholder = locale.UsersListRedesign.filter.placeholderSA;
      
      if (isOrgUsersList){
        searchPlaceholder = locale.UsersListRedesign.filter.placeholderOrg;
      } else if (isBranchUsersList) {
        searchPlaceholder = locale.UsersListRedesign.filter.placeholderBranch;
      }

      return searchPlaceholder;
    }

    return (
      <Form
        noValidate
        className="patient-care-usersearch-form"
        onSubmit={(e) => { e.preventDefault(); }}
      >
        <FormItem type="item">
          <SearchFilter
            id="patient-care-usersearch-form"
            name="search-users"
            value={searchValue}
            placeholder={isLowResolution ? locale.UsersListRedesign.filter.placeholderXS : setPlaceholder()}
            autoComplete="off"
            clearHandler={this.handleClearSearch}
            onChange={this.handleChangeSearch}
            icon={<IconSearch outline />}
          />
        </FormItem>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsersForm);
