import React, { Component } from 'react';
import { Button } from '@patient-access/ui-kit';

import locale from 'service/locale';

type Props = {
  onScrollClick: () => any,
  buttonText?: string,
}

export default class ScrollUpToToday extends Component<Props> {
  render() {
    const { onScrollClick, buttonText } = this.props;

    return (
      <div className="patient-care-sidebar-footer">
        <Button
          buttonType="secondary"
          messageKey="scroll-up-btn"
          defaultMessage={buttonText || locale.Share.scrollUpButton}
          data-id="scroll-up-btn"
          className="patient-care-scroll-btn"
          onClick={onScrollClick}
          buttonLength="full"
        />
      </div>
    )
  }
}
