import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Checkbox } from '@patient-access/ui-kit';
import { CalendarFiltersBodyItem } from '../wrappers';
import { ButtonSettings } from '../button';
import { setActiveAdminMenu } from 'actions/profile';
import { filterEventsByServices } from 'actions/calendarView';
import * as RoutesConstants from 'constants/RoutesConstants';

type Props = {
  organisationId: string,
  branchId: string,
  service: Object,
  serviceFilters: any[],
  isAbleToAddAndEdit: boolean,
  setActiveAdminMenu: (status: boolean) => any,
  filterEventsByServices: (calerndarId: string) => Action,
};

type State = {
  isRedirect: boolean,
};

const mapStateToProps = state => ({
  serviceFilters: state.calendarView.serviceFilters,
});

const mapDispatchToProps = dispatch => ({
  setActiveAdminMenu: (status) => dispatch(setActiveAdminMenu(status)),
  filterEventsByServices: calerndarId => dispatch(filterEventsByServices(calerndarId)),
});

class SectionServicesItem extends Component<Props, State> {
  state = {
    isRedirect: false,
  };

  handleToggleService = (e: any) => {
    const { service, filterEventsByServices } = this.props;
    filterEventsByServices(service.id);
  };

  handleEditServiceClick = (e: any) => {
    e && e.preventDefault();
    const { setActiveAdminMenu } = this.props;
    this.setState({ isRedirect: true }, () => { setActiveAdminMenu(true); });
  };

  render() {
    const { organisationId, branchId, service, serviceFilters, isAbleToAddAndEdit } = this.props;
    const { isRedirect } = this.state;
    const isServiceSelected = serviceFilters.findIndex(serviceFilter => serviceFilter === service.id) === -1;

    const renderSettingsButton = () => {
      if (isAbleToAddAndEdit) {
        return <ButtonSettings onClick={this.handleEditServiceClick} />;
      }
      return null;
    }

    if (isRedirect) {
      return <Redirect to={`${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.SERVICES}`} />;
    }
    return (
      <Fragment>
        <CalendarFiltersBodyItem>
          {renderSettingsButton()}
          <Checkbox
            size="small"
            id={service.id}
            name={service.name}
            value={isServiceSelected ? 'true' : 'false'}
            label={service.name}
            onChange={this.handleToggleService}
            checked={isServiceSelected}
          />
        </CalendarFiltersBodyItem>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionServicesItem);
