import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  InputLabel,
  Input,
  InputError
} from "@patient-access/ui-kit";

import { checkEmail } from "helpers/checkValues";
import { updateForm } from "actions/form";

import locale from "service/locale";
import "./styles.scss";

type Props = {
  form: any,
  updateForm: (data: any) => any
};

type State = {
  new_user_firstName: boolean,
  new_user_email: boolean
};

const mapStateToProps = state => ({
  form: state.form
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data))
});

class AddUserForm extends Component<Props, State> {
  state = {
    new_user_firstName: false,
    new_user_email: false
  };

  componentWillUnmount = () => {
    this.setState({
      new_user_firstName: false,
      new_user_email: false
    });
  };

  handleFocus = ({ target: { name} }) => {
    this.setState({ [name]: true });
  };

  handleBlur = ({ target: { name } }) => {
    this.setState({ [name]: false });
  };

  handleAddUserFormChange = ({ target: { name, value } }) => {
    const { updateForm } = this.props;
    updateForm({ [name]: value });
  };

  render() {
    const { form } = this.props;
    const { new_user_firstName, new_user_email } = this.state;
    const isFirstNameCorrect = !!form.new_user_firstName;
    const isEmailCorrect = checkEmail(form.new_user_email);
    const isEditedFirstName = Object.keys(form).includes("new_user_firstName");
    const isEditedEmail = Object.keys(form).includes("new_user_email");

    return (
      <Form noValidate>
        <div className="patient-care-block-row">
          <div className="patient-care-col-6">
            <FormItem
              type="item"
              error={
                !isFirstNameCorrect && !new_user_firstName && isEditedFirstName
                  ? "true"
                  : ""
              }
            >
              <InputLabel
                htmlFor="new_user_firstName"
                message={locale.AddUser.nameLabel}
                size="small"
              />
              <Input
                type="text"
                id="new_user_firstName"
                name="new_user_firstName"
                data-id="new_user_firstName"
                placeholder={locale.AddUser.nameInput}
                onChange={this.handleAddUserFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {!isFirstNameCorrect &&
                !new_user_firstName &&
                isEditedFirstName && (
                  <InputError message={locale.AddUser.nameError} />
                )}
            </FormItem>
          </div>
        </div>
        <div className="patient-care-block-row">
          <div className="patient-care-col-6">
            <FormItem
              type="item"
              error={
                !isEmailCorrect && !new_user_email && isEditedEmail
                  ? "true"
                  : ""
              }
            >
              <InputLabel
                htmlFor="new_user_email"
                message={locale.AddUser.emailLabel}
                size="small"
              />
              <Input
                type="text"
                id="new_user_email"
                name="new_user_email"
                data-id="new_user_email"
                placeholder={locale.AddUser.emailInput}
                onChange={this.handleAddUserFormChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {!isEmailCorrect && !new_user_email && isEditedEmail && (
                <InputError message={locale.AddUser.emailError} />
              )}
            </FormItem>
          </div>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
