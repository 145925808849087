import React, { Component } from 'react';
import { ReportsLegend, ReportsLegendItem } from 'components/Pages/Reports/wrappers';
import ChartPrescriptionRequests from "../ChartPrescriptionRequests/ChartPrescriptionRequests";
import * as ColorConstants from 'constants/ColorConstants';
import './styles/card-reports-prescriptions-chart.scss';
import locale from 'service/locale';

type Props = {
  isResizing: boolean,
};

class CardprescriptionRequestsChart extends Component<Props> {
  render() {
    const { isResizing } = this.props;

    return (
      <div className="card-reports-prescriptions-chart">
        <div className="card-reports-prescriptions-chart-header">
          <h3>{locale.Reports.smartPharmacyPrescriptionRequestGraphTitle}</h3>
        </div>
        <div className="card-reports-prescriptions-chart-content">
          <ChartPrescriptionRequests isResizing={isResizing}/>
        </div>
        <div className="card-reports-prescriptions-chart-footer">
          <ReportsLegend>
            <ReportsLegendItem color={ColorConstants.COLOR_GRAPH_TWO} label={locale.Reports.smartPharmacyPrescriptionRequestGraphLegend1} />
          </ReportsLegend>
        </div>
      </div>
    );
  }
};

export default CardprescriptionRequestsChart;
