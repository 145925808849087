import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Overlay } from '@patient-access/ui-kit';
import OverlayConfirmationAdd from './OverlayConfirmationAdd';
import OverlayConfirmationEdit from './OverlayConfirmationEdit';
import OverlayConfirmationDelete from './OverlayConfirmationDelete';
import OverlayConfirmationAllError from './Error/OverlayConfirmationAllError';
import OverlayConfirmationPartialError from './Error/OverlayConfirmationPartialError';
import * as OrganisationServicesConstants from 'constants/OrganisationServicesConstants';

type Props = {
  isAddBranches: boolean,
  organisationId: string,
  isOverlayVisible: boolean,
  overlayCofirmationMode: string,
};

const mapStateToProps = state => ({
  overlayCofirmationMode: state.organisationServices.ui.overlayCofirmationMode,
  isOverlayVisible: state.organisationServices.ui.isOverlayConfirmationVisible,
});

class OverlayConfirmation extends Component<Props> {
  render() {
    const { organisationId, overlayCofirmationMode, isOverlayVisible, isAddBranches } = this.props;

    const renderContent = () => {
      switch (overlayCofirmationMode) {
        case OrganisationServicesConstants.OVERLAY_MODE.ADD:
          return <OverlayConfirmationAdd organisationId={organisationId} isAddBranches={isAddBranches}/>;
        case OrganisationServicesConstants.OVERLAY_MODE.EDIT:
          return <OverlayConfirmationEdit organisationId={organisationId} />;
        case OrganisationServicesConstants.OVERLAY_MODE.DELETE:
          return <OverlayConfirmationDelete organisationId={organisationId} />;
        case OrganisationServicesConstants.OVERLAY_MODE.ALL_ERROR:
          return <OverlayConfirmationAllError />;
        case OrganisationServicesConstants.OVERLAY_MODE.PARTIAL_ERROR:
         return <OverlayConfirmationPartialError />
        default:
          return null;
      }
    }

    if (isOverlayVisible) {
      return (
        <Overlay background="dark" type="confirmation" isOpen={true} showCloseButton={false} doClose={() => {}}>
          {renderContent()}
        </Overlay>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, undefined)(OverlayConfirmation);
