import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AdminUsersNew from "./AdminUsers_/AdminUsers";
// import AdminContent from "./AdminContent/AdminContent";
import BranchContent from "./BranchContent/BranchContent";
import OrganisationContent from "./OrganisationContent/OrganisationContent";
// import OrganisationsList from "./AdminContent/OrganisationsList/OrganisationsList";
import OrganisationsListSA from "./AdminContent/OrganisationsList_SA/OrganisationsList";
import OrganisationSettings from "./OrganisationContent/OrganisationSettings/OrganisationSettings";
import OrganisationDetails from "./OrganisationContent/OrganisationDetails/OrganisationDetails";
import BranchSettings from "./BranchContent/BranchSettings/BranchSettings";
import BranchServicesNew from "./BranchContent/BranchServices_/BranchServices";

import BranchRooms from "./BranchContent/BranchRooms/BranchRooms";
import BranchCalendarsNew from "./BranchContent/BranchCalendars_/BranchCalendars";
import AvailabilitySettings from "./BranchContent/AvailabilitySettings/AvailabilitySettings";
import PayoutDetails from "./OrganisationContent/PayoutDetails/PayoutDetails";
import BranchesListSA from "./AdminContent/BranchesList_SA/BranchesList";
import BranchesListOA from "./AdminContent/OrgBranchList/BranchesList";
// import CalendarsList from "./AdminContent/CalendarsList/CalendarsList";
import NotFound from "components/Pages/NotFound/NotFound";
import BranchDetails from "./BranchContent/BranchDetails/BranchDetails"
import DefaultServices from "./AdminContent/DefaultServices/DefaultServices";
import EnabledServices from "./OrganisationContent/EnabledServices/EnabledServices";
import AvailabilityRules from "./OrganisationContent/AvailabilityRules/AvailabilityRules";
import SmartPharmacy from "./OrganisationContent/SmartPharmacy/SmartPharmacy";
import SmartPharmacyRegister from "./OrganisationContent/SmartPharmacyRegister/SmartPharmacyRegister";
import OrganisationServices from './OrganisationContent/OrganisationServices/OrganisationServices';
import ServiceDetails from './OrganisationContent/OrganisationServices/ServiceDetails';
import OrganisationCalendars from "./OrganisationContent/OrganisationCalendars/OrganisationCalendars";

import Playground from "./Playground/Playground";

import * as RoutesConstants from 'constants/RoutesConstants';

import "./styles.scss";

class AdminPage extends Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${RoutesConstants.ADMIN_BRANCHES}`}
          component={() => (
            <BranchesListSA />
          )}
        />
        <Route
          exact
          path={RoutesConstants.ADMIN_DEFAULT_SERVICES}
          component={() => (
            <DefaultServices />
          )}
        />
        <Route
          exact
          path={RoutesConstants.ADMIN_ORGANISATIONS}
          component={() => (
            <OrganisationsListSA />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_USERS}`}
          component={() => (
            <AdminUsersNew />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/playground`}
          component={props => process.env.NODE_ENV === "development" ? (
            <Playground organisationId={props.match.params.organisationId} />
          ) : (<NotFound />)}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.SMART_PHARMACY_REGISTER}`}
          component={props => (
            <SmartPharmacyRegister organisationId={props.match.params.organisationId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.BRANCHES}`}
          component={props => (
            <BranchesListOA organisationId={props.match.params.organisationId}/>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.USERS}`}
          component={props => (
            <AdminUsersNew organisationId={props.match.params.organisationId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.DETAILS}`}
          component={props => (
            <OrganisationContent organisationId={props.match.params.organisationId}>
              <OrganisationDetails organisationId={props.match.params.organisationId} />
            </OrganisationContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.SETTINGS}`}
          component={props => (
            <OrganisationContent organisationId={props.match.params.organisationId}>
              <OrganisationSettings organisationId={props.match.params.organisationId} />
            </OrganisationContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.ENABLED_SERVICES}`}
          component={props => (
            <EnabledServices organisationId={props.match.params.organisationId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.SERVICES}`}
          component={props => (
            <OrganisationServices organisationId={props.match.params.organisationId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.SERVICE}/:serviceId/${RoutesConstants.DETAILS}`}
          component={props => (
            <ServiceDetails organisationId={props.match.params.organisationId} serviceId={props.match.params.serviceId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.PAYOUT}`}
          component={props => (
            <OrganisationContent organisationId={props.match.params.organisationId}>
              <PayoutDetails organisationId={props.match.params.organisationId} />
            </OrganisationContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.CALENDARS}`}
          component={props => (
            <OrganisationCalendars organisationId={props.match.params.organisationId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.AVAILABILITY_RULES}`}
          component={props => (
            <OrganisationContent organisationId={props.match.params.organisationId}>
              <AvailabilityRules organisationId={props.match.params.organisationId} />
            </OrganisationContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/${RoutesConstants.SMART_PHARMACY}`}
          component={(props) => (
            <SmartPharmacy organisationId={props.match.params.organisationId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.USERS}`}
          component={(props) => (
            <AdminUsersNew organisationId={props.match.params.organisationId} branchId={props.match.params.branchId}/>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.AVAILABILITY}`}
          component={(props) => (
            <BranchContent organisationId={props.match.params.organisationId} branchId={props.match.params.branchId}>
              <AvailabilitySettings organisationId={props.match.params.organisationId} branchId={props.match.params.branchId} />
            </BranchContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.SERVICES}`}
          component={(props) => (
            <BranchServicesNew organisationId={props.match.params.organisationId} branchId={props.match.params.branchId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.CALENDARS}`}
          component={(props) => (
            <BranchCalendarsNew organisationId={props.match.params.organisationId} branchId={props.match.params.branchId} />
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.ROOMS}`}
          component={(props) => (
            <BranchContent organisationId={props.match.params.organisationId} branchId={props.match.params.branchId}>
              <BranchRooms organisationId={props.match.params.organisationId} branchId={props.match.params.branchId} />
            </BranchContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.DETAILS}`}
          component={(props) => (
            <BranchContent organisationId={props.match.params.organisationId} branchId={props.match.params.branchId}>
              <BranchDetails organisationId={props.match.params.organisationId} branchId={props.match.params.branchId} />
            </BranchContent>
          )}
        />
        <Route
          exact
          path={`${RoutesConstants.ADMIN_ORGANISATIONS}/:organisationId/:branchId/${RoutesConstants.SETTINGS}`}
          component={(props) => (
            <BranchContent organisationId={props.match.params.organisationId} branchId={props.match.params.branchId}>
              <BranchSettings organisationId={props.match.params.organisationId} branchId={props.match.params.branchId} />
            </BranchContent>
          )}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default AdminPage;
