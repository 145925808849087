import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Icon, IconSearch } from '@patient-access/ui-kit';
import AsyncSelect from "react-select/lib/Async";

import { getFilteredBranchesPPL, setPPLBranchesList } from 'actions/branches';
import type { Action } from "types/actions";
import type { Branch } from "types/organisation";

import locale from 'service/locale';

type State = {
  searchValue: string,
  isMenuOpen: boolean,
};

type Props = {
  PPLBranchesList: Branch[],
  handleSelectBranch: (branch: Branch) => Action,
  getFilteredOrganisations: (searchValue: string, callback: Function) => Action,
  setPPLBranchesList: (branchesList: Branch[], searchValue?: string) => Action
};

const mapStateToProps = (state: any) => ({
  PPLBranchesList: state.branches.PPLBranchesList,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getFilteredBranches: (searchValue: string, callback: Function) => dispatch(getFilteredBranchesPPL(searchValue, callback)),
  setPPLBranchesList: (branchesList, searchValue?) => dispatch(setPPLBranchesList(branchesList, searchValue)),
});

class AddBranchSearch extends Component<Props, State> {

  state = {
    searchValue: "",
    isMenuOpen: false,
  };

  handleSearchChange = (searchValue: string, action: any) => {
    const { setPPLBranchesList } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({ searchValue, isMenuOpen: searchValue.length > 2 }, () => {
        if (searchValue && searchValue.length < 2) {
          setPPLBranchesList([], searchValue);
        }
      });
    }

    if (action.action === "input-blur" || action.action === "menu-close") {
      this.setState({ isMenuOpen: false });
    }
  };

  handleEmptyList = () => {
    const { searchValue } = this.state;

    if (searchValue.length) {
      return locale.AddBranch.searchEmpty;
    }

    return null;
  };

  handleLoadOptions = (inputValue: string, callback: Function) => {
    const { getFilteredBranches } = this.props;

    if (inputValue.length > 2) {
      return getFilteredBranches(inputValue, callback);
    }
  };

  handleFocus = (e: Event) => {
    const { value } = e && e.target;

    if (value.length > 2) {
      this.setState({ isMenuOpen: true });
    }
  };

  handleChange = (value, action) => {
    const { handleSelectBranch } = this.props;

    if (action.action === "select-option") {
      handleSelectBranch(value);
    }
  };

  render() {
    const { PPLBranchesList } = this.props;
    const { searchValue, isMenuOpen } = this.state;

    return (
      <Fragment>
        <div className="patient-care-search-block">
          <AsyncSelect
            defaultOptions={searchValue.length ? PPLBranchesList : []}
            cacheOptions
            id="new_branch"
            name="new_branch"
            loadOptions={this.handleLoadOptions}
            placeholder={locale.AddBranch.searchPlaceholder}
            onChange={this.handleChange}
            onInputChange={this.handleSearchChange}
            classNamePrefix="patient-care"
            className="patient-care-search-select"
            inputValue={searchValue}
            noOptionsMessage={this.handleEmptyList}
            menuIsOpen={isMenuOpen}
            onFocus={this.handleFocus}
          />
          <Icon
            type="inline"
            size="medium"
            icon={
              <IconSearch outline={true} />
            }
          />
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBranchSearch);
