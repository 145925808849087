import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import { Skeleton } from '@patient-access/ui-kit';
import { formatCurrency, formatNumber } from 'helpers/formatData';
import locale from 'service/locale';
import './styles.scss';

import ChartRevenue from '../ChartRevenue/ChartRevenue';
import ChartStatus from '../ChartStatus/ChartStatus';
import ChartBranch from '../ChartBranch/ChartBranch';
import LegendRevenue from '../LegendRevenue/LegendRevenue';
import LegendAppointments from '../LegendAppointments/LegendAppointments';

type Props = {
  revenue: any,
  appointment: any,
  selectedBranch: any,
  selectedService: any,
  selectedStartDate: any,
  selectedEndDate: any,
  isResizing: boolean,
  isLoading: boolean,
  isEmptyRevenue: boolean,
  isEmptyAppointment: boolean,
};

const mapStateToProps = (state) => ({
  selectedBranch: state.reportsBasic.selectedBranch,
  selectedService: state.reportsBasic.selectedService,
  selectedStartDate: state.reportsBasic.filters.startDate,
  selectedEndDate: state.reportsBasic.filters.endDate,
});

class ReportsContent extends Component<Props> {
  renderHeadlineBranch = () => {
    const { selectedBranch } = this.props;
    if (selectedBranch.name) return selectedBranch.name;
    return locale.Reports.downloadOverlay.branchAll;
  }

  renderHeadlineDate = () => {
    const { selectedStartDate, selectedEndDate } = this.props;
    return `${moment(selectedStartDate).format('DD/MM/YYYY')} - ${moment(selectedEndDate).format('DD/MM/YYYY')}`;
  }

  renderHeadlineService = () => {
    const { selectedService } = this.props;
    if (selectedService.name) return selectedService.name;
    return locale.Reports.downloadOverlay.serviceAll;
  }

  renderRevenueClassName = (value) => {
    if (value >= 0) return 'reports-basic-positive';
    return 'reports-basic-negative';
  }

  render() {
    const { revenue, appointment, isResizing, isLoading, isEmptyRevenue, isEmptyAppointment } = this.props;
    return (
      <div className="reports-basic-content">
        <div className="reports-basic-card">
          <div className="reports-basic-headline">
            <h2>{locale.Reports.revenueTitle} {isLoading ? locale.Reports.loading : <Fragment>{this.renderHeadlineBranch()} {this.renderHeadlineDate()} {this.renderHeadlineService()}</Fragment>}</h2>
          </div>
          <div className="reports-basic-row">
            <div className="reports-basic-column">
              <div className={`reports-basic-box ${(isLoading || isEmptyRevenue) ? '' : this.renderRevenueClassName(revenue.detail.total)}`}>
                <div className="reports-basic-item">
                  <h3><strong>{locale.Reports.revenueTotalEstimated}</strong></h3>
                  {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton large" /> : <p><strong>{formatCurrency(revenue.detail.total)}</strong></p>}
                </div>
              </div>
              <div className="reports-basic-box">
                <div className="reports-basic-item">
                  <h3>{locale.Reports.revenueNetOnline}</h3>
                  {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton medium" /> : <p>{formatCurrency(revenue.detail.online)}</p>}
                </div>
                <div className="reports-basic-item reports-basic-item-secondary">
                  <h3>{locale.Reports.revenueTotalFees}</h3>
                  {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton medium" /> : <p>{formatCurrency(revenue.detail.totalFees)}</p>}
                  <ul>
                    <li>
                      <h3>{locale.Reports.revenueServiceCommision}</h3>
                      {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton small" /> : <p>{formatCurrency(revenue.detail.commission)}</p>}
                    </li>
                    <li>
                      <h3>{locale.Reports.revenueStripeFees}</h3>
                      {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton small" /> : <p>{formatCurrency(revenue.detail.stripeFees)}</p>}
                    </li>
                  </ul>
                </div>
                <div className="reports-basic-item reports-basic-item-secondary">
                  <h3>{locale.Reports.revenueOnlineGross}</h3>
                  {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton medium" /> : <p>{formatCurrency(revenue.detail.gross)}</p>}
                </div>
              </div>
              <div className="reports-basic-box">
                <div className="reports-basic-item">
                  <h3>{locale.Reports.revenueInStoreEstimated}</h3>
                  {(isLoading || isEmptyRevenue) ? <Skeleton type="basic" className="reports-basic-skeleton medium" /> : <p>{formatCurrency(revenue.detail.inStore)}</p>}
                </div>
              </div>
            </div>
            <div className="reports-basic-column">
              <div className="reports-basic-box reports-basic-box-graph">
                <div className="reports-basic-title">
                  <h3>{isLoading ? locale.Reports.loading : locale.Reports.revenueGraphHeader}</h3>
                </div>
                <ChartRevenue revenue={revenue} isResizing={isResizing} isLoading={isLoading} isEmptyRevenue={isEmptyRevenue} />
                <LegendRevenue isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
        <div className="reports-basic-card">
          <div className="reports-basic-headline">
            <h2>{locale.Reports.appointmentsTitle} {isLoading ? locale.Reports.loading : <Fragment>{this.renderHeadlineBranch()} {this.renderHeadlineDate()} {this.renderHeadlineService()}</Fragment>}</h2>
          </div>
          <div className="reports-basic-row">
            <div className="reports-basic-column">
              <div className="reports-basic-box">
                <div className="reports-basic-item">
                  <h3><strong>{locale.Reports.appointmentsTotal}</strong></h3>
                  {(isLoading || isEmptyAppointment) ? <Skeleton type="basic" className="reports-basic-skeleton large" /> : <p><strong>{formatNumber(appointment.total)}</strong></p>}
                </div>
              </div>
              <div className="reports-basic-box reports-basic-box-graph">
                <div className="reports-basic-title">
                  <h3>{isLoading ? locale.Reports.loading : locale.Reports.appointmentsGraphStatusHeader}</h3>
                </div>
                <ChartStatus appointment={appointment} isResizing={isResizing} isLoading={isLoading} isEmptyAppointment={isEmptyAppointment} />
                <MediaQuery maxWidth={1099}>
                  <LegendAppointments isLoading={isLoading} />
                </MediaQuery>
              </div>
            </div>
            <div className="reports-basic-column">
              <div className="reports-basic-box reports-basic-box-graph">
                <div className="reports-basic-title">
                  <h3>{isLoading ? locale.Reports.loading : locale.Reports.appointmentsGraphOrganisationHeader}</h3>
                </div>
                <ChartBranch appointment={appointment} isResizing={isResizing} isLoading={isLoading} isEmptyAppointment={isEmptyAppointment} />
                <MediaQuery maxWidth={1099}>
                  <LegendAppointments isLoading={isLoading} />
                </MediaQuery>
              </div>
            </div>
          </div>
          <MediaQuery minWidth={1100}>
            <LegendAppointments isLoading={isLoading} />
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, undefined)(ReportsContent);
