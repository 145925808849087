import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconSortDown, IconSortUp } from '@patient-access/ui-kit';
import './styles/card-reports-nominations.scss';
import locale from 'service/locale';

const mapStateToProps = (state) => ({
  summary: state.reportsSmartPharmacy.metrics.summary.data,
});

class CardNominations extends Component<Props> {
  render() {
    const { summary } = this.props;

    const nominations = summary.nominations.toLocaleString();
    let didNominationIncrease = true;
    let nominationIncrease = summary.nominations - summary.previousNominations;
    if (nominationIncrease < 0) {
      nominationIncrease = Math.abs(nominationIncrease);
      didNominationIncrease = false;
    }

    const renderNominationIncrease = () => {
      if (nominationIncrease === 0) return <p>No change vs previous period</p>;
      return (
        <p>
          {didNominationIncrease ? <i><IconSortUp outline /></i> : <i><IconSortDown outline /></i>} 
          <span>{nominationIncrease.toLocaleString()} {locale.Reports.smartPharmacyNominationIncrease}</span>
        </p>
      )
    }

    return (
      <div className="card-reports-nominations card-reports-nominations-active">
        <h3>{locale.Reports.smartPharmacyNominations}</h3>
        <h4>{nominations}</h4>
        {renderNominationIncrease()}
      </div>
    );
  }
};

export default connect(mapStateToProps)(CardNominations);
