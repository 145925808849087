import React, { Component } from "react";
import { Loader } from "@patient-access/ui-kit";

import { signIn } from '../aadAuthHelper';
import '../../styles.scss';

class AADLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    }
  }

  async componentDidMount() {
    try {
      sessionStorage.clear();
      await signIn();
    }
    catch (e) {
      this.setState({ error: 'Please signin again' })
    }
  }

  render() {
    return (
      <div className="patient-care-aadlogin-loader">
        <Loader size="small" type="inline" />
      </div>
    );
  }
}

export default AADLogin;
