import React, { Component } from 'react';
import { LinkIcon, IconCloseCircle } from '@patient-access/ui-kit';

import './styles/overlay-description.scss';

type Props = {
  children: any,
  isOpen: boolean,
  doClose: () => any,
};

type State = {
  isScrollTop: boolean,
  isScrollBottom: boolean,
};

class OverlayDescription extends Component<Props, State> {
  state = {
    isScrollTop: true,
    isScrollBottom: false,
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    const overlayDescriptionDiv = document.querySelector('.overlay-description-content');
    this.setState({
      isScrollTop: overlayDescriptionDiv.scrollTop === 0,
      isScrollBottom: overlayDescriptionDiv.scrollTop === overlayDescriptionDiv.scrollHeight - overlayDescriptionDiv.clientHeight,
    });
  }

  render() {
    const { isScrollTop, isScrollBottom } = this.state;
    const { isOpen, doClose, children } = this.props;

    return (
      <div className={`ui-kit-overlay${isOpen ? ' ui-kit-overlay-visible': ''}`}>
        <div className="ui-kit-overlay-container">
          <div className="ui-kit-overlay-background ui-kit-overlay-dark" onClick={doClose}></div>
          <div className="ui-kit-overlay-wrapper ui-kit-overlay-description">
          <div className="ui-kit-overlay-close">
            <LinkIcon to="#close" size="larger" icon={<IconCloseCircle outline={false} />} accessibilitySpan="Close" onClick={(e) => { e.preventDefault(); doClose(); }} />
          </div>
            <div className={`ui-kit-overlay-content${!isScrollTop ? ' header-shadow' : ''}${!isScrollBottom ? ' footer-shadow' : ''}`} onScroll={this.scrollHandler}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default OverlayDescription;
