import React, { Component, Fragment } from "react";
import {
  Form,
  FormItem,
  InputLabel,
  Textarea,
  Overlay,
  Button
} from "@patient-access/ui-kit";
import Select from "react-select";
import locale from "service/locale";
import * as AppointmentDetailsConstants from 'constants/AppointmentDetailsConstants';

type Props = {
  handleCancelAppointment: (reason: string, behalfOfPatient: boolean) => any,
  handleDoNotCancelAppointment: () => any,
  isOnline: boolean,
  price?: number,
  isPaidUpfront?: boolean,
  paymentStatus: number
};

type State = {
  cancellationReason: string,
  cancellationTypeOptions: any[],
  cancellationTypeOptionSelected: Object,
}

export default class CancelOverlay extends Component<Props, State> {
  state = {
    cancellationReason: "",
    cancellationTypeOptions: AppointmentDetailsConstants.cancellationTypeOptions,
    cancellationTypeOptionSelected: { value: null }
  };

  handleCancellationReasonChange = (e?: Event) => {
    e && e.preventDefault();
    this.setState({ cancellationReason: e.target.value });
  };

  handleCancelAppointment = (cancellationReason: string, behalfOfPatient: boolean) => {
    const { handleCancelAppointment } = this.props;
    handleCancelAppointment(cancellationReason, behalfOfPatient);
  };

  handleSelectCancellationType = (cancellationTypeOption?: Event) => {
    this.setState({
      cancellationTypeOptionSelected: cancellationTypeOption
    })
  };

  render() {
    const { handleDoNotCancelAppointment, isOnline, price, isPaidUpfront, paymentStatus } = this.props;
    const { cancellationReason, cancellationTypeOptions, cancellationTypeOptionSelected } = this.state;
    const isFreeAppointment = price === 0;
    const modalLocale = isOnline ? locale.Modals.cancelOnlineOverlay: locale.Modals.cancelOverlay;
    const behalfOfPatient = AppointmentDetailsConstants.CANCELLATION_TYPE_CUSTOMER === cancellationTypeOptionSelected.value;
    const behalfOfClinician = AppointmentDetailsConstants.CANCELLATION_TYPE_CLINICIAN === cancellationTypeOptionSelected.value;
    const isCancelDisabled = !cancellationTypeOptionSelected.value || (!cancellationReason.length && !behalfOfPatient);

    return (
      <Overlay
        isOpen={true}
        showCloseButton={true}
        background="dark"
        type="appointment"
        dataId="appointment-overlay"
        doClose={handleDoNotCancelAppointment}
      >
        <div className="overlay-appointment-header">
          <h3>{modalLocale.header}</h3>
        </div>
        <div className="overlay-appointment-content">
          <h4>{modalLocale.whoIsCancelling}</h4>

          <div className="patient-care-block">
            <Form>
              <FormItem type="item">
                <InputLabel
                  htmlFor="cancelled_by"
                  message={locale.Modals.cancelOverlay.whoIsCancellingLabel}
                  size="small"
                />
              </FormItem>
            </Form>

            <Select
              onChange={this.handleSelectCancellationType}
              options={cancellationTypeOptions}
              classNamePrefix="patient-care"
              className="patient-care-select"
              isDisabled={false}
              placeholder={locale.Modals.cancelOverlay.whoIsCancellingPlaceholder}
            />
          </div>

          {
            behalfOfClinician
            ? (
              <Fragment>
                {
                  (paymentStatus === locale.Appointment.paymentStatus.notPaid.value) ? (
                    <h4>
                      {
                        isFreeAppointment
                          ? modalLocale.freeAppointmentWarningMessage
                          : isPaidUpfront
                          ? modalLocale.paidUpfrontClinitianMessage
                          : modalLocale.notPaidClinitianMessage
                        }
                      </h4>
                  ) : (
                    <Fragment>
                      <h4>{locale.Modals.cancelOnlineOverlay.paidfrontClinitianMessage[0]}</h4>
                      <h4>{locale.Modals.cancelOnlineOverlay.paidfrontClinitianMessage[1]}</h4>
                    </Fragment>
                  )
                }
                <Form>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="appointment_reason"
                      message={locale.Modals.cancelOverlay.textareaLabel}
                      size="small"
                    />
                    <Textarea
                        id="appointment_reason"
                        name="appointment_reason"
                        placeholder={isOnline ? locale.Modals.cancelOnlineOverlay.textareaLabel : locale.Modals.cancelOverlay.textareaPlaceholder}
                        rows={5}
                        maxLength={150}
                        countMax={150}
                        onChange={this.handleCancellationReasonChange}
                        value={cancellationReason}
                    />
                  </FormItem>
                </Form>
              </Fragment>
            )
            : null
          }

          {
            behalfOfPatient
            ? (
              (paymentStatus === locale.Appointment.paymentStatus.notPaid.value) ? (
                <h4>
                  {
                    isFreeAppointment
                      ? modalLocale.freeAppointmentWarningMessage
                      : isPaidUpfront
                      ? modalLocale.paidUpfrontPatientMessage
                      : modalLocale.notPaidPatientMessage
                  }
                </h4>
              ) : (
                <Fragment>
                  <h4>{locale.Modals.cancelOnlineOverlay.paidfrontPatientMessage[0]}</h4>
                  <h4>{locale.Modals.cancelOnlineOverlay.paidfrontPatientMessage[1]}</h4>
                </Fragment>
              )

            )
            : null
          }

        </div>
        <div className="overlay-appointment-buttons">
          <Button
            buttonType="blueline"
            messageKey="discard-btn"
            defaultMessage={modalLocale.buttonCancel}
            onClick={handleDoNotCancelAppointment}
            data-id="discard-btn"
            className="patient-care-btn-in-group"
          />
          <Button
            buttonType="secondary"
            messageKey="save-btn"
            defaultMessage={modalLocale.buttonOk}
            isDisabled={isCancelDisabled}
            onClick={ (e) => { (e && e.preventDefault()); this.handleCancelAppointment(cancellationReason, behalfOfPatient) } }
            data-id="save-btn"
            className="patient-care-btn-in-group"
          />
        </div>
      </Overlay>
    );
  }
}
