import React, { Component, Fragment } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Notification } from "@patient-access/ui-kit";

type Props = {
  recaptcha: any,
  recaptchaError: string,
  onChange: any,
};

type State = {
  isError: boolean
};

class Recaptcha extends Component<Props, State> {
  state = {
    isError: false,
  }

  onExpired = () => {
    const { recaptcha } = this.props;
    recaptcha.current.reset();
  }

  onErrored = () => {
    this.setState({ isError: true });
  }

  render() {
    const { recaptcha, recaptchaError, onChange } = this.props;
    const { isError } = this.state;
    const recaptchaSiteKey = window.__ENV.recaptchaSiteKey;
    return (
      <Fragment>
        <ReCAPTCHA
          ref={recaptcha}
          size="invisible"
          sitekey={recaptchaSiteKey}
          onChange={onChange}
          onExpired={this.onExpired}
          onErrored={this.onErrored}
        />
        {isError ? <Notification type="warning" messageKey={recaptchaError} defaultMessage={recaptchaError} /> : null}
      </Fragment>
    );
  }
}

export default Recaptcha;
