import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Skeleton } from "@patient-access/ui-kit";
import NavigationPrompt from "react-router-navigation-prompt";

import PayoutMethod from "./PayoutMethod/PayoutMethod";
import BankAccountDetails from "./BankAccountDetails/BankAccountDetails";
import BankAccountVerificationStatus from "./BankAccountVerificationStatus/BankAccountVerificationStatus";
import StripeServiceAgreement from "./StripeServiceAgreement/StripeServiceAgreement";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";

import {
  getOrganisationDetails,
  setOrganisationDetailsInitialState,
  updateOrganisationPayoutSettings,
  updateOrganisationSettings
} from "actions/organisationDetails"
import { clearForm } from "actions/form";
import { openModalOverlay, closeModalOverlay } from "actions/panel";
import type { Organisation } from "types/organisation";
import * as UserConstants from "constants/RolesConstants";
import type { Action } from "types/actions";

import locale from "service/locale";

type Props = {
  organisationId: string,
  isActiveAgenda: boolean,
  clearForm: () => any,
  form: any,
  isModalOverlayOpened: boolean,
  openModalOverlay: () => any,
  closeModalOverlay: () => any,
  getOrganisationDetails: (organisationId: string) => Organisation,
  setOrganisationDetailsInitialState: () => any,
  organisationDetails: Organisation,
  currentRole: any,
  isUpdatingPayoutSettingsPending: boolean,
  isOrganisationsPending: boolean,
  updateOrganisationPayoutSettings: (organisationId: string, settings: any) => Action,
  updateOrganisationSettings:(organisationId: string, settings:any) => Action
};

type State = {
  isDataChanged: boolean,
  isPayoutMethodEdited: boolean,
}

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  isActiveAgenda: state.panel.isActiveAgenda,
  form: state.form,
  isModalOverlayOpened: state.panel.isModalOverlayOpened,
  organisationDetails: state.organisationDetails,
  isUpdatingPayoutSettingsPending: state.organisationDetails.isUpdatingPayoutSettingsPending,
  isOrganisationsPending: state.organisations.isOrganisationsPending,
  isUpdatingOrganisationSettingsPending: state.organisationDetails.isUpdatingOrganisationSettingsPending
});

const mapDispatchToProps = (dispatch: Function): any => ({
  clearForm: () => dispatch(clearForm()),
  openModalOverlay: () => dispatch(openModalOverlay()),
  closeModalOverlay: () => dispatch(closeModalOverlay()),
  getOrganisationDetails: (organisationId: string) => dispatch(getOrganisationDetails(organisationId)),
  setOrganisationDetailsInitialState: () => dispatch(setOrganisationDetailsInitialState()),
  updateOrganisationPayoutSettings: (organisationId: string, settings: any) => dispatch(updateOrganisationPayoutSettings(organisationId, settings)),
  updateOrganisationSettings: (organisationId: string, setting:any) => dispatch(updateOrganisationSettings(organisationId, setting))
});

class PayoutDetails extends Component<Props,State> {

  state = {
    isDataChanged: false,
    isPayoutMethodEdited: false,
  };

  componentDidMount = (): void => {
    const { organisationId, getOrganisationDetails } = this.props;
    getOrganisationDetails(organisationId);
  };

  componentWillUnmount = (): void => {
    const { setOrganisationDetailsInitialState, clearForm } = this.props;
    setOrganisationDetailsInitialState();
    clearForm();
  };

  componentWillReceiveProps = (nextProps: Props): void => {
    const { isUpdatingPayoutSettingsPending, isUpdatingOrganisationSettingsPending } = this.props;

    if ((isUpdatingPayoutSettingsPending && !nextProps.isUpdatingPayoutSettingsPending) || (isUpdatingOrganisationSettingsPending && !nextProps.isUpdatingOrganisationSettingsPending)) {
      this.setState({
        isDataChanged: false,
      });
    }
  };

  handleUpdatePayoutDetails = (): void => {
    const { organisationId, form, updateOrganisationPayoutSettings, updateOrganisationSettings } = this.props;
    const keys = Object.keys(form);
    keys.includes('paymentMethod') && updateOrganisationPayoutSettings(organisationId, form);
    keys.includes('tosAcceptance') && updateOrganisationSettings(organisationId, form);
  };

  handlePayoutMethodEdit = (status: boolean): void => {
    this.setState({
      isDataChanged: true,
      isPayoutMethodEdited: status
    });
  };

  handleAcceptanceEdit = (): void => {
    this.setState({
      isDataChanged: true,
    });
  };

  handleOpenDiscardDialog = (): void => {
    const { openModalOverlay } = this.props;
    openModalOverlay();
  };

  handleDiscardChanges = (): void => {
    const { clearForm, closeModalOverlay, organisationId, getOrganisationDetails } = this.props;

    this.setState({
      isDataChanged: false,
    }, () => {
      clearForm();
      closeModalOverlay();
      getOrganisationDetails(organisationId);
    });
  };

  handleStay = (): void => {
    const { closeModalOverlay } = this.props;
    closeModalOverlay();
  };

  render() {
    const {
      isActiveAgenda,
      isModalOverlayOpened,
      organisationDetails,
      currentRole,
      isUpdatingPayoutSettingsPending,
      isUpdatingOrganisationSettingsPending,
      isOrganisationsPending
    } = this.props;
    const { isDataChanged, isPayoutMethodEdited } = this.state;
    const { account } = organisationDetails;
    const isSuperAdmin = currentRole.role === UserConstants.ADMIN;
    return isOrganisationsPending
      ? (
          <Fragment>
            <Skeleton type="base" />
            <Skeleton type="base" />
            <Skeleton type="base" />
          </Fragment>
      )
      : (
          <Fragment>
            <div className={`patient-care-details-holder ${isDataChanged ? "data-changed" : ""}`}>
              <div className="patient-care-container-sm">
                { isSuperAdmin && (
                  <PayoutMethod
                    name={account && account.name}
                    handlePayoutMethodEdit={this.handlePayoutMethodEdit}
                  />
                )}
                <ul className="patient-care-numbered-list">
                  <li>
                    <BankAccountDetails
                      name={account && account.name}
                      stripeId={account && account.id}
                      accountStatus={account && account.bankDetailsProvided} />
                  </li>
                  <li>
                    <BankAccountVerificationStatus
                      legalEntity={account && account.legalEntity}
                    />
                  </li>
                  <li>
                    <StripeServiceAgreement
                      name={account && account.name}
                      tosAcceptance={account && account.tosAcceptance}
                      handleAcceptanceEdit={this.handleAcceptanceEdit}
                    />
                  </li>
                </ul>
              </div>

              { isDataChanged
                ? (
                  <div className={`patient-care-buttons-fixed-bottom ${isActiveAgenda ? "is-active-agenda" : ""}`}>
                    <div className="patient-care-container-sm">
                      <div className="patient-care-row-align-right">
                        <Button
                          buttonType="blueline"
                          messageKey="discard-btn"
                          defaultMessage={locale.Appointment.buttons.discardChanges}
                          onClick={this.handleOpenDiscardDialog}
                          isLoading={isUpdatingPayoutSettingsPending || isUpdatingOrganisationSettingsPending}
                          data-id="discard-btn"
                          className="patient-care-btn-in-group"
                        />
                        <Button
                          buttonType="secondary"
                          messageKey="save-changes-btn"
                          defaultMessage={locale.Appointment.buttons.saveChanges}
                          onClick={this.handleUpdatePayoutDetails}
                          isLoading={isUpdatingPayoutSettingsPending || isUpdatingOrganisationSettingsPending}
                          isDisabled={isPayoutMethodEdited}
                          data-id="save-changes-btn"
                          className="patient-care-btn-in-group"
                          tabIndex={isPayoutMethodEdited ? -1 : 0}
                        />
                      </div>
                    </div>
                  </div>
                ) : null }
            </div>
            {isModalOverlayOpened && (
              <DiscardChangesOverlay
                handleDiscardChanges={this.handleDiscardChanges}
                handleStay={this.handleStay}
              />
            )}
            <NavigationPrompt when={isDataChanged} >
              {({ onConfirm, onCancel }) => (
                <DiscardChangesOverlay
                  handleStay={onCancel}
                  handleDiscardChanges={onConfirm}
                />
              )}
            </NavigationPrompt>
          </Fragment>
          );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutDetails);
