import React, { Component } from 'react';
import { Tooltip, FormItem, InputError, Checkbox } from '@patient-access/ui-kit';
import { SidebarBodySection, SidebarBodyHeader, SidebarBodyContent } from 'components/Share/sidebar';
import locale from 'service/locale';

type Props = {
  userData: any,
  isError: boolean,
  isSuperAdmin: boolean,
  setUserData: any,
  handleValidateModes: any,
};

class SectionModes extends Component<Props> {
  render() {
    const { userData, isError, isSuperAdmin, setUserData, handleValidateModes } = this.props;

    const handleModes = (e: any) => {
      const target = e.target;
      const value = parseInt(target.value);
      let modesArray = userData.serviceModes;
      if (target.checked) {
        modesArray.push(value);
      } else {
        const index = modesArray.indexOf(value);
        modesArray.splice(index, 1);
      }
      modesArray = [...new Set(modesArray)];
      setUserData({ serviceModes: modesArray });
      handleValidateModes();
    }

    const renderF2FOption = () => {
      const isModeF2F = userData.serviceAvailableModes.includes(0);
      if (isModeF2F) {
        return (
          <Checkbox id="appointmentType_F2F_active" name="appointmentType_F2F_active" label={locale.BranchServicesRedesign.section.appointmentTypes.labelF2F} onChange={handleModes} value={0} checked={userData.serviceModes.includes(0)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.BranchServicesRedesign.section.appointmentTypes.tooltipF2F}>
            <Checkbox id="appointmentType_F2F_inactive" name="appointmentType_F2F_inactive" value="appointmentType_F2F_inactive" label={locale.BranchServicesRedesign.section.appointmentTypes.labelF2F} onChange={() => {}} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderVideoOption = () => {
      const isModeVideo = userData.serviceAvailableModes.includes(1);
      if (isModeVideo) {
        return (
          <Checkbox id="appointmentType_Video_active" name="appointmentType_Video_active" label={locale.BranchServicesRedesign.section.appointmentTypes.labelVideo} onChange={handleModes} value={1} checked={userData.serviceModes.includes(1)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.BranchServicesRedesign.section.appointmentTypes.tooltipVideo}>
            <Checkbox id="appointmentType_Video_inactive" name="appointmentType_Video_inactive" value="appointmentType_Video_inactive" label={locale.BranchServicesRedesign.section.appointmentTypes.labelVideo} onChange={() => {}} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderPhoneOption = () => {
      const isModePhone = userData.serviceAvailableModes.includes(2);
      if (isModePhone) {
        return (
          <Checkbox id="appointmentType_Phone_active" name="appointmentType_Phone_active" label={locale.BranchServicesRedesign.section.appointmentTypes.labelPhone} onChange={handleModes} value={2} checked={userData.serviceModes.includes(2)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.BranchServicesRedesign.section.appointmentTypes.tooltipPhone}>
            <Checkbox id="appointmentType_Phone_inactive" name="appointmentType_Phone_inactive" value="appointmentType_Phone_inactive" label={locale.BranchServicesRedesign.section.appointmentTypes.labelPhone} onChange={() => {}} disabled />
          </Tooltip>
        </div>
      );
    }

    const renderHomeDeliveryOption = () => {
      const isModeHomeDelivery = userData.serviceAvailableModes.includes(5);
      if (isModeHomeDelivery) {
        return (
          <Checkbox id="appointmentType_HomeDelivery_active" name="appointmentType_HomeDelivery_active" label={locale.BranchServicesRedesign.section.appointmentTypes.labelHomeDelivery} onChange={handleModes} value={5} checked={userData.serviceModes.includes(5)} />
        );
      }
      return (
        <div className="input-checkbox">
          <Tooltip vertical="middle" horizontal="right" message={locale.BranchServicesRedesign.section.appointmentTypes.tooltipHomeDelivery}>
            <Checkbox id="appointmentType_HomeDelivery_inactive" name="appointmentType_HomeDelivery_inactive" value="appointmentType_HomeDelivery_inactive" label={locale.BranchServicesRedesign.section.appointmentTypes.labelHomeDelivery} onChange={() => {}} disabled />
          </Tooltip>
        </div>
      );
    }

    return (
      <SidebarBodySection>
        <SidebarBodyHeader>
          <h3>{locale.BranchServicesRedesign.section.appointmentTypes.header}</h3>
        </SidebarBodyHeader>
        <SidebarBodyContent>
          <FormItem type="item" error={isError ? 'error' : null}>
            <div className="row row-list">
              {renderF2FOption()}
              {renderVideoOption()}
              {renderPhoneOption()}
              {isSuperAdmin ? renderHomeDeliveryOption() : null}
            </div>
            {isError ? <InputError message={locale.BranchServicesRedesign.validationError.appointmentType} /> : null}
          </FormItem>
        </SidebarBodyContent>
      </SidebarBodySection>
    );
  }
}

export default SectionModes;
