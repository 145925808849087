import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import type { Action } from 'types/actions';
import type { Organisation } from 'types/organisation';

type OrganisationsState = {
  organisationsList: Organisation[],
  organisationsListAsync: Organisation[],
  pages: number,
  pageNumber: number,
  searchValue: string,
  searchValueAsync: string,
  isOrganisationsPending: boolean,
  isOrganisationsCHPending: boolean,
  isOrganisationDeletionPending: boolean,
}

const initialState: OrganisationsState = {
  organisationsList: [],
  organisationsListAsync: [],
  pages: 0,
  pageNumber: 0,
  searchValue: "",
  searchValueAsync: "",
  isOrganisationsPending: false,
  isOrganisationsCHPending: false,
  isOrganisationDeletionPending: false,
};

export default (localState: OrganisationsState = initialState, action: Action): OrganisationsState => {
  switch (action.type) {
    case OrganisationsConstants.SET_ORGANISATIONS_LIST:
      const { organisationsList, searchValue } = action.payload;
      const { items, pages, pageNumber } = organisationsList;
      return {
        ...localState,
        organisationsList: items,
        pages: pages === 0 ? localState.pages : pages,
        pageNumber: pageNumber,
        searchValue,
        isOrganisationsPending: false,
      };
    case OrganisationsConstants.SET_ORGANISATIONS_LIST_ASYNC:
      const { organisationsListAsync, searchValueAsync } = action.payload;
      return {
        ...localState,
        organisationsListAsync: organisationsListAsync.items,
        searchValueAsync,
      };
    case OrganisationsConstants.SET_ORGANISATIONSCH_LIST:
      const { chOrganisationsList, chSearchValue } = action.payload;
      return {
        ...localState,
        chOrganisationsList: chOrganisationsList,
        chSearchValue,
        isOrganisationsCHPending: false,
      };
    case OrganisationsConstants.UPDATE_ORGANISATIONS_LIST:
      return {
        ...localState,
        organisationsList: action.payload,
        isOrganisationsPending: false,
      };
    case OrganisationsConstants.SET_ORGANISATIONS_PENDING:
      return {
        ...localState,
        isOrganisationsPending: true,
      };
    case OrganisationsConstants.SET_ORGANISATIONSCH_PENDING:
      return {
        ...localState,
        isOrganisationsCHPending: true,
      };
    case OrganisationsConstants.DELETE_ORGANISATION_PENDING:
      return {
        ...localState,
        isOrganisationDeletionPending: true,
      };
    case OrganisationsConstants.DELETE_ORGANISATION_SUCCESS:
      return {
        ...localState,
        isOrganisationDeletionPending: false,
      };
    case OrganisationsConstants.DELETE_ORGANISATION_ERROR:
      return {
        ...localState,
        isOrganisationDeletionPending: false,
      };
    default:
      return localState;
  }
}
