import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import moment from "moment";
import { Form, FormItem, InputLabel, Input } from "@patient-access/ui-kit";
import { cloneDeep } from "lodash";
import TimeInterval from "components/Pages/Admin/BranchContent/TimeInterval/TimeInterval" 
import * as CalendarsConstants from 'constants/CalendarsConstants';
import { updateForm } from "actions/form";
import { getAvailabilitySettings } from 'actions/calendars';
import type { Action } from 'types/actions';
import { formatOpeningHours } from "helpers/formatData";
import locale from "service/locale";
import './styles.scss';

const today = new Date().toISOString().substr(0, 10);

const inputNames = {
  startDate: "startDate",
  endDate: "endDate",
  durationType: "duration_type",
  openHours: "openHours",
  branchOpenHours: "branchOpenHours"
};

type Props = {
  updateForm: (data: any) => Action,
  form: any,
  calendar: any,
  getAvailabilitySettings: (organisationId: string, branchId: string) => Action,
  branchId: string,
  organisationId: string,
  isAvailabilityPending: boolean
};

type State = {
  durationType: Object,
  hasErrors: boolean,
};

const mapStateToProps = (state) => ({
  form: state.form,
  calendar: state.calendar,
  organisationId: state.router.organisationId,
  branchId: state.router.branchId,
  isAvailabilityPending: state.calendar.isGetPending  
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: (data) => dispatch(updateForm(data)),
  getAvailabilitySettings: (organisationId: string, branchId: string) => dispatch(getAvailabilitySettings(organisationId, branchId)),
});

class AddNewCalendarAvailability extends Component<Props, State> {
  state = {
    durationType: locale.AddNewCalendar.durationOptions[0],
    hasErrors: false
  };

  componentDidMount = () => {
    const { updateForm, organisationId, branchId, getAvailabilitySettings } = this.props;
    updateForm({
      ...CalendarsConstants.defaultNewCalendarAvailability,
      [inputNames.startDate]: today,
      [inputNames.durationType]: locale.AddNewCalendar.durationOptions[0].value,
    });
    getAvailabilitySettings(organisationId, branchId);
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const { updateForm, isAvailabilityPending } = this.props;   

    if (isAvailabilityPending && !nextProps.isAvailabilityPending) {      
      const openHours = formatOpeningHours(nextProps.calendar.openHours);  
      const formattedOpenHours = cloneDeep(CalendarsConstants.defaultNewCalendarAvailability);
      
      if (nextProps.calendar.openHours.length) {
        openHours.forEach(openDay => {
         
          formattedOpenHours[CalendarsConstants.DaysOfWeek[openDay.dayOfWeek]].status =
            openDay.isOpen
              ? locale.AddNewCalendar.dayAvailableOptions[0].value
              : locale.AddNewCalendar.dayAvailableOptions[1].value;            
        });
      }

      updateForm({
        ...formattedOpenHours,
        [inputNames.startDate]: today,
        [inputNames.durationType]: locale.AddNewCalendar.durationOptions[0].value,
        [inputNames.openHours]: nextProps.calendar.openHours,    
        [inputNames.branchOpenHours]: cloneDeep(nextProps.calendar.openHours)    
      });      
    }
  };

  handleSelectDurationType = (e: any) => {
    const { updateForm } = this.props;
    const { value } = e;
    updateForm({ duration_type: value });
    this.setState({
      durationType: locale.AddNewCalendar.durationOptions.find(
        option => option.value === e.value
      )
    });
  };

  handleTimeChange = ({ target: { name, value } }) => {
    const { updateForm, form } = this.props;
    const parameters = name.split("_");
    updateForm({
      [parameters[0]]: { ...form[parameters[0]], [parameters[1]]: value }
    });
  };

  handleDateChange = ({ target: { name, value } }) => {
    const { updateForm, form } = this.props;
    const { durationType } = this.state;
    const { endDate } = form;
    const isRange = durationType.value === locale.AddNewCalendar.durationOptions[1].value;
    const isEndBeforeStart = moment(endDate).isBefore(value);

    if (name === inputNames.startDate && isRange && (!endDate || isEndBeforeStart)) {
      updateForm({
        [name]: value,
        [inputNames.endDate]: value
      });
    } else {
      updateForm({
        [name]: value
      });
    }
  };

  handleSelectDayStatus = (data: any) => {
    const { updateForm, form } = this.props;
    const { value, name } = data;
    updateForm({
      [name]: {
        ...form[name],
        status: value
      }
    });    
  }; 

  render() {
    const { durationType} = this.state;
    const { form } = this.props;
    const { startDate, endDate } = form;
    const calendarStart = startDate || today;
    const calendarEnd = endDate || calendarStart;
    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddNewCalendar.addNewCalendarAvailabilityTitle}</h3>
          <p>{locale.AddNewCalendar.addNewCalendarAvailabilityDescription}</p>
        </div>
        <div
          className={
            durationType.value !==
            locale.AddNewCalendar.durationOptions[0].value
              ? "patient-care-сalendar-dates with-duration-range"
              : "patient-care-сalendar-dates"
          }
        >
          <div className="patient-care-block-row">
            <div
              className={
                durationType.value !==
                locale.AddNewCalendar.durationOptions[0].value
                  ? "patient-care-col-4"
                  : "patient-care-col-6"
              }
            >
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="startDate"
                    message={locale.AddNewCalendar.labelStartDate}
                    size="small"
                  />
                  <Input
                    id="startDate"
                    name={inputNames.startDate}
                    onChange={this.handleDateChange}
                    value={calendarStart}
                    data-id="startDate"
                    type="date"
                    min={today}
                    max={endDate || null}
                  />
                </FormItem>
              </Form>
            </div>
            <div
              className={
                durationType.value !==
                locale.AddNewCalendar.durationOptions[0].value
                  ? "patient-care-col-4"
                  : "patient-care-col-6"
              }
            >
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="duration_type"
                    message={locale.AddNewCalendar.addNewCalendarDurationLabel}
                    size="small"
                  />
                </FormItem>
              </Form>
              <Select
                id="duration_type"
                name={inputNames.durationType}
                options={locale.AddNewCalendar.durationOptions}
                value={durationType}
                onChange={this.handleSelectDurationType}
                classNamePrefix="patient-care"
                className="patient-care-select"
              />
            </div>
            {durationType.value !==
              locale.AddNewCalendar.durationOptions[0].value && (
              <div className="patient-care-col-4">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel
                      htmlFor="endDate"
                      message={locale.AddNewCalendar.labelEndDate}
                      size="small"
                    />
                    <Input
                      id="endDate"
                      name={inputNames.endDate}
                      onChange={this.handleDateChange}
                      data-id="endDate"
                      type="date"
                      value={calendarEnd}
                      min={calendarStart}
                    />
                  </FormItem>
                </Form>
              </div>
            )}
          </div>
        </div>
        <div className="patient-care-сalendar-availability">
          {locale.AddNewCalendar.daysOfWeek.map(day => {
             const dayName = day.value.name;            
            const formDayData = form[dayName];
            const isAvailableDay = 
              formDayData &&
              formDayData.status === locale.AddNewCalendar.dayAvailableOptions[0].value;                       
            const timeSessionForDay = form.openHours && form.openHours.find(item => item.day === day.dayOfWeek);            
            const editableDay = timeSessionForDay && (timeSessionForDay.timesSessions.length >= 1 && 
                                timeSessionForDay.timesSessions[0].from !== CalendarsConstants.EMPTY_VALUE && 
                                timeSessionForDay.timesSessions[0].to !== CalendarsConstants.EMPTY_VALUE);                              
            return (
              <div key={day.label} className="patient-care-block-row">
                <div className="patient-care-day-col">
                  <span className="patient-care-day">{day.label}</span>
                  <Select
                    id={dayName}
                    name={dayName}
                    options={locale.AddNewCalendar.dayAvailableOptions}
                    classNamePrefix="patient-care"
                    className={`patient-care-select`}
                    value={
                      isAvailableDay
                        ? locale.AddNewCalendar.dayAvailableOptions[0]
                        : locale.AddNewCalendar.dayAvailableOptions[1]
                    }
                    isDisabled={!isAvailableDay && !editableDay}
                    onChange={e =>
                      this.handleSelectDayStatus({
                        value: e.value,
                        name: dayName
                      })
                    }
                    isSearchable={false}
                  />
                </div>
                {isAvailableDay ? ( 
                  <div className="patient-care-time-col edited">        
                     {                        
                        timeSessionForDay &&
                        timeSessionForDay.timesSessions &&
                        timeSessionForDay.timesSessions.map((t,index)=>{
                            return (<TimeInterval
                              intervalIndex={index}
                              timesSessions={timeSessionForDay.timesSessions}
                              dayOfWeek={timeSessionForDay.day}                        
                              key={index} />)
                        })}
            </div> 
            ) : null
                }
        </div>
        );        
          })}
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCalendarAvailability);
