import React, { Component, Fragment } from 'react';
import { LogoPatientAccessPro, Icon, IconHelp, LinkExternal } from '@patient-access/ui-kit';

import locale from 'service/locale';
import './styles.scss';

import MetaData from '../../Share/MetaData/MetaData';

import * as RoutesConstants from 'constants/RoutesConstants';

class NotSupported extends Component {

  render() {
    return (
      <Fragment>
        <MetaData />
        <header className="patient-care-header">
          <div className="patient-care-header-container">
            <div className="patient-care-logo-full">
              <a href={RoutesConstants.HOME}>
                <LogoPatientAccessPro />
              </a>
            </div>
            <a
              href={locale.Login.linkSupportCentre}
              className="patient-care-support-link"
            >
              <Icon
                type="inline"
                size="medium"
                icon={<IconHelp outline={true} />}
              />
              <span className="patient-care-xs">
                {locale.Login.linkNameSupportCentre}
              </span>
            </a>
          </div>
        </header>
        <div className="patient-care-full-height-page">
          <div className="patient-care-full-height-body">
            <div className="patient-care-full-height-container">
              <div className="patient-care-full-height-text">
                <h1>
                  {locale.NotSupported.NotSupportedTitle}
                </h1>
                <p>
                  {locale.NotSupported.NotSupportedMessage}
                </p>
              </div>
              <ul className="patient-care-unstyled-list">
                <li className="patient-care-option-item">
                  <span className="patient-care-option-image chrome"></span>
                  <LinkExternal
                    to={locale.NotSupported.chromeLink}
                    message={locale.NotSupported.chromeText}
                    target="_blank"
                  />
                </li>
                <li className="patient-care-option-item">
                  <span className="patient-care-option-image firefox"></span>
                  <LinkExternal
                    to={locale.NotSupported.firefoxLink}
                    message={locale.NotSupported.firefoxText}
                    target="_blank"
                  />
                </li>
              </ul>
              </div>
          </div>
        </div>
      </Fragment>
    );
  }

}

export default NotSupported;
